import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {StammbaumService} from '../../services/stammbaum.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'change-tree-settings',
  templateUrl: './change-tree-settings.component.html',
  styleUrls: ['./change-tree-settings.component.css']
})
export class ChangeTreeSettingsComponent implements OnInit {
  @Input() version;
  form: FormGroup;

  constructor(private ngbActiveModal: NgbActiveModal,
              private stammbaumService: StammbaumService) {
    this.form = new FormGroup({
      hide_eo_3: new FormControl(null),
      hide_eo_4: new FormControl(null)
    });
  }

  ngOnInit() {
    if (this.version) {
      this.form.patchValue({
        hide_eo_3: this.version.hide_eo_3,
        hide_eo_4: this.version.hide_eo_4,
      })
    }
  }

  save() {
    const data = {
      version_id: this.version.id,
      hide_eo_3: this.form.controls.hide_eo_3.value,
      hide_eo_4: this.form.controls.hide_eo_4.value,
    };
    this.stammbaumService.changeStammbaumVersionSettings(data).subscribe(
      (result) => { this.ngbActiveModal.close(); },
      (error) => { console.log(error); },
      () => {}
      );
  }

  close() {
    this.ngbActiveModal.close();
  }

}
