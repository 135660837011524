import { Injectable } from '@angular/core';
import {ConstantsService} from './constants.service';
import {Store} from '@ngrx/store';
import {IAutoparserState} from "../store/autoparser-state.interface";
import * as AutoparserActions from "../store/autoparser.actions";


@Injectable()
export class AutoparserWebsocketService {

  private ws: WebSocket;

  constructor(private constantsService: ConstantsService,
              private store: Store<{autoparser: IAutoparserState}>) {}

  public connect() {
    if (!this.ws) {
      this.create(this.constantsService.getWebsocketServerAutoparserChannel());
      console.log('Successfully connected: ' + this.constantsService.getWebsocketServerAutoparserChannel());
    }
  }

  public send(data) {
    if (this.ws.readyState === WebSocket.OPEN) {
         this.ws.send(JSON.stringify(data));
          console.log('sent', data);
        }
  }

  public received(socket_data) {
    const message_body = JSON.parse(socket_data.data);
    this.store.dispatch(new AutoparserActions.AddNewMessage(message_body.message));
  }

  private create(url) {
    this.ws = new WebSocket(url);
    this.ws.onmessage = (message) => {
      this.received(message);
    };
    this.ws.onerror = (message) => {
      console.log(message);
      console.log('WebSocket error!');
    };
    this.ws.onclose = (message) => {
      console.log(message);
      console.log('WebSocket disconnected, try to reconnect');
      this.ws = null;
      this.connect();
    };
  }
}
