import {ConstantsService} from './constants.service';
import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {IAutoparserDirectory} from '../nvz-autoparser/autoparser-directory.interface';
import {IAutoparserRow} from '../nvz-autoparser/autoparser-row.interface';
import {IAutoparserPostprocessedEntry, IAutoparserState} from '../store/autoparser-state.interface';
import {Store} from '@ngrx/store';
import * as AutoparserActions from '../store/autoparser.actions';
import {IAuthUser} from '../models/auth-user.interface';
import {Subscription} from 'rxjs';

@Injectable()
export class AutoparserService implements OnDestroy {
  authUser: IAuthUser;
  authUserSub: Subscription;

  constructor(private http: HttpClient,
              private constantsService: ConstantsService,
              private store: Store<{autoparser: IAutoparserState, authUser: {authUser: IAuthUser}}>) {
    this.authUserSub = this.store.select('authUser').subscribe(
      (authUser) => {
        this.authUser = authUser.authUser;
      }
    );
  }

  startPreprocessing() {
    return this.http.get(`${this.constantsService.getApiEndpoint()}/autoparser/start-preprocessing/`, {headers: this.constantsService.getHttpOptions()});
  }

  startOCR() {
    return this.http.get(`${this.constantsService.getApiEndpoint()}/autoparser/start-ocr/`, {headers: this.constantsService.getHttpOptions()});
  }

  getDirectories() {
    this.http.get<IAutoparserDirectory[]>(`${this.constantsService.getApiEndpoint()}/autoparser/get-directories/`, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (dirs) => {
        dirs = dirs.filter(x => x.postprocess_user === this.authUser.user.id); // only show directories owned by current user
        this.store.dispatch(new AutoparserActions.ReplaceAutoparserDirectories(dirs));
      }
    );
  }

  reloadCurrentDirectory(id: number) {
    this.http.get<IAutoparserDirectory>(`${this.constantsService.getApiEndpoint()}/autoparser/get-directory/?id=${id}`, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (dir) => {
        this.store.dispatch(new AutoparserActions.SetAutoparserActiveDir(dir));
      }
    );
  }

  getRow(dir_id: number, row_count: number) {
    return this.http.get<IAutoparserRow>(`${this.constantsService.getApiEndpoint()}/autoparser/get-row/?dir_id=${dir_id}&row_count=${row_count}`, {headers: this.constantsService.getHttpOptions()});
  }

  endPage(dir_id: number, row_count: number) {
    return this.http.get<IAutoparserRow>(`${this.constantsService.getApiEndpoint()}/autoparser/end-page/?dir_id=${dir_id}&current_row=${row_count}`, {headers: this.constantsService.getHttpOptions()});
  }

  saveDataForImport(data: any) {
    return this.http.post(`${this.constantsService.getApiEndpoint()}/autoparser/data-for-import/`, data, {headers: this.constantsService.getHttpOptions()});
  }

  changeDataForImport(data: any) {
    return this.http.patch(`${this.constantsService.getApiEndpoint()}/autoparser/data-for-import/${data.id}/`, data, {headers: this.constantsService.getHttpOptions()});
  }

  deleteDataForImport(id: number) {
    return this.http.delete(`${this.constantsService.getApiEndpoint()}/autoparser/data-for-import/${id}/`, {headers: this.constantsService.getHttpOptions()});
  }

  getDataExportList(dir_id: number) {
    this.http.get<IAutoparserPostprocessedEntry[]>(`${this.constantsService.getApiEndpoint()}/autoparser/data-for-import/?directory_id=${dir_id}`, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (result) => {
        this.store.dispatch(new AutoparserActions.ReplaceAutoparserPostprocessList(result));
      },
      (error) => {
        console.log(error);
      },
    () => {}
    );
  }

  ngOnDestroy(): void {
    this.authUserSub.unsubscribe();
  }
}
