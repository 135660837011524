import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DocumentsService} from '../documents.service';
import {IStammbaumPerson} from '../../stammbaum/stammbaum-person.interface';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'vundv-popup',
  templateUrl: './vundv-popup.component.html',
  styleUrls: ['./vundv-popup.component.css']
})
export class VundvPopupComponent {
  person_id: number;
  person: IStammbaumPerson;
  form: FormGroup;

  constructor(private ngbActiveModal: NgbActiveModal,
              private documentsService: DocumentsService) {
    this.form = new FormGroup({
      'hu_needed': new FormControl(false),
      'gu_needed': new FormControl(false),
      'pa_needed': new FormControl(false),
      'bank_needed': new FormControl(false),
      'email_needed': new FormControl(true),
      'freitext': new FormControl('')
    });
  }

  createVollmacht() {
    this.documentsService.createVundV(this.person_id, 'vollmacht');
  }

  createVertrag() {
    this.documentsService.createVundV(this.person_id, 'vertrag');
  }

  createBestaetigung() {
    const hu_needed = this.form.controls.hu_needed.value;
    const gu_needed = this.form.controls.gu_needed.value;
    const pa_needed = this.form.controls.pa_needed.value;
    const bank_needed = this.form.controls.bank_needed.value;
    const email_needed = this.form.controls.email_needed.value;
    const freitext = this.form.controls.freitext.value;
    this.documentsService.createVundV(this.person_id, 'bestaetigung', hu_needed, gu_needed, pa_needed, bank_needed, email_needed, freitext);
  }

  createVollmachtM() {
    this.documentsService.createVundV(this.person_id, 'vollmacht-m');
  }

  createVertragM() {
    this.documentsService.createVundV(this.person_id, 'vertrag-m');
  }

  createBestaetigungM() {
    const hu_needed = this.form.controls.hu_needed.value;
    const gu_needed = this.form.controls.gu_needed.value;
    const pa_needed = this.form.controls.pa_needed.value;
    const bank_needed = this.form.controls.bank_needed.value;
    const email_needed = this.form.controls.email_needed.value;
    const freitext = this.form.controls.freitext.value;
    this.documentsService.createVundV(this.person_id, 'bestaetigung-m', hu_needed, gu_needed, pa_needed, bank_needed, email_needed, freitext);
  }

  canCreateVertrag() {
    if (this.person && this.person.contract_send_out_percentage) { return true; }
    return false;
  }

  close() {
    this.ngbActiveModal.close();
  }
}
