import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {KassenbuchBelegInterface} from '../kassenbuch.interface';

@Component({
  selector: 'app-kassenbuch-delete-kostenposition-popup',
  templateUrl: './kassenbuch-delete-kostenposition-popup.component.html',
  styleUrls: ['./kassenbuch-delete-kostenposition-popup.component.css']
})
export class KassenbuchDeleteKostenpositionPopupComponent implements OnInit {
  form: FormGroup;
  kostenposition: KassenbuchBelegInterface;
  constructor(private ngbActiveModal: NgbActiveModal) {
    this.form = new FormGroup({
      deleted_reason: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {}

  cancel() {
    this.ngbActiveModal.dismiss();
  }

  confirm() {
    this.ngbActiveModal.close(this.form.controls.deleted_reason.value);
  }

}
