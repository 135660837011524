import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {CustomUppercasePipe} from '../custom-uppercase-pipe/custom-uppercase-pipe';

@Component({
  selector: 'editfield-name',
  templateUrl: './editfield-name.component.html',
  styleUrls: ['./editfield-name.component.css']
})
export class EditfieldNameComponent implements OnInit, OnChanges {

  @Input() first_name = '';
  @Input() last_name = '';
  @Input() birth_name = '';
  @Input() editMode = false;
  @Output() first_nameChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() last_nameChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() birth_nameChange: EventEmitter<string> = new EventEmitter<string>();
  nameDisplay = '';
  cssClass = '';

  constructor(private customUppercasePipe: CustomUppercasePipe) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.parse();
  }

  parse() {
    const ln = this.customUppercasePipe.transform(this.last_name);
    this.nameDisplay = this.first_name + ' ' + ln;
    if (this.birth_name != '') {
      this.nameDisplay += ', geb. ' + this.birth_name;
    }
    this.cssClass = 'normal';
  }
}
