import {Injectable, OnDestroy} from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import {Store} from '@ngrx/store';
import {HttpClient} from '@angular/common/http';
import {ConstantsService} from "./constants.service";
import {IUserRole} from "../models/userrole.interface";
import {INetworkState} from "../store/network-state.interface";
import {IAuthUser} from "../models/auth-user.interface";
import * as UserRoleActions from "../store/user-role.actions";
import * as NetworkActions from "../store/network.actions";

const TRY_RELOAD_INTERVAL = 10 * 1000;

@Injectable()
export class UserroleService implements OnDestroy {

  userRoleLoadingInProgress: boolean;
  userRolesObs: Observable<IUserRole[]>;
  userRolesSubscription: Subscription;
  userRoles: IUserRole[];
  networkObs: Observable<INetworkState>;
  network: INetworkState;
  networkSubscription: Subscription;
  authUserObs: Observable<{authUser: IAuthUser}>;
  authUser: IAuthUser;
  authUserSubscription: Subscription;
  reloadTimerSubscription: Subscription;

  constructor(private http: HttpClient,
              private store: Store<{userRole: IUserRole[], network: INetworkState, authUser: {authUser: IAuthUser}}>,
              private constantsService: ConstantsService)
  {
    this.userRolesObs = this.store.select('userRoles');
    this.userRolesSubscription = this.userRolesObs.subscribe(
      (userRoles) => {this.userRoles = userRoles; }
    );
    this.networkObs = this.store.select('network');
    this.networkSubscription = this.networkObs.subscribe(
      (network) => {this.network = network; }
    );
    this.authUserObs = this.store.select('authUser');
    this.authUserSubscription = this.authUserObs.subscribe(
      (authUser) => {this.authUser = authUser.authUser; }
    );
    this.reloadTimerSubscription = timer(0, TRY_RELOAD_INTERVAL).subscribe(t => {
      if(this.network.updateNeededUserRole && this.authUser.loggedIn && this.network.isConnectedToServer) { // if user is logged in, reload is neccessary and there is a connection
        this.getUserRolesFromServer();
      }
    });
  }

   triggerUserRolesReloadFromServer() {
    this.store.dispatch(new NetworkActions.SetUpdateNeededUserRoleTrue());
    this.getUserRolesFromServer();
  }

  getUserRolesFromServer() {
    if (this.userRoleLoadingInProgress) { // if already reloading, then ignore
      return;
    }
    this.userRoleLoadingInProgress = true;
    this.http.get<IUserRole[]>(`${this.constantsService.getApiEndpoint()}/userrole/`, {headers: this.constantsService.getHttpOptions()}).
      subscribe(
      (response) => {
        this.store.dispatch(new UserRoleActions.SetUserRoles(response));
        this.store.dispatch(new NetworkActions.SetUpdateNeededUserRoleFalse());
        this.userRoleLoadingInProgress = false;
      },
      (error) => {
        this.userRoleLoadingInProgress = false;
      },
      () => {
        this.userRoleLoadingInProgress = false;
      });
  }

  getUserRoles() {
    return this.userRoles;
  }

  ngOnDestroy() {
    this.userRolesSubscription.unsubscribe();
    this.authUserSubscription.unsubscribe();
    this.networkSubscription.unsubscribe();
    this.reloadTimerSubscription.unsubscribe();
  }
}

