import {IGeneratedDocumentState} from './generated-document-state.interface';
import * as GeneratedDocumentActions from './generated-document.actions';

const initialState: IGeneratedDocumentState = {
  categories: [],
  templates_for_category: [],
  template_selected: null,
  recipients_selected: [],
  case_id_selected: null,
  person_id_selected: null
};

export function generatedDocumentReducer(state = initialState, action: GeneratedDocumentActions.GeneratedDocumentActions) {
  switch (action.type) {

    case GeneratedDocumentActions.REPLACE_GENERATED_DOCUMENT_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };

    case GeneratedDocumentActions.REPLACE_GENERATED_DOCUMENT_TEMPLATES:
      return {
        ...state,
        templates_for_category: action.payload
      };

    case GeneratedDocumentActions.REPLACE_GENERATED_DOCUMENT_TEMPLATE_SELECTED:
      return {
        ...state,
        template_selected: action.payload
      };

    case GeneratedDocumentActions.REPLACE_GENERATED_DOCUMENT_RECIPIENTS_SELECTED:
      return {
        ...state,
        recipients_selected: action.payload
      };

    case GeneratedDocumentActions.REPLACE_GENERATED_DOCUMENT_CASE_AND_PERSON_SELECTED:
      return {
        ...state,
        case_id_selected: action.payload.case_id,
        person_id_selected: action.payload.person_id
      };

    default:
      return state;
  }
}
