import {Component, OnInit, Input, HostListener, OnChanges, SimpleChanges} from '@angular/core';
import {Store} from '@ngrx/store';
import {INVZState} from '../../store/nvz-state.interface';
import * as NVZOptions from '../nvz-options';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IUser} from '../../models/user.interface';
import {NVZService} from '../../services/nvz.service';
import {
  IAppNotification, NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR,
  NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS, NOTIFICATION_SLOT_NVZ
} from '../../app-notifications/app-notification.interface';
import * as AppNotificationActions from '../../store/app-notification.actions';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';
import {UserService} from '../../services/user.service';
import {RightsManagementService} from '../../services/rights-management.service';
import {NVZStaedteFormComponent} from '../nvz-staedte-form/nvz-staedte-form.component';
import {INVZStadt} from '../nvz-stadt.interface';

@Component({
  selector: 'nvz-staedte',
  templateUrl: './nvz-staedte.component.html',
  styleUrls: ['./nvz-staedte.component.css']
})
export class NvzStaedteComponent implements OnInit, OnChanges {
  @HostListener('window:keydown', ['$event'])
  @Input() nvzState: INVZState;
  @Input() user: IUser;
  copiedStaedte: INVZStadt[] = [];
  filterByFirstLetter = null;
  filterByString = '';

  keyEvent(event: KeyboardEvent) {
    if ((event.key === 'Insert') && this.rightsManagementService.isAllowedToEditAllNVZEntries()) {
      this.addStadt();
    }
  }

  constructor(private store: Store<{nvz: INVZState, appNotifications: { appNotifications: IAppNotification[]}}>,
              private modalService: NgbModal,
              public nvzService: NVZService,
              public userService: UserService,
              public rightsManagementService: RightsManagementService) { }

  ngOnInit() {
    this.nvzService.getStaedte();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.nvzState) {
      if (changes.nvzState.currentValue !== changes.nvzState.previousValue) {
        this.copyAndRunFilters();
      }
    }
  }

  filterLetter($event) {
    this.filterByFirstLetter = $event;
    this.copyAndRunFilters();
  }

  filterBy($event) {
    this.filterByString = $event;
    this.copyAndRunFilters();
  }

  copyAndRunFilters() {
    let staedte: INVZStadt[] = this.nvzState.staedte;
    if (this.filterByFirstLetter) {
      staedte = staedte.filter(x => x.name[0] === this.filterByFirstLetter);
    }
    if (this.filterByString) {
      staedte = staedte.filter(x => {
        if (x.name.toUpperCase().includes(this.filterByString.toUpperCase())) {
          return true;
        }
        if (x.bundesland.toUpperCase().includes(this.filterByString.toUpperCase())) {
          return true;
        }
        if (x.type.toUpperCase().includes(this.filterByString.toUpperCase())) {
          return true;
        }
        return false;
      });
    }
    this.copiedStaedte = staedte;
  }

  showStadtStatus(statusId: number) {
    let options = NVZOptions.NVZStadtStatusOptions;
    let option = options.find(x => x.id == statusId);
    if (option) {
      return option.name;
    }
    return "---";
  }

  addStadt() {
    const modalRef = this.modalService.open(NVZStaedteFormComponent);
    modalRef.componentInstance.editMode = false;
    modalRef.result.then(
      (result) => {if (result) {this.save(result, false)}},
      () => {}
    );
  }

  editStadt(stadt: INVZStadt) {
    const modalRef = this.modalService.open(NVZStaedteFormComponent);
    modalRef.componentInstance.stadt = stadt;
    modalRef.componentInstance.editMode = true;
    modalRef.result.then(
      (result) => {if (result) {this.save(result, true)}},
      () => {}
    );
  }

  deleteStadt(stadt: INVZStadt) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.message = "Wollen Sie diese Stadt mit allen ihren Standesämtern, Verzeichnissen und Einträgen wirklich löschen?";
    modalRef.componentInstance.title = "Bestätigung";
    modalRef.result.then(
      (result) => {
        if (result) {
          this.nvzService.deleteStadt(stadt).subscribe(
            (result) => {
              this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
              this.nvzService.getStaedte();
            },
            (error) => {
              this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
              this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR));
              this.nvzService.getStaedte();
            },
            () => {}
          );
        }
      }
    );
  }

  selectStadt(stadt: INVZStadt) {
    this.nvzService.navigateToStandesaemter(stadt);
  }

  save(stadt: INVZStadt, editMode: boolean) {
    if (editMode) { // change existing
      let stadtToChange: INVZStadt = this.nvzState.staedte.find(x => x.id === stadt.id);
      if (stadtToChange) {
        stadtToChange = stadt;
        this.nvzService.updateStadt(stadt).subscribe(
        (result) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.nvzService.getStaedte();
        },
        (error) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR));
          this.nvzService.getStaedte();
        },
        () => {}
      );
      }
    } else { // create new
      stadt.owner_id = this.user.id;
      stadt.land = this.nvzService.activeLandId;
      this.nvzState.staedte.push(stadt);
      this.nvzService.createStadt(stadt).subscribe(
        (result) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.nvzService.getStaedte();
        },
        (error) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR));
          this.nvzService.getStaedte();
        },
        () => {}
      );
    }
  }
}
