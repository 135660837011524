import {
  AfterViewInit,
  Component,
  ElementRef, HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {ICase} from '../case.interface';
import Fraction from 'fraction.js';
import {IStammbaumPerson} from '../../stammbaum/stammbaum-person.interface';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {StammbaumService} from '../../services/stammbaum.service';
import {AuskehrService} from '../../services/auskehr.service';
import {Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddAuskehrInstitutionComponent} from '../../auskehr/add-auskehr-institution/add-auskehr-institution.component';
import {EditAuskehrQuelleComponent} from '../../auskehr/edit-auskehr-quelle/edit-auskehr-quelle.component';
import {IAddressbookInstitution} from '../../addressbook/addressbook-institution.interface';
import {
  RemoveAuskehrInstitutionComponent
} from "../../auskehr/remove-auskehr-institution/remove-auskehr-institution.component";
import {AuskehrGeldquelleInterface, AuskehrZahlungInterface} from "../../auskehr/auskehr.interface";
import {ConfirmModalComponent} from "../../tools/confirm-modal/confirm-modal.component";
import {captureMessage} from "@sentry/browser";
import {EditAuskehrZahlungComponent} from "../../auskehr/edit-auskehr-zahlung/edit-auskehr-zahlung.component";
import {EditErbenSteuerComponent} from "../../auskehr/edit-erben-steuer/edit-erben-steuer.component";

@Component({
  selector: 'app-case-auskehr',
  templateUrl: './case-auskehr.component.html',
  styleUrls: ['./case-auskehr.component.css']
})
export class CaseAuskehrComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() activeCase: ICase;
  @Input() copiedActiveCase: ICase;
  @Input() stammbaumState: IStammbaumState;
  @ViewChild('scrollIn', {static: true}) scrollIn: ElementRef;
  erben: IStammbaumPerson[] = [];
  institutionen: IAddressbookInstitution[] = [];
  institutionenSub: Subscription;
  allInstSumAtDeath = 0;
  allInstSumReceived = 0;
  allInstSumExpected = 0;
  notizenMode = 'alle';
  notizenErbenID = null;
  notizenInstitutionID = null;
  innerWidth: any;
  showVerlauf = false;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.resizeScrollArea();
  }

  constructor(private stammbaumService: StammbaumService,
              private auskehrService: AuskehrService,
              private ngbModal: NgbModal,
              private renderer: Renderer2) {
    this.institutionenSub = this.auskehrService.auskehrInstitutionsForCaseSub.subscribe(
      (value) => {
        this.institutionen = value;
        this.allInstSumReceived = 0;
        this.allInstSumAtDeath = 0;
        this.allInstSumExpected = 0;
        for (const inst of this.institutionen) {
          this.allInstSumReceived += inst.sum_received;
          this.allInstSumAtDeath += inst.sum_at_death;
          this.allInstSumExpected += inst.sum_expected;
        }
      }
    );
  }

  ngOnInit() {}

  ngOnChanges() {
    this.copyAndRunFilters();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.resizeScrollArea();
      }, 0)
  }

  ngOnDestroy() {
    this.institutionenSub.unsubscribe();
  }

  toggleShowVerlauf() {
    this.showVerlauf = !this.showVerlauf;
  }

  copyAndRunFilters() {
    if (!this.stammbaumState || !this.stammbaumState.stammbaum) {
      return;
    }
    let heirs: IStammbaumPerson[] = [...this.stammbaumState.stammbaum.persons];
    heirs = heirs.filter(x => { return this.stammbaumService.isPersonHeir(x); });
    this.erben = heirs;
  }

  addInstitution() {
    const modalRef = this.ngbModal.open(AddAuskehrInstitutionComponent);
    modalRef.componentInstance.caseId = this.activeCase.id;
  }

  removeInstitution(institution: IAddressbookInstitution) {
    const modalRef = this.ngbModal.open(RemoveAuskehrInstitutionComponent);
    modalRef.componentInstance.caseId = this.activeCase.id;
    modalRef.componentInstance.institution = institution;
    modalRef.result.then(
      (result) => {
        this.setDisplayMode('alle', null);
      },
      () => {}
    );
  }

  createQuelle(institution: IAddressbookInstitution) {
    const modalRef = this.ngbModal.open(EditAuskehrQuelleComponent);
    modalRef.componentInstance.caseId = this.activeCase.id;
    modalRef.componentInstance.institution = institution;
  }

  editQuelle(institution: IAddressbookInstitution, quelle: AuskehrGeldquelleInterface) {
    const modalRef = this.ngbModal.open(EditAuskehrQuelleComponent);
    modalRef.componentInstance.caseId = this.activeCase.id;
    modalRef.componentInstance.institution = institution;
    modalRef.componentInstance.geldquelle = quelle;
    modalRef.componentInstance.editMode = true;
  }

  deleteQuelle(institution: IAddressbookInstitution, quelle: AuskehrGeldquelleInterface) {
    const modalRef = this.ngbModal.open(ConfirmModalComponent);
    modalRef.componentInstance.message = `Wollen Sie die Geldquelle ${quelle.name} wirklich löschen?`;
    modalRef.componentInstance.title = 'Bestätigung';
    modalRef.result.then(
      (result) => {
        if (result) {
          this.auskehrService.deleteGeldquelle(quelle.id).subscribe(
            (nwResult) => {
              this.auskehrService.saveGeldquelleLocally(nwResult, institution.id);
            },
            (error) => {
              captureMessage('error deleting auskehr geldquelle');
              console.log(error);
            },
            () => {}
          );
        }
      }
    );
  }

  createAuskehrung(institution: IAddressbookInstitution) {
    const modalRef = this.ngbModal.open(EditAuskehrZahlungComponent);
    modalRef.componentInstance.caseId = this.activeCase.id;
    modalRef.componentInstance.institution = institution;
  }

  editAuskehrung(institution: IAddressbookInstitution, zahlung: AuskehrZahlungInterface) {
    const modalRef = this.ngbModal.open(EditAuskehrZahlungComponent);
    modalRef.componentInstance.caseId = this.activeCase.id;
    modalRef.componentInstance.institution = institution;
    modalRef.componentInstance.zahlung = zahlung;
    modalRef.componentInstance.editMode = true;
  }

  deleteAuskehrung(institution: IAddressbookInstitution, zahlung: AuskehrZahlungInterface) {
    const modalRef = this.ngbModal.open(ConfirmModalComponent);
    modalRef.componentInstance.message = `Wollen Sie die Auskehr-Zahlung ${zahlung.text} wirklich löschen?`;
    modalRef.componentInstance.title = 'Bestätigung';
    modalRef.result.then(
      (result) => {
        if (result) {
          this.auskehrService.deleteZahlung(zahlung.id).subscribe(
            (nwResult) => {
              this.auskehrService.saveZahlungLocally(nwResult, institution.id);
            },
            (error) => {
              captureMessage('error deleting auskehr zahlung');
              console.log(error, zahlung.id);
            },
            () => {}
          );
        }
      }
    );
  }

  filterDeleted(geldquellen: AuskehrGeldquelleInterface[]) {
    return geldquellen.filter((x) => !x.deleted );
  }

  filterDeletedZahlungen(zahlungen: AuskehrZahlungInterface[]) {
    return zahlungen.filter((x) => !x.deleted );
  }
  fraction(decimalNumber: number) {
    const frac = new Fraction(decimalNumber * 0.01);
      return frac.toFraction();
  }

  openUnbedenklichkeit(erbe: IStammbaumPerson) {
    const modalRef = this.ngbModal.open(EditErbenSteuerComponent);
    modalRef.componentInstance.erbe = {...erbe};
  }

  setDisplayMode(mode: string, id: number) {
    if (mode === 'alle') {
      this.notizenErbenID = null;
      this.notizenInstitutionID = null;
      this.notizenMode = mode;
    }
    if (mode === 'erbe') {
      this.notizenErbenID = id;
      this.notizenInstitutionID = null;
      this.notizenMode = mode;
    }
    if (mode === 'institution') {
      this.notizenErbenID = null;
      this.notizenInstitutionID = id;
      this.notizenMode = mode;
    }
  }

  getHeirTitel() {
    if (this.notizenMode === 'erbe' || this.notizenErbenID !== null) {
      const e = this.erben.find(x => x.id === this.notizenErbenID);
      if (e !== null) { return `${e.vorname} ${e.nachname} (ID ${e.id})`; }
    }
    return '';
  }

  getInstitutionTitel() {
    if (this.notizenMode === 'institution' || this.notizenInstitutionID !== null) {
      const i = this.institutionen.find(x => x.id === this.notizenInstitutionID);
      if (i !== null) { return `${i.name}`; }
    }
    return '';
  }

  getNoteSubject() {
    if (this.notizenMode === 'erbe') { return this.getHeirTitel(); }
    if (this.notizenMode === 'institution') { return this.getInstitutionTitel(); }
    return '';
  }

  resizeScrollArea() {
    let elem = this.scrollIn.nativeElement;
    let distance = 0;
    // Loop up the DOM
    do {
      // Increase our distance counter
      distance += elem.offsetTop;
      // Set the element to it's parent
      elem = elem.offsetParent;
    } while (elem);
    distance = distance < 0 ? 0 : distance;
    this.renderer.setStyle(this.scrollIn.nativeElement, 'height', `${window.innerHeight - distance - 10}px`);
  }

}
