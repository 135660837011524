import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {isNullOrUndefined} from "util";

@Component({
  selector: 'editfield-textarea',
  templateUrl: './editfield-textarea.component.html',
  styleUrls: ['./editfield-textarea.component.css']
})
export class EditfieldTextareaComponent implements OnInit {

  @Input() text: string = "";
  @Input() editMode: boolean = false;
  @Input() placeholder: string = "";
  @Input() maxLength: string = "";
  @Input() rows = 2;
  @Output() textChange: EventEmitter<string> = new EventEmitter<string>();
  textDisplay: string = "";

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.textDisplay = this.text;
  }
}
