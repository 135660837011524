import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {INVZStandesamt, INVZStandesamtTree} from '../namensverzeichnisse/nvz-standesamt.interface';
import {Store} from '@ngrx/store';
import {INVZState} from '../store/nvz-state.interface';
import {
  IAppNotification, NOTIFICATION_MESSAGE_LOAD_NVZ_FAILED_ERROR, NOTIFICATION_MESSAGE_LOAD_NVZ_IN_PROGRESS,
  NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS, NOTIFICATION_SLOT_NVZ,
} from '../app-notifications/app-notification.interface';
import * as AppNotificationActions from '../store/app-notification.actions';
import {ConstantsService} from './constants.service';
import * as NVZActions from '../store/nvz.actions';
import {INVZVerzeichnis} from '../namensverzeichnisse/nvz-verzeichnis.interface';
import {INVZEintrag} from '../namensverzeichnisse/nvz-eintrag.interface';
import {INVZEintragSearchresult} from '../namensverzeichnisse/nvz-eintrag-searchresult.interface';
import {INVZStadt} from '../namensverzeichnisse/nvz-stadt.interface';
import {INVZLand} from '../namensverzeichnisse/nvz-land.interface';
import {Subscription} from 'rxjs';
import * as UiActions from '../store/ui.actions';
import {MODULE_PAGES, MODULES} from '../routing/routes.model';
import * as NVZOptions from '../namensverzeichnisse/nvz-options';



@Injectable()
export class NVZService implements OnDestroy {
  activeLandId: number = null;
  staedteFirstLoad = false;
  laenderFirstLoad = false;
  standesaemterLoaded = false;
  verzeichnisseLoaded = false;
  eintraegeLoaded = false;
  nvzSub: Subscription;

  constructor(private http: HttpClient,
              private store: Store<{nvz: INVZState, appNotifications: { appNotifications: IAppNotification[]}}>,
              private constantsService: ConstantsService) {
    this.nvzSub = this.store.select('nvz').subscribe(
      (nvzState) => {
        if (nvzState && nvzState.activeLand) {
          this.activeLandId = nvzState.activeLand.id;
        } else {
          this.activeLandId = null;
        }
      }
    );
    this.getStaedte();
  }

  navigateToStandesaemter(stadt: INVZStadt) {
    this.store.dispatch(new NVZActions.SetActiveStadt(stadt));
    this.getStandesaemter(stadt.id);
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.NAMENSVERZEICHNISSE, page: MODULE_PAGES.NAMENSVERZEICHNISSE.STANDESAEMTER}));
  }

  navigateToVerzeichnisse(amt: INVZStandesamt) {
    this.store.dispatch(new NVZActions.SetActiveStandesamt(amt));
    this.getVerzeichnisse(amt.id);
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.NAMENSVERZEICHNISSE, page: MODULE_PAGES.NAMENSVERZEICHNISSE.VERZEICHNISSE}));
  }

  navigateToVerzeichnisseInTree(amt: INVZStandesamtTree) {
    this.store.dispatch(new NVZActions.SetActiveLand(amt.stadt_id.land));
    const stdt = <INVZStadt><unknown>amt.stadt_id;
    stdt.land = amt.stadt_id.land.id;
    this.store.dispatch(new NVZActions.SetActiveStadt(stdt));
    const stamt = <INVZStandesamt><unknown>amt;
    stamt.stadt_id = amt.stadt_id.id;
    this.navigateToVerzeichnisse(stamt);
  }

  navigateToStaedte(land: INVZLand) {
    this.store.dispatch(new NVZActions.SetActiveLand(land));
    this.getStaedte();
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.NAMENSVERZEICHNISSE, page: MODULE_PAGES.NAMENSVERZEICHNISSE.STAEDTE}));
  }

  navigateToLaender() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.NAMENSVERZEICHNISSE, page: MODULE_PAGES.NAMENSVERZEICHNISSE.LAENDER}));
  }

  createLand(land: INVZLand) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS));
    return this.http.post(`${this.constantsService.getApiEndpoint()}/v3/nvz-land/`, land, {headers: this.constantsService.getHttpOptions()});
  }

  updateLand(land: INVZLand) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS));
    return this.http.patch(`${this.constantsService.getApiEndpoint()}/v3/nvz-land/${land.id}/`, land, {headers: this.constantsService.getHttpOptions()});
  }

  deleteLand(land: INVZLand) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS));
    return this.http.delete(`${this.constantsService.getApiEndpoint()}/v3/nvz-land/${land.id}/`, {headers: this.constantsService.getHttpOptions()});
  }

  getLaender() {
    this.laenderFirstLoad = false;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_NVZ_IN_PROGRESS));
    const url = `${this.constantsService.getApiEndpoint()}/v3/nvz-land/`;
    this.http.get<INVZLand[]>(url, {headers: this.constantsService.getHttpOptions()}).
      subscribe(
      (response) => {
        this.store.dispatch(new NVZActions.ReplaceLaender(response));
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
        this.laenderFirstLoad = true;
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_NVZ_FAILED_ERROR));
      },
      () => {}
    );
  }

  createStadt(stadt: INVZStadt) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS));
    return this.http.post(`${this.constantsService.getApiEndpoint()}/v3/nvz-stadt/`, stadt, {headers: this.constantsService.getHttpOptions()});
  }

  updateStadt(stadt: INVZStadt) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS));
    return this.http.patch(`${this.constantsService.getApiEndpoint()}/v3/nvz-stadt/${stadt.id}/`, stadt, {headers: this.constantsService.getHttpOptions()});
  }

  deleteStadt(stadt: INVZStadt) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS));
    return this.http.delete(`${this.constantsService.getApiEndpoint()}/v3/nvz-stadt/${stadt.id}/`, {headers: this.constantsService.getHttpOptions()});
  }

  getStaedte() {
    this.staedteFirstLoad = false;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_NVZ_IN_PROGRESS));
    let url = '';
    if (this.activeLandId) {
      url = `${this.constantsService.getApiEndpoint()}/v3/nvz-stadt/?land_id=${this.activeLandId}`;
    } else {
      url = `${this.constantsService.getApiEndpoint()}/v3/nvz-stadt/`;
    }
    this.http.get<INVZStadt[]>(url, {headers: this.constantsService.getHttpOptions()}).
      subscribe(
      (response) => {
        this.store.dispatch(new NVZActions.ReplaceStaedte(response));
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
        this.staedteFirstLoad = true;
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_NVZ_FAILED_ERROR));
      },
      () => {}
    );
  }

  createStandesamt(standesamt: INVZStandesamt) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS));
    return this.http.post(`${this.constantsService.getApiEndpoint()}/v3/nvz-standesamt/`, standesamt, {headers: this.constantsService.getHttpOptions()});
  }

  updateStandesamt(standesamt: INVZStandesamt) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS));
    return this.http.patch(`${this.constantsService.getApiEndpoint()}/v3/nvz-standesamt/${standesamt.id}/`, standesamt, {headers: this.constantsService.getHttpOptions()});
  }

  deleteStandesamt(standesamt: INVZStandesamt) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS));
    return this.http.delete(`${this.constantsService.getApiEndpoint()}/v3/nvz-standesamt/${standesamt.id}/`, {headers: this.constantsService.getHttpOptions()});
  }

  getStandesaemter(stadt_id = 0) {
    this.standesaemterLoaded = false;
    let url: string;
    if (stadt_id === 0) {
      url = `${this.constantsService.getApiEndpoint()}/v3/nvz-standesamt/`;
    } else {
      url = `${this.constantsService.getApiEndpoint()}/v3/nvz-standesamt/stadt_id=${stadt_id}/`;
    }
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_NVZ_IN_PROGRESS));
    this.http.get<INVZStandesamt[]>(url, {headers: this.constantsService.getHttpOptions()}).
      subscribe(
      (response) => {
        this.store.dispatch(new NVZActions.ReplaceStandesaemter(response));
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
        this.standesaemterLoaded = true;
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_NVZ_FAILED_ERROR));
      },
      () => {}
    );
  }

  createVerzeichnis(verzeichnis: INVZVerzeichnis) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS));
    return this.http.post(`${this.constantsService.getApiEndpoint()}/v3/nvz-verzeichnis/`, verzeichnis, {headers: this.constantsService.getHttpOptions()});
  }

  updateVerzeichnis(verzeichnis: INVZVerzeichnis) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS));
    return this.http.patch(`${this.constantsService.getApiEndpoint()}/v3/nvz-verzeichnis/${verzeichnis.id}/`, verzeichnis, {headers: this.constantsService.getHttpOptions()});
  }

  deleteVerzeichnis(verzeichnis: INVZVerzeichnis) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS));
    return this.http.delete(`${this.constantsService.getApiEndpoint()}/v3/nvz-verzeichnis/${verzeichnis.id}/`, {headers: this.constantsService.getHttpOptions()});
  }

  getVerzeichnisse(amt_id = 0) {
    this.verzeichnisseLoaded = false;
    let url: string;
    if (amt_id === 0) {
      url = `${this.constantsService.getApiEndpoint()}/v3/nvz-verzeichnis/`;
    } else {
      url = `${this.constantsService.getApiEndpoint()}/v3/nvz-verzeichnis/standesamt_id=${amt_id}/`;
    }
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_NVZ_IN_PROGRESS));
    this.http.get<INVZVerzeichnis[]>(url, {headers: this.constantsService.getHttpOptions()}).
      subscribe(
      (response) => {
        this.store.dispatch(new NVZActions.ReplaceVerzeichnisse(response));
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
        this.verzeichnisseLoaded = true;
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_NVZ_FAILED_ERROR));
      },
      () => {}
    );
  }

  checkVerzeichnis(verzeichnis_id = 0) {
    const url = `${this.constantsService.getApiEndpoint()}/v3/nvz-check/verzeichnis_id=${verzeichnis_id}/`;
    return this.http.get<{total: number, first: number, last: number, double: any, multiples: any, missing: any}>(url, {headers: this.constantsService.getHttpOptions()});
  }

  removeUserFromVerzeichnis(verzeichnis: INVZVerzeichnis, user: number) {
    const url = 'mode=remove&verzeichnis_id=' + verzeichnis.id + '&user_id=' + user;
    return this.updateUserRightsVerzeichnis(url);
  }

  addUserToVerzeichnis(verzeichnis: INVZVerzeichnis, user: number) {
    const url = 'mode=add&verzeichnis_id=' + verzeichnis.id + '&user_id=' + user;
    return this.updateUserRightsVerzeichnis(url);
  }

  updateUserRightsVerzeichnis(url: string) {
    url = `${this.constantsService.getApiEndpoint()}/v3/nvz-vz-user-management/?${url}`;
    return this.http.get(url, {headers: this.constantsService.getHttpOptions()});
  }

  createEintrag(eintrag: INVZEintrag) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS));
    return this.http.post<INVZEintrag>(`${this.constantsService.getApiEndpoint()}/v3/nvz-eintrag/`, eintrag, {headers: this.constantsService.getHttpOptions()});
  }

  updateEintrag(eintrag: INVZEintrag) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS));
    return this.http.patch<INVZEintrag>(`${this.constantsService.getApiEndpoint()}/v3/nvz-eintrag/${eintrag.id}/`, eintrag, {headers: this.constantsService.getHttpOptions()});
  }

  deleteEintrag(eintrag: INVZEintrag) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS));
    return this.http.delete(`${this.constantsService.getApiEndpoint()}/v3/nvz-eintrag/${eintrag.id}/`, {headers: this.constantsService.getHttpOptions()});
  }

  getEintraege(verzeichnis_id: number = 0) {
    this.eintraegeLoaded = false;
    let url: string;
    if (verzeichnis_id == 0) {
      url = `${this.constantsService.getApiEndpoint()}/v3/nvz-eintrag/`;
    } else {
      url = `${this.constantsService.getApiEndpoint()}/v3/nvz-eintrag/verzeichnis_id=${verzeichnis_id}/`;
    }
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_NVZ_IN_PROGRESS));
    this.http.get<INVZEintrag[]>(url, {headers: this.constantsService.getHttpOptions()}).
      subscribe(
      (response) => {
        this.store.dispatch(new NVZActions.ReplaceNVZEintrage(response));
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
        this.eintraegeLoaded = true;
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_NVZ_FAILED_ERROR));
      },
      () => {}
    );
  }

  searchEintraege(searchstring: string = "") {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_NVZ_IN_PROGRESS));
    let url: string;
    if (searchstring) {
      url = `${this.constantsService.getApiEndpoint()}/v3/nvz-search/?${searchstring}`;
    } else {
      url = `${this.constantsService.getApiEndpoint()}/v3/nvz-search/`;
    }
    return this.http.get<INVZEintragSearchresult[]>(url, {headers: this.constantsService.getHttpOptions()});
  }

  showZufallsfundEintraege() {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_NVZ_IN_PROGRESS));
    const url = `${this.constantsService.getApiEndpoint()}/v3/nvz-zufall/`;
    return this.http.get<INVZEintragSearchresult[]>(url, {headers: this.constantsService.getHttpOptions()});
  }

  searchNVZStadt(stadt_searchstring: string, country_id = -1) {
    let url: string;
    if (country_id === -1) {
      url = `${this.constantsService.getApiEndpoint()}/v3/nvz-stadt-search/?search=${stadt_searchstring}`;
    } else {
      url = `${this.constantsService.getApiEndpoint()}/v3/nvz-stadt-search/?search=${stadt_searchstring}&country_id=${country_id}`;
    }
    return this.http.get<{id: number, name: string}[]>(url, {headers: this.constantsService.getHttpOptions()});
  }

  searchNVZStandesamt(standesamt_searchstring: string, stadt_searchstring: string, bundesland_searchstring: string) {
    const url = `${this.constantsService.getApiEndpoint()}/v3/nvz-standesamt-search/?search=${standesamt_searchstring}&search_stadt=${stadt_searchstring}&search_bundesland=${bundesland_searchstring}`;
    return this.http.get<INVZStandesamtTree[]>(url, {headers: this.constantsService.getHttpOptions()});
  }

  showVerzeichnisArt(artId: number) {
    const options = NVZOptions.NVZUrkundenArt;
    const option = options.find(x => +x.id === +artId);
    if (option) {
      return option.name;
    }
    return '--';
  }

  ngOnDestroy(): void {
    this.nvzSub.unsubscribe();
  }
}
