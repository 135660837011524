import {Component, OnInit, Input, HostListener, SimpleChanges, OnChanges} from '@angular/core';
import {Store} from '@ngrx/store';
import {INVZState} from '../../store/nvz-state.interface';
import {INVZStandesamt} from '../nvz-standesamt.interface';
import * as NVZOptions from '../nvz-options';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NVZStandesaemterFormComponent} from '../nvz-standesaemter-form/nvz-standesaemter-form.component';
import {IUser} from '../../models/user.interface';
import {NVZService} from '../../services/nvz.service';
import {
  IAppNotification, NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR,
  NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS, NOTIFICATION_SLOT_NVZ
} from '../../app-notifications/app-notification.interface';
import * as AppNotificationActions from '../../store/app-notification.actions';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';
import {UserService} from '../../services/user.service';
import {RightsManagementService} from '../../services/rights-management.service';

@Component({
  selector: 'nvz-standesaemter',
  templateUrl: './nvz-standesaemter.component.html',
  styleUrls: ['./nvz-standesaemter.component.css']
})
export class NvzStandesaemterComponent implements OnInit, OnChanges {
  @HostListener('window:keydown', ['$event'])
  @Input() nvzState: INVZState;
  @Input() user: IUser;
  filterByFirstLetter: string = null;
  filterByString = '';
  copiedStandesaemter: INVZStandesamt[] = [];

  keyEvent(event: KeyboardEvent) {
    if ((event.key === 'Insert' && this.rightsManagementService.isAllowedToEditAllNVZEntries())) {
      this.addAmt();
    }
  }

  constructor(private store: Store<{nvz: INVZState, appNotifications: { appNotifications: IAppNotification[]}}>,
              private modalService: NgbModal,
              public nvzService: NVZService,
              public userService: UserService,
              public rightsManagementService: RightsManagementService) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.nvzState) {
      if (changes.nvzState.currentValue !== changes.nvzState.previousValue) {
        this.copyAndRunFilters();
      }
    }
  }

  filterLetter($event) {
    this.filterByFirstLetter = $event;
    this.copyAndRunFilters();
  }

  filterBy($event) {
    this.filterByString = $event;
    this.copyAndRunFilters();
  }

  copyAndRunFilters() {
    let standesaemter: INVZStandesamt[] = this.nvzState.standesaemter;
    if (this.filterByFirstLetter) {
      standesaemter = standesaemter.filter(x => x.name[0] === this.filterByFirstLetter);
    }
    if (this.filterByString) {
      standesaemter = standesaemter.filter(x => {
        if (x.name.toUpperCase().includes(this.filterByString.toUpperCase())) {
          return true;
        }
        if (x.schluessel.toUpperCase().includes(this.filterByString.toUpperCase())) {
          return true;
        }
        return false;
      });
    }
    this.copiedStandesaemter = standesaemter;
  }

  showAmtStatus(statusId: number) {
    let options = NVZOptions.NVZStandesamtStatusOptions;
    let option = options.find(x => x.id == statusId);
    if (option) {
      return option.name;
    }
    return "---";
  }

  addAmt() {
    const modalRef = this.modalService.open(NVZStandesaemterFormComponent);
    modalRef.componentInstance.editMode = false;
    modalRef.result.then(
      (result) => {if (result) {this.save(result, false)}},
      () => {}
    );
  }

  editAmt(amt: INVZStandesamt) {
    const modalRef = this.modalService.open(NVZStandesaemterFormComponent);
    modalRef.componentInstance.amt = amt;
    modalRef.componentInstance.editMode = true;
    modalRef.result.then(
      (result) => {if (result) {this.save(result, true)}},
      () => {}
    );
  }

  deleteAmt(amt: INVZStandesamt) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.message = "Wollen Sie dieses Standesamt mit all seinen Verzeichnissen und Einträgen wirklich löschen?";
    modalRef.componentInstance.title = "Bestätigung";
    modalRef.result.then(
      (result) => {
        if (result) {
          this.nvzService.deleteStandesamt(amt).subscribe(
            (result) => {
              this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
              this.nvzService.getStandesaemter(this.nvzState.activeStadt.id);
            },
            (error) => {
              this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
              this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR));
              this.nvzService.getStandesaemter(this.nvzState.activeStadt.id);
            },
            () => {}
          );
        }
      }
    );
  }

  selectAmt(amt: INVZStandesamt) {
    this.nvzService.navigateToVerzeichnisse(amt);
  }

  save(amt: INVZStandesamt, editMode: boolean) {
    if (editMode) { // change existing
      let amtToChange: INVZStandesamt = this.nvzState.standesaemter.find(x => x.id === amt.id);
      if (amtToChange) {
        amtToChange = amt;
        this.nvzService.updateStandesamt(amt).subscribe(
        (result) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.nvzService.getStandesaemter(this.nvzState.activeStadt.id);
        },
        (error) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR));
          this.nvzService.getStandesaemter(this.nvzState.activeStadt.id);
        },
        () => {}
      );
      }
    } else { // create new, if it is a new one, set owner and city id
      amt.stadt_id = this.nvzState.activeStadt.id;
      amt.owner_id = this.user.id;
      this.nvzState.standesaemter.push(amt);
      this.nvzService.createStandesamt(amt).subscribe(
        (result) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.nvzService.getStandesaemter(this.nvzState.activeStadt.id);
        },
        (error) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR));
          this.nvzService.getStandesaemter(this.nvzState.activeStadt.id);
        },
        () => {}
      );
    }
  }
}
