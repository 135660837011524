import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {ConstantsService} from "./constants.service";
import {IAddressbookInstitution} from "../addressbook/addressbook-institution.interface";
import {IAddressbookPerson} from "../addressbook/addressbook-person.interface";
import {IAddressbookEntryState} from "../store/addressbook-entry-state.interface";
import * as AddressbookEntryActions from "../store/addressbook-entry.actions";
import {IAddressbookContact} from "../addressbook/addressbook-contact.interface";
import {IAddressbookAddress} from "../addressbook/addressbook-address.interface";

@Injectable()
export class AddressbookService {

  institutionTypesObs: Observable<{id: number, name: string}>; // todo move into interface
  contactTypesObs: Observable<{id: number, name: string}>;
  addressRegionsObs: Observable<{id: number, name: string}>;

  constructor(private http: HttpClient,
              private store: Store<IAddressbookEntryState>,
              private constantsService: ConstantsService)
  { // todo remove/refactor
    this.institutionTypesObs = this.http.get<{id: number, name: string}>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-institution-types/`, {headers: this.constantsService.getHttpOptions()});
    this.contactTypesObs = this.http.get<{id: number, name: string}>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-contact-types/`, {headers: this.constantsService.getHttpOptions()});
    this.addressRegionsObs = this.http.get<{id: number, name: string}>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-address-regions/`, {headers: this.constantsService.getHttpOptions()});
  }

  // Methods for Institutions

  typeaheadSearch(param: string) {
     return this.http.post<{institutions: IAddressbookInstitution[], persons: IAddressbookPerson[]}>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-search/`, {'q': param},{headers: this.constantsService.getHttpOptions()});
  }

  typeaheadSearchForDocumentRecipient(param: string) {
     return this.http.get<IAddressbookInstitution[]>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-search-for-recipient/?q=${param}`, {headers: this.constantsService.getHttpOptions()});
  }

  loadInstitutionFromServer(id: number) {
    this.http.get<IAddressbookInstitution>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-institution-details-flat/${id}/`, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (entry: IAddressbookInstitution) => {
        this.store.dispatch(new AddressbookEntryActions.SetActiveAddressbookEntry(entry));
      }
    );
  }

  getInstitutionDataDirectly(id: number) {
    return this.http.get<IAddressbookInstitution>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-institution-details-flat/${id}/`, {headers: this.constantsService.getHttpOptions()});
  }

  create(institution: IAddressbookInstitution) {
    return this.http.post<IAddressbookInstitution>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-create/`, institution,{headers: this.constantsService.getHttpOptions()});
  }

  update(institution: IAddressbookInstitution) {
    return this.http.post<IAddressbookInstitution>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-update/`, institution,{headers: this.constantsService.getHttpOptions()});
  }

  delete(institution_id: number) {
    return this.http.post<any>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-delete/`, institution_id,{headers: this.constantsService.getHttpOptions()});
  }

  // Methods for Addresses

  createAddress(address: IAddressbookAddress) {
    return this.http.post<IAddressbookAddress>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-create-address/`, address,{headers: this.constantsService.getHttpOptions()});
  }

  deleteAddress(address_id: number) {
    return this.http.post<any>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-delete-address/`, address_id,{headers: this.constantsService.getHttpOptions()});
  }

  updateAddress(address: IAddressbookAddress) {
    return this.http.post<IAddressbookAddress>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-update-address/`, address,{headers: this.constantsService.getHttpOptions()});
  }

  getAddress(address_id: number) {
    return this.http.get<IAddressbookAddress>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-show-address/${address_id}/`, {headers: this.constantsService.getHttpOptions()});
  }

  // Methods for Persons

  createPerson(person: IAddressbookPerson) {
    return this.http.post<IAddressbookPerson>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-create-person/`, person,{headers: this.constantsService.getHttpOptions()});
  }

  deletePerson(person_id: number) {
    return this.http.post<any>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-delete-person/`, person_id,{headers: this.constantsService.getHttpOptions()});
  }

  updatePerson(person: IAddressbookPerson) {
    return this.http.post<IAddressbookPerson>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-update-person/`, person,{headers: this.constantsService.getHttpOptions()});
  }

  getPerson(person_id: number) {
    return this.http.get<IAddressbookPerson>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-show-person/${person_id}/`, {headers: this.constantsService.getHttpOptions()});
  }

  // Methods for Contacts

   createContact(contact: IAddressbookContact) {
    return this.http.post<IAddressbookContact>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-create-contact/`, contact,{headers: this.constantsService.getHttpOptions()});
  }

  deleteContact(contact_id: number) {
    return this.http.post<any>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-delete-contact/`, contact_id,{headers: this.constantsService.getHttpOptions()});
  }

  updateContact(contact: IAddressbookContact) {
    return this.http.post<IAddressbookContact>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-update-contact/`, contact,{headers: this.constantsService.getHttpOptions()});
  }

  getContact(contact_id: number) {
    return this.http.get<IAddressbookContact>(`${this.constantsService.getApiEndpoint()}/v2/addressbook-show-contact/${contact_id}/`, {headers: this.constantsService.getHttpOptions()});
  }
}
