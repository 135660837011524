import {Action} from "@ngrx/store";
import {IAuthUser} from "../models/auth-user.interface";

export const SET_AUTH_USER_DATA = 'SET_AUTH_USER_DATA';
export const CLEAR_AUTH_USER_DATA = 'CLEAR_AUTH_USER_DATA';
export const UPDATE_LAST_ACTION_TIMESTAMP = 'UPDATE_LAST_ACTION_TIMESTAMP';

export class SetAuthUserData implements Action {
  readonly type = SET_AUTH_USER_DATA;
  constructor(public payload: IAuthUser) {}
}
export class ClearAuthUserData implements Action {
  readonly type = CLEAR_AUTH_USER_DATA;
  constructor() {}
}
export class UpdateLastActionTimestamp implements Action {
  readonly type = UPDATE_LAST_ACTION_TIMESTAMP;
  constructor(public payload: number) {}
}

export type AuthUserActions = SetAuthUserData | ClearAuthUserData | UpdateLastActionTimestamp;
