import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {CaseDisplayOpenable, CaseSaveMode, CaseService} from '../../services/case.service';
import {Store} from '@ngrx/store';
import {IUi} from '../../models/ui.interface';
import {ICaseState} from '../../store/case-state.interface';
import {RightsManagementService} from '../../services/rights-management.service';
import {ICase} from '../case.interface';
import {ICaseStatus} from '../../models/case-status.interface';
import {IUserState} from '../../store/user-state.interface';
import {IUser} from '../../models/user.interface';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {StammbaumService} from '../../services/stammbaum.service';
import * as CaseActions from '../../store/case.actions';


@Component({
  selector: 'case-detail-max',
  templateUrl: './case-detail-max.component.html',
  styleUrls: ['./case-detail-max.component.css']
})
export class CaseDetailMaxComponent implements OnInit, OnDestroy {
  mode = 1;  // active tab
  editMode: CaseSaveMode = CaseSaveMode.Kein_Modus_gewaehlt;
  displayOpened: CaseDisplayOpenable[] = [];
  noteScrollPosition = {x: 0, y: 0};

  activeCaseId: number;
  activeCaseSub: Subscription;
  activeCase: ICase;
  copiedActiveCase: ICase;
  caseStatus: ICaseStatus[];
  caseStatusSub: Subscription;
  usersStateSub: Subscription;
  users: IUser[];


  @Input() printablePersongroups: string[];
  @Input() ui;

  id: number;

  stammbaumSub: Subscription;
  stammbaumState: IStammbaumState;

  constructor(private hotkeysService: HotkeysService,
              private ngbModal: NgbModal,
              private caseService: CaseService,
              private stammbaumService: StammbaumService,
              private router: Router,
              private store: Store<{ ui: IUi, caseStatus: ICaseStatus[], cases: ICaseState, users: IUserState }>,
              public rightsManagementService: RightsManagementService,
              public userService: UserService) {

    this.stammbaumSub = this.store.select('stammbaum').subscribe(
      (stammbaumState) => {
        this.stammbaumState = stammbaumState;
      });

    const keys = [
      {key: 'alt+g', command: 1, description: ''},
      {key: 'alt+n', command: 2, description: ''},
      {key: 'alt+s', command: 3, description: ''},
      {key: 'alt+p', command: 4, description: ''},
      {key: 'alt+e', command: 5, description: ''},
      {key: 'alt+t', command: 6, description: ''},
      {key: 'alt+d', command: 7, description: ''},
      {key: 'alt+m', command: 8, description: ''},
      {key: 'alt+b', command: 9, description: ''},
    ];
    for (const hk of keys) {
      this.hotkeysService.add(new Hotkey(hk.key, (event: KeyboardEvent): boolean => {
        this.changeMode(hk.command);
        return false; // Prevent bubbling
      }, undefined, hk.description));
    }
    this.hotkeysService.add(new Hotkey('esc', (event: KeyboardEvent): boolean => {
      this.caseService.goToCaseSearch();
      return false; // Prevent bubbling
    }, undefined, 'zurück zur Fall-Liste'));
    this.hotkeysService.add(new Hotkey('right', (event: KeyboardEvent): boolean => {
      this.increaseMode();
      return false; // Prevent bubbling
    }, undefined, 'nach rechts'));
    this.hotkeysService.add(new Hotkey('left', (event: KeyboardEvent): boolean => {
      this.decreaseMode();
      return false; // Prevent bubbling
    }, undefined, 'nach links'));


    this.caseStatusSub = this.store.select('caseStatus').subscribe(
      (caseStatus) => {
        this.caseStatus = caseStatus;
      }
    );
    this.usersStateSub = this.store.select('users').subscribe(
      (usersState) => {
        this.users = usersState.users;
      }
    );
    this.activeCaseSub = this.store.select('cases').subscribe(
      (cases) => {
        if (this.editMode !== CaseSaveMode.Kein_Modus_gewaehlt) {
          // if we are in edit mode, check if this case has changed, and if it has, notify user
          // todo implement JSON.stringify - compare the 2 objects, if changed then notify user via modal about server-side changes and ask to reload or discard
        } else { // if we are not in any edit mode, simply update
          this.activeCase = cases.activeCase;
          this.activeCaseId = cases.activeCaseId;
          this.mode = cases.activeCaseDetailTab;
          this.copiedActiveCase = {...cases.activeCase};
        }
      }
    );
  }

  ngOnInit() {
    this.store.dispatch(new StammbaumActions.SetStammbaumMode('person-list'));
    this.store.dispatch(new StammbaumActions.StammbaumSetTreeViewMode('edit-person-data'));
  }

  hasTrueStammbaum() {
    return this.stammbaumService.hasTrueStammbaum();
  }

  noteScrollPositionChanged($event) {
    this.noteScrollPosition = {x: $event.x, y: $event.y};
  }

  changeEditMode(mode: CaseSaveMode) {
    this.editMode = mode;
  }

  changeOpened(item: CaseDisplayOpenable) {
    if (this.displayOpened.find(x => x === item)) {
      this.displayOpened = this.displayOpened.filter(x => x !== item);
    } else {
      this.displayOpened.push(item);
    }
  }

  changeMode(mode: number) {
    if (mode === 3 && !this.hasTrueStammbaum()) { return; } // can't access this mode if there is no stammbaum
    if (!this.editMode) { // only switch display mode if edit mode is none
      this.store.dispatch(new CaseActions.SetActiveCaseDetailTab(mode));
    }
  }

  increaseMode() {
    if (!this.editMode) {
      if (this.mode < 9) {
        // check if Stammbaum can be accessed
        if (this.mode + 1 === 3 && !this.hasTrueStammbaum()) {
          this.store.dispatch(new CaseActions.SetActiveCaseDetailTab(this.mode + 2));
        } else {
          this.store.dispatch(new CaseActions.SetActiveCaseDetailTab(this.mode + 1));
        }
      } else {
        this.store.dispatch(new CaseActions.SetActiveCaseDetailTab(1));
      }
    }
  }

  decreaseMode() {
    if (!this.editMode) {
      if (this.mode > 1) {
        if (this.mode - 1 === 3 && !this.hasTrueStammbaum()) {
          this.store.dispatch(new CaseActions.SetActiveCaseDetailTab(this.mode - 2));
        } else {
          this.store.dispatch(new CaseActions.SetActiveCaseDetailTab(this.mode - 1));
        }
      } else {
        this.store.dispatch(new CaseActions.SetActiveCaseDetailTab(9));
      }
    }
  }

  ngOnDestroy() {
    this.activeCaseSub.unsubscribe();
    this.caseStatusSub.unsubscribe();
    this.usersStateSub.unsubscribe();
    this.stammbaumSub.unsubscribe();
  }

}
