import * as FileUploadActions from './file-upload.actions';
import {IFileUploadState} from "./file-upload-state.interface";


const initialState: IFileUploadState = {
  fileUploadsForCurrentCase: [],
  fileUploadsForCurrentPerson: []
};

export function fileUploadReducer(state = initialState, action: FileUploadActions.FileUploadActions) {
  switch(action.type) {

    case FileUploadActions.REPLACE_FILE_UPLOADS_FOR_CURRENT_CASE:
      return {
        ...state,
        fileUploadsForCurrentCase: action.payload
      };

    case FileUploadActions.REPLACE_FILE_UPLOADS_FOR_CURRENT_PERSON:
      return {
        ...state,
        fileUploadsForCurrentPerson: action.payload
      };

    default:
      return state;
  }
}
