import {Component, OnInit} from '@angular/core';
import {IStammbaumMarriage} from '../stammbaum-marriage.interface';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {IStammbaum} from '../stammbaum.interface';

@Component({
  selector: 'stammbaum-tree-edit-marriage',
  templateUrl: './stammbaum-tree-edit-marriage.component.html',
  styleUrls: ['./stammbaum-tree-edit-marriage.component.css']
})
export class StammbaumTreeEditMarriageComponent implements OnInit {
  marriage: IStammbaumMarriage;
  form: FormGroup;

  constructor(private ngbActiveModal: NgbActiveModal,
              private store: Store<{stammbaum: IStammbaum}>,
              private ngbModal: NgbModal) {
    this.form = new FormGroup({
      'needs_hu': new FormControl(false),
      'needs_hu_but_do_without': new FormControl(false),
      'ordered_hu': new FormControl(false),
      'ordered_hu_user': new FormControl(''),
      'ordered_hu_date': new FormControl(''),
      'kommentar': new FormControl(''),
      'date': new FormControl(''),
      'trauungsdatum': new FormControl(''),
      'trauungsinfo': new FormControl(''),
      'divorced': new FormControl(false),
      'divorced_date': new FormControl(''),
      'aussereheliche_partnerschaft': new FormControl(false)
    });
  }

  ngOnInit() {
    this.form.patchValue({
      'needs_hu': this.marriage.needs_hu,
      'needs_hu_but_do_without': this.marriage.needs_hu_but_do_without,
      'ordered_hu': this.marriage.ordered_hu,
      'ordered_hu_user': this.marriage.ordered_hu_user,
      'ordered_hu_date': this.marriage.ordered_hu_date,
      'kommentar': this.marriage.kommentar,
      'date': this.marriage.date,
      'trauungsdatum': this.marriage.trauungsdatum,
      'trauungsinfo': this.marriage.trauungsinfo,
      'divorced': this.marriage.divorced,
      'divorced_date': this.marriage.divorced_date,
      'aussereheliche_partnerschaft': this.marriage.aussereheliche_partnerschaft
    });
  }

  cancel() {
    this.ngbActiveModal.close();
  }

  save() {
    this.store.dispatch(new StammbaumActions.TriggerEditMarriageInActiveStammbaum(<IStammbaumMarriage>{
      date: this.form.controls.date.value,
      kommentar: this.form.controls.kommentar.value,
      needs_hu: this.form.controls.needs_hu.value,
      needs_hu_but_do_without: this.form.controls.needs_hu_but_do_without.value,
      ordered_hu: this.form.controls.ordered_hu.value,
      ordered_hu_user: this.form.controls.ordered_hu_user.value,
      ordered_hu_date: this.form.controls.ordered_hu_date.value,
      divorced: this.form.controls.divorced.value,
      divorced_date: this.form.controls.divorced_date.value,
      trauungsdatum: this.form.controls.trauungsdatum.value,
      trauungsinfo: this.form.controls.trauungsinfo.value,
      aussereheliche_partnerschaft: this.form.controls.aussereheliche_partnerschaft.value,
      id: this.marriage.id
    }));
    this.ngbActiveModal.close();
  }
}
