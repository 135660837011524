import {Component, OnDestroy, OnInit} from '@angular/core';
import { UserService } from '../../services/user.service';
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {IUser} from "../../models/user.interface";
import {IUserState} from "../../store/user-state.interface";
import {IUserRole} from "../../models/userrole.interface";
import {IUi} from "../../models/ui.interface";
import {MODULE_PAGES, MODULES} from "../../routing/routes.model";
import * as UiActions from "../../store/ui.actions";
import {IAuthUser} from "../../models/auth-user.interface";
import {RightsManagementService} from "../../services/rights-management.service";
import {ConstantsService} from "../../services/constants.service";
import {NotifyModalComponent} from '../../tools/notify-modal/notify-modal.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {UserChangeSeniorComponent} from '../user-change-senior/user-change-senior.component';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap/modal/modal-config';

@Component({
  selector: 'user-profile-show',
  templateUrl: './user-profile-show.component.html',
  styleUrls: ['./user-profile-show.component.css']
})
export class UserProfileShowComponent implements OnInit, OnDestroy {

  authUserId: number;
  authUserSub: Subscription;
  copiedUser: IUser;
  userSub: Subscription;
  users: IUserState;
  userRoles: IUserRole[];
  userRolesSub: Subscription;
  printableUserRoles: string[] = [];

  pwdChanging = false;
  pwdChangeComplete = false;
  pwdChangeError = false;
  uploadingImage = false;
  imageUploaded = false;
  imageUploadError = false;
  pwdDiffer = false;
  pwdTooEasy = false;
  editMode = false;
  editPasswordMode = false;
  oldPwd = '';
  newPwd = '';
  newPwdRepeat = '';
  isAllowedToSeeAllUserData = false;
  profile_path = '';
  genderOptions = [];


  constructor(protected userService: UserService,
              protected rightsManagementService: RightsManagementService,
              private constantsService: ConstantsService,
              private modalService: NgbModal,
              private store: Store<{ui: IUi, users: IUserState, userRoles: IUserRole[], authUser: {authUser: IAuthUser}}>)
  {
    this.genderOptions = this.userService.getUserGenderOptions();
    this.profile_path = this.constantsService.getApiEndpoint() + '/v3/get-profile-pic/?user_id=';
    this.isAllowedToSeeAllUserData = this.rightsManagementService.isAllowedToSeeAllUserData();
    this.authUserSub = this.store.select('authUser').subscribe(
      (authUser) => {
        if (authUser.authUser.user) {
          this.authUserId = authUser.authUser.user.id;
        } else {
          this.authUserId = null;
        }
      }
    );
    this.userSub = this.store.select('users').subscribe(
      (users) => {
        this.users = users;
        const activeUserId: number = users.activeUser;
        if (!this.editMode) { // only copy data if not in edit mode
          this.copiedUser = {...users.users.find(x => x.id == activeUserId)};
        }
      }
    );
    this.userRolesSub = this.store.select('userRoles').subscribe(
      (userRoles) => {
        this.userRoles = [...userRoles];
        for (const userRole of userRoles) {
          this.printableUserRoles[+userRole.id] = userRole.description;
        }
      });
  }

  ngOnInit() {}

  changeSenior() {
    const modalRef: NgbModalRef = this.modalService.open(UserChangeSeniorComponent, <NgbModalOptions>{size: 'sm', backdrop: 'static'});
    modalRef.componentInstance.user = this.copiedUser;
  }

  backToList() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.USERS, page: MODULE_PAGES.USERS.USER_LIST}));
  }

  editProfile() {
    this.editMode = true;
  }

  editPassword() {
    this.editPasswordMode = true;
  }

  cancelEdit() {
    this.editMode = false;
    const activeUserId: number = this.users.activeUser;
    this.copiedUser = {...this.users.users.find(x => x.id == activeUserId)};
  }

  cancelEditPassword() {
    if (this.pwdChanging) {
      return;
    }
    this.editPasswordMode = false;
    this.pwdTooEasy = false;
    this.pwdDiffer = false;
    this.oldPwd = '';
    this.newPwd = '';
    this.newPwdRepeat = '';
    this.pwdChangeComplete = false;
  }

  save() {
    this.editMode = false;
    this.userService.save(this.copiedUser);
  }

  changeSorting() {
    this.copiedUser.note_reply_sort_ascending = !this.copiedUser.note_reply_sort_ascending;
    this.save();
  }

  changePassword() {
    if (this.pwdChanging) {
      return;
    }
    this.pwdDiffer = false;
    this.pwdTooEasy = false;
    this.pwdChangeComplete = false;
    if (this.newPwd != this.newPwdRepeat) {
      this.pwdDiffer = true;
      return;
    }
    if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(this.newPwd)) { // at least 8 chars, min 1 upper, 1 lower, 1 digit
      this.pwdTooEasy = true;
      return;
    }
    this.pwdDiffer = false;
    this.pwdTooEasy = false;
    this.pwdChangeError = false;
    this.pwdChanging = true;
    this.userService.changePassword(this.copiedUser, this.oldPwd, this.newPwd)
      .subscribe(
        (data) => this.pwdChanged(),
        (err) => this.pwdChangeFailed(err)
      );
  }

  imageUpload(event) {
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
      this.uploadingImage = true;
      this.imageUploaded = false;
      this.imageUploadError = false;
      this.userService.uploadImage(fileList, this.copiedUser).subscribe(
        (result) => this.doneImageUpload(),
        (err) => this.errorImageUpload(err)
      );
    }
  }

  doneImageUpload() {
    this.uploadingImage = false;
    this.imageUploaded = true;
    this.imageUploadError = false;
  }

  errorImageUpload(err) {
    this.uploadingImage = false;
    this.imageUploaded = false;
    this.imageUploadError = true;
  }

  saved() {
  }

  pwdChanged() {
    this.pwdChanging = false;
    this.pwdChangeError = false;
    this.pwdChangeComplete = true;
    this.oldPwd = '';
    this.newPwd = '';
    this.newPwdRepeat = '';
    this.editPasswordMode = false;
  }

  pwdChangeFailed(err) {
    this.pwdChanging = false;
    this.pwdChangeError = true;
    this.pwdChangeComplete = false;
    this.oldPwd = '';
    this.newPwd = '';
    this.newPwdRepeat = '';
  }

  setSaveError(err) {
    console.log(err);
  }

  ngOnDestroy() {
    this.userRolesSub.unsubscribe();
    this.userSub.unsubscribe();
    this.authUserSub.unsubscribe();
  }
}
