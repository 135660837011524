import {IStammbaumPerson} from '../stammbaum/stammbaum-person.interface';
import {Action} from '@ngrx/store';
import {IUrkundenFileRefForPerson} from '../stammbaum/urkunden-file-ref.interface';
import {IStammbaumMarriage} from '../stammbaum/stammbaum-marriage.interface';
import {IStammbaum} from '../stammbaum/stammbaum.interface';
import {IStammbaumVersion} from '../stammbaum/stammbaum-version.interface';
import {IStammbaumConnections} from '../stammbaum/stammbaum-connections.interface';

export const REPLACE_STAMMBAUM_VERSIONS = 'REPLACE_STAMMBAUM_VERSIONS';
export const REPLACE_ACTIVE_STAMMBAUM = 'REPLACE_ACTIVE_STAMMBAUM';
export const REPLACE_ACTIVE_STAMMBAUM_PERSONS = 'REPLACE_ACTIVE_STAMMBAUM_PERSONS';
export const REPLACE_ACTIVE_STAMMBAUM_MARRIAGES = 'REPLACE_ACTIVE_STAMMBAUM_MARRIAGES';
export const REPLACE_ACTIVE_STAMMBAUM_CONNECTIONS = 'REPLACE_ACTIVE_STAMMBAUM_CONNECTIONS';
export const REPLACE_STAMMBAUM_PERSONS_AND_MARRIAGES_WITHOUT_VERSION_FOR_CASE = 'REPLACE_STAMMBAUM_PERSONS_AND_MARRIAGES_WITHOUT_VERSION_FOR_CASE';
export const TRIGGER_LOAD_STAMMBAUM_CHECKPOINT = 'TRIGGER_LOAD_STAMMBAUM_CHECKPOINT';
export const TRIGGER_SAVE_STAMMBAUM_CHECKPOINT = 'TRIGGER_SAVE_STAMMBAUM_CHECKPOINT';
export const TRIGGER_LOAD_STAMMBAUM_VERSION = 'TRIGGER_LOAD_STAMMBAUM_VERSION';
export const TRIGGER_LOAD_STAMMBAUM_VERSIONS_FOR_CASE = 'TRIGGER_LOAD_STAMMBAUM_VERSIONS_FOR_CASE';
export const TRIGGER_LOAD_STAMMBAUM_PERSONS_AND_MARRIAGES_WITHOUT_VERSION_FOR_CASE = 'TRIGGER_LOAD_STAMMBAUM_PERSONS_AND_MARRIAGES_WITHOUT_VERSION_FOR_CASE';
export const TRIGGER_ADD_PERSON_TO_ACTIVE_STAMMBAUM = 'TRIGGER_ADD_PERSON_TO_ACTIVE_STAMMBAUM';
export const TRIGGER_ADD_PERSON_WITHOUT_VERSION = 'TRIGGER_ADD_PERSON_WITHOUT_VERSION';
export const TRIGGER_ADD_MARRIAGE_TO_ACTIVE_STAMMBAUM = 'TRIGGER_ADD_MARRIAGE_TO_ACTIVE_STAMMBAUM'; // old
export const TRIGGER_ADD_MARRIAGE_WITHOUT_VERSION = 'TRIGGER_ADD_MARRIAGE_WITHOUT_VERSION';
export const TRIGGER_ADD_MARRIAGE_AND_PERSON_TO_ACTIVE_STAMMBAUM = 'TRIGGER_ADD_MARRIAGE_AND_PERSON_TO_ACTIVE_STAMMBAUM'; // new
export const TRIGGER_EDIT_PERSON_IN_ACTIVE_STAMMBAUM = 'TRIGGER_EDIT_PERSON_IN_ACTIVE_STAMMBAUM';
export const TRIGGER_EDIT_PERSON_WITHOUT_VERSION = 'TRIGGER_EDIT_PERSON_WITHOUT_VERSION';
export const TRIGGER_EDIT_MARRIAGE_IN_ACTIVE_STAMMBAUM = 'TRIGGER_EDIT_MARRIAGE_IN_ACTIVE_STAMMBAUM';
export const TRIGGER_EDIT_MARRIAGE_WITHOUT_VERSION = 'TRIGGER_EDIT_MARRIAGE_WITHOUT_VERSION';
export const REPLACE_PERSON_FOR_UPLOAD = 'REPLACE_PERSON_FOR_UPLOAD';
export const REPLACE_MARRIAGE_FOR_UPLOAD = 'REPLACE_MARRIAGE_FOR_UPLOAD';
export const REPLACE_URKUNDE_TO_EDIT = 'REPLACE_URKUNDE_TO_EDIT';
export const REPLACE_PERSON_TO_EDIT_ID = 'REPLACE_PERSON_TO_EDIT_ID';
export const REPLACE_MARRIAGE_TO_EDIT_ID = 'REPLACE_MARRIAGE_TO_EDIT_ID';
export const TRIGGER_SET_STAMMBAUM_PERSON_ACTIVE = 'TRIGGER_SET_STAMMBAUM_PERSON_ACTIVE';
export const TRIGGER_SET_STAMMBAUM_PERSON_INACTIVE = 'TRIGGER_SET_STAMMBAUM_PERSON_INACTIVE';
export const TRIGGER_DELETE_STAMMBAUM_PERSON = 'TRIGGER_DELETE_STAMMBAUM_PERSON';
export const TRIGGER_DELETE_STAMMBAUM_MARRIAGE = 'TRIGGER_DELETE_STAMMBAUM_MARRIAGE';
export const TRIGGER_ADD_CHILD = 'TRIGGER_ADD_CHILD';
export const SET_STAMMBAUM_MODE = 'SEND_STAMMBAUM_MODE';
export const STAMMBAUM_DISPLAY_ZOOM_IN = 'SEND_STAMMBAUM_DISPLAY_ZOOM_IN';
export const STAMMBAUM_DISPLAY_ZOOM_OUT = 'SEND_STAMMBAUM_DISPLAY_ZOOM_OUT';
export const STAMMBAUM_DISPLAY_SET_SHOW_KASTEN = 'STAMMBAUM_DISPLAY_SET_SHOW_KASTEN';
export const STAMMBAUM_DISPLAY_TOGGLE_SHOW_KASTEN = 'STAMMBAUM_DISPLAY_TOGGLE_SHOW_KASTEN';
export const STAMMBAUM_SCROLL_TO_POS = 'STAMMBAUM_SCROLL_TO_POS';
export const STAMMBAUM_EXECUTE_PRINT = 'STAMMBAUM_EXECUTE_PRINT';
export const STAMMBAUM_SET_TREE_VIEW_MODE = 'STAMMBAUM_SET_TREE_VIEW_MODE';
export const TRIGGER_RECALCULATE_DISPLAY = 'TRIGGER_RECALCULATE_DISPLAY';
export const TRIGGER_SET_STAMMBAUM_VERSION_AS_DEFAULT_AND_LOAD = 'TRIGGER_SET_STAMMBAUM_VERSION_AS_DEFAULT_AND_LOAD';


export class TriggerLoadStammbaumVersion implements Action {
  readonly type = TRIGGER_LOAD_STAMMBAUM_VERSION;
  constructor(public payload: IStammbaumVersion) {}
}

export class TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase implements Action {
  readonly type = TRIGGER_LOAD_STAMMBAUM_PERSONS_AND_MARRIAGES_WITHOUT_VERSION_FOR_CASE;
  constructor(public payload: number) {} // the id of the case for which it should be loaded
}

export class ReplaceStammbaumPersonsAndMarriagesWithoutVersionForCase implements Action {
  readonly type = REPLACE_STAMMBAUM_PERSONS_AND_MARRIAGES_WITHOUT_VERSION_FOR_CASE;
  constructor(public payload: {persons: IStammbaumPerson[], marriages: IStammbaumMarriage[]}) {}
}

export class TriggerSetStammbaumVersionAsDefaultAndLoad implements Action {
  readonly type = TRIGGER_SET_STAMMBAUM_VERSION_AS_DEFAULT_AND_LOAD;
  constructor(public payload: IStammbaumVersion) {}
}

export class TriggerSaveStammbaumCheckpoint implements Action {
  readonly type = TRIGGER_SAVE_STAMMBAUM_CHECKPOINT;
  constructor(public payload: {version_id: number, title: string, comment: string}) {}
}

export class TriggerLoadStammbaumCheckpoint implements Action {
  readonly type = TRIGGER_LOAD_STAMMBAUM_CHECKPOINT;
  constructor(public payload: number) {}
}

export class TriggerLoadStammbaumVersionsForCase implements Action {
  readonly type = TRIGGER_LOAD_STAMMBAUM_VERSIONS_FOR_CASE;
  constructor(public payload: boolean) {} // payload indicates if to proceed directly to reload default version (T) or only version list (F)
}

export class TriggerAddPersonToActiveStammbaum implements Action {
  readonly type = TRIGGER_ADD_PERSON_TO_ACTIVE_STAMMBAUM;
  constructor(public payload: IStammbaumPerson) {}
}

export class TriggerAddPersonWithoutVersion implements Action {
  readonly type = TRIGGER_ADD_PERSON_WITHOUT_VERSION;
  constructor(public payload: IStammbaumPerson) {}
}

// todo this will have to be removed when adding persons is handled via Stammbaum
export class TriggerAddMarriageToActiveStammbaum implements Action {
  readonly type = TRIGGER_ADD_MARRIAGE_TO_ACTIVE_STAMMBAUM;
  constructor(public payload: IStammbaumMarriage) {}
}

export class TriggerAddMarriageWithoutVersion implements Action {
  readonly type = TRIGGER_ADD_MARRIAGE_WITHOUT_VERSION;
  constructor(public payload: IStammbaumMarriage) {}
}

// this is the new one when marriage is added via stammbaum
export class TriggerAddMarriageAndPersonToActiveStammbaum implements Action {
  readonly type = TRIGGER_ADD_MARRIAGE_AND_PERSON_TO_ACTIVE_STAMMBAUM;
  constructor(public payload: {person: IStammbaumPerson, marriage: IStammbaumMarriage}) {}
}

export class TriggerAddChild implements Action {
  readonly type = TRIGGER_ADD_CHILD;
  constructor(public payload: {first_parent_id: number, second_parent_id: number, child_data: IStammbaumPerson}) {}
}

export class TriggerRecalculateDisplay implements Action {
  readonly type = TRIGGER_RECALCULATE_DISPLAY;
  constructor() {}
}

export class TriggerEditPersonInActiveStammbaum implements Action {
  readonly type = TRIGGER_EDIT_PERSON_IN_ACTIVE_STAMMBAUM;
  constructor(public payload: IStammbaumPerson) {}
}

export class TriggerEditPersonWithoutVersion implements Action {
  readonly type = TRIGGER_EDIT_PERSON_WITHOUT_VERSION;
  constructor(public payload: IStammbaumPerson) {}
}

export class TriggerEditMarriageInActiveStammbaum implements Action {
  readonly type = TRIGGER_EDIT_MARRIAGE_IN_ACTIVE_STAMMBAUM;
  constructor(public payload: IStammbaumMarriage) {}
}

export class TriggerEditMarriageWithoutVersion implements Action {
  readonly type = TRIGGER_EDIT_MARRIAGE_WITHOUT_VERSION;
  constructor(public payload: IStammbaumMarriage) {}
}

export class ReplacePersonForUpload implements Action {
  readonly type = REPLACE_PERSON_FOR_UPLOAD;
  constructor(public payload: IStammbaumPerson) {}
}

export class ReplacePersonToEditId implements Action {
  readonly type = REPLACE_PERSON_TO_EDIT_ID;
  constructor(public payload: number) {}
}

export class ReplaceMarriageToEditId implements Action {
  readonly type = REPLACE_MARRIAGE_TO_EDIT_ID;
  constructor(public payload: number) {}
}

export class ReplaceMarriageForUpload implements Action {
  readonly type = REPLACE_MARRIAGE_FOR_UPLOAD;
  constructor(public payload: IStammbaumMarriage) {}
}

export class ReplaceUrkundeToEdit implements Action {
  readonly type = REPLACE_URKUNDE_TO_EDIT;
  constructor(public payload: IUrkundenFileRefForPerson) {}
}

export class ReplaceStammbaum implements Action {
  readonly type = REPLACE_ACTIVE_STAMMBAUM;
  constructor(public payload: IStammbaum) {}
}

export class ReplaceStammbaumVersions implements Action {
  readonly type = REPLACE_STAMMBAUM_VERSIONS;
  constructor(public payload: IStammbaumVersion[]) {}
}

export class ReplaceActiveStammbaumConnections implements Action {
  readonly type = REPLACE_ACTIVE_STAMMBAUM_CONNECTIONS;
  constructor(public payload: IStammbaumConnections) {}
}

export class ReplaceActiveStammbaumPersons implements Action {
  readonly type = REPLACE_ACTIVE_STAMMBAUM_PERSONS;
  constructor(public payload: IStammbaumPerson[]) {}
}

export class ReplaceActiveStammbaumMarriages implements Action {
  readonly type = REPLACE_ACTIVE_STAMMBAUM_MARRIAGES;
  constructor(public payload: IStammbaumMarriage[]) {}
}

export class TriggerSetStammbaumPersonActive implements Action {
  readonly type = TRIGGER_SET_STAMMBAUM_PERSON_ACTIVE;
  constructor(public payload: number) {}
}

export class TriggerSetStammbaumPersonInactive implements Action {
  readonly type = TRIGGER_SET_STAMMBAUM_PERSON_INACTIVE;
  constructor(public payload: number) {}
}

export class TriggerDeleteStammbaumPerson implements Action {
  readonly type = TRIGGER_DELETE_STAMMBAUM_PERSON;
  constructor(public payload: number) {}
}

export class TriggerDeleteStammbaumMarriage implements Action {
  readonly type = TRIGGER_DELETE_STAMMBAUM_MARRIAGE;
  constructor(public payload: number) {}
}

export class SetStammbaumMode implements Action {
  readonly type = SET_STAMMBAUM_MODE;
  constructor(public payload: string) {}
}

export class StammbaumDisplayZoomIn implements Action {
  readonly type = STAMMBAUM_DISPLAY_ZOOM_IN;
  constructor() {}
}

export class StammbaumDisplayZoomOut implements Action {
  readonly type = STAMMBAUM_DISPLAY_ZOOM_OUT;
  constructor() {}
}

export class StammbaumDisplaySetShowKasten implements Action {
  readonly type = STAMMBAUM_DISPLAY_SET_SHOW_KASTEN;
  constructor(public payload: boolean) {}
}

export class StammbaumDisplayToggleShowKasten implements Action {
  readonly type = STAMMBAUM_DISPLAY_TOGGLE_SHOW_KASTEN;
  constructor() {}
}

export class StammbaumExecutePrint implements Action {
  readonly type = STAMMBAUM_EXECUTE_PRINT;
}

export class StammbaumScrollToPos implements Action {
  readonly type = STAMMBAUM_SCROLL_TO_POS;
  constructor(public payload: {x: number, y: number}) {}
}

export class StammbaumSetTreeViewMode implements Action {
  readonly type = STAMMBAUM_SET_TREE_VIEW_MODE;
  constructor(public payload: string) {}
}

export type StammbaumActions =
  ReplaceActiveStammbaumPersons |
  ReplaceActiveStammbaumMarriages |
  ReplaceStammbaum |
  ReplaceStammbaumVersions |
  ReplaceActiveStammbaumConnections |
  TriggerLoadStammbaumVersion |
  TriggerLoadStammbaumCheckpoint |
  TriggerSaveStammbaumCheckpoint |
  TriggerLoadStammbaumVersionsForCase |
  TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase |
  ReplaceStammbaumPersonsAndMarriagesWithoutVersionForCase |
  TriggerAddPersonToActiveStammbaum |
  TriggerAddPersonWithoutVersion |
  TriggerAddMarriageToActiveStammbaum |
  TriggerAddMarriageWithoutVersion |
  TriggerAddMarriageAndPersonToActiveStammbaum |
  TriggerAddChild |
  TriggerEditPersonInActiveStammbaum |
  TriggerEditPersonWithoutVersion |
  TriggerEditMarriageInActiveStammbaum |
  ReplacePersonForUpload |
  ReplacePersonToEditId |
  ReplaceMarriageToEditId |
  ReplaceMarriageForUpload |
  ReplaceUrkundeToEdit |
  TriggerSetStammbaumPersonActive |
  TriggerSetStammbaumPersonInactive |
  TriggerDeleteStammbaumPerson |
  TriggerDeleteStammbaumMarriage |
  SetStammbaumMode |
  StammbaumDisplayZoomIn |
  StammbaumDisplayZoomOut |
  StammbaumDisplaySetShowKasten |
  StammbaumDisplayToggleShowKasten |
  StammbaumScrollToPos |
  StammbaumSetTreeViewMode |
  TriggerRecalculateDisplay |
  StammbaumExecutePrint |
  TriggerSetStammbaumVersionAsDefaultAndLoad;
