import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'abc-choose',
  templateUrl: './abc-choose.component.html',
  styleUrls: ['./abc-choose.component.css'],
})
export class AbcChooseComponent {
  @Output() chosen: EventEmitter<string> = new EventEmitter();
  letterChosen: string = null;

  letters: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  clicked(letter: string) {
    if (letter == this.letterChosen) {
      this.chosen.emit(null);
      this.letterChosen = null;
    } else {
      this.chosen.emit(letter);
      this.letterChosen = letter;
    }
  }
}
