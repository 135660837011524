import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {Store} from '@ngrx/store';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {IStammbaumVersion} from '../stammbaum-version.interface';
import {StammbaumService} from '../../services/stammbaum.service';

@Component({
  selector: 'stammbaum-version-select',
  templateUrl: './stammbaum-version-select.component.html',
  styleUrls: ['./stammbaum-version-select.component.css']
})
export class StammbaumVersionSelectComponent {
  stammbaumState: IStammbaumState;

  constructor(private ngbActiveModal: NgbActiveModal,
              private store: Store<{stammbaum: IStammbaumState}>,
              private stammbaumService: StammbaumService) {}

  close() {
    this.ngbActiveModal.close();
  }

  changeStb(version: IStammbaumVersion) {
    this.store.dispatch(new StammbaumActions.TriggerSetStammbaumVersionAsDefaultAndLoad(version));
    this.close();
  }

  createStb(mode) {
    this.store.dispatch(new StammbaumActions.ReplaceStammbaum(null));
    this.stammbaumService.createNewStammbaum(mode);
    this.ngbActiveModal.close();
  }
}
