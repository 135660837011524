import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import { ITeamMember } from '../../team-member.interface';
import { TeamMemberService } from '../../../services/team-member.service';
import {ITeamMemberState} from '../../../store/team-member-state.interface';
import {UserService} from '../../../services/user.service';


@Component({
  selector: 'team-icons',
  templateUrl: './team-icons.component.html',
  styleUrls: ['./team-icons.component.css']
})
export class TeamIconsComponent implements OnInit, OnDestroy {
  teamMembers: ITeamMember[] = [];
  teamMembersSub: Subscription;

  constructor(private teamMemberService: TeamMemberService,
              public userService: UserService,
              private store: Store<{teamMembers: ITeamMemberState}>) { }

  ngOnInit() {
    this.teamMembersSub = this.store.select('teamMembers').subscribe(
      (teamMembers) => {
        this.teamMembers = teamMembers.teamMembersForCurrentCase.filter(x => !x.deleted);
      });
  }

  ngOnDestroy() {
    this.teamMembersSub.unsubscribe();
  }
}












