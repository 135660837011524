import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {IUi} from "../models/ui.interface";
import * as UiActions from "../store/ui.actions";
import {IModuleRoute} from "./module-route.interface";

@Injectable()
export class RoutingService {

  constructor(private store: Store<{ui: IUi}>) {}

  navigateToModule(route: IModuleRoute) {
    this.store.dispatch(new UiActions.SetActiveModule(route));
  }
}


