import {Injectable, OnDestroy} from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import {Store} from '@ngrx/store';
import { HttpClient} from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { AuthenticationService } from './authentication.service';
import { ITeamMember } from '../cases/team-member.interface';
import {INetworkState} from '../store/network-state.interface';
import * as NetworkActions from '../store/network.actions';
import {ICaseState} from '../store/case-state.interface';
import * as TeamMemberActions from '../store/team-member.actions';
import {IAuthUser} from '../models/auth-user.interface';
import {
  NOTIFICATION_MESSAGE_TEAMMEMBER_LOAD_ERROR,
  NOTIFICATION_MESSAGE_TEAMMEMBER_LOAD_TIMEOUT,
  NOTIFICATION_MESSAGE_TEAMMEMBER_LOADING, NOTIFICATION_MESSAGE_TEAMMEMBER_SAVE_ERROR,
  NOTIFICATION_MESSAGE_TEAMMEMBER_SAVE_TIMEOUT,
  NOTIFICATION_MESSAGE_TEAMMEMBER_SAVING,
  NOTIFICATION_SLOT_TEAMMEMBER
} from '../app-notifications/app-notification.interface';
import * as AppNotificationActions from '../store/app-notification.actions';
import {ITeamMemberState} from '../store/team-member-state.interface';

const TRY_RELOAD_INTERVAL = 10 * 1000; // 10 Sekunden

@Injectable()
export class TeamMemberService implements OnDestroy {

  network: INetworkState;
  networkSub: Subscription;
  authUser: IAuthUser;
  authUserSub: Subscription;
  activeCaseId: number;
  casesSub: Subscription;
  reloadTimerSub: Subscription;
  teamMemberForCaseLoadingInProgress = false;
  teamMemberSub: Subscription;
  teamMembersForCurrentCase: ITeamMember[];

  constructor(private constantsService: ConstantsService,
              private http: HttpClient,
              private store: Store<{teamMembers: ITeamMemberState, network: INetworkState, cases: ICaseState, authUser: {authUser: IAuthUser}}>,
              private authenticationService: AuthenticationService)
  {
    this.casesSub = this.store.select('cases').subscribe((cases) => {this.activeCaseId = cases.activeCaseId;});
    this.networkSub = this.store.select('network').subscribe((network) => { this.network = network});
    this.authUserSub = this.store.select('authUser').subscribe((authUser) => { this.authUser = authUser.authUser});
    this.reloadTimerSub = timer(0, TRY_RELOAD_INTERVAL).subscribe(t => {
      if (this.network.updateNeededTeamMemberForCase && this.authUser.loggedIn && this.network.isConnectedToServer) { // if user is logged in, reload is neccessary and there is a connection
        this.getTeamMemberForCaseFromServer();
      }
    });
    this.teamMemberSub = this.store.select('teamMembers').subscribe(
      (teamMemberState) => { this.teamMembersForCurrentCase = teamMemberState.teamMembersForCurrentCase; }
    );
  }

  triggerTeamMemberForCaseReloadFromServer() {
    if (this.activeCaseId) {
      this.store.dispatch(new NetworkActions.SetUpdateNeededTeamMemberForCaseTrue());
      this.getTeamMemberForCaseFromServer();
    }
  }

  getTeamMemberForCaseFromServer() {
    if (this.teamMemberForCaseLoadingInProgress) {
      return; // if loading already in progress, ignore!
    }
    this.teamMemberForCaseLoadingInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_TEAMMEMBER_LOADING));
    this.http.get<ITeamMember[]>(`${this.constantsService.getApiEndpoint()}/v2/team-members-for-case/${this.activeCaseId}/`, {headers: this.constantsService.getHttpOptions()}).
      subscribe(
      (response) => {
        this.store.dispatch(new TeamMemberActions.ReplaceTeamMemberListForCurrentCase(response));
        this.store.dispatch(new NetworkActions.SetUpdateNeededTeamMemberForCaseFalse());
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
        this.teamMemberForCaseLoadingInProgress = false;
      },
      (error) => {
        this.teamMemberForCaseLoadingInProgress = false;
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_TEAMMEMBER_LOAD_ERROR));
      },
      () => {
        if (this.teamMemberForCaseLoadingInProgress) {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_TEAMMEMBER_LOAD_TIMEOUT));
          this.teamMemberForCaseLoadingInProgress = false;
        }
      }
    );
  }


  create(teamMember: ITeamMember): Observable<any> {
    const body = JSON.stringify(teamMember);
    return this.http.post(`${this.constantsService.getApiEndpoint()}/team_create/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  save(teamMember: ITeamMember): Observable<any> {
    const body = JSON.stringify(teamMember);
    return this.http.patch(`${this.constantsService.getApiEndpoint()}/team_update/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  delete(teamMemberId: number): Observable<any> {
    return this.http.get(`${this.constantsService.getApiEndpoint()}/team_delete/${teamMemberId}/`, {headers: this.constantsService.getHttpOptions()});
  }

  ngOnDestroy() {
    this.casesSub.unsubscribe();
    this.reloadTimerSub.unsubscribe();
    this.networkSub.unsubscribe();
    this.authUserSub.unsubscribe();
    this.teamMemberSub.unsubscribe();
  }

}


