import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';


const DATE_INPUT_VALUE_ACCESSOR: any = {
provide : NG_VALUE_ACCESSOR,
useExisting: forwardRef(() => DateInputFormComponent),
multi : true,
};

@Component({
  selector : 'date-input-form',
  templateUrl: './date-input-form.component.html',
  styleUrls: ['./date-input-form.component.css'],
  providers : [DATE_INPUT_VALUE_ACCESSOR],
})
export class DateInputFormComponent implements ControlValueAccessor {
  @Input() displayOnly;
  text: string;
  dateDisplay: string;
  cssClass: string;
  disabled: boolean;
  private onChange: Function;
  private onTouched: Function;

  constructor() {
    this.onChange = (_: any) => {
    };
    this.onTouched = () => {};
    this.disabled = false;
  }

  public setValue(value: string) {
    this.text = value;
    this.onChange(value);
    this.onTouched();
    this.parse(value);
  }

  parse(value: string) {
    if (!value || value === '') { // an empty string
      this.setDateEmpty();
      return;
    }

    if (value.substring(0, 1) === '#') { // a comment
      this.setComment(value.slice(1));
      return;
    }

    if (/^(?!\s*$)[0-9]{8}$/.test(value)) { // just a normal date
      let day: string = value.substring(0, 2);
      let month: string = value.substring(2, 4);
      let year: string = value.substring(4, 8);
      if (this.isValidDate(day, month, year)) {
        this.setDateOK(day, month, year);
        return;
      }
    }

    if (/^(?!\s*$)[\?]{1}[0-9]{8}$/.test(value)) { // a date that is uncertain
      let day: string = value.substring(1, 3);
      let month: string = value.substring(3, 5);
      let year: string = value.substring(5, 9);
      if (this.isValidDate(day, month, year)) {
        this.setDateUncertain(day, month, year);
        return;
      }
    }

    if (/^(?!\s*$)[0-9]{8}[+]{1}[0-9]{8}$/.test(value)) { // two alternatives for the date
      let day_begin: string = value.substring(0, 2);
      let month_begin: string = value.substring(2, 4);
      let year_begin: string = value.substring(4, 8);
      let day_end: string = value.substring(9, 11);
      let month_end: string = value.substring(11, 13);
      let year_end: string = value.substring(13, 17);
      if (this.isValidDate(day_begin, month_begin, year_begin) && this.isValidDate(day_end, month_end, year_end)) {
        this.setDateAlternatives(day_begin, month_begin, year_begin, day_end, month_end, year_end);
        return;
      }
    }

    if (/^(?!\s*$)[\?]{1}[0-9]{8}[+]{1}[0-9]{8}$/.test(value)) { // two alternatives for the date that are uncertain
      let day_begin: string = value.substring(1, 3);
      let month_begin: string = value.substring(3, 5);
      let year_begin: string = value.substring(5, 9);
      let day_end: string = value.substring(10, 12);
      let month_end: string = value.substring(12, 14);
      let year_end: string = value.substring(14, 18);
      if (this.isValidDate(day_begin, month_begin, year_begin) && this.isValidDate(day_end, month_end, year_end)) {
        this.setDateAlternativesUncertain(day_begin, month_begin, year_begin, day_end, month_end, year_end);
        return;
      }
    }

    if (/^(?!\s*$)[0-9]{8}[\-]{1}[0-9]{8}$/.test(value)) { // a period
      let day_begin: string = value.substring(0, 2);
      let month_begin: string = value.substring(2, 4);
      let year_begin: string = value.substring(4, 8);
      let day_end: string = value.substring(9, 11);
      let month_end: string = value.substring(11, 13);
      let year_end: string = value.substring(13, 17);

      if (this.isValidDate(day_begin, month_begin, year_begin) && this.isValidDate(day_end, month_end, year_end)) {
        if (this.isBefore(day_begin, month_begin, year_begin, day_end, month_end, year_end)) {
          this.setDatePeriod(day_begin, month_begin, year_begin, day_end, month_end, year_end);
          return;
        }
      }
    }

     if (/^(?!\s*$)[\?]{1}[0-9]{8}[\-]{1}[0-9]{8}$/.test(value)) { // a period that is uncertain
      let day_begin: string = value.substring(1, 3);
      let month_begin: string = value.substring(3, 5);
      let year_begin: string = value.substring(5, 9);
      let day_end: string = value.substring(10, 12);
      let month_end: string = value.substring(12, 14);
      let year_end: string = value.substring(14, 18);

      if (this.isValidDate(day_begin, month_begin, year_begin) && this.isValidDate(day_end, month_end, year_end)) {
        if (this.isBefore(day_begin, month_begin, year_begin, day_end, month_end, year_end)) {
          this.setDatePeriodUncertain(day_begin, month_begin, year_begin, day_end, month_end, year_end);
          return;
        }
      }
    }

    this.setDateError();
    return;
  }

  isValidDate (day: string, month: string, year: string) {
    if (+day < 1 || +month < 1 || +year < 1) { return false; }
    if (+month > 12) { return false; }
    if (+year < 1800 || +year > 2200) { return false; }
    if (+day > 31) { return false; }
    if (+day > 29 && +month === 2) { return false; }
    if (+day > 30 && (+month === 4 || +month === 6 || +month === 9 || +month === 11)) { return false; }
    return true;
  }

  isBefore(day_begin: string, month_begin: string, year_begin: string, day_end: string, month_end: string, year_end: string) {
    if (+year_end > +year_begin) { return true; }
    if (+year_end === +year_begin && +month_end > +month_begin) { return true; }
    if (+year_end === +year_begin && +month_end === +month_begin && +day_end > +day_begin) { return true; }
    return false;
  }

  setDateOK(day: string, month: string, year: string) {
    this.dateDisplay = day + '.' + month + '.' + year;
    this.cssClass = 'ok';
  }

  setDateUncertain(day: string, month: string, year: string) {
    this.dateDisplay = 'vermutlich ' + day + '.' + month + '.' + year;
    this.cssClass = 'uncertain';
  }

  setDatePeriod(day_begin: string, month_begin: string, year_begin: string, day_end: string, month_end: string, year_end: string) {
    this.dateDisplay = day_begin + '.' + month_begin + '.' + year_begin + ' - ' + day_end + '.' + month_end + '.' + year_end;
    this.cssClass = 'period';
  }

  setDatePeriodUncertain(day_begin: string, month_begin: string, year_begin: string, day_end: string, month_end: string, year_end: string) {
    this.dateDisplay = 'vermutlich ' + day_begin + '.' + month_begin + '.' + year_begin + ' - ' + day_end + '.' + month_end + '.' + year_end;
    this.cssClass = 'uncertain';
  }

  setDateAlternatives(day_begin: string, month_begin: string, year_begin: string, day_end: string, month_end: string, year_end: string) {
    this.dateDisplay = day_begin + '.' + month_begin + '.' + year_begin + ' oder ' + day_end + '.' + month_end + '.' + year_end;
    this.cssClass = 'alternatives';
  }

  setDateAlternativesUncertain(day_begin: string, month_begin: string, year_begin: string, day_end: string, month_end: string, year_end: string) {
    this.dateDisplay = 'vermutlich ' + day_begin + '.' + month_begin + '.' + year_begin + ' oder ' + day_end + '.' + month_end + '.' + year_end;
    this.cssClass = 'uncertain';
  }

  setDateError() {
    this.dateDisplay = '<<fehlerhaft>>';
    this.cssClass = 'error';
  }

  setDateEmpty() {
    this.dateDisplay = '<<unbekannt>>';
    this.cssClass = 'empty';
  }

  setComment(text: string) {
    this.dateDisplay = text;
    this.cssClass = 'comment';
  }

  writeValue(obj: any): void {
    this.text = obj;
    this.parse(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
