import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import * as CaseActions from './case.actions';
import {map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {NoteService} from '../services/note.service';
import * as NoteActions from './note.actions';
import {INoteState} from './note-state.interface';
import {TeamMemberService} from '../services/team-member.service';
import {FileUploadService} from '../services/file-upload.service';
import * as TeamMemberActions from './team-member.actions';
import * as FileUploadActions from './file-upload.actions';
import {ITeamMemberState} from './team-member-state.interface';
import {IFileUploadState} from './file-upload-state.interface';
import {CaseService} from '../services/case.service';
import {ICaseState} from './case-state.interface';
import * as StammbaumActions from './stammbaum.actions';
import {StammbaumService} from '../services/stammbaum.service';

@Injectable()
export class CaseEffects {
  @Effect({dispatch: false})
  loadStammbaumForCase = this.actions.pipe(
    ofType<CaseActions.SetActiveCase>(CaseActions.SET_ACTIVE_CASE), // if active case is set, then trigger reload stammbaum for it
    map((action) => {
      this.store.dispatch(new StammbaumActions.ReplaceActiveStammbaumPersons([])); // clear stammbaum persons for case
      this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersionsForCase(true));
      this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(action.payload.id));
    }));

  @Effect({dispatch: false})
  loadNotesForCase = this.actions.pipe(
    ofType<CaseActions.SetActiveCase>(CaseActions.SET_ACTIVE_CASE), // if active case is set, then trigger reload of notes, files and team members for it
    map((action) => {
      this.store.dispatch(new NoteActions.ReplaceNotesListForCurrentCase([])); // clear note list
      this.store.dispatch(new TeamMemberActions.ReplaceTeamMemberListForCurrentCase([])); // clear team member list
      this.store.dispatch(new FileUploadActions.ReplaceFileUploadsForCurrentCase([])); // clear file upload list
      this.noteService.triggerNotesForCaseReloadFromServer();
      this.teamMemberService.triggerTeamMemberForCaseReloadFromServer();
      this.fileUploadService.triggerFileUploadForCaseReloadFromServer();
    }));

  @Effect({dispatch: false})
  loadNotesForPerson = this.actions.pipe(ofType(CaseActions.SET_ACTIVE_PERSON), // if active case is set, then trigger reload of notes for it
    map((payload) => {
      this.store.dispatch(new NoteActions.ReplaceNotesListForCurrentPerson([])); // clear note list
      this.store.dispatch(new FileUploadActions.ReplaceFileUploadsForCurrentPerson([])); // clear file upload list
      this.noteService.triggerNotesForPersonReloadFromServer();
      this.fileUploadService.triggerFileUploadForPersonReloadFromServer();
    }));

  @Effect({dispatch: false})
  expandCase = this.actions.pipe(
    ofType<CaseActions.ExpandCaseInCaselist>(CaseActions.EXPAND_CASE_IN_CASELIST),
    map((action) => {
      this.store.dispatch(new CaseActions.ReplaceExpandedHeirs([]));
      if (action.payload > 0) {
        this.caseService.getExpandedHeirs(action.payload).subscribe(
          (result) => {
            this.store.dispatch(new CaseActions.ReplaceExpandedHeirs(result));
          },
          (error) => console.log(error),
          () => {
          }
        );
      }
    })
  );

  @Effect({dispatch: false})
  reloadCaseData = this.actions.pipe(ofType(CaseActions.REPLACE_CASES_IN_CASELIST), // if case list is renewed, then reload the active case data
    map((payload) => {
      this.caseService.reloadCaseDetailsForActiveCaseFromServer();
    }));

  @Effect({dispatch: false})
  triggerReloadActiveCase = this.actions.pipe(ofType(CaseActions.TRIGGER_RELOAD_ACTIVE_CASE),
    map((payload) => {
      this.caseService.reloadCaseDetailsForActiveCaseFromServer();
    }));

  constructor(private actions: Actions,
              private noteService: NoteService,
              private teamMemberService: TeamMemberService,
              private fileUploadService: FileUploadService,
              private caseService: CaseService,
              private stammbaumService: StammbaumService,
              private store: Store<{ notes: INoteState, teamMembers: ITeamMemberState, fileUploads: IFileUploadState, cases: ICaseState }>) {
  }
}


