import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup} from '@angular/forms';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import {IStammbaumMarriage} from '../stammbaum-marriage.interface';
import {Store} from '@ngrx/store';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {CaseService} from '../../services/case.service';
import {CustomUppercasePipe} from '../../tools/custom-uppercase-pipe/custom-uppercase-pipe';

@Component({
  selector: 'add-person-and_marriage',
  templateUrl: './add-person-and-marriage.component.html',
  styleUrls: ['./add-person-and-marriage.component.css']
})
export class AddPersonAndMarriageComponent implements OnInit {
  form: FormGroup;
  firstPartnerPerson: IStammbaumPerson = null;
  constructor(private ngbActiveModal: NgbActiveModal,
              private caseService: CaseService,
              private customUppercasePipe: CustomUppercasePipe,
              private store: Store<{stammbaum: IStammbaumState}>) {}

  ngOnInit() {
    this.form = new FormGroup({
      'nachname': new FormControl(''),
      'vorname': new FormControl(''),
      'geburtsname': new FormControl(''),
      'kommentar': new FormControl(''),
      'sicher_keine_nachkommen': new FormControl(false),
      'wird_gesucht': new FormControl(false),
      'geschlecht': new FormControl(0),
      'aussereheliche_partnerschaft': new FormControl(false)
    });
  }

  cancel() {
    this.ngbActiveModal.dismiss();
  }

  save() {
    const pers: IStammbaumPerson = <IStammbaumPerson>{
      nachname: this.customUppercasePipe.transform(this.form.controls.nachname.value),
      vorname: this.form.controls.vorname.value,
      geburtsname: this.form.controls.geburtsname.value,
      kommentar: this.form.controls.kommentar.value,
      sicher_keine_nachkommen: this.form.controls.sicher_keine_nachkommen.value,
      wird_gesucht: this.form.controls.wird_gesucht.value,
      geschlecht: this.form.controls.geschlecht.value
    };
    pers.nachname = pers.nachname.toLocaleUpperCase();
    const mar: IStammbaumMarriage = <IStammbaumMarriage>{
        persons: [this.firstPartnerPerson],
        aussereheliche_partnerschaft: this.form.controls.aussereheliche_partnerschaft.value
      };
    this.store.dispatch(new StammbaumActions.TriggerAddMarriageAndPersonToActiveStammbaum({person: pers, marriage: mar}));
    this.ngbActiveModal.close();
  }
}
