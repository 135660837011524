import {Component, HostListener, Input, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import {CaseService} from '../../services/case.service';
import {IStammbaumMarriage} from '../stammbaum-marriage.interface';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {EditMarriageComponent} from '../edit-marriage/edit-marriage.component';
import {Subscription} from 'rxjs';
import {StammbaumService} from '../../services/stammbaum.service';
import {CustomUppercasePipe} from '../../tools/custom-uppercase-pipe/custom-uppercase-pipe';
import {RightsManagementService} from '../../services/rights-management.service';
import {CustomFirstLetterUppercasePipe} from '../../tools/custom-first-letter-uppercase-pipe/custom-first-letter-uppercase-pipe';
import {DatesService} from '../../services/dates.service';
import {AgeCheckComponent} from "../../tools/age-check-popup/age-check.component";
import * as path from "path";

@Component({
  selector: 'edit-person-single-field',
  templateUrl: './edit-person-single-field.component.html',
  styleUrls: ['./edit-person-single-field.component.css'],
})
export class EditPersonSingleFieldComponent implements OnInit {
  @Input() editMode = false;
  @Input() enterToClose = false;
  form: FormGroup;
  person: IStammbaumPerson = null;
  mode: string = null;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.enterToClose && event.key === 'Enter') {
      this.save();
    }
  }

  constructor(private ngbActiveModal: NgbActiveModal,
              public rightsManagementService: RightsManagementService,
              private customUppercasePipe: CustomUppercasePipe,
              private customFirstLetterUppercasePipe: CustomFirstLetterUppercasePipe,
              public stammbaumService: StammbaumService,
              private datesService: DatesService,
              private ngbModal: NgbModal,
              private store: Store<{stammbaum: IStammbaumState}>) {
    this.form = new FormGroup({
      kommentar: new FormControl(''),
      kommentar_beruf: new FormControl(''),
      kommentar_ab_recherche: new FormControl(''),
      kommentar_urkunden: new FormControl(''),
      vorname: new FormControl(''),
      nachname: new FormControl(''),
      geburtsname: new FormControl(''),
      geburtsinfo: new FormControl(''),
      geburtsland: new FormControl(''),
      geburtsdatum: new FormControl(''),
      taufinfo: new FormControl(''),
      taufdatum: new FormControl(''),
      sterbeinfo: new FormControl(''),
      sterbeland: new FormControl(''),
      sterbedatum: new FormControl(''),
      gefallen: new FormControl(''),
      begraebnisinfo: new FormControl(''),
      begraebnisdatum: new FormControl(''),
      religion: new FormControl(''),
      geschlecht: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.form.patchValue({kommentar_urkunden: this.person.kommentar_urkunden});
    this.form.patchValue({kommentar_beruf: this.person.kommentar_beruf});
    this.form.patchValue({kommentar_ab_recherche: this.person.kommentar_ab_recherche});
    this.form.patchValue({kommentar: this.person.kommentar});
    this.form.patchValue({vorname: this.person.vorname});
    this.form.patchValue({nachname: this.person.nachname});
    this.form.patchValue({geburtsname: this.person.geburtsname});
    this.form.patchValue({geburtsdatum: this.person.geburtsdatum});
    this.form.patchValue({geburtsinfo: this.person.geburtsinfo});
    this.form.patchValue({geburtsland: this.person.geburtsland});
    this.form.patchValue({taufdatum: this.person.taufdatum});
    this.form.patchValue({taufinfo: this.person.taufinfo});
    this.form.patchValue({sterbeinfo: this.person.sterbeinfo});
    this.form.patchValue({sterbeland: this.person.sterbeland});
    this.form.patchValue({sterbedatum: this.person.sterbedatum});
    this.form.patchValue({gefallen: this.person.gefallen});
    this.form.patchValue({begraebnisinfo: this.person.begraebnisinfo});
    this.form.patchValue({begraebnisdatum: this.person.begraebnisdatum});
    this.form.patchValue({religion: this.person.religion});
    this.form.patchValue({geschlecht: this.person.geschlecht});
  }

  cancel() {
    this.ngbActiveModal.close();
  }

  check() {
    if (this.mode === 'death') {
      if (this.datesService.isDateReadable(this.form.controls.sterbedatum.value) && this.datesService.isDateReadable(this.person.geburtsdatum)) {
        if (this.datesService.calculateAgeAtDeath(this.person.geburtsdatum, this.form.controls.sterbedatum.value) < 12 && !this.person.sicher_keine_nachkommen) {
          const modalRefAge = this.ngbModal.open(AgeCheckComponent, {backdrop: 'static'});
          modalRefAge.componentInstance.mode = 'below12';
          modalRefAge.result.then(
            () => {
              this.person.sicher_keine_nachkommen = true;
              this.check2();
            },
            () => {
              this.check2();
            }
          )
        } else {
          this.check2();
        }
      } else {
        this.check2();
      }
    } else {
      this.check2();
    }
  }

  check2() {
    if (this.mode === 'death') {
      if (!this.person.no_more_partners && this.datesService.isPersonNachverstorben(this.form.controls.sterbedatum.value) && this.person.person_type !== 5) {
        const modalRefNachverstorben = this.ngbModal.open(AgeCheckComponent, {backdrop: 'static'});
        modalRefNachverstorben.componentInstance.mode = 'nachverstorben';
        modalRefNachverstorben.result.then(
          () => {
            this.person.person_type = 5;
            this.save();
          },
          () => {
            this.save();
          }
        )
      } else {
        this.save();
      }
    } else {
      this.save();
    }
  }

  save() {
    const patchedPerson: IStammbaumPerson = <IStammbaumPerson>{
      id: this.person.id,
      person_type: this.person.person_type,
      sicher_keine_nachkommen: this.person.sicher_keine_nachkommen
    };
    if (this.mode === 'kommentar') {
      patchedPerson.kommentar = this.form.controls.kommentar.value;
    }
    if (this.mode === 'kommentar_urkunden') {
      patchedPerson.kommentar_urkunden = this.form.controls.kommentar_urkunden.value;
    }
    if (this.mode === 'kommentar_beruf') {
      patchedPerson.kommentar_beruf = this.form.controls.kommentar_beruf.value;
    }
    if (this.mode === 'kommentar_ab_recherche') {
      patchedPerson.kommentar_ab_recherche = this.form.controls.kommentar_ab_recherche.value;
    }
    if (this.mode === 'vorname') {
      patchedPerson.vorname = this.customFirstLetterUppercasePipe.transform(this.form.controls.vorname.value);
    }
    if (this.mode === 'nachname') {
      patchedPerson.nachname = this.customUppercasePipe.transform(this.form.controls.nachname.value);
      patchedPerson.geburtsname = this.customFirstLetterUppercasePipe.transform(this.form.controls.geburtsname.value);
    }
    if (this.mode === 'birth') {
      patchedPerson.geburtsinfo = this.form.controls.geburtsinfo.value;
      patchedPerson.geburtsland = this.form.controls.geburtsland.value;
      patchedPerson.geburtsdatum = this.form.controls.geburtsdatum.value;
      patchedPerson.taufinfo = this.form.controls.taufinfo.value;
      patchedPerson.taufdatum = this.form.controls.taufdatum.value;
    }
    if (this.mode === 'death') {
      patchedPerson.sterbeinfo = this.form.controls.sterbeinfo.value;
      patchedPerson.sterbeland = this.form.controls.sterbeland.value;
      patchedPerson.sterbedatum = this.form.controls.sterbedatum.value;
      patchedPerson.begraebnisinfo = this.form.controls.begraebnisinfo.value;
      patchedPerson.begraebnisdatum = this.form.controls.begraebnisdatum.value;
      patchedPerson.gefallen = this.form.controls.gefallen.value;
    }
    if (this.mode === 'religion') {
      patchedPerson.religion = this.form.controls.religion.value;
    }
    if (this.mode === 'geschlecht') {
      patchedPerson.geschlecht = this.form.controls.geschlecht.value;
    }
    this.store.dispatch(new StammbaumActions.TriggerEditPersonInActiveStammbaum(patchedPerson));
    this.ngbActiveModal.close();
  }
}
