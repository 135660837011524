import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'editfield-text',
  templateUrl: './editfield-text.component.html',
  styleUrls: ['./editfield-text.component.css']
})
export class EditfieldTextComponent implements OnInit, OnChanges {

  @Input() text = '';
  @Input() editMode = false;
  @Input() placeholder = '';
  @Input() maxLength = '';
  @Output() textChange: EventEmitter<string> = new EventEmitter<string>();
  textDisplay = '';
  cssClass = '';

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.parse();
  }

  parse() {
    if (isNullOrUndefined(this.text)) {
      return;
    }

    if (this.text.substring(0, 1) === '!') { // a comment
      this.textDisplay = this.text.slice(1);
      this.cssClass = 'important';
      return;
    }

    if (this.text.substring(0, 1) === '?') { // a comment
      this.textDisplay = 'vermutlich ' + this.text.slice(1);
      this.cssClass = 'uncertain';
      return;
    }
    this.textDisplay = this.text;
    this.cssClass = 'normal';
  }
}
