import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'filter-field',
  templateUrl: './filter-field.component.html',
  styleUrls: ['./filter-field.component.css']
})
export class FilterFieldComponent implements AfterViewInit {
  @Input() hint = '';
  @Output() searchstring: EventEmitter<string> = new EventEmitter();
  @ViewChild('tagfield', { static: true }) tagfield: ElementRef;
  form: FormGroup;

  constructor() {
    this.form = new FormGroup({
      'text': new FormControl('')
    });
    this.form.controls.text.valueChanges.subscribe(
      (value) => {
        this.searchstring.emit(value);
      }
    );
  }

  ngAfterViewInit() {
    this.tagfield.nativeElement.focus();
  }
}
