import {Input, Component, OnInit, OnDestroy, AfterViewInit, Output, EventEmitter, HostListener} from '@angular/core';
import {Subscription} from 'rxjs';
import {CaseDisplayOpenable, CaseSaveMode, CaseService} from '../../services/case.service';
import {Store} from '@ngrx/store';
import {IUi} from '../../models/ui.interface';
import {ICaseState} from '../../store/case-state.interface';
import {RightsManagementService} from '../../services/rights-management.service';
import {ICase} from '../case.interface';
import {ICaseStatus} from '../../models/case-status.interface';
import {IUserState} from '../../store/user-state.interface';
import {IUser} from '../../models/user.interface';
import {RoutingService} from '../../routing/routing.service';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';


@Component({
  selector: 'case-detail-max-notes',
  templateUrl: './case-detail-max-notes.component.html',
  styleUrls: ['./case-detail-max-notes.component.css']
})
export class CaseDetailMaxNotesComponent implements OnInit, AfterViewInit, OnDestroy {
  activeCaseId: number;
  activeCaseSub: Subscription;
  activeCase: ICase;
  copiedActiveCase: ICase;
  caseStatus: ICaseStatus[];
  caseStatusSub: Subscription;
  usersStateSub: Subscription;
  users: IUser[];
  urkundensuchePreview = '';

  @Input() scrollPosition = {x: 0, y: 0};
  @Output() saveScrollPosition: EventEmitter<{x: number, y: number}> = new EventEmitter<{x: number, y: number}>();
  @Input() opened: CaseDisplayOpenable[] = [];
  @Output() openedChanged: EventEmitter<CaseDisplayOpenable> = new EventEmitter<CaseDisplayOpenable>();
  @Input() printablePersongroups: string[];
  @Input() ui;
  @Input() editMode: CaseSaveMode;
  @Output() editModeChanged: EventEmitter<CaseSaveMode> = new EventEmitter<CaseSaveMode>();

  CaseSaveMode = CaseSaveMode;
  CaseDisplayOpenable = CaseDisplayOpenable;

  id: number;

  parentCase: ICase = null;
  childCases: ICase[] = [];
  parentCaseEdit = '';

  constructor(private caseService: CaseService,
              private router: Router,
              private store: Store<{ui: IUi, caseStatus: ICaseStatus[], cases: ICaseState, users: IUserState}>,
              public rightsManagementService: RightsManagementService,
              private routingService: RoutingService,
              public userService: UserService) {
    this.caseStatusSub = this.store.select('caseStatus').subscribe(
      (caseStatus) => { this.caseStatus = caseStatus; }
    );
    this.usersStateSub = this.store.select('users').subscribe(
      (usersState) => {
        this.users = usersState.users;
      }
    );
    this.activeCaseSub = this.store.select('cases').subscribe(
      (cases) => {
        if (this.editMode === CaseSaveMode.Rahmendaten ||
          this.editMode === CaseSaveMode.Erblasserdaten ||
          this.editMode === CaseSaveMode.Nachlassdaten) {
          // if we are in edit mode, check if this case has changed, and if it has, notify user
          // todo implement JSON.stringify - compare the 2 objects, if changed then notify user via modal about server-side changes and ask to reload or discard
        } else { // if we are not in any edit mode, simply update
          this.activeCase = cases.activeCase;
          this.activeCaseId = cases.activeCaseId;
          this.copiedActiveCase = {...cases.activeCase};
          if (this.activeCase.urkundensuche_text) {
            this.urkundensuchePreview = this.activeCase.urkundensuche_text.slice(0, 100);
            this.urkundensuchePreview = this.urkundensuchePreview.split(/\r?\n/)[0];
            if (this.urkundensuchePreview !== this.activeCase.urkundensuche_text) {
              this.urkundensuchePreview += '...';
            }
          }
          this.parentCase = null;
          this.parentCaseEdit = '';
          this.childCases = [];
          if (cases.activeCase && cases.activeCase.child_cases && cases.activeCase.child_cases.length > 0) {
            for (const c of cases.activeCase.child_cases) {
              const ca: ICase = cases.cases.find(x => +x.id === +c);
              if (ca) {
                this.childCases.push(ca);
              }
            }
          }
          if (cases.activeCase && cases.activeCase.parent_case && cases.activeCase.parent_case > 0) {
            this.parentCaseEdit = cases.activeCase.parent_case.toString();
            this.parentCase = cases.cases.find(x => +x.id === +cases.activeCase.parent_case)
          }
        }
      }
    );
  }

  toggleOpen(item: CaseDisplayOpenable) {
    this.openedChanged.emit(item);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    window.scrollTo({top: this.scrollPosition.y, left: this.scrollPosition.x});
  }

  ngOnDestroy() {
    this.saveScrollPosition.emit({x: window.scrollX, y: window.scrollY});
    this.activeCaseSub.unsubscribe();
    this.caseStatusSub.unsubscribe();
    this.usersStateSub.unsubscribe();
  }


  setEditMode(mode: CaseSaveMode) {
    this.editModeChanged.emit(mode);
  }

  saveData() {
    this.caseService.saveCaseData(this.copiedActiveCase, this.editMode);
    this.setEditMode(CaseSaveMode.Kein_Modus_gewaehlt);
  }

  reloadData() {
    this.editModeChanged.emit(CaseSaveMode.Kein_Modus_gewaehlt);
    this.copiedActiveCase = {...this.activeCase};
  }

  displayUrkundensuche() {
    return this.opened.find(x => x === CaseDisplayOpenable.Urkundensuche);
  }

  displayFallzusammenfassung() {
    return this.opened.find(x => x === CaseDisplayOpenable.Fallzusammenfassung);
  }
}
