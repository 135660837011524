import {Action} from '@ngrx/store';
import {ICaseStatus} from '../models/case-status.interface';

export const SET_CASE_STATUS = 'SET_CASE_STATUS';

export class SetCaseStatus implements Action {
  readonly type = SET_CASE_STATUS;
  constructor(public payload: ICaseStatus[]) {}
}

export type CaseStatusActions = SetCaseStatus;
