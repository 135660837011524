import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'showDate'})
export class ShowDatePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    if (value.length === 8) {
      return `${value.substring(0, 2)}.${value.substring(2, 4)}.${value.substring(4, 8)}`;
    }
    if (value[0] === '#') {
      return value.substr(1);
    }
    return value;
  }
}
