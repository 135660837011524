import { Injectable } from '@angular/core';
import {Subscription, timer} from "rxjs";
import {Store} from '@ngrx/store';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import { ConstantsService } from './constants.service';
import { IBundesanzeiger } from '../models/bundesanzeiger.interface';
import { AuthenticationService } from './authentication.service';
import * as AppNotificationActions from "../store/app-notification.actions";
import {
  NOTIFICATION_MESSAGE_BUNDESANZEIGER_LOAD_ERROR, NOTIFICATION_MESSAGE_BUNDESANZEIGER_LOAD_TIMEOUT,
  NOTIFICATION_MESSAGE_BUNDESANZEIGER_LOADING,
  NOTIFICATION_MESSAGE_BUNDESANZEIGER_SAVE_ERROR,
  NOTIFICATION_MESSAGE_BUNDESANZEIGER_SAVE_TIMEOUT, NOTIFICATION_MESSAGE_BUNDESANZEIGER_SAVING,
  NOTIFICATION_SLOT_BUNDESANZEIGER
} from "../app-notifications/app-notification.interface";
import {IBundesanzeigerState} from "../store/bundesanzeiger-state.interface";
import * as BundesanzeigerActions from "../store/bundesanzeiger.actions";
import * as NetworkActions from "../store/network.actions";
import {INetworkState} from "../store/network-state.interface";
import {IAuthUser} from "../models/auth-user.interface";

const TRY_RELOAD_INTERVAL = 10 * 1000; // 10 Sekunden

@Injectable()
export class BundesanzeigerService {

  loadingInProgress: boolean = false;
  deletingInProgress: boolean = false;
  creatingInProgress: boolean = false;
  networkSub: Subscription;
  network: INetworkState;
  reloadTimerSub: Subscription;
  authUser: IAuthUser;
  authUserSub: Subscription;

  constructor(private authenticationService: AuthenticationService,
              private constantsService: ConstantsService,
              private http: HttpClient,
              private store: Store<{bundesanzeiger: IBundesanzeigerState, network: INetworkState, authUser: {authUser: IAuthUser}}>)
  {
    this.networkSub = this.store.select('network').subscribe((network) => { this.network = network});
    this.authUserSub = this.store.select('authUser').subscribe((authUser) => {this.authUser = authUser.authUser});
    this.reloadTimerSub = timer(0, TRY_RELOAD_INTERVAL).subscribe(t => {
      if(this.network.updateNeededBundesanzeigerCases && this.authUser.loggedIn && this.network.isConnectedToServer) { // if user is logged in, reload is neccessary and there is a connection
        this.getBundesanzeigerCasesFromServer();
      }
    });
  }

  triggerBundesanzeigerReloadFromServer() {
    this.store.dispatch(new NetworkActions.SetUpdateNeededBundesanzeigerCasesTrue());
    this.getBundesanzeigerCasesFromServer();
  }

  getBundesanzeigerCasesFromServer() {
    if (this.loadingInProgress) {
      return; // if loading already in progress, ignore!
    }
    this.loadingInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_BUNDESANZEIGER));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_BUNDESANZEIGER_LOADING));
    this.http.get<IBundesanzeiger[]>(`${this.constantsService.getApiEndpoint()}/bundesanzeiger_list/?deleted=false`, {headers: this.constantsService.getHttpOptions()})
      .subscribe(
      (response) => {
        this.store.dispatch(new BundesanzeigerActions.ReplaceBundesanzeigerCasesInList(response));
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_BUNDESANZEIGER));
        this.store.dispatch(new NetworkActions.SetUpdateNeededBundesanzeigerCasesFalse());
        this.loadingInProgress = false;
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_BUNDESANZEIGER));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_BUNDESANZEIGER_LOAD_ERROR));
        this.loadingInProgress = false;
      },
      () => {
        if (this.loadingInProgress) {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_BUNDESANZEIGER));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_BUNDESANZEIGER_LOAD_TIMEOUT));
          this.loadingInProgress = false;
        }
      }
    );
  }

  delete(id: number) {
    if (this.deletingInProgress) {
      return; // ignore if there is already a delete action in progress
    }
    this.deletingInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_BUNDESANZEIGER));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_BUNDESANZEIGER_SAVING));
    this.http.get(`${this.constantsService.getApiEndpoint()}/bundesanzeiger_delete/${id}/`, {headers: this.constantsService.getHttpOptions()})
      .subscribe(
        (result) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_BUNDESANZEIGER));
          this.store.dispatch(new BundesanzeigerActions.RemoveBundesanzeigerCaseFromList(id));
          this.deletingInProgress = false;
        },
        (error) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_BUNDESANZEIGER));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_BUNDESANZEIGER_SAVE_ERROR));
          this.deletingInProgress = false;
        },
        () => {
          if (this.deletingInProgress) {
            this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_BUNDESANZEIGER));
            this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_BUNDESANZEIGER_SAVE_TIMEOUT));
            this.deletingInProgress = false;
          }
        });
  }

  create_case(id: number) {
     if (this.creatingInProgress) {
      return; // ignore if there is already a create action in progress
    }
    this.creatingInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_BUNDESANZEIGER));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_BUNDESANZEIGER_SAVING));
    this.http.get(`${this.constantsService.getApiEndpoint()}/bundesanzeiger_to_case/${id}/`, {headers: this.constantsService.getHttpOptions()})
       .subscribe(
        (result) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_BUNDESANZEIGER));
          this.store.dispatch(new BundesanzeigerActions.RemoveBundesanzeigerCaseFromList(id));
          this.creatingInProgress = false;
        },
        (error) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_BUNDESANZEIGER));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_BUNDESANZEIGER_SAVE_ERROR));
          this.creatingInProgress = false;
        },
        () => {
          if (this.creatingInProgress) {
            this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_BUNDESANZEIGER));
            this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_BUNDESANZEIGER_SAVE_TIMEOUT));
            this.creatingInProgress = false;
          }
        });
  }

  ngOnDestroy() {
    this.networkSub.unsubscribe();
    this.reloadTimerSub.unsubscribe();
    this.authUserSub.unsubscribe();
  }
}



