import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FileUploadListComponent} from "../files/file-upload/file-upload-list/file-upload-list.component";
import {ToolsModule} from "../tools/tools.module";
import {NgbAlertModule, NgbTabsetModule} from "@ng-bootstrap/ng-bootstrap";
import {ReactiveFormsModule, FormsModule} from "@angular/forms";
import {GeneratedDocumentsModule} from "../generated-documents/generated-documents.module";
import {FileUploaderComponent} from './file-upload/file-uploader/file-uploader.component';
import {FileUploadModule} from 'ng2-file-upload';
import {NgxFileDropModule} from 'ngx-file-drop';

@NgModule({
  declarations: [
    FileUploadListComponent,
    FileUploaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ToolsModule,
    NgbAlertModule,
    NgbTabsetModule,
    ReactiveFormsModule,
    GeneratedDocumentsModule,
    FileUploadModule,
    NgxFileDropModule
  ],
  exports: [
    FileUploadListComponent
  ],
  entryComponents: [
    FileUploaderComponent
  ]
})

export class FilesModule {}
