import {Component, Output, EventEmitter} from '@angular/core';
import {AddressbookService} from "../../services/addressbook.service";
import {IAddressbookInstitution} from "../../addressbook/addressbook-institution.interface";
import {IAddressbookPerson} from "../../addressbook/addressbook-person.interface";
import {IGeneratedDocRecipient} from "../generated-doc-recipient.interface";

@Component({
  selector: 'select-recipient-from-addressbook',
  templateUrl: './select-recipient-from-addressbook.component.html',
  styleUrls: ['./select-recipient-from-addressbook.component.css']
})
export class SelectRecipientFromAddressbookComponent {

  @Output() addressbookRecipientSelected: EventEmitter<IGeneratedDocRecipient> = new EventEmitter<IGeneratedDocRecipient>();

  searchValue: string = '';
  searchResultInstitutions: IAddressbookInstitution[] = [];

  constructor(private addressbookService: AddressbookService) {}

  search() {
    if (this.searchValue.length > 3) {
      this.addressbookService.typeaheadSearchForDocumentRecipient(this.searchValue.toLowerCase()).subscribe(
        (institutions) => {
          this.searchResultInstitutions = institutions;
        }
      );
    }
    else {
      this.searchResultInstitutions = [];
    }
  }

  chooseInstitution(institution: IAddressbookInstitution) {
    this.addressbookRecipientSelected.emit(<IGeneratedDocRecipient>{addressbook_institution_id: institution.id, name: institution.name});
  }

  choosePerson(person: IAddressbookPerson, institution_name: string) {
    this.addressbookRecipientSelected.emit(<IGeneratedDocRecipient>{addressbook_person_id: person.id, name: person.last_name.toUpperCase() + ' ' + person.first_name + ' - ' + institution_name});
  }

}
