import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';
import {IUserState} from '../../store/user-state.interface';
import {Subscription} from 'rxjs';
import {IUser} from '../../models/user.interface';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-datepicker-popup',
  templateUrl: './datepicker-popup.component.html',
  styleUrls: ['./datepicker-popup.component.css']
})
export class DatepickerPopupComponent implements OnInit, OnDestroy {
  edit_mode = false;
  calendarEntryForm: FormGroup;
  for_user_id: number;
  deadline: string = null;
  deadline_hour = null;
  deadline_minute = null;
  deadline_display: Date;
  usersSub: Subscription;
  users: IUser[] = [];
  useropts: {value: number, text: string}[] = [];
  constructor(public activeModal: NgbActiveModal, private ngbModal: NgbModal, private store: Store<{users: IUserState}>) {
    this.usersSub = this.store.select('users').subscribe(
      (users: IUserState) => {
        this.users = users.users.filter(x => x.is_active);
        this.useropts = [];
        this.useropts.push({value: 0, text: 'für mich selbst'});
        for (const u of this.users) {
          this.useropts.push({value: u.id, text: `${u.abbreviation} - ${u.first_name} ${u.last_name}`});
        }
      }
    );
  }

  ngOnInit() {
    this.calendarEntryForm = new FormGroup({
        'deadlineTimestamp': new FormControl(null, [Validators.required, Validators.maxLength(10)]),
        'deadlineTimestampTime': new FormControl(null, [Validators.maxLength(10)]),
        'forUserId': new FormControl(0)
      });
    if (this.edit_mode) {
      if (!this.deadline_hour) {
        this.deadline_display = new Date(this.deadline);
      } else {
        const dateArray = this.deadline.split('-');
        this.deadline_display = new Date(+dateArray[0], +dateArray[1] - 1, +dateArray[2], this.deadline_hour, this.deadline_minute)
      }
      const timeInput = {hour: this.deadline_hour, minute: this.deadline_minute}
      const date = this.deadline.split('-');
      const dateInput = {year: date[0], month: date[1], day: date[2]};
      this.calendarEntryForm.patchValue({
        'deadlineTimestamp': dateInput,
        'deadlineTimestampTime': timeInput,
      });
    }
    this.calendarEntryForm.valueChanges.subscribe((form) => {
      if (form.deadlineTimestamp != null) {
        this.deadline = form.deadlineTimestamp.year + '-' + form.deadlineTimestamp.month + '-' + form.deadlineTimestamp.day;
      }
      if (form.deadlineTimestampTime != null) {
        this.deadline_hour = form.deadlineTimestampTime.hour;
        this.deadline_minute = form.deadlineTimestampTime.minute;
      }
   });
  }

  ngOnDestroy() {
    this.usersSub.unsubscribe();
  }

  close() {
    this.activeModal.dismiss();
  }

  create() {
    this.activeModal.close({date: this.deadline, hour: this.deadline_hour, minute: this.deadline_minute, user: this.calendarEntryForm.controls.forUserId.value});
  }

  delete() {
    const modalRef = this.ngbModal.open(ConfirmModalComponent);
    modalRef.componentInstance.message = 'Wollen Sie den Termineintrag wirklich löschen?';
    modalRef.componentInstance.title = 'Bestätigung';
    modalRef.result.then(
      (result) => {
        if (result) {
          this.activeModal.close('delete');
        }
      }
    );
  }
}
