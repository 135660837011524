import {Component, Input} from '@angular/core';
import {IUi} from "../../models/ui.interface";
import {Store} from "@ngrx/store";
import {IModulePages, MODULE_PAGES} from "../../routing/routes.model";
import * as SLOTS from "../../app-notifications/app-notification.interface";

@Component({
  selector: 'calendar-entry',
  templateUrl: './calendar-entry.component.html',
  styleUrls: ['./calendar-entry.component.css']
})

export class CalendarEntryComponent {
  @Input() ui: {ui: IUi};
  MODULE_PAGES: IModulePages;
  SLOT_CALENDAR = SLOTS.NOTIFICATION_SLOT_CALENDAR;

  constructor(private store: Store<{ui: IUi}>) {}

  ngOnInit() {
    this.MODULE_PAGES = MODULE_PAGES;
  }
}
