import {Injectable, OnDestroy} from '@angular/core';
import {IStammbaumPerson} from '../stammbaum/stammbaum-person.interface';
import {Subscription} from 'rxjs';
import {IStammbaumState} from '../store/stammbaum-state.interface';
import {Store} from '@ngrx/store';

@Injectable()
export class DatesService implements OnDestroy {
  conditions = ['+', '-', '#', '?'];
  stammbaumSub: Subscription;
  stammbaumState: IStammbaumState;

  constructor(private store: Store<{stammbaum: IStammbaumState}>) {
    this.stammbaumSub = this.store.select('stammbaum').subscribe(
      (stammbaumState) => {
        this.stammbaumState = stammbaumState;
      }
    )
  }

  ngOnDestroy() {
    this.stammbaumSub.unsubscribe();
  }

  calculateAge(birthday_input: string) {
    const birthday_string = birthday_input.slice(-4) + '-' + birthday_input.slice(2, 4) + '-' + birthday_input.slice(0, 2);
    const birthday = new Date(birthday_string);
    const diff_ms = Date.now() - birthday.getTime();
    const age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  isDateReadable(date_input_string) {
    if (date_input_string === null || date_input_string === '') {
      return false;
    }
    const readable = this.conditions.some(el => date_input_string.includes(el));
    return !readable;
  }

  calculateMonthDiff(d1: Date, d2: Date) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  calculateAgeAtDeath(birthday_input, deathday_input) {
    const birthday_string = birthday_input.slice(-4) + '-' + birthday_input.slice(2, 4) + '-' + birthday_input.slice(0, 2);
    const deathday_string = deathday_input.slice(-4) + '-' + deathday_input.slice(2, 4) + '-' + deathday_input.slice(0, 2);
    const birthday = new Date(birthday_string);
    const deathday = new Date(deathday_string);
    const diff_ms = deathday.getTime() - birthday.getTime();
    const age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  isPersonNachverstorben(p_input) {
    const el = this.stammbaumState.stammbaum.persons.find(sp => sp.person_type === 1);
    if (this.isDateReadable(p_input) && this.isDateReadable(el.sterbedatum)) {
      const deathday_person_string = p_input.slice(-4) + '-' + p_input.slice(2, 4) + '-' + p_input.slice(0, 2);
      const deathday_el_string = el.sterbedatum.slice(-4) + '-' + el.sterbedatum.slice(2, 4) + '-' + el.sterbedatum.slice(0, 2);
      if (new Date(deathday_person_string).getTime() > new Date(deathday_el_string).getTime()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
