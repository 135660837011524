import * as CalendarEntryActions from './calendar-entry.actions';
import {ICalendarEntryState} from "./calendar-entry-state.interface";

const initialState: ICalendarEntryState = {
  calendarEntries: [],
  activeCalendarEntry: null,
};

export function calendarEntryReducer(state = initialState, action: CalendarEntryActions.CalendarEntryActions) {
  switch(action.type) {

    case CalendarEntryActions.REPLACE_ENTRIES_IN_ENTRYLIST:
      return {
        ...state,
        calendarEntries: action.payload
      };

    case CalendarEntryActions.ADD_ENTRY_TO_ENTRYLIST:
      return {
        ...state,
        calendarEntries: [...state.calendarEntries, action.payload]
      };

    case CalendarEntryActions.SET_ACTIVE_ENTRY:
      return {
        ...state,
        activeCalendarEntry: action.payload
      };

    case CalendarEntryActions.UPDATE_ACTIVE_ENTRY:
      return {
        ...state,
        activeCalendarEntry: action.payload
      };

    case CalendarEntryActions.DELETE_ALL_ENTRY_DATA:
      return initialState;

    default:
      return state;
  }
}
