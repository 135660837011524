import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {IUser} from '../../models/user.interface';
import {IUserState} from '../../store/user-state.interface';
import {IUi} from '../../models/ui.interface';
import * as UiActions from '../../store/ui.actions';
import {MODULES, MODULE_PAGES} from '../../routing/routes.model';
import {RoutingService} from '../../routing/routing.service';
import * as UserActions from '../../store/user.actions';
import {UserService} from '../../services/user.service';
import {RightsManagementService} from '../../services/rights-management.service';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {UserListMailPopupComponent} from "./user-list-mail-popup/user-list-mail-popup.component";


@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit, OnDestroy {

  recalculating = false;
  resultCount = 0;
  users: IUser[] = [];
  copiedUsers: IUser[] = [];
  usersStateSub: Subscription;
  ngbModalRefMail: NgbModalRef;

  constructor(private store: Store<{users: IUserState, ui: IUi}>,
              public userService: UserService,
              private ngbModal: NgbModal,
              public rightsManagementService: RightsManagementService) {}

  ngOnInit() {
    this.usersStateSub = this.store.select('users').subscribe(
      (usersState) => {
        this.users = [...usersState.users];
        this.users = this.users.filter(x => x.is_active);
        this.copyAndRunFilters();
      }
    );
  }

  onTableClicked(userId: number) {
    this.store.dispatch(new UserActions.SetActiveUser(userId));
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.USERS, page: MODULE_PAGES.USERS.USER_EDIT}));
   }

  copyAndRunFilters() {
    this.recalculating = true;
    this.copiedUsers = [...this.users];
    this.recalculating = false;
    this.resultCount = this.copiedUsers.length;
  }

  ngOnDestroy() {
    this.usersStateSub.unsubscribe();
  }

  openMail() {
    this.ngbModalRefMail = this.ngbModal.open(UserListMailPopupComponent, {backdrop: 'static', size: 'xl'});
    this.ngbModalRefMail.componentInstance.users = this.copiedUsers.filter(x => !x.is_developer_account);
    this.copyAndRunFilters();
    this.ngbModalRefMail.result.then(
      (result) => { this.userService.sendMassMailToUsers(result).subscribe(
        (innerResult) => {},
        (error) => { console.log(error); },
        () => {}
      )},
      () => {}
    );
  }

  isAllowedToSendMassMail() {
    return this.rightsManagementService.isAllowedToSendMassMail();
  }
}
