import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {KassenbuchService} from '../kassenbuch.service';
import {KassenbuchBelegInterface, KassenbuchEinzelpostenAusBelegInterface} from '../kassenbuch.interface';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-send-money-popup',
  templateUrl: './betrag-umrechnen-popup.component.html',
  styleUrls: ['./betrag-umrechnen-popup.component.css']
})
export class BetragUmrechnenPopupComponent implements OnInit {
  form: FormGroup;
  beleg: KassenbuchBelegInterface = null;
  posten: KassenbuchEinzelpostenAusBelegInterface = null;
  constructor(private ngbActiveModal: NgbActiveModal, private kassenbuchService: KassenbuchService, private datePipe: DatePipe) {
    this.form = new FormGroup({
      betrag: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {}

  getWaehrungString(id: number) { this.kassenbuchService.getWaehrungOptionStringById(id); }

  cancel() {
    this.ngbActiveModal.dismiss();
  }

  confirm() {
    this.ngbActiveModal.close({
      betrag: this.form.controls.betrag.value,
      waehrung: this.kassenbuchService.getWaehrungOptionStringById(this.posten.waehrung)
    });
  }
}
