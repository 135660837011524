import {Component, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {INVZStandesamt, INVZStandesamtTree} from '../../namensverzeichnisse/nvz-standesamt.interface';
import {NVZService} from '../../services/nvz.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'search-standesamt',
  templateUrl: './search-standesamt.component.html',
  styleUrls: ['./search-standesamt.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SearchStandesamtComponent {
  form: FormGroup;
  newAmtForm: FormGroup;
  search_in_progress = false;
  searched = false;
  results: INVZStandesamtTree[] = [];
  navigate_to_selected = false;
  allow_creating_new = false;
  private searchValueSubject: Subject<string> = new Subject<string>();
  private searchValueSubscription: Subscription;
  private search = '';
  private search_stadt = '';
  private search_bundesland = '';

  constructor(private ngbActiveModal: NgbActiveModal,
              private ngbModal: NgbModal,
              private userService: UserService,
              private nvzService: NVZService) {
    this.form = new FormGroup({
      freitext: new FormControl(''),
      stadt: new FormControl(''),
      bundesland: new FormControl('')
    });
    this.newAmtForm = new FormGroup({
      standesamt_name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      stadt_name: new FormControl('', [Validators.required, Validators.maxLength(50)])
    });
    this.form.controls.freitext.valueChanges.subscribe(
      (value) => {
        this.search = value;
        this.searchValueSubject.next(value);
      }
    );
    this.form.controls.stadt.valueChanges.subscribe(
      (value) => {
        this.search_stadt = value;
        this.searchValueSubject.next(value);
      }
    );
    this.form.controls.bundesland.valueChanges.subscribe(
      (value) => {
        this.search_bundesland = value;
        this.searchValueSubject.next(value);
      }
    );
    this.searchValueSubscription = this.searchValueSubject.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe((value) => {
        this.search_in_progress = true;
        this.searched = true;
        this.nvzService.searchNVZStandesamt(this.search, this.search_stadt, this.search_bundesland).subscribe(
          (results) => {
            this.results = results;
          },
          (error) => {
          },
            () => {
            this.search_in_progress = false;
          });
      });
  }

  selectStandesamt(amt: INVZStandesamtTree) {
    if (this.navigate_to_selected) {
      this.nvzService.navigateToVerzeichnisseInTree(amt);
      this.close();
    } else {
      this.ngbActiveModal.close(amt);
    }
  }

  createNew() {
    const modalRef = this.ngbModal.open(ConfirmModalComponent);
    modalRef.componentInstance.message = 'Wollen Sie wirklich ein neues Standesamt anlegen? Bitte prüfen Sie vorher, ob dieses Standesamt nicht doch schon vorhanden ist und geben Sie eine möglichst vollständige Beschreibung zum Ort an.';
    modalRef.componentInstance.title = 'Bestätigung';
    modalRef.result.then(
      (result) => {
        if (result) {
          this.createNewExecute();
        }
      }
    );
  }

  createNewExecute() {
    const newStb = <INVZStandesamt>{
      name: this.newAmtForm.controls.standesamt_name.value,
      comment: this.newAmtForm.controls.stadt_name.value,
      stadt_id: null,
      owner_id: null,
      status: 0
    };
    this.nvzService.createStandesamt(newStb).subscribe(
      (result) => {},
      (error) => {
        console.log(error);
      },
      () => {
        this.form.reset();
        this.newAmtForm.reset();
        this.search = newStb.name;
        this.searchValueSubject.next(newStb.name);
      }
    );
  }

  close() {
    this.ngbActiveModal.close();
  }
}
