import {Component, Input, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ICalendarEntry} from '../calendar-entry.interface';
import {IUser} from '../../models/user.interface';
import {IUserState} from '../../store/user-state.interface';
import {ICalendarEntryState} from '../../store/calendar-entry-state.interface';
import * as CalendarEntryActions from '../../store/calendar-entry.actions';
import {CalendarEntryService} from '../../services/calendar-entry.service';
import * as UiActions from '../../store/ui.actions';
import {MODULE_PAGES, MODULES} from '../../routing/routes.model';
import {RoutingService} from '../../routing/routing.service';
import {UserService} from '../../services/user.service';
import {NoteService} from "../../services/note.service";
import {INoteState} from "../../store/note-state.interface";
import {CaseService} from "../../services/case.service";

@Component({
  selector: 'calendar-popup',
  templateUrl: './calendar-popup.component.html',
  styleUrls: ['./calendar-popup.component.css']
})

export class CalendarPopupComponent implements OnDestroy {

  calendarEntry: ICalendarEntry;
  calendarEntrySub: Subscription;
  usersStateSub: Subscription;
  users: IUser[] = [];

  constructor(public activeModal: NgbActiveModal,
              public calendarEntryService: CalendarEntryService,
              private routingService: RoutingService,
              public userService: UserService,
              private noteService: NoteService,
              private caseService: CaseService,
              private store: Store<{users: IUserState, calendarEntry: ICalendarEntryState}>)
  {
    this.calendarEntrySub = this.store.select('calendarEntry').subscribe(
      (entry) => {this.calendarEntry = entry.activeCalendarEntry;}
      );
    this.usersStateSub = this.store.select('users').subscribe(
      (users) => {
        this.users = users.users;
      }
    );
  }

  setCalendarEntryDone() {
    this.calendarEntry.completed = true;
    this.store.dispatch(new CalendarEntryActions.UpdateActiveEntry(this.calendarEntry));
    this.calendarEntryService.calendarEntrySetToDone(this.calendarEntry.id);
    this.activeModal.close();
  }

  setCalendarEntryOpen() {
    this.calendarEntry.completed = false;
    this.store.dispatch(new CalendarEntryActions.UpdateActiveEntry(this.calendarEntry));
    this.calendarEntryService.calendarEntrySetToOpen(this.calendarEntry.id);
    this.activeModal.close();
  }

  editCalendarEntry() {
    this.store.dispatch(new CalendarEntryActions.SetActiveEntry(this.calendarEntry));
    this.routingService.navigateToModule({moduleName: MODULES.CALENDAR});
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.CALENDAR, page: MODULE_PAGES.CALENDAR.FORM_CHANGE}));
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.usersStateSub.unsubscribe();
    this.calendarEntrySub.unsubscribe();
  }

  goToCase() {
    this.caseService.goToCasedetailId(+this.calendarEntry.case);
    this.activeModal.close();
  }

  goToPerson() {
    this.caseService.goToPersondetailId(+this.calendarEntry.person);
    this.activeModal.close();
  }

  goToNote() {
    this.noteService.goToNote(+this.calendarEntry.note);
    this.activeModal.close();
  }
}
