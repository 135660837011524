export const NVZStadtStatusOptions = [
  {id: 0, name: "unbearbeitet"},
  {id: 1, name: "teilweise"},
  {id: 2, name: "vollständig"}
];

export const NVZStandesamtStatusOptions = [
  {id: 0, name: "unbearbeitet"},
  {id: 1, name: "teilweise"},
  {id: 2, name: "vollständig"}
];

export const NVZVerzeichnisStatusOptions = [
  {id: 0, name: "unbearbeitet"},
  {id: 1, name: "teilweise"},
  {id: 2, name: "vollständig"}
];

export const NVZEintragStatusOptions = [
  {id: 0, name: "unbearbeitet"},
  {id: 1, name: "teilweise"},
  {id: 2, name: "vollständig"}
];

export const NVZUrkundenArt = [
  {id: 0, name: "GU"},
  {id: 1, name: "HU"},
  {id: 2, name: "SU"},
];
