import {Input, Component, OnInit, ViewChild, ElementRef, SimpleChanges, OnChanges, OnDestroy, AfterViewInit} from '@angular/core';
import {Subscription} from 'rxjs';
import { CaseService } from '../../services/case.service';
import {MODULES, MODULE_PAGES} from '../../routing/routes.model';
import * as UiActions from '../../store/ui.actions';
import {Store} from '@ngrx/store';
import {IUi} from '../../models/ui.interface';
import {ICaseState} from '../../store/case-state.interface';
import {RightsManagementService} from '../../services/rights-management.service';
import {ICase} from '../case.interface';
import {ICaseStatus} from '../../models/case-status.interface';
import {IUserState} from '../../store/user-state.interface';
import {IUser} from '../../models/user.interface';
import {RoutingService} from '../../routing/routing.service';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';


@Component({
  selector: 'case-detail-max-files',
  templateUrl: './case-detail-max-files.component.html',
  styleUrls: ['./case-detail-max-files.component.css']
})
export class CaseDetailMaxFilesComponent implements OnInit, AfterViewInit, OnDestroy {
  activeCaseId: number;
  activeCaseSub: Subscription;
  activeCase: ICase;
  copiedActiveCase: ICase;
  caseStatus: ICaseStatus[];
  caseStatusSub: Subscription;
  usersStateSub: Subscription;
  users: IUser[];


  @Input() printablePersongroups: string[];
  @Input() ui;
  @ViewChild('personsRef', { static: false }) personsRef: ElementRef;
  @ViewChild('filesRef', { static: false }) filesRef: ElementRef;
  @ViewChild('notesRef', { static: false }) notesRef: ElementRef;
  @ViewChild('topRef', { static: false }) topRef: ElementRef;

  id: number;
  editModePersonData = false;
  editModeParentCase = false;
  editModeBaseData = false;
  editBundesanzeiger = false;
  editUrkundensuche = false;
  editEbszus = false;
  editSummary = false;
  parentCase: ICase = null;
  childCases: ICase[] = [];
  parentCaseEdit = '';

  constructor(private caseService: CaseService,
              private router: Router,
              private store: Store<{ui: IUi, caseStatus: ICaseStatus[], cases: ICaseState, users: IUserState}>,
              public rightsManagementService: RightsManagementService,
              private routingService: RoutingService,
              public userService: UserService) {
    this.caseStatusSub = this.store.select('caseStatus').subscribe(
      (caseStatus) => { this.caseStatus = caseStatus; }
    );
    this.usersStateSub = this.store.select('users').subscribe(
      (usersState) => {
        this.users = usersState.users;
      }
    );
    this.activeCaseSub = this.store.select('cases').subscribe(
      (cases) => {
        if (this.editModeBaseData || this.editModePersonData || this.editBundesanzeiger || this.editSummary) {
          // if we are in edit mode, check if this case has changed, and if it has, notify user
          // todo implement JSON.stringify - compare the 2 objects, if changed then notify user via modal about server-side changes and ask to reload or discard
        } else { // if we are not in any edit mode, simply update
          this.activeCase = cases.activeCase;
          this.activeCaseId = cases.activeCaseId;
          this.copiedActiveCase = {...cases.activeCase};
          this.parentCase = null;
          this.parentCaseEdit = '';
          this.childCases = [];
          if (cases.activeCase && cases.activeCase.child_cases && cases.activeCase.child_cases.length > 0) {
            for (const c of cases.activeCase.child_cases) {
              const ca: ICase = cases.cases.find(x => +x.id === +c);
              if (ca) {
                this.childCases.push(ca);
              }
            }
          }
          if (cases.activeCase && cases.activeCase.parent_case && cases.activeCase.parent_case > 0) {
            this.parentCaseEdit = cases.activeCase.parent_case.toString();
            this.parentCase = cases.cases.find(x => +x.id === +cases.activeCase.parent_case)
          }
        }
      }
    );
  }

  ngAfterViewInit() {
    window.scrollTo({top: 0, left: 0});
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.activeCaseSub.unsubscribe();
    this.caseStatusSub.unsubscribe();
    this.usersStateSub.unsubscribe();
  }
}
