import { Injectable } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { AuthenticationService } from './authentication.service';
import {Store} from '@ngrx/store';
import {ICalendarEntryState} from '../store/calendar-entry-state.interface';
import {ICalendarEntry} from '../calendar/calendar-entry.interface';
import * as CalendarEntryActions from '../store/calendar-entry.actions';
import * as NetworkActions from '../store/network.actions';
import {INetworkState} from '../store/network-state.interface';
import {IAuthUser} from '../models/auth-user.interface';
import {
  NOTIFICATION_MESSAGE_CASEDETAIL_LOADER_LOADING, NOTIFICATION_MESSAGE_LOAD_CALENDAR_ENTRY_FAILED_ERROR,
  NOTIFICATION_MESSAGE_LOAD_CALENDAR_ENTRY_FAILED_TIMEOUT,
  NOTIFICATION_MESSAGE_LOAD_CALENDAR_ENTRY_IN_PROGRESS, NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_FAILED_ERROR,
  NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_FAILED_TIMEOUT,
  NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_IN_PROGRESS,
  NOTIFICATION_SLOT_CALENDAR,
  NOTIFICATION_SLOT_CASEDETAIL_LOADER
} from '../app-notifications/app-notification.interface';
import * as AppNotificationActions from '../store/app-notification.actions';

const TRY_RELOAD_INTERVAL = 10 * 1000; // 10 Sekunden

@Injectable()
export class CalendarEntryService {

  network: INetworkState;
  networkSub: Subscription;
  reloadTimerSub: Subscription;
  authUserSub: Subscription;
  authUser: IAuthUser;
  entryLoadingInProgress = false;
  setDoneInProgress = false;
  setOpenInProgress = false;
  createEntryInProgress = false;
  saveEntryInProgress = false;

  constructor(private store: Store<{calendarEntry: ICalendarEntryState, network: INetworkState, authUser: {authUser: IAuthUser}}>,
              private http: HttpClient,
              private constantsService: ConstantsService) {
    this.networkSub = this.store.select('network').subscribe((network) => { this.network = network});
    this.authUserSub = this.store.select('authUser').subscribe((authUser) => { this.authUser = authUser.authUser});
    this.reloadTimerSub = timer(0, TRY_RELOAD_INTERVAL).subscribe(t => {
      if (this.network.updateNeededCalendarEntries && this.authUser.loggedIn && this.network.isConnectedToServer) { // if user is logged in, reload is neccessary and there is a connection
        this.getCalendarEntriesFromServer();
      }
    });
  }

  triggerCalendarEntryReloadFromServer() {
    this.store.dispatch(new NetworkActions.SetUpdateNeededCalendarEntriesTrue());
    this.getCalendarEntriesFromServer();
  }

  getCalendarEntriesFromServer() {
    if (this.entryLoadingInProgress) {
      return; // if loading already in progress, ignore!
    }
    this.entryLoadingInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_CALENDAR_ENTRY_IN_PROGRESS));
    this.http.get<ICalendarEntry[]>(`${this.constantsService.getApiEndpoint()}/v2/calendar-entries/`, {headers: this.constantsService.getHttpOptions()})
      .subscribe(
      (response: ICalendarEntry[]) => {
        this.entryLoadingInProgress = false;
        response = this.convertFormatFromMySQL(response);
        this.store.dispatch(new CalendarEntryActions.ReplaceEntriesInEntrylist(response));
        this.store.dispatch(new NetworkActions.SetUpdateNeededCalendarEntriesFalse());
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_CALENDAR_ENTRY_FAILED_ERROR));
        this.entryLoadingInProgress = false;
      },
      () => {
        if (this.entryLoadingInProgress) {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_CALENDAR_ENTRY_FAILED_TIMEOUT));
          this.entryLoadingInProgress = false;
        }
      }
    );
  }

  calendarEntrySetToDone(id: number) {
    this.setDoneInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_IN_PROGRESS));
    this.http.get(`${this.constantsService.getApiEndpoint()}/v2/calendar-entry-set-done/${id}/`, {headers: this.constantsService.getHttpOptions()})
      .subscribe(
      (response) => {
        this.setDoneInProgress = false;
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
        this.triggerCalendarEntryReloadFromServer();
      },
      (error) => {
        this.setDoneInProgress = false;
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_FAILED_ERROR));
        this.triggerCalendarEntryReloadFromServer();
      },
      () => {
        if (this.setDoneInProgress) {
          this.setDoneInProgress = false;
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_FAILED_TIMEOUT));
          this.triggerCalendarEntryReloadFromServer();
        }
      }
    );
  }

  calendarEntrySetToOpen(id: number) {
    this.setOpenInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_IN_PROGRESS));
    this.http.get(`${this.constantsService.getApiEndpoint()}/v2/calendar-entry-set-open/${id}/`, {headers: this.constantsService.getHttpOptions()})
      .subscribe(
      (response) => {
        this.setOpenInProgress = false;
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
        this.triggerCalendarEntryReloadFromServer();
      },
      (error) => {
        this.setOpenInProgress = false;
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_FAILED_ERROR));
        this.triggerCalendarEntryReloadFromServer();
      },
      () => {
        if (this.setOpenInProgress) {
          this.setOpenInProgress = false;
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_FAILED_TIMEOUT));
          this.triggerCalendarEntryReloadFromServer();
        }
      }
    );
  }

  calendarEntryCreate(entry: ICalendarEntry) {
    this.createEntryInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_IN_PROGRESS));
    entry.deadline_timestamp_string = this.convertFormatForMySQL(entry.deadline_timestamp);
    entry.created_timestamp_string = this.convertFormatForMySQL(entry.created_timestamp);
    this.http.post(`${this.constantsService.getApiEndpoint()}/v2/create-calendar-entry/`, entry, {headers: this.constantsService.getHttpOptions()})
      .subscribe(
      (response) => {
        this.createEntryInProgress = false;
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
        this.triggerCalendarEntryReloadFromServer();
      },
      (error) => {
        this.createEntryInProgress = false;
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_FAILED_ERROR));
        this.triggerCalendarEntryReloadFromServer();
      },
      () => {
         if (this.createEntryInProgress) {
          this.createEntryInProgress = false;
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_FAILED_TIMEOUT));
          this.triggerCalendarEntryReloadFromServer();
        }
      }
    );
  }

  calendarEntrySave(entry: ICalendarEntry) {
    if (entry.id < 1) {
      this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
      this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_FAILED_ERROR));
      this.triggerCalendarEntryReloadFromServer();
      return;
    }
    this.saveEntryInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_IN_PROGRESS));
    entry.deadline_timestamp_string = this.convertFormatForMySQL(entry.deadline_timestamp);
    entry.created_timestamp_string = this.convertFormatForMySQL(entry.created_timestamp);
    this.http.post(`${this.constantsService.getApiEndpoint()}/v2/change-calendar-entry/`, entry, {headers: this.constantsService.getHttpOptions()})
      .subscribe(
      (response) => {
        this.saveEntryInProgress = false;
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
        this.triggerCalendarEntryReloadFromServer();
      },
      (error) => {
        this.saveEntryInProgress = false;
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_FAILED_ERROR));
        this.triggerCalendarEntryReloadFromServer();
      },
      () => {
         if (this.saveEntryInProgress) {
          this.saveEntryInProgress = false;
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CALENDAR));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_FAILED_TIMEOUT));
          this.triggerCalendarEntryReloadFromServer();
        }
      }
    );
  }

  convertFormatFromMySQL(entries: ICalendarEntry[]) {
    for (const entry of entries) {
      entry.deadline_timestamp = new Date(entry.deadline_timestamp_string);
      entry.created_timestamp = new Date(entry.created_timestamp_string);
    }
    return entries;
  }

  convertFormatForMySQL(timestamp: Date) {
    const timestamp_db: string = moment(timestamp).format('YYYY-MM-DD');
    return timestamp_db;
  }

  convertTimeForOutput(hour: number, minute: number) {
    if (hour == null || minute == null) {
      return '';
    }
    let output = '';
    if (hour < 10) {
      output += '0';
    }
    output += hour.toString() + ':';
    if (minute < 10) {
      output += '0';
    }
    output += minute.toString();
    return output;
  }

  convertTimeForOutputWithText(hour: number, minute: number) {
    let output = this.convertTimeForOutput(hour, minute);
    if (output != '') {
      output = 'um ' + output + ' Uhr';
    }
    return output;
  }
}
