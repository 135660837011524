import * as CaseStatusActions from './case-status.actions';
import {ICaseStatus} from '../models/case-status.interface';

const initialState: ICaseStatus[] = [];

export function caseStatusReducer(state = initialState, action: CaseStatusActions.CaseStatusActions) {
  switch (action.type) {

    case CaseStatusActions.SET_CASE_STATUS:
      return action.payload;

    default:
      return state;
  }
}
