import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {ICaseState} from '../../store/case-state.interface';
import {CaseService} from '../../services/case.service';

@Component({
  selector: 'heir-history',
  templateUrl: './heir-history.component.html',
  styleUrls: ['./heir-history.component.css']
})

export class HeirHistoryComponent implements OnDestroy {
  personHistory: {id: number, text: string}[] = [];
  caseStateSub: Subscription;

  constructor(private store: Store<{cases: ICaseState}>,
              private caseService: CaseService)
  {
    this.caseStateSub = this.store.select('cases').subscribe(
      (caseState) => {
        if (caseState) {
          this.personHistory = caseState.personHistory;
        }
      }
    );
  }

  ngOnDestroy() {
    this.caseStateSub.unsubscribe();
  }

  goToHeirId(id: number) {
    this.caseService.goToPersondetailId(id);
  }
}
