import * as NoteActions from './note.actions';
import {INoteState} from './note-state.interface';
import {INote} from '../notes/note.interface';

const initialState: INoteState = {
  recentNotes: [],
  notesForCurrentCase: [],
  notesForCurrentPerson: [],
  highlightedNote: null
};

export function noteReducer(state = initialState, action: NoteActions.NoteActions) {
  switch (action.type) {

    case NoteActions.REPLACE_RECENT_NOTES_LIST:
      return {
        ...state,
        recentNotes: action.payload
      };

    case NoteActions.REPLACE_NOTES_LIST_FOR_CURRENT_CASE:
      return {
        ...state,
        notesForCurrentCase: action.payload
      };

    case NoteActions.REPLACE_NOTES_LIST_FOR_CURRENT_PERSON:
      return {
        ...state,
        notesForCurrentPerson: action.payload
      };

    case NoteActions.REPLACE_RECENT_NOTE:
      const notes_recent: INote[] = state.recentNotes.filter(x => x.id !== +action.payload.id);
      notes_recent.push(action.payload);
      notes_recent.sort((a, b) => {
          if (a.updated < b.updated) { return 1 }
          if (a.updated > b.updated) { return -1 }
          return 0;
        });
      return {
        ...state,
        recentNotes: [...notes_recent]
      };

    case NoteActions.REPLACE_NOTE_FOR_CURRENT_CASE:
      const notes_case = state.notesForCurrentCase.filter(x => x.id !== +action.payload.id);
      notes_case.push(action.payload);
      notes_case.sort((a, b) => {
          if (a.important && !b.important) { return -1 }
          if (!a.important && b.important) { return 1 }
          if (a.created < b.created) { return 1 }
          if (a.created > b.created) { return -1 }
          return 0;
        });
      return {
        ...state,
        notesForCurrentCase: [...notes_case]
      };

    case NoteActions.REPLACE_NOTE_FOR_CURRENT_PERSON:
      const notes_person = state.notesForCurrentPerson.filter(x => x.id !== +action.payload.id);
      notes_person.push(action.payload);
      notes_person.sort((a, b) => {
          if (a.important && !b.important) { return -1 }
          if (!a.important && b.important) { return 1 }
          if (a.created < b.created) { return 1 }
          if (a.created > b.created) { return -1 }
          return 0;
        });
      return {
        ...state,
        notesForCurrentPerson: [...notes_person]
      };

    case NoteActions.HIGHLIGHT_NOTE:
      return {
        ...state,
        highlightedNote: action.payload
      };

    default:
      return state;
  }
}
