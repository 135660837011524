import {ITeamMemberState} from './team-member-state.interface';
import * as TeamMemberActions from './team-member.actions';

const initialState: ITeamMemberState = {
  teamMembersForCurrentCase: [],
};

export function teamMemberReducer(state = initialState, action: TeamMemberActions.TeamMemberActions) {
  switch(action.type) {

    case TeamMemberActions.REPLACE_TEAM_MEMBER_LIST_FOR_CURRENT_CASE:
      return {
        ...state,
        teamMembersForCurrentCase: action.payload
      };

    default:
      return state;
  }
}
