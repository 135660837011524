import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AppEntryComponent} from './components/app-entry/app-entry.component';
import {LoginComponent} from './components/login/login.component';
import {AuthGuardService} from './services/auth-guard.service';
import {StammbaumEntryComponent} from './stammbaum/stammbaum-entry/stammbaum-entry.component';


const appRoutes: Routes = [
  // { path: 'show-note', component: SimpleShowNoteComponent, canActivate: [AuthGuard], pathMatch: 'full'}, // TODO implement - just display the note and enable answering it
  { path: 'login', component: LoginComponent, pathMatch: 'full'},
  { path: '', component: AppEntryComponent, canActivate: [AuthGuardService], pathMatch: 'full'},
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      // scrollPositionRestoration: 'enabled',
      // anchorScrolling: 'enabled',
      // enableTracing: true // debugging
    })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {}


