import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {KassenbuchService} from '../kassenbuch.service';

@Component({
  selector: 'app-kassenbuch-menu-for-main',
  templateUrl: './kassenbuch-menu-for-main.component.html',
  styleUrls: ['./kassenbuch-menu-for-main.component.css']
})
export class KassenbuchMenuForMainComponent implements OnInit, OnDestroy {
  activeSubpage: string;
  subpageSubscription: Subscription;
  constructor(private kassenbuchService: KassenbuchService) { }

  ngOnInit() {
    this.subpageSubscription = this.kassenbuchService.kassenbuchMainSubpageSub.subscribe(
      (subpage) => {
        this.activeSubpage = subpage;
      }
    );
  }

  ngOnDestroy() {
    this.subpageSubscription.unsubscribe();
  }

  navigate(subpage: string) {
    this.kassenbuchService.setKassenbuchSubpage(subpage);
  }
}
