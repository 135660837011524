import {Component, OnDestroy, OnInit} from '@angular/core';
import {IAutoparserPostprocessedEntry, IAutoparserState} from '../../store/autoparser-state.interface';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import * as AutoparserActions from '../../store/autoparser.actions';
import {AutoparserService} from '../../services/autoparser.service';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';
import {NvzApResultListPopupComponent} from '../nvz-ap-result-list-popup/nvz-ap-result-list-popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LineImagePopupComponent} from '../line-image-popup/line-image-popup.component';

@Component({
  selector: 'nvz-ap-result-list',
  templateUrl: './nvz-ap-result.component.html',
  styleUrls: ['./nvz-ap-result.component.css']
})
export class NvzApResultComponent implements OnInit, OnDestroy {
  autoparserState: IAutoparserState;
  autoparserStateSub: Subscription;

  constructor(private store: Store<{autoparser: IAutoparserState}>,
              private autoparserService: AutoparserService,
              private ngbModal: NgbModal) {
    this.autoparserStateSub = this.store.select('autoparser').subscribe(
      (autoparserState) => {
        this.autoparserState = autoparserState;
      }
    );
  }

  ngOnInit(): void {
    this.autoparserService.getDataExportList(this.autoparserState.activeDir.id);
  }

  ngOnDestroy(): void {
    this.autoparserStateSub.unsubscribe();
  }

  back() {
    this.store.dispatch(new AutoparserActions.SetAutoparserActivePage('postprocess'));
  }

  edit(entry: IAutoparserPostprocessedEntry) {
    const modalRef = this.ngbModal.open(NvzApResultListPopupComponent, {size: 'lg'});
    modalRef.componentInstance.entry = entry;
    modalRef.componentInstance.allExistingEntries = this.autoparserState.postprocessedEntries;
    modalRef.result.then(
      (result) => {
        if (result && result.entry) {
          this.autoparserService.changeDataForImport(result.entry).subscribe(
            (result_html) => {
              this.autoparserService.getDataExportList(this.autoparserState.activeDir.id);
            },
            (error_html) => {
              // show error?
            },
            () => {}
          );
        }
        },
      () => {}
    );
  }

  show(entry: IAutoparserPostprocessedEntry) {
    const modalRef = this.ngbModal.open(LineImagePopupComponent, {size: 'lg', backdrop: 'static'});
    const filename_row_highlight_image = ['https://db.ilanot.de/backend/autoparser/view-image/?filename=highlight-row-' + entry.row_count + '.jpg&document=' + this.autoparserState.activeDir.name + '&folder=partial-pics'];
    modalRef.componentInstance.urls = filename_row_highlight_image;
  }

  delete(id: number) {
    const modalRef = this.ngbModal.open(ConfirmModalComponent, {size: 'sm'});
    modalRef.componentInstance.message = 'Wollen Sie diesen Eintrag wirklich löschen?';
    modalRef.componentInstance.title = 'Bestätigung';
    modalRef.result.then(
      (result) => {
        if (result) {
          this.autoparserService.deleteDataForImport(id).subscribe(
            (result_html) => {
              this.autoparserService.getDataExportList(this.autoparserState.activeDir.id);
            },
            (error) => {
              // show error?
            },
            () => {}
          );
        }
      }
    );
  }
}
