import {Action} from "@ngrx/store";
import {IAddressbookInstitution} from "../addressbook/addressbook-institution.interface";

export const SET_ACTIVE_ADDRESSBOOK_ENTRY = 'SET_ACTIVE_ADDRESSBOOK_ENTRY';

export class SetActiveAddressbookEntry implements Action {
  readonly type = SET_ACTIVE_ADDRESSBOOK_ENTRY;
  constructor(public payload: IAddressbookInstitution) {}
}

export type AddressbookEntryActions = SetActiveAddressbookEntry;
