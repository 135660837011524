import {Component, OnDestroy} from '@angular/core';
import {Subscription } from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {IUi} from '../../models/ui.interface';
import {MODULE_PAGES, MODULES} from '../../routing/routes.model';
import * as UiActions from '../../store/ui.actions';
import {ICalendarEntryState} from '../../store/calendar-entry-state.interface';
import {ICalendarEntry} from '../calendar-entry.interface';
import {IAuthUser} from '../../models/auth-user.interface';
import {IUser} from '../../models/user.interface';
import {IUserState} from '../../store/user-state.interface';
import {CalendarPopupComponent} from '../calendar-popup/calendar-popup.component';
import * as CalendarEntryActions from '../../store/calendar-entry.actions';
import {CalendarEntryService} from '../../services/calendar-entry.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'calendar-list',
  templateUrl: './calendar-list.component.html',
  styleUrls: ['./calendar-list.component.css']
})

export class CalendarListComponent implements OnDestroy {
  calendarEntriesSub: Subscription;
  calendarEntriesOwn: ICalendarEntry[];
  calendarEntriesOther: ICalendarEntry[];
  calendarEntriesDone: ICalendarEntry[];
  authUser: IAuthUser;
  authUserSub: Subscription;
  usersStateSub: Subscription;
  users: IUser[] = [];


  constructor(private store: Store<{ui: IUi, calendarEntry: ICalendarEntryState, authUser: {authUser: IAuthUser}, users: IUserState}>,
              private modalService: NgbModal, public calendarEntryService: CalendarEntryService,
              public userService: UserService) {
    this.usersStateSub = this.store.select('users').subscribe(
      (users) => {
        this.users = users.users;
      }
    );
    this.authUserSub = this.store.select('authUser').subscribe((authUser) => {this.authUser = authUser.authUser;});
    this.calendarEntriesSub = this.store.select('calendarEntry').subscribe(
      (entries: {calendarEntries: ICalendarEntry[]}) => {
        const entriesCopy: ICalendarEntry[] = [...entries.calendarEntries];
        // entriesCopy = entries.calendarEntries.sort((a, b) => a.deadline_timestamp.getTime() - b.deadline_timestamp.getTime()); is done in backend now
        this.calendarEntriesOther = entriesCopy.filter(x => (x.created_by_user_id == this.authUser.user.id) && (x.for_user_id != this.authUser.user.id) && !x.completed);
        this.calendarEntriesOwn = entriesCopy.filter(x => (x.for_user_id == this.authUser.user.id) && !x.completed);
        this.calendarEntriesDone = entriesCopy.filter(x => x.completed && ((new Date().getTime() - x.deadline_timestamp.getTime()) < 1000 * 3600 * 24 * 14));
      }
    );
  }

  showMonth() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.CALENDAR, page: MODULE_PAGES.CALENDAR.MONTH}));
  }

  showFormOwn() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.CALENDAR, page: MODULE_PAGES.CALENDAR.FORM_OWN}));
  }

  showForm() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.CALENDAR, page: MODULE_PAGES.CALENDAR.FORM}));
  }

  openDetails(calendarEntry: ICalendarEntry) {
    this.store.dispatch(new CalendarEntryActions.SetActiveEntry(calendarEntry));
    const modalRef = this.modalService.open(CalendarPopupComponent);
  }

  ngOnDestroy() {
    this.calendarEntriesSub.unsubscribe();
    this.authUserSub.unsubscribe();
    this.usersStateSub.unsubscribe();
  }
}
