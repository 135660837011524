import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EditPersonComponent} from '../edit-person/edit-person.component';
import {FormControl, FormGroup} from '@angular/forms';
import {CaseService} from '../../services/case.service';
import {ConstantsService} from '../../services/constants.service';
import {IStammbaumMarriage} from '../stammbaum-marriage.interface';
import {Store} from '@ngrx/store';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {PersonType, StammbaumService} from '../../services/stammbaum.service';

@Component({
  selector: 'stammbaum-list-persons-without-version',
  templateUrl: './stammbaum-list-persons-without-version.component.html',
  styleUrls: ['./stammbaum-list-persons-without-version.component.css']
})

// this component is for the display and handling of those cases that still use the old Urkunden-Modul instead of the new Stammbaum-Modul

export class StammbaumListPersonsWithoutVersionComponent implements OnChanges {
  @Input() stammbaumState;
  personToUploadFor: IStammbaumPerson = null;
  stammbaumPersons: IStammbaumPerson[];
  stammbaumMarriages: IStammbaumMarriage[];
  stammbaumPersonsToShow: IStammbaumPerson[];
  filterForm: FormGroup;
  showInactive = false;
  sortingNachname = false;
  sortingVorname = false;
  sortingGeburtsname = false;
  person_type_options;

  constructor(private ngbModal: NgbModal,
              private caseService: CaseService,
              private stammbaumService: StammbaumService,
              private store: Store<{ stammbaum: IStammbaumState }>,
              private constantsService: ConstantsService) {
    this.person_type_options = this.stammbaumService.person_type_options;
    this.filterForm = new FormGroup({
        'name': new FormControl('')
      }
    );
    this.filterForm.valueChanges.subscribe(
      (form) => {
        this.copyAndRunFilters();
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.stammbaumState) {
      const stammbaumState: IStammbaumState = changes.stammbaumState.currentValue;
      this.personToUploadFor = stammbaumState.personForUpload;
      this.stammbaumMarriages = stammbaumState.stammbaumMarriagesWithoutVersion;
      let pers = stammbaumState.stammbaumPersonsWithoutVersion;
      pers = pers.filter(x => {
        const person_type = this.person_type_options.find(z => z.id === x.person_type);
        if (person_type && person_type.is_in_tree) {
          return true;
        }
        return false;
      });
      this.stammbaumPersons = pers;
      this.copyAndRunFilters();
    }
  }

  resetSorting() {
    this.sortingGeburtsname = false;
    this.sortingNachname = false;
    this.sortingVorname = false;
  }

  sortVorname() {
    this.resetSorting();
    this.sortingVorname = true;
    this.copyAndRunFilters();
  }

  sortNachname() {
    this.resetSorting();
    this.sortingNachname = true;
    this.copyAndRunFilters();
  }

  sortGeburtsname() {
    this.resetSorting();
    this.sortingGeburtsname = true;
    this.copyAndRunFilters();
  }

  addPerson() {
    this.store.dispatch(new StammbaumActions.ReplacePersonToEditId(null));
    const modalRef: NgbModalRef = this.ngbModal.open(EditPersonComponent, <NgbModalOptions>{size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.editMode = false;
    modalRef.result.then(
      (result) => {
      },
      (result) => {
      }
    );
  }

  createNewPerson() {
    let modalRef: NgbModalRef;
    modalRef = this.ngbModal.open(EditPersonComponent, <NgbModalOptions>{size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.editMode = false;
    modalRef.componentInstance.hasTrueStammbaum = false;
    modalRef.result.then(
      (result) => {},
      () => {}
    );
  }

  printUrkundenliste() {
    const case_id = this.caseService.getActiveCaseId();
    const token = localStorage.getItem('token');
    const mapForm = document.createElement('form');
    mapForm.style.display = 'none';
    mapForm.target = 'Urkundenliste Fall ' + case_id;
    mapForm.method = 'POST'; // or "post" if appropriate
    mapForm.action = this.constantsService.getApiEndpoint() + '/v3/urkundenliste-as-pdf/';
    let mapInput = document.createElement('input');
    mapInput.type = 'text';
    mapInput.name = 'api_token';
    mapInput.value = token;
    const case_id_f = document.createElement('input');
    case_id_f.type = 'text';
    case_id_f.name = 'case_id';
    case_id_f.value = case_id.toString();
    mapForm.appendChild(mapInput);
    mapForm.appendChild(case_id_f);
    document.body.appendChild(mapForm);
    let map = window.open('', 'Urkundenliste Fall ' + case_id);
    if (map) {
      mapForm.submit();
    }
  }

  toggleInactive() {
    this.showInactive = !this.showInactive;
    this.copyAndRunFilters();
  }

  copyAndRunFilters() {
    this.stammbaumPersonsToShow = [];
    let persons: IStammbaumPerson[] = [];
    if (!this.showInactive) {
      persons = this.stammbaumPersons.filter(x => x.is_active);
    } else {
      persons = this.stammbaumPersons;
    }

    // only display persons that are in the tree (i.e. no namensträger)
    persons = persons.filter(x => {
      const person_type = this.stammbaumService.person_type_options.find(z => z.id === x.person_type);
      if (person_type && person_type.is_in_tree) {
        return true;
      }
      return false;
    });

    if (this.filterForm.controls.name.value !== '') {
      const fields: string[] = this.filterForm.controls.name.value.split(' ');
      for (const field of fields) {
        persons = persons.filter(x =>
          x.vorname.toLowerCase().includes(field.toLowerCase()) ||
          x.nachname.toLowerCase().includes(field.toLowerCase()) ||
          x.geburtsname.toLowerCase().includes(field.toLowerCase())
        );
      }
    }
    if (this.sortingGeburtsname) {
      persons.sort((a, b) => (a.geburtsname.localeCompare(b.geburtsname)));
    }
    if (this.sortingNachname) {
      persons.sort((a, b) => (a.nachname.localeCompare(b.nachname)));
    }
    if (this.sortingVorname) {
      persons.sort((a, b) => (a.vorname.localeCompare(b.vorname)));
    }
    this.stammbaumPersonsToShow = persons;
  }
}
