import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import { ImageViewerModule } from "ngx-image-viewer";
import {NvzApEntryComponent} from "./nvz-ap-entry/nvz-ap-entry.component";
import {NvzApMessagesComponent} from "./nvz-ap-messages/nvz-ap-messages.component";
import {NvzApEditResultsComponent} from "./nvz-ap-edit-results/nvz-ap-edit-results.component";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NvzPageSettingsComponent} from './nvz-page-settings/nvz-page-settings.component';
import {ToolsModule} from '../tools/tools.module';
import {NvzApResultComponent} from './nvz-ap-result-list/nvz-ap-result.component';
import {NvzApResultListPopupComponent} from './nvz-ap-result-list-popup/nvz-ap-result-list-popup.component';
import {NvzApConfigComponent} from './nvz-ap-config/nvz-ap-config.component';
import {LineImagePopupComponent} from './line-image-popup/line-image-popup.component';

@NgModule({
  entryComponents: [
    NvzApEditResultsComponent,
    NvzPageSettingsComponent,
    NvzApResultListPopupComponent,
    LineImagePopupComponent
  ],
  declarations: [
    NvzApEntryComponent,
    NvzApResultComponent,
    NvzApMessagesComponent,
    NvzApEditResultsComponent,
    NvzPageSettingsComponent,
    NvzApResultListPopupComponent,
    NvzApConfigComponent,
    LineImagePopupComponent
  ],
  imports: [
    CommonModule,
    ImageViewerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ToolsModule
  ],
  providers: [],
  exports: [
    NvzApEntryComponent
  ]
})
export class NvzAutoparserModule {}
