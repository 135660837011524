import { Component, OnInit, ViewChild, ElementRef, HostListener} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {INVZStandesamt} from "../nvz-standesamt.interface";
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import * as NVZOptions from '../nvz-options';
import {INVZVerzeichnis} from "../nvz-verzeichnis.interface";
import {NVZService} from "../../services/nvz.service";

@Component({
  selector: 'nvz-verzeichnisse-check',
  templateUrl: './nvz-verzeichnisse-check.component.html',
  styleUrls: ['./nvz-verzeichnisse-check.component.css']
})
export class NVZVerzeichnisseCheckComponent implements OnInit {

  verzeichnis: INVZVerzeichnis = <INVZVerzeichnis>{};
  form: FormGroup;
  options = NVZOptions.NVZVerzeichnisStatusOptions;
  urkundenarten = NVZOptions.NVZUrkundenArt;
  first_number: number = null;
  last_number: number = null;
  total_number: number = null;
  double_numbers = [];
  multiple_numbers = [];
  missing_numbers = [];

  constructor(private activeModal: NgbActiveModal, private nvzService: NVZService) { }

  ngOnInit() {
    this.nvzService.checkVerzeichnis(this.verzeichnis.id).subscribe(
      (result) => {
        this.first_number = result.first;
        this.last_number = result.last;
        this.double_numbers = result.double;
        this.multiple_numbers = result.multiples;
        this.missing_numbers = result.missing;
        this.total_number = result.total;
      }
    );
  }

  back() {
    this.activeModal.close();
  }
}
