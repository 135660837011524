import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {isNullOrUndefined} from "util";

@Component({
  selector: 'editfield-select',
  templateUrl: './editfield-select.component.html',
  styleUrls: ['./editfield-select.component.css']
})
export class EditfieldSelectComponent implements OnInit, OnChanges {

  @Input() select: number = 0;
  @Input() editMode: boolean = false;
  @Input() options: any;
  @Output() selectChange: EventEmitter<number> = new EventEmitter<number>();
  cssClass: string = "";
  selectDisplay: string = "";

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.parse();
  }

  parse() {
    for (let item of this.options) {
      if (item.id == this.select) {
        this.selectDisplay = item.description;
      }
    }
  }
}
