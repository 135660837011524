import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription, timer} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { AuthenticationService } from './authentication.service';
import {INote} from '../notes/note.interface';
import {INetworkState} from '../store/network-state.interface';
import * as NetworkActions from '../store/network.actions';
import * as NoteActions from '../store/note.actions';
import * as NoteDraftActions from '../store/note-draft.actions';
import {IAuthUser} from '../models/auth-user.interface';
import {ICaseState} from '../store/case-state.interface';
import * as AppNotificationActions from '../store/app-notification.actions';
import {
  NOTIFICATION_MESSAGE_CASEDETAIL_LOADER_LOADING,
  NOTIFICATION_MESSAGE_FIND_NOTE_FAILED_ERROR,
  NOTIFICATION_MESSAGE_FIND_NOTE_IN_PROGRESS,
  NOTIFICATION_MESSAGE_NOTE_LOAD_ERROR,
  NOTIFICATION_MESSAGE_NOTE_LOAD_TIMEOUT,
  NOTIFICATION_MESSAGE_NOTE_LOADING,
  NOTIFICATION_SLOT_CASEDETAIL_LOADER,
  NOTIFICATION_SLOT_FIND_NOTE,
  NOTIFICATION_SLOT_NOTE
} from '../app-notifications/app-notification.interface';
import {CaseService} from './case.service';
import {PageScrollService} from 'ngx-page-scroll-core';
import {DOCUMENT} from '@angular/common';
import {INoteDraft} from '../notes/note-draft.interface';
import {RightsManagementService} from "./rights-management.service";

const TRY_RELOAD_INTERVAL = 10 * 1000; // 10 Sekunden

@Injectable()
export class NoteService implements OnDestroy {

  recentNotesLoadingInProgress = false;
  notesForCaseLoadingInProgress = false;
  notesForPersonLoadingInProgress = false;
  reloadTimerSub: Subscription;
  network: INetworkState;
  networkSub: Subscription;
  authUser: IAuthUser;
  authUserSub: Subscription;
  activeCaseId: number;
  activeCaseSub: Subscription;
  activePersonId: number;
  scrollToNoteId: number = null;
  scrollToNoteSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null)

  constructor(private constantsService: ConstantsService, private http: HttpClient,
              private store: Store<{network: INetworkState, authUser: {authUser: IAuthUser}, cases: ICaseState}>,
              private authenticationService: AuthenticationService,
              private rightsManagementService: RightsManagementService,
              private caseService: CaseService,
              private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) {
    this.networkSub = this.store.select('network').subscribe((network) => { this.network = network});
    this.authUserSub = this.store.select('authUser').subscribe((authUser) => { this.authUser = authUser.authUser});
    this.activeCaseSub = this.store.select('cases').subscribe(
      (cases) => {
        this.activeCaseId = cases.activeCaseId;
        this.activePersonId = cases.activePersonId;
      }
    );
    this.reloadTimerSub = timer(0, TRY_RELOAD_INTERVAL).subscribe(t => {
      if (this.network.updateNeededNotes && this.authUser.loggedIn && this.network.isConnectedToServer) { // if user is logged in, reload is neccessary and there is a connection
        this.getRecentNotesFromServer();
      }
      if (this.network.updateNeededNotesForCase && this.authUser.loggedIn && this.network.isConnectedToServer) { // if user is logged in, reload is neccessary and there is a connection
        this.getNotesForCaseFromServer();
      }
      if (this.network.updateNeededNotesForPerson && this.authUser.loggedIn && this.network.isConnectedToServer) { // if user is logged in, reload is neccessary and there is a connection
        this.getNotesForPersonFromServer();
      }
    });
  }

  triggerNotesReloadFromServer() {
    this.store.dispatch(new NetworkActions.SetUpdateNeededNotesTrue());
    this.getRecentNotesFromServer();
  }

  triggerNotesForCaseReloadFromServer() {
    if (this.activeCaseId) {
      this.store.dispatch(new NetworkActions.SetUpdateNeededNotesForCaseTrue());
      this.getNotesForCaseFromServer();
    }
  }

  triggerNotesForPersonReloadFromServer() {
    if (this.activePersonId) {
      this.store.dispatch(new NetworkActions.SetUpdateNeededNotesForPersonTrue());
      this.getNotesForPersonFromServer();
    }
  }

  getRecentNotesFromServer() {
    if (this.recentNotesLoadingInProgress) {
      return; // if loading already in progress, ignore!
    }
    this.recentNotesLoadingInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NOTE));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_NOTE_LOADING));
    this.http.get<INote[]>(`${this.constantsService.getApiEndpoint()}/v2/recent-notes/`, {headers: this.constantsService.getHttpOptions()}).
      subscribe(
      (response) => {
        this.store.dispatch(new NoteActions.ReplaceRecentNotesList(response));
        this.store.dispatch(new NetworkActions.SetUpdateNeededNotesFalse());
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NOTE));
        this.store.dispatch(new NetworkActions.AddMessage({event_timestamp: null, text: 'Der Notizenverlauf wurde geladen', level: 0}));
        this.recentNotesLoadingInProgress = false;
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NOTE));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_NOTE_LOAD_ERROR));
        this.recentNotesLoadingInProgress = false;
      },
      () => {
        if (this.recentNotesLoadingInProgress) {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NOTE));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_NOTE_LOAD_TIMEOUT));
          this.store.dispatch(new NetworkActions.AddMessage({event_timestamp: null, text: 'Fehler beim Laden des Notizenverlaufs', level: 2}));
          this.recentNotesLoadingInProgress = false;
        }
      }
    );
  }

  getNotesForCaseFromServer() {
    if (this.notesForCaseLoadingInProgress) {
      return; // if loading already in progress, ignore!
    }
    this.notesForCaseLoadingInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NOTE));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_NOTE_LOADING));
    this.http.get<INote[]>(`${this.constantsService.getApiEndpoint()}/v2/notes-for-case/${this.activeCaseId}/`, {headers: this.constantsService.getHttpOptions()}).
      subscribe(
      (response) => {
        this.store.dispatch(new NoteActions.ReplaceNotesListForCurrentCase(response));
        this.store.dispatch(new NetworkActions.SetUpdateNeededNotesForCaseFalse());
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NOTE));
        this.store.dispatch(new NetworkActions.AddMessage({event_timestamp: null, text: `Die Notizen für Fall ${this.activeCaseId} wurden geladen`, level: 0}));
        this.notesForCaseLoadingInProgress = false;
      },
      (error) => {
        this.notesForCaseLoadingInProgress = false;
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NOTE));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_NOTE_LOAD_ERROR));
        this.store.dispatch(new NetworkActions.AddMessage({event_timestamp: null, text: `Fehler beim Laden der Notizen für Fall ${this.activeCaseId}`, level: 2}));
      },
      () => {
        if (this.notesForCaseLoadingInProgress) {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NOTE));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_NOTE_LOAD_TIMEOUT));
          this.notesForCaseLoadingInProgress = false;
        }
      }
    );
  }

  getNotesForPersonFromServer() {
    if (this.notesForPersonLoadingInProgress) {
      return; // if loading already in progress, ignore!
    }
    this.notesForPersonLoadingInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NOTE));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_NOTE_LOADING));
    this.http.get<INote[]>(`${this.constantsService.getApiEndpoint()}/v2/notes-for-person/${this.activePersonId}/`, {headers: this.constantsService.getHttpOptions()}).
      subscribe(
      (response) => {
        this.store.dispatch(new NoteActions.ReplaceNotesListForCurrentPerson(response));
        this.store.dispatch(new NetworkActions.SetUpdateNeededNotesForPersonFalse());
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NOTE));
        this.store.dispatch(new NetworkActions.AddMessage({event_timestamp: null, text: `Die Notizen für Person ${this.activePersonId} wurden geladen`, level: 0}));
        this.notesForPersonLoadingInProgress = false;
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NOTE));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_NOTE_LOAD_ERROR));
        this.store.dispatch(new NetworkActions.AddMessage({event_timestamp: null, text: `Fehler beim Laden der Notizen für Person ${this.activePersonId}`, level: 2}));
        this.notesForPersonLoadingInProgress = false;
      },
      () => {
        if (this.notesForPersonLoadingInProgress) {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NOTE));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_NOTE_LOAD_TIMEOUT));
          this.notesForPersonLoadingInProgress = false;
        }
      }
    );
  }

  goToNote(id: number) {
    this.scrollToNoteSubject.next(null);
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_FIND_NOTE));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_FIND_NOTE_IN_PROGRESS));
    this.http.get<INote>(`${this.constantsService.getApiEndpoint()}/v3/get-note/?note_id=${id}`, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (result) => {
        if (!result || !result.hasOwnProperty('id')) {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_FIND_NOTE));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_FIND_NOTE_FAILED_ERROR));
          return;
        }
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_FIND_NOTE));
        if (+result.folder_id === 0) { // normale Recherche-Notizen
          if (!(+result.person_id > 0)) {
            this.caseService.goToCasedetailId(+result.case_id, '', false, 2);
          } else {
            this.caseService.goToPersondetailId(+result.person_id);
          }
        } else {
          if (+result.folder_id === 1 && this.rightsManagementService.isAllowedToViewAuskehrTabInCase()) { // Auskehr-Notizen
            this.caseService.goToCasedetailId(+result.case_id, '', false, 11);
          }
        }
        setTimeout(() => {
          this.scrollToNote(id);
        }, 2000);
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_FIND_NOTE));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_FIND_NOTE_FAILED_ERROR));
      }
    );
  }

  scrollToNote(id: number) {
    this.scrollToNoteSubject.next(id);
    // this.pageScrollService.scroll({
    //   document: this.document,
    //   scrollTarget: '#ac' + id.toString() + '-header',
    //   scrollOffset: 100
    // });
    this.store.dispatch(new NoteActions.HighlightNote(id));
    setTimeout(() => {
          this.scrollToNoteSubject.next(null);
        }, 2000);
  }

  createCalendarEntryToNoteId(noteId: number, reminder, userId: number | null = null) {
    const body = {
      note: noteId,
      reminder_date: reminder.date,
      deadline_hour: reminder.hour,
      deadline_minute: reminder.minute
    }
    if (userId !== null) {
      body['for_user_id'] = userId;
    }
    return this.http.post(`${this.constantsService.getApiEndpoint()}/v2/note-create-calendar-entry/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  save(noteId: number, response: string): Observable<any> {
    const body = {result: response};
    return this.http.post(`${this.constantsService.getApiEndpoint()}/v5/note-response/${noteId}/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  setNoteImportant(note: INote): Observable<any> {
    return this.http.get(`${this.constantsService.getApiEndpoint()}/v2/set-note-important/${note.id}/`, {headers: this.constantsService.getHttpOptions()});
  }

  unsetNoteImportant(note: INote): Observable<any> {
    return this.http.get(`${this.constantsService.getApiEndpoint()}/v2/unset-note-important/${note.id}/`, {headers: this.constantsService.getHttpOptions()});
  }

  delete(noteId: number): Observable<any> {
    return this.http.get(`${this.constantsService.getApiEndpoint()}/v2/note-delete/${noteId}/`, {headers: this.constantsService.getHttpOptions()});
  }

  create(note: INote, forUserId: number | null = null): Observable<any> {
    const bodyobj: any = note;
    if (forUserId) {
      bodyobj['for_user_id'] = forUserId;
    }
    const body = JSON.stringify(bodyobj);
    return this.http.post(`${this.constantsService.getApiEndpoint()}/v2/note-create/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  searchNotes(search_strings: string, show_deleted = false) {
    const body = JSON.stringify({'search': search_strings, 'show_deleted': show_deleted});
    return this.http.post<INote[]>(`${this.constantsService.getApiEndpoint()}/v3/note-search/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  createNewNoteDraft(draft: INoteDraft) {
    this.store.dispatch(new NoteDraftActions.CreateNewNoteDraft(draft));
  }

  deleteNoteDrafts() {
    this.store.dispatch(new NoteDraftActions.DeleteNoteDraft());
  }

  ngOnDestroy () {
    this.activeCaseSub.unsubscribe();
    this.networkSub.unsubscribe();
    this.reloadTimerSub.unsubscribe();
    this.authUserSub.unsubscribe();
  }
}

