import { Component, OnInit } from '@angular/core';
import {IStammbaumPerson} from '../../stammbaum/stammbaum-person.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {StammbaumService} from '../../services/stammbaum.service';

@Component({
  selector: 'app-person-popup',
  templateUrl: './person-popup.component.html',
  styleUrls: ['./person-popup.component.css']
})
export class PersonPopupComponent implements OnInit {

  heir: IStammbaumPerson = null;

  constructor(public ngbActiveModal: NgbActiveModal,
              private stammbaumService: StammbaumService) { }

  ngOnInit() {}

  goToHeir() {
    this.ngbActiveModal.close(true);
  }

  canDisplayDetails() {
    return this.stammbaumService.canDisplayPersonDetails(this.heir);
  }

  getPersonTypeText() {
    return this.stammbaumService.getPersonTypeText(this.heir.person_type);
  }

  close() {
    this.ngbActiveModal.close(false);
  }

}
