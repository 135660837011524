import {Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {INVZStandesamt} from "../nvz-standesamt.interface";
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import * as NVZOptions from '../nvz-options';
import {NVZService} from "../../services/nvz.service";
import {INVZStadt} from "../nvz-stadt.interface";

@Component({
  selector: 'nvz-staedte-form',
  templateUrl: './nvz-staedte-form.component.html',
  styleUrls: ['./nvz-staedte-form.component.css']
})
export class NVZStaedteFormComponent implements OnInit, AfterViewInit {
  @ViewChild('name', { static: true }) nameElement: ElementRef;
  @ViewChild('comment', { static: true }) commentElement: ElementRef;
  @ViewChild('status', { static: true }) statusElement: ElementRef;
  @HostListener('window:keydown', ['$event'])

  editMode = false;
  stadt: INVZStadt = <INVZStadt>{};
  form: FormGroup;
  options = NVZOptions.NVZStadtStatusOptions;

  keyEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.key === 'Enter') {
      this.save();
    }
  }

  constructor(private activeModal: NgbActiveModal,
              private nvzService: NVZService) { }

  ngOnInit() {
    this.form = new FormGroup({
        'name': new FormControl(null, [Validators.required, Validators.maxLength(50)]),
        'comment': new FormControl(null, [Validators.maxLength(100)]),
        'type': new FormControl('', [Validators.maxLength(30)]),
        'bundesland': new FormControl('', [Validators.maxLength(30)]),
        'gemeindeschluessel': new FormControl('', [Validators.maxLength(15)]),
        'status': new FormControl(0, [Validators.required]),
      });
    if (this.editMode) {
      this.form.setValue({
        name: this.stadt.name,
        comment: this.stadt.comment,
        type: this.stadt.type,
        bundesland: this.stadt.bundesland,
        gemeindeschluessel: this.stadt.gemeindeschluessel,
        status: this.stadt.status
      })
    }
  }

  ngAfterViewInit() {
    this.nameElement.nativeElement.focus();
  }

  save() {
    if (this.form.valid) {
      this.stadt.name = this.form.controls.name.value;
      this.stadt.comment = this.form.controls.comment.value;
      this.stadt.type = this.form.controls.type.value;
      this.stadt.bundesland = this.form.controls.bundesland.value;
      this.stadt.gemeindeschluessel = this.form.controls.gemeindeschluessel.value;
      this.stadt.status = this.form.controls.status.value;
      this.activeModal.close(this.stadt);
    }
  }

  back() {
    this.activeModal.close();
  }
}
