import {Injectable, OnDestroy} from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import {Store} from '@ngrx/store';
import { HttpClient} from '@angular/common/http';
import { IFileUpload } from '../files/file-upload.interface';
import { ConstantsService } from './constants.service';
import {INetworkState} from '../store/network-state.interface';
import * as NetworkActions from '../store/network.actions';
import * as FileUploadActions from '../store/file-upload.actions';
import {IAuthUser} from '../models/auth-user.interface';
import {ICaseState} from '../store/case-state.interface';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {IStammbaumState} from '../store/stammbaum-state.interface';
import {StammbaumService} from './stammbaum.service';
import * as StammbaumActions from '../store/stammbaum.actions';

const TRY_RELOAD_INTERVAL = 10 * 1000; // 10 Sekunden

@Injectable()
export class FileUploadService implements OnDestroy {
  uploader: FileUploader; // for the new file upload manager
  uploaderFileInfo: {file: FileItem, information: IFileUpload}[] = [];
  currentFileInfo: IFileUpload;
  uploading = false;
  upload_error = false;

  fileUploadsForCaseLoadingInProgress = false;
  fileUploadsForPersonLoadingInProgress = false;
  noTimeoutFlagFileUploadsForCase = true;
  activeCaseId: number;
  activeCaseSub: Subscription;
  activePersonId: number;
  stammbaumSub: Subscription;
  stammbaumState: IStammbaumState;
  network: INetworkState;
  networkSub: Subscription;
  authUser: IAuthUser;
  authUserSub: Subscription;
  reloadTimerSub: Subscription;

  constructor(private constantsService: ConstantsService,
              private http: HttpClient,
              private store: Store<{network: INetworkState, authUser: {authUser: IAuthUser}, cases: ICaseState}>)
  {
    // setup new file upload manager
    const headers = [this.constantsService.getHeadersForFileUpload()];
    this.uploader = new FileUploader({url: `${this.constantsService.getApiEndpoint()}/v3/file-upload/`, headers: headers});

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.uploaderFileInfo.push({file: file, information: this.currentFileInfo});
    };
    this.uploader.onCompleteAll = () => {
      this.uploading = false;
    };
    this.uploader.onCompleteItem = (item, response) => {
      if (item) {
        if (!item.isError && !item.isCancel) {
          // if it is a case without true stammbaum, force a reload for this case person and marriage data!
          const fileInf = this.uploaderFileInfo.find(x => x.file === item);
          if (fileInf && fileInf.information) {
            if (fileInf.information.case_id === this.activeCaseId) {
              // todo the below does not work because of cyclic dependency - maybe move into different service? stammbaum-display.service?? (maybe rename?)
              // for the moment the logic is written explicitly again here...
              if (!this.stammbaumState.stammbaumVersions || this.stammbaumState.stammbaumVersions.length === 0) {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(fileInf.information.case_id));
              }
            }
          }
          // remove from queue
          this.uploader.removeFromQueue(item);
          this.uploaderFileInfo = this.uploaderFileInfo.filter(x => x.file !== item);
        }
      }
    };
    this.uploader.onCancelItem = (item) => {
      this.uploaderFileInfo.filter(x => x.file !== item);
    };
    this.uploader.onErrorItem = (error) => {
      console.log(error);
      this.upload_error = true;
    };
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      const fileInfo = this.uploaderFileInfo.find(x => x.file === fileItem);
      this.uploading = true;
      // console.log('info', additionalInformation);
      // console.log('fileitem', fileItem, this.additionalInformation);
      form.append('case_id', fileInfo.information.case_id);
      form.append('person_id', fileInfo.information.person_id);
      form.append('folder_id' , fileInfo.information.folder_id);
      form.append('title', fileInfo.information.title);
      form.append('description', fileInfo.information.description);
      if (fileInfo.information.stammbaum_relation) {
        form.append('stammbaum_relation', true);
        form.append('stb__person', fileInfo.information.stammbaum_relation.person);
        form.append('stb__marriage', fileInfo.information.stammbaum_relation.marriage);
        form.append('stb__urkunden_art', fileInfo.information.stammbaum_relation.urkunden_art);
        form.append('stb__urkunden_art_freitext', fileInfo.information.stammbaum_relation.urkunden_art_freitext);
        form.append('stb__urkunden_unterart', fileInfo.information.stammbaum_relation.urkunden_unterart);
        form.append('stb__urkunden_status', fileInfo.information.stammbaum_relation.urkunden_status);
        form.append('stb__kirche_oder_behoerde_freitext', fileInfo.information.stammbaum_relation.kirche_oder_behoerde_freitext);
        form.append('stb__kirche_oder_behoerde_ort', fileInfo.information.stammbaum_relation.kirche_oder_behoerde_ort);
        form.append('stb__standesamt', fileInfo.information.stammbaum_relation.standesamt);
        form.append('stb__jahr', fileInfo.information.stammbaum_relation.jahr);
        form.append('stb__urkunden_nummer', fileInfo.information.stammbaum_relation.urkunden_nummer);
        form.append('stb__is_gu', fileInfo.information.stammbaum_relation.is_gu);
        form.append('stb__is_hu', fileInfo.information.stammbaum_relation.is_hu);
        form.append('stb__is_su', fileInfo.information.stammbaum_relation.is_su);
      console.log(fileInfo.information.stammbaum_relation);
      }
     };

    this.stammbaumSub  = this.store.select('stammbaum').subscribe(
      (stammbaumState) => { this.stammbaumState = stammbaumState; }
    );
    // old below???
    this.networkSub = this.store.select('network').subscribe((network) => { this.network = network});
    this.authUserSub = this.store.select('authUser').subscribe((authUser) => { this.authUser = authUser.authUser});
    this.activeCaseSub = this.store.select('cases').subscribe(
      (cases) => {
        this.activeCaseId = cases.activeCaseId;
        this.activePersonId = cases.activePersonId;
      });
    this.reloadTimerSub = timer(0, TRY_RELOAD_INTERVAL).subscribe(t => {
      if(this.network.updateNeededFileUploadsForCase && this.authUser.loggedIn && this.network.isConnectedToServer) { // if user is logged in, reload is neccessary and there is a connection
        this.getFileUploadsForCaseFromServer();
      }
      if(this.network.updateNeededFileUploadsForPerson && this.authUser.loggedIn && this.network.isConnectedToServer) { // if user is logged in, reload is neccessary and there is a connection
        this.getFileUploadsForPersonFromServer();
      }
    });
  }

  triggerFileUploadForCaseReloadFromServer() {
    if (this.activeCaseId) {
      this.store.dispatch(new NetworkActions.SetUpdateNeededFileUploadsForCaseTrue());
      this.getFileUploadsForCaseFromServer();
    }
  }

  triggerFileUploadForPersonReloadFromServer() {
    if (this.activePersonId) {
      this.store.dispatch(new NetworkActions.SetUpdateNeededFileUploadsForPersonTrue());
      this.getFileUploadsForPersonFromServer();
    }
  }

  getFileUploadsForCaseFromServer() {
    if (this.fileUploadsForCaseLoadingInProgress) {
      return; // if loading already in progress, ignore!
    }
    this.fileUploadsForCaseLoadingInProgress = true;
    this.noTimeoutFlagFileUploadsForCase = false;
    this.http.get<IFileUpload[]>(`${this.constantsService.getApiEndpoint()}/v2/file-uploads-for-case/${this.activeCaseId}/`, {headers: this.constantsService.getHttpOptions()}).
      subscribe(
      (response) => {
        this.store.dispatch(new FileUploadActions.ReplaceFileUploadsForCurrentCase(response));
        this.store.dispatch(new NetworkActions.SetUpdateNeededFileUploadsForCaseFalse());
        this.noTimeoutFlagFileUploadsForCase = true;
        this.fileUploadsForCaseLoadingInProgress = false;
      },
      (error) => {
        this.noTimeoutFlagFileUploadsForCase = true;
        this.fileUploadsForCaseLoadingInProgress = false;
      },
      () => {
        if (!this.noTimeoutFlagFileUploadsForCase) { // display error
        }
        this.fileUploadsForCaseLoadingInProgress = false;
      }
    );
  }

  getFileUploadsForPersonFromServer() {
    if (this.fileUploadsForPersonLoadingInProgress) {
      return; // if loading already in progress, ignore!
    }
    this.fileUploadsForPersonLoadingInProgress = true;
    this.http.get<IFileUpload[]>(`${this.constantsService.getApiEndpoint()}/v2/file-uploads-for-person/${this.activePersonId}/`, {headers: this.constantsService.getHttpOptions()}).
      subscribe(
      (response) => {
        this.fileUploadsForPersonLoadingInProgress = false;
        this.store.dispatch(new FileUploadActions.ReplaceFileUploadsForCurrentPerson(response));
        this.store.dispatch(new NetworkActions.SetUpdateNeededFileUploadsForPersonFalse());
      },
      (error) => {
        this.fileUploadsForPersonLoadingInProgress = false;
        // todo display error
      },
      () => {
        if (this.fileUploadsForPersonLoadingInProgress) { // todo display error
          this.fileUploadsForPersonLoadingInProgress = false;
        }
      }
    );
  }

  showFile(element) {
    let token = localStorage.getItem('token');
    let mapForm = document.createElement("form");
    mapForm.style.display = "none";
    mapForm.target = "Datei" + element.id;
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = this.constantsService.getApiEndpoint() + "/v3/get-file/";
    let mapInput = document.createElement("input");
    mapInput.type = "text";
    mapInput.name = "api_token";
    mapInput.value = token;
    let file_id = document.createElement("input");
    file_id.type = "text";
    file_id.name = "file_id";
    file_id.value = element.id;
    mapForm.appendChild(mapInput);
    mapForm.appendChild(file_id);
    document.body.appendChild(mapForm);
    let map = window.open("", "Datei" + element.id);
    if (map) {
      mapForm.submit();
    }
  }


  getFolderList() {
    const folderList = [
      {id: 0, description: 'sonstiges'},
      {id: 1, description: 'Stammbaum'},
      {id: 2, description: 'Urkunden'},
      {id: 3, description: 'V+V'},
      {id: 4, description: 'Akteneinsicht'},
      {id: 5, description: 'EBS-Urkunden'}
    ];
    return folderList;
  }


  changeFolder(fileId: number, folderId: number) {
    const body = '{"id": ' + fileId + ', "folder_id": ' + folderId + '}';
    return this.http.post(`${this.constantsService.getApiEndpoint()}/file_move/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  changeFilename(fileId: number, filename: string, description: string, title: string) {
    if (filename === '' || title === '') {
      return;
    }
    const body = '{"file_id": ' + fileId + ', "filename": "' + filename + '", "description": "' + description + '", "title": "' + title + '"}';
    return this.http.post(`${this.constantsService.getApiEndpoint()}/v3/rename-file/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  delete(fileId: number) : Observable<any>{
    return this.http.get(`${this.constantsService.getApiEndpoint()}/file_delete/${fileId}/`, {headers: this.constantsService.getHttpOptions()});
  }

  uploadFile(fileList: FileList, id: number, case_id: number, file_title: string, file_description: string, folder_id: number) {
    let file: File = fileList[0];
    let formData: FormData = new FormData();
    let user_id: number = this.authUser.user.id;

    if (case_id==null) {
      case_id = 0
    }
    if (user_id==null) {
      user_id = 0
    }

    formData.append('person_id', id.toString());
    formData.append('case_id', case_id.toString());
    formData.append('title', file_title);
    formData.append('description', file_description);
    formData.append('user_id', user_id.toString());
    formData.append('folder_id', folder_id.toString());
    formData.append('file', file);

    return this.http.post(`${this.constantsService.getApiEndpoint()}/person_file_upload/`, formData, {headers: this.constantsService.getHttpFileUploadOptions()});
  }

  ngOnDestroy() {
    this.authUserSub.unsubscribe();
    this.reloadTimerSub.unsubscribe();
    this.networkSub.unsubscribe();
    this.activeCaseSub.unsubscribe();
    this.stammbaumSub.unsubscribe();
  }
}
