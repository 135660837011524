import {Component, Input} from "@angular/core";
import {IUi} from "../models/ui.interface";
import {ICaseState} from "../store/case-state.interface";
import {IAuthUser} from "../models/auth-user.interface";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {RightsManagementService} from "../services/rights-management.service";
import {INetworkState} from "../store/network-state.interface";
import {IUserRole} from "../models/userrole.interface";
import {IUserState} from "../store/user-state.interface";
import {ICaseStatus} from "../models/case-status.interface";
import {INoteState} from "../store/note-state.interface";

@Component({
  selector: 'debug-entry',
  templateUrl: './debug-entry.component.html',
  styleUrls: ['debug-entry.component.css']
})

export class DebugEntryComponent {
  uiObs: Observable<{ui: IUi}>;
  casesObs: Observable<ICaseState>;
  authUserObs: Observable<{authUser: IAuthUser}>;
  networkObs: Observable<INetworkState>;
  userRolesObs: Observable<IUserRole[]>;
  userObs: Observable<IUserState>;
  caseStatusObs: Observable<ICaseStatus[]>;
  notesObs: Observable<INoteState>;
  showDebug: boolean = false;

  constructor(private store: Store<{notes: INoteState, caseStatus: ICaseStatus[], ui: {ui: IUi}, authUser: {authUser: IAuthUser}, cases: ICaseState, network: INetworkState, userRoles: IUserRole[], users: IUserState}>,
              public rightsManagementService: RightsManagementService) {
    this.uiObs = this.store.select('ui');
    this.authUserObs = this.store.select('authUser');
    this.casesObs = this.store.select('cases');
    this.networkObs = this.store.select('network');
    this.userRolesObs = this.store.select('userRoles');
    this.userObs = this.store.select('users');
    this.caseStatusObs = this.store.select('caseStatus');
    this.notesObs = this.store.select('notes');
  }

  onToggleDebug() {
    this.showDebug = !this.showDebug;
  }
}
