import {Action} from "@ngrx/store";
import {IUserRole} from "../models/userrole.interface";

export const SET_USER_ROLES = 'SET_USER_ROLES';

export class SetUserRoles implements Action {
  readonly type = SET_USER_ROLES;
  constructor(public payload: IUserRole[]) {}
}

export type UserRoleActions = SetUserRoles;
