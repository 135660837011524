import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {IGeneratedDocumentState} from "../../store/generated-document-state.interface";
import {IGeneratedDocTemplate} from "../generated-doc-template.interface";
import {IGeneratedDocRecipient} from "../generated-doc-recipient.interface";
import {GeneratedDocumentEmailParserService} from "../../services/generated-document-email-parser.service";

@Component({
  selector: 'edit-document',
  templateUrl: './edit-document.component.html',
  styleUrls: ['./edit-document.component.css']
})
export class EditDocumentComponent implements OnDestroy {
  templateSub: Subscription;
  template: IGeneratedDocTemplate = null;
  recipients: IGeneratedDocRecipient[] = [];
  text: string = '';
  subject: string = '';
  case_id: number = null;
  person_id: number = null;

  constructor(private store: Store<{generatedDocument: IGeneratedDocumentState}>,
              private generatedDocumentEmailParserService: GeneratedDocumentEmailParserService) {
    this.templateSub = this.store.select('generatedDocument').subscribe(
      (generatedDocumentState) => {
        this.template = generatedDocumentState.template_selected;
        this.recipients = generatedDocumentState.recipients_selected;
        this.text = this.template.body_text;
        this.subject = this.template.subject_text;
        this.case_id = generatedDocumentState.case_id_selected;
        this.person_id = generatedDocumentState.person_id_selected;
        this.parse();
      }
    );
  }

  parse() {
    this.text = this.generatedDocumentEmailParserService.parse(this.text, this.case_id, this.person_id);
    this.subject = this.generatedDocumentEmailParserService.parse(this.subject, this.case_id, this.person_id);
  }

  ngOnDestroy() {
    this.templateSub.unsubscribe();
  }
}
