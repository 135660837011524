import { NgModule } from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import { KassenbuchListForCaseComponent } from './kassenbuch-list-for-case/kassenbuch-list-for-case.component';
import { KassenbuchForMainMenuComponent } from './kassenbuch-for-main-menu/kassenbuch-for-main-menu.component';
import { EditKostenpositionComponent } from './edit-kostenposition/edit-kostenposition.component';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxFileDropModule} from 'ngx-file-drop';
import {ToolsModule} from '../tools/tools.module';
import { SendMoneyPopupComponent } from './send-money-popup/send-money-popup.component';
import { KassenbuchDeleteKostenpositionPopupComponent } from './kassenbuch-delete-kostenposition-popup/kassenbuch-delete-kostenposition-popup.component';
import { KassenbuchEditKostenpositionStatusPopupComponent } from './kassenbuch-edit-kostenposition-status-popup/kassenbuch-edit-kostenposition-status-popup.component';
import { KassenbuchMenuForMainComponent } from './kassenbuch-menu-for-main/kassenbuch-menu-for-main.component';
import {KassenbuchListForMainComponent} from './kassenbuch-list-for-main/kassenbuch-list-for-main.component';
import {AppNotificationModule} from '../app-notifications/app-notifications.module';
import { KassenbuchStatsComponent } from './kassenbuch-stats/kassenbuch-stats.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {BetragUmrechnenPopupComponent} from "./betrag-umrechnen-popup/betrag-umrechnen-popup.component";


@NgModule({
  declarations: [
    KassenbuchListForCaseComponent,
    KassenbuchListForMainComponent,
    KassenbuchForMainMenuComponent,
    EditKostenpositionComponent,
    SendMoneyPopupComponent,
    KassenbuchDeleteKostenpositionPopupComponent,
    KassenbuchEditKostenpositionStatusPopupComponent,
    KassenbuchMenuForMainComponent,
    KassenbuchStatsComponent,
    BetragUmrechnenPopupComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    ToolsModule,
    AppNotificationModule,
    NgxChartsModule
  ],
  exports: [
    KassenbuchListForCaseComponent,
    KassenbuchForMainMenuComponent,
    EditKostenpositionComponent
  ],
  entryComponents: [
    EditKostenpositionComponent,
    SendMoneyPopupComponent,
    KassenbuchDeleteKostenpositionPopupComponent,
    KassenbuchEditKostenpositionStatusPopupComponent,
    BetragUmrechnenPopupComponent
  ],
  providers: [ ]
})
export class KassenbuchModule { }
