import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {PersonType, StammbaumService} from '../../services/stammbaum.service';
import {CustomUppercasePipe} from '../../tools/custom-uppercase-pipe/custom-uppercase-pipe';
import {RightsManagementService} from '../../services/rights-management.service';
import {CustomFirstLetterUppercasePipe} from '../../tools/custom-first-letter-uppercase-pipe/custom-first-letter-uppercase-pipe';
import {DatesService} from '../../services/dates.service';
import {AgeCheckComponent} from '../../tools/age-check-popup/age-check.component';

@Component({
  selector: 'stammbaum-tree-edit-person',
  templateUrl: './stammbaum-tree-edit-person.component.html',
  styleUrls: ['./stammbaum-tree-edit-person.component.css'],
})
export class StammbaumTreeEditPersonComponent implements OnInit {
  @Input() person: IStammbaumPerson;
  form: FormGroup;
  availableOptions;
  selectedOption;
  PersonType = PersonType;
  displayAusgeschlagen = false;
  displayUnwillig = false;
  displayAdoption = false;
  displayESAStopField = false;

  constructor(private ngbActiveModal: NgbActiveModal,
              public rightsManagementService: RightsManagementService,
              private customUppercasePipe: CustomUppercasePipe,
              private customFirstLetterUppercasePipe: CustomFirstLetterUppercasePipe,
              public stammbaumService: StammbaumService,
              private datesService: DatesService,
              private ngbModal: NgbModal,
              private store: Store<{stammbaum: IStammbaumState}>) {
    this.form = new FormGroup({
      'id': new FormControl(null),
      'nachname': new FormControl(''),
      'person_type': new FormControl(''),
      'geburtsname': new FormControl(''),
      'vorname': new FormControl(''),
      'geburtsdatum': new FormControl(''),
      'ausschlagung_belegt': new FormControl(''),
      'ausschlagung_for_minors': new FormControl( false),
      'ausschlagung_datum': new FormControl( ''),
      'ausschlagung_ort': new FormControl( ''),
      'ausschlagung_aktenbezug': new FormControl(''),
      'adoptiert': new FormControl(''),
      'adoptiert_jahr': new FormControl(''),
      'adoptiert_land': new FormControl(0),
      'adoptiert_erbrecht_auf_adoptionseltern': new FormControl(''),
      'gefallen': new FormControl(''),
      'berliner_testament': new FormControl(false),
      'geschlecht': new FormControl(null),
      'staatsbuergerschaft': new FormControl(null),
      'unwillig_still_include_ebs': new FormControl(false),
      'nachverstorben_stop_esa': new FormControl(false),
      'nk_in_vierter_eo_nicht_erbberechtigt': new FormControl(false),
      'wird_gesucht': new FormControl(false),
      'sicherungsaufforderung': new FormControl(false),
      'unbekannt': new FormControl(false),
      'sicher_keine_nachkommen': new FormControl(false),
      'geburtsinfo': new FormControl(''),
      'geburtsland': new FormControl(''),
      'taufinfo': new FormControl(''),
      'taufdatum': new FormControl(''),
      'begraebnisinfo': new FormControl(''),
      'begraebnisdatum': new FormControl(''),
      'religion': new FormControl(''),
      'sterbedatum': new FormControl(''),
      'sterbeinfo': new FormControl(''),
      'sterbeland': new FormControl(''),
      'kommentar': new FormControl(''),
      'kommentar_beruf': new FormControl(''),
      'kommentar_urkunden': new FormControl(''),
      'kommentar_ab_recherche': new FormControl(''),
      'needs_gu': new FormControl(false),
      'needs_gu_but_do_without': new FormControl(false),
      'ordered_gu': new FormControl(false),
      'ordered_gu_user': new FormControl(false),
      'ordered_gu_date': new FormControl(''),
      'needs_su': new FormControl(false),
      'needs_su_but_do_without': new FormControl(false),
      'ordered_su': new FormControl(false),
      'ordered_su_user': new FormControl(false),
      'ordered_su_date': new FormControl('')
    });
    this.availableOptions = this.stammbaumService.person_type_options.filter(x => x.chooseable);
  }

  ngOnInit(): void {
    this.selectedOption = this.stammbaumService.person_type_options.filter(x => x.id === this.person.person_type);
    this.form.patchValue({
      'id': this.person.id,
      'person_type': this.person.person_type,
      'nachname': this.person.nachname,
      'geburtsname': this.person.geburtsname,
      'vorname': this.person.vorname,
      'ausschlagung_belegt': this.person.ausschlagung_belegt,
      'ausschlagung_for_minors': this.person.ausschlagung_for_minor_kids,
      'ausschlagung_datum': this.person.ausschlagung_datum,
      'ausschlagung_ort': this.person.ausschlagung_ort,
      'ausschlagung_aktenbezug': this.person.ausschlagung_aktenbezug,
      'adoptiert': this.person.adoptiert,
      'adoptiert_jahr': this.person.adoptiert_jahr,
      'adoptiert_land': this.person.adoptiert_land,
      'adoptiert_erbrecht_auf_adoptionseltern': this.person.adoptiert_erbrecht_auf_adoptionseltern,
      'gefallen': this.person.gefallen,
      'berliner_testament': this.person.berliner_testament,
      'geschlecht': this.person.geschlecht,
      'staatsbuergerschaft': this.person.staatsbuergerschaft,
      'unwillig_still_include_ebs': this.person.unwillig_still_include_ebs,
      'nachverstorben_stop_esa': this.person.nachverstorben_stop_esa,
      'sicher_keine_nachkommen': this.person.sicher_keine_nachkommen,
      'nk_in_vierter_eo_nicht_erbberechtigt': this.person.nk_in_vierter_eo_nicht_erbberechtigt,
      'wird_gesucht': this.person.wird_gesucht,
      'sicherungsaufforderung': this.person.sicherungsaufforderung,
      'unbekannt': this.person.unbekannt,
      'geburtsdatum': this.person.geburtsdatum,
      'geburtsinfo': this.person.geburtsinfo,
      'geburtsland': this.person.geburtsland,
      'taufinfo': this.person.taufinfo,
      'taufdatum': this.person.taufdatum,
      'begraebnisinfo': this.person.begraebnisinfo,
      'begraebnisdatum': this.person.begraebnisdatum,
      'religion': this.person.religion,
      'sterbedatum': this.person.sterbedatum,
      'sterbeinfo': this.person.sterbeinfo,
      'sterbeland': this.person.sterbeland,
      'kommentar': this.person.kommentar,
      'kommentar_beruf': this.person.kommentar_beruf,
      'kommentar_urkunden': this.person.kommentar_urkunden,
      'kommentar_ab_recherche': this.person.kommentar_ab_recherche,
      'needs_gu': this.person.needs_gu,
      'needs_gu_but_do_without': this.person.needs_gu_but_do_without,
      'ordered_gu': this.person.ordered_gu,
      'ordered_gu_user': this.person.ordered_gu_user,
      'ordered_gu_date': this.person.ordered_gu_date,
      'needs_su': this.person.needs_su,
      'needs_su_but_do_without': this.person.needs_su_but_do_without,
      'ordered_su': this.person.ordered_su,
      'ordered_su_user': this.person.ordered_su_user,
      'ordered_su_date': this.person.ordered_su_date
    });
    if (this.person.person_type === PersonType['ausgeschlagener Erbe']) {
      this.displayAusgeschlagen = true;
    }
    if (this.person.person_type === PersonType['unwilliger Erbe']) {
      this.displayUnwillig = true;
    }
    if (this.person.adoptiert) {
      this.displayAdoption = true;
    }
    if (this.person.person_type === PersonType['Erbe (nachverstorben)']) {
      this.displayESAStopField = true;
    }
    this.form.get('sicher_keine_nachkommen').valueChanges.subscribe(val => {
      if (val) {
        this.form.patchValue({nk_in_vierter_eo_nicht_erbberechtigt: false});
      }
    });
    this.form.get('nk_in_vierter_eo_nicht_erbberechtigt').valueChanges.subscribe(val => {
      if (val) {
        this.form.patchValue({sicher_keine_nachkommen: false});
      }
    });
    this.form.get('person_type').valueChanges.subscribe(val => {
      this.displayAusgeschlagen = val && (+val === PersonType['ausgeschlagener Erbe']);
      this.displayUnwillig = val && (+val === PersonType['unwilliger Erbe']);
      this.displayESAStopField = val && (+val === PersonType['Erbe (nachverstorben)']);
    });
    this.form.get('adoptiert').valueChanges.subscribe(value => {
      this.displayAdoption = value;
    })
  }

  isAvailableOption() {
    return this.availableOptions.find(x => x.id === this.person.person_type);
  }

  cancel() {
    this.ngbActiveModal.close();
  }

  check() {
    if (this.datesService.isPersonNachverstorben(this.form.controls.sterbedatum.value) && this.form.controls.person_type.value !== PersonType['Erbe (nachverstorben)'] && !this.person.no_more_partners) {
      const modalRefNachverstorben = this.ngbModal.open(AgeCheckComponent, {backdrop: 'static'});
      modalRefNachverstorben.componentInstance.mode = 'nachverstorben';
      modalRefNachverstorben.result.then(
        () => {
          this.form.patchValue({person_type: PersonType['Erbe (nachverstorben)']});
          this.check2();
        },
        () => {
          this.check2();
        }
      );
    } else {
      this.check2();
    }
  }

  check2() {
    if (this.datesService.isDateReadable(this.form.controls.geburtsdatum.value) && this.datesService.isDateReadable(this.form.controls.sterbedatum.value)) {
      if (this.datesService.calculateAgeAtDeath(this.form.controls.geburtsdatum.value, this.form.controls.sterbedatum.value) < 12 && !this.form.controls.sicher_keine_nachkommen.value) {
        const modalRefAge: NgbModalRef = this.ngbModal.open(AgeCheckComponent, {backdrop: 'static'});
        modalRefAge.componentInstance.mode = 'below12';
        modalRefAge.result.then(
          () => {
            this.form.patchValue({sicher_keine_nachkommen: true});
            this.save();
          },
          () => {
            this.save();
          }
        );
      } else {
        this.save();
      }
    } else {
      this.save();
    }
  }

  save() {
    const nachname = this.customUppercasePipe.transform(this.form.controls.nachname.value);
    const vorname = this.customFirstLetterUppercasePipe.transform(this.form.controls.vorname.value);
    const geburtsname = this.customFirstLetterUppercasePipe.transform(this.form.controls.geburtsname.value);
    this.store.dispatch(new StammbaumActions.TriggerEditPersonInActiveStammbaum(<IStammbaumPerson>{
      id: this.form.controls.id.value,
      vorname: vorname,
      nachname: nachname,
      person_type: this.form.controls.person_type.value,
      geburtsname: geburtsname,
      adoptiert: this.form.controls.adoptiert.value,
      adoptiert_jahr: this.form.controls.adoptiert_jahr.value,
      adoptiert_land: this.form.controls.adoptiert_land.value,
      adoptiert_erbrecht_auf_adoptionseltern: this.form.controls.adoptiert_erbrecht_auf_adoptionseltern.value,
      gefallen: this.form.controls.gefallen.value,
      berliner_testament: this.form.controls.berliner_testament.value,
      geschlecht: this.form.controls.geschlecht.value,
      staatsbuergerschaft: this.form.controls.staatsbuergerschaft.value,
      unwillig_still_include_ebs: this.form.controls.unwillig_still_include_ebs.value,
      nachverstorben_stop_esa: this.form.controls.nachverstorben_stop_esa.value,
      ausschlagung_belegt: this.form.controls.ausschlagung_belegt.value,
      ausschlagung_for_minor_kids: this.form.controls.ausschlagung_for_minors.value,
      ausschlagung_datum: this.form.controls.ausschlagung_datum.value,
      ausschlagung_ort: this.form.controls.ausschlagung_ort.value,
      ausschlagung_aktenbezug: this.form.controls.ausschlagung_aktenbezug.value,
      wird_gesucht: this.form.controls.wird_gesucht.value,
      sicherungsaufforderung: this.form.controls.sicherungsaufforderung.value,
      unbekannt: this.form.controls.unbekannt.value,
      sicher_keine_nachkommen: this.form.controls.sicher_keine_nachkommen.value,
      nk_in_vierter_eo_nicht_erbberechtigt: this.form.controls.nk_in_vierter_eo_nicht_erbberechtigt.value,
      geburtsdatum: this.form.controls.geburtsdatum.value,
      geburtsinfo: this.form.controls.geburtsinfo.value,
      geburtsland: this.form.controls.geburtsland.value,
      taufdatum: this.form.controls.taufdatum.value,
      taufinfo: this.form.controls.taufinfo.value,
      sterbedatum: this.form.controls.sterbedatum.value,
      sterbeinfo: this.form.controls.sterbeinfo.value,
      sterbeland: this.form.controls.sterbeland.value,
      begraebnisdatum: this.form.controls.begraebnisdatum.value,
      begraebnisinfo: this.form.controls.begraebnisinfo.value,
      religion: this.form.controls.religion.value,
      kommentar_beruf: this.form.controls.kommentar_beruf.value,
      kommentar_urkunden: this.form.controls.kommentar_urkunden.value,
      kommentar_ab_recherche: this.form.controls.kommentar_ab_recherche.value,
      kommentar: this.form.controls.kommentar.value,
      needs_gu: this.form.controls.needs_gu.value,
      needs_gu_but_do_without: this.form.controls.needs_gu_but_do_without.value,
      ordered_gu: this.form.controls.ordered_gu.value,
      ordered_gu_user: this.form.controls.ordered_gu_user.value,
      ordered_gu_date: this.form.controls.ordered_gu_date.value,
      needs_su: this.form.controls.needs_su.value,
      needs_su_but_do_without: this.form.controls.needs_su_but_do_without.value,
      ordered_su: this.form.controls.ordered_su.value,
      ordered_su_user: this.form.controls.ordered_su_user.value,
      ordered_su_date: this.form.controls.ordered_su_date.value
    }));
    this.ngbActiveModal.close();
  }
}
