import {Action} from "@ngrx/store";
import {IFileUpload} from "../files/file-upload.interface";

export const REPLACE_FILE_UPLOADS_FOR_CURRENT_CASE = 'REPLACE_FILE_UPLOADS_FOR_CURRENT_CASE';
export const REPLACE_FILE_UPLOADS_FOR_CURRENT_PERSON = 'REPLACE_FILE_UPLOADS_FOR_CURRENT_PERSON';

export class ReplaceFileUploadsForCurrentCase implements Action {
  readonly type = REPLACE_FILE_UPLOADS_FOR_CURRENT_CASE;
  constructor(public payload: IFileUpload[]) {}
}

export class ReplaceFileUploadsForCurrentPerson implements Action {
  readonly type = REPLACE_FILE_UPLOADS_FOR_CURRENT_PERSON;
  constructor(public payload: IFileUpload[]) {}
}

export type FileUploadActions = ReplaceFileUploadsForCurrentCase | ReplaceFileUploadsForCurrentPerson;
