import {Component, Input, OnDestroy} from '@angular/core';
import {CaseService} from "../../services/case.service";
import {ICaseState} from "../../store/case-state.interface";
import {Observable, Subscription} from "rxjs";
import {Store} from "@ngrx/store";

@Component({
  selector: 'case-history',
  templateUrl: './case-history.component.html',
  styleUrls: ['./case-history.component.css']
})

export class CaseHistoryComponent implements OnDestroy {
  casesObs: Observable<ICaseState>;
  casesSubscription: Subscription;
  cases: ICaseState;


  constructor(private caseService: CaseService,
              private store: Store<{cases: ICaseState}>)
  {
    this.casesObs = this.store.select('cases');
    this.casesSubscription = this.casesObs.subscribe((cases) => {this.cases = cases; });
  }

  ngOnDestroy() {
    this.casesSubscription.unsubscribe();
  }

  goToCaseId(id: number) {
    this.caseService.goToCasedetailId(id);
  }
}
