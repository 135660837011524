import {Component, OnDestroy, OnInit} from '@angular/core';
import {IUserState} from '../../store/user-state.interface';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {IUser} from '../../models/user.interface';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'user-tree',
  templateUrl: './user-tree.component.html',
  styleUrls: ['./user-tree.component.css'],
})
export class UserTreeComponent implements OnInit, OnDestroy {
  userStateSub: Subscription;
  user_hierarchy: {senior: string, juniors: {name: string}[]}[] = [];

  constructor(private store: Store<{users: IUserState}>,
              private userService: UserService) {}

  ngOnInit() {
    this.userStateSub = this.store.select('users').subscribe(
      (userState) => {
        if (userState && userState.users && userState.users.length > 0) {
          this.buildHierarchy(userState.users);
        }
      }
    );
  }

  buildHierarchy(users: IUser[]) {
    this.user_hierarchy = [];
    users = users.filter(x => x.is_active && x.juniors && x.juniors.length > 0);
    for (const user of users) {
      const juniors = [];
      for (const jun of user.juniors) {
        juniors.push({name: this.userService.getUserName(jun.id)})
      }
      this.user_hierarchy.push({senior: this.userService.getUserName(user.id), juniors: juniors});
    }
  }

  ngOnDestroy() {
    this.userStateSub.unsubscribe();
  }
}
