import * as AuthUserActions from './auth.actions';
import {IAuthUser} from "../models/auth-user.interface";
import {IUser} from "../models/user.interface";

const initialState: {authUser: IAuthUser} = {
  authUser: {
    loggedIn: false,
    user: <IUser>{},
    lastActionTimestamp: null
  }
};

export function authReducer(state = initialState, action: AuthUserActions.AuthUserActions) {
  switch(action.type) {

    case AuthUserActions.SET_AUTH_USER_DATA:
      return {
        ...state,
        authUser: action.payload
      };

    case AuthUserActions.CLEAR_AUTH_USER_DATA:
      return {
        ...state,
        authUser: {
          loggedIn: false,
          user: null
        }
      };

    case AuthUserActions.UPDATE_LAST_ACTION_TIMESTAMP:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          lastActionTimestamp: action.payload
        }
      };

    default:
      return state;
  }
}
