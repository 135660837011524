import * as NoteDraftActions from './note-draft.actions'
import {INoteDraft} from '../notes/note-draft.interface';

const initialState: INoteDraft = {
  subject: null,
  text: null,
  reply: null,
  reply_note_id: null,
  open_ac_panels: null
}

export function noteDraftReducer (state = initialState, action: NoteDraftActions.NoteDraftActions) {
  switch (action.type) {
    case NoteDraftActions.DELETE_NOTE_DRAFT:
      return {
        ...state,
        subject: null,
        text: null,
        reply: null,
        reply_note_id: null,
        open_ac_panels: null
      };

    case NoteDraftActions.CREATE_NEW_NOTE_DRAFT:
      return {
        ...state,
        subject: action.payload.subject,
        text: action.payload.text,
        reply: action.payload.reply,
        reply_note_id: action.payload.reply_note_id,
        open_ac_panels: action.payload.open_ac_panels
      };

    default:
      return state;
  }
}
