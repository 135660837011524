import * as NetworkActions from './network.actions';
import {INetworkState} from './network-state.interface';
import {INetworkManagerMessage} from '../network-manager/network-manager-message.interface';

const initialState: INetworkState = {
  isConnectedToServer: false,
  updateNeededCase: false,
  updateNeededPerson: false,
  updateNeededUserRole: false,
  updateNeededUser: false,
  updateNeededCaseStatus: false,
  updateNeededNotes: false,
  updateNeededPersonGroup: false,
  updateNeededPersonsForCase: false,
  updateNeededNotesForCase: false,
  updateNeededNotesForPerson: false,
  updateNeededTeamMemberForCase: false,
  updateNeededFileUploadsForCase: false,
  updateNeededFileUploadsForPerson: false,
  updateNeededCalendarEntries: false,
  updateNeededBundesanzeigerCases: false,
  last_update_api_endpoints__case: null,
  last_update_api_endpoints__person: null,
  last_update_api_endpoints__note: null,
  messages: []
};

export function networkReducer(state = initialState, action: NetworkActions.NetworkActions) {
  switch (action.type) {

    case NetworkActions.ADD_MESSAGE:
      const remaining = state.messages;
      if (remaining.length > 50) {
        remaining.pop();
      }
      const new_message: INetworkManagerMessage = action.payload;
      if (!new_message.event_timestamp) {
        new_message.event_timestamp = Date();
      }
      return {
        ...state,
        messages: [new_message, ...remaining]
      };

    case NetworkActions.SET_CONNECTED_TO_SERVER_TRUE:
      return {
        ...state,
        isConnectedToServer: true
      };

    case NetworkActions.SET_CONNECTED_TO_SERVER_FALSE:
      return {
        ...state,
        isConnectedToServer: false
      };

      case NetworkActions.SET_UPDATE_NEEDED_CASE_TRUE:
      return {
        ...state,
        updateNeededCase: true
      };

    case NetworkActions.SET_UPDATE_NEEDED_CASE_FALSE:
      return {
        ...state,
        updateNeededCase: false
      };

    case NetworkActions.SET_UPDATE_NEEDED_PERSON_TRUE:
      return {
        ...state,
        updateNeededPerson: true
      };

    case NetworkActions.SET_UPDATE_NEEDED_PERSON_FALSE:
      return {
        ...state,
        updateNeededPerson: false
      };

    case NetworkActions.SET_UPDATE_NEEDED_PERSONS_FOR_CASE_TRUE:
      return {
        ...state,
        updateNeededPersonsForCase: true
      };

    case NetworkActions.SET_UPDATE_NEEDED_PERSONS_FOR_CASE_FALSE:
      return {
        ...state,
        updateNeededPersonsForCase: false
      };

    case NetworkActions.SET_UPDATE_NEEDED_USER_ROLE_TRUE:
      return {
        ...state,
        updateNeededUserRole: true
      };

    case NetworkActions.SET_UPDATE_NEEDED_USER_ROLE_FALSE:
      return {
        ...state,
        updateNeededUserRole: false
      };

    case NetworkActions.SET_UPDATE_NEEDED_USER_TRUE:
      return {
        ...state,
        updateNeededUser: true
      };

    case NetworkActions.SET_UPDATE_NEEDED_USER_FALSE:
      return {
        ...state,
        updateNeededUser: false
      };

    case NetworkActions.SET_UPDATE_NEEDED_CASE_STATUS_TRUE:
      return {
        ...state,
        updateNeededCaseStatus: true
      };

    case NetworkActions.SET_UPDATE_NEEDED_CASE_STATUS_FALSE:
      return {
        ...state,
        updateNeededCaseStatus: false
      };

    case NetworkActions.SET_UPDATE_NEEDED_NOTES_TRUE:
      return {
        ...state,
        updateNeededNotes: true
      };

    case NetworkActions.SET_UPDATE_NEEDED_NOTES_FALSE:
      return {
        ...state,
        updateNeededNotes: false
      };

    case NetworkActions.SET_UPDATE_NEEDED_NOTES_FOR_CASE_TRUE:
      return {
        ...state,
        updateNeededNotesForCase: true
      };

    case NetworkActions.SET_UPDATE_NEEDED_NOTES_FOR_CASE_FALSE:
      return {
        ...state,
        updateNeededNotesForCase: false
      };

    case NetworkActions.SET_UPDATE_NEEDED_NOTES_FOR_PERSON_TRUE:
      return {
        ...state,
        updateNeededNotesForPerson: true
      };

    case NetworkActions.SET_UPDATE_NEEDED_NOTES_FOR_PERSON_FALSE:
      return {
        ...state,
        updateNeededNotesForPerson: false
      };

    case NetworkActions.SET_UPDATE_NEEDED_TEAM_MEMBER_FOR_CASE_TRUE:
      return {
        ...state,
        updateNeededTeamMemberForCase: true
      };

    case NetworkActions.SET_UPDATE_NEEDED_TEAM_MEMBER_FOR_CASE_FALSE:
      return {
        ...state,
        updateNeededTeamMemberForCase: false
      };

    case NetworkActions.SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_CASE_TRUE:
      return {
        ...state,
        updateNeededFileUploadsForCase: true
      };

    case NetworkActions.SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_CASE_FALSE:
      return {
        ...state,
        updateNeededFileUploadsForCase: false
      };

    case NetworkActions.SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_PERSON_TRUE:
      return {
        ...state,
        updateNeededFileUploadsForPerson: true
      };

    case NetworkActions.SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_PERSON_FALSE:
      return {
        ...state,
        updateNeededFileUploadsForPerson: false
      };

    case NetworkActions.SET_UPDATE_NEEDED_PERSON_GROUP_TRUE:
      return {
        ...state,
        updateNeededPersonGroup: true
      };

    case NetworkActions.SET_UPDATE_NEEDED_PERSON_GROUP_FALSE:
      return {
        ...state,
        updateNeededPersonGroup: false
      };

    case NetworkActions.SET_UPDATE_NEEDED_CALENDAR_ENTRIES_TRUE:
      return {
        ...state,
        updateNeededCalendarEntries: true
      };

    case NetworkActions.SET_UPDATE_NEEDED_CALENDAR_ENTRIES_FALSE:
      return {
        ...state,
        updateNeededCalendarEntries: false
      };

    case NetworkActions.SET_UPDATE_NEEDED_BUNDESANZEIGER_CASES_TRUE:
      return {
        ...state,
        updateNeededBundesanzeigerCases: true
      };

    case NetworkActions.SET_UPDATE_NEEDED_BUNDESANZEIGER_CASES_FALSE:
      return {
        ...state,
        updateNeededBundesanzeigerCases: false
      };

    case NetworkActions.SET_LAST_UPDATE_API_ENDPOINTS__CASE: // this is for the new network communication method
      return {
        ...state,
        last_update_api_endpoints__case: action.payload
      };

    case NetworkActions.SET_LAST_UPDATE_API_ENDPOINTS__PERSON: // this is for the new network communication method
      return {
        ...state,
        last_update_api_endpoints__person: action.payload
      };

    case NetworkActions.SET_LAST_UPDATE_API_ENDPOINTS__NOTE: // this is for the new network communication method
      return {
        ...state,
        last_update_api_endpoints__note: action.payload
      };

    case NetworkActions.RESET_ALL:
      return initialState;

    default:
      return state;
  }
}
