import {Component, EventEmitter, forwardRef, Input, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {IUser} from '../../models/user.interface';
import {Store} from '@ngrx/store';
import {IUserState} from '../../store/user-state.interface';

const INTSELECT_VALUE_ACCESSOR: any = {
provide : NG_VALUE_ACCESSOR,
useExisting: forwardRef(() => ChooseUserReactiveFormsComponent),
multi : true,
};

@Component({
  selector : 'choose-user-reactive-forms',
  templateUrl: './choose-user-reactive-forms.component.html',
  styleUrls: ['./choose-user-reactive-forms.component.css'],
  providers : [INTSELECT_VALUE_ACCESSOR],
})
export class ChooseUserReactiveFormsComponent implements OnDestroy, ControlValueAccessor {

  @Input() title = '';
  @Input() userId;
  @Input() inactive = false;
  public value: number;
  disabled: boolean;
  private onChange: Function;
  private onTouched: Function;
  userSub: Subscription;
  users: IUser[] = [];

  constructor(private store: Store<{users: IUserState}>) {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
    this.disabled = false;

    this.userSub = this.store.select('users').subscribe(
      (userState) => {
        let users = userState.users.filter(x => x.is_active);
        users = users.sort((a, b) => {
          if (a.abbreviation < b.abbreviation) { return -1; }
          if (a.abbreviation > b.abbreviation) { return 1; }
          return 0;
        });
        const userOptions = [];
        userOptions.push(<IUser>{id: 0, abbreviation: '--'});
        userOptions.push(...users);
        this.users = userOptions;
      }
    );
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  public setValue($event): void {
    this.value = $event.target.value;
    this.onChange(this.value);
    this.onTouched();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
