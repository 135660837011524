import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NetworkService} from '../../services/network.service';

@Component({
  selector: 'backup-status-modal',
  templateUrl: './backup-status-modal.component.html',
  styleUrls: ['./backup-status-modal.component.css']
})

export class BackupStatusModalComponent implements OnInit {
  bks: {created: string, filename: string}[] = [];
  constructor(public activeModal: NgbActiveModal,
              private networkService: NetworkService) {}

  ngOnInit() {
    this.networkService.getDatabaseUpdateStatus().subscribe(
      (result) => this.bks = result
    );
  }

  cancel() {
    this.activeModal.close(false);
  }

  confirm() {
    this.activeModal.close(true);
  }
}
