import { Component, OnInit } from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IUser} from '../../../models/user.interface';
import {ConfirmModalComponent} from '../../../tools/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-user-list-mail-popup',
  templateUrl: './user-list-mail-popup.component.html',
  styleUrls: ['./user-list-mail-popup.component.css']
})
export class UserListMailPopupComponent implements OnInit {
  users: IUser[] = [];  // the users are given via componentInstance from where the modal is called - attention, users set to is_developer_account are filtered there already and so not shown
  send_to: IUser[] = [];
  not_send_to: IUser[] = [];
  header: String;
  message;
  manual: String[] = [];
  single_mail: String = '';
  send_to_ids: number[] = [];

  ermittler = true;
  leitung = true;
  nvz = false;
  verwaltung = true;
  archiv = false;
  admin = true;

  alertModalRef: NgbModalRef;

  quillToolbar: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
      [{'header': 1}, {'header': 2}],               // custom button values
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
      [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
      [{'color': []}, {'background': []}],          // dropdown with defaults from theme
      [{'align': []}],
      ['link', 'image']                         // link and image
    ]
  };

  constructor(public activeModal: NgbActiveModal,
              private ngbModal: NgbModal) {
  }

  ngOnInit() {
    this.not_send_to = this.users.filter(el => el.userrole.description === 'Ermittler' || el.userrole.description === 'Senior-Ermittler' || el.userrole.description === 'Junior-Ermittler' || el.userrole.description === 'Leitung Ermittlung' || el.userrole.description === 'Administrator' || el.userrole.description === 'Verwaltung');
    this.sortArrays();
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  switchList(item, list_number) {
    if (list_number === 1) {
      this.send_to.splice(this.send_to.indexOf(item), 1);
      this.not_send_to.push(item);
    } else {
      this.not_send_to.splice(this.not_send_to.indexOf(item), 1)
      this.send_to.push(item);
    }
    this.sortArrays();
  }

  allToSend() {
    this.send_to = this.send_to.concat(this.not_send_to);
    this.not_send_to = [];
    this.sortArrays();
  }

  allToNotSend() {
    this.not_send_to = this.not_send_to.concat(this.send_to);
    this.send_to = [];
    this.sortArrays();
  }

  addToList() {
    if (this.single_mail.toLowerCase().match(/^\S+@\S+\.\S+$/)) {
      this.manual.push(this.single_mail);
      this.single_mail = '';
    } else {
      this.alertModalRef = this.ngbModal.open(ConfirmModalComponent, {backdrop: 'static'});
      this.alertModalRef.componentInstance.title = 'E-Mail falsch';
      this.alertModalRef.componentInstance.message = 'Bitte eine valide Mail-Adresse eingeben!';
      this.alertModalRef.result.then(
        () => {},
        () => {}
      );
    }
  }

  deleteFromList(mail) {
    this.manual.splice(this.manual.indexOf(mail), 1);
  }

  send() {
    if (this.send_to.length === 0 && this.manual.length === 0) {
      this.alertModalRef = this.ngbModal.open(ConfirmModalComponent, {backdrop: 'static'});
      this.alertModalRef.componentInstance.title = 'Empfänger-Liste leer!';
      this.alertModalRef.componentInstance.message = 'Bitte mindestens einen Empfänger angeben!';
      this.alertModalRef.result.then(
        () => {},
        () => {}
      );
    } else {
      for (const send of this.send_to) {
        this.send_to_ids.push(send.id);
      }
      this.activeModal.close({
        user_ids: this.send_to_ids,
        manual_addresses: this.manual,
        subject: this.header,
        message: this.message
      });
    }
  }

  switch(input) {
    switch (input) {
      case 'admin': {
        this.admin = !this.admin;
        if (this.admin) {
          let push = this.users.filter(el => el.userrole.description === 'Administrator')
          push = push.filter(el => !this.not_send_to.includes(el));
          this.not_send_to = this.not_send_to.concat(push);
          this.not_send_to = this.not_send_to.filter(x => !this.send_to.includes(x));
        } else {
          this.not_send_to = this.not_send_to.filter(x => !this.users.filter(el => el.userrole.description === 'Administrator').includes(x))
        }
        this.sortArrays();
        break;
      }
      case 'archiv': {
        this.archiv = !this.archiv;
        if (this.archiv) {
          let push = this.users.filter(el => el.userrole.description === 'Archivmitarbeiter')
          push = push.filter(el => !this.not_send_to.includes(el));
          this.not_send_to = this.not_send_to.concat(push);
          this.not_send_to = this.not_send_to.filter(x => !this.send_to.includes(x));
        } else {
          this.not_send_to = this.not_send_to.filter(x => !this.users.filter(el => el.userrole.description === 'Archivmitarbeiter').includes(x))
        }
        this.sortArrays();
        break;
      }
      case 'ermittler': {
        this.ermittler = !this.ermittler;
        if (this.ermittler) {
          let push = this.users.filter(el => el.userrole.description === 'Ermittler' || el.userrole.description === 'Senior-Ermittler' || el.userrole.description === 'Junior-Ermittler')
          push = push.filter(el => !this.not_send_to.includes(el));
          this.not_send_to = this.not_send_to.concat(push);
          this.not_send_to = this.not_send_to.filter(x => !this.send_to.includes(x));
        } else {
          this.not_send_to = this.not_send_to.filter(x => !this.users.filter(el => el.userrole.description === 'Ermittler' || el.userrole.description === 'Senior-Ermittler' || el.userrole.description === 'Junior-Ermittler').includes(x))
        }
        this.sortArrays();
        break;
      }
      case 'leitung': {
        this.leitung = !this.leitung;
        if (this.leitung) {
          let push = this.users.filter(el => el.userrole.description === 'Leitung Ermittlung')
          push = push.filter(el => !this.not_send_to.includes(el));
          this.not_send_to = this.not_send_to.concat(push);
          this.not_send_to = this.not_send_to.filter(x => !this.send_to.includes(x));
        } else {
          this.not_send_to = this.not_send_to.filter(x => !this.users.filter(el => el.userrole.description === 'Leitung Ermittlung').includes(x))
        }
        this.sortArrays();
        break;
      }
      case 'verwaltung': {
        this.verwaltung = !this.verwaltung;
        if (this.verwaltung) {
          let push = this.users.filter(el => el.userrole.description === 'Verwaltung')
          push = push.filter(el => !this.not_send_to.includes(el));
          this.not_send_to = this.not_send_to.concat(push);
          this.not_send_to = this.not_send_to.filter(x => !this.send_to.includes(x));
        } else {
          this.not_send_to = this.not_send_to.filter(x => !this.users.filter(el => el.userrole.description === 'Verwaltung').includes(x))
        }
        this.sortArrays();
        break;
      }
      case 'nvz': {
        this.nvz = !this.nvz;
        if (this.nvz) {
          let push = this.users.filter(el => el.userrole.description === 'NVZ-Mitarbeiter')
          push = push.filter(el => !this.not_send_to.includes(el));
          this.not_send_to = this.not_send_to.concat(push);
          this.not_send_to = this.not_send_to.filter(x => !this.send_to.includes(x));
        } else {
          this.not_send_to = this.not_send_to.filter(x => !this.users.filter(el => el.userrole.description === 'NVZ-Mitarbeiter').includes(x))
        }
        this.sortArrays();
        break;
      }
      default: return;
    }
  }

  pillColor(user: IUser) {
    switch (user.userrole.description) {
      case 'Administrator':
        return 'red';
      case 'Ermittler':
        return 'green';
      case 'Junior-Ermittler':
        return 'green';
      case 'Senior-Ermittler':
        return 'green';
      case 'Leitung Ermittlung':
        return 'green';
      case 'Verwaltung':
        return 'orange';
      case 'Archivmitarbeiter':
        return 'blue';
      case 'NVZ-Mitarbeiter':
        return 'blue';
      default:
        return 'gray';
    }
  }

  pillLabel(user: IUser) {
     switch (user.userrole.description) {
      case 'Administrator':
        return 'A';
      case 'Ermittler':
        return 'E';
      case 'Junior-Ermittler':
        return 'E';
      case 'Senior-Ermittler':
        return 'E';
      case 'Leitung Ermittlung':
        return 'LE';
      case 'Verwaltung':
        return 'V';
      case 'Archivmitarbeiter':
        return 'Ar';
      case 'NVZ-Mitarbeiter':
        return 'NVZ';
      default:
        return '';
    }
  }

  sortArrays() {
    this.not_send_to.sort(function (a, b) {
      const nameA = a.userrole.description;
      const nameB = b.userrole.description;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    this.send_to.sort(function (a, b) {
      const nameA = a.userrole.description;
      const nameB = b.userrole.description;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
}
