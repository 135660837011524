import * as AutoparserActions from './autoparser.actions';
import {IAutoparserState} from './autoparser-state.interface';

const initialState: IAutoparserState = {
  messages: ['Autoparser bereit...'],
  activePage: 'entry',
  activeDir: null,
  directories: [],
  autoparserColumnSettings: {
    columnNachname: 0,
    columnVorname: 1,
    columnGeburtsname: -2,
    columnUrkundennummer: 3,
    columnJuedisch: -1,
    columnsDisabled: []
  },
  postprocessedEntries: []
};

export function autoparserReducer(state = initialState, action: AutoparserActions.AutoparserActions) {
  switch(action.type) {

    case AutoparserActions.ADD_NEW_MESSAGE:
      const messages: string[] = [...state.messages];
      if (messages.length > 200) {
        messages.shift();
      }
      return {
        ...state,
        messages: [...messages, action.payload]
      };

    case AutoparserActions.SET_AUTOPARSER_ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.payload
      };

    case AutoparserActions.SET_AUTOPARSER_ACTIVE_DIR:
      return {
        ...state,
        activeDir: action.payload,
      };

    case AutoparserActions.SET_AUTOPARSER_COLUMN_SETTINGS:
      return {
        ...state,
        autoparserColumnSettings: action.payload
      };

    case AutoparserActions.REPLACE_AUTOPARSER_POSTPROCESS_LIST:
      return {
        ...state,
        postprocessedEntries: action.payload
      };

    case AutoparserActions.REPLACE_AUTOPARSER_DIRECTORIES:
      return {
        ...state,
        directories: action.payload
      };

    default:
      return state;
  }
}
