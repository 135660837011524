import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  ElementRef,
  Renderer2,
  OnDestroy,
  HostListener, ViewChild, AfterViewInit,
} from '@angular/core';
import {NgbAccordion, NgbModal, NgbModalRef, NgbPanelChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {NoteService} from '../../services/note.service';
import {DatePipe} from '@angular/common';
import {INote} from '../note.interface';
import {IUser} from '../../models/user.interface';
import {INoteState} from '../../store/note-state.interface';
import {IUserState} from '../../store/user-state.interface';
import {RightsManagementService} from '../../services/rights-management.service';
import {IAuthUser} from '../../models/auth-user.interface';
import {ICase} from '../../cases/case.interface';
import {ICaseState} from '../../store/case-state.interface';
import * as NoteActions from '../../store/note.actions';
import {UserService} from '../../services/user.service';
import {IStammbaumPerson} from '../../stammbaum/stammbaum-person.interface';
import {DatepickerPopupComponent} from '../datepicker-popup/datepicker-popup.component';
import {ICalendarEntry} from '../../calendar/calendar-entry.interface';
import {CalendarEntryService} from '../../services/calendar-entry.service';
import {INoteDraft} from '../note-draft.interface';


@Component({
  selector: 'note-list-max',
  templateUrl: './note-list-max.component.html',
  styleUrls: ['./note-list-max.component.css']
})
export class NoteListMaxComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  notes: INote[] = [];
  copiedNotes: INote[] = [];
  notesSub: Subscription;
  notesState: INoteState = null;
  @Input() mode = 'case';  // case, case-auskehr and person
  @Input() notizenErbenID = null;
  @Input() notizenInstitutionID = null;
  @Input() noteSubject = '';
  @Input() folderId = null;  // null = all, 0 = Recherche-Notizen, 1= Auskehr-Notizen
  @Input() canAddNote = true;
  @ViewChild('scrollIn', {static: true}) scrollIn: ElementRef;
  createMode = true;

  show_also_persons = false;
  show_also_auskehr = false;
  result_placeholder: string[] = [];
  sortAscDefault = false;
  sortOrderInv = false;

  caseSub: Subscription;
  activeCase: ICase;
  activePerson: IStammbaumPerson;
  authUserSub: Subscription;
  authUser: IAuthUser;
  users: IUser[] = [];
  usersStateSub: Subscription;
  noteScrollSub: Subscription;

  openAccordionPanels: string[] = [];
  formOpen: string[] = [];
  search_notes = '';
  newNote = <INote>{};
  loading = false;
  error = false;
  errorMessage = '';

  noteDraft: INoteDraft;
  noteDraftSub: Subscription;
  openedPanelsWhileUsed: string[] = []; // workaround for saving open AC-Panels while in use TODO: use Accordion-Methods after Version-Update?

  ngbModalRefDatum: NgbModalRef;

  quillToolbar: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
      [{'header': 1}, {'header': 2}],               // custom button values
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
      [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
      [{'color': []}, {'background': []}],          // dropdown with defaults from theme
      [{'align': []}],
      ['link', 'image']                         // link and image
    ]
  };

  innerWidth: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.resizeScrollArea();
  }

  constructor(private noteService: NoteService,
              public rightsManagementService: RightsManagementService,
              private elementRef: ElementRef,
              private ngbModal: NgbModal,
              public userService: UserService,
              private calendarEntryService: CalendarEntryService,
              private datePipe: DatePipe,
              private store: Store<{
                notes: INoteState,
                noteDraft: INoteDraft,
                users: IUserState,
                authUser: { authUser: IAuthUser },
                cases: ICaseState
              }>,
              private renderer: Renderer2) {
    this.store.dispatch(new NoteActions.HighlightNote(null)); // reset on init
  }

  toggleElement(event) {
    if (event.nextState) {
      this.openedPanelsWhileUsed.push(event.panelId);
    } else {
      this.openedPanelsWhileUsed.splice(this.openedPanelsWhileUsed.indexOf(event.panelId));
    }
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.authUserSub = this.store.select('authUser').subscribe(
      (authUser) => {
        this.authUser = authUser.authUser;
        if (authUser && authUser.authUser) {
          this.sortAscDefault = authUser.authUser.user.note_reply_sort_ascending;
        }
      }
    );
    this.caseSub = this.store.select('cases').subscribe(
      (cases) => {
        this.activeCase = {...cases.activeCase};
        this.activePerson = {...cases.activePerson};
      }
    );
    this.usersStateSub = this.store.select('users').subscribe(
      (usersState) => {
        this.users = usersState.users;
        this.copyAndRunFilters();
      }
    );
    this.noteDraftSub = this.store.select('noteDraft').subscribe(
      (noteDraft) => {
        if (noteDraft.open_ac_panels) {
          this.openAccordionPanels = noteDraft.open_ac_panels;
          this.openedPanelsWhileUsed = noteDraft.open_ac_panels;
        }
        if (noteDraft.reply) {
          this.result_placeholder[noteDraft.reply_note_id] = noteDraft.text;
        } else {
          if (noteDraft.subject) {
            this.newNote.title = noteDraft.subject;
          }
          if (noteDraft.text) {
            this.newNote.text = noteDraft.text;
          }
        }
      }
    )
    this.noteScrollSub = this.noteService.scrollToNoteSubject.subscribe(
      (id) => {
        if (id) {
          this.scrollToNote(id);
        }
      }
    )
    this.notesSub = this.store.select('notes').subscribe(
      (notes) => {
        this.notesState = notes;
        this.afterNotesUpdated();
      });
  }

  sortInverse(noteId) {
    this.sortOrderInv = !this.sortOrderInv;
    const note = this.copiedNotes.find(x => x.id === noteId);
    let sortO = this.sortAscDefault;
    if (this.sortOrderInv) { sortO = !sortO; }
    if (!note) { return; }
    note.responses_for_note = note.responses_for_note.sort((a, b) => {
      if (a.id < b.id && sortO) { return -1; }
      if (a.id < b.id && !sortO) { return 1; }
      if (a.id > b.id && sortO) { return 1; }
      if (a.id > b.id && !sortO) { return -1; }
      return 0;
    });
  }

  showAnswerFieldOnTop() {
    if (this.sortAscDefault && this.sortOrderInv) { return true; }
    if (!this.sortAscDefault && !this.sortOrderInv) { return true; }
    return false;
  }

  afterNotesUpdated() {
    if (this.notesState === null) {
      return;
    }
    if (this.mode === 'case') {
      this.notes = this.notesState.notesForCurrentCase;
    }
    if (this.mode === 'case-auskehr') {
      this.notes = this.notesState.notesForCurrentCase;
    }
    if (this.mode === 'person') {
      this.notes = this.notesState.notesForCurrentPerson;
    }

    if (this.folderId !== null && (!this.show_also_auskehr || this.mode !== 'case')) {
      this.notes = this.notes.filter(x => +x.folder_id === +this.folderId);
    }
    if (this.notizenErbenID !== null) {
      this.notes = this.notes.filter(x => +x.person_id === +this.notizenErbenID);
    }
    if (this.notizenInstitutionID !== null) {
      this.notes = this.notes.filter(x => +x.institution === +this.notizenInstitutionID);
    }
    this.copyAndRunFilters();
    if (this.notesState && this.notesState.highlightedNote) {
      if (this.notesState.highlightedNote > 0) {
        this.highlightNote(this.notesState.highlightedNote);
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.resizeScrollArea();
    }, 0)
  }

  highlightNote(id: number) {
    this.openAccordionPanels = [('ac' + id)];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.afterNotesUpdated();
  }

  noteInSearchResults(note: INote) {
    if (!this.search_notes) {
      return true;
    }
    if (note.title.toLowerCase().includes(this.search_notes.toLowerCase())) {
      return true;
    }
    if (note.text.toLowerCase().includes(this.search_notes.toLowerCase())) {
      return true;
    }
    for (const res of note.responses_for_note) {
      if (res.result.toLowerCase().includes(this.search_notes.toLowerCase())) {
        return true;
      }
    }
    return false;
  }

  copyAndRunFilters() {
    if (this.mode === 'case') {
      if (this.show_also_persons) {
        this.copiedNotes = [...this.notes];
      } else {
        this.copiedNotes = [...this.notes.filter(x => +x.person_id === 0)];
      }
    }
    if (this.mode === 'case-auskehr') {
      this.copiedNotes = [...this.notes];
    }
    if (this.mode === 'person') {
      this.copiedNotes = [...this.notes];
    }
  }

  updateNotes(notes: INote[]) {
    this.notes = notes;
  }


  updateUsers(users: IUser[]) {
    this.users = users;
  }

  toggleShowPersons() {
    if (this.show_also_persons && (this.show_also_auskehr || !this.rightsManagementService.isAllowedToViewAuskehrTabInCase())) {
      this.show_also_persons = false;
      this.show_also_auskehr = false;
      this.afterNotesUpdated();
      return;
    }
    if (this.show_also_persons && !this.show_also_auskehr && this.rightsManagementService.isAllowedToViewAuskehrTabInCase()) {
      this.show_also_auskehr = true;
      this.afterNotesUpdated();
      return;
    }
    if (!this.show_also_persons) {
      this.show_also_persons = true;
      this.afterNotesUpdated();
      return;
    }
  }

  openAll() {
    for (const item of this.notes) {
      this.openAccordionPanels.push('ac' + item.id);
    }
  }

  closeAll() {
    this.openAccordionPanels = [];
  }

  panelChane($event: NgbPanelChangeEvent) {

  }

  createNote(reminder_14?, reminder_with_date?, forUserId: number | null = null) {

    if (!this.newNote || (!this.newNote.title) || this.newNote.title === '') {
      return;
    }
    this.loading = true;
    if (this.mode === 'person') {
      this.newNote.person_id = this.activePerson.id;
      this.newNote.case_id = this.activePerson.case;
      this.newNote.folder_id = 0;
    }
    if (this.mode === 'case') {
      this.newNote.person_id = 0;
      this.newNote.case_id = this.activeCase.id;
      this.newNote.folder_id = 0;
    }
    if (this.mode === 'case-auskehr') {
      if (this.notizenErbenID) {
        this.newNote.person_id = this.notizenErbenID;
      } else {
        this.newNote.person_id = 0;
      }
      if (this.notizenInstitutionID) {
        this.newNote.institution = this.notizenInstitutionID;
      }
      this.newNote.folder_id = 1;
      this.newNote.case_id = this.activeCase.id;
    }
    this.newNote.user_id = this.authUser.user.id;
    if (reminder_14) {
      this.newNote.reminder_14 = true;
    }
    if (reminder_with_date) {
      this.newNote.reminder_date = reminder_with_date.date;
      this.newNote.deadline_hour = reminder_with_date.hour;
      this.newNote.deadline_minute = reminder_with_date.minute;
    }
    let fUi = null;
    if (forUserId) {
      fUi = forUserId;
    }
    this.noteService.create(this.newNote, fUi).subscribe(
      (response) => {
        this.noteCreated(response);
      },
      (error) => {
        console.log(error);
      },     // todo error handling and result feedback/alarm
      () => {
      }
    );
    this.noteService.deleteNoteDrafts();
  }

  createNeedsTitel() {
    return (!this.newNote || (!this.newNote.title) || this.newNote.title === '');
  }

  saveNote(note: INote) {
    if (!this.result_placeholder || (!this.result_placeholder[note.id]) || this.result_placeholder[note.id] === '') {
      return
    }
    const result = this.result_placeholder[note.id]; // add to an existing answer
    this.result_placeholder[note.id] = null;
    note.result_user_id = this.authUser.user.id;
    this.noteService.save(note.id, result).subscribe(response => {
      this.noteSaved(response);
    });
  }

  toggleImportantNote(note: INote, important: boolean) {
    note.important = important;
    if (important) {
      this.noteService.setNoteImportant(note).subscribe(response => {
        this.noteSaved(response);
      });
    } else {
      this.noteService.unsetNoteImportant(note).subscribe(response => {
        this.noteSaved(response);
      });
    }
  }

  noteSaved(response: Response) {
    console.log('saved');
    this.copyAndRunFilters();
  }

  delete(noteId: number) {
    this.noteService.delete(noteId).subscribe(response => {
      this.noteDeleted(response);
    });
  }

  noteDeleted(response: Response) {
    console.log('deleted');
    this.copyAndRunFilters();
  }

  noteCreated(response: Response) {
    this.loading = false;
    this.formOpen = [];
    this.newNote = <INote>{};
    this.openAccordionPanels = this.openAccordionPanels.filter(x => x !== 'new');
    this.copyAndRunFilters();
    // todo error handling
  }

  openDatePicker() {
    this.ngbModalRefDatum = this.ngbModal.open(DatepickerPopupComponent, {backdrop: 'static', size: 'xl'});
    this.ngbModalRefDatum.result.then((result) => {
      const forUserId = result.user;
      this.createNote(false, result, forUserId);
    }, () => {
    });
  }

  ngOnDestroy() {
    this.resizeScrollArea();
    if (this.newNote.title || this.newNote.text) {
      this.noteDraft = {
        subject: this.newNote.title,
        text: this.newNote.text,
        reply: false,
        open_ac_panels: this.openedPanelsWhileUsed
      }
      this.noteService.createNewNoteDraft(this.noteDraft);
    } else if (this.result_placeholder.some(el => el !== null)) {
      for (const message of this.result_placeholder.filter(x => x)) {
        this.noteDraft = {
          text: message,
          reply: true,
          reply_note_id: this.result_placeholder.indexOf(message),
          open_ac_panels: this.openedPanelsWhileUsed
        };
        this.noteService.createNewNoteDraft(this.noteDraft);
      }
    } else if (this.openedPanelsWhileUsed.length > 0) {
      this.noteDraft = {
        text: null,
        reply: null,
        open_ac_panels: this.openedPanelsWhileUsed
      }
      this.noteService.createNewNoteDraft(this.noteDraft);
    } else {
      this.noteService.deleteNoteDrafts();
    }
    this.notesSub.unsubscribe();
    this.usersStateSub.unsubscribe();
    this.authUserSub.unsubscribe();
    this.caseSub.unsubscribe();
    this.noteDraftSub.unsubscribe();
    this.noteScrollSub.unsubscribe();
  }

  calendarEntryForUser(note: INote, userId: number = null) {
    if (userId === null) {
      return note.calendar_entries_for_note.find(x => x.for_user_id === this.authUser.user.id)
    }
    return note.calendar_entries_for_note.find(x => x.for_user_id === userId)
  }

  createCalendarEntry(note) {
    this.ngbModalRefDatum = this.ngbModal.open(DatepickerPopupComponent, {backdrop: 'static', size: 'xl'});
    this.ngbModalRefDatum.result.then((result) => {
      let userId = null;
      if (result.user) {
        userId = result.user;
      }
      this.createCalendarEntryToNoteID(note.id, result, userId);
    }, () => {
    });
    this.copyAndRunFilters();
  }

  editCalendarEntryOnNote(calendarEntry: ICalendarEntry) {
    this.ngbModalRefDatum = this.ngbModal.open(DatepickerPopupComponent, {backdrop: 'static', size: 'xl'});
    this.ngbModalRefDatum.componentInstance.edit_mode = true;
    this.ngbModalRefDatum.componentInstance.deadline = calendarEntry.deadline_timestamp_string;
    this.ngbModalRefDatum.componentInstance.deadline_hour = calendarEntry.deadline_hour;
    this.ngbModalRefDatum.componentInstance.deadline_minute = calendarEntry.deadline_minute;
    this.ngbModalRefDatum.componentInstance.forUserId = calendarEntry.for_user_id;
    this.ngbModalRefDatum.result.then((result) => {
      if (result === 'delete') {
        this.calendarEntryService.calendarEntrySetToDone(calendarEntry.id);
      } else {
        calendarEntry.deadline_timestamp_string = result.date;
        calendarEntry.deadline_timestamp = new Date(result.date);
        calendarEntry.deadline_hour = result.hour;
        calendarEntry.deadline_minute = result.minute;
        this.calendarEntryService.calendarEntrySave(calendarEntry);
      }
    }, () => {
    });
    this.copyAndRunFilters();
  }

  getCalenderDisplay(calendarEntry: ICalendarEntry) {
    let display: Date;
    const date = calendarEntry.deadline_timestamp_string.split('-');
    if (calendarEntry.deadline_hour) {
      display = new Date(+date[0], +date[1] - 1, +date[2], calendarEntry.deadline_hour, calendarEntry.deadline_minute);
      return display;
    } else {
      display = new Date(+date[0], +date[1] - 1, +date[2]);
      return display;
    }
  }

  createCalendarEntryToNoteID(noteID, reminder, userId: number | null = null) {
    this.noteService.createCalendarEntryToNoteId(noteID, reminder, userId).subscribe(() => {
    });
    this.copyAndRunFilters();
  }

  resizeScrollArea() {
    let elem = this.scrollIn.nativeElement;
    let distance = 0;
    // Loop up the DOM
    do {
      // Increase our distance counter
      distance += elem.offsetTop;
      // Set the element to it's parent
      elem = elem.offsetParent;
    } while (elem);
    distance = distance < 0 ? 0 : distance;
    let finalDist = window.innerHeight - distance - 10
    finalDist = finalDist < 400 ? 400 : finalDist;
    this.renderer.setStyle(this.scrollIn.nativeElement, 'height', `${finalDist}px`);
  }

  scrollToNote(id: number) {
    const elem = document.getElementById('ac' + id.toString() + '-header');
    if (elem) {
      elem.scrollIntoView({block: 'start', inline: 'nearest', behavior: 'smooth'})
    }
  }
}
