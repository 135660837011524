import * as SearchResultActions from './search-result.actions';
import {ISearchResultState} from "./search-result-state.interface";

const initialState: ISearchResultState = {
  search_result: {
    notes: [],
    tel: []
  }
};

export function searchResultReducer(state = initialState, action: SearchResultActions.SearchResultActions) {
  switch (action.type) {

    case SearchResultActions.REPLACE_SEARCH_RESULT_NOTES:
      return {
        ...state,
        search_result: {notes: action.payload}
      };

    case SearchResultActions.REPLACE_SEARCH_RESULT_TEL:
      return {
        ...state,
        search_result: {tel: action.payload}
      };

    default:
      return state;
  }
}
