import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';


@Injectable()
export class DebugLogService {
  log(message: string) {
    if (environment.showDebugMessages) {
      console.log(message);
    }
  }
}
