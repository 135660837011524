import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddAuskehrInstitutionComponent } from './add-auskehr-institution/add-auskehr-institution.component';
import {ReactiveFormsModule} from '@angular/forms';
import {EditAuskehrQuelleComponent} from './edit-auskehr-quelle/edit-auskehr-quelle.component';
import {AddressbookModule} from '../addressbook/addressbook.module';
import {RemoveAuskehrInstitutionComponent} from './remove-auskehr-institution/remove-auskehr-institution.component';
import {EditAuskehrZahlungComponent} from './edit-auskehr-zahlung/edit-auskehr-zahlung.component';
import { EditErbenSteuerComponent } from './edit-erben-steuer/edit-erben-steuer.component';
import {ToolsModule} from "../tools/tools.module";



@NgModule({
  declarations: [
    AddAuskehrInstitutionComponent,
    RemoveAuskehrInstitutionComponent,
    EditAuskehrQuelleComponent,
    EditAuskehrZahlungComponent,
    EditErbenSteuerComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AddressbookModule,
    ToolsModule
  ],
  exports: [
    AddAuskehrInstitutionComponent,
    RemoveAuskehrInstitutionComponent,
    EditAuskehrQuelleComponent,
    EditAuskehrZahlungComponent,
    EditErbenSteuerComponent
  ],
  entryComponents: [
    AddAuskehrInstitutionComponent,
    RemoveAuskehrInstitutionComponent,
    EditAuskehrQuelleComponent,
    EditAuskehrZahlungComponent,
    EditErbenSteuerComponent
  ]
})
export class AuskehrModule { }
