import {Component, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {INote} from '../note.interface';
import {INoteState} from '../../store/note-state.interface';
import {CaseService} from '../../services/case.service';
import * as CaseActions from '../../store/case.actions';
import {NoteService} from '../../services/note.service';
import {UserService} from "../../services/user.service";

@Component({
  selector: 'note-single-popup',
  templateUrl: './note-single-popup.component.html',
  styleUrls: ['./note-single-popup.component.css']
})

export class NoteSinglePopupComponent {

  @Input() note: INote;
  @Input() caseExists: boolean;
  loadAllDateInProgress = false;
  constructor(public activeModal: NgbActiveModal,
              private noteService: NoteService,
              public userService: UserService,
              private store: Store<{notes: INoteState}>,
              private caseService: CaseService) {}

  close() {
    this.activeModal.close();
  }

  loadAllData() {
    this.store.dispatch(new CaseActions.SetLoadAllCasesFlag(true));
    this.caseService.triggerCaselistReloadFromServer();
    this.loadAllDateInProgress = true;
    setTimeout(() => {
      this.caseExists = true;
      this.loadAllDateInProgress = false;
    }, 5000);
  }

  goToCase() {
    this.caseService.goToCasedetailId(this.note.case_id);
    this.activeModal.close();
  }

  goToPerson() {
    this.caseService.goToPersondetailId(this.note.person_id);
    this.activeModal.close();
  }

  goToNote() {
    if (+this.note.id > 0) {
      this.noteService.goToNote(+this.note.id);
      this.activeModal.close();
    }
  }
}
