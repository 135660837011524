import {Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import * as NVZOptions from '../nvz-options';
import {INVZVerzeichnis} from '../nvz-verzeichnis.interface';

@Component({
  selector: 'nvz-verzeichnisse-form',
  templateUrl: './nvz-verzeichnisse-form.component.html',
  styleUrls: ['./nvz-verzeichnisse-form.component.css']
})
export class NVZVerzeichnisseFormComponent implements OnInit, AfterViewInit {
  @ViewChild('art', { static: true }) artElement: ElementRef;
  @ViewChild('jahrgang', { static: true }) nameElement: ElementRef;
  @ViewChild('comment', { static: true }) commentElement: ElementRef;
  @ViewChild('status', { static: true }) statusElement: ElementRef;
  @HostListener('window:keydown', ['$event'])
  
  editMode = false;
  verzeichnis: INVZVerzeichnis = <INVZVerzeichnis>{};
  form: FormGroup;
  options = NVZOptions.NVZVerzeichnisStatusOptions;
  urkundenarten = NVZOptions.NVZUrkundenArt;
  
  keyEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.key === 'Enter') {
      this.save();
    }
  }
  
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.form = new FormGroup({
        'jahrgang': new FormControl(null, [Validators.required, Validators.maxLength(30)]),
        'comment': new FormControl(null, [Validators.maxLength(100)]),
        'status': new FormControl(0, [Validators.required]),
        'art': new FormControl(0, [Validators.required])
      });
    if (this.editMode) {
      this.form.setValue({
        jahrgang: this.verzeichnis.jahrgang,
        comment: this.verzeichnis.comment,
        status: this.verzeichnis.status,
        art: this.verzeichnis.art
      });
    }
  }

  ngAfterViewInit() {
    this.artElement.nativeElement.focus();
  }

  save() {
    if (this.form.valid) {
      this.verzeichnis.jahrgang = this.form.controls.jahrgang.value;
      this.verzeichnis.comment = this.form.controls.comment.value;
      this.verzeichnis.status = this.form.controls.status.value;
      this.verzeichnis.art = this.form.controls.art.value;
      this.activeModal.close(this.verzeichnis);
    }
  }

  back() {
    this.activeModal.close();
  }
}
