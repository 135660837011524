import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditfieldTextComponent} from './editfield-text/editfield-text.component';
import {EditfieldSelectComponent} from './editfield-select/editfield-select.component';
import {EditfieldCheckboxComponent} from './editfield-checkbox/editfield-checkbox.component';
import {EditfieldAddressComponent} from './editfield-address/editfield-address.component';
import {EditfieldDateComponent} from './editfield-date/editfield-date.component';
import {EditfieldContactComponent} from './editfield-contact/editfield-contact.component';
import {EditfieldNameComponent} from './editfield-name/editfield-name.component';
import {EditfieldTextareaComponent} from './editfield-textarea/editfield-textarea.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SpinnerCircleComponent} from './spinner-circle/spinner-circle.component';
import {ShowBooleanValueComponent} from './show-boolean-value/show-boolean-value.component';
import {ShowDateComponent} from './show-date/show-date.component';
import {DeactivatedModalComponent} from './deactivated-modal/deactivated-modal-component';
import {ConfirmModalComponent} from './confirm-modal/confirm-modal.component';
import {NotifyModalComponent} from './notify-modal/notify-modal.component';
import {BackupStatusModalComponent} from './backup-status-modal/backup-status-modal.component';
import {NgxFileDropModule} from 'ngx-file-drop';
import {AbcChooseComponent} from './abc-choose/abc-choose.component';
import {FilterFieldComponent} from './filter-field/filter-field.component';
import {DateInputFormComponent} from './date-input-form/date-input-form.component';
import {ClientConnectionModalComponent} from './client-connection-modal/client-connection-modal.component';
import {RejectVersionComponent} from './reject-version/reject-version.component';
import {ClickStopPropagation} from './click-stop-propagation/click-stop-propagation';
import {ShowDatePipe} from './show-date-pipe/show-date-pipe';
import {UserNotificationPopupComponent} from './user-notification-popup/user-notification-popup.component';
import {CustomUppercasePipe} from './custom-uppercase-pipe/custom-uppercase-pipe';
import {ChooseUserComponent} from './choose-user/choose-user';
import {ChooseUserReactiveFormsComponent} from './choose-user-reactive-forms/choose-user-reactive-forms.component';
import {CustomFirstLetterUppercasePipe} from './custom-first-letter-uppercase-pipe/custom-first-letter-uppercase-pipe';
import { AgeCheckComponent } from './age-check-popup/age-check.component';
import { TableSortHeaderComponent } from './table-sort-header/table-sort-header.component';
import {FormControlPipe} from './form-control-pipe/form-control-pipe';
import { WelcomePopupComponent } from './welcome-popup/welcome-popup.component';
import { FaxEinsichtModalComponent } from './fax-einsicht-modal/fax-einsicht-modal.component';
import {AddressbookModule} from "../addressbook/addressbook.module";

@NgModule({
  declarations: [
    ShowBooleanValueComponent,
    BackupStatusModalComponent,
    EditfieldTextComponent,
    EditfieldSelectComponent,
    EditfieldCheckboxComponent,
    EditfieldAddressComponent,
    EditfieldDateComponent,
    EditfieldContactComponent,
    EditfieldNameComponent,
    EditfieldTextareaComponent,
    SpinnerCircleComponent,
    ShowDateComponent,
    DeactivatedModalComponent,
    ConfirmModalComponent,
    NotifyModalComponent,
    AbcChooseComponent,
    FilterFieldComponent,
    DateInputFormComponent,
    ClientConnectionModalComponent,
    RejectVersionComponent,
    ClickStopPropagation,
    ShowDatePipe,
    CustomUppercasePipe,
    CustomFirstLetterUppercasePipe,
    UserNotificationPopupComponent,
    ChooseUserComponent,
    ChooseUserReactiveFormsComponent,
    AgeCheckComponent,
    TableSortHeaderComponent,
    FormControlPipe,
    WelcomePopupComponent,
    FaxEinsichtModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxFileDropModule,
    ReactiveFormsModule,
    AddressbookModule
  ],
  entryComponents: [
    DeactivatedModalComponent,
    UserNotificationPopupComponent,
    ConfirmModalComponent,
    BackupStatusModalComponent,
    ClientConnectionModalComponent,
    RejectVersionComponent,
    FaxEinsichtModalComponent,
    AgeCheckComponent,
    WelcomePopupComponent
  ],
  exports: [
    EditfieldTextComponent,
    UserNotificationPopupComponent,
    EditfieldSelectComponent,
    EditfieldCheckboxComponent,
    EditfieldAddressComponent,
    EditfieldDateComponent,
    EditfieldContactComponent,
    EditfieldNameComponent,
    ChooseUserComponent,
    ChooseUserReactiveFormsComponent,
    EditfieldTextareaComponent,
    SpinnerCircleComponent,
    ShowBooleanValueComponent,
    ShowDateComponent,
    DeactivatedModalComponent,
    ConfirmModalComponent,
    NotifyModalComponent,
    BackupStatusModalComponent,
    AbcChooseComponent,
    FilterFieldComponent,
    DateInputFormComponent,
    ClientConnectionModalComponent,
    RejectVersionComponent,
    ClickStopPropagation,
    ShowDatePipe,
    CustomUppercasePipe,
    CustomFirstLetterUppercasePipe,
    TableSortHeaderComponent,
    FormControlPipe,
    WelcomePopupComponent,
    FaxEinsichtModalComponent
  ]
})

export class ToolsModule {}
