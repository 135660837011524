import {Action} from '@ngrx/store';
import {IModuleRoute} from 'app/routing/module-route.interface';
import {IScrollCommand} from '../models/scroll-command.interface';

export const REPLACE_USER_NOTIFICATIONS = 'REPLACE_USER_NOTIFICATIONS';
export const SET_ACTIVE_MODULE = 'SET_ACTIVE_MODULE';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const SET_ACTIVE_PAGES_TO_INITIAL_STATE = 'SET_ACTIVE_PAGES_TO_INITIAL_STATE';
export const TRIGGER_SCROLL_COMMAND = 'TRIGGER_SCROLL_COMMAND';

export class ReplaceUserNotifications implements Action {
  readonly type = REPLACE_USER_NOTIFICATIONS;
  constructor(public payload: string[]) {}
}

export class SetActiveModule implements Action {
  readonly type = SET_ACTIVE_MODULE;
  constructor(public payload: IModuleRoute) {}
}
export class SetActivePage implements Action {
  readonly type = SET_ACTIVE_PAGE;
  constructor(public payload: {module: string, page: string}) {}
}
export class SetActivePagesToInitialState implements Action {
  readonly type = SET_ACTIVE_PAGES_TO_INITIAL_STATE;
  constructor() {}
}

export class TriggerScrollCommand implements Action {
  readonly type = TRIGGER_SCROLL_COMMAND;
  constructor(public payload: IScrollCommand) {}
}

export type UiActions = ReplaceUserNotifications | SetActiveModule | SetActivePage | SetActivePagesToInitialState | TriggerScrollCommand;
