import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {KassenbuchBelegInterface} from '../kassenbuch.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-kassenbuch-edit-kostenposition-status-popup',
  templateUrl: './kassenbuch-edit-kostenposition-status-popup.component.html',
  styleUrls: ['./kassenbuch-edit-kostenposition-status-popup.component.css']
})
export class KassenbuchEditKostenpositionStatusPopupComponent implements OnInit {
  kostenposition: KassenbuchBelegInterface;
  confirmToUndoPayment = false;
  constructor(private ngbActiveModal: NgbActiveModal) {}

  ngOnInit() {}

  cancel() {
    this.ngbActiveModal.dismiss();
  }

  undoPayment() {
    this.ngbActiveModal.close('undo-payment');
  }

  confirmUndoPayment() {
    this.confirmToUndoPayment = true;
  }

  cancelUndoPayment() {
    this.confirmToUndoPayment = false;
  }

  setCompleted() {
    this.ngbActiveModal.close('set-done');
  }

  setUnCompleted() {
    this.ngbActiveModal.close('set-undone');
  }

}
