import {Input, Component, Output, EventEmitter, AfterViewInit, OnInit} from '@angular/core';
import {CaseService, CaseSaveMode, CaseDisplayOpenable} from '../../services/case.service';
import {RightsManagementService} from '../../services/rights-management.service';
import {ICase, IDependentCase} from '../case.interface';
import {UserService} from '../../services/user.service';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {DocumentsService} from '../../documents/documents.service';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CaseChangeStatusComponent} from '../case-change-status/case-change-status.component';
import {FaxEinsichtModalComponent} from "../../tools/fax-einsicht-modal/fax-einsicht-modal.component";


@Component({
  selector: 'case-detail-max-basics',
  templateUrl: './case-detail-max-basics.component.html',
  styleUrls: ['./case-detail-max-basics.component.css']
})
export class CaseDetailMaxBasicsComponent implements AfterViewInit {
  @Input() copiedActiveCase: ICase;
  @Input() activeCase: ICase;
  @Input() editMode: CaseSaveMode;
  @Output() editModeChanged: EventEmitter<CaseSaveMode> = new EventEmitter<CaseSaveMode>();
  @Input() caseStatusOptions = [];
  @Input() opened: CaseDisplayOpenable[] = [];
  @Output() openedChanged: EventEmitter<CaseDisplayOpenable> = new EventEmitter<CaseDisplayOpenable>();
  parentCaseEdit = '';
  CaseSaveMode = CaseSaveMode;
  CaseDisplayOpenable = CaseDisplayOpenable;
  id: number;
  showStatusChangeHistory = false;
  ngbModalRefStatus: NgbModalRef;
  userOptions = [];

  constructor(public caseService: CaseService,
              private ngbModal: NgbModal,
              private documentsService: DocumentsService,
              public rightsManagementService: RightsManagementService,
              public userService: UserService) {
    this.userOptions = this.usersAsOptions();
  }

  ngAfterViewInit() {
    window.scrollTo({top: 0, left: 0});
  }

  goToCase(id: number) {
    this.caseService.goToCasedetailId(id);
  }

  toggleShowStatusChangeHistory() {
    this.showStatusChangeHistory = !this.showStatusChangeHistory;
  }

  openChangeStatusModal() {
    const ref: NgbModalRef = this.ngbModal.open(CaseChangeStatusComponent, {backdrop: 'static', size: 'xl'});
    ref.componentInstance.activeCase = this.activeCase;
    ref.result.then((result) => {
      this.copiedActiveCase.status = result.status;
      this.copiedActiveCase.ablagegrund = result.grund;
      this.copiedActiveCase.ablagegrund_erlaeuterung = result.erlaeuterung;
      this.saveData(true);
    }, () => {});
  }

  usersAsOptions() {
    return this.userService.usersAsOptions();
  }

  setEditMode(mode: CaseSaveMode) {
    this.editModeChanged.emit(mode);
  }

  toggleOpen(item: CaseDisplayOpenable) {
    this.openedChanged.emit(item);
  }

  createDocxForCase() {
    this.documentsService.createDocxForCase(this.activeCase.id, 'empty');
  }

  displayEBSZusammenfassung() {
    return this.opened.find(x => x === CaseDisplayOpenable.EBS_Zusammenfassung);
  }

  displayBundesanzeigertext() {
    return this.opened.find(x => x === CaseDisplayOpenable.Bundesanzeigertext);
  }

  faxEinsichtModal() {
    const ngbModalRef = this.ngbModal.open(FaxEinsichtModalComponent, <NgbModalOptions>{backdrop: 'static'});
    ngbModalRef.componentInstance.caseId = this.activeCase.id;
    ngbModalRef.result.then(
      (result) => {},
      (reason) => {}
    )
  }

  saveData(status_edit = false) {
    if (status_edit) {
      this.caseService.saveCaseData(this.copiedActiveCase, CaseSaveMode.Status);
    } else {
      this.caseService.saveCaseData(this.copiedActiveCase, this.editMode);
    }
    this.setEditMode(CaseSaveMode.Kein_Modus_gewaehlt);
  }

  reloadData() {
    this.setEditMode(CaseSaveMode.Kein_Modus_gewaehlt);
    this.copiedActiveCase = {...this.activeCase};
  }
}
