import {NgModule} from '@angular/core';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {CalendarEntryComponent} from "./calendar-entry/calendar-entry.component";
import {CalendarListComponent} from "./calendar-list/calendar-list.component";
import {CalendarShowMonthComponent} from "./calendar-show-month/calendar-show-month.component";
import {CalendarFormComponent} from "./calendar-form/calendar-form.component";
import {DatepickerModule} from "../datepicker/datepicker.module";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CalendarPopupComponent} from "./calendar-popup/calendar-popup.component";
import {CalendarNotificationComponent} from "./calendar-notification/calendar-notification.component";
import {AppNotificationModule} from "../app-notifications/app-notifications.module";

@NgModule({
  entryComponents: [
    CalendarPopupComponent
  ],
  declarations: [
    CalendarEntryComponent,
    CalendarListComponent,
    CalendarShowMonthComponent,
    CalendarFormComponent,
    CalendarPopupComponent,
    CalendarNotificationComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DatepickerModule,
    NgbModule,
    AppNotificationModule
  ],
  exports: [
    CalendarEntryComponent,
    CalendarNotificationComponent
  ]
})

export class CalendarModule {}
