import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RightsManagementService} from '../../services/rights-management.service';
import {IAuthUser} from '../../models/auth-user.interface';
import {IUserState} from '../../store/user-state.interface';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Input() authUser: IAuthUser;
  uSub: Subscription;
  userState: IUserState;

  constructor(public rightsManagementService: RightsManagementService,
              private store: Store<{users: IUserState}>) {
    this.uSub = this.store.select('users').subscribe(
    (userState) => { this.userState = userState; }
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.uSub.unsubscribe();
  }
}
