import {Injectable} from '@angular/core';
import {IStammbaumConnections} from '../stammbaum/stammbaum-connections.interface';
import {IStammbaumPersonDisplayParams} from '../stammbaum/stammbaum-person-display-params.interface';
import {StammbaumService} from './stammbaum.service';
import {Store} from '@ngrx/store';
import {IStammbaumState} from '../store/stammbaum-state.interface';
import {IStammbaum} from '../stammbaum/stammbaum.interface';
import {BehaviorSubject, Observable} from 'rxjs';
import * as StammbaumActions from '../store/stammbaum.actions';

export const PERSON_WIDTH_PX = 150;
export const MARGIN_WIDTH_PX = 30;
export const PERSON_MIN_HEIGHT_PX = 250;
export const MARGIN_HEIGHT_PX = 30;
export const HEIGHT_OF_CONTROL_BUTTONS = 30;
export const FREE_SPACE_BELOW_TREE_PX = 350;

@Injectable()
export class StammbaumDisplayService {

  private _personHeight: BehaviorSubject<number> = new BehaviorSubject(PERSON_MIN_HEIGHT_PX);
  public readonly personHeight: Observable<number> = this._personHeight.asObservable();
  private _scrollPos: BehaviorSubject<{x: number, y: number, height: number, width: number}> = new BehaviorSubject<{x: number, y: number, height: number, width: number}>({x: 0, y: 0, height: 0 , width: 0});
  public readonly scrollPos: Observable<{x: number, y: number, height: number, width: number}> = this._scrollPos.asObservable();
  maxHeightRequired = 0;

  constructor(private stammbaumService: StammbaumService,
              private store: Store<{ stammbaum: IStammbaumState }>) {
  }

  calculateDisplay(data: IStammbaum): void {
    const connections: IStammbaumConnections = {m: [], c: []} as IStammbaumConnections;
    // prepare: add/clear displayParams to every person
    for (const p of data.persons) {
      p.displayParams = <IStammbaumPersonDisplayParams>{};
    }

    const marrias = data.marriages.filter(x => !x.deleted);
    for (const mar of marrias) {
      if (!data.persons || !mar.persons || (mar.persons.length !== 2)) {
        console.log('error with this marriage, no or not enough person data', mar);
        continue;
      }
      const pers = data.persons.filter(x => (x.id === mar.persons[0].id || x.id === mar.persons[1].id));
      if (pers.length < 2) { continue; }
      const duplicate_partners = pers[0].has_duplicate && pers[1].has_duplicate;
      if (pers[0].x_position < pers[1].x_position) {
        connections.m.push({
          p1: data.persons.find(x => x.id === pers[0].id),
          p2: data.persons.find(x => x.id === pers[1].id),
          m: mar
        });
      } else {
        connections.m.push({
          p1: data.persons.find(x => x.id === pers[1].id),
          p2: data.persons.find(x => x.id === pers[0].id),
          m: mar
        });
      }
      // if they are duplicate partners, also display their connection
      if (duplicate_partners) {
        const dp1 = data.persons.find(x => x.id === pers[0].has_duplicate);
        const dp2 = data.persons.find(x => x.id === pers[1].has_duplicate);
        if (dp1 && dp2) {
          if (dp1.x_position > dp2.x_position) {
            connections.m.push({
              p1: dp2,
              p2: dp1,
              m: mar
            });
          } else {
            connections.m.push({
              p1: dp1,
              p2: dp2,
              m: mar
            });
          }
        }
      }
    }
    for (const per of data.persons) {
      if (per.parenthood) {
        const parent = data.persons.find(x => x.id === per.parenthood.first_parent);
        if (per.show_parents) {
          connections.c.push({p: parent, c: per});
        }
        if (per.has_duplicate) {
          const duplicate = data.persons.find(x => x.id === per.has_duplicate);
          if (duplicate && duplicate.show_parents) {
            connections.c.push({p: parent, c: duplicate});
          }
        }
      }
    }
    data.connections = connections;

    for (const person of data.persons) {
      person.displayParams.x = (PERSON_WIDTH_PX + MARGIN_WIDTH_PX) * person.x_position + 10;
      person.displayParams.y = 2 + (this._personHeight.getValue() + MARGIN_HEIGHT_PX) * person.y_position + 10;
      if ((data.version.hide_eo_3 && (person.erbordnung === 3)) || (data.version.hide_eo_4 && (person.erbordnung === 4))) {
        person.displayParams.color = '#f0f0f0'; } else {
        person.displayParams.color = this.stammbaumService.getPersonTypeColor(person);
      }
    }
    this.store.dispatch(new StammbaumActions.ReplaceStammbaum(data));
  }

  setMaxHeightRequired(height: number) {
    if (height > this.maxHeightRequired) {
      this.maxHeightRequired = height + HEIGHT_OF_CONTROL_BUTTONS;
    }
  }

  resetPersonHeightRequired() {
    this.maxHeightRequired = PERSON_MIN_HEIGHT_PX;
    this._personHeight.next(PERSON_MIN_HEIGHT_PX);
    this.rebuildTree();
  }

  checkIfTreeHasToBeRebuilt() {
    if (this._personHeight.getValue() < this.maxHeightRequired) {
      this.rebuildTree();
    }
  }

  rebuildTree() {
    console.log('rebuild tree called, current height', this._personHeight.getValue(), 'required height', this.maxHeightRequired);
    setTimeout(() => {
      this._personHeight.next(this.maxHeightRequired);
      this.store.dispatch(new StammbaumActions.TriggerRecalculateDisplay());
    });
  }

  setScrollPos(x: number, y: number, height: number, width: number) {
    this._scrollPos.next({x: x, y: y, height: height, width: width});
  }
}
