import {Component} from "@angular/core";
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'deactivated-modal',
  template: `<h3>Diese Funktion ist momentan deaktiviert</h3>`
})

export class DeactivatedModalComponent {
  constructor(public activeModal: NgbActiveModal) {}

  close() {
    this.activeModal.close();
  }
}
