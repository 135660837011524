import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {isNullOrUndefined} from "util";
import {ICommunicationDetail} from "../../models/communication-detail.interface";
import {CommunicationDetailService} from "../../services/communication-detail.service";

@Component({
  selector: 'editfield-contact',
  templateUrl: './editfield-contact.component.html',
  styleUrls: ['./editfield-contact.component.css']
})
export class EditfieldContactComponent implements OnInit {

  @Input() editMode: boolean = false;
  @Input() personId: number = 0;
  @Output() contactsChange: EventEmitter<Array<ICommunicationDetail>> = new EventEmitter <Array<ICommunicationDetail>>();
  contacts: Array<ICommunicationDetail> = Array();
  cssClass: string = "";
  contactTypes: Array<Object> = [{'id': 1, 'description': 'Telefon'}, {'id': 2, 'description': 'Handy'}, {'id': 3, 'description': 'Email'}, {'id': 4, 'description': 'Fax'}]
  printableContactTypes: string[] = [];
  type: string = null;
  details: string = null;

  constructor(private communicationDetailService: CommunicationDetailService) {
    this.printableContactTypes[1] = 'Telefon';
    this.printableContactTypes[2] = 'Handy';
    this.printableContactTypes[3] = 'Email';
    this.printableContactTypes[4] = 'Fax';
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!isNullOrUndefined(changes.personId)) {
      this.contacts = [];
      this.getCommunicationDetails(this.personId);
    }
  }

  addContact() {
    if (this.type == "" || this.details == "") {
      return;
    }
    if (isNullOrUndefined(this.type)) {
      return;
    }
    if (isNullOrUndefined(this.details)) {
      return;
    }
    let detail = <ICommunicationDetail>{};
    detail.type = this.type;
    detail.details = this.details;
    detail.person_id = this.personId;
    this.contacts.push(detail);
    this.details = "";
    this.type = "";
    this.communicationDetailService.create(detail).subscribe(
      () => {
        this.getCommunicationDetails(this.personId);
      }
    );

  }

  remove(contact: ICommunicationDetail, i: number) {
    console.log(contact);
    this.communicationDetailService.delete(contact).subscribe();
    this.contacts.splice(i, 1);
  }

  getCommunicationDetails(id: number): void {
    if (!id) { return; }
    this.communicationDetailService.getAllForPerson(id).subscribe(
      (communicationDetails) => {
        this.updateCommunicationDetails(communicationDetails);
      },
      (error) => {},
      () => {}
      );
  }

  updateCommunicationDetails(communicationDetails: ICommunicationDetail[]) {
    this.contacts = communicationDetails;
  }
}
