import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'toCustomFirstLetterUppercase'})
export class CustomFirstLetterUppercasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) { return ''; }
    value = value.split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
    value = value.split('-')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join('-');
    value = value.split('>')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join('>');
    return value;
  }
}
