import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuskehrService} from '../../services/auskehr.service';
import {captureMessage} from '@sentry/browser';

@Component({
  selector: 'app-add-auskehr-institution',
  templateUrl: './add-auskehr-institution.component.html',
  styleUrls: ['./add-auskehr-institution.component.css']
})
export class AddAuskehrInstitutionComponent implements OnInit {
  institutionId: number;
  saving = false;
  caseId = null;

  constructor(private activeModal: NgbActiveModal, private auskehrService: AuskehrService) {}

  ngOnInit() {
  }

  cancel() {
    this.activeModal.close();
  }

  save() {
    this.saving = true;
    this.auskehrService.saveInstitution(
      this.institutionId,
      this.caseId,
    ).subscribe(
      (result) => {
        this.auskehrService.saveInstitutionLocally(result);
        this.saving = false;
        this.activeModal.close();
      },
      (error) => {
        captureMessage('error saving auskehr institution');
        console.log(error);
        this.saving = false;
      },
      () => {
      }
    );
  }
}

