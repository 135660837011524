import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {isNullOrUndefined} from "util";

@Component({
  selector: 'editfield-date',
  templateUrl: './editfield-date.component.html',
  styleUrls: ['./editfield-date.component.css']
})
export class EditfieldDateComponent implements OnInit {

  @Input() date: string = "";
  @Input() displayUnbekanntAsNo = false;
  @Input() editMode: boolean = false;
  @Output() dateChange: EventEmitter<string> = new EventEmitter<string>();
  dateDisplay: string = "";
  cssClass: string = "";

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.parse();
  }

  parse() {
    if (isNullOrUndefined(this.date) || this.date=="") { // an empty string
      this.setDateEmpty();
      return;
    }

    if (this.date.substring(0,1) == "#") { // a comment
      this.setComment(this.date.slice(1));
      return;
    }

    if (/^(?!\s*$)[0-9]{8}$/.test(this.date)) { // just a normal date
      let day: string = this.date.substring(0,2);
      let month: string = this.date.substring(2,4);
      let year: string = this.date.substring(4,8);
      if (this.isValidDate(day, month, year)) {
        this.setDateOK(day, month, year);
        return;
      }
    }

    if (/^(?!\s*$)[\?]{1}[0-9]{8}$/.test(this.date)) { // a date that is uncertain
      let day: string = this.date.substring(1,3);
      let month: string = this.date.substring(3,5);
      let year: string = this.date.substring(5,9);
      if (this.isValidDate(day, month, year)) {
        this.setDateUncertain(day, month, year);
        return;
      }
    }

    if (/^(?!\s*$)[0-9]{8}[+]{1}[0-9]{8}$/.test(this.date)) { // two alternatives for the date
      let day_begin: string = this.date.substring(0,2);
      let month_begin: string = this.date.substring(2,4);
      let year_begin: string = this.date.substring(4,8);
      let day_end: string = this.date.substring(9,11);
      let month_end: string = this.date.substring(11,13);
      let year_end: string = this.date.substring(13,17);
      if (this.isValidDate(day_begin, month_begin, year_begin) && this.isValidDate(day_end, month_end, year_end)) {
        this.setDateAlternatives(day_begin, month_begin, year_begin, day_end, month_end, year_end);
        return;
      }
    }

    if (/^(?!\s*$)[\?]{1}[0-9]{8}[+]{1}[0-9]{8}$/.test(this.date)) { // two alternatives for the date that are uncertain
      let day_begin: string = this.date.substring(1,3);
      let month_begin: string = this.date.substring(3,5);
      let year_begin: string = this.date.substring(5,9);
      let day_end: string = this.date.substring(10,12);
      let month_end: string = this.date.substring(12,14);
      let year_end: string = this.date.substring(14,18);
      if (this.isValidDate(day_begin, month_begin, year_begin) && this.isValidDate(day_end, month_end, year_end)) {
        this.setDateAlternativesUncertain(day_begin, month_begin, year_begin, day_end, month_end, year_end);
        return;
      }
    }

    if (/^(?!\s*$)[0-9]{8}[\-]{1}[0-9]{8}$/.test(this.date)) { // a period
      let day_begin: string = this.date.substring(0,2);
      let month_begin: string = this.date.substring(2,4);
      let year_begin: string = this.date.substring(4,8);
      let day_end: string = this.date.substring(9,11);
      let month_end: string = this.date.substring(11,13);
      let year_end: string = this.date.substring(13,17);

      if (this.isValidDate(day_begin, month_begin, year_begin) && this.isValidDate(day_end, month_end, year_end)) {
        if (this.isBefore(day_begin, month_begin, year_begin, day_end, month_end, year_end)) {
          this.setDatePeriod(day_begin, month_begin, year_begin, day_end, month_end, year_end);
          return;
        }
      }
    }

     if (/^(?!\s*$)[\?]{1}[0-9]{8}[\-]{1}[0-9]{8}$/.test(this.date)) { // a period that is uncertain
      let day_begin: string = this.date.substring(1,3);
      let month_begin: string = this.date.substring(3,5);
      let year_begin: string = this.date.substring(5,9);
      let day_end: string = this.date.substring(10,12);
      let month_end: string = this.date.substring(12,14);
      let year_end: string = this.date.substring(14,18);

      if (this.isValidDate(day_begin, month_begin, year_begin) && this.isValidDate(day_end, month_end, year_end)) {
        if (this.isBefore(day_begin, month_begin, year_begin, day_end, month_end, year_end)) {
          this.setDatePeriodUncertain(day_begin, month_begin, year_begin, day_end, month_end, year_end);
          return;
        }
      }
    }

    this.setDateError();
    return;
  }

  isValidDate (day: string, month: string, year: string) {
    if (+day < 1 || +month < 1 || +year < 1) { return false; }
    if (+month > 12) { return false; }
    if (+year < 1800 || +year > 2200) { return false; }
    if (+day > 31) { return false; }
    if (+day > 29 && +month == 2) { return false; }
    if (+day > 30 && (+month == 4 || +month == 6 || +month == 9 || +month == 11)) { return false; }
    return true;
  }

  isBefore(day_begin: string, month_begin: string, year_begin: string, day_end: string, month_end: string, year_end: string) {
    if (+year_end > +year_begin) { return true; }
    if (+year_end == +year_begin && +month_end > +month_begin) { return true; }
    if (+year_end == +year_begin && +month_end == +month_begin && +day_end > +day_begin) { return true; }
    return false;
  }

  setDateOK(day: string, month: string, year: string) {
    this.dateDisplay = day + "." + month + "." + year;
    this.cssClass = "ok";
  }

  setDateUncertain(day: string, month: string, year: string) {
    this.dateDisplay = "vermutlich " + day + "." + month + "." + year;
    this.cssClass = "uncertain";
  }

  setDatePeriod(day_begin: string, month_begin: string, year_begin: string, day_end: string, month_end: string, year_end: string) {
    this.dateDisplay = day_begin + "." + month_begin + "." + year_begin + " - " + day_end + "." + month_end + "." + year_end;
    this.cssClass = "period";
  }

  setDatePeriodUncertain(day_begin: string, month_begin: string, year_begin: string, day_end: string, month_end: string, year_end: string) {
    this.dateDisplay = "vermutlich " + day_begin + "." + month_begin + "." + year_begin + " - " + day_end + "." + month_end + "." + year_end;
    this.cssClass = "uncertain";
  }

  setDateAlternatives(day_begin: string, month_begin: string, year_begin: string, day_end: string, month_end: string, year_end: string) {
    this.dateDisplay = day_begin + "." + month_begin + "." + year_begin + " oder " + day_end + "." + month_end + "." + year_end;
    this.cssClass = "alternatives";
  }

  setDateAlternativesUncertain(day_begin: string, month_begin: string, year_begin: string, day_end: string, month_end: string, year_end: string) {
    this.dateDisplay = "vermutlich " + day_begin + "." + month_begin + "." + year_begin + " oder " + day_end + "." + month_end + "." + year_end;
    this.cssClass = "uncertain";
  }

  setDateError() {
    this.dateDisplay = "<<fehlerhaft>>";
    this.cssClass = "error";
  }

  setDateEmpty() {
    if (this.displayUnbekanntAsNo) {
      this.dateDisplay = "nein";
    } else {
      this.dateDisplay = "<<unbekannt>>";
    }
    this.cssClass = "empty";
  }

  setComment(text: string) {
    this.dateDisplay = text;
    this.cssClass = "comment";
  }
}
