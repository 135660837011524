import { Component, OnInit, OnDestroy } from '@angular/core';
import {NoteService} from '../../services/note.service';
import {INote} from '../../notes/note.interface';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import * as SearchResultActions from '../../store/search-result.actions';
import {NOTIFICATION_SLOT_FIND_NOTE} from '../../app-notifications/app-notification.interface';
import {ISearchResultState} from '../../store/search-result-state.interface';
import {ICaseState} from '../../store/case-state.interface';
import * as AppNotificationActions from '../../store/app-notification.actions';
import {GlobalsearchService} from '../../services/globalsearch.service';
import {CaseService} from '../../services/case.service';


@Component({
  selector: 'globalsearch',
  templateUrl: 'globalsearch.component.html',
  styleUrls: ['globalsearch.component.css']
})

// todo implement newly also for persons

export class GlobalsearchComponent implements OnInit, OnDestroy {
  public note_search = '';
  public tel_search = '';
  search_results: INote[] = [];
  search_results_tel: {type: string, id: number, number: string, text: string}[] = [];
  go_to_note_id = '';
  go_to_note_disabled = true;
  persons_loaded = false;
  cases_loaded = false;
  searching = false;
  searchingTel = false;
  search_result_sub: Subscription;
  case_sub: Subscription;
  slot = NOTIFICATION_SLOT_FIND_NOTE;

  constructor(private noteService: NoteService,
              private globalsearchService: GlobalsearchService,
              private caseService: CaseService,
              private store: Store<{searchResults: ISearchResultState, cases: ICaseState}>) {}

  ngOnInit() {
     this.search_result_sub = this.store.select('searchResults').subscribe(
      (searchResultsState) => {
        if (searchResultsState && searchResultsState.search_result) {
          this.search_results = searchResultsState.search_result.notes;
          this.search_results_tel = searchResultsState.search_result.tel;
        }
      }
      );
     this.case_sub = this.store.select('cases').subscribe(
       (result) => {
         if (result && result.cases && result.cases.length > 0) {
           this.cases_loaded = true;
         } else {
           this.cases_loaded = false;
         }
         this.updateLoadingState();
       }
     );
  }

  updateLoadingState() {
    this.go_to_note_disabled = !(this.cases_loaded && this.persons_loaded);
  }

  ngOnDestroy() {
    this.search_result_sub.unsubscribe();
    this.case_sub.unsubscribe();
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_FIND_NOTE));
  }

  searchNotes() {
    this.store.dispatch(new SearchResultActions.ReplaceSearchResultNotes([]));
    this.searching = true;
    this.search_results_tel = [];
    this.noteService.searchNotes(this.note_search).subscribe(
      (result) => {
        this.store.dispatch(new SearchResultActions.ReplaceSearchResultNotes(result));
      },
      (error) => {},
      () => {
        this.searching = false;
      }
    );
  }

  searchTel() {
    this.store.dispatch(new SearchResultActions.ReplaceSearchResultTel([]));
    this.searchingTel = true;
    this.search_results = [];
    this.globalsearchService.searchTel(this.tel_search).subscribe(
      (result) => {
        this.store.dispatch(new SearchResultActions.ReplaceSearchResultTel(result));
      },
      (error) => {},
      () => {
        this.searchingTel = false;
      }
    );
  }

  printType(type: string) {
    if (type === 'person') { return 'Stammbaum-Person'; }
    if (type === 'institution') { return 'Institution'; }
    if (type === 'addressbook-person') { return 'Adressbuch-Person'; }
    return 'sonstiges';
  }

  goToNote() {
    if (+this.go_to_note_id > 0) {
      this.noteService.goToNote(+this.go_to_note_id);
    }
  }

  goToPerson(id: number) {
    this.caseService.goToPersondetailId(id);
  }
}




