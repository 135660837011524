import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {Store} from '@ngrx/store';
import {IUserState} from '../../store/user-state.interface';
import {Subscription} from 'rxjs';
import {IUser} from '../../models/user.interface';

@Component({
  selector: 'choose-user',
  template: `
  <div class="form-group row">
    <label *ngIf="title" for="forUserId" class="col-sm-2 control-label">{{title}}</label>
    <div class="col-sm-10">
      <select [disabled]="disabled" id="forUserId" [ngModel]="userId" class="form-control" (change)="changed($event)">
        <option *ngFor="let user of users"
          [value]="user.id">
          {{user.abbreviation}}
        </option>
      </select>
    </div>
  </div>
  `
})

export class ChooseUserComponent implements OnChanges, OnDestroy {
  @Input() title = '';
  @Input() disabled = false;
  @Input() userId;
  @Output() userIdSelected: EventEmitter<number> = new EventEmitter<number>();
  userSub: Subscription;
  users: IUser[] = [];

  constructor(private store: Store<{users: IUserState}>) {
    this.userSub = this.store.select('users').subscribe(
      (userState) => {
        let users = userState.users.filter(x => x.is_active);
        users = users.sort((a, b) => {
          if (a.abbreviation < b.abbreviation) { return -1; }
          if (a.abbreviation > b.abbreviation) { return 1; }
          return 0;
        });
        const userOptions = [];
        userOptions.push(<IUser>{id: 0, abbreviation: '--'});
        userOptions.push(...users);
        this.users = userOptions;
      }
    );
  }

  changed($event) {
    this.userIdSelected.emit($event.target.value);
  }

  ngOnChanges(ch: SimpleChanges) {
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
}
