import {Component, Input} from '@angular/core';
import {GenerateDocumentPopupComponent} from "../generate-document-popup/generate-document-popup.component";
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {EditDocumentComponent} from "../edit-document/edit-document.component";

@Component({
  selector: 'generate-doc-button',
  templateUrl: './generate-doc-button.component.html',
  styleUrls: ['./generate-doc-button.component.css']
})

export class GenerateDocButtonComponent {
  @Input() mode: string;
  @Input() activeCaseId: number = 0;
  @Input() caseIdForActivePerson: number = 0;
  @Input() activePersonId: number = 0;

  constructor(private ngbModal: NgbModal) {}

  generateDocument() {
    const modalRef = this.ngbModal.open(GenerateDocumentPopupComponent, <NgbModalOptions>{size: 'lg'});
    if (this.mode === 'case') {
      modalRef.componentInstance.case = this.activeCaseId;
      modalRef.componentInstance.person = 0;
    }
    else {
      modalRef.componentInstance.case = this.caseIdForActivePerson;
      modalRef.componentInstance.person = this.activePersonId;
    }
    modalRef.result.then(
      (result) => {
        if (result) {
          this.generateDocumentPhaseTwo();
        }
      },
      () => {}
    );
  }

  generateDocumentPhaseTwo() {
    const modalRef = this.ngbModal.open(EditDocumentComponent, <NgbModalOptions>{size: 'lg'});
  }
}
