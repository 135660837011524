import { Component, OnInit, Input } from '@angular/core';
import {IModulePages, MODULE_PAGES} from '../../routing/routes.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IUi} from '../../models/ui.interface';
import {INVZState} from '../../store/nvz-state.interface';
import {IAuthUser} from '../../models/auth-user.interface';
import {IUser} from '../../models/user.interface';

@Component({
  selector: 'namensverzeichnisse-entry',
  templateUrl: './namensverzeichnisse-entry.component.html',
  styleUrls: ['./namensverzeichnisse-entry.component.css']
})
export class NamensverzeichnisseEntryComponent implements OnInit {
  @Input() ui: {ui: IUi};
  @Input() nvzState: INVZState;
  @Input() authUser: {authUser: IAuthUser};
  user: IUser;
  MODULE_PAGES: IModulePages;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.MODULE_PAGES = MODULE_PAGES;
    this.user = this.authUser.authUser.user;
  }

  showNavigation() {
    const activePage = this.ui.ui.routing.activePageInModule.NAMENSVERZEICHNISSE;
    if (activePage === MODULE_PAGES.NAMENSVERZEICHNISSE.STAEDTE ||
      activePage === MODULE_PAGES.NAMENSVERZEICHNISSE.STANDESAEMTER ||
      activePage === MODULE_PAGES.NAMENSVERZEICHNISSE.VERZEICHNISSE ||
      activePage === MODULE_PAGES.NAMENSVERZEICHNISSE.LAENDER ||
      activePage === MODULE_PAGES.NAMENSVERZEICHNISSE.EINTRAG
    ) {
      return true;
    } else {
      return false;
    }
  }

}
