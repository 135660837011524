import {Component, OnInit } from '@angular/core';
import {BundesanzeigerService} from "../../../services/bundesanzeiger.service";
import {IBundesanzeiger} from "../../../models/bundesanzeiger.interface";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {IBundesanzeigerState} from "../../../store/bundesanzeiger-state.interface";

@Component({
  selector: 'app-bundesanzeiger-list',
  templateUrl: './bundesanzeiger-list.component.html',
  styleUrls: ['./bundesanzeiger-list.component.css']
})
export class BundesanzeigerListComponent implements OnInit {

  bas: IBundesanzeiger[] = [];
  openAccordionPanels : string[] = [];
  baSub: Subscription;

  constructor(private bundesanzeigerService: BundesanzeigerService,
              private store: Store<{bundesanzeiger: IBundesanzeigerState}>) {
    this.baSub = this.store.select('bundesanzeiger').subscribe(
      (ba_cases) => {this.updateBas(ba_cases.bundesanzeiger_cases);}
    );
  }

  ngOnInit() {}

  updateBas(bas: IBundesanzeiger[]) {
    this.bas = bas;
    this.openAccordionPanels = [];
    for (let ba of bas) {
      this.openAccordionPanels.push('ac'+ba.id);
    }
  }

  delete(ba: IBundesanzeiger) {
    this.bundesanzeigerService.delete(ba.id);
  }

  createCase(ba: IBundesanzeiger) {
    let id = ba.id;
    this.bundesanzeigerService.create_case(id);
  }

  ngOnDestroy() {
    this.baSub.unsubscribe();
  }
}
