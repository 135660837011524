import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'toCustomUppercase'})
export class CustomUppercasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) { return ''; }
    value = value.replace('ß', '\u1e9e');
    value = value.toLocaleUpperCase();
    return value;
  }
}
