import {Component, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Store} from "@ngrx/store";
import {AddressbookService} from "../../services/addressbook.service";
import {IAddressbookInstitution} from "../addressbook-institution.interface";
import {AddressbookCreatePopupComponent} from "../addressbook-create-popup/addressbook-create-popup.component";
import * as AddressbookEntryActions from "../../store/addressbook-entry.actions";
import {AddressbookDetailPopupComponent} from "../addressbook-detail-popup/addressbook-detail-popup.component";
import {MODULE_PAGES, MODULES} from "../../routing/routes.model";
import * as UiActions from "../../store/ui.actions";
import {isNullOrUndefined} from "util";
import {IAddressbookEntryState} from "../../store/addressbook-entry-state.interface";
import {RoutingService} from "../../routing/routing.service";
import {RightsManagementService} from '../../services/rights-management.service';


@Component({
  selector: 'addressbook-autocomplete',
  templateUrl: './addressbook-autocomplete.component.html',
  styleUrls: ['./addressbook-autocomplete.component.css']
})

export class AddressbookAutocompleteComponent implements OnInit, OnChanges {

  @Input() editMode: boolean = false;
  @Input() fieldValue: number;
  fieldValueText: string = "";
  @Output() fieldValueChange: EventEmitter<number> = new EventEmitter<number>();
  cssClass: string = "";
  showInputField: boolean = true;
  searchResultInstitutions: IAddressbookInstitution[] = [];
  searched: boolean = false;
  searchValue: string = "";
  isAllowedToCreate = false;

  constructor(private addressbookService: AddressbookService,
              private rightsManagementService: RightsManagementService,
              private routingService: RoutingService,
              private modalService: NgbModal,
              private store: Store<{addressbookEntry: IAddressbookEntryState}>) {}

  ngOnInit() {
    this.searchValue = '';
    if (this.rightsManagementService.isAllowedToUseModule(MODULES.ADDRESSBOOK)) { this.isAllowedToCreate = true; }
  }

  ngOnChanges(c: SimpleChanges) {
    if (c.fieldValue && c.fieldValue.currentValue > 0) { // if a institution is chosen, then load its name from the server
      this.getInstitutionName();
    }
    if (c.fieldValue && !(c.fieldValue.currentValue > 0)) {
      this.fieldValueText = '';
    }
  }

  getInstitutionName() {
    this.addressbookService.getInstitutionDataDirectly(this.fieldValue).subscribe(
      (result) => {
        this.fieldValueText = result.name;
      },
      (error) => {},
      () => {}
      // todo catch errors
    );
  }

  search() {
    if (this.searchValue.length > 2) {
      this.addressbookService.typeaheadSearch(this.searchValue.toLowerCase()).subscribe(
        (addressbookEntries) => {
          this.searchResultInstitutions = addressbookEntries.institutions;
          this.searched = true;
        }
      );
    }
    else {
      this.searchResultInstitutions = [];
      this.searched = false;
    }
  }

  choose(objChosen: IAddressbookInstitution) {
    this.fieldValueChange.emit(objChosen.id);
    this.fieldValueText = objChosen.name;
    this.searchResultInstitutions = [];
    this.searchValue = "";
    this.showInputField = false;
    this.searched = false;
    this.showInputField = true;
  }

  resetValue() {
    this.fieldValueChange.emit(null);
    this.fieldValueText = '';
    this.searchResultInstitutions = [];
    this.searchValue = '';
    this.showInputField = false;
    this.searched = false;
    this.showInputField = true;
  }

  createNew() {
    const modalRef = this.modalService.open(AddressbookCreatePopupComponent, {size: 'lg'});
    modalRef.result.then(
      (institution: IAddressbookInstitution) => {
        if (!isNullOrUndefined(institution) && institution.id > 0) {
          this.choose(institution);
        }
      }
    )
  }

  showDetails() {
    const modalRef = this.modalService.open(AddressbookDetailPopupComponent);
    modalRef.componentInstance.id = this.fieldValue;
    modalRef.result.then(
      (result: IAddressbookInstitution) => {
        if (!isNullOrUndefined(result)) { // if edit button was pressed, continue to editing entry
          this.store.dispatch(new AddressbookEntryActions.SetActiveAddressbookEntry(result));
          this.routingService.navigateToModule({moduleName: MODULES.ADDRESSBOOK});
          this.store.dispatch(new UiActions.SetActivePage({module:MODULES.ADDRESSBOOK, page: MODULE_PAGES.ADDRESSBOOK.EDIT}));
        }
      }
    )
  }
}
