import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {AuthenticationService} from '../../services/authentication.service';
import {IUi} from '../../models/ui.interface';
import {IAuthUser} from '../../models/auth-user.interface';
import {INetworkState} from '../../store/network-state.interface';
import {IUserRole} from '../../models/userrole.interface';
import {Store} from '@ngrx/store';
import * as UiActions from '../../store/ui.actions';
import {RoutingService} from '../../routing/routing.service';
import {IScrollCommand} from '../../models/scroll-command.interface';
import {IModulePages, MODULE_PAGES, MODULES} from '../../routing/routes.model';
import {CaseService} from '../../services/case.service';
import {FileUploadService} from '../../services/file-upload.service';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FileUploaderComponent} from '../../files/file-upload/file-uploader/file-uploader.component';
import {ConstantsService} from '../../services/constants.service';
import {SearchStandesamtComponent} from '../../stammbaum/search-standesamt/search-standesamt.component';
import {NetworkManagerMessagesComponent} from '../../network-manager/network-manager-messages/network-manager-messages.component';
import {RightsManagementService} from '../../services/rights-management.service';
import {NVZStandesamtSortComponent} from '../../namensverzeichnisse/nvz-standesamt-sort/nvz-standesamt-sort.component';
import {Router} from '@angular/router';


@Component({
  selector: 'app-topmenu',
  templateUrl: 'topmenu.component.html',
  styleUrls: ['topmenu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TopmenuComponent implements OnInit, OnDestroy {
  @Input() ui: {ui: IUi};
  @Input() authUser: {authUser: IAuthUser};
  @Input() network: INetworkState;
  userRolesObs: Observable<IUserRole[]>;
  userRolesSub: Subscription;
  userRole = '';
  MODULE_PAGES: IModulePages;
  MODULES;

  constructor(private authenticationService: AuthenticationService,
              public rightsManagementService: RightsManagementService,
              private routingService: RoutingService,
              public constantsService: ConstantsService,
              private caseService: CaseService,
              private router: Router,
              private ngbModal: NgbModal,
              public fileUploadService: FileUploadService,
              private store: Store<{userRoles: IUserRole[], ui: IUi}>) {
    this.userRolesObs = this.store.select('userRoles');
    this.userRolesSub = this.userRolesObs.subscribe(
      (userRoles) => {
        if (this.authUser && this.authUser.authUser && this.authUser.authUser.user && this.authUser.authUser.user.userrole) {
          const ur = userRoles.find(x => +x.id === +this.authUser.authUser.user.userrole);
          if (ur) {
            this.userRole = ur.description;
          }
        }
      });
  }

  ngOnInit() {
    this.MODULE_PAGES = MODULE_PAGES;
    this.MODULES = MODULES;
  }

  goToDashboard() {
    this.routingService.navigateToModule({moduleName: MODULES.DASHBOARD});
  }

  goToStammbaum() {
    this.router.navigate(['/stammbaum', {'id': this.caseService.activeCaseId}]);
    /*
    this.routingService.navigateToModule({moduleName: MODULES.CASES});
    this.store.dispatch(new CaseActions.SetCaseModuleHeadline('Stammbaum Fall ' + this.caseService.activeCaseLastName + ' (' + this.caseService.activeCaseId + ')'));
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.CASES, page: MODULE_PAGES.CASES.STAMMBAUM}));
  */
  }

  ngOnDestroy() {
    this.userRolesSub.unsubscribe();
  }

  scrollToTop() {
    this.store.dispatch(new UiActions.TriggerScrollCommand(<IScrollCommand>{toTop: true}));
  }

  scrollToNote() {
    this.store.dispatch(new UiActions.TriggerScrollCommand(<IScrollCommand>{toNotes: true}));
  }

  scrollToPersons() {
    this.store.dispatch(new UiActions.TriggerScrollCommand(<IScrollCommand>{toPersons: true}));
  }

  scrollToFiles() {
    this.store.dispatch(new UiActions.TriggerScrollCommand(<IScrollCommand>{toFiles: true}));
  }

  caseBoerse() {
    this.caseService.goToBoerse();
  }

  caseSearch() {
    this.caseService.goToCaseSearch();
  }

  casePool() {
    this.caseService.goToPool();
  }

  goToNewCaseView() {
    this.caseService.goToNewCaseView();
  }

  goBackToCaseFromStammbaum() {
    this.caseService.goToCasedetailId(this.caseService.getActiveCaseId());
  }

  nvzBackToSearch() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.NAMENSVERZEICHNISSE, page: MODULE_PAGES.NAMENSVERZEICHNISSE.SEARCH}));
  }

  userTree() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.USERS, page: MODULE_PAGES.USERS.USER_TREE}));
  }

  userList() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.USERS, page: MODULE_PAGES.USERS.USER_LIST}));
  }

  standesamtSearch() {
    const modalRef = this.ngbModal.open(SearchStandesamtComponent, <NgbModalOptions>{size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.navigate_to_selected = true;
  }

  showUploadManager() {
    const modalRef: NgbModalRef = this.ngbModal.open(FileUploaderComponent, <NgbModalOptions>{size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.display_only = true;
  }

  showNetworkManager() {
    const modalRef: NgbModalRef = this.ngbModal.open(NetworkManagerMessagesComponent, <NgbModalOptions>{size: 'lg'});
  }

  standesamtSort() {
    const modalRef: NgbModalRef = this.ngbModal.open(NVZStandesamtSortComponent, <NgbModalOptions>{size: 'lg', backdrop: 'static'});
  }
}
