import {Component, Input, OnDestroy} from '@angular/core';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import {IStammbaumMarriage} from '../stammbaum-marriage.interface';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EditMarriageComponent} from '../edit-marriage/edit-marriage.component';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {Store} from '@ngrx/store';
import {PERSON_MIN_HEIGHT_PX, PERSON_WIDTH_PX, StammbaumDisplayService} from '../../services/stammbaum-display.service';
import {Subscription} from 'rxjs';
import {IStammbaumVersion} from '../stammbaum-version.interface';

@Component({
  selector: '[stammbaum-tree-marriage-connection]',
  templateUrl: './stammbaum-tree-marriage-connection.component.html',
  styleUrls: ['./stammbaum-tree-marriage-connection.component.css']
})
export class StammbaumTreeMarriageConnectionComponent implements OnDestroy {
  x1 = 0;
  y1 = 0;
  x2 = 0;
  y2 = 0;
  p1_is_secondary_marriage = false;
  p1_eo = 0;
  p2_is_secondary_marriage = false;
  p2_eo = 0;
  realHeight = PERSON_MIN_HEIGHT_PX;
  realHeightSub: Subscription;
  @Input() treeViewMode: string;
  @Input() m: IStammbaumMarriage;
  @Input() version: IStammbaumVersion;
  @Input() mapOnly = false;
  @Input() set p1(p1: IStammbaumPerson) {
    if (!p1) {
      return;
    }
    this.x1 = p1.displayParams.x + PERSON_WIDTH_PX;
    this.y1 = p1.displayParams.y + 0.5 * this.realHeight;
    this.p1_is_secondary_marriage = p1.no_more_partners;
    this.p1_eo = p1.erbordnung;
  }
  @Input() set p2(p2: IStammbaumPerson) {
    if (!p2) {
      return;
    }
    this.x2 = p2.displayParams.x;
    this.y2 = p2.displayParams.y + 0.5 * this.realHeight;
    this.p2_is_secondary_marriage = p2.no_more_partners;
    this.p2_eo = p2.erbordnung;
  }

  constructor(private store: Store<{stammbaum: IStammbaumState}>,
              private ngbModal: NgbModal,
              private stammbaumDisplayService: StammbaumDisplayService) {
    this.realHeightSub = this.stammbaumDisplayService.personHeight.subscribe(
      (height) => {
        this.realHeight = height;
      }
    );
  }

  hideConnection() {
    if (!this.p1_eo || !this.p2_eo || !this.version) { return true; }
    if (this.version.hide_eo_4 && ((this.p1_eo === 4) || (this.p2_eo === 4))) { return true; }
    if (this.version.hide_eo_3 && ((this.p1_eo === 3) || (this.p2_eo === 3))) { return true; }
    return false;
  }

  ngOnDestroy(): void {
    this.realHeightSub.unsubscribe();
  }
}
