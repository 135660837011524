import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {StammbaumService} from '../../services/stammbaum.service';
import {ICase} from '../case.interface';
import {CaseSaveMode, CaseService} from '../../services/case.service';
import {RightsManagementService} from '../../services/rights-management.service';
import {UserService} from '../../services/user.service';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {IStammbaumPerson} from '../../stammbaum/stammbaum-person.interface';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs/internal/Subscription';
import {ITeamMember} from '../team-member.interface';
import {PersonPopupComponent} from '../person-popup/person-popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ShowEbsComponent} from './show-ebs/show-ebs.component';
import {DocumentsService} from '../../documents/documents.service';

@Component({
  selector: 'case-detail-ebs-doku',
  templateUrl: './case-detail-ebs-doku.component.html',
  styleUrls: ['./case-detail-ebs-doku.component.css']
})
export class CaseDetailEBSDokuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() stammbaumState: IStammbaumState;
  @Input() copiedActiveCase: ICase;
  @Input() activeCase: ICase;
  @Input() editMode: CaseSaveMode;
  @Output() editModeChanged: EventEmitter<CaseSaveMode> = new EventEmitter<CaseSaveMode>();
  ausschlagungen: IStammbaumPerson[] = [];
  unwillige: IStammbaumPerson[] = [];
  nachverstorbene: IStammbaumPerson[] = [];
  CaseSaveMode = CaseSaveMode;
  teamMembersSub: Subscription;
  teamMembers: ITeamMember[] = [];
  userOptions = [];
  erbenOptions = [];
  erklaerunsOptions = [];

  constructor(public stammbaumService: StammbaumService,
              private store: Store<any>,
              private ngbModal: NgbModal,
              private caseService: CaseService,
              public rightsManagementService: RightsManagementService,
              public userService: UserService,
              private documentService: DocumentsService) {}

  ngOnInit() {
    this.teamMembersSub = this.store.select('teamMembers').subscribe(
      (teamMembers) => {
        this.teamMembers = teamMembers.teamMembersForCurrentCase.filter(x => !x.deleted);
      });
    this.userOptions = this.usersAsOptions();
    this.erklaerunsOptions = this.getErklaerungOsptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.stammbaumState) {
      this.ausschlagungen = this.stammbaumService.getAusschlagungen();
      this.unwillige = this.stammbaumService.getUnwilligeErben();
      this.nachverstorbene = this.stammbaumService.getNachverstorbeneErben();
      this.erbenOptions = this.erbenAsOptions();
    }
  }

  ngOnDestroy() {
    if (this.teamMembersSub) {
      this.teamMembersSub.unsubscribe();
    }
  }

  hasTrueStammbaum() {
    return this.stammbaumService.hasTrueStammbaum();
  }

  zeigeFehlendeUrkunden(mode: number) {
    this.stammbaumService.fehlendeUrkundenPDF(mode);
  }

  usersAsOptions() {
    return this.userService.usersAsOptions();
  }

  getErklaerungOsptions() {
    return this.caseService.getErklaerungOptions();
  }

  erbenAsOptions() {
    return this.stammbaumService.getErbenAsOptions();
  }

  displayPersonData(persId) {
    if (this.stammbaumState && this.stammbaumState.stammbaum && this.stammbaumState.stammbaum.persons) {
      const p = this.stammbaumState.stammbaum.persons.find(x => x.id === persId);
      if (p) { return `${p.nachname} ${p.vorname} ${p.geburtsdatum}`; }
    }
    return '';
  }

  onSelectPerson(persId: number) {
    if (!persId || persId < 1) {return; }
    const pers = this.stammbaumState.stammbaum.persons.find(x => x.id === persId);
    if (!pers) { return; }
    const modalRef = this.ngbModal.open(PersonPopupComponent);
    modalRef.componentInstance.heir = pers;
    modalRef.result.then(
      (result) => {
        if (pers && result) {
          this.caseService.goToPersondetailId(pers.id, `${pers.nachname} ${pers.vorname}`);
        }
      },
      () => {}
    );
  }

  isAllowedToESA() {
    return this.rightsManagementService.isAllowedToCreateESA();
  }

  isAllowedToEV() {
    return this.rightsManagementService.isAllowedToCreateEV();
  }
  setEditMode(mode: CaseSaveMode) {
    this.editModeChanged.emit(mode);
  }

  goToCase(id: number) {
    this.caseService.goToCasedetailId(id);
  }

  saveData() {
    this.caseService.saveCaseData(this.copiedActiveCase, this.editMode);
    this.setEditMode(CaseSaveMode.Kein_Modus_gewaehlt);
  }

  reloadData() {
    this.setEditMode(CaseSaveMode.Kein_Modus_gewaehlt);
    this.copiedActiveCase = {...this.activeCase};
  }

  getCaseLeaders() {
    return this.teamMembers.filter(x => !x.deleted && x.leader);
  }

  getEBSDokuMembers() {
    return this.teamMembers.filter(x => !x.deleted && x.ebs_doku);
  }

  showEBSAntrag() {
    this.documentService.createDocxForEBS(this.stammbaumService.activeVersion.id)
    // const ebsModalRef = this.ngbModal.open(ShowEbsComponent, {backdrop: 'static', size: 'lg'});
    // ebsModalRef.componentInstance.versionID = this.stammbaumService.activeVersion.id;
  }

  showEVEntwurf() {
    this.documentService.createDocxForEV(this.activeCase.id)
    // const ebsModalRef = this.ngbModal.open(ShowEbsComponent, {backdrop: 'static', size: 'lg'});
    // ebsModalRef.componentInstance.versionID = this.stammbaumService.activeVersion.id;
  }

}
