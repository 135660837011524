import {Component} from "@angular/core";
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'notify-modal',
  templateUrl: './notify-modal.component.html',
  styleUrls: ['./notify-modal.component.css']
})

export class NotifyModalComponent {
  message: string = "";
  title: string = "";

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  confirm() {
    this.activeModal.close(true);
  }
}
