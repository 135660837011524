import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-welcome-popup',
  templateUrl: './welcome-popup.component.html',
  styleUrls: ['./welcome-popup.component.css']
})
export class WelcomePopupComponent implements OnInit {

  constructor(private ngbActiveModal: NgbActiveModal) { }

  ngOnInit() {}

  close() {
    this.ngbActiveModal.close();
  }

}
