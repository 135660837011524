import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NetworkService} from '../../services/network.service';
import {IClientConnection} from '../../models/client-connection.interface';

@Component({
  selector: 'client-connection-modal',
  templateUrl: './client-connection-modal.component.html',
  styleUrls: ['./client-connection-modal.component.css']
})

export class ClientConnectionModalComponent implements OnInit {
  ccon: IClientConnection[] = [];
  constructor(public activeModal: NgbActiveModal,
              private networkService: NetworkService) {}

  ngOnInit() {
    this.networkService.getClientConnectionStatus().subscribe(
      (result) => this.ccon = result
    );
  }

  cancel() {
    this.activeModal.close(false);
  }

  confirm() {
    this.activeModal.close(true);
  }
}
