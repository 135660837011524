import {Component, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NVZService} from '../../services/nvz.service';
import {Subscription} from 'rxjs';
import {INVZState} from '../../store/nvz-state.interface';
import {Store} from '@ngrx/store';

@Component({
  selector: 'nvz-standesamt-sort',
  templateUrl: './nvz-standesamt-sort.component.html',
  styleUrls: ['./nvz-standesamt-sort.component.css']
})
export class NVZStandesamtSortComponent implements OnDestroy {
  nvzStateSub: Subscription;
  nvzState: INVZState;

  constructor(private ngbActiveModal: NgbActiveModal,
              private nvzService: NVZService,
              private store: Store<{nvz: INVZState}>) {
    this.nvzService.getStandesaemter(-1); // get all standesaemter that belong to no kreis/stadt
    this.nvzStateSub = this.store.select('nvz').subscribe(
      (nvzState) => { this.nvzState = nvzState; }
    );
  }

  ngOnDestroy(): void {
    this.nvzStateSub.unsubscribe();
  }

  close() {
    this.ngbActiveModal.close();
  }
}
