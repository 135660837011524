import { Component, OnInit } from '@angular/core';
import {ConstantsService} from '../../../services/constants.service';
import {HttpClient} from '@angular/common/http';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-show-ebs',
  templateUrl: './show-ebs.component.html',
  styleUrls: ['./show-ebs.component.css']
})
export class ShowEbsComponent implements OnInit {

  ebsText = '';
  versionID: number;

  constructor(private constantsService: ConstantsService,
              private http: HttpClient,
              private ngbActiveModal: NgbActiveModal) { }

  ngOnInit() {
    this.http.get<string>(`${this.constantsService.getApiEndpoint()}/v6/get-ebs/${this.versionID}/`, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (response) => {
        this.ebsText = response
        console.log(this.ebsText)
      },
      (error) => {
        this.ebsText = error
      }
    );
  }

  close() {
    this.ngbActiveModal.close()
  }

}
