import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import {IGeneratedDocumentState} from "../store/generated-document-state.interface";
import {ConstantsService} from "./constants.service";
import {IGeneratedDocCategory} from "../generated-documents/generated-doc-category.interface";
import * as GeneratedDocumentActions from "../store/generated-document.actions";

@Injectable()
export class GeneratedDocumentService {
  constructor(private store: Store<{ generatedDocument: IGeneratedDocumentState }>,
              private http: HttpClient,
              private constantsService: ConstantsService) {
  }

  reloadCategories() {
    this.http.get<IGeneratedDocCategory[]>(`${this.constantsService.getApiEndpoint()}/v3/generated-document-category/`, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (response) => {
        this.store.dispatch(new GeneratedDocumentActions.ReplaceGeneratedDocumentCategories(response));
      },
      () => {
      }, // todo catch errors
      () => {
      }); // todo catch timeouts
  }
}
