import {Component} from '@angular/core';

@Component({
  selector: 'nvz-ap-config',
  templateUrl: './nvz-ap-config.component.html',
  styleUrls: ['./nvz-ap-config.component.css']
})
export class NvzApConfigComponent {
  mode: any;
  parameter_mode: any;
  setMode(param: any) {}
  setParameterMode(param: any) {}
}
