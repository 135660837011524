import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import { Observable, Subscription, timer } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { ConstantsService } from './constants.service';
import {IUser} from '../models/user.interface';
import {IUserState} from '../store/user-state.interface';
import {INetworkState} from '../store/network-state.interface';
import * as NetworkActions from '../store/network.actions';
import * as UserActions from '../store/user.actions';
import {IAuthUser} from '../models/auth-user.interface';
import * as AppNotificationActions from '../store/app-notification.actions';
import {
  NOTIFICATION_MESSAGE_LOAD_USERS_FAILED_ERROR, NOTIFICATION_MESSAGE_LOAD_USERS_FAILED_TIMEOUT,
  NOTIFICATION_MESSAGE_LOAD_USERS_IN_PROGRESS, NOTIFICATION_MESSAGE_SAVE_USERS_FAILED_ERROR,
  NOTIFICATION_MESSAGE_SAVE_USERS_FAILED_TIMEOUT,
  NOTIFICATION_MESSAGE_SAVE_USERS_IN_PROGRESS,
  NOTIFICATION_SLOT_USERS
} from '../app-notifications/app-notification.interface';
import {UserroleService} from './userrole.service';
import {IUserRole} from '../models/userrole.interface';

const TRY_RELOAD_INTERVAL = 10 * 1000; // 10 Sekunden

@Injectable()
export class UserService implements OnDestroy {

  private baseUrl: string;
  userlistLoadingInProgress = false;
  noTimeoutFlag = false;
  saveUserInProgress = false;
  reloadTimerSubscription: Subscription;
  networkObs: Observable<INetworkState>;
  network: INetworkState;
  networkSubscription: Subscription;
  authUserObs: Observable<{authUser: IAuthUser}>;
  authUser: IAuthUser;
  authUserSubscription: Subscription;
  userState: IUserState;
  userStateSub: Subscription;

  constructor(private constantsService: ConstantsService,
              private http: HttpClient,
              private userRoleService: UserroleService,
              private store: Store<{users: IUserState, network: INetworkState, authUser: {authUser: IAuthUser}}>) {
    this.baseUrl = constantsService.getApiEndpoint();
    this.userStateSub = this.store.select('users').subscribe(
      (userState) => {this.userState = userState; }
      );
    this.networkObs = this.store.select('network');
    this.networkSubscription = this.networkObs.subscribe((network) => { this.network = network});
    this.authUserObs = this.store.select('authUser');
    this.authUserSubscription = this.authUserObs.subscribe((authUser) => { this.authUser = authUser.authUser});
    this.reloadTimerSubscription = timer(0, TRY_RELOAD_INTERVAL).subscribe(t => {
      if (this.network.updateNeededCase && this.authUser.loggedIn && this.network.isConnectedToServer) { // if user is logged in, reload is neccessary and there is a connection
        this.getUsersFromServer();
      }
    });
  }

  getUserGenderOptions() {
    return [
      {value: 0, description: 'keine Angabe'},
      {value: 1, description: 'weiblich'},
      {value: 2, description: 'männlich'},
    ];
  }

  getUserName(id: number) {
    let user: IUser;
    if (this.userState) {
      user = this.userState.users.find(x => +x.id === +id);
      if (user) {
        return user.first_name + ' ' + user.last_name;
      }
    }
    return '';
  }

  getSeniors() {
    const userroles: IUserRole[] = this.userRoleService.getUserRoles();
    if (userroles) {
      const userrole = userroles.find(x => x.description === 'Senior-Ermittler');
      if (userrole && this.userState && this.userState.users) {
        return this.userState.users.filter(x => (x.userrole.id === userrole.id) && x.is_active);
      }
    }
    return [];
  }

  getAbbreviationForUser(id: number) {
    let user: IUser;
    if (this.userState) {
      user = this.userState.users.find(x => +x.id === +id);
      if (user) {
        return user.abbreviation;
      }
    }
    return '';
  }

  ngOnDestroy() {
    this.authUserSubscription.unsubscribe();
    this.reloadTimerSubscription.unsubscribe();
    this.networkSubscription.unsubscribe();
    this.userStateSub.unsubscribe();
  }

  triggerUserlistReloadFromServer() {
    this.store.dispatch(new NetworkActions.SetUpdateNeededUserTrue());
    this.getUsersFromServer();
  }

  getUsersFromServer() {
    if (this.userlistLoadingInProgress) {
      return; // if loading already in progress, ignore!
    }
    this.userlistLoadingInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_USERS));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_USERS_IN_PROGRESS));
    this.noTimeoutFlag = false;
    this.http.get<IUser[]>(`${this.baseUrl}/v2/user-list/`, {headers: this.constantsService.getHttpOptions()}).
      subscribe(
      (response) => {
        this.store.dispatch(new UserActions.ReplaceUsersInUserlist(response));
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_USERS));
        this.store.dispatch(new NetworkActions.SetUpdateNeededUserFalse());
        this.noTimeoutFlag = true;
        this.userlistLoadingInProgress = false;
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_USERS));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_USERS_FAILED_ERROR));
        this.noTimeoutFlag = true;
        this.userlistLoadingInProgress = false;
      },
      () => {
        if (!this.noTimeoutFlag) {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_USERS));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_USERS_FAILED_TIMEOUT));
        }
        this.userlistLoadingInProgress = false;
      }
    );
  }

  changeSenior(user: number, senior: number) {
    this.http.post(`${this.baseUrl}/v2/user-change-senior/`, {id: user, senior: senior}, {headers: this.constantsService.getHttpOptions()})
      .subscribe(
        (result) => {}, // todo confirm changes executed
        (error) => {}, // todo catch errors
        () => {}
      );
  }

   edit_save(user: IUser) {
    this.saveUserInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_USERS));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_USERS_IN_PROGRESS));
    this.http.put(`${this.baseUrl}/v2/user-edit-save/`, user, {headers: this.constantsService.getHttpOptions()})
      .subscribe(
        (result) => {
          this.saveUserInProgress = false;
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_USERS));
        },
        (error) => {
          this.saveUserInProgress = false;
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_USERS));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_USERS_FAILED_ERROR));
        },
        () => {
          if (this.saveUserInProgress) {
            this.saveUserInProgress = false;
            this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_USERS));
            this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_USERS_FAILED_TIMEOUT));
          }
        }
      );
  }

  save(user: IUser) {
    this.saveUserInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_USERS));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_USERS_IN_PROGRESS));
    this.http.put(`${this.baseUrl}/v2/user-profile-save/`, user, {headers: this.constantsService.getHttpOptions()})
      .subscribe(
        (result) => {
          this.saveUserInProgress = false;
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_USERS));
        },
        (error) => {
          this.saveUserInProgress = false;
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_USERS));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_USERS_FAILED_ERROR));
        },
        () => {
          if (this.saveUserInProgress) {
            this.saveUserInProgress = false;
            this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_USERS));
            this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_USERS_FAILED_TIMEOUT));
          }
        }
      );
  }

  // OLD METHODS BELOW

  changePassword(user: IUser, oldPwd: string, newPwd: string) {
    const body = 'user=' + user.id + '&old=' + oldPwd + '&new=' + newPwd;
    return this.http
      .post(`${this.baseUrl}/user_pwd_change/`, body, {headers: this.constantsService.getHttpFormPostOptions()});
  }

  resetPassword(user: IUser) {
    const body = 'user_id=' + user.id;
    return this.http
      .post(`${this.baseUrl}/user_pwd_reset/`, body, {headers: this.constantsService.getHttpFormPostOptions()});
  }

  create(user: IUser): Observable<any> {
    let body = 'first_name=' + user.first_name + '&last_name=' + user.last_name + '&mobile=';
    body += user.mobile + '&abbreviation=' + user.abbreviation + '&email=' + user.email + '&userrole=' + user.userrole;
    return this.http.post(`${this.constantsService.getApiEndpoint()}/user_create/`, body, {headers: this.constantsService.getHttpFormPostOptions()});
  }

  uploadImage(fileList: FileList, user: IUser) {
  const file: File = fileList[0];
    const formData: FormData = new FormData();
    formData.append('user_id', user.id.toString());
    formData.append('file', file);
    return this.http.post(`${this.constantsService.getApiEndpoint()}/user_pic_upload/`, formData, {headers: this.constantsService.getHttpFileUploadOptions()});
  }

  getActiveUserId() {
    if (this.authUser && this.authUser.user) {
      return this.authUser.user.id;
    }
    return null;
  }

  usersAsOptions() {
    if (!this.userState || !this.userState.users) { return []; }
    const uasop = [{id: '', description: '-----------------'}];
    for (const u of this.userState.users.filter(x => x.is_active)) {
      uasop.push({id: u.id.toString(), description: `${u.first_name} ${u.last_name} (${u.abbreviation})`});
    }
    return uasop;
  }

  sendMassMailToUsers(mail_infos) {
    return this.http.post(`${this.baseUrl}/v6/send-mass-mail/`, mail_infos, {headers: this.constantsService.getHttpOptions()});
  }
}
