import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {FormControl, FormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {IStammbaumCheckpoint} from '../stammbaum-checkpoint.interface';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'stammbaum-checkpoints',
  templateUrl: './stammbaum-checkpoints.component.html',
  styleUrls: ['./stammbaum-checkpoints.component.css']
})
export class StammbaumCheckpointsComponent implements OnInit {
  stammbaumState: IStammbaumState;
  form: FormGroup;
  showAuto = false;
  checkpointsToShow: IStammbaumCheckpoint[] = [];

  constructor(private ngbActiveModal: NgbActiveModal,
              public userService: UserService,
              private store: Store<{stammbaum: IStammbaumState}>) {
    this.form = new FormGroup({
      title: new FormControl(''),
      comment: new FormControl('')
    });
  }

  ngOnInit(): void {
    if (this.stammbaumState) {
      this.selectCBToShow();
    }
  }

  selectCBToShow() {
    this.checkpointsToShow = this.stammbaumState.stammbaum.version.checkpoints.filter(x => this.showAuto || !x.autosave);
  }

  cancel() {
    this.ngbActiveModal.close();
  }

  toggleShowAuto() {
    this.showAuto = !this.showAuto;
    this.selectCBToShow();
  }

  loadCheckpoint(id: number) {
    this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumCheckpoint(id));
    this.cancel();
  }

  saveCheckpoint() {
    this.store.dispatch(new StammbaumActions.TriggerSaveStammbaumCheckpoint({
      version_id: this.stammbaumState.stammbaum.version.id,
      title: this.form.controls.title.value,
      comment: this.form.controls.comment.value
    }));
    this.cancel();
  }
}
