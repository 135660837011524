import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {AppNotificationComponent} from "./app-notification.component";

@NgModule({
  declarations: [
    AppNotificationComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AppNotificationComponent
  ]
})

export class AppNotificationModule {}
