import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {IStammbaumPerson} from '../../stammbaum/stammbaum-person.interface';
import {PersonType, StammbaumService} from '../../services/stammbaum.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PersonPopupComponent} from '../person-popup/person-popup.component';
import {CaseService} from '../../services/case.service';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {Store} from '@ngrx/store';


@Component({
  selector: 'case-detail-max-nt-without-version',
  templateUrl: './case-detail-max-nt-without-version.component.html',
  styleUrls: ['./case-detail-max-nt-without-version.component.css']
})
export class CaseDetailMaxNtWithoutVersionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() stammbaumState: IStammbaumState;
  stammbaumPersonsToDisplay: IStammbaumPerson[] = [];
  searchString = '';
  createNewInProgress = false;

  constructor(private stammbaumService: StammbaumService,
              private store: Store<{stammbaum: IStammbaumState}>,
              private caseService: CaseService,
              private ngbModal: NgbModal) {
  }

  ngOnInit() {
    this.copyAndRunFilters();
  }

  ngOnChanges() {
    this.copyAndRunFilters();
  }

  createNew() {
    this.createNewInProgress = true;
    this.stammbaumService.addStammbaumPersonWithoutVersion(<IStammbaumPerson>{
      person_type: PersonType.Namenstraeger,
      nachname: '',
      vorname: '',
      geburtsname: '',
      case: this.caseService.getActiveCaseId()
    }).subscribe(
      (result) => {
        this.caseService.goToPersondetailId(result.id);
        this.createNewInProgress = false;
         this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(this.caseService.getActiveCaseId()));
        },
      (error) => {
        console.log(error);
        this.createNewInProgress = false;
        },
    () => {}
    );
  }

  getPersonTypeText(personType: number) {
    return this.stammbaumService.getPersonTypeText(personType);
  }

  onSelect(item: IStammbaumPerson) {
    const modalRef = this.ngbModal.open(PersonPopupComponent);
    modalRef.componentInstance.heir = item;
    modalRef.result.then(
      (result) => {
        if (item && result) {
          this.caseService.goToPersondetailId(item.id, `${item.nachname} ${item.vorname}`);
        }
      },
      () => {}
    );
  }

  copyAndRunFilters() {
    if (!this.stammbaumState) {
      return;
    }
    let heirs: IStammbaumPerson[] = [...this.stammbaumState.stammbaumPersonsWithoutVersion];
    heirs = heirs.filter(x => { return this.stammbaumService.isPersonNT(x); });
    this.stammbaumPersonsToDisplay = heirs;
  }

  ngOnDestroy() {
  }

}
