import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AddressService} from '../../services/address.service';
import {isNullOrUndefined} from 'util';
import {CaseService} from '../../services/case.service';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {RightsManagementService} from '../../services/rights-management.service';
import {IStammbaumPerson} from '../../stammbaum/stammbaum-person.interface';
import {Store} from '@ngrx/store';
import {ICaseState} from '../../store/case-state.interface';
import {PersonType, StammbaumService} from '../../services/stammbaum.service';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {Address} from '../../models/address';
import {CustomUppercasePipe} from '../../tools/custom-uppercase-pipe/custom-uppercase-pipe';
import {StammbaumTreeUrkundenUploadComponent} from '../../stammbaum/stammbaum-tree-urkunden-upload/stammbaum-tree-urkunden-upload.component';
import {IStammbaumMarriage} from '../../stammbaum/stammbaum-marriage.interface';
import {IUrkundenFileRef} from '../../stammbaum/urkunden-file-ref.interface';
import {UrkundenPopupComponent} from '../../stammbaum/urkunden-popup/urkunden-popup.component';
import {VundvPopupComponent} from '../../documents/vundv-popup/vundv-popup.component';
import {DocumentsService} from '../../documents/documents.service';


@Component({
  selector: 'person-detail',
  templateUrl: './person-detail.component.html',
  styleUrls: ['./person-detail.component.css']
})
export class PersonDetailComponent implements OnInit, OnDestroy {

  activePersonSub: Subscription;
  activePerson: IStammbaumPerson;
  copiedPerson: IStammbaumPerson;
  PersonType = PersonType;

  id: number;
  private sub: any;
  editModePersonData = false;
  editModeAdditionalData = false;
  editModeRequiredDocuments = false;
  editModeRequiredDocsPart = '';


  constructor(private adressService: AddressService,
              private documentsService: DocumentsService,
              private stammbaumService: StammbaumService,
              private customUppercasePipe: CustomUppercasePipe,
              private modalService: NgbModal,
              private caseService: CaseService,
              private ngbModal: NgbModal,
              private store: Store<{ cases: ICaseState }>,
              private rightsManagementService: RightsManagementService) {
  }

  ngOnInit() {
    this.activePersonSub = this.store.select('cases').subscribe(
      (caseState) => {
        this.activePerson = caseState.activePerson;
        this.copiedPerson = {...this.activePerson};
        if (!this.copiedPerson.address) {
          this.copiedPerson.address = <Address>{}
        }
      }
    );
  }

  ngOnDestroy() {
    this.activePersonSub.unsubscribe();
  }

  createVundV() {
    const ngbModalRef = this.ngbModal.open(VundvPopupComponent, <NgbModalOptions>{backdrop: 'static'});
    ngbModalRef.componentInstance.person_id = this.copiedPerson.id;
    ngbModalRef.componentInstance.person = this.copiedPerson;
  }

  createDocx() {
    this.documentsService.createDocx(this.copiedPerson.id, 'empty');
  }

  getTitle() {
    if (!this.copiedPerson) {
      return ''
    }
    return `${this.copiedPerson.nachname} ${this.copiedPerson.vorname} (${this.copiedPerson.id}) - ${this.copiedPerson.case_display_data}`;
  }

  getPersonGroupOptions() {
    const pt = this.stammbaumService.person_type_options.find(x => x.id === this.activePerson.person_type);
    if (!pt) {
      return [];
    }
    if (pt.id === PersonType.Erblasser) {
      return this.stammbaumService.person_type_options.filter(x => x.id === PersonType.Erblasser);
    }
    if (!pt.is_in_tree) {
      return this.stammbaumService.person_type_options.filter(x => !x.is_in_tree);
    } else {
      return this.stammbaumService.person_type_options.filter(x => x.is_in_tree && (x.id !== PersonType.Erblasser));
    }
  }

  updateValue(property, bit, $event) {
    // console.log('update value for bit', bit, 'property', property, 'event', $event);
    // console.log('before', this.copiedPerson[property]);
    if ($event) {
      // tslint:disable-next-line:no-bitwise
      this.copiedPerson[property] = this.copiedPerson[property] | 1 << bit;
    } else {
      // tslint:disable-next-line:no-bitwise
      this.copiedPerson[property] = this.copiedPerson[property] & ~(1 << bit);
    }
    // console.log('after', this.copiedPerson[property]);
  }

  checkValue(property, bit) {
    // tslint:disable-next-line:no-bitwise
    return ((this.copiedPerson[property] >> bit) % 2 !== 0)
  }

  updateUserField(property, value, mar_id = null) {
    if (property !== 'ordered_hu_user') {
      this.copiedPerson[property] = value;
    } else {
      const m = this.copiedPerson.needs_hu_marriages.find(x => x.marriage_id === mar_id);
      if (m) {
        m.ordered_hu_user = value;
        console.log(m.ordered_hu_user);
      }
    }
  }

  isHeir() {
    return this.stammbaumService.isPersonHeir(this.copiedPerson);
  }

  back() {
    if (!this.editModePersonData && !this.editModeAdditionalData && !this.editModeRequiredDocuments) { // only switch display mode if edit mode is none
      this.caseService.goToCaseSearch();
    }
  }

  toCase(caseId: number) {
    this.caseService.goToCasedetailId(caseId);
  }

  toCaseHeir(caseId: number) {
    this.caseService.goToCasedetailId(caseId, '', false, 5);
  }

  toCaseNotes(caseId: number) {
    this.caseService.goToCasedetailId(caseId, '', false, 2);
  }

  isDeleteable() {
    if (true || !this.rightsManagementService.isAllowedToDeleteEmptyPersons()) { // todo enable again after reworking...
      return false;
    }
    if ((this.activePerson.vorname === null || this.activePerson.vorname === '') &&
      (this.activePerson.nachname === null || this.activePerson.nachname === '') &&
      (this.activePerson.geburtsname === null || this.activePerson.geburtsname === '') &&
      (this.activePerson.person_type === null || +this.activePerson.person_type === 0)) {
      return true;
    }
    return false;
  }

  deletePerson() {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.message = 'Wollen Sie diese Person wirklich löschen?';
    modalRef.componentInstance.title = 'Bestätigung';
    modalRef.result.then(
      (result) => {
        if (result) {
          this.deleteConfirmed();
        }
      });
  }

  deleteConfirmed() {
    this.copiedPerson.deleted = true;
    // this.heirService.save(this.copiedPerson).subscribe(response => { console.log(response)} )
  }

  saveData() {
    const mar = this.copiedPerson.needs_hu_marriages.find(x => x.marriage_id.toString() === this.editModeRequiredDocsPart);
    if (mar) { // marriage data has been changed
      const mtc = <IStammbaumMarriage>{
        id: mar.marriage_id,
        ordered_hu: mar.ordered_hu,
        ordered_hu_date: mar.ordered_hu_date,
        ordered_hu_user: mar.ordered_hu_user,
        needs_hu_comment: mar.needs_hu_comment
      };
      if (!this.stammbaumService.hasTrueStammbaum() || !this.stammbaumService.isPersonInTree(this.copiedPerson)) {
        this.store.dispatch(new StammbaumActions.TriggerEditMarriageWithoutVersion(mtc));
      } else {
        this.store.dispatch(new StammbaumActions.TriggerEditMarriageInActiveStammbaum(mtc));
      }
    }
    this.editModePersonData = false;
    this.editModeAdditionalData = false;
    this.editModeRequiredDocuments = false;
    this.copiedPerson.nachname = this.customUppercasePipe.transform(this.copiedPerson.nachname);
    if (!this.stammbaumService.hasTrueStammbaum() || !this.stammbaumService.isPersonInTree(this.copiedPerson)) {
      this.store.dispatch(new StammbaumActions.TriggerEditPersonWithoutVersion(<IStammbaumPerson>this.copiedPerson));
    } else {
      this.store.dispatch(new StammbaumActions.TriggerEditPersonInActiveStammbaum(<IStammbaumPerson>this.copiedPerson));
    }
  }

  uploadUrkunden() {
    const modalRef = this.ngbModal.open(StammbaumTreeUrkundenUploadComponent, {backdrop: 'static', size: 'lg'});
    modalRef.componentInstance.editMode = false;
    if (!this.copiedPerson.is_duplicate_of) {
      modalRef.componentInstance.person = this.stammbaumService.getStammbaumPersonById(this.copiedPerson.id);
    } else {
      modalRef.componentInstance.person = this.stammbaumService.getStammbaumPersonById(this.copiedPerson.is_duplicate_of);
    }
  }

  showUrkunden() {
    const urkunden: IUrkundenFileRef[] = [];
    for (const urkunde of this.copiedPerson.urkunden_for_person) {
      urkunden.push(this.stammbaumService.checkIfReferenceDocumentForDisplay(urkunde));
    }
    for (const mar of this.copiedPerson.marriages_for_stammbaum_person) {
      for (const urk of mar.urkunden_for_marriage) {
        if (!urkunden.find(x => x.id === urk.id)) {
          urkunden.push(this.stammbaumService.checkIfReferenceDocumentForDisplay(urk));
        }
      }
    }
    const modalRef = this.ngbModal.open(UrkundenPopupComponent, <NgbModalOptions>{size: 'lg'});
    modalRef.componentInstance.person = this.copiedPerson;
    modalRef.componentInstance.urkunden = urkunden;
  }

  reloadData() {
    this.editModePersonData = false;
    this.editModeAdditionalData = false;
    this.editModeRequiredDocuments = false;
  }

  editAdditionalData() {
    this.editModeAdditionalData = true;
  }

  editPersonData() {
    this.editModePersonData = true;
  }

  editRequiredDocuments(mode: string) {
    this.editModeRequiredDocsPart = mode;
    this.editModeRequiredDocuments = true;
  }

  getRevocationPeriodEndDate() {
    if (isNullOrUndefined(this.copiedPerson)) {
      return '';
    }
    if (isNullOrUndefined(this.copiedPerson.signature_date)) {
      return '';
    }
    if (this.copiedPerson.signature_date.length != 8) {
      return '';
    }
    const day = +this.copiedPerson.signature_date.slice(0, 2);
    const mon = +this.copiedPerson.signature_date.slice(2, 4) - 1;
    const year = +this.copiedPerson.signature_date.slice(4, 8);
    let endDate: Date = new Date(year, mon, day);
    endDate.setDate(endDate.getDate() + 14);
    const newDay = endDate.getDate().toString();
    const newMonth = (+endDate.getMonth() + 1).toString();
    const newYear = endDate.getFullYear().toString();
    let dateString: string = '';
    if (newDay.length == 1) {
      dateString += '0' + newDay;
    } else {
      dateString += newDay;
    }
    if (newMonth.length == 1) {
      dateString += '0' + newMonth;
    } else {
      dateString += newMonth;
    }
    dateString += newYear;
    return dateString;
  }
}
