import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import {CaseService} from '../../services/case.service';
import {IStammbaumMarriage} from '../stammbaum-marriage.interface';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {EditMarriageComponent} from '../edit-marriage/edit-marriage.component';
import {Subscription} from 'rxjs';
import {PersonType, StammbaumService} from '../../services/stammbaum.service';
import {CustomUppercasePipe} from '../../tools/custom-uppercase-pipe/custom-uppercase-pipe';
import {RightsManagementService} from '../../services/rights-management.service';
import {AgeCheckComponent} from '../../tools/age-check-popup/age-check.component';
import {DatesService} from '../../services/dates.service';

@Component({
  selector: 'edit-person',
  templateUrl: './edit-person.component.html',
  styleUrls: ['./edit-person.component.css'],
})
export class EditPersonComponent implements OnInit, OnDestroy {
  @Input() editMode = false;
  @Input() hasTrueStammbaum = false;
  form: FormGroup;
  marriages: IStammbaumMarriage[] = [];
  person_id: number;
  person: IStammbaumPerson;
  stammbaumStateSub: Subscription;
  person_with_status_erblasser_exists = false;
  current_person_type: number = null;

  constructor(private ngbActiveModal: NgbActiveModal,
              private ngbModal: NgbModal,
              public rightsManagementService: RightsManagementService,
              private customUppercasePipe: CustomUppercasePipe,
              private caseService: CaseService,
              private datesService: DatesService,
              public stammbaumService: StammbaumService,
              private store: Store<{ stammbaum: IStammbaumState }>) {
    this.form = new FormGroup({
      'id': new FormControl(null),
      'nachname': new FormControl(''),
      'person_type': new FormControl(''),
      'geburtsname': new FormControl(''),
      'vorname': new FormControl(''),
      'geburtsdatum': new FormControl(''),
      'nk_in_vierter_eo_nicht_erbberechtigt': new FormControl(false),
      'wird_gesucht': new FormControl(false),
      'sicher_keine_nachkommen': new FormControl(false),
      'geburtsinfo': new FormControl(''),
      'geburtsland': new FormControl(''),
      'sterbedatum': new FormControl(''),
      'sterbeinfo': new FormControl(''),
      'sterbeland': new FormControl(''),
      'kommentar': new FormControl(''),
      'needs_gu': new FormControl(false),
      'needs_gu_but_do_without': new FormControl(false),
      'ordered_gu': new FormControl(false),
      'ordered_gu_date': new FormControl(''),
      'needs_su': new FormControl(false),
      'needs_su_but_do_without': new FormControl(false),
      'ordered_su': new FormControl(false),
      'ordered_su_date': new FormControl('')
    });
  }

  ngOnInit(): void {
    this.stammbaumStateSub = this.store.select('stammbaum').subscribe(
      (stammbaumState) => {
        if (stammbaumState && stammbaumState.stammbaum.persons) {
          const erblasser = stammbaumState.stammbaum.persons.find(x => (x.person_type === 1) && (x.id !== stammbaumState.personToEditId));
          if (erblasser) {
            this.person_with_status_erblasser_exists = true;
          } else {
            this.person_with_status_erblasser_exists = false;
          }
        }
        let person: IStammbaumPerson;
        if (this.hasTrueStammbaum) {
          person = stammbaumState.stammbaum.persons.find(x => x.id === stammbaumState.personToEditId);
        } else {
          person = stammbaumState.stammbaumPersonsWithoutVersion.find(x => x.id === stammbaumState.personToEditId);
        }
        if (person) {
          if (this.form && this.editMode) {
            this.form.patchValue({
              'id': person.id,
              'person_type': person.person_type,
              'nachname': person.nachname,
              'geburtsname': person.geburtsname,
              'vorname': person.vorname,
              'geburtsdatum': person.geburtsdatum,
              'sicher_keine_nachkommen': person.sicher_keine_nachkommen,
              'nk_in_vierter_eo_nicht_erbberechtigt': person.nk_in_vierter_eo_nicht_erbberechtigt,
              'wird_gesucht': person.wird_gesucht,
              'geburtsinfo': person.geburtsinfo,
              'geburtsland': person.geburtsland,
              'sterbedatum': person.sterbedatum,
              'sterbeinfo': person.sterbeinfo,
              'sterbeland': person.sterbeland,
              'kommentar': person.kommentar,
              'needs_gu': person.needs_gu,
              'needs_gu_but_do_without': person.needs_gu_but_do_without,
              'ordered_gu': person.ordered_gu,
              'ordered_gu_date': person.ordered_gu_date,
              'needs_su': person.needs_su,
              'needs_su_but_do_without': person.needs_su_but_do_without,
              'ordered_su': person.ordered_su,
              'ordered_su_date': person.ordered_su_date
            });
            this.current_person_type = person.person_type;
          }
          this.marriages = person.marriages_for_stammbaum_person;
          this.person_id = person.id;
          this.person = person;
        }
      });
    this.form.get('sicher_keine_nachkommen').valueChanges.subscribe(val => {
      if (val) {
        this.form.patchValue({nk_in_vierter_eo_nicht_erbberechtigt: false});
      }
    });
    this.form.get('nk_in_vierter_eo_nicht_erbberechtigt').valueChanges.subscribe(val => {
      if (val) {
        this.form.patchValue({sicher_keine_nachkommen: false});
      }
    });
    this.form.get('person_type').valueChanges.subscribe(val => {
      this.current_person_type = val;
    });
  }

  ngOnDestroy(): void {
    this.stammbaumStateSub.unsubscribe();
    this.store.dispatch(new StammbaumActions.ReplacePersonToEditId(null));
  }

  addEhe() {
    this.store.dispatch(new StammbaumActions.ReplaceMarriageToEditId(null));
    let modalRef: NgbModalRef;
    modalRef = this.ngbModal.open(EditMarriageComponent, <NgbModalOptions>{backdrop: 'static'});
    modalRef.componentInstance.editMode = false;
    modalRef.componentInstance.hasTrueStammbaum = this.hasTrueStammbaum;
    modalRef.result.then(
      (result) => {
      },
      () => {
      }
    );
  }

  editEhe(marriage: IStammbaumMarriage) {
    this.store.dispatch(new StammbaumActions.ReplaceMarriageToEditId(marriage.id));
    let modalRef: NgbModalRef;
    modalRef = this.ngbModal.open(EditMarriageComponent, <NgbModalOptions>{backdrop: 'static'});
    modalRef.componentInstance.editMode = true;
    modalRef.componentInstance.hasTrueStammbaum = this.hasTrueStammbaum;
    modalRef.result.then(
      (result) => {
      },
      () => {
      }
    );
  }

  cancel() {
    this.ngbActiveModal.close();
  }

  check() {
    if (this.datesService.isDateReadable(this.form.controls.geburtsdatum.value) && this.datesService.isDateReadable(this.form.controls.sterbedatum.value)) {
      if (this.datesService.calculateAgeAtDeath(this.form.controls.geburtsdatum.value, this.form.controls.sterbedatum.value) < 12 && !this.form.controls.sicher_keine_nachkommen.value) {
        const modalRefAge: NgbModalRef = this.ngbModal.open(AgeCheckComponent, {backdrop: 'static'});
        modalRefAge.result.then(
          () => {
            this.form.patchValue({sicher_keine_nachkommen: true});
            this.save();
          },
          () => {
            this.save();
          }
        );
      } else {
        this.save();
      }
    } else {
      this.save();
    }
  }

  save() {
    if (!this.editMode) {
      if (!this.hasTrueStammbaum) {
        this.store.dispatch(new StammbaumActions.TriggerAddPersonWithoutVersion(<IStammbaumPerson>{
          vorname: this.form.controls.vorname.value,
          nachname: this.customUppercasePipe.transform(this.form.controls.nachname.value),
          person_type: this.form.controls.person_type.value,
          geburtsname: this.form.controls.geburtsname.value,
          geburtsdatum: this.form.controls.geburtsdatum.value,
          wird_gesucht: this.form.controls.wird_gesucht.value,
          sicher_keine_nachkommen: this.form.controls.sicher_keine_nachkommen.value,
          nk_in_vierter_eo_nicht_erbberechtigt: this.form.controls.nk_in_vierter_eo_nicht_erbberechtigt.value,
          geburtsinfo: this.form.controls.geburtsinfo.value,
          geburtsland: this.form.controls.geburtsland.value,
          sterbedatum: this.form.controls.sterbedatum.value,
          sterbeinfo: this.form.controls.sterbeinfo.value,
          sterbeland: this.form.controls.sterbeland.value,
          kommentar: this.form.controls.kommentar.value,
          case: this.caseService.getActiveCaseId(),
          needs_gu: this.form.controls.needs_gu.value,
          needs_gu_but_do_without: this.form.controls.needs_gu_but_do_without.value,
          ordered_gu: this.form.controls.ordered_gu.value,
          ordered_gu_date: this.form.controls.ordered_gu_date.value,
          needs_su: this.form.controls.needs_su.value,
          needs_su_but_do_without: this.form.controls.needs_su_but_do_without.value,
          ordered_su: this.form.controls.ordered_su.value,
          ordered_su_date: this.form.controls.ordered_su_date.value
        }));
      } else {
        this.store.dispatch(new StammbaumActions.TriggerAddPersonToActiveStammbaum(<IStammbaumPerson>{
          vorname: this.form.controls.vorname.value,
          nachname: this.form.controls.nachname.value,
          person_type: this.form.controls.person_type.value,
          geburtsname: this.form.controls.geburtsname.value,
          geburtsdatum: this.form.controls.geburtsdatum.value,
          wird_gesucht: this.form.controls.wird_gesucht.value,
          sicher_keine_nachkommen: this.form.controls.sicher_keine_nachkommen.value,
          nk_in_vierter_eo_nicht_erbberechtigt: this.form.controls.nk_in_vierter_eo_nicht_erbberechtigt.value,
          geburtsinfo: this.form.controls.geburtsinfo.value,
          geburtsland: this.form.controls.geburtsland.value,
          sterbedatum: this.form.controls.sterbedatum.value,
          sterbeinfo: this.form.controls.sterbeinfo.value,
          sterbeland: this.form.controls.sterbeland.value,
          kommentar: this.form.controls.kommentar.value,
          case: this.caseService.getActiveCaseId(),
          needs_gu: this.form.controls.needs_gu.value,
          needs_gu_but_do_without: this.form.controls.needs_gu_but_do_without.value,
          ordered_gu: this.form.controls.ordered_gu.value,
          ordered_gu_date: this.form.controls.ordered_gu_date.value,
          needs_su: this.form.controls.needs_su.value,
          needs_su_but_do_without: this.form.controls.needs_su_but_do_without.value,
          ordered_su: this.form.controls.ordered_su.value,
          ordered_su_date: this.form.controls.ordered_su_date.value
        }));
      }
    } else {
      if (!this.hasTrueStammbaum) {
        this.store.dispatch(new StammbaumActions.TriggerEditPersonWithoutVersion(<IStammbaumPerson>{
          id: this.form.controls.id.value,
          vorname: this.form.controls.vorname.value,
          nachname: this.form.controls.nachname.value,
          person_type: this.form.controls.person_type.value,
          geburtsname: this.form.controls.geburtsname.value,
          geburtsdatum: this.form.controls.geburtsdatum.value,
          wird_gesucht: this.form.controls.wird_gesucht.value,
          sicher_keine_nachkommen: this.form.controls.sicher_keine_nachkommen.value,
          nk_in_vierter_eo_nicht_erbberechtigt: this.form.controls.nk_in_vierter_eo_nicht_erbberechtigt.value,
          geburtsinfo: this.form.controls.geburtsinfo.value,
          geburtsland: this.form.controls.geburtsland.value,
          sterbedatum: this.form.controls.sterbedatum.value,
          sterbeinfo: this.form.controls.sterbeinfo.value,
          sterbeland: this.form.controls.sterbeland.value,
          kommentar: this.form.controls.kommentar.value,
          case: this.caseService.getActiveCaseId(),
          needs_gu: this.form.controls.needs_gu.value,
          needs_gu_but_do_without: this.form.controls.needs_gu_but_do_without.value,
          ordered_gu: this.form.controls.ordered_gu.value,
          ordered_gu_date: this.form.controls.ordered_gu_date.value,
          needs_su: this.form.controls.needs_su.value,
          needs_su_but_do_without: this.form.controls.needs_su_but_do_without.value,
          ordered_su: this.form.controls.ordered_su.value,
          ordered_su_date: this.form.controls.ordered_su_date.value
        }));
      } else {
        this.store.dispatch(new StammbaumActions.TriggerEditPersonInActiveStammbaum(<IStammbaumPerson>{
          id: this.form.controls.id.value,
          vorname: this.form.controls.vorname.value,
          nachname: this.form.controls.nachname.value,
          person_type: this.form.controls.person_type.value,
          geburtsname: this.form.controls.geburtsname.value,
          geburtsdatum: this.form.controls.geburtsdatum.value,
          wird_gesucht: this.form.controls.wird_gesucht.value,
          sicher_keine_nachkommen: this.form.controls.sicher_keine_nachkommen.value,
          nk_in_vierter_eo_nicht_erbberechtigt: this.form.controls.nk_in_vierter_eo_nicht_erbberechtigt.value,
          geburtsinfo: this.form.controls.geburtsinfo.value,
          geburtsland: this.form.controls.geburtsland.value,
          sterbedatum: this.form.controls.sterbedatum.value,
          sterbeinfo: this.form.controls.sterbeinfo.value,
          sterbeland: this.form.controls.sterbeland.value,
          kommentar: this.form.controls.kommentar.value,
          case: this.caseService.getActiveCaseId(),
          needs_gu: this.form.controls.needs_gu.value,
          needs_gu_but_do_without: this.form.controls.needs_gu_but_do_without.value,
          ordered_gu: this.form.controls.ordered_gu.value,
          ordered_gu_date: this.form.controls.ordered_gu_date.value,
          needs_su: this.form.controls.needs_su.value,
          needs_su_but_do_without: this.form.controls.needs_su_but_do_without.value,
          ordered_su: this.form.controls.ordered_su.value,
          ordered_su_date: this.form.controls.ordered_su_date.value
        }));
      }
    }
    this.ngbActiveModal.close();
  }

  public getPersonTypeOptions() {
    if (!this.hasTrueStammbaum) {
      return this.stammbaumService.person_type_options;
    }
    if (this.current_person_type === PersonType.Erblasser) {
      return this.stammbaumService.person_type_options.filter(x => x.id === PersonType.Erblasser);
    }
    return this.stammbaumService.person_type_options.filter(x => x.is_in_tree && (x.id !== PersonType.Erblasser));
  }
}
