import {Component} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ICalendarEntry} from '../calendar-entry.interface';
import {IAuthUser} from '../../models/auth-user.interface';
import {IUser} from '../../models/user.interface';
import {IUi} from '../../models/ui.interface';
import {ICalendarEntryState} from '../../store/calendar-entry-state.interface';
import {IUserState} from '../../store/user-state.interface';
import * as CalendarEntryActions from '../../store/calendar-entry.actions';
import {CalendarPopupComponent} from '../calendar-popup/calendar-popup.component';
import {CalendarEntryService} from '../../services/calendar-entry.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'calendar-notification',
  templateUrl: './calendar-notification.component.html',
  styleUrls: ['./calendar-notification.component.css']
})

export class CalendarNotificationComponent {

  calendarEntriesSub: Subscription;
  calendarEntriesOwn: ICalendarEntry[];
  calendarEntriesOther: ICalendarEntry[];
  authUser: IAuthUser;
  authUserSub: Subscription;
  usersStateSub: Subscription;
  users: IUser[] = [];

  constructor(private store: Store<{ui: IUi, calendarEntry: ICalendarEntryState, authUser: {authUser: IAuthUser}, users: IUserState}>,
              private modalService: NgbModal, public calendarEntryService: CalendarEntryService,
              public userService: UserService)
  {
    this.usersStateSub = this.store.select('users').subscribe(
      (users) => {
        this.users = users.users;
      }
    );
    this.authUserSub = this.store.select('authUser').subscribe((authUser) => {this.authUser = authUser.authUser;});
    this.calendarEntriesSub = this.store.select('calendarEntry').subscribe(
      (entries: {calendarEntries: ICalendarEntry[]}) => {
        let entriesCopy: ICalendarEntry[] = entries.calendarEntries;
        entriesCopy = entries.calendarEntries.filter(x => x.deadline_timestamp.getTime() <= new Date().getTime() + 1000 * 3600 * 24 * 2);
        entriesCopy = entriesCopy.sort((a, b) => a.deadline_timestamp.getTime() - b.deadline_timestamp.getTime());
        this.calendarEntriesOther = entriesCopy.filter(x => (x.created_by_user_id == this.authUser.user.id) && (x.for_user_id != this.authUser.user.id) && !x.completed);
        this.calendarEntriesOwn = entriesCopy.filter(x => (x.for_user_id == this.authUser.user.id) && !x.completed);
      }
    );
  }

  openDetails(calendarEntry: ICalendarEntry) {
    this.store.dispatch(new CalendarEntryActions.SetActiveEntry(calendarEntry));
    const modalRef = this.modalService.open(CalendarPopupComponent);
  }
}
