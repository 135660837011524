import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToolsModule} from '../tools/tools.module';
import {FilesModule} from '../files/files.module';
import {TeamListComponent} from './team/team-list/team-list.component';
import {NgbAccordionModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NotesModule} from '../notes/notes.module';
import {CaseHistoryComponent} from './case-history/case-history.component';
import {AppNotificationModule} from '../app-notifications/app-notifications.module';
import {DebugModule} from '../debug/debug.module';
import {CaseListShowComponent} from './case-list-show/case-list-show.component';
import {AddressbookModule} from '../addressbook/addressbook.module';
import {AddressbookCreatePopupComponent} from '../addressbook/addressbook-create-popup/addressbook-create-popup.component';
import {StammbaumModule} from '../stammbaum/stammbaum.module';
import {CaseBoerseComponent} from './case-boerse/case-boerse.component';
import {CasePoolComponent} from './case-pool/case-pool.component';
import {CaseDetailMaxComponent} from './case-detail-max/case-detail-max.component';
import {CaseDetailMaxMenuComponent} from './case-detail-max-menu/case-detail-max-menu.component';
import {CaseDetailMaxBasicsComponent} from './case-detail-max-basics/case-detail-max-basics.component';
import {HotkeyModule} from 'angular2-hotkeys';
import {CaseDetailMaxHelpComponent} from './case-detail-max-help/case-detail-max-help.component';
import {CaseDetailStammbaumComponent} from './case-detail-stammbaum/case-detail-stammbaum.component';
import {CaseDetailMaxNotesComponent} from './case-detail-max-notes/case-detail-max-notes.component';
import {CaseDetailMaxHeirComponent} from './case-detail-max-heir/case-detail-max-heir.component';
import {CaseDetailMaxFilesComponent} from './case-detail-max-files/case-detail-max-files.component';
import {CaseDetailPersonsComponent} from './case-detail-persons/case-detail-persons.component';
import {TeamCardsComponent} from './team/team-cards/team-cards.component';
import {CaseListSearchResultsComponent} from './case-list-search-results/case-list-search-results.component';
import {CaseSearchComponent} from './case-search/case-search.component';
import {PersonListSearchResultsComponent} from './person-list-search-results/person-list-search-results.component';
import {PersonDetailComponent} from './person-detail/person-detail.component';
import {HeirEmbeddedMaxComponent} from './heir-embedded-max/heir-embedded-max.component';
import {PersonPopupComponent} from './person-popup/person-popup.component';
import {HeirsForCaselistComponent} from './heirs-for-caselist/heirs-for-caselist.component';
import {HeirHistoryComponent} from './heir-history/heir-history.component';
import {HeirEmbeddedMaxWithoutVersionComponent} from './heir-embedded-max-without-version/heir-embedded-max-without-version.component';
import {CaseDetailMaxNtWithoutVersionComponent} from './case-detail-max-nt-without-version/case-detail-max-nt-without-version.component';
import {CaseDetailEBSDokuComponent} from './case-detail-ebs-doku-help/case-detail-eba-doku.component';
import {TeamIconsComponent} from './team/team-icons/team-icons.component';
import {CaseChangeStatusComponent} from './case-change-status/case-change-status.component';
import { ShowEbsComponent } from './case-detail-ebs-doku-help/show-ebs/show-ebs.component';
import {KassenbuchModule} from '../kassenbuch/kassenbuch.module';
import { CaseAuskehrComponent } from './case-auskehr/case-auskehr.component';
import {AuskehrModule} from '../auskehr/auskehr.module';

@NgModule({
  declarations: [
    CaseDetailMaxComponent,
    CaseDetailMaxBasicsComponent,
    CaseDetailMaxFilesComponent,
    CaseDetailEBSDokuComponent,
    CaseDetailMaxHeirComponent,
    CaseDetailMaxHelpComponent,
    CaseDetailMaxMenuComponent,
    CaseDetailStammbaumComponent,
    CaseDetailMaxNotesComponent,
    CaseChangeStatusComponent,
    CaseDetailMaxNtWithoutVersionComponent,
    CaseDetailPersonsComponent,
    TeamCardsComponent,
    CaseListSearchResultsComponent,
    CaseSearchComponent,
    CaseListShowComponent,
    CaseHistoryComponent,
    TeamListComponent,
    TeamIconsComponent,
    CaseBoerseComponent,
    CasePoolComponent,
    CasePoolComponent,
    PersonPopupComponent,
    PersonListSearchResultsComponent,
    PersonDetailComponent,
    HeirEmbeddedMaxComponent,
    HeirEmbeddedMaxWithoutVersionComponent,
    HeirsForCaselistComponent,
    HeirHistoryComponent,
    ShowEbsComponent,
    CaseAuskehrComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ToolsModule,
        FilesModule,
        NotesModule,
        NgbAccordionModule,
        NgbAlertModule,
        AppNotificationModule,
        DebugModule,
        AddressbookModule,
        StammbaumModule,
        HotkeyModule,
        KassenbuchModule,
        AuskehrModule
    ],
  entryComponents: [
    AddressbookCreatePopupComponent,
    CaseDetailMaxHelpComponent,
    AddressbookCreatePopupComponent,
    PersonPopupComponent,
    CaseChangeStatusComponent,
    ShowEbsComponent
  ],
  exports: [
    CaseDetailMaxComponent,
    CasePoolComponent,
    CaseBoerseComponent,
    CaseSearchComponent,
    CaseHistoryComponent,
    CaseListShowComponent,
    HeirHistoryComponent,
    PersonDetailComponent,
    CaseChangeStatusComponent
  ]
})

export class CasesModule {}
