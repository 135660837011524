import {
  Component,
  ElementRef,
  ViewChild,
  HostListener,
  OnInit,
  AfterViewInit, OnDestroy
} from '@angular/core';
import {AutoparserService} from '../../services/autoparser.service';
import {IAutoparserDirectory} from '../autoparser-directory.interface';
import {FormControl, FormGroup, Validator, Validators} from '@angular/forms';
import {IAutoparserRow} from '../autoparser-row.interface';
import {Subscription, timer} from 'rxjs';
import {ConstantsService} from '../../services/constants.service';
import {Store} from '@ngrx/store';
import {IAutoparserColumnSettings, IAutoparserPostprocessedEntry, IAutoparserState} from '../../store/autoparser-state.interface';
import * as AutoparserActions from '../../store/autoparser.actions';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NvzPageSettingsComponent} from '../nvz-page-settings/nvz-page-settings.component';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';

@Component({
  selector: 'nvz-ap-edit-results',
  templateUrl: './nvz-ap-edit-results.component.html',
  styleUrls: ['./nvz-ap-edit-results.component.css'],
})
export class NvzApEditResultsComponent implements OnInit, AfterViewInit, OnDestroy {
  timerSubscription: Subscription;
  nachnameElement: HTMLInputElement;
  @ViewChild('vorname', { static: false }) vornameElement: ElementRef;
  @ViewChild('geburtsname', { static: false }) geburtsnameElement: ElementRef;
  @ViewChild('urkunden_nr', { static: false }) urkunden_nrElement: ElementRef;
  dir: IAutoparserDirectory;
  current_row: IAutoparserRow = null;
  editForm: FormGroup;
  filename_row_image: string[] = [];
  filename_row_highlight_image: string[] = [];
  column_settings: IAutoparserColumnSettings;
  last_nvz_entries: IAutoparserPostprocessedEntry[] = [];
  autoparserStateSub: Subscription;
  autoparserState: IAutoparserState;
  allExistingEntries: IAutoparserPostprocessedEntry[] = [];
  entriesWithSameNumber: IAutoparserPostprocessedEntry[] = [];

  // below deprecated todo remove
  filename: string[] = [];
  total_lines: number = 0;
  server_error = null;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {

    if (event.shiftKey && event.key === 'Enter') {
      if (!this.entriesWithSameNumber || this.entriesWithSameNumber.length === 0) {
        // only run the check if it has not been run before
        this.checkExisting();
        if (this.entriesWithSameNumber && this.entriesWithSameNumber.length > 0) {
          return;
        }
      }
      this.next(true, true);
    }
    if (event.altKey && event.key === 'w') {
      if (!this.entriesWithSameNumber || this.entriesWithSameNumber.length === 0) {
        // only run the check if it has not been run before
        this.checkExisting();
        if (this.entriesWithSameNumber && this.entriesWithSameNumber.length > 0) {
          return;
        }
      }
      this.next(true, false);
    }
    if (event.ctrlKey && event.key === 'i') {
      this.next(false, true);
    }
    if (event.ctrlKey && event.key === 'c') {
      this.empty();
    }
    if (event.altKey && event.key === 'j') {
      this.toggleJuedisch();
    }
  }


  // TODO HOW TO GET THE PICS NOW:
  // https://db.ilanot.de/backend/autoparser/view-image/?filename=row-2045-column-4.jpg&document=P_Rep_570_2148&folder=partial-pics

  constructor(private autoparserService: AutoparserService,
              private ngbModal: NgbModal,
              private constantsService: ConstantsService,
              private store: Store<{autoparser: IAutoparserState}>) {
    this.editForm = new FormGroup({
      'vorname': new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      'nachname': new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      'geburtsname': new FormControl('', [Validators.maxLength(100)]),
      'urkunden_nr': new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      'juedisch': new FormControl(false),
    });
    this.autoparserStateSub = this.store.select('autoparser').subscribe(
      (autoparserState) => {
        this.autoparserState = autoparserState;
        this.allExistingEntries = autoparserState.postprocessedEntries;
        this.column_settings = autoparserState.autoparserColumnSettings;
        if (autoparserState.activeDir && (!this.dir || autoparserState.activeDir.id !== this.dir.id || autoparserState.activeDir.current_row !== this.dir.current_row)) {
          this.dir = autoparserState.activeDir;
          if (this.dir) {
            if (this.dir.id) {
              this.getRow(this.dir.current_row);
            } else {
              this.getRow(0)
            }
          }
        }
      });
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  next(save= false, next_row= false) {
    if (save) { // if an entry is to be saved, do not proceed if form is invalid
      if (this.editForm.invalid) {
        this.editForm.markAsTouched();
        return;
      }
    }
    if (save) {
      this.saveEntry(next_row);
      return;
    }
    if (next_row) {
      this.nextRow();
    }
  }

  nextRow() {
    this.empty();
    let row_count = this.current_row.row_count;
    row_count++;
    this.current_row = null;
    this.getRow(row_count);
  }

  saveEntry(next_row= false) {
    let nachname: string = this.editForm.controls.nachname.value;
    if (nachname) {
      nachname = nachname.toUpperCase();
    }
    let vorname: string = this.editForm.controls.vorname.value;
    if (vorname) {
      vorname = vorname
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0)
          .toUpperCase() + s.substring(1))
        .join(' ');
    }
    const entry: IAutoparserPostprocessedEntry = {
      id: null,
      directory: this.dir.id,
      vorname: vorname,
      nachname: nachname,
      geburtsname: this.editForm.controls.geburtsname.value,
      urkunden_nr: this.editForm.controls.urkunden_nr.value,
      juedisch: this.editForm.controls.juedisch.value,
      row_count: this.current_row.row_count
    };
    this.autoparserService.saveDataForImport(entry).subscribe(
      () => {
        this.autoparserService.reloadCurrentDirectory(this.dir.id);
        if (next_row) {
          this.nextRow();
        } else {
          this.empty();
          this.nachnameElement = document.getElementById('nachname') as HTMLInputElement;
          this.nachnameElement.focus();
          this.nachnameElement.select(); // does not seem to work TODO
        }
        if (this.last_nvz_entries.length > 3) {
          this.last_nvz_entries.shift();
        }
        this.last_nvz_entries.push(entry);
      },
      (error) => {
        // todo implement error handling (show message)
        console.log(error);
      },
      () => {}
    );
  }

  getRow(row_count: number) {
    this.resetExisting();
    this.filename_row_highlight_image = ['https://db.ilanot.de/backend/autoparser/view-image/?filename=highlight-row-' + row_count + '.jpg&document=' + this.dir.name + '&folder=partial-pics'];
    this.filename_row_image = ['https://db.ilanot.de/backend/autoparser/view-image/?filename=row-' + row_count + '.jpg&document=' + this.dir.name + '&folder=partial-pics'];
    this.autoparserService.getRow(this.dir.id, row_count).subscribe(
      (row) => {
        this.current_row = row;
        this.autoparserService.reloadCurrentDirectory(this.dir.id);
        this.prepareForm();
      }
    );
    this.autoparserService.getDataExportList(this.dir.id);
  }

  endThisPage() {
  const modalRef = this.ngbModal.open(ConfirmModalComponent);
    modalRef.componentInstance.message = 'Wollen Sie die Seite wirklich beenden?';
    modalRef.componentInstance.title = 'Bestätigung';
    modalRef.result.then(
      (result) => {
        if (result) {
          this.nextPageAndGetRow();
        }
      }
    );
  }

  nextPageAndGetRow() {
    this.autoparserService.endPage(this.dir.id, this.current_row.row_count).subscribe(
      (row) => {
        this.getRow(row.row_count);
      }
    );
  }

  close() {
    this.store.dispatch(new AutoparserActions.SetAutoparserActivePage('entry'));
    this.store.dispatch(new AutoparserActions.SetAutoparserActiveDir(null));
  }

  prepareForm() {
    this.timerSubscription = timer(0, 500).subscribe(t => {
      if (this.current_row.columns.length - 1 < this.column_settings.columnUrkundennummer) {
        this.column_settings.columnUrkundennummer = -1;
      }
      if (this.current_row.columns.length - 1 < this.column_settings.columnGeburtsname) {
        this.column_settings.columnGeburtsname = -1;
      }
      if (this.current_row.columns.length - 1 < this.column_settings.columnVorname) {
        this.column_settings.columnVorname = -1;
      }
      if (this.current_row.columns.length - 1 < this.column_settings.columnNachname) {
        this.column_settings.columnNachname = -1;
      }
      if (this.current_row.columns.length - 1 < this.column_settings.columnJuedisch) {
        this.column_settings.columnJuedisch = -1;
      }
      this.store.dispatch(new AutoparserActions.SetAutoparserColumnSettings(this.column_settings));
      let vorname_value = null;
      let nachname_value = null;
      let geburtsname_value = null;
      let urkunden_nr_value = null;
      if (this.column_settings.columnVorname > -1) {
        vorname_value = this.current_row.columns[this.column_settings.columnVorname].ocr_text_detected;
        if (vorname_value) {
          vorname_value = vorname_value.replace(/[^a-züöäß ]/gi, '').trim();
        }
      }
      if (this.column_settings.columnNachname > -1) {
        nachname_value = this.current_row.columns[this.column_settings.columnNachname].ocr_text_detected;
        if (nachname_value) {
          let name: string = nachname_value.replace(/[^a-züöäß. ]/gi, '');
          let name_2 = '';
          if (name.length > 3) {
            const index_g = name.indexOf('geb.');
            if (index_g > -1) {
              const names = name.split('geb.', 2);
              name = names[0];
              name_2 = names[1];
            }
            const index_v = name.indexOf('verw.');
            if (index_v > -1) {
              const names = name.split('verw.', 2);
              name = names[1];
              name_2 = names[0];
            }
            const index_e = name.indexOf('verehl.');
            if (index_e > -1) {
              const names = name.split('verehl.', 2);
              name = names[1];
              name_2 = names[0];
            }
            const index_h = name.indexOf('verh.');
            if (index_h > -1) {
              const names = name.split('verh.', 2);
              name = names[1];
              name_2 = names[0];
            }
          }
          nachname_value = name.toUpperCase().trim();
          if (this.column_settings.columnGeburtsname === -2) {
            geburtsname_value = name_2;
          }
        }
      }
      if (this.column_settings.columnGeburtsname > -1) {
        geburtsname_value = this.current_row.columns[this.column_settings.columnGeburtsname].ocr_text_detected;
        if (geburtsname_value) {
          geburtsname_value = geburtsname_value.replace(/[^a-züöäß ]/gi, '').trim();
        }
      }
      if (this.column_settings.columnUrkundennummer > -1) {
        urkunden_nr_value = this.current_row.columns[this.column_settings.columnUrkundennummer].ocr_text_detected;
        if (urkunden_nr_value) {
          urkunden_nr_value = urkunden_nr_value.replace(/[^0-9]/gi, '').trim();
        }
      }
      this.editForm.patchValue({
        'vorname': vorname_value,
        'nachname': nachname_value,
        'geburtsname': geburtsname_value,
        'urkunden_nr': urkunden_nr_value,
        'juedisch': false
      });
      this.nachnameElement = document.getElementById('nachname') as HTMLInputElement;
      this.nachnameElement.focus();
      this.nachnameElement.select();
      this.timerSubscription.unsubscribe();
    });
  }

  toggleJuedisch() {
    const jued = !this.editForm.controls.juedisch.value;
    this.editForm.patchValue({'juedisch': jued});
  }

  openPageSettings() {
    const ref: NgbModalRef = this.ngbModal.open(NvzPageSettingsComponent, {size: 'lg', backdrop: 'static'});
    ref.componentInstance.current_row = this.current_row;
  }

  openList() {
    this.store.dispatch(new AutoparserActions.SetAutoparserActivePage('list'));
  }

  empty() {
   this.editForm.patchValue({
     'vorname': null,
     'nachname': null,
     'geburtsname': null,
     'urkunden_nr': null,
     'juedisch': false
   });
  }

  checkExisting() {
    this.entriesWithSameNumber = [];
    if (this.editForm.controls.urkunden_nr.value) {
      this.entriesWithSameNumber = this.allExistingEntries.filter(x => +x.urkunden_nr === +this.editForm.controls.urkunden_nr.value);
    }
  }

  resetExisting() {
    this.entriesWithSameNumber = [];
  }

  export() {
    window.open(this.constantsService.getApiEndpoint() + '/autoparser/output-csv/?directory_id=' + this.dir.id, 'Exportliste ' + this.dir.name);
  }

  ngOnDestroy(): void {
    this.autoparserStateSub.unsubscribe();
  }

}
