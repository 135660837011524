import {Component, OnInit, Input, AfterViewInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import { ITeamMember } from '../../team-member.interface';
import { TeamMemberService } from '../../../services/team-member.service';
import {isNullOrUndefined} from 'util';
import {ITeamMemberState} from '../../../store/team-member-state.interface';
import {IUser} from '../../../models/user.interface';
import {IUserState} from '../../../store/user-state.interface';
import {RightsManagementService} from '../../../services/rights-management.service';
import {ICaseState} from '../../../store/case-state.interface';
import {
  NOTIFICATION_MESSAGE_CASELIST_LOADER_LOADING, NOTIFICATION_MESSAGE_TEAMMEMBER_SAVE_ERROR,
  NOTIFICATION_MESSAGE_TEAMMEMBER_SAVE_TIMEOUT,
  NOTIFICATION_MESSAGE_TEAMMEMBER_SAVING,
  NOTIFICATION_SLOT_CASELIST_LOADER, NOTIFICATION_SLOT_TEAMMEMBER
} from '../../../app-notifications/app-notification.interface';
import * as AppNotificationActions from '../../../store/app-notification.actions';
import {UserService} from '../../../services/user.service';
import {ConstantsService} from '../../../services/constants.service';


@Component({
  selector: 'team-cards',
  templateUrl: './team-cards.component.html',
  styleUrls: ['./team-cards.component.css']
})
export class TeamCardsComponent implements OnInit, AfterViewInit {
  users: IUser[] = [];
  activeUsers: IUser[] = [];
  usersStateSub: Subscription;
  casesSub: Subscription;
  case_id: number;
  editMode = false;
  teamMembers: ITeamMember[] = [];
  teamMembersSub: Subscription;
  copiedTeamMembers: ITeamMember[] = [];
  createTeamMemberInProgress = false;
  saveTeamMemberInProgress = false;
  deleteTeamMemberInProgress = false;

  editTeamMember: ITeamMember = <ITeamMember>{};
  formOpen: string[] = [];
  openAccordionPanels: string[] = [];
  newTeamMember: ITeamMember = <ITeamMember>{};
  search_team = '';

  constructor(private teamMemberService: TeamMemberService,
              public userService: UserService,
              public rightsManagementService: RightsManagementService,
              private constantsService: ConstantsService,
              private store: Store<{teamMembers: ITeamMemberState, users: IUserState, cases: ICaseState}>) { }

  ngOnInit() {
    this.teamMembersSub = this.store.select('teamMembers').subscribe(
      (teamMembers) => {
        this.teamMembers = teamMembers.teamMembersForCurrentCase.filter(x => !x.deleted);
        this.copyAndRunFilters();
      });
    this.usersStateSub = this.store.select('users').subscribe(
      (usersState) => {
        this.users = [...usersState.users];
        this.activeUsers = this.users.filter(x => x.is_active);
        this.copyAndRunFilters();
      });

    this.casesSub = this.store.select('cases').subscribe(
        (cases) => { this.case_id = cases.activeCaseId;}
      );
    this.newTeamMember = <ITeamMember>{};
  }

  ngAfterViewInit() {
    window.scrollTo({top: 0, left: 0});
  }

  copyAndRunFilters() {
    this.copiedTeamMembers = [...this.teamMembers];
  }

  updateUsers(users: IUser[]) {
    this.users = users;
  }

  teamExists() {
    if (isNullOrUndefined(this.copiedTeamMembers)) {
      return false;
    } else {
      return true;
    }
  }

  editTeamMemberExists() {
    if (isNullOrUndefined(this.editTeamMember)) {
      return false;
    } else {
      return true;
    }
  }

  createTeamMember() {
    if (this.createTeamMemberInProgress) {
      return; // if there is a request still being procressed, do nothing!
    }
    if (isNullOrUndefined(this.newTeamMember.user_id)) {
      return; // nobody selected
    }
    for (const element of this.copiedTeamMembers) {
      if (element.user_id == this.newTeamMember.user_id) {
        return; // person is already in team
      }
    }
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_TEAMMEMBER_SAVING));
    this.newTeamMember.case_id = this.case_id;
    this.createTeamMemberInProgress = true;
    this.teamMemberService.create(this.newTeamMember).subscribe(
      (result) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
        this.teamMemberService.triggerTeamMemberForCaseReloadFromServer();
        this.formOpen = [];
        this.newTeamMember = <ITeamMember>{};
        this.createTeamMemberInProgress = false;
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_TEAMMEMBER_SAVE_ERROR));
        this.createTeamMemberInProgress = false;
      },
      () => {
        if (this.createTeamMemberInProgress) {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_TEAMMEMBER_SAVE_TIMEOUT));
          this.createTeamMemberInProgress = false;
        }
      });
  }


  edit(id: number) {
    this.editMode = true;
    this.editTeamMember = this.copiedTeamMembers.find(x => x.id === id);
  }

  cancelEdit() {
    this.editMode = false;
    this.editTeamMember = null;
  }

  saveTeamMember() {
    if (this.saveTeamMemberInProgress) { // if saving is still in progress, ignore!
      return;
    }
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_TEAMMEMBER_SAVING));
    this.editMode = false;
    this.saveTeamMemberInProgress = true;
    this.teamMemberService.save(this.editTeamMember).subscribe(
    (result) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
        this.teamMemberService.triggerTeamMemberForCaseReloadFromServer();
        this.newTeamMember = <ITeamMember>{};
        this.saveTeamMemberInProgress = false;
        this.formOpen = [];
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_TEAMMEMBER_SAVE_ERROR));
        this.saveTeamMemberInProgress = false;
      },
      () => {
        if (this.saveTeamMemberInProgress) {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_TEAMMEMBER_SAVE_TIMEOUT));
          this.saveTeamMemberInProgress = false;
        }
      });
    this.editTeamMember = null;
  }

  delete(member_id: number) {
    if (this.deleteTeamMemberInProgress) { // if deleting is still in progress, ignore!
      return;
    }
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_TEAMMEMBER_SAVING));
    this.editMode = false;
    this.deleteTeamMemberInProgress = true;
   this.teamMemberService.delete(member_id).subscribe(
    (result) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
        this.teamMemberService.triggerTeamMemberForCaseReloadFromServer();
        this.newTeamMember = <ITeamMember>{};
        this.deleteTeamMemberInProgress = false;
        this.formOpen = [];
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_TEAMMEMBER_SAVE_ERROR));
        this.deleteTeamMemberInProgress = false;
      },
      () => {
        if (this.deleteTeamMemberInProgress) {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_TEAMMEMBER));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_TEAMMEMBER_SAVE_TIMEOUT));
          this.deleteTeamMemberInProgress = false;
        }
      });
  }

  ngOnDestroy() {
    this.usersStateSub.unsubscribe();
    this.teamMembersSub.unsubscribe();
    this.casesSub.unsubscribe();
  }
}












