import {Component} from '@angular/core';
import {ConstantsService} from '../../services/constants.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'reject-version',
  templateUrl: './reject-version.component.html',
  styleUrls: ['./reject-version.component.css']
})
export class RejectVersionComponent {
  version = '';
  recommend_only = true;

  constructor(private constantsService: ConstantsService,
              private ngbActiveModal: NgbActiveModal) {
    this.version = this.constantsService.getApiVersion();
  }

  close() {
    this.ngbActiveModal.close();
  }
}
