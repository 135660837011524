import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CaseService} from '../../../services/case.service';
import {ConstantsService} from '../../../services/constants.service';
import {HttpClient} from '@angular/common/http';
import {IUrkundenFileRef, IUrkundenFileRefForReuse} from '../../urkunden-file-ref.interface';
import {StammbaumService} from '../../../services/stammbaum.service';
import {ConfirmModalComponent} from '../../../tools/confirm-modal/confirm-modal.component';
import {IStammbaumPerson} from '../../stammbaum-person.interface';
import {IStammbaumMarriage} from '../../stammbaum-marriage.interface';
import {KeyValue} from '@angular/common';

@Component({
  selector: 'app-mutiple-document-usage',
  templateUrl: './mutiple-document-usage.component.html',
  styleUrls: ['./mutiple-document-usage.component.css']
})
export class MutipleDocumentUsageComponent implements OnInit {
  loading = true;
  activeCaseID: number;
  countsAs = 0;
  personToUploadFor: IStammbaumPerson;
  urkunden: IUrkundenFileRefForReuse[];
  persons = {};
  mode = 'list' // list for choosing urk, single for uploading duplicate. start wirth list-mode
  choosenUrkunde: IUrkundenFileRefForReuse;
  showMarriageSelect = false;
  marriageID: number = null;
  order = (a: KeyValue<number, { person: IStammbaumPerson, urkunden: IUrkundenFileRefForReuse[] }>, b: KeyValue<number, { person: IStammbaumPerson, urkunden: IUrkundenFileRefForReuse[] }>): number => {
    return a.value.person.nachname < b.value.person.nachname ? -1 : (b.value.person.nachname < a.value.person.nachname ? 1 : 0);
  }

  constructor(private ngbAvtiveModal: NgbActiveModal,
              private caseService: CaseService,
              private stammbaumService: StammbaumService,
              private constantsService: ConstantsService,
              private http: HttpClient,
              private ngbModal: NgbModal) { }

  ngOnInit() {
    this.activeCaseID = this.caseService.getActiveCaseId()
    const params = `?case_id=${this.activeCaseID}`;
    this.http.get<IUrkundenFileRefForReuse[]>(`${this.constantsService.getApiEndpoint()}/v6/get-document-list-for-case/${params}`, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (response) => {
        this.urkunden = response;
      },
      (e) => {
        console.log('Error:', e)
      },
      () => {
        this.filterUrkunden();
      });
  }

  filterUrkunden() {
    for (const urk of this.urkunden) {
      if (urk.person.id in this.persons) {
        this.persons[urk.person.id].urkunden.push(urk);
      } else {
        this.persons[urk.person.id] = {
          person: urk.person,
          urkunden: [urk]
        };
      }
    }
    this.loading = false;
  }

  getUrkundenUnterart(urkunde: IUrkundenFileRefForReuse) {
    return this.stammbaumService.getUrkundenUnterartText(urkunde)
  }

  getUrkundenStatus(urkunde: IUrkundenFileRefForReuse) {
    return this.stammbaumService.getUrkundenStatusText(urkunde.urkunden_status)
  }

  selectUrkunde(urkunde: IUrkundenFileRefForReuse) {
    const modalRef = this.ngbModal.open(ConfirmModalComponent, {backdrop: 'static'})
    modalRef.componentInstance.title = 'Diese Urkunde wirklich wiederverwenden?'
    modalRef.componentInstance.message = `${this.getUrkundenUnterart(urkunde)} ${urkunde.urkunden_nummer}/${urkunde.jahr} von ${urkunde.person.vorname} ${urkunde.person.nachname} mehrfach verwenden?`
    modalRef.result.then(
      (result) => {
        if (result) {
          this.choosenUrkunde = urkunde;
          this.mode = 'single'
        }
      }
    )
  }

  backToListView() {
    this.mode = 'list';
    this.choosenUrkunde = null;
  }

  uploadDuplicate() {
    this.loading = true;
    const referenced_doc_id = this.choosenUrkunde.id;
    let marriage_id = null;
    const person_id = this.personToUploadFor.id;
    if (this.countsAs === 3) {
      marriage_id = this.marriageID;
    }
    const is_gu = this.countsAs === 1;
    const is_su = this.countsAs === 2;
    const is_hu = this.countsAs === 3;
    const body = {
      person_id: person_id,
      marriage_id: marriage_id,
      refers_to_id: referenced_doc_id,
      is_gu: is_gu,
      is_su: is_su,
      is_hu: is_hu}
    this.http.post(`${this.constantsService.getApiEndpoint()}/v6/create-reuse-reference/`, body, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (response) => {
        console.log('Res:', response)
      },
      (error) => {
        console.log('E:', error);
        this.mode = 'error';
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.ngbAvtiveModal.close();
    }
    );
  }
  display_marriage(m: IStammbaumMarriage) {
    let martext = '';
    if (m.persons.length < 2) {
      return '';
    }
    let perseins = `${m.persons[0].vorname} ${m.persons[0].nachname}`;
    if (m.persons[0].geburtsname) {
      perseins = `${perseins} (geb. ${m.persons[0].geburtsname})`;
    }
    let perszwei = `${m.persons[1].vorname} ${m.persons[1].nachname}`;
    if (m.persons[1].geburtsname) {
      perszwei = `${perszwei} (geb. ${m.persons[1].geburtsname})`;
    }
    martext = `${perseins} x ${perszwei}`;
    if (m.date && m.date !== 'null') {
      martext = `${martext} am ${m.date}`;
    }
    return martext;
  }

  onTypeChange(newValue) {
    this.showMarriageSelect = newValue === 3;
  }

  cancel() {
    this.ngbAvtiveModal.dismiss();
  }
}
