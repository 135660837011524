import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit, ViewChild,
  AfterViewInit
} from '@angular/core';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {
  FREE_SPACE_BELOW_TREE_PX,
  MARGIN_HEIGHT_PX,
  MARGIN_WIDTH_PX,
  PERSON_MIN_HEIGHT_PX,
  PERSON_WIDTH_PX,
  StammbaumDisplayService
} from '../../services/stammbaum-display.service';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';

import htmlToImage from 'html-to-image';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotifyModalComponent} from '../../tools/notify-modal/notify-modal.component';
import {IStammbaumVersion} from '../stammbaum-version.interface';
import * as StammbaumActions from '../../store/stammbaum.actions';

@Component({
  selector: 'stammbaum-tree-svg',
  templateUrl: './stammbaum-tree-svg.component.html',
  styleUrls: ['./stammbaum-tree-svg.component.css']
})

export class StammbaumTreeSvgComponent implements OnInit, AfterViewChecked, OnDestroy, AfterViewInit {
  connections_m = [];
  connections_c = [];
  height = 0;
  width = 0;
  original_height: number;
  original_width: number;
  heightOfPersonCards = PERSON_MIN_HEIGHT_PX;
  heightOfPersonCardsSub: Subscription;
  zoomFactor: number;
  version: IStammbaumVersion;
  viewBox = '0 0 0 0';
  _stammbaumState: IStammbaumState;
  to_saved_scroll_position: {scroll: boolean, x_pos: number, y_pos: number} = {scroll: false, x_pos: null, y_pos: null};
  @ViewChild('print', {static: false}) printRef: ElementRef;

  @Input() set stammbaumState(stammbaumState: IStammbaumState) {
    if (stammbaumState && stammbaumState.executePrint) {
      this.generatePdf();
      return;
    }
    if (stammbaumState && (stammbaumState.scrollToX || stammbaumState.scrollToY)) {
      this.to_saved_scroll_position = {
        scroll: true,
        x_pos: stammbaumState.scrollToX,
        y_pos: stammbaumState.scrollToY
      }
      // return;
    }
    this._stammbaumState = stammbaumState;
    if (stammbaumState.stammbaum.connections) {
      this.connections_m = stammbaumState.stammbaum.connections.m;
      this.connections_c = stammbaumState.stammbaum.connections.c;
    }
    if (stammbaumState.stammbaum.version) {
      this.setOriginalWidthAndHeight()
      this.version = stammbaumState.stammbaum.version;
    }
    if (stammbaumState && stammbaumState.stammbaumDisplay) {
      if (stammbaumState.stammbaumDisplay.zoomFactor) {
        this.zoomFactor = this._stammbaumState.stammbaumDisplay.zoomFactor;
      }
    }
    this.updateScrollPosition(null);
    this.rescale();
  }

  scrollTo(x, y) {
    window.scrollTo(x, y);
  }

  @HostListener('window:scroll', ['$event'])
    updateScrollPosition(event) {
      let height = window.innerHeight;
      let width = window.innerWidth;
      height = height / (this.zoomFactor / 5);
      width = width / (this.zoomFactor / 5);
      this.stammbaumDisplayService.setScrollPos(window.pageXOffset, window.pageYOffset, height, width);
    }

  constructor(private stammbaumDisplayService: StammbaumDisplayService,
              private ngbModal: NgbModal,
              private store: Store<{stammbaum: IStammbaumState}>) {}

  ngOnInit() {
    this.updateScrollPosition(null);
    this.heightOfPersonCardsSub = this.stammbaumDisplayService.personHeight.subscribe(
      (newHeight) => {
        this.heightOfPersonCards = newHeight;
      }
    );
    console.log(this._stammbaumState);
  }

  ngAfterViewInit() {
    if (this.to_saved_scroll_position.scroll) {
      this.scrollTo(this.to_saved_scroll_position.x_pos, this.to_saved_scroll_position.y_pos);
    }
  }

  ngAfterViewChecked() {
    this.stammbaumDisplayService.checkIfTreeHasToBeRebuilt();
    setTimeout(() => this.setOriginalWidthAndHeight(), 100);
  }

  ngOnDestroy() {
    this.store.dispatch(new StammbaumActions.StammbaumScrollToPos({
      x: window.scrollX,
      y: window.scrollY
    }));
    this.heightOfPersonCardsSub.unsubscribe();
  }

  getViewBox() {
    return this.viewBox;
  }

  calculateViewBox() {
    if (this.original_width && this.original_height) {
      this.viewBox = '0 0 ' + this.original_width + ' ' + this.original_height;
    } else {
      this.viewBox = '0 0 0 0';
    }
  }

  setOriginalWidthAndHeight() {
    this.original_width = (this._stammbaumState.stammbaum.version.width + 1) * (PERSON_WIDTH_PX + MARGIN_WIDTH_PX);
    this.original_height = (this._stammbaumState.stammbaum.version.height + 1) * (this.heightOfPersonCards + MARGIN_HEIGHT_PX) + FREE_SPACE_BELOW_TREE_PX;
    this.calculateViewBox();
  }

  rescale() {
    this.width = this.original_width * (this.zoomFactor / 5);
    this.height = this.original_height * (this.zoomFactor / 5);
  }

  generatePdf() {
  htmlToImage.toJpeg(this.printRef.nativeElement, { quality: 0.99 })
    .then(function (dataUrl) {
      const link = document.createElement('a');
      link.download = 'stammbaum.jpeg';
      link.href = dataUrl;
      link.click();
    });
  const ngbModalRef = this.ngbModal.open(NotifyModalComponent, {backdrop: 'static'});
  ngbModalRef.componentInstance.title = 'Hinweis';
  ngbModalRef.componentInstance.message = 'Die Datei wird erzeugt und steht in Kürze zum Download bereit';
  }
}
