import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {ICaseState} from '../store/case-state.interface';
import {ICase} from '../cases/case.interface';
import {IStammbaumPerson} from '../stammbaum/stammbaum-person.interface';

@Injectable()
export class GeneratedDocumentEmailParserService implements OnDestroy {
  caseSub: Subscription;
  cases: ICase[];
  personSub: Subscription;
  persons: IStammbaumPerson[];

  constructor(private store: Store<{cases: ICaseState}>) {
    this.caseSub = this.store.select('cases').subscribe(
      (casesState) => {
        this.cases = casesState.cases;
      }
    );
    // todo implement again for persons
  }

  ngOnDestroy() {
    this.personSub.unsubscribe();
    this.caseSub.unsubscribe();
  }

  parse(text: string, case_id: number, person_id: number) {

    // replace values for case variables
    const currentCase: ICase = this.cases.find(x => +x.id === +case_id);
    if (currentCase) {
      let fallnummer: string = currentCase.id.toString();
      if (currentCase && currentCase.old_id !== '') {
        fallnummer = currentCase.old_id;
      }
      text = text.replace(new RegExp('<<FALLNUMMER>>', 'g'), fallnummer); // flag 'gi' for 'globally case-insensitive'
      text = text.replace(new RegExp('<<ERBLASSER_VORNAME>>', 'g'), currentCase.first_name);
      text = text.replace(new RegExp('<<ERBLASSER_NACHNAME>>', 'g'), currentCase.last_name);
    }

    const currentPerson: IStammbaumPerson = this.persons.find(x => +x.id === +person_id);
    if (currentPerson) {
      text = text.replace(new RegExp('<<PERSON_VORNAME>>', 'g'), currentPerson.vorname); // flag 'gi' for 'globally case-insensitive'
      text = text.replace(new RegExp('<<PERSON_NACHNAME>>', 'g'), currentPerson.nachname); //
    }

    return text;
  }
}
