import {Component, OnDestroy, Output, EventEmitter} from '@angular/core';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {IUserState} from "../../store/user-state.interface";
import {IUser} from "../../models/user.interface";
import {IGeneratedDocRecipient} from "../generated-doc-recipient.interface";

@Component({
  selector: 'select-recipient-from-user',
  templateUrl: './select-recipient-from-user.component.html',
  styleUrls: ['./select-recipient-from-user.component.css']
})
export class SelectRecipientFromUserComponent implements OnDestroy {
  @Output() userRecipientSelected: EventEmitter<IGeneratedDocRecipient> = new EventEmitter<IGeneratedDocRecipient>();
  userSub: Subscription;
  users: IUser[] = [];

  constructor(private store: Store<{users: IUserState}>) {
    this.userSub = this.store.select('users').subscribe(
      (userState) => {
        this.users = userState.users.filter(x => x.is_active);
      }
    );
  }

  addAll() {
    for (const user of this.users) {
      if (user.is_active) {
        this.userRecipientSelected.emit(<IGeneratedDocRecipient>{user_id: user.id, name: user.abbreviation.toUpperCase()});
      }
    }
  }

  addGroup(group_id: number) {
    for (const user of this.users) {
      if (user.is_active && +user.userrole === group_id) {
        this.userRecipientSelected.emit(<IGeneratedDocRecipient>{user_id: user.id, name: user.abbreviation.toUpperCase()});
      }
    }
  }

  addUser(user: IUser) {
    this.userRecipientSelected.emit(<IGeneratedDocRecipient>{user_id: user.id, name: user.abbreviation.toUpperCase()});
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
}
