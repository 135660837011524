import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {KassenbuchBelegInterface, KassenbuchEinzelpostenAusBelegInterface} from '../kassenbuch.interface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EditKostenpositionComponent} from '../edit-kostenposition/edit-kostenposition.component';
import {ICase} from '../../cases/case.interface';
import {KassenbuchService} from '../kassenbuch.service';
import {Subscription} from 'rxjs';
import {UserService} from '../../services/user.service';
import {FormControl, FormGroup} from '@angular/forms';
import {SendMoneyPopupComponent} from '../send-money-popup/send-money-popup.component';
import {
  KassenbuchDeleteKostenpositionPopupComponent
} from '../kassenbuch-delete-kostenposition-popup/kassenbuch-delete-kostenposition-popup.component';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';
import {
  KassenbuchEditKostenpositionStatusPopupComponent
} from '../kassenbuch-edit-kostenposition-status-popup/kassenbuch-edit-kostenposition-status-popup.component';
import {BetragUmrechnenPopupComponent} from '../betrag-umrechnen-popup/betrag-umrechnen-popup.component';

@Component({
  selector: 'app-kassenbuch-list-for-case',
  templateUrl: './kassenbuch-list-for-case.component.html',
  styleUrls: ['./kassenbuch-list-for-case.component.css']
})
export class KassenbuchListForCaseComponent implements OnInit, OnDestroy {
  @Input() copiedActiveCase: ICase;
  @Input() activeCase: ICase;
  sortBy = 'Beleg-Datum';
  sortInverse = false;
  showDeleted = false;
  showOnlyNotDone = false;
  kostenposSub: Subscription;
  kostenpositionen: KassenbuchBelegInterface[] = [];
  kostenpositionenFiltered: KassenbuchBelegInterface[] = [];
  kostenpositionenSorted: KassenbuchBelegInterface[] = [];
  kostenpositionExpanded: number = null;
  sum = 0;
  sum_rechnungsstellung = 0;
  sum_invalid = false;
  count = 0;
  countDeleted = 0;
  form: FormGroup;
  constructor(private ngbModal: NgbModal,
              public kassenbuchService: KassenbuchService,
              public userService: UserService) {
    this.form = new FormGroup({
      filterstring: new FormControl('')
    });
    this.form.controls.filterstring.valueChanges.subscribe(
      (val) => { this.doFilterAndSort(); }
    );
    this.kostenposSub = this.kassenbuchService.kostenpositionenListForCaseSub.subscribe(
      (positionen) => {
        this.kostenpositionen = positionen;
        this.doCalculate();
        this.doFilterAndSort();
      }
    );
  }

  ngOnInit() {}

  setSortBy(columnName: string) {
    if (this.sortBy === columnName) {
      this.sortInverse = !this.sortInverse;
    } else {
      this.sortBy = columnName;
      this.sortInverse = false;
    }
    this.doSort();
  }

  getWaehrungString(id: number) {
    return this.kassenbuchService.getWaehrungOptionStringById(id);
  }

  waehrungUmrechnen(ep: KassenbuchEinzelpostenAusBelegInterface) {
    const ngbModalRef = this.ngbModal.open(BetragUmrechnenPopupComponent);
    ngbModalRef.componentInstance.posten = ep;
    ngbModalRef.result.then(
      (modalResult) => {
        this.kassenbuchService.umrechnen(ep.id, modalResult).subscribe(
          (result) => {
            this.kassenbuchService.updateKostenpositionLocally(result);
          },
          (error) => { console.log(error); },
          () => {}
        );
      },
      () => {}
    );
  }

  doCalculate() {
    this.sum = 0;
    this.sum_rechnungsstellung = 0;
    this.count = 0;
    this.countDeleted = 0;
    this.sum_invalid = false;
    for (const b of this.kostenpositionen) {
      if (!b.deleted) {
        this.sum += b.betrag_brutto;
        this.sum_rechnungsstellung += b.betrag_brutto_rechnungsstellung;
        this.count += 1;
        if (b.betrag_invalid) {
          this.sum_invalid = true;
        }
      } else {
        this.countDeleted += 1;
      }
    }
  }

  toggleDeleted() {
    this.showDeleted = !this.showDeleted;
    this.doFilterAndSort();
  }

  toggleShowOnlyNotDone() {
    this.showOnlyNotDone = !this.showOnlyNotDone;
    this.doFilterAndSort();
  }

  doFilterAndSort() {
    let koposFiltered = [];
    if (this.showOnlyNotDone) {
      koposFiltered = this.kostenpositionen.filter(x => !x.done);
    } else {
      koposFiltered = this.kostenpositionen;
    }
    if (this.form.controls.filterstring.value) {
      koposFiltered = this.kostenpositionen.filter(
        x => {
          if (x.belegnummer.toLowerCase().includes(this.form.controls.filterstring.value.toLowerCase())) { return true; }
          if (x.belegdatum.toLowerCase().includes(this.form.controls.filterstring.value.toLowerCase())) { return true; }
          if (x.belegtitel.toLowerCase().includes(this.form.controls.filterstring.value.toLowerCase()))  { return true; }
          return false;
        }
      );
    }
    if (this.showDeleted) {
      this.kostenpositionenFiltered = koposFiltered;
    } else {
      this.kostenpositionenFiltered = koposFiltered.filter(x => !x.deleted);
    }
    this.doSort();
  }
  doSort() {
    this.kostenpositionenSorted = [...this.kostenpositionenFiltered].sort((a, b) => {
      let first = '';
      let second = '';
      if (this.sortBy === 'Datum') { first = a.belegdatum; second = b.belegdatum; }
      if (this.sortBy === 'Kürzel') { first = this.kassenbuchService.getArtOptionTextById(a.art); second = this.kassenbuchService.getArtOptionTextById(b.art); }
      if (this.sortBy === 'Position') { first = a.belegtitel; second = b.belegtitel; }
      if (this.sortBy === 'Betrag') { first = a.betrag_brutto.toString(); second = b.betrag_brutto.toString(); }
      if (this.sortBy === 'Kategorie') { first = a.kostenkategorie; second = b.kostenkategorie; }
      if (this.sortBy === 'MA') { first = this.userService.getUserName(a.von_mitarbeiter); second = this.userService.getUserName(b.von_mitarbeiter); }
      if (this.sortBy === 'Belegnummer') { first = a.belegnummer; second = b.belegnummer; }
      if (this.sortBy === 'Beleg-Datum') { first = a.belegdatum; second = b.belegdatum; }
      if (this.sortBy === 'Zahlung') { first = a.betrag_bezahlt_am; second = b.betrag_bezahlt_am; }
      if (this.sortBy === 'durch') {first = this.userService.getUserName(a.betrag_bezahlt_durch); second = this.userService.getUserName(b.betrag_bezahlt_durch); }
      if (first === null) { first = ''; }
      if (second === null) { second = ''; }
      if (!this.sortInverse && (first > second)) { return 1; }
      if (this.sortInverse && (first > second)) { return -1; }
      if (!this.sortInverse && (first < second)) { return -1; }
      if (this.sortInverse && (first < second)) { return 1; }
      return 0;
    });
  }

  toggleEinzelposten(id: number) {
    if (this.kostenpositionExpanded === id) {
      this.kostenpositionExpanded = null;
    } else {
      this.kostenpositionExpanded = id;
    }
  }

  addKostenposition() {
    const ngbModalRef = this.ngbModal.open(EditKostenpositionComponent, {size: 'lg', backdrop: 'static'});
    ngbModalRef.componentInstance.case_id = this.activeCase.id;
    ngbModalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  getKostenkategorieName(kostenkategorieID) {
    return this.kassenbuchService.getKostenkategorieName(kostenkategorieID);
  }

  getArtOptionText(id: number) { return this.kassenbuchService.getArtOptionTextById(id); }

  showFile(fileId: number) {
    this.kassenbuchService.showFile(fileId);
  }

  doPayment(posten: KassenbuchBelegInterface) {
    const ngbModalRef = this.ngbModal.open(SendMoneyPopupComponent);
    ngbModalRef.componentInstance.beleg = posten;
    ngbModalRef.result.then(
      (modalResult) => {
        this.kassenbuchService.setPaid(posten.id, modalResult.datum, +modalResult.done === 1, +modalResult.method).subscribe(
          (result) => {
            this.kassenbuchService.updateKostenpositionLocally(result);
          },
          (error) => { console.log(error); },
          () => {}
        );
      },
      () => {}
    );
  }

  deleteKostenposition(posten: KassenbuchBelegInterface) {
    const ngbModalRef = this.ngbModal.open(KassenbuchDeleteKostenpositionPopupComponent);
    ngbModalRef.componentInstance.kostenposition = posten;
    ngbModalRef.result.then(
      (modalResult) => {
        this.kassenbuchService.deleteKostenposition(posten.id, modalResult).subscribe(
          (result) => {
            this.kassenbuchService.updateKostenpositionLocally(result);
          },
          (error) => { console.log(error); },
          () => {}
        );
      },
      () => {}
    );
  }

  undoDeleteKostenposition(posten: KassenbuchBelegInterface) {
    const modalRef = this.ngbModal.open(ConfirmModalComponent);
    modalRef.componentInstance.message = `Wollen Sie das Löschen des Belegs ${posten.belegtitel} wirklich rückgängig machen?`;
    modalRef.componentInstance.title = 'Bestätigung';
    modalRef.result.then(
      (modalResult) => {
        this.kassenbuchService.undoDeleteKostenposition(posten.id).subscribe(
      (result) => {
        this.kassenbuchService.updateKostenpositionLocally(result);
        },
      (error) => { console.log(error); },
      () => {}
    );
      },
      () => {}
    );
  }

  editStatus(posten: KassenbuchBelegInterface) {
    const ngbModalRef = this.ngbModal.open(KassenbuchEditKostenpositionStatusPopupComponent);
    ngbModalRef.componentInstance.kostenposition = posten;
    ngbModalRef.result.then(
      (modalResult) => {
        this.kassenbuchService.editKostenpositionStatus(posten.id, modalResult).subscribe(
          (result) => {
            this.kassenbuchService.updateKostenpositionLocally(result);
          },
          (error) => { console.log(error); },
          () => {}
        );
      },
      () => {}
    );
  }

  editKostenposition(k: KassenbuchBelegInterface) {
    const ngbModalRef = this.ngbModal.open(EditKostenpositionComponent, {size: 'lg', backdrop: 'static'});
    ngbModalRef.componentInstance.case_id = this.activeCase.id;
    ngbModalRef.componentInstance.editMode = true;
    ngbModalRef.componentInstance.kostenposition = k;
    ngbModalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  createAbrechnungAggregated() {
    this.kassenbuchService.loadAbrechnung(this.activeCase.id, 'aggregated', 0);
  }

  createAbrechnungFull() {
    this.kassenbuchService.loadAbrechnung(this.activeCase.id, 'full', 0);
  }

  ngOnDestroy() {
    this.kostenposSub.unsubscribe();
  }

}
