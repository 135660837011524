import * as BundesanzeigerActions from './bundesanzeiger.actions';
import {IBundesanzeigerState} from "./bundesanzeiger-state.interface";
import {IBundesanzeiger} from "../models/bundesanzeiger.interface";

const initialState: IBundesanzeigerState = {
  bundesanzeiger_cases: []
};

export function bundesanzeigerReducer(state = initialState, action: BundesanzeigerActions.BundesanzeigerActions) {
  switch(action.type) {

    case BundesanzeigerActions.REPLACE_BUNDESANZEIGER_CASES_IN_LIST:
      return {
        ...state,
        bundesanzeiger_cases: action.payload
      };

    case BundesanzeigerActions.REMOVE_BUNDESANZEIGER_CASE_FROM_LIST:
      let old_bundesanzeiger_cases: IBundesanzeiger[] = [...state.bundesanzeiger_cases];
      old_bundesanzeiger_cases = old_bundesanzeiger_cases.filter(x => x.id != action.payload);
      return {
        ...state,
        bundesanzeiger_cases: old_bundesanzeiger_cases
      };

    default:
      return state;
  }
}
