import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { Store } from '@ngrx/store';
import {Subscription} from 'rxjs';
import {IAddressbookEntryState} from "../../store/addressbook-entry-state.interface";
import {IAddressbookInstitution} from "../addressbook-institution.interface";
import {isNullOrUndefined} from 'util';
import * as UiActions from "../../store/ui.actions";
import * as AddressbookEntryActions from "../../store/addressbook-entry.actions";
import {MODULE_PAGES, MODULES} from "../../routing/routes.model";
import {AddressbookService} from "../../services/addressbook.service";
import {AddressbookDetailPopupComponent} from "../addressbook-detail-popup/addressbook-detail-popup.component";
import {IAddressbookContact} from "../addressbook-contact.interface";
import {AddressbookSubformContactComponent} from "../addressbook-subform-contact/addressbook-subform-contact.component";
import {AddressbookSubformPersonComponent} from "../addressbook-subform-person/addressbook-subform-person.component";
import {IAddressbookPerson} from "../addressbook-person.interface";
import {IAddressbookAddress} from "../addressbook-address.interface";
import {AddressbookSubformAddressComponent} from "../addressbook-subform-address/addressbook-subform-address.component";

@Component({
  selector: 'addressbook-form',
  templateUrl: './addressbook-form.component.html',
  styleUrls: ['./addressbook-form.component.css']
})
export class AddressbookFormComponent implements OnInit {

  addressbookEntry: IAddressbookInstitution = null;
  addressbookEntrySub: Subscription;
  addressbookEntryForm: FormGroup;
  deleteable: boolean = false;
  contactTypes; // todo typing, change, refactor to ngrx/store and loading on change from table/backend!
  institutionTypes; // todo typing, change, refactor to ngrx/store and loading on change from table/backend!
  addressRegions; // todo typing, change, refactor to ngrx/store and loading on change from table/backend!

  constructor(private store: Store<{addressbookEntry: IAddressbookEntryState}>,
              private addressbookService: AddressbookService,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.addressbookService.institutionTypesObs.subscribe(
      (instTypes) => {this.institutionTypes = instTypes;} // todo dirty hack, remove/replace
    );
    this.addressbookService.addressRegionsObs.subscribe(
      (addrRegions) => {this.addressRegions = addrRegions;} // todo dirty hack, remove/replace
    );
    this.addressbookService.contactTypesObs.subscribe(
      (contTypes) => {this.contactTypes = contTypes;} // todo dirty hack, remove/replace
    );
    this.addressbookEntryForm = new FormGroup({
      'id': new FormControl(null),
      'name': new FormControl(null, [Validators.required, Validators.maxLength(40)]),
      'comment': new FormControl(null, [Validators.maxLength(400)]),
      'type': new FormControl(null, [Validators.required]),
    });
    this.addressbookEntrySub = this.store.select('addressbookEntry').subscribe(
      (addressbookEntry) => {
        this.addressbookEntry = addressbookEntry.activeAddressbookEntry;
        this.deleteable = false;
        if (this.addressbookEntry) {
          if (this.addressbookEntry.persons && this.addressbookEntry.persons.length < 1 && this.addressbookEntry.contacts.length < 1 && this.addressbookEntry.addresses.length < 1) {
            this.deleteable = true;
          }
        }
        if (!isNullOrUndefined(this.addressbookEntry)) {
          this.addressbookEntryForm.setValue(
            {
              id: this.addressbookEntry.id,
              name: this.addressbookEntry.name,
              type: this.addressbookEntry.type,
              comment: this.addressbookEntry.comment
            }
          )
        }
      }
    );
  }

  printContactType(type_id) {
    if (this.contactTypes) {
      let type = this.contactTypes.find(x => x.id == type_id);
      if (type) {
        return type.name;
      }
      else {
        return "";
      }
    }
  }

  printInstitutionType(type_id) {
    if (this.institutionTypes) {
      let type = this.institutionTypes.find(x => x.id == type_id);
      if (type) {
        return type.name;
      }
      else {
        return "";
      }
    }
  }

  printAddressRegion(region_id) {
    if (this.addressRegions) {
      let region = this.addressRegions.find(x => x.id == region_id);
      if (region) {
        return region.name;
      }
      else {
        return "";
      }
    }
  }

  cancel() {
    this.store.dispatch(new UiActions.SetActivePage({module:MODULES.ADDRESSBOOK, page: MODULE_PAGES.ADDRESSBOOK.SEARCH}));
  }

  update() {
    this.addressbookEntry = {
      ...this.addressbookEntry,
      id: this.addressbookEntryForm.controls.id.value,
      name: this.addressbookEntryForm.controls.name.value,
      type: this.addressbookEntryForm.controls.type.value,
      comment: this.addressbookEntryForm.controls.comment.value
    };
    this.addressbookService.update(this.addressbookEntry).subscribe(
      (result) => {
        this.addressbookService.loadInstitutionFromServer(this.addressbookEntryForm.controls.id.value);
        this.cancel(); // on success, go back to search page
      },
      (error) => {}, // todo catch errors
      () => {} // todo catch timeout
    );
  }

  delete() {
    this.addressbookService.delete(this.addressbookEntry.id).subscribe(
      (result) => {
        this.cancel(); // on success, go back to search page
        this.store.dispatch(new AddressbookEntryActions.SetActiveAddressbookEntry(null));
      },
      (error) => {}, // todo catch errors
      () => {} // todo catch timeout
    );
  }

  editContact(contact: IAddressbookContact) {
    const modalRef = this.modalService.open(AddressbookSubformContactComponent);
    modalRef.componentInstance.contact = contact;
    modalRef.result.then(
      (result: IAddressbookContact) => {
        this.addressbookService.loadInstitutionFromServer(this.addressbookEntryForm.controls.id.value);
      }, () => {}
    )
  }

  createContact() {
    this.editContact(<IAddressbookContact>{id: 0, institution: this.addressbookEntry.id})
  }

  createContactForPerson(person_id: number) {
    this.editContact(<IAddressbookContact>{id: 0, person: person_id});
  }

  editPerson(person: IAddressbookPerson) {
    const modalRef = this.modalService.open(AddressbookSubformPersonComponent);
    modalRef.componentInstance.person = person;
    modalRef.result.then(
      (result: IAddressbookPerson) => {
        this.addressbookService.loadInstitutionFromServer(this.addressbookEntryForm.controls.id.value);
      }, () => {}
    )
  }

  createPerson() {
    this.editPerson(<IAddressbookPerson>{id: 0, institution: this.addressbookEntry.id});
  }

  editAddress(address: IAddressbookAddress) {
    const modalRef = this.modalService.open(AddressbookSubformAddressComponent);
    modalRef.componentInstance.address = address;
    modalRef.result.then(
      (result: IAddressbookAddress) => {
        this.addressbookService.loadInstitutionFromServer(this.addressbookEntryForm.controls.id.value);
      }, () => {}
    )
  }

  createAddress() {
    this.editAddress(<IAddressbookAddress>{id: 0, institution: this.addressbookEntry.id});
  }

  ngOnDestroy() {
    this.addressbookEntrySub.unsubscribe();
  }
}
