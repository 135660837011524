import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../services/authentication.service';
import {IUser} from '../../models/user.interface';
import * as SLOTS from '../../app-notifications/app-notification.interface';
import {ConstantsService} from '../../services/constants.service';

@Component({
    selector: 'login-form',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  public user: IUser;
  public loginForm: FormGroup;
  NOTIFICATION_SLOT_LOGIN = SLOTS.NOTIFICATION_SLOT_LOGIN;

  constructor (
    public authenticationService: AuthenticationService,
    public constantsService: ConstantsService) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      'user': new FormControl(null, [Validators.required]),
      'password': new FormControl(null, [Validators.required]),
    });
  }

  onFormSubmit() {
    const user: IUser = <IUser>{username: this.loginForm.controls.user.value, password: this.loginForm.controls.password.value};
    this.authenticationService.tryLogin(user);
  }
}







