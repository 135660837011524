import {Injectable, OnDestroy} from '@angular/core';
import {ConstantsService} from './constants.service';
import {Subscription, timer} from 'rxjs';
import {Store} from '@ngrx/store';
import * as NetworkActions from '../store/network.actions';
import {IAuthUser} from '../models/auth-user.interface';
import * as CaseActions from '../store/case.actions';
import {ICaseState} from '../store/case-state.interface';
import * as StammbaumActions from '../store/stammbaum.actions';
import {INetworkState} from '../store/network-state.interface';
import * as NoteActions from '../store/note.actions';
import {DebugLogService} from './debug-log.service';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {RejectVersionComponent} from '../tools/reject-version/reject-version.component';
import {AuthenticationService} from './authentication.service';

declare var require: any;
const { version } = require('../../../package.json');

@Injectable()
export class WebsocketService implements OnDestroy {
  ws: WebSocket = null;
  timerSub: Subscription;
  authUserSub: Subscription;
  authUser: {authUser: IAuthUser};
  caseState: ICaseState;
  caseStateSub: Subscription;
  networkState: INetworkState;
  networkStateSub: Subscription;

  SEND_HEARTBEAT_INTERVAL = 60 * 1000; // Milliseconds

  constructor(private constantsService: ConstantsService,
              private authenticationService: AuthenticationService,
              private ngbModal: NgbModal,
              private debugLogService: DebugLogService,
              private store: Store<{authUser: {authUser: IAuthUser}, cases: ICaseState, network: INetworkState}>) {
    this.caseStateSub = this.store.select('cases').subscribe(
      (caseState) => { this.caseState = caseState; }
    );

    this.networkStateSub = this.store.select('network').subscribe(
      (networkState) => { this.networkState = networkState; }
    );
    this.authUserSub = this.store.select('authUser').subscribe(
      (authUser) => {
        this.authUser = authUser;
        if (this.authUser && this.authUser.authUser && this.authUser.authUser.loggedIn) {
          this.timerSub = timer(0, this.SEND_HEARTBEAT_INTERVAL).subscribe(t => {
          if (this.ws) { // if user logged in and websocket open, send heartbeat
            this.sendHeartbeat();
          } else { // if user logged in and no websocket, open it
            this.connectWebsocket();
          }
        });
      } else { // if user logged out, unsubscribe and disconnect websocket
        this.timerSub = null;
        if (this.ws) {
          this.ws.close();
          this.store.dispatch(new NetworkActions.SetConnectedToServerFalse());
        }
      }
    });
  }

  ngOnDestroy() {
    this.authUserSub.unsubscribe();
    this.timerSub.unsubscribe();
    this.caseStateSub.unsubscribe();
    this.networkStateSub.unsubscribe();
  }

  public connectWebsocket() {
    if (!this.ws) {
      this.create(this.constantsService.getWebsocketServerDataUpdateChannelForUser());
    }
  }

  public send(data) {
    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(data));
    }
  }

  public received(socket_data) {
    const message_body = JSON.parse(socket_data.data);
    console.log('received via data notify channel', message_body);

    if (message_body.message && message_body.message.action === 'reject-version') {
      const ngbModalRef = this.ngbModal.open(RejectVersionComponent, <NgbModalOptions>{backdrop: 'static'});
      ngbModalRef.componentInstance.recommend_only = false;
      this.authenticationService.logout();
    }

    if (message_body.message && message_body.message.action === 'recommend-version') {
      const ngbMoadlRef = this.ngbModal.open(RejectVersionComponent, <NgbModalOptions>{backdrop: 'static'});
      ngbMoadlRef.componentInstance.recommed_only = true;
    }

    // process all timestamp updates
    if (message_body.message && message_body.message.action === 'data-timestamp-update') {
      if (message_body.message.table === 'api_endpoints__case') {
        this.store.dispatch(new NetworkActions.SetLastUpdateApiEndpointsCase(message_body.message.data));
      }
      if (message_body.message.table === 'api_endpoints__person') {
        this.store.dispatch(new NetworkActions.SetLastUpdateApiEndpointsPerson(message_body.message.data));
      }
      if (message_body.message.table === 'api_endpoints__note') {
        this.store.dispatch(new NetworkActions.SetLastUpdateApiEndpointsNote(message_body.message.data));
      }
    }

    // process all data updates
    if (message_body.message && message_body.message.action === 'data-update') {

      // if case data update is received, update case list, update case table last update time, and if this case is the currently active one, then also reload its details
      if (message_body.message.table === 'api_endpoints__case') {
        this.store.dispatch(new NetworkActions.AddMessage({event_timestamp: null, level: 0, text: `Daten-Update erhalten für Fall ${message_body.message.id}`}));
        if (message_body.message.data.status !== 'Ablage' || this.caseState.loadAllCasesExecuted) { // replace/add this case to caselist if it is not status Ablage OR Ablage-Fälle are loaded
          this.store.dispatch(new CaseActions.ReplaceCaseInCaselist(message_body.message.data));
        }
        if (this.caseState && (+this.caseState.activeCaseId === +message_body.message.id)) {
          this.store.dispatch(new CaseActions.TriggerReloadActiveCase());
        }
        // Set update time
        this.store.dispatch(new NetworkActions.SetLastUpdateApiEndpointsCase(message_body.message.data.updated));
      }

      if (message_body.message.table === 'api_endpoints__stammbaum_person') {
        console.log('person id', message_body.message.person_id);
        this.store.dispatch(new NetworkActions.AddMessage({event_timestamp: null, level: 0, text: `Daten-Update erhalten für Person ${message_body.message.person_id}`}));
        if (this.caseState && (+this.caseState.activeCaseId === +message_body.message.case_id)) {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersionsForCase(true));
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(this.caseState.activeCaseId));
          // todo this can be done more sophisticated by only reloading version if active version is in message_body.message.versions (an array that contains all versions that are affected by the change of this person)
        }
      }

      if (message_body.message.table === 'api_endpoints__note') {
        this.store.dispatch(new NetworkActions.AddMessage({event_timestamp: null, level: 0, text: `Daten-Update erhalten für Notiz ${message_body.message.id}`}));
        // add/replace note in recent notes (must always be in there, as it just changed which is the reason for this notify)
        this.store.dispatch(new NoteActions.ReplaceRecentNote(message_body.message.data));

        // Check if note belongs to active case, and add/repace it
        if (this.caseState && this.caseState.activeCaseId && (+this.caseState.activeCaseId === +message_body.message.data.case_id)) {
          this.store.dispatch(new NoteActions.ReplaceNoteForCurrentCase(message_body.message.data));
        }
        // Check if note belongs to active person, and add/replace it
        if (this.caseState && this.caseState.activePersonId && (+this.caseState.activePersonId === +message_body.message.data.person_id)) {
          this.store.dispatch(new NoteActions.ReplaceNoteForCurrentPerson(message_body.message.data));
        }
        // Set Update Time
        this.store.dispatch(new NetworkActions.SetLastUpdateApiEndpointsNote(message_body.message.data.updated));
      }

      // if urkundenfileref is updated, check if it belongs to the active case, and trigger reload if required
      // TODO REALLY NO RELOAD SHOULD BE REQUIRED, BUT THE DATA SHOULD BE SENT VIA PUSH
      if (message_body.message.table === 'api_endpoints_urkundenfilereference_for_case_id') {
        if (this.caseState && (+this.caseState.activeCaseId === +message_body.message.id)) {
          console.log('trigger reload for stammbaum persons of case ', message_body.message.id);
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersionsForCase(true));
        }
      }
    }

    /* if (message_body.message.action === 'data-update' && message_body.message.table === 'api_endpoints_urkundenfilereference_for_case_id') {
      if (+message_body.message.id === +this.caseService.getActiveCaseId()) {
        console.log('trigger reload for stammbaum persons of case ', message_body.message.id);
        this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumForCase());
      }
    }*/
  }

  sendHeartbeat() {
    this.send({
        'message': {
          'heartbeat': 'ping'
        }
      });
  }

  private create(url) {
    this.ws = new WebSocket(url);
    this.ws.onmessage = (message) => {
      this.received(message);
    };
    this.ws.onerror = (message) => {
      console.log('websocket error', message); // todo wrap in some conditional wrapper that can be en-/disabled via environment
    };
    this.ws.onopen = () => {
      this.send({
        'message': {
          'token': localStorage.getItem('token'),
          'version': version

        }
      });
      this.store.dispatch(new NetworkActions.AddMessage({event_timestamp: null, level: 0, text: 'Die Websocket-Verbindung wurde aufgebaut'}));
      this.store.dispatch(new NetworkActions.SetConnectedToServerTrue());
    };
    this.ws.onclose = (message) => {
      console.log(message);
      this.ws = null;
      if (this.networkState.isConnectedToServer) {
        this.store.dispatch(new NetworkActions.AddMessage({event_timestamp: null, level: 1, text: 'Die Websocket-Verbindung zum Server wurde beendet'}));
      }
      this.store.dispatch(new NetworkActions.SetConnectedToServerFalse());
      if (this.authUser && this.authUser.authUser && this.authUser.authUser.loggedIn) {
        this.connectWebsocket();
      }
    };
  }
}
