import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {UserEntryComponent} from './user-entry/user-entry.component';
import {UserDetailsComponent} from './user-details/user-details.component';
import {UserProfileShowComponent} from './user-profile-show/user-profile-show.component';
import {UserListComponent} from './user-list/user-list.component';
import {ToolsModule} from '../tools/tools.module';
import {UserManagementComponent} from './user-management/user-management.component';
import {UserManagementEditComponent} from './user-management-edit/user-management-edit.component';
import {AppNotificationModule} from '../app-notifications/app-notifications.module';
import {NotifyModalComponent} from '../tools/notify-modal/notify-modal.component';
import {UserChangeSeniorComponent} from './user-change-senior/user-change-senior.component';
import {UserTreeComponent} from './user-tree/user-tree.component';
import {UserListMailPopupComponent} from './user-list/user-list-mail-popup/user-list-mail-popup.component';
import {QuillModule} from 'ngx-quill';


@NgModule({
  entryComponents: [
    NotifyModalComponent,
    UserChangeSeniorComponent,
    UserListMailPopupComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbAlertModule,
        ToolsModule,
        AppNotificationModule,
        QuillModule
    ],
  declarations: [
    UserEntryComponent,
    UserDetailsComponent,
    UserListComponent,
    UserProfileShowComponent,
    UserManagementComponent,
    UserManagementEditComponent,
    UserChangeSeniorComponent,
    UserTreeComponent,
    UserListMailPopupComponent,
  ],
  exports: [
    UserEntryComponent,
    UserManagementComponent,
    UserDetailsComponent,
    UserManagementEditComponent,
    UserChangeSeniorComponent,
    UserTreeComponent
  ]
})

export class UsersModule {

}
