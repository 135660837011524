import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {StammbaumService} from '../../services/stammbaum.service';

@Component({
  selector: 'create-pdf',
  templateUrl: './create-pdf.component.html',
  styleUrls: ['./create-pdf.component.css']
})
export class CreatePdfComponent {
  constructor(private ngbActiveModal: NgbActiveModal,
              private stammbaumService: StammbaumService) {}

  close() {
    this.ngbActiveModal.close();
  }

  create (mode: number) {
    this.stammbaumService.createPdf(mode);
  }
}
