import {Component, Input, OnDestroy} from '@angular/core';
import {INVZState} from '../../store/nvz-state.interface';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {IModulePages, MODULE_PAGES, MODULES} from '../../routing/routes.model';
import {IUi} from '../../models/ui.interface';
import * as NVZActions from '../../store/nvz.actions';
import * as UiActions from '../../store/ui.actions';
import {NVZService} from '../../services/nvz.service';

@Component({
  selector: 'nvz-edit-navigation',
  templateUrl: './nvz-edit-navigation.component.html',
  styleUrls: ['./nvz-edit-navigation.component.css']
})
export class NvzEditNavigationComponent {
  MODULE_PAGES: IModulePages;
  @Input() nvzState: INVZState;
  @Input() ui: {ui: IUi};

  constructor(private store: Store<{nvz: INVZState}>, private nvzService: NVZService) {
    this.MODULE_PAGES = MODULE_PAGES;
  }

  goToLaenderwahl() {
    this.nvzService.navigateToLaender();
  }

  goToStandesamtwahl() {
    this.nvzService.navigateToStandesaemter(this.nvzState.activeStadt);
  }

  goToStadtwahl() {
    this.nvzService.navigateToStaedte(this.nvzState.activeLand);
  }

  goToVerzeichniswahl() {
    this.nvzService.navigateToVerzeichnisse(this.nvzState.activeStandesamt);
  }

  showVerzeichnisArt(artId: number) {
    return this.nvzService.showVerzeichnisArt(artId);
  }
}
