import {Injectable} from '@angular/core';
import {INote} from '../notes/note.interface';
import {ConstantsService} from './constants.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class GlobalsearchService {

  constructor(private constantsService: ConstantsService, private http: HttpClient) {}

  searchTel(searchstring) {
    return this.http.post<{type: string, id: number, number: string, text: string}[]>(`${this.constantsService.getApiEndpoint()}/v5/search-tel/`, {'searchstring': searchstring}, {headers: this.constantsService.getHttpOptions()});
  }
}
