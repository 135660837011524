import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import {Subscription} from 'rxjs';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {INVZStandesamtTree} from '../../namensverzeichnisse/nvz-standesamt.interface';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SearchStandesamtComponent} from '../search-standesamt/search-standesamt.component';
import {IFileUpload} from '../../files/file-upload.interface';
import {FileUploadService} from '../../services/file-upload.service';
import {CaseService} from '../../services/case.service';
import {IUrkundenFileRef} from '../urkunden-file-ref.interface';
import {StammbaumService} from '../../services/stammbaum.service';
import {IStammbaumMarriage} from '../stammbaum-marriage.interface';

@Component({
  selector: 'stammbaum-urkunden-upload',
  styleUrls: ['./stammbaum-urkunden-upload.component.css'],
  templateUrl: 'stammbaum-urkunden-upload.component.html',
  encapsulation: ViewEncapsulation.None
})
export class StammbaumUrkundenUploadComponent implements OnInit, AfterViewInit, OnDestroy {
  arr = Array;
  public hasBaseDropZoneOver = false;
  public file: NgxFileDropEntry = null;
  form: FormGroup;
  showMarriageSelect = false;
  showUrkundenArtFreitext = false;
  showStandesamtSelect = false;
  showKircheText = false;
  persons: IStammbaumPerson[] = [];
  marriages: IStammbaumMarriage[] = [];
  person_to_upload_for: IStammbaumPerson;
  standesamt_selected: INVZStandesamtTree = null;
  standesamt_needed = false;
  persons_autocomplete: IStammbaumPerson[] = [];
  stammbaumSub: Subscription;
  changedInfo: IUrkundenFileRef;
  @ViewChild('standesamt', {static: false}) standesamtRef: ElementRef;
  @ViewChild('urkunden_art', {static: false}) urkundenArtRef: ElementRef;
  @Input() stammbaumState: IStammbaumState;
  @Input() editMode = false;
  @Input() hasTrueStammbaum = false;

  urkunden_art_sub_options = <{ id: number, description: string }[]>[
    {id: 0, description: '---bitte auswählen---'},
  ];

  constructor(private store: Store<{ stammbaum: IStammbaumState }>,
              private ngbModal: NgbModal,
              private caseService: CaseService,
              private fileUploadService: FileUploadService,
              public stammbaumService: StammbaumService) {
    this.stammbaumSub = this.store.select('stammbaum').subscribe(
      (stammbaumState) => {
        if (stammbaumState.urkundeToEdit) {
          this.editMode = true;
        }
        this.person_to_upload_for = stammbaumState.personForUpload;
        if (this.hasTrueStammbaum && stammbaumState && stammbaumState.stammbaum) {
          this.persons = stammbaumState.stammbaum.persons;
          this.marriages = stammbaumState.stammbaum.marriages;
        }
        if (!this.hasTrueStammbaum && stammbaumState && stammbaumState.stammbaumPersonsWithoutVersion) {
          this.persons = stammbaumState.stammbaumPersonsWithoutVersion;
        }
        if (!this.hasTrueStammbaum && stammbaumState && stammbaumState.stammbaumMarriagesWithoutVersion) {
          this.marriages = stammbaumState.stammbaumMarriagesWithoutVersion;
        }
      }
    );
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      'urkunden_nr': new FormControl(null, [Validators.required, Validators.max(2147483646), Validators.pattern('^[0-9]*$')]),
      'jahr': new FormControl(null, [Validators.required, Validators.pattern('^[0-9]*$')]),
      'urkunden_art': new FormControl(0, [Validators.required, Validators.min(1)]),
      'urkunden_art_subtyp': new FormControl(0, [Validators.required, Validators.min(1)]),
      'urkunden_art_freitext': new FormControl(''),  // only required conditionally
      'urkunden_status': new FormControl(0, [Validators.required, Validators.min(1)]),
      'standesamt': new FormControl(null), // only required conditionally
      'kirche_text': new FormControl(''), // only required conditionally
      'kirche_ort': new FormControl(''), // only required conditionally
      'marriage_select': new FormControl(null), // only required conditionally
      'is_gu': new FormControl(false),
      'is_hu': new FormControl(false),
      'is_su': new FormControl(false)
    });
    this.form.get('urkunden_art').valueChanges.subscribe(val => {
      if (val) {
        if (+val === 0) { // nicht gewählt
          this.urkunden_art_sub_options = [];
          this.form.patchValue({urkunden_art_subtyp: null});
          this.showStandesamtSelect = false;
          this.showKircheText = false;
          this.form.get('kirche_text').clearValidators();
          this.form.get('kirche_text').updateValueAndValidity();
          this.form.get('kirche_ort').clearValidators();
          this.form.get('kirche_ort').updateValueAndValidity();
          this.standesamt_needed = false;
          this.form.get('urkunden_art_freitext').clearValidators();
          this.form.get('urkunden_art_freitext').updateValueAndValidity();
          this.form.get('marriage_select').clearValidators();
          this.form.get('marriage_select').updateValueAndValidity();
        }
        if (+val === 1) { // Personenstand
          this.urkunden_art_sub_options = this.stammbaumService.urkunden_art_ps_options;
          this.showStandesamtSelect = true;
          this.showKircheText = false;
          this.form.patchValue({urkunden_art_subtyp: null});
          this.form.get('kirche_text').clearValidators();
          this.form.get('kirche_text').updateValueAndValidity();
          this.form.get('kirche_ort').clearValidators();
          this.form.get('kirche_ort').updateValueAndValidity();
          this.standesamt_needed = true;
          this.form.get('urkunden_art_freitext').clearValidators();
          this.form.get('urkunden_art_freitext').updateValueAndValidity();
          this.form.get('marriage_select').clearValidators();
          this.form.get('marriage_select').updateValueAndValidity();
        }
        if (+val === 2) { // Kirchenbuch
          this.urkunden_art_sub_options = this.stammbaumService.urkunden_art_kb_options;
          this.showStandesamtSelect = false;
          this.showKircheText = true;
          this.form.patchValue({urkunden_art_subtyp: null});
          this.form.get('kirche_text').setValidators([Validators.required]);
          this.form.get('kirche_text').updateValueAndValidity();
          this.form.get('kirche_ort').setValidators([Validators.required]);
          this.form.get('kirche_ort').updateValueAndValidity();
          this.standesamt_needed = false;
          this.form.get('urkunden_art_freitext').clearValidators();
          this.form.get('urkunden_art_freitext').updateValueAndValidity();
          this.form.get('marriage_select').clearValidators();
          this.form.get('marriage_select').updateValueAndValidity();
        }
        if (+val === 3) { // Urkundenersatz
          this.urkunden_art_sub_options = this.stammbaumService.urkunden_art_ue_options;
          this.showStandesamtSelect = false;
          this.showKircheText = true;
          this.form.patchValue({urkunden_art_subtyp: null});
          this.form.get('kirche_text').setValidators([Validators.required]);
          this.form.get('kirche_text').updateValueAndValidity();
          this.form.get('kirche_ort').setValidators([Validators.required]);
          this.form.get('kirche_ort').updateValueAndValidity();
          this.standesamt_needed = false;
          this.form.get('urkunden_art_freitext').clearValidators();
          this.form.get('urkunden_art_freitext').updateValueAndValidity();
          this.form.get('marriage_select').clearValidators();
          this.form.get('marriage_select').updateValueAndValidity();
        }
      }
    });
    this.form.get('urkunden_art_subtyp').valueChanges.subscribe(val => {
      this.showMarriageSelect = (+val === 2 || +val === 12 || +val === 23 || +val === 25 || +val === 27 || +val === 39);
      if (!this.showMarriageSelect) {
        this.form.patchValue({marriage_select: 0});
      }
      this.showUrkundenArtFreitext = (+val === 38 || +val === 39);
      if (this.showMarriageSelect) {
        this.form.get('marriage_select').setValidators([Validators.required, Validators.min(1)]);
      } else {
        this.form.get('marriage_select').clearValidators();
      }
      this.form.get('marriage_select').updateValueAndValidity();
      if (this.showUrkundenArtFreitext) {
        this.form.get('urkunden_art_freitext').setValidators([Validators.required]);
      } else {
        this.form.get('urkunden_art_freitext').clearValidators();
      }
      this.form.get('urkunden_art_freitext').updateValueAndValidity();
      this.form.patchValue({is_gu: (+val === 1)}); // set checkbox is_gu to true if gu is chosen as subtype
      this.form.patchValue({is_su: (+val === 3)});
      this.form.patchValue({is_hu: (+val === 2)});
    });
    if (this.editMode) {
      const stammbaumState = this.stammbaumState;
      if (stammbaumState.urkundeToEdit) {
        this.form.patchValue({
          'urkunden_nr': stammbaumState.urkundeToEdit.urkunden_nummer,
          'jahr': stammbaumState.urkundeToEdit.jahr,
          'urkunden_art': stammbaumState.urkundeToEdit.urkunden_art,
          'urkunden_art_subtyp': stammbaumState.urkundeToEdit.urkunden_unterart,
          'urkunden_art_freitext': stammbaumState.urkundeToEdit.urkunden_art_freitext,
          'urkunden_status': stammbaumState.urkundeToEdit.urkunden_status,
          'kirche_text': stammbaumState.urkundeToEdit.kirche_oder_behoerde_freitext,
          'kirche_ort': stammbaumState.urkundeToEdit.kirche_oder_behoerde_ort,
          'marriage_select': stammbaumState.urkundeToEdit.marriage,
          'is_gu': stammbaumState.urkundeToEdit.is_gu,
          'is_su': stammbaumState.urkundeToEdit.is_su,
          'is_hu': stammbaumState.urkundeToEdit.is_hu
        });
        this.form.updateValueAndValidity();
        if (stammbaumState.urkundeToEdit.standesamt) {
          this.standesamt_selected = <INVZStandesamtTree><unknown>{
            id: stammbaumState.urkundeToEdit.standesamt.id,
            name: stammbaumState.urkundeToEdit.standesamt.name
          };
        }
      }
    }
  }

  ngAfterViewInit() {
    this.urkundenArtRef.nativeElement.focus();
  }

  caseDisplayInformation() {
   return `${this.caseService.getActiveCaseLastName()} (${this.caseService.getActiveCaseId()})`;
  }

  setValues() {
    let standesamt_id: number = null;
    if (this.standesamt_selected) {
      standesamt_id = this.standesamt_selected.id;
    }
    const stammbaumRelation = <IUrkundenFileRef>{
      person: this.person_to_upload_for.id,
      marriage: this.form.controls.marriage_select.value,
      urkunden_art: this.form.controls.urkunden_art.value,
      urkunden_unterart: this.form.controls.urkunden_art_subtyp.value,
      urkunden_status: this.form.controls.urkunden_status.value,
      kirche_oder_behoerde_freitext: this.form.controls.kirche_text.value,
      kirche_oder_behoerde_ort: this.form.controls.kirche_ort.value,
      urkunden_art_freitext: this.form.controls.urkunden_art_freitext.value,
      standesamt: standesamt_id,
      jahr: this.form.controls.jahr.value,
      urkunden_nummer: this.form.controls.urkunden_nr.value,
      is_gu: this.form.controls.is_gu.value,
      is_su: this.form.controls.is_su.value,
      is_hu: this.form.controls.is_hu.value,
    };
    const description = 'Urkunde';
    const title = 'Urkunde';
    if (!this.editMode) {
      this.fileUploadService.currentFileInfo = <IFileUpload>{
        case_id: this.caseService.getActiveCaseId(),
        person_id: this.person_to_upload_for.id,
        folder_id: 1000,
        description: description,
        title: title,
        stammbaum_relation: stammbaumRelation
      };
    } else {
      this.changedInfo = stammbaumRelation;
    }
  }

  display_marriage(m: IStammbaumMarriage) {
    let martext = '';
    if (m.persons.length < 2) {
      return '';
    }
    let perseins = `${m.persons[0].vorname} ${m.persons[0].nachname}`;
    if (m.persons[0].geburtsname) {
      perseins = `${perseins} (geb. ${m.persons[0].geburtsname})`;
    }
    let perszwei = `${m.persons[1].vorname} ${m.persons[1].nachname}`;
    if (m.persons[1].geburtsname) {
      perszwei = `${perszwei} (geb. ${m.persons[1].geburtsname})`;
    }
    martext = `${perseins} x ${perszwei}`;
    if (m.date && m.date !== 'null') {
      martext = `${martext} am ${m.date}`;
    }
    return martext;
  }

  public dropped(event: NgxFileDropEntry[]) {
    this.file = event[0];
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOver(event) {
  }

  public fileLeave(event) {
  }

  removeFile() {
    const fileInfo = this.fileUploadService.uploaderFileInfo.find(x => {
      if (!x.information || !x.information.stammbaum_relation) {
        return false;
      }
      if (x.information.stammbaum_relation.person === this.person_to_upload_for.id) {
        return true;
      }
      return false;
    });
    if (fileInfo) {
      fileInfo.file.cancel();
    }
    this.file = null;
  }

  upload() {
    this.setValues();
    if (this.file && this.file.fileEntry && this.file.fileEntry.isFile) {
      const fileEntry = this.file.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        // Here you can access the real file
        console.log(this.file.relativePath, file);
        this.fileUploadService.uploader.addToQueue([file]);
        /**
         // You could upload it like this:
         const formData = new FormData()
         formData.append('logo', file, relativePath)
         // Headers
         const headers = new HttpHeaders({
          'security-token': 'mytoken'
        })
         this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
         .subscribe(data => {
          // Sanitized logo returned from backend
        })
         **/
      });
      const fileInfoUL = this.fileUploadService.uploaderFileInfo.find(x => {
        if (!x.information || !x.information.stammbaum_relation) {
          return false;
        }
        if (x.information.stammbaum_relation.person === this.person_to_upload_for.id) {
          return true;
        }
        return false;
      });
      if (fileInfoUL) {
        fileInfoUL.file.upload();
        this.file = null;
        this.close();
      }
    } else {
      // It was a directory (empty directories are added, otherwise only files), so ignore it!!!
    }
  }

  close() {
    if (this.hasTrueStammbaum) {
      if (this.stammbaumState.stammbaumMode === 'urkunden-upload-list' || this.stammbaumState.stammbaumMode === 'urkunden-edit-list') {
        this.store.dispatch(new StammbaumActions.SetStammbaumMode('person-list'))
      }
    } else { // if there is no true stammbaum, just emit close event
     this.store.dispatch(new StammbaumActions.ReplacePersonForUpload(null));
    }
    this.store.dispatch(new StammbaumActions.ReplaceUrkundeToEdit(null));
  }

  saveChanges() {
    this.setValues();
    this.stammbaumService.updateUrkunde(this.stammbaumState.urkundeToEdit.id, this.changedInfo).subscribe(
      (reply) => {
        this.close();
        this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(this.caseService.getActiveCaseId()));
      },
      (error) => {
        console.log(error);
      },
      () => {
      }
    );
  }

  ngOnDestroy(): void {
    this.stammbaumSub.unsubscribe();
  }

  chooseStandesamt() {
    const modalRef: NgbModalRef = this.ngbModal.open(SearchStandesamtComponent, <NgbModalOptions>{size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.allow_creating_new = true;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.standesamt_selected = result;
          this.form.patchValue({standesamt: result.id});
        }
      },
      () => {
      }
    );
  }
}
