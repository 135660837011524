import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HistoryEntryComponent} from './history-entry/history-entry.component';
import {DebugModule} from '../debug/debug.module';
import {AppNotificationModule} from '../app-notifications/app-notifications.module';
import {CasesModule} from '../cases/cases.module';
import {HistoryNotesComponent} from './history-notes/history-notes.component';
import {NotesModule} from '../notes/notes.module';

@NgModule({
  declarations: [
    HistoryEntryComponent,
    HistoryNotesComponent,
  ],
  imports: [
    CommonModule,
    DebugModule,
    AppNotificationModule,
    CasesModule,
    NotesModule
  ],
  exports: [
    HistoryEntryComponent,
    HistoryNotesComponent
  ]
})
export class HistoryModule {}
