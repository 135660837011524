import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Store} from "@ngrx/store";
import {switchMap} from 'rxjs/operators';
import  {Subscription} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { UserService } from '../../services/user.service';
import {IUser} from "../../models/user.interface";
import {NotifyModalComponent} from "../../tools/notify-modal/notify-modal.component";
import {IUserState} from "../../store/user-state.interface";
import {IUi} from "../../models/ui.interface";
import {IUserRole} from "../../models/userrole.interface";
import {MODULE_PAGES, MODULES} from "../../routing/routes.model";
import * as UiActions from "../../store/ui.actions";


@Component({
  selector: 'user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {

  printableUserRoles: string[] = [];
  userRolesSub: Subscription;
  userRoles: IUserRole[] = [];
  newUser: IUser;
  id: number;
  creatingUser = false;

  public submitted: boolean; // keep track if form is submitted
  public events: any[] = []; // used to display form changes


  constructor(private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private modalService: NgbModal,
              private store: Store<{ui: IUi, users: IUserState, userRoles: IUserRole[]}>)
  {
    this.userRolesSub = this.store.select('userRoles').subscribe(
      (userRoles) => {
        this.userRoles = [...userRoles];
        for (const userRole of userRoles) {
          this.printableUserRoles[+userRole.id] = userRole.description;
        }
      });
  }

  ngOnInit() {
    this.newUser = <IUser>{};
  }

  save() {
    // validate form data first before sending to server
    this.creatingUser = true;
    this.userService.create(this.newUser).subscribe(
      response => { this.userCreated(response)},
      (error) => { this.userCreateFailed()},
        () => {}
        );
  }

  userCreated(response) {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.ADMINISTRATION, page: MODULE_PAGES.ADMINISTRATION.USER}));
    const modalRef = this.modalService.open(NotifyModalComponent);
    modalRef.componentInstance.message = 'Der neue Benutzer wurde angelegt und benachrichtigt.';
    modalRef.componentInstance.title = 'Hinweis';
    this.creatingUser = false;
  }

  userCreateFailed() {
    const modalRef = this.modalService.open(NotifyModalComponent);
    modalRef.componentInstance.message = 'Der neue Benutzer konnte nicht angelegt werden.';
    modalRef.componentInstance.title = 'Fehler';
    this.creatingUser = false;
  }



  cancel() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.ADMINISTRATION, page: MODULE_PAGES.ADMINISTRATION.USER}));
  }
}
