import {AfterViewInit, Component, Input} from '@angular/core';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {StammbaumService} from '../../services/stammbaum.service';

@Component({
  selector: 'case-detail-persons',
  templateUrl: './case-detail-persons.component.html',
  styleUrls: ['./case-detail-persons.component.css']
})
export class CaseDetailPersonsComponent implements AfterViewInit {
  @Input() stammbaumState: IStammbaumState;

  constructor(private stammbaumService: StammbaumService) {}

  ngAfterViewInit() {
    window.scrollTo({top: 0, left: 0});
  }

  hasTrueStammbaum() {
    return this.stammbaumService.hasTrueStammbaum();
  }
}
