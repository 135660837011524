import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {IStammbaumVersion} from '../stammbaum-version.interface';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {StammbaumVersionSelectComponent} from '../stammbaum-version-select/stammbaum-version-select.component';
import {StammbaumService} from '../../services/stammbaum.service';

@Component({
  selector: 'stammbaum-version-display',
  templateUrl: './stammbaum-version-display.component.html',
  styleUrls: ['./stammbaum-version-display.component.css']
})
export class StammbaumVersionDisplayComponent implements OnChanges {
  @Input() stammbaumState: IStammbaumState;
  currentVersion: IStammbaumVersion = null;

  constructor(private ngbModal: NgbModal) {}

  ngOnChanges(change: SimpleChanges) {
    if (change.stammbaumState && change.stammbaumState.currentValue) {
      if (change.stammbaumState.currentValue.stammbaum && change.stammbaumState.currentValue.stammbaum.version) {
        this.currentVersion = change.stammbaumState.currentValue.stammbaum.version;
      }
    }
  }

  triggerSelectVersion() {
    const ngbModalRef: NgbModalRef = this.ngbModal.open(StammbaumVersionSelectComponent, <NgbModalOptions>{size: 'lg', backdrop: 'static'});
    ngbModalRef.componentInstance.stammbaumState = this.stammbaumState;
  }
}
