import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import {INVZState} from "../../store/nvz-state.interface";
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import {MODULE_PAGES, MODULES} from "../../routing/routes.model";
import * as UiActions from "../../store/ui.actions";
import * as NVZOptions from "../nvz-options";
import {NVZService} from "../../services/nvz.service";
import * as NVZActions from "../../store/nvz.actions";
import * as AppNotificationActions from "../../store/app-notification.actions";
import {
  NOTIFICATION_MESSAGE_LOAD_NVZ_FAILED_ERROR,
  NOTIFICATION_SLOT_NVZ
} from "../../app-notifications/app-notification.interface";
import {INVZEintrag} from "../nvz-eintrag.interface";
import {INVZEintragSearchresult} from "../nvz-eintrag-searchresult.interface";

@Component({
  selector: 'nvz-zufall',
  templateUrl: './nvz-zufall.component.html',
  styleUrls: ['./nvz-zufall.component.css']
})
export class NvzZufallComponent implements OnInit {

  @Input() nvzState: INVZState;
  search_done: boolean;
  options = NVZOptions.NVZUrkundenArt;
  search_results: INVZEintragSearchresult[] = [];

  constructor(private store: Store<{nvz: INVZState}>,
              private nvzService: NVZService) { }

  ngOnInit() {
    this.loadData();
  }

  back() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.NAMENSVERZEICHNISSE, page: MODULE_PAGES.NAMENSVERZEICHNISSE.SEARCH}));
  }

  loadData() {
    this.search_done = false;
    this.nvzService.showZufallsfundEintraege().subscribe(
      (response) => {
        this.search_results = response;
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
        this.search_done = true;
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_NVZ_FAILED_ERROR));
      },
      () => {}
    );
  }

  showVerzeichnisArt(artId: number) {
    let options = NVZOptions.NVZUrkundenArt;
    let option = options.find(x => x.id == artId);
    if (option) {
      return option.name;
    }
    return "--";
  }
}
