import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AdministrationEntryComponent} from "./administration-entry/administration-entry.component";
import {UsersModule} from "../users/users.module";
import {AppNotificationModule} from "../app-notifications/app-notifications.module";
import {GeneratedDocumentsModule} from "../generated-documents/generated-documents.module";

@NgModule({
  imports: [
    CommonModule,
    UsersModule,
    AppNotificationModule,
    GeneratedDocumentsModule
  ],
  declarations: [
    AdministrationEntryComponent
  ],
  exports: [
    AdministrationEntryComponent
  ]
})
export class AdministrationModule {}
