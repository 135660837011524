import {Component, Input, OnInit} from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import {IUi} from "../../models/ui.interface";
import {IAuthUser} from "../../models/auth-user.interface";
import {Store} from "@ngrx/store";
import * as AuthUserActions from "../../store/auth.actions";
import {RightsManagementService} from "../../services/rights-management.service";
import {RoutingService} from "../../routing/routing.service";
import {MODULES} from "../../routing/routes.model";
import * as UiActions from "../../store/ui.actions";
import * as CaseActions from "../../store/case.actions";

@Component({
  selector: 'app-sidemenu',
  templateUrl: 'sidemenu.component.html',
  styleUrls: ['sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {

  @Input() ui: {ui: IUi};
  MODULES;

  sub_is_active = false; // todo remove / refactor

  constructor(public rightsManagementService: RightsManagementService,
              public authenticationService: AuthenticationService,
              private routingService: RoutingService,
              private store: Store<{authUser: IAuthUser}>) { }

  ngOnInit() {
    this.MODULES = MODULES;
  }

  navigateToModule(route: string) {
    this.routingService.navigateToModule({moduleName: route});
  }

  toggleActive() { // todo remove / refactor
    this.sub_is_active = !this.sub_is_active;
  }

  logout() {
    this.authenticationService.logout();
  }
}
