import {Action} from '@ngrx/store';
import {INVZStandesamt} from '../namensverzeichnisse/nvz-standesamt.interface';
import {INVZEintrag} from '../namensverzeichnisse/nvz-eintrag.interface';
import {INVZVerzeichnis} from '../namensverzeichnisse/nvz-verzeichnis.interface';
import {INVZStadt} from '../namensverzeichnisse/nvz-stadt.interface';
import {INVZLand} from '../namensverzeichnisse/nvz-land.interface';

export const REPLACE_LAENDER = 'REPLACE_LAENDER';
export const REPLACE_STAEDTE = 'REPLACE_STAEDTE';
export const REPLACE_STANDESAEMTER = 'REPLACE_STANDESAEMTER';
export const REPLACE_VERZEICHNISSE = 'REPLACE_VERZEICHNISSE';
export const REPLACE_NVZEINTRAEGE = 'REPACE_NVZEINTRAEGE';
export const SET_ACTIVE_STADT = 'SET_ACTIVE_STADT';
export const SET_ACTIVE_LAND = 'SET_ACTIVE_LAND';
export const SET_ACTIVE_STANDESAMT = 'SET_ACTIVE_STANDESAMT';
export const SET_ACTIVE_VERZEICHNIS = 'SET_ACTIVE_VERZEICHNIS';

export class ReplaceLaender implements Action {
  readonly type = REPLACE_LAENDER;
  constructor(public payload: INVZLand[]) {}
}

export class ReplaceStaedte implements Action {
  readonly type = REPLACE_STAEDTE;
  constructor(public payload: INVZStadt[]) {}
}

export class ReplaceStandesaemter implements Action {
  readonly type = REPLACE_STANDESAEMTER;
  constructor(public payload: INVZStandesamt[]) {}
}

export class ReplaceVerzeichnisse implements Action {
  readonly type = REPLACE_VERZEICHNISSE;
  constructor(public payload: INVZVerzeichnis[]) {}
}

export class ReplaceNVZEintrage implements Action {
  readonly type = REPLACE_NVZEINTRAEGE;
  constructor(public payload: INVZEintrag[]) {}
}

export class SetActiveStadt implements Action {
  readonly type = SET_ACTIVE_STADT;
  constructor(public payload: INVZStadt) {}
}

export class SetActiveLand implements Action {
  readonly type = SET_ACTIVE_LAND;
  constructor(public payload: INVZLand) {}
}

export class SetActiveStandesamt implements Action {
  readonly type = SET_ACTIVE_STANDESAMT;
  constructor(public payload: INVZStandesamt) {}
}

export class SetActiveVerzeichnis implements Action {
  readonly type = SET_ACTIVE_VERZEICHNIS;
  constructor(public payload: INVZVerzeichnis) {}
}

export type NVZActions =
  ReplaceLaender |
  ReplaceStaedte |
  ReplaceStandesaemter |
  ReplaceVerzeichnisse |
  ReplaceNVZEintrage |
  SetActiveStadt |
  SetActiveLand |
  SetActiveStandesamt |
  SetActiveVerzeichnis;
