import {Component} from "@angular/core";
import {Store} from "@ngrx/store";
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {IAddressbookInstitution} from "../addressbook-institution.interface";
import {AddressbookService} from "../../services/addressbook.service";
import * as AddressbookEntryActions from "../../store/addressbook-entry.actions";
import {IAddressbookEntryState} from "../../store/addressbook-entry-state.interface";

@Component({
  selector: 'addressbook-create-popup',
  templateUrl: './addressbook-create-popup.component.html',
  styleUrls: ['./addressbook-create-popup.component.css']
})

export class AddressbookCreatePopupComponent {
  institution: IAddressbookInstitution = <IAddressbookInstitution>{};
  addressbookEntryForm: FormGroup;
  saving: boolean = false;
  institutionTypes; // todo typing, change, refactor to ngrx/store and loading on change from table/backend!

  constructor(public activeModal: NgbActiveModal,
              private addressbookService: AddressbookService,
              private store: Store<{addressbookEntry: IAddressbookEntryState}>) {}

  ngOnInit() {
    this.addressbookEntryForm = new FormGroup({
      'type': new FormControl(null, [Validators.required]),
      'institution_name': new FormControl(null, [Validators.required, Validators.maxLength(40)])
    });
    this.addressbookService.institutionTypesObs.subscribe(
      (instTypes) => {this.institutionTypes = instTypes;} // todo dirty hack, remove/replace
    );
  }

  cancel() {
    this.activeModal.close(null);
  }

  save() {
    this.saving = true;
    this.institution = <IAddressbookInstitution>{};
    this.institution.name = this.addressbookEntryForm.controls.institution_name.value;
    this.institution.type = this.addressbookEntryForm.controls.type.value;
    this.addressbookService.create(this.institution).subscribe(
      (result) => {
        // success
        this.store.dispatch(new AddressbookEntryActions.SetActiveAddressbookEntry(result));
        this.saving = false;
        this.activeModal.close(result);
      },
      (error) => {
        // dispatch Action to show error message for saving
        this.saving = false;
      },
      () => {
        if (this.saving) {
          this.saving = false;
          // dispatch Action to show error message for timeout
        }
      }
      );

  }
}
