import {IStammbaumCheckpoint} from './stammbaum-checkpoint.interface';

export class IStammbaumVersion {
  id: number;
  created: string;
  updated: string;
  title: string;
  comment: string;
  case: number;
  is_default: boolean;
  created_by: number;
  last_changed_by: number;
  persons: number[];
  marriages: number[];
  hide_eo_4: boolean;
  hide_eo_3: boolean;
  height: number; // height of the stammbaum grid
  width: number; // width of the stammbaum grid
  checkpoints: IStammbaumCheckpoint[];
}
