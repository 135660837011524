import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuskehrService} from '../../services/auskehr.service';
import {captureMessage} from '@sentry/browser';
import {AuskehrGeldquelleInterface} from '../auskehr.interface';

@Component({
  selector: 'app-edit-auskehr-quelle',
  templateUrl: './edit-auskehr-quelle.component.html',
  styleUrls: ['./edit-auskehr-quelle.css']
})
export class EditAuskehrQuelleComponent implements OnInit {
  editMode = false;
  form: FormGroup;
  saving = false;
  caseId = null;
  institution = null;
  geldquelle: AuskehrGeldquelleInterface = null;

  constructor(private activeModal: NgbActiveModal, private auskehrService: AuskehrService) {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      description: new FormControl('', Validators.maxLength(300)),
      sum_expected: new FormControl(null),
      sum_expected_as_number: new FormControl(0),
      sum_expected_formatted: new FormControl('€ 0,00'),
      sum_invalid: new FormControl(true),
      completed: new FormControl(0)
    });
    this.form.controls.sum_expected.valueChanges.subscribe(
      (newValue) => {
        let transformedValue = '';
        if (newValue === null || newValue === undefined || newValue === '') {
          this.form.patchValue({
            sum_expected_formatted: 'ungültig',
            sum_expected_as_number: 0,
            sum_invalid: true
          });
          return;
        }
        if (typeof newValue !== 'string') {
          newValue = newValue.toString();
        }
        if (newValue.match(/^[+\-0-9,.()]+$/g) === null) {
          this.form.patchValue({
            sum_expected_formatted: 'ungültig',
            sum_expected_as_number: 0,
            sum_invalid: true
          });
          return;
        }
        const countKomma = newValue.split(',').length - 1;
        let countPoint = newValue.split('.').length - 1;
        if (countKomma === 1 && countPoint === 1) {
          if (newValue.indexOf('.') + 4 === newValue.indexOf(',')) {
            newValue = newValue.replace('.', '')
            countPoint = newValue.split('.').length - 1;
          }
        }
        let frontString = '';
        let backString = '';
        if (countKomma + countPoint > 1) {
          this.form.patchValue({
            sum_expected_formatted: 'ungültig',
            sum_expected_as_number: 0,
            sum_invalid: true
          });
          return
        }
        if (countKomma + countPoint === 0) {
          transformedValue = `€ ${newValue},00`;
          this.form.patchValue({
            sum_expected_as_number: +newValue,
          });
        }
        if (countKomma + countPoint === 1) {
          let spl: string[];
          if (countKomma === 1) {
            spl = newValue.split(',', 2);
          } else {
            spl = newValue.split('.', 2);
          }
          frontString = spl[0];
          backString = spl[1];
          if (backString === null || backString === undefined) {
            backString = '00';
          }
          if (backString.length === 1) {
            backString = `${backString}0`;
          }
          if (backString.length === 0) {
            backString = `${backString}00`;
          }
          if (backString.length > 2) {
            this.form.patchValue({
              sum_expected_formatted: 'ungültig',
              sum_expected_as_number: 0,
              sum_invalid: true
            });
            return;
          }
          if (frontString.length === 0) {
            frontString = '0';
          }
          transformedValue = `€ ${frontString},${backString}`;
           this.form.patchValue({
            sum_expected_as_number: +frontString + (+backString / 100),
          });
        }
        this.form.patchValue({
              sum_expected_formatted: transformedValue,
              sum_invalid: false
            });
    });
  }

  ngOnInit() {
    if (this.editMode) {
      this.form.patchValue({
        name: this.geldquelle.name,
        description: this.geldquelle.description,
        sum_expected: this.geldquelle.sum_expected,
        completed: 0
      });
      if (this.geldquelle.completed || +this.geldquelle.completed === 1 || this.geldquelle.completed.toString() === 'true') {
        this.form.patchValue({ completed: 1 });
      }
    }
  }

  cancel() {
    this.activeModal.close();
  }

  save() {
    if (!this.editMode) {
      this.saving = true;
      this.auskehrService.saveGeldquelle(
        <AuskehrGeldquelleInterface>{
          name: this.form.controls.name.value,
          description: this.form.controls.description.value,
          sum_expected: this.form.controls.sum_expected_as_number.value,
          completed: this.form.controls.completed.value,
        },
        this.institution.id,
        this.caseId,
        this.editMode
      ).subscribe(
        (result) => {
          this.auskehrService.saveGeldquelleLocally(result, this.institution.id);
          this.saving = false;
          this.activeModal.close();
        },
        (error) => {
          captureMessage('error saving auskehr geldquelle');
          console.log(error);
          this.saving = false;
        },
        () => {
        }
      );
    } else {
      this.saving = true;
      this.auskehrService.saveGeldquelle(
        <AuskehrGeldquelleInterface>{
          id: this.geldquelle.id,
          name: this.form.controls.name.value,
          description: this.form.controls.description.value,
          sum_expected: this.form.controls.sum_expected_as_number.value,
          completed: this.form.controls.completed.value,
        },
        this.institution.id,
        this.caseId,
        this.editMode
      ).subscribe(
        (result) => {
          this.auskehrService.saveGeldquelleLocally(result, this.institution.id);
          this.saving = false;
          this.activeModal.close();
        },
        (error) => {
          captureMessage('error saving auskehr geldquelle');
          console.log(error);
          this.saving = false;
        },
        () => {
        }
      );
    }
  }
}

