import {Action} from '@ngrx/store';
import {IGeneratedDocCategory} from "../generated-documents/generated-doc-category.interface";
import {IGeneratedDocTemplate} from "../generated-documents/generated-doc-template.interface";
import {IGeneratedDocRecipient} from "../generated-documents/generated-doc-recipient.interface";

export const REPLACE_GENERATED_DOCUMENT_CATEGORIES = 'REPLACE_GENERATED_DOCUMENT_CATEGORIES';
export const REPLACE_GENERATED_DOCUMENT_TEMPLATES = 'REPLACE_GENERATED_DOCUMENT_TEMPLATES';
export const REPLACE_GENERATED_DOCUMENT_TEMPLATE_SELECTED = 'REPLACE_GENERATED_DOCUMENT_TEMPLATE_SELECTED';
export const REPLACE_GENERATED_DOCUMENT_RECIPIENTS_SELECTED = 'REPLACE_GENERATED_DOCUMENT_RECIPIENTS_SELECTED';
export const REPLACE_GENERATED_DOCUMENT_CASE_AND_PERSON_SELECTED = 'REPLACE_GENERATED_DOCUMENT_CASE_AND_PERSON_SELECTED';
export const TRIGGER_RELOAD_GENERATED_DOCUMENT_CATEGORIES = 'TRIGGER_RELOAD_GENERATED_DOCUMENT_CATEGORIES';
export const TRIGGER_RELOAD_GENERATED_DOCUMENT_TEMPLATES = 'TRIGGER_RELOAD_GENERATED_DOCUMENT_TEMPLATES';

export class ReplaceGeneratedDocumentCategories implements Action {
  readonly type = REPLACE_GENERATED_DOCUMENT_CATEGORIES;
  constructor(public payload: IGeneratedDocCategory[]) {}
}

export class ReplaceGeneratedDocumentTemplates implements Action {
  readonly type = REPLACE_GENERATED_DOCUMENT_TEMPLATES;
  constructor(public payload: IGeneratedDocTemplate[]) {}
}

export class ReplaceGeneratedDocumentTemplateSelected implements Action {
  readonly type = REPLACE_GENERATED_DOCUMENT_TEMPLATE_SELECTED;
  constructor(public payload: IGeneratedDocTemplate) {}
}

export class ReplaceGeneratedDocumentRecipientsSelected implements Action {
  readonly type = REPLACE_GENERATED_DOCUMENT_RECIPIENTS_SELECTED;
  constructor(public payload: IGeneratedDocRecipient[]) {}
}

export class ReplaceGeneratedDocumentCaseAndPersonSelected implements Action {
  readonly type = REPLACE_GENERATED_DOCUMENT_CASE_AND_PERSON_SELECTED;
  constructor(public payload: {case_id: number, person_id: number}) {}
}

export class TriggerReloadGeneratedDocumentCategories implements Action {
  readonly type = TRIGGER_RELOAD_GENERATED_DOCUMENT_CATEGORIES;
  constructor() {}
}

export class TriggerReloadGeneratedDocumentTemplates implements Action {
  readonly type = TRIGGER_RELOAD_GENERATED_DOCUMENT_TEMPLATES;
  constructor(public payload: number) {} // category id for which to load templates
}

export type GeneratedDocumentActions =
  ReplaceGeneratedDocumentCategories |
  ReplaceGeneratedDocumentTemplateSelected |
  ReplaceGeneratedDocumentRecipientsSelected |
  ReplaceGeneratedDocumentCaseAndPersonSelected |
  TriggerReloadGeneratedDocumentCategories |
  ReplaceGeneratedDocumentTemplates |
  TriggerReloadGeneratedDocumentTemplates;
