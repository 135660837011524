import {Action} from '@ngrx/store';
import {IAutoparserDirectory} from '../nvz-autoparser/autoparser-directory.interface';
import {IAutoparserColumnSettings, IAutoparserPostprocessedEntry} from './autoparser-state.interface';

export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE';
export const SET_AUTOPARSER_ACTIVE_PAGE = 'SET_AUTOPARSER_ACTIVE_PAGE';
export const SET_AUTOPARSER_ACTIVE_DIR = 'SET_AUTOPARSER_ACTIVE_DIR';
export const SET_AUTOPARSER_COLUMN_SETTINGS = 'SET_AUTOPARSER_COLUMN_SETTINGS';
export const REPLACE_AUTOPARSER_POSTPROCESS_LIST = 'REPLACE_AUTOPARSER_POSTPROCESS_LIST';
export const REPLACE_AUTOPARSER_DIRECTORIES = 'REPLACE_AUTOPARSER_DIRECTORIES';

export class AddNewMessage implements Action {
  readonly type = ADD_NEW_MESSAGE;
  constructor(public payload: string) {}
}

export class SetAutoparserActivePage implements Action {
  readonly type = SET_AUTOPARSER_ACTIVE_PAGE;
  constructor(public payload: string) {}
}

export class SetAutoparserActiveDir implements Action {
  readonly type = SET_AUTOPARSER_ACTIVE_DIR;
  constructor(public payload: IAutoparserDirectory) {}
}

export class SetAutoparserColumnSettings implements Action {
  readonly type = SET_AUTOPARSER_COLUMN_SETTINGS;
  constructor(public payload: IAutoparserColumnSettings) {}
}

export class ReplaceAutoparserPostprocessList implements Action {
  readonly type = REPLACE_AUTOPARSER_POSTPROCESS_LIST;
  constructor(public payload: IAutoparserPostprocessedEntry[]) {}
}

export class ReplaceAutoparserDirectories implements Action {
  readonly type = REPLACE_AUTOPARSER_DIRECTORIES;
  constructor(public payload: IAutoparserDirectory[]) {}
}

export type AutoparserActions = AddNewMessage |
  SetAutoparserActivePage |
  SetAutoparserActiveDir |
  SetAutoparserColumnSettings |
  ReplaceAutoparserPostprocessList |
  ReplaceAutoparserDirectories;
