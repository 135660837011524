import { NgModule } from '@angular/core';
import { ExtraFormsComponent } from './extra-forms.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    ExtraFormsComponent,
    AutocompleteComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    ExtraFormsComponent,
    AutocompleteComponent
  ]
})
export class ExtraFormsModule { }
