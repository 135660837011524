import {Component, OnInit} from '@angular/core';
import {captureMessage} from "@sentry/browser";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AuskehrService} from "../../services/auskehr.service";
import {IAddressbookInstitution} from "../../addressbook/addressbook-institution.interface";

@Component({
  selector: 'app-remove-add-auskehr-institution',
  templateUrl: './remove-auskehr-institution.component.html',
  styleUrls: ['./remove-auskehr-institution.component.css']
})
export class RemoveAuskehrInstitutionComponent {
  institution: IAddressbookInstitution;
  saving = false;
  caseId = null;

  constructor(private activeModal: NgbActiveModal, private auskehrService: AuskehrService) {}

  cancel() {
    this.activeModal.dismiss();
  }

  save() {
    this.saving = true;
    this.auskehrService.removeInstitution(
      this.institution.id,
      this.caseId,
    ).subscribe(
      (result) => {
        this.auskehrService.removeInstitutionLocally(result);
        this.saving = false;
        this.activeModal.close();
      },
      (error) => {
        captureMessage('error removing auskehr institution');
        console.log(error);
        this.saving = false;
      },
      () => {
      }
    );
  }
}
