import {Injectable, OnDestroy} from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import {Store} from '@ngrx/store';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ConstantsService} from './constants.service';
import {INetworkState} from '../store/network-state.interface';
import {IAuthUser} from '../models/auth-user.interface';
import * as NetworkActions from '../store/network.actions';
import {ICaseStatus} from '../models/case-status.interface';
import * as CaseStatusActions from '../store/case-status.actions';

const TRY_RELOAD_INTERVAL = 10 * 1000;

@Injectable()
export class CasestatusService implements OnDestroy {

  caseStatusLoadingInProgress: boolean;
  caseStatusObs: Observable<ICaseStatus[]>;
  caseStatusSubscription: Subscription;
  caseStatus: ICaseStatus[];
  networkObs: Observable<INetworkState>;
  network: INetworkState;
  networkSubscription: Subscription;
  authUserObs: Observable<{authUser: IAuthUser}>;
  authUser: IAuthUser;
  authUserSubscription: Subscription;
  reloadTimerSubscription: Subscription;

  constructor(private http: HttpClient,
              private store: Store<{caseStatus: ICaseStatus[], network: INetworkState, authUser: {authUser: IAuthUser}}>,
              private constantsService: ConstantsService) {
    this.caseStatusObs = this.store.select('caseStatus');
    this.caseStatusSubscription = this.caseStatusObs.subscribe(
      (caseStatus) => {this.caseStatus = caseStatus; }
    );
    this.networkObs = this.store.select('network');
    this.networkSubscription = this.networkObs.subscribe(
      (network) => {this.network = network; }
    );
    this.authUserObs = this.store.select('authUser');
    this.authUserSubscription = this.authUserObs.subscribe(
      (authUser) => {this.authUser = authUser.authUser; }
    );
    this.reloadTimerSubscription = timer(0, TRY_RELOAD_INTERVAL).subscribe(t => {
      if (this.network.updateNeededUserRole && this.authUser.loggedIn && this.network.isConnectedToServer) {
        // if user is logged in, reload is necessary and there is a connection
        this.getUserRolesFromServer();
      }
    });
  }

  triggerCaseStatusReloadFromServer() {
    this.store.dispatch(new NetworkActions.SetUpdateNeededCaseStatusTrue());
    this.getUserRolesFromServer();
  }

  getCaseStatusText(status) {
    const sta = this.caseStatus.find(x => +x.id === +status);
    if (sta) { return sta.description; }
    return 'keine Beschreibung';
  }

  getUserRolesFromServer() {
    if (this.caseStatusLoadingInProgress) { // if already reloading, then ignore
      return;
    }
    this.caseStatusLoadingInProgress = true;
    this.http.get<ICaseStatus[]>(`${
      this.constantsService.getApiEndpoint()}/casestatus/`, {headers: this.constantsService.getHttpOptions()
    }).subscribe(
      (response) => {
        this.store.dispatch(new CaseStatusActions.SetCaseStatus(response));
        this.store.dispatch(new NetworkActions.SetUpdateNeededCaseStatusFalse());
        this.caseStatusLoadingInProgress = false;
      },
      () => {
        this.caseStatusLoadingInProgress = false;
      },
      () => {
        this.caseStatusLoadingInProgress = false;
      });
  }

  ngOnDestroy() {
    this.caseStatusSubscription.unsubscribe();
    this.authUserSubscription.unsubscribe();
    this.networkSubscription.unsubscribe();
    this.reloadTimerSubscription.unsubscribe();
  }
}
