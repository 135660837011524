import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IAutoparserColumnSettings, IAutoparserState} from '../../store/autoparser-state.interface';
import {IAutoparserRow} from '../autoparser-row.interface';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import * as AutoparserActions from '../../store/autoparser.actions';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';

@Component({
  selector: 'nvz-ap-page-settings',
  templateUrl: './nvz-page-settings.component.html',
  styleUrls: ['./nvz-page-settings.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NvzPageSettingsComponent implements OnInit, OnDestroy {
  editForm: FormGroup;
  column_settings: IAutoparserColumnSettings;
  nachname_col_options = [];
  vorname_col_options = [];
  geburtsname_col_options = [];
  urkunden_nr_col_options = [];
  juedisch_col_options = [];
  current_row: IAutoparserRow;
  autpparserStateSub: Subscription;

  constructor(private ngbActiveModal: NgbActiveModal,
              private ngbModal: NgbModal,
              private store: Store<{autoparser: IAutoparserState}>) {
    this.editForm = new FormGroup({
      'choose_vorname': new FormControl(-1),
      'choose_nachname': new FormControl(-1),
      'choose_geburtsname': new FormControl(-1),
      'choose_urkunden_nr': new FormControl(-1),
      'choose_juedisch': new FormControl(-1),
    });
    this.editForm.controls['choose_vorname'].valueChanges.subscribe(
      (value) => {
        this.column_settings.columnVorname = +value;
        this.calculateOptions();
      }
    );
    this.editForm.controls['choose_nachname'].valueChanges.subscribe(
      (value) => {
        this.column_settings.columnNachname = +value;
        this.calculateOptions();
      }
    );
    this.editForm.controls['choose_geburtsname'].valueChanges.subscribe(
      (value) => {
        this.column_settings.columnGeburtsname = +value;
        this.calculateOptions();
      }
    );
    this.editForm.controls['choose_urkunden_nr'].valueChanges.subscribe(
      (value) => {
        this.column_settings.columnUrkundennummer = +value;
        this.calculateOptions();
      }
    );
    this.editForm.controls['choose_juedisch'].valueChanges.subscribe(
      (value) => {
        this.column_settings.columnJuedisch = +value;
        this.calculateOptions();
      }
    );
  }

  ngOnInit(): void {
    this.autpparserStateSub = this.store.select('autoparser').subscribe(
      (autoparserState) => {
        if (autoparserState && autoparserState.autoparserColumnSettings) {
          this.column_settings = autoparserState.autoparserColumnSettings;
          this.editForm.patchValue({
            'choose_vorname': this.column_settings.columnVorname,
            'choose_nachname': this.column_settings.columnNachname,
            'choose_geburtsname': this.column_settings.columnGeburtsname,
            'choose_urkunden_nr': this.column_settings.columnUrkundennummer,
            'choose_juedisch': this.column_settings.columnJuedisch,
          });
          this.calculateOptions();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.autpparserStateSub.unsubscribe();
  }

  calculateOptions() {
    let i: number = 0;
    this.nachname_col_options = [{value: -1, text: 'keine'}];
    this.vorname_col_options = [{value: -1, text: 'keine'}];
    this.geburtsname_col_options = [{value: -1, text: 'keine'}];
    this.urkunden_nr_col_options = [{value: -1, text: 'keine'}];
    this.juedisch_col_options = [{value: -1, text: 'keine'}];
    if (this.column_settings.columnNachname !== -1) {
      this.geburtsname_col_options.push({value: -2, text: 'unter Nachname'});
    }
    for (const col of this.current_row.columns) {
      if (i !== this.column_settings.columnVorname && i !== this.column_settings.columnGeburtsname && i !== this.column_settings.columnUrkundennummer && i !== this.column_settings.columnJuedisch) {
        this.nachname_col_options.push({value: i, text: 'Spalte ' + i.toString()});
      }
      if (i !== this.column_settings.columnNachname && i !== this.column_settings.columnGeburtsname && i !== this.column_settings.columnUrkundennummer && i !== this.column_settings.columnJuedisch) {
        this.vorname_col_options.push({value: i, text: 'Spalte ' + i.toString()});
      }
      if (i !== this.column_settings.columnNachname && i !== this.column_settings.columnVorname && i !== this.column_settings.columnUrkundennummer && i !== this.column_settings.columnJuedisch) {
        this.geburtsname_col_options.push({value: i, text: 'Spalte ' + i.toString()});
      }
      if (i !== this.column_settings.columnNachname && i !== this.column_settings.columnVorname && i !== this.column_settings.columnGeburtsname && i !== this.column_settings.columnJuedisch) {
        this.urkunden_nr_col_options.push({value: i, text: 'Spalte ' + i.toString()});
      }
      if (i !== this.column_settings.columnNachname && i !== this.column_settings.columnVorname && i !== this.column_settings.columnGeburtsname && i !== this.column_settings.columnUrkundennummer) {
        this.juedisch_col_options.push({value: i, text: 'Spalte ' + i.toString()});
      }
      i++;
    }
  }

  close() {
    this.store.dispatch(new AutoparserActions.SetAutoparserColumnSettings({
      columnVorname: this.editForm.controls.choose_vorname.value,
      columnNachname: this.editForm.controls.choose_nachname.value,
      columnGeburtsname: this.editForm.controls.choose_geburtsname.value,
      columnUrkundennummer: this.editForm.controls.choose_urkunden_nr.value,
      columnJuedisch: this.editForm.controls.choose_juedisch.value,
      columnsDisabled: [] // todo
    }));
    this.ngbActiveModal.close();
  }
}
