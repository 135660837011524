import * as StammbaumActions from './stammbaum.actions';
import {IStammbaumState} from './stammbaum-state.interface';
import {IStammbaumPerson} from '../stammbaum/stammbaum-person.interface';
import {IStammbaumMarriage} from '../stammbaum/stammbaum-marriage.interface';
import {IStammbaum} from '../stammbaum/stammbaum.interface';
import {IStammbaumConnections} from '../stammbaum/stammbaum-connections.interface';
import {captureMessage} from '@sentry/browser';


const initialState: IStammbaumState = {
  stammbaum: <IStammbaum>{
    persons: [],
    marriages: [],
    version: null,
    connections: <IStammbaumConnections>{},
    active_version_id: null
  },
  stammbaumPersonsWithoutVersion: [],
  stammbaumMarriagesWithoutVersion: [],
  stammbaumVersions: [],
  stammbaumDisplay: {
    zoomFactor: 3,
    showKasten: true
  },
  scrollToY: null,
  scrollToX: null,
  executePrint: false,
  personForUpload: null,
  marriageForUpload: null,
  personToEditId: null,
  marriageToEditId: null,
  stammbaumMode: 'person-list',
  treeViewMode: 'edit-person-data',
  urkundeToEdit: null
};

export function stammbaumReducer(state = initialState, action: StammbaumActions.StammbaumActions) {
  switch (action.type) {

    case StammbaumActions.SET_STAMMBAUM_MODE:
      return {
        ...state,
        stammbaumMode: action.payload,
        executePrint: false,
        scrollToX: null,
        scrollToY: null
      };

    case StammbaumActions.TRIGGER_ADD_PERSON_TO_ACTIVE_STAMMBAUM:
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        stammbaum: {
          version: {...state.stammbaum.version},
          persons: [...state.stammbaum.persons, action.payload],
          marriages: [...state.stammbaum.marriages],
          connections: {...state.stammbaum.connections},
        }
      };

    case StammbaumActions.TRIGGER_ADD_MARRIAGE_TO_ACTIVE_STAMMBAUM: // this is old
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        stammbaum: {
          version: {...state.stammbaum.version},
          persons: [...state.stammbaum.persons],
          marriages: [...state.stammbaum.marriages, action.payload],
          connections: {...state.stammbaum.connections},
        }
      };

    case StammbaumActions.TRIGGER_ADD_MARRIAGE_AND_PERSON_TO_ACTIVE_STAMMBAUM: // new
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null
      }; // everything is handled by corresponding effect

    case StammbaumActions.REPLACE_PERSON_FOR_UPLOAD:
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        personForUpload: action.payload
      };

    case StammbaumActions.REPLACE_PERSON_TO_EDIT_ID:
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        personToEditId: action.payload
      };

    case StammbaumActions.REPLACE_MARRIAGE_TO_EDIT_ID:
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        marriageToEditId: action.payload
      };

    case StammbaumActions.REPLACE_MARRIAGE_FOR_UPLOAD:
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        marriageForUpload: action.payload
      };

    case StammbaumActions.REPLACE_URKUNDE_TO_EDIT:
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        urkundeToEdit: action.payload
      };

    case StammbaumActions.TRIGGER_EDIT_PERSON_IN_ACTIVE_STAMMBAUM:
      const persons: IStammbaumPerson[] = state.stammbaum.persons;
      const person = persons.find(x => x.id === action.payload.id);
      if (!person) {
        captureMessage(`cannot edit person in active stammbaum, no person found for person ID: ${action.payload.id} in state.stammbaum.persons array (count ${state.stammbaum.persons.length})`);
        return state;
      }
      Object.assign(person, action.payload);
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        stammbaum: {
          version: {...state.stammbaum.version},
          persons: [...persons],
          marriages: [...state.stammbaum.marriages],
          connections: {...state.stammbaum.connections},
        }
      };

    case StammbaumActions.TRIGGER_EDIT_MARRIAGE_IN_ACTIVE_STAMMBAUM:
      const marriages: IStammbaumMarriage[] = state.stammbaum.marriages;
      const marriage = marriages.find(x => x.id === action.payload.id);
      Object.assign(marriage, action.payload);
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        stammbaum: {
          version: {...state.stammbaum.version},
          persons: [...state.stammbaum.persons],
          marriages: [...marriages],
          connections: {...state.stammbaum.connections},
        }
      };

    case StammbaumActions.TRIGGER_SET_STAMMBAUM_PERSON_ACTIVE:
      const persons_for_active: IStammbaumPerson[] = state.stammbaum.persons;
      const person_to_set_active = persons_for_active.find(x => x.id === action.payload);
      Object.assign(person_to_set_active, {is_active: true});
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        stammbaum: {
          version: {...state.stammbaum.version},
          persons: [...persons_for_active],
          marriages: [...state.stammbaum.marriages],
          connections: {...state.stammbaum.connections},
        }
      };

    case StammbaumActions.TRIGGER_SET_STAMMBAUM_PERSON_INACTIVE:
      const persons_for_inactive: IStammbaumPerson[] = state.stammbaum.persons;
      const person_to_set_inactive = persons_for_inactive.find(x => x.id === action.payload);
      Object.assign(person_to_set_inactive, {is_active: false});
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        stammbaum: {
          version: {...state.stammbaum.version},
          persons: [...persons_for_inactive],
          marriages: [...state.stammbaum.marriages],
          connections: {...state.stammbaum.connections},
        }
      };

    case StammbaumActions.TRIGGER_DELETE_STAMMBAUM_PERSON:
      const persons_for_delete: IStammbaumPerson[] = state.stammbaum.persons.filter(x => x.id !== action.payload);
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        stammbaum: {
          version: {...state.stammbaum.version},
          persons: [...persons_for_delete],
          marriages: [...state.stammbaum.marriages],
          connections: {...state.stammbaum.connections},
        }
      };

    case StammbaumActions.TRIGGER_DELETE_STAMMBAUM_MARRIAGE:
      const marriages_for_delete: IStammbaumMarriage[] = state.stammbaum.marriages.filter(x => x.id !== action.payload);
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        stammbaum: {
          version: {...state.stammbaum.version},
          persons: [...state.stammbaum.persons],
          marriages: [...marriages_for_delete],
          connections: {...state.stammbaum.connections},
        }
      };

    case StammbaumActions.REPLACE_ACTIVE_STAMMBAUM_PERSONS:
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        stammbaum: {
          version: {...state.stammbaum.version},
          persons: action.payload,
          marriages: [...state.stammbaum.marriages],
          connections: {...state.stammbaum.connections},
        }
      };

    case StammbaumActions.REPLACE_ACTIVE_STAMMBAUM_MARRIAGES:
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        stammbaum: {
          version: {...state.stammbaum.version},
          persons: [...state.stammbaum.persons],
          marriages: action.payload,
          connections: {...state.stammbaum.connections},
        }
      };

    case StammbaumActions.REPLACE_STAMMBAUM_PERSONS_AND_MARRIAGES_WITHOUT_VERSION_FOR_CASE:
      return {
        ...state,
        stammbaumPersonsWithoutVersion: action.payload.persons,
        stammbaumMarriagesWithoutVersion: action.payload.marriages
      };

    case StammbaumActions.REPLACE_STAMMBAUM_VERSIONS:
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        stammbaumVersions: action.payload
      };

    case StammbaumActions.REPLACE_ACTIVE_STAMMBAUM_CONNECTIONS:
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        stammbaum: {
          version: {...state.stammbaum.version},
          persons: [...state.stammbaum.persons],
          marriages: [...state.stammbaum.marriages],
          connections: action.payload,
        }
      };

    case StammbaumActions.REPLACE_ACTIVE_STAMMBAUM:
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        stammbaum: action.payload
      };

    case StammbaumActions.STAMMBAUM_DISPLAY_ZOOM_IN:
      let zf = state.stammbaumDisplay.zoomFactor;
      if (zf < 12) {
        zf += 1;
      }
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        stammbaumDisplay:
          {
            ...state.stammbaumDisplay,
            zoomFactor: zf
          }
      };

    case StammbaumActions.STAMMBAUM_DISPLAY_ZOOM_OUT:
      let zfo = state.stammbaumDisplay.zoomFactor;
      if (zfo > 1) {
        zfo -= 1;
      }
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        stammbaumDisplay:
          {
            ...state.stammbaumDisplay,
            zoomFactor: zfo
          }
      };

    case StammbaumActions.STAMMBAUM_DISPLAY_SET_SHOW_KASTEN:
      return {
        ...state,
        stammbaumDisplay: {
          ...state.stammbaumDisplay,
          showKasten: action.payload
        }
      }

    case StammbaumActions.STAMMBAUM_DISPLAY_TOGGLE_SHOW_KASTEN:
      const showKasten = !state.stammbaumDisplay.showKasten;
      return {
        ...state,
        stammbaumDisplay: {
          ...state.stammbaumDisplay,
          showKasten: showKasten
        }
      }

    case StammbaumActions.STAMMBAUM_EXECUTE_PRINT:
      return {
        ...state,
        executePrint: true,
        scrollToX: null,
        scrollToY: null
      };

    case StammbaumActions.STAMMBAUM_SCROLL_TO_POS:
      return {
        ...state,
        executePrint: false,
        scrollToX: action.payload.x,
        scrollToY: action.payload.y
      };

    case StammbaumActions.STAMMBAUM_SET_TREE_VIEW_MODE:
      return {
        ...state,
        executePrint: false,
        scrollToX: null,
        scrollToY: null,
        treeViewMode: action.payload
      };

    default:
      return state;
  }
}
