import {Action} from '@ngrx/store';
import {INote} from '../notes/note.interface';

export const REPLACE_RECENT_NOTES_LIST = 'REPLACE_RECENT_NOTES_LIST';
export const REPLACE_RECENT_NOTE = 'REPLACE_RECENT_NOTE';
export const REPLACE_NOTES_LIST_FOR_CURRENT_CASE = 'REPLACE_NOTES_LIST_FOR_CURRENT_CASE';
export const REPLACE_NOTE_FOR_CURRENT_CASE = 'REPLACE_NOTE_FOR_CURRENT_CASE';
export const REPLACE_NOTES_LIST_FOR_CURRENT_PERSON = 'REPLACE_NOTES_LIST_FOR_CURRENT_PERSON';
export const REPLACE_NOTE_FOR_CURRENT_PERSON = 'REPLACE_NOTE_FOR_CURRENT_PERSON';
export const HIGHLIGHT_NOTE = 'HIGHLIGHT_NOTE';

export class ReplaceRecentNotesList implements Action {
  readonly type = REPLACE_RECENT_NOTES_LIST;
  constructor(public payload: INote[]) {}
}

export class ReplaceRecentNote implements Action {
  readonly type = REPLACE_RECENT_NOTE;
  constructor(public payload: INote) {}
}

export class ReplaceNotesListForCurrentCase implements Action {
  readonly type = REPLACE_NOTES_LIST_FOR_CURRENT_CASE;
  constructor(public payload: INote[]) {}
}

export class ReplaceNoteForCurrentCase implements Action {
  readonly type = REPLACE_NOTE_FOR_CURRENT_CASE;
  constructor(public payload: INote) {}
}

export class ReplaceNotesListForCurrentPerson implements Action {
  readonly type = REPLACE_NOTES_LIST_FOR_CURRENT_PERSON;
  constructor(public payload: INote[]) {}
}

export class ReplaceNoteForCurrentPerson implements Action {
  readonly type = REPLACE_NOTE_FOR_CURRENT_PERSON;
  constructor(public payload: INote) {}
}

export class HighlightNote implements Action {
  readonly type = HIGHLIGHT_NOTE;
  constructor(public payload: number) {}
}

export type NoteActions =
  ReplaceRecentNotesList |
  ReplaceNotesListForCurrentCase |
  ReplaceNotesListForCurrentPerson |
  ReplaceRecentNote |
  ReplaceNoteForCurrentCase |
  ReplaceNoteForCurrentPerson |
  HighlightNote;

