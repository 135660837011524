export interface IAppNotification {
  text: string,
  icon_class: string,
  css_class: string,
  slot: string // it is possible to remove all notifications of one slot with one command
}

// constants for the slots
export const NOTIFICATION_SLOT_GLOBAL = 'NOTIFICATION_SLOT_GLOBAL'; // messages in global slot are shown in each notification widget, limitToSlot is ignored
export const NOTIFICATION_SLOT_CASELIST_LOADER = 'NOTIFICATION_SLOT_CASELIST_LOADER';
export const NOTIFICATION_SLOT_CASEDETAIL_LOADER = 'NOTIFICATION_SLOT_CASEDETAIL_LOADER';
export const NOTIFICATION_SLOT_PERSONLIST_LOADER = 'NOTIFICATION_SLOT_PERSONLIST_LOADER';
export const NOTIFICATION_SLOT_PERSONLIST_FOR_CASE_LOADER = 'NOTIFICATION_SLOT_PERSONLIST_FOR_CASE_LOADER';
export const NOTIFICATION_SLOT_PERSONDETAIL_LOADER = 'NOTIFICATION_SLOT_PERSONDETAIL_LOADER';
export const NOTIFICATION_SLOT_TEAMMEMBER = 'NOTIFICATION_SLOT_TEAMMEMBER';
export const NOTIFICATION_SLOT_NOTE = 'NOTIFICATION_SLOT_NOTE';
export const NOTIFICATION_SLOT_LOGIN = 'NOTIFICATION_SLOT_LOGIN';
export const NOTIFICATION_SLOT_CALENDAR = 'NOTIFICATION_SLOT_CALENDAR';
export const NOTIFICATION_SLOT_USERS = 'NOTIFICATION_SLOT_USERS';
export const NOTIFICATION_SLOT_BUNDESANZEIGER = 'NOTIFICATION_SLOT_BUNDESANZEIGER';
export const NOTIFICATION_SLOT_NVZ = 'NOTIFICATION_SLOT_NVZ';
export const NOTIFICATION_SLOT_FIND_NOTE = 'NOTIFICATION_SLOT_FIND_NOTE';

// constants for the display classes
export const NOTIFICATION_CLASS_PRIMARY = 'primary';
export const NOTIFICATION_CLASS_SUCCESS = 'success';
export const NOTIFICATION_CLASS_WARNING = 'warning';
export const NOTIFICATION_CLASS_DANGER = 'danger';

// empty message
export const NOTIFICATION_MESSAGE_EMPTY_MESSAGE = <IAppNotification>{text: 'Die lokalen Daten sind auf aktuellem Stand', icon_class: 'fa fa-check', css_class: 'alert-success', slot: NOTIFICATION_SLOT_GLOBAL};

// messages for slot CASELIST_LOADER
export const NOTIFICATION_MESSAGE_CASELIST_LOADER_LOADING = <IAppNotification>{text: 'Die Fall-Liste wird vom Server abgerufen', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_CASELIST_LOADER};
export const NOTIFICATION_MESSAGE_CASELIST_LOADER_ERROR = <IAppNotification>{text: 'ERROR: Die Fall-Liste konnte nicht übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_CASELIST_LOADER};
export const NOTIFICATION_MESSAGE_CASELIST_LOADER_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Fall-Liste konnte nicht übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_CASELIST_LOADER};
export const NOTIFICATION_MESSAGE_CASELIST_CREATE_NEW_CASE_IN_PROGRESS = <IAppNotification>{text: 'Ein neuer Fall wird angelegt', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_CASELIST_LOADER};
export const NOTIFICATION_MESSAGE_CASELIST_CREATE_NEW_CASE_ERROR = <IAppNotification>{text: 'ERROR: Es konnte kein neuer Fall angelegt werden ', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_CASELIST_LOADER};
export const NOTIFICATION_MESSAGE_CASELIST_CREATE_NEW_CASE_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Es konnte kein neuer Fall angelegt werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_CASELIST_LOADER};

// messages for slot CASEDETAIL_LOADER
export const NOTIFICATION_MESSAGE_CASEDETAIL_LOADER_LOADING = <IAppNotification>{text: 'Die Fall-Daten werden vom Server abgerufen', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_CASEDETAIL_LOADER};
export const NOTIFICATION_MESSAGE_CASEDETAIL_LOADER_ERROR = <IAppNotification>{text: 'ERROR: Die Fall-Daten konnten nicht vom Server übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_CASEDETAIL_LOADER};
export const NOTIFICATION_MESSAGE_CASEDETAIL_LOADER_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Fall-Daten konnte nicht vom Server übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_CASEDETAIL_LOADER};
export const NOTIFICATION_MESSAGE_CASEDETAIL_SAVING = <IAppNotification>{text: 'Die Änderungen der Fall-Daten werden gespeichert', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_CASEDETAIL_LOADER};
export const NOTIFICATION_MESSAGE_CASEDETAIL_SAVE_ERROR = <IAppNotification>{text: 'ERROR: Die Änderungen der Fall-Daten konnten nicht gespeichert werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_CASEDETAIL_LOADER};
export const NOTIFICATION_MESSAGE_CASEDETAIL_SAVE_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Änderungen der Fall-Daten konnten nicht gespeichert werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_CASEDETAIL_LOADER};

// messages for slot TEAMMEMBER
export const NOTIFICATION_MESSAGE_TEAMMEMBER_LOADING = <IAppNotification>{text: 'Die Team-Mitlglieder werden vom Server abgerufen', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_TEAMMEMBER};
export const NOTIFICATION_MESSAGE_TEAMMEMBER_LOAD_ERROR = <IAppNotification>{text: 'ERROR: Die Team-Mitglieder konnten nicht vom Server übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_TEAMMEMBER};
export const NOTIFICATION_MESSAGE_TEAMMEMBER_LOAD_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Team-Mitglieder konnte nicht vom Server übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_TEAMMEMBER};
export const NOTIFICATION_MESSAGE_TEAMMEMBER_SAVING = <IAppNotification>{text: 'Die Team-Änderungen werden gespeichert', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_TEAMMEMBER};
export const NOTIFICATION_MESSAGE_TEAMMEMBER_SAVE_ERROR = <IAppNotification>{text: 'ERROR: Die Team-Änderungen konnten nicht gespeichert werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_TEAMMEMBER};
export const NOTIFICATION_MESSAGE_TEAMMEMBER_SAVE_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Team-Änderungen konnten nicht gespeichert werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_TEAMMEMBER};

// messages for slot NOTE
export const NOTIFICATION_MESSAGE_NOTE_LOADING = <IAppNotification>{text: 'Die Notizen werden vom Server abgerufen', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_NOTE};
export const NOTIFICATION_MESSAGE_NOTE_LOAD_ERROR = <IAppNotification>{text: 'ERROR: Die Notizen konnten nicht vom Server übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_NOTE};
export const NOTIFICATION_MESSAGE_NOTE_LOAD_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Notizen konnte nicht vom Server übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_NOTE};
export const NOTIFICATION_MESSAGE_NOTE_SAVING = <IAppNotification>{text: 'Die Notiz-Änderungen werden gespeichert', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_NOTE};
export const NOTIFICATION_MESSAGE_NOTE_SAVE_ERROR = <IAppNotification>{text: 'ERROR: Die Notiz-Änderungen konnten nicht gespeichert werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_NOTE};
export const NOTIFICATION_MESSAGE_NOTE_SAVE_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Notiz-Änderungen konnten nicht gespeichert werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_NOTE};

// messages for slot PERSONLIST_LOADER
export const NOTIFICATION_MESSAGE_PERSONLIST_LOADER_LOADING = <IAppNotification>{text: 'Die Personen-Liste wird vom Server abgerufen', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_PERSONLIST_LOADER};
export const NOTIFICATION_MESSAGE_PERSONLIST_LOADER_ERROR = <IAppNotification>{text: 'ERROR: Die Personen-Liste konnte nicht übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_PERSONLIST_LOADER};
export const NOTIFICATION_MESSAGE_PERSONLIST_LOADER_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Personen-Liste konnte nicht übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_PERSONLIST_LOADER};

// messages for slot PERSONLIST_FOR_CASE_LOADER
export const NOTIFICATION_MESSAGE_PERSONLIST_FOR_CASE_LOADER_LOADING = <IAppNotification>{text: 'Die Personen-Liste für den Fall wird vom Server abgerufen', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_PERSONLIST_FOR_CASE_LOADER};
export const NOTIFICATION_MESSAGE_PERSONLIST_FOR_CASE_LOADER_ERROR = <IAppNotification>{text: 'ERROR: Die Personen-Liste für den Fall konnte nicht übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_PERSONLIST_FOR_CASE_LOADER};
export const NOTIFICATION_MESSAGE_PERSONLIST_FOR_CASE_LOADER_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Personen-Liste für den Fall konnte nicht übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_PERSONLIST_FOR_CASE_LOADER};

// messages for slot PERSONDETAIL_LOADER
export const NOTIFICATION_MESSAGE_PERSONDETAIL_LOADER_LOADING = <IAppNotification>{text: 'Die Personen-Daten werden vom Server abgerufen', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_PERSONDETAIL_LOADER};
export const NOTIFICATION_MESSAGE_PERSONDETAIL_LOADER_ERROR = <IAppNotification>{text: 'ERROR: Die Personen-Daten konnten nicht vom Server übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_PERSONDETAIL_LOADER};
export const NOTIFICATION_MESSAGE_PERSONDETAIL_LOADER_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Personen-Daten konnte nicht vom Server übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_PERSONDETAIL_LOADER};

// messages for slot LOGIN
export const NOTIFICATION_MESSAGE_LOGIN_IN_PROGRESS = <IAppNotification>{text: 'Die Login-Daten werden überprüft', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_LOGIN};
export const NOTIFICATION_MESSAGE_LOGIN_FAILED_ERROR = <IAppNotification>{text: 'ERROR: Login fehlgeschlagen', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_LOGIN};
export const NOTIFICATION_MESSAGE_LOGIN_FAILED_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Login fehlgeschlagen', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_LOGIN};

// messages for slot CALENDAR
export const NOTIFICATION_MESSAGE_LOAD_CALENDAR_ENTRY_IN_PROGRESS = <IAppNotification>{text: 'Die Aufgabeliste wird geladen', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_CALENDAR};
export const NOTIFICATION_MESSAGE_LOAD_CALENDAR_ENTRY_FAILED_ERROR = <IAppNotification>{text: 'ERROR: Die Aufgabeliste konnte nicht geladen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_CALENDAR};
export const NOTIFICATION_MESSAGE_LOAD_CALENDAR_ENTRY_FAILED_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Aufgabenliste konnte nicht geladen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_CALENDAR};
export const NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_IN_PROGRESS = <IAppNotification>{text: 'Die Änderungen werden gespeichert', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_CALENDAR};
export const NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_FAILED_ERROR = <IAppNotification>{text: 'ERROR: Die Änderungen konnten nicht gespeichert werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_CALENDAR};
export const NOTIFICATION_MESSAGE_SAVE_CALENDAR_ENTRY_FAILED_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Änderungen konnten nicht gespeichert werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_CALENDAR};

// messages for slot USERS
export const NOTIFICATION_MESSAGE_LOAD_USERS_IN_PROGRESS = <IAppNotification>{text: 'Die Mitarbeiterdaten werden geladen', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_USERS};
export const NOTIFICATION_MESSAGE_LOAD_USERS_FAILED_ERROR = <IAppNotification>{text: 'ERROR: Die Mitarbeiterdaten konnten nicht geladen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_USERS};
export const NOTIFICATION_MESSAGE_LOAD_USERS_FAILED_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Mitarbeiterdaten konnten nicht geladen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_USERS};
export const NOTIFICATION_MESSAGE_SAVE_USERS_IN_PROGRESS = <IAppNotification>{text: 'Die Änderungen werden gespeichert', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_USERS};
export const NOTIFICATION_MESSAGE_SAVE_USERS_FAILED_ERROR = <IAppNotification>{text: 'ERROR: Die Änderungen konnten nicht gespeichert werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_USERS};
export const NOTIFICATION_MESSAGE_SAVE_USERS_FAILED_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Änderungen konnten nicht gespeichert werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_USERS};

// messages for slot BUNDESANZEIGER
export const NOTIFICATION_MESSAGE_BUNDESANZEIGER_LOADING = <IAppNotification>{text: 'Die Bundesanzeiger-Fälle werden abgerufen', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_BUNDESANZEIGER};
export const NOTIFICATION_MESSAGE_BUNDESANZEIGER_LOAD_ERROR = <IAppNotification>{text: 'ERROR: Die Bundesanzeiger-Fälle konnten nicht übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_BUNDESANZEIGER};
export const NOTIFICATION_MESSAGE_BUNDESANZEIGER_LOAD_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Bundesanzeiger-Fälle konnten nicht übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_BUNDESANZEIGER};
export const NOTIFICATION_MESSAGE_BUNDESANZEIGER_SAVING = <IAppNotification>{text: 'Die Änderungen werden gespeichert', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_BUNDESANZEIGER};
export const NOTIFICATION_MESSAGE_BUNDESANZEIGER_SAVE_ERROR = <IAppNotification>{text: 'ERROR: Die Änderungen für die Bundesanzeiger-Fälle konnten nicht übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_BUNDESANZEIGER};
export const NOTIFICATION_MESSAGE_BUNDESANZEIGER_SAVE_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Änderungen für die Bundesanzeiger-Fälle konnten nicht übertragen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_BUNDESANZEIGER};

// messages for slot NVZ
export const NOTIFICATION_MESSAGE_LOAD_NVZ_IN_PROGRESS = <IAppNotification>{text: 'Die NVZ-Daten werden geladen', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_NVZ};
export const NOTIFICATION_MESSAGE_LOAD_NVZ_FAILED_ERROR = <IAppNotification>{text: 'ERROR: Die NVZ-Daten konnten nicht geladen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_NVZ};
export const NOTIFICATION_MESSAGE_LOAD_NVZ_FAILED_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die NVZ-Daten konnten nicht geladen werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_NVZ};
export const NOTIFICATION_MESSAGE_SAVE_NVZ_IN_PROGRESS = <IAppNotification>{text: 'Die Änderungen werden gespeichert', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_NVZ};
export const NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR = <IAppNotification>{text: 'ERROR: Die Änderungen konnten nicht gespeichert werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_NVZ};
export const NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_TIMEOUT = <IAppNotification>{text: 'TIMEOUT: Die Änderungen konnten nicht gespeichert werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_NVZ};

// messages for slot FIND_NOTE
export const NOTIFICATION_MESSAGE_FIND_NOTE_IN_PROGRESS = <IAppNotification>{text: 'Die Notiz wird gesucht', icon_class: 'fa fa-spinner fa-spin fa-fw', css_class: 'alert-success', slot: NOTIFICATION_SLOT_FIND_NOTE};
export const NOTIFICATION_MESSAGE_FIND_NOTE_FAILED_ERROR = <IAppNotification>{text: 'FEHLER: Die Notiz konnte nicht gefunden werden', icon_class: 'fa fa-exclamation-triangle', css_class: 'alert-danger', slot: NOTIFICATION_SLOT_FIND_NOTE};
