import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {StammbaumService} from '../../services/stammbaum.service';
import {CaseService} from '../../services/case.service';
import {Subscription} from 'rxjs';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import {ShowDatePipe} from '../../tools/show-date-pipe/show-date-pipe';
import {Store} from '@ngrx/store';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {IStammbaumMarriage} from '../stammbaum-marriage.interface';

@Component({
  selector: 'stammbaum-select-marriage',
  templateUrl: 'stammbaum-exchange-marriage.component.html',
  styleUrls: ['./stammbaum-exchange-marriage.component.css']
})
export class StammbaumExchangeMarriageComponent implements OnInit, OnDestroy {
  stammbaumSub: Subscription;
  marriages: IStammbaumMarriage[] = [];
  marriageToExchange: IStammbaumMarriage = null;

  constructor(private ngbActiveModal: NgbActiveModal,
              private store: Store<{stammbaum: IStammbaumState}>,
              private caseService: CaseService,
              private showDatePipe: ShowDatePipe,
              private stammbaumService: StammbaumService) {}

  ngOnInit(): void {
    this.stammbaumSub = this.stammbaumService.loadStammbaumPersonsAndMarriagesWithoutVersion(this.caseService.getActiveCaseId(), true).subscribe(
      (result) => {
        console.log(result.marriages);
        let mars = result.marriages.filter(x => x.deleted);
        const p1_old = this.marriageToExchange.persons[0];
        const p2_old = this.marriageToExchange.persons[1];
        mars = mars.filter(x => { // filter marriages, display only those with one missing partner or same partners as original one
          if (!x.persons || x.persons.length !== 2) { return true; }
          if (x.persons[0].id !== p1_old.id && x.persons[0].id !== p2_old.id) { return false; }
          if (x.persons[1].id !== p1_old.id && x.persons[1].id !== p2_old.id) { return false; }
          return true;
        });
        this.marriages = mars;
        },
      (error) => {},
      () => {}
    );
  }

  ngOnDestroy(): void {
    this.stammbaumSub.unsubscribe();
  }

  printDate(date: string) {
    return this.showDatePipe.transform(date);
  }

  printMarriage() {
    const ehe_eins = this.getPersonName(this.marriageToExchange.persons[0]);
    const ehe_zwei = this.getPersonName(this.marriageToExchange.persons[1]);
    return `${ehe_eins} x ${ehe_zwei}`;
  }

  getPersonName(person: IStammbaumPerson) {
    if (!person) { return ''; }
    const gebdat = this.showDatePipe.transform(person.geburtsdatum);
    return `${person.nachname} ${person.vorname} ${gebdat}`;
  }

  exchange(m: IStammbaumMarriage) {
    this.stammbaumService.exchangeStammbaumMarriage(m.id, this.marriageToExchange.id).subscribe(
      (result) => {
        this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersionsForCase(true));
        this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(this.caseService.getActiveCaseId()));
        this.ngbActiveModal.close();
      },
      (error) => { console.log(error); },
      () => {}
    );
  }

  close() {
    this.ngbActiveModal.close();
  }
}
