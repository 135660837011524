import { Directive, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector : 'focus'
})
export class FocusDirective {

  constructor(public renderer: Renderer2, public elementRef: ElementRef) {
  }

  ngOnInit() {
    this.renderer.selectRootElement(this.elementRef).focus();
    this.renderer.setStyle(this.elementRef, 'background-color', 'yellow');
  }
}
