import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {
  MARGIN_HEIGHT_PX,
  MARGIN_WIDTH_PX,
  PERSON_MIN_HEIGHT_PX,
  PERSON_WIDTH_PX,
  StammbaumDisplayService
} from '../../services/stammbaum-display.service';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as StammbaumActions from '../../store/stammbaum.actions';


@Component({
  selector: 'stammbaum-tree-map-svg',
  templateUrl: './stammbaum-tree-map-svg.component.html',
  styleUrls: ['./stammbaum-tree-map-svg.component.css']
})

export class StammbaumTreeMapSvgComponent implements OnInit, OnDestroy {
  connections_m = [];
  connections_c = [];
  height = 0;
  width = 0;
  original_height: number;
  original_width: number;
  zoomFactor = 0.6;
  scrollPos = {x: 0, y: 0, height: 0, width: 0};
  scrollPosSub: Subscription;
  _stammbaumState: IStammbaumState;
  @Input() set stammbaumState(stammbaumState: IStammbaumState) {
    this._stammbaumState = stammbaumState;
    if (stammbaumState.stammbaum.connections) {
      this.connections_m = stammbaumState.stammbaum.connections.m;
      this.connections_c = stammbaumState.stammbaum.connections.c;
    }
    if (stammbaumState.stammbaum.version) {
      this.original_width = (stammbaumState.stammbaum.version.width + 1) * (PERSON_WIDTH_PX + MARGIN_WIDTH_PX);
      this.original_height = (stammbaumState.stammbaum.version.height + 1) * (PERSON_MIN_HEIGHT_PX + MARGIN_HEIGHT_PX);
    }
    this.rescale();
  }

  getViewBox() {
    return '0 0 ' + this.original_width + ' ' + this.original_height;
  }

  constructor(private stammbaumDisplayService: StammbaumDisplayService,
              private store: Store<{stammbaum: IStammbaumState}>) {
    this.scrollPosSub = this.stammbaumDisplayService.scrollPos.subscribe(
      (pos) => {
        this.scrollPos = {
          x: pos.x,
          y: pos.y,
          height: pos.height, // / (this.zoomFactor / 5),
          width: pos.width // (this.zoomFactor / 5)
        };
      }
    );
  }

  goTo($event) {
    return; // todo implement
    this.store.dispatch(new StammbaumActions.StammbaumScrollToPos({
      x: $event.clientX / this.width * (5 / this.zoomFactor),
      y: $event.clientY / this.height * (5 / this.zoomFactor)
    }));
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.scrollPosSub.unsubscribe();
  }

  rescale() {
    this.width = this.original_width * (this.zoomFactor / 5);
    this.height = this.original_height * (this.zoomFactor / 5);
  }
}
