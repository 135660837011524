import {Component, Input, OnDestroy} from '@angular/core';
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {INote} from "../note.interface";
import {NoteSinglePopupComponent} from "../note-single-popup/note-single-popup.component";
import {IUserState} from "../../store/user-state.interface";
import {IUser} from "../../models/user.interface";
import {ICaseState} from "../../store/case-state.interface";
import {ICase} from "../../cases/case.interface";
import {CaseService} from "../../services/case.service";
import {UserService} from '../../services/user.service';

@Component({
  selector: 'note-single-display',
  templateUrl: './note-single-display.component.html',
  styleUrls: ['./note-single-display.component.css']
})

export class NoteSingleDisplayComponent implements OnDestroy{
  @Input() note: INote;
  users: IUser[] = [];
  usersStateSub: Subscription;
  cases: ICase[];
  casesSub: Subscription;

  constructor(private modalService: NgbModal,
              private caseService: CaseService,
              public userService: UserService,
              private store: Store<{users: IUserState, cases: ICaseState}>)
  {
    this.usersStateSub = this.store.select('users').subscribe(
      (usersState) => {
        this.users = usersState.users;
      }
    );
    this.casesSub = this.store.select('cases').subscribe(
      (cases) => {
        this.cases = cases.cases;
      }
    )
  }

  openDetails() {
    const modalRef = this.modalService.open(NoteSinglePopupComponent);
    modalRef.componentInstance.note = this.note;
    const caseExists = this.cases && this.cases.find(x => +x.id === +this.note.case_id);
    modalRef.componentInstance.caseExists = caseExists;
  }

  getNoteCaseText(note: INote) {
    let text = note.case_id.toString();
    if (note.case_name) {
      text = note.case_name + ' ' + text;
    }
    return text;
  }

  getNotePersonText(note: INote) {
    let text = note.person_id.toString();
    if (note.person_name) {
      text = note.person_name + ' ' + text;
    }
    return text;
  }

  ngOnDestroy() {
    this.usersStateSub.unsubscribe();
    this.casesSub.unsubscribe();
  }
}
