import {Component, Input} from "@angular/core";
import {IUi} from "../../models/ui.interface";
import {IModulePages, MODULES, MODULE_PAGES} from "../../routing/routes.model";

@Component({
  selector: 'addressbook-entry',
  templateUrl: './addressbook-entry.component.html',
  styleUrls: ['./addressbook-entry.component.css']
})

export class AddressbookEntryComponent {
  MODULE_PAGES: IModulePages;
  @Input() ui: {ui: IUi};

  constructor() {}

  ngOnInit() {
    this.MODULE_PAGES = MODULE_PAGES;
  }
}
