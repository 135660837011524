import {Injectable } from '@angular/core';
import {Store} from '@ngrx/store';
import {HttpClient} from '@angular/common/http';
import {ConstantsService } from './constants.service';
import {RoutingService} from '../routing/routing.service';
import {MODULES} from '../routing/routes.model';
import * as AuthUserActions from '../store/auth.actions';
import {IAuthUser} from '../models/auth-user.interface';
import {IUser} from '../models/user.interface';
import {
  IAppNotification,
  NOTIFICATION_MESSAGE_LOGIN_FAILED_ERROR, NOTIFICATION_MESSAGE_LOGIN_FAILED_TIMEOUT,
  NOTIFICATION_MESSAGE_LOGIN_IN_PROGRESS,
  NOTIFICATION_SLOT_LOGIN
} from '../app-notifications/app-notification.interface';
import * as UiActions from '../store/ui.actions';
import * as CaseActions from '../store/case.actions';
import * as AppNotificationActions from '../store/app-notification.actions';
import * as NetworkActions from '../store/network.actions';
import * as UserActions from '../store/user.actions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FileUploadService} from './file-upload.service';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';

const IDLE_TIME_LIMIT_AUTOMATIC_RE_LOGIN: number = 1000 * 60 * 60; // 1000 * 60 * 60 => 1 hour
const IDLE_TIME_LIMIT_AUTOMATIC_LOGOUT: number = 1000 * 60 * 60; //  1000 * 60 * 60 => 1 hour

@Injectable()
export class AuthenticationService {

  loginAttemptInProgress = false;
  tryGetTokenRunning = false;
  tryGetUserDataRunning = false;
  isLoggedIn = false;

  constructor(private http: HttpClient,
              private router: Router,
              private store: Store<{appNotification: IAppNotification, authUser: IAuthUser}>,
              private routingService: RoutingService,
              private modalService: NgbModal,
              private constantsService: ConstantsService,
              private fileUploadService: FileUploadService) {}


  tryLogin(user: IUser) {
    if (this.loginAttemptInProgress) { // if there is already a login attempt being progressed, just ignore the new request
      return;
    }
    this.store.dispatch(new AppNotificationActions.UpdateAppNotification(NOTIFICATION_MESSAGE_LOGIN_IN_PROGRESS));
    this.loginAttemptInProgress = true;
    this.cleanupUserData();
    this.tryGetTokenRunning = true;
    this.getToken(user).subscribe(
      (data) => this.getTokenSuccessCallback(data),
              (err) => this.getTokenErrorCallback(err),
              () => this.getTokenTimeoutCallback()
      );
  }

  loginFromLocalStorage() {
    const user: IUser = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');
    const lastActionTimestamp: number = +localStorage.getItem('lastActionTimestamp');
    const timeDiff: number = Date.now() - lastActionTimestamp;
    if (token && user && timeDiff < IDLE_TIME_LIMIT_AUTOMATIC_RE_LOGIN) { // if last action was within last hour, automatically log in
      this.completeLogin(user, token, true);
    }
  }

  getToken (user: IUser) {
    const body = JSON.stringify(user);
    return this.http.post(`${this.constantsService.getApiEndpoint()}/v2/api-token-auth/`, body, {headers: this.constantsService.getHttpLoginOptions()});
  }

  getUserByToken(token) {
    return this.http.get<IUser>(`${this.constantsService.getApiEndpoint()}/v2/me/`, {headers: this.constantsService.getHttpOptions(token)});
  }

  getTokenSuccessCallback(data: any) {
    this.tryGetTokenRunning = false;
    this.tryGetUserDataRunning = true;
    const token = data.token;
    // get user data
    this.getUserByToken(token).subscribe(
        (user) => this.getUserSuccessCallback(user, token),
        (err) => this.getUserErrorCallback(err),
        () => this.getUserTimeoutCallback()
      );
  }

  getTokenErrorCallback(error) {
    this.tryGetTokenRunning = false;
    this.loginAttemptInProgress = false;
    this.store.dispatch(new AppNotificationActions.UpdateAppNotification(NOTIFICATION_MESSAGE_LOGIN_FAILED_ERROR));
  }

  getTokenTimeoutCallback() {
    if (this.tryGetTokenRunning) {
      this.tryGetTokenRunning = false;
      this.loginAttemptInProgress = false;
      this.store.dispatch(new AppNotificationActions.UpdateAppNotification(NOTIFICATION_MESSAGE_LOGIN_FAILED_TIMEOUT));
    }
  }

  getUserSuccessCallback(user: IUser, token: string) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_LOGIN));
    this.tryGetUserDataRunning = false;
    this.completeLogin(user, token);
    this.loginAttemptInProgress = false;

    // update token for file upload after login!
    const headers = [this.constantsService.getHeadersForFileUpload()];
    this.fileUploadService.uploader.setOptions({headers: headers})
  }

  completeLogin(user: IUser, token: string, from_storage= false) {
    this.isLoggedIn = true;
    const currentTimestamp: number = Date.now();
    this.saveTokenAndUser(token, user, currentTimestamp);
    this.http.get<string[]>(`${this.constantsService.getApiEndpoint()}/v5/user-notifications/`, {headers: this.constantsService.getHttpOptions(token)}).subscribe(
      (result) => { this.store.dispatch(new UiActions.ReplaceUserNotifications(result))},
      (error) => {},
      () => {}
    );
    this.routingService.navigateToModule({moduleName: MODULES.DASHBOARD});
    this.store.dispatch(new AuthUserActions.SetAuthUserData(<IAuthUser>{loggedIn: true, user: <IUser>user, lastActionTimestamp: currentTimestamp}));
    if (!from_storage) { // if user logged in with credentials instead of local storage date, redirect to initial page
      this.router.navigate(['/']);
    }
    if (from_storage) { // if user logged in from local storage check if redirect is neccessary or if the requested page should be shown
      const rSub: Subscription = this.router.events.subscribe(
        (routeSnap) => {
          if (routeSnap instanceof NavigationStart) {
            if (routeSnap.url && routeSnap.url.includes('stammbaum')) {
              this.router.navigate(['/']);
            }
            rSub.unsubscribe();
          }
        }
      );
    }
  }

  getUserErrorCallback(error) {
    this.tryGetUserDataRunning = false;
    this.loginAttemptInProgress = false;
    this.store.dispatch(new AppNotificationActions.UpdateAppNotification(NOTIFICATION_MESSAGE_LOGIN_FAILED_ERROR));
  }

  getUserTimeoutCallback() {
    if (this.tryGetUserDataRunning) {
      this.tryGetUserDataRunning = false;
      this.loginAttemptInProgress = false;
      this.store.dispatch(new AppNotificationActions.UpdateAppNotification(NOTIFICATION_MESSAGE_LOGIN_FAILED_TIMEOUT));
    }
  }

  saveTokenAndUser(token: string, user: IUser, currentTimestamp: number) {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('lastActionTimestamp', JSON.stringify(currentTimestamp));
    localStorage.setItem('token', token);
  }

  logout() {
    this.isLoggedIn = false;
    this.cleanupUserData();
    this.router.navigate(['/login']);
  }

  cleanupUserData() {
    this.store.dispatch(new UiActions.SetActivePagesToInitialState());
    this.store.dispatch(new AuthUserActions.ClearAuthUserData());
    this.store.dispatch(new CaseActions.DeleteAllCaseData());
    this.store.dispatch(new UserActions.DeleteAllUserData());
    // todo check if there is more data to clean
    this.store.dispatch(new NetworkActions.ResetAll());
    localStorage.clear();
  }

  checkIfAuthorized() {
    const lastActionTimestamp: number = +localStorage.getItem('lastActionTimestamp');
    const timeDiff: number = Date.now() - lastActionTimestamp;
    if (localStorage.getItem('token') === null || timeDiff > IDLE_TIME_LIMIT_AUTOMATIC_LOGOUT)  {
      // if there is no token available or the user was idle too long force logout
      this.logout();
      return false;
    }
    // else update the timestamp in store and local storage and proceed
    const currentTimestamp: number = Date.now();
    localStorage.setItem('lastActionTimestamp', JSON.stringify(currentTimestamp));
    this.store.dispatch(new AuthUserActions.UpdateLastActionTimestamp(lastActionTimestamp));
    return true;
  }
}
