import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})

export class ConfirmModalComponent implements OnInit {
  message = '';
  title = '';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  cancel() {
    this.activeModal.close(false);
  }

  confirm() {
    this.activeModal.close(true);
  }
}
