import {Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NVZService} from '../../services/nvz.service';
import {INVZLand} from '../nvz-land.interface';

@Component({
  selector: 'nvz-laender-form',
  templateUrl: './nvz-laender-form.component.html',
  styleUrls: ['./nvz-laender-form.component.css']
})
export class NVZLaenderFormComponent implements OnInit, AfterViewInit {
  @ViewChild('name', { static: true }) nameElement: ElementRef;
  @ViewChild('comment', { static: true }) commentElement: ElementRef;
  @HostListener('window:keydown', ['$event'])

  editMode = false;
  land: INVZLand = <INVZLand>{};
  form: FormGroup;

  keyEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.key === 'Enter') {
      this.save();
    }
  }

  constructor(private activeModal: NgbActiveModal,
              private nvzService: NVZService) { }

  ngOnInit() {
    this.form = new FormGroup({
        'name': new FormControl(null, [Validators.required, Validators.maxLength(30)]),
        'comment': new FormControl(null, [Validators.maxLength(100)]),
      });
    if (this.editMode) {
      this.form.setValue({
        name: this.land.name,
        comment: this.land.comment,
      })
    }
  }

  ngAfterViewInit() {
    this.nameElement.nativeElement.focus();
  }

  save() {
    if (this.form.valid) {
      this.land.name = this.form.controls.name.value;
      this.land.comment = this.form.controls.comment.value;
      this.activeModal.close(this.land);
    }
  }

  back() {
    this.activeModal.close();
  }
}
