import {Component, Input} from "@angular/core";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IAddressbookInstitution} from "../addressbook-institution.interface";
import {AddressbookService} from "../../services/addressbook.service";
import {IAddressbookEntryState} from "../../store/addressbook-entry-state.interface";

@Component({
  selector: 'addressbook-detail-popup',
  templateUrl: './addressbook-detail-popup.component.html',
  styleUrls: ['./addressbook-detail-popup.component.css']
})

export class AddressbookDetailPopupComponent {
  id: number = 0;
  institutionSub: Subscription;
  institution: IAddressbookInstitution = <IAddressbookInstitution>{};
  contactTypes; // todo typing, change, refactor to ngrx/store and loading on change from table/backend!
  institutionTypes; // todo typing, change, refactor to ngrx/store and loading on change from table/backend!
  addressRegions; // todo typing, change, refactor to ngrx/store and loading on change from table/backend!

  constructor(public activeModal: NgbActiveModal,
              private addressbookService: AddressbookService,
              private store: Store<{addressbookEntry: IAddressbookEntryState}>) {}

  ngOnInit() {
    this.addressbookService.loadInstitutionFromServer(this.id);
    this.addressbookService.institutionTypesObs.subscribe(
      (instTypes) => {this.institutionTypes = instTypes;} // todo dirty hack, remove/replace
    );
    this.addressbookService.addressRegionsObs.subscribe(
      (addrRegions) => {this.addressRegions = addrRegions;} // todo dirty hack, remove/replace
    );
    this.addressbookService.contactTypesObs.subscribe(
      (contTypes) => {this.contactTypes = contTypes;} // todo dirty hack, remove/replace
    );
    this.institutionSub = this.store.subscribe(
      (institution) => { this.institution = institution.addressbookEntry.activeAddressbookEntry;}
    );
  }

  cancel() {
    this.activeModal.close(null);
  }

  edit() {
    this.activeModal.close(this.institution);
  }

  ngOnDestroy() {
    this.institutionSub.unsubscribe();
  }

  printContactType(type_id) {
    if (this.contactTypes) {
      let type = this.contactTypes.find(x => x.id == type_id);
      if (type) {
        return type.name;
      }
      else {
        return "";
      }
    }
  }

  printInstitutionType(type_id) {
    if (this.institutionTypes) {
      let type = this.institutionTypes.find(x => x.id == type_id);
      if (type) {
        return type.name;
      }
      else {
        return "";
      }
    }
  }

  printAddressRegion(region_id) {
    if (this.addressRegions) {
      let region = this.addressRegions.find(x => x.id == region_id);
      if (region) {
        return region.name;
      }
      else {
        return "";
      }
    }
  }

}
