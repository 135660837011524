import {ToolsModule} from '../tools/tools.module';
import {NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import {NgbAccordionModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {QuillModule} from 'ngx-quill'
import {NoteSingleDisplayComponent} from './note-single-display/note-single-display.component';
import {NoteSinglePopupComponent} from './note-single-popup/note-single-popup.component';
import {NoteListMaxComponent} from './note-list-max/note-list-max.component';
import { DatepickerPopupComponent } from './datepicker-popup/datepicker-popup.component';

@NgModule({
  entryComponents: [
    NoteSinglePopupComponent,
    DatepickerPopupComponent
  ],
  declarations: [
    NoteListMaxComponent,
    NoteSingleDisplayComponent,
    NoteSinglePopupComponent,
    DatepickerPopupComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ToolsModule,
        NgbAccordionModule,
        NgbAlertModule,
        NgbModule,
        QuillModule,
        NgxPageScrollCoreModule,
        ReactiveFormsModule
    ],
  exports: [
    NoteListMaxComponent,
    NoteSingleDisplayComponent
  ]
})

export class NotesModule {}
