import {Action} from "@ngrx/store";
import {INote} from "../notes/note.interface";

export const REPLACE_SEARCH_RESULT_NOTES = 'REPLACE_SEARCH_RESULT_NOTES';
export const REPLACE_SEARCH_RESULT_TEL = 'REPLACE_SEARCH_RESULT_TEL';

export class ReplaceSearchResultNotes implements Action {
  readonly type = REPLACE_SEARCH_RESULT_NOTES;
  constructor(public payload: INote[]) {}
}

export class ReplaceSearchResultTel implements Action {
  readonly type = REPLACE_SEARCH_RESULT_TEL;
  constructor(public payload: {type: string, id: number, number: string, text: string}[]) {}
}

export type SearchResultActions =
  ReplaceSearchResultNotes | ReplaceSearchResultTel;
