import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {IUrkundenFileRef} from '../urkunden-file-ref.interface';
import {AddPersonAndMarriageComponent} from '../add-person-and-marriage/add-person-and-marriage.component';
import {PersonType, StammbaumService} from '../../services/stammbaum.service';
import {AddChildComponent} from '../add-child/add-child.component';
import {HEIGHT_OF_CONTROL_BUTTONS, PERSON_WIDTH_PX, StammbaumDisplayService} from '../../services/stammbaum-display.service';
import {Subscription} from 'rxjs';
import {EditPersonSingleFieldComponent} from '../edit-person-single-field/edit-person-single-field.component';
import {StammbaumTreeEditPersonComponent} from '../stammbaum-tree-edit-person/stammbaum-tree-edit-person.component';
import {ShowDatePipe} from '../../tools/show-date-pipe/show-date-pipe';
import {IStammbaumMarriage} from '../stammbaum-marriage.interface';
import {StammbaumTreeEditMarriageComponent} from '../stammbaum-tree-edit-marriage/stammbaum-tree-edit-marriage.component';
import {StammbaumTreeSelectEditMarriageComponent} from '../stammbaum-tree-select-edit-marriage/stammbaum-tree-select-edit-marriage.component';
import {StammbaumTreeUrkundenUploadComponent} from '../stammbaum-tree-urkunden-upload/stammbaum-tree-urkunden-upload.component';
import {StammbaumTreeUrkundenPopupComponent} from '../stammbaum-tree-urkunden-popup/stammbaum-tree-urkunden-popup.component';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';
import {StammbaumExchangePersonComponent} from '../stammbaum-exchange-person/stammbaum-exchange-person.component';
import {StammbaumExchangeMarriageComponent} from '../stammbaum-exchange-marriage/stammbaum-exchange-marriage.component';
import {IStammbaumVersion} from '../stammbaum-version.interface';
import {HeirPopupComponent} from '../heir-popup/heir-popup.component';
import {CaseService} from '../../services/case.service';
import Fraction from 'fraction.js';



@Component({
  selector: '[stammbaum-tree-person]',
  templateUrl: './stammbaum-tree-person.component.html',
  styleUrls: ['./stammbaum-tree-person.component.css']
})
export class StammbaumTreePersonComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() person: IStammbaumPerson;
  @Input() version: IStammbaumVersion;
  personDisplayData: IStammbaumPerson;
  originalPersonIfDuplicate: IStammbaumPerson = null;
  @Input() treeViewMode: string;
  @Input() mapOnly = false;
  @ViewChild('contentdiv', {static: false}) contentdivRef: ElementRef;
  width;
  height;
  centerOffsetPix;
  heightOfControlButtons;
  heightSub: Subscription;
  PersonType = PersonType;
  urkunden_for_display: IUrkundenFileRef[] = [];

  constructor(private store: Store<{stammbaum: IStammbaumState}>,
              private showDatePipe: ShowDatePipe,
              private stammbaumService: StammbaumService,
              private stammbaumDisplayService: StammbaumDisplayService,
              private ngbModal: NgbModal,
              private caseService: CaseService) {
    this.width = PERSON_WIDTH_PX;
    this.centerOffsetPix = 0.5 * PERSON_WIDTH_PX;
    this.heightOfControlButtons = HEIGHT_OF_CONTROL_BUTTONS;
  }

  ngOnInit() {
    this.heightSub = this.stammbaumDisplayService.personHeight.subscribe(
      (newHeight) => {
        this.height = newHeight;
      }
    );
    this.updateUrkundenForDisplay();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.person) {
      const inputPersonData = changes.person.currentValue;

      if (!inputPersonData.is_duplicate_of && !inputPersonData.has_duplicate) {
        // just a normal person, copy all
        this.personDisplayData = inputPersonData;
        this.originalPersonIfDuplicate = null;
      }

      if (inputPersonData.has_duplicate) {
        // a normal person that has a duplicate, so only display parents if flag is set
        this.originalPersonIfDuplicate = null;
        const personDisplayData = <IStammbaumPerson>{};
        for (const key of Object.keys(inputPersonData)) {
          if (!['show_children', 'show_parents'].includes(key)) {
            personDisplayData[key] = inputPersonData[key];
          }
        }
        if (inputPersonData.show_parents) {
          personDisplayData.parenthood = inputPersonData.parenthood;
        }
        this.personDisplayData = personDisplayData;
      }

      if (inputPersonData.is_duplicate_of) {
        // it is a duplicate
        const origPersonData = this.stammbaumService.getStammbaumPersonById(inputPersonData.is_duplicate_of);
        this.originalPersonIfDuplicate = origPersonData;
        if (origPersonData) {
          for (const key of Object.keys(origPersonData)) {
            if (!['displayParams', 'x_position', 'y_position', 'has_duplicate', 'is_duplicate_of', 'show_parents', 'show_children', 'id'].includes(key)) {
              inputPersonData[key] = origPersonData[key];
            }
          }
          if (inputPersonData.show_parents) {
            inputPersonData.parenthood = origPersonData.parenthood;
          }
          inputPersonData.displayParams.color = origPersonData.displayParams.color;
        }
        this.personDisplayData = inputPersonData;
      }
    }
    this.updateUrkundenForDisplay();
  }

  ngAfterViewInit() {
    const realHeight = this.contentdivRef.nativeElement.offsetHeight;
    if (realHeight > this.height - this.heightOfControlButtons) { // if real height is higher than card area height, then recalculate
      this.stammbaumDisplayService.setMaxHeightRequired(realHeight);
    }
  }

  ngOnDestroy() {
    this.heightSub.unsubscribe();
  }

  hidePerson() {
    if (this.version.hide_eo_4 && (this.person.erbordnung === 4)) { return true; }
    if (this.version.hide_eo_3 && (this.person.erbordnung === 3)) { return true; }
    return false;
  }

  printVorname() {
    const regExp = /\>([^)]+)\</;
    const matches = regExp.exec(this.personDisplayData.vorname);
    if (!matches) {
      return this.personDisplayData.vorname;
    }
    let vorname = this.personDisplayData.vorname;
    let underlined = matches[0];
    underlined = underlined.replace('<', '');
    underlined = underlined.replace('>', '');
    underlined = '<u>' + underlined + '</u>';
    vorname = vorname.replace(matches[0], underlined);
    return vorname;
  }

  isOrHasDuplicate() {
    if (this.personDisplayData.has_duplicate > 0 || this.personDisplayData.is_duplicate_of > 0) {
      return true;
    }
    return false;
  }

  getEOColor(erbordnung: number) {
    if (erbordnung === 1) {
      return 'red';
    }
    if (erbordnung === 2) {
      return 'orange';
    }
    if (erbordnung === 3) {
      return 'yellow';
    }
    if (erbordnung === 4) {
      return 'green';
    }
    return 'grey';
  }

  displayAddChildrenButton() {
    if (this.personDisplayData.is_duplicate_of) {
      return false;
    }
    if (this.personDisplayData.no_more_partners) {
      return true;
    }
    const mar = this.personDisplayData.marriages_for_stammbaum_person.find(x => x.persons.find(y => !y.no_more_partners));
    // if there is no marriage of this partner with a partner that also can have multiple partner, then there is nothing to display
    if (!mar) {
      return false;
    }
    const pers = mar.persons.find(x => x.id !== this.personDisplayData.id);
    if (pers && pers.x_position > this.personDisplayData.x_position) {
      return false;
    }
    return true;
  }

  displayVuVSend() {
    if ((this.personDisplayData.contract_send_out_date !== '') || (this.personDisplayData.person_type === PersonType['Erbe (v+v verschickt)'])) {
      return true;
    }
    return false;
  }

  displayVuVSigned() {
    if ((this.personDisplayData.signature_date !== '') || (this.personDisplayData.person_type === PersonType['Erbe (v+v unterschrieben)'])) {
      return true;
    }
    return false;
  }

  printVuVSendDatum() {
    if (this.personDisplayData.contract_send_out_date !== '') {
      return this.showDatePipe.transform(this.personDisplayData.contract_send_out_date);
    }
    return '';
  }

  printVuVSignedDatum() {
    if (this.personDisplayData.signature_date !== '') {
      return this.showDatePipe.transform(this.personDisplayData.signature_date);
    }
    return '';
  }

  printMarriageData(): {display: boolean, dataMarriage: string, dataTrauung: string, dataScheidung: string, divorced: boolean, ausserehelich: boolean} {
    let no_more_partners = true;
    if (!this.personDisplayData.marriages_for_stammbaum_person) {
      return {display: false, dataMarriage: '', dataTrauung: '', dataScheidung: '', divorced: false, ausserehelich: false};
    }
    let mar: IStammbaumMarriage;
    if (this.personDisplayData.no_more_partners) { // if a partner has no more other partners, just display his marriage data
      mar = this.personDisplayData.marriages_for_stammbaum_person.find(x => true);
    } else { // if it has (or can have) other partners, it is more complicated
      mar = this.personDisplayData.marriages_for_stammbaum_person.find(x => x.persons.find(y => !y.no_more_partners));
      // if there is no marriage of this partner with a partner that also can have multiple partner, then there is nothing to display
      if (!mar) {
        return {display: false, dataMarriage: '', dataTrauung: '', dataScheidung: '', divorced: false, ausserehelich: false};
      }
      // if there are two partners that can have multiple partners, then display their mutual marriage at the left one
      no_more_partners = false;
    }
    if (mar) {
      const pers = mar.persons.find(x => x.id !== this.personDisplayData.id);
      if (!no_more_partners) { // decide whether to display according to person position
        if (!pers || pers.x_position > this.personDisplayData.x_position) {
          return {display: false, dataMarriage: '', dataTrauung: '', dataScheidung: '', divorced: false, ausserehelich: false};
        }
      }
      let date = '';
      if (mar.date) {
        date = this.showDatePipe.transform(mar.date);
      }
      if (mar.kommentar) {
        date = `${date} ${mar.kommentar}`;
      }
      let trauung = '';
      if (mar.trauungsdatum) {
        trauung = this.showDatePipe.transform(mar.trauungsdatum);
      }
      if (mar.trauungsinfo) {
        trauung = `${trauung} ${mar.trauungsinfo}`;
      }
      let scheidung = '';
      if (mar.divorced_date) {
        scheidung = this.showDatePipe.transform(mar.divorced_date);
      }
      if (pers) {
        return {display: true, dataMarriage: date, dataTrauung: trauung, dataScheidung: scheidung, divorced: mar.divorced, ausserehelich: mar.aussereheliche_partnerschaft};
      }
    }
    return {display: false, dataMarriage: '', dataTrauung: '', dataScheidung: '', divorced: false, ausserehelich: false};
  }

  addEhe() {
    this.store.dispatch(new StammbaumActions.ReplaceMarriageToEditId(null));
    let modalRef: NgbModalRef;
    modalRef = this.ngbModal.open(AddPersonAndMarriageComponent, <NgbModalOptions>{size: 'sm', backdrop: 'static'});
    modalRef.componentInstance.firstPartnerPerson = this.personDisplayData;
    modalRef.result.then(
      (result) => {},
      () => {}
      );
  }

  addChild() {
    let modalRef: NgbModalRef;
    modalRef = this.ngbModal.open(AddChildComponent, <NgbModalOptions>{backdrop: 'static'});
    modalRef.componentInstance.first_parent_id = this.personDisplayData.id;
    modalRef.result.then(
      (result) => {},
      () => {}
    );
  }

  editPersonData() {
    const modalRef = this.ngbModal.open(StammbaumTreeEditPersonComponent, <NgbModalOptions>{size: 'lg', backdrop: 'static'});
    if (!this.person.is_duplicate_of) {
      modalRef.componentInstance.person = this.personDisplayData;
    } else {
      modalRef.componentInstance.person = this.originalPersonIfDuplicate;
    }
  }

  editMarriageData() {
    let marriages = this.personDisplayData.marriages_for_stammbaum_person;
    if (this.personDisplayData.is_duplicate_of) {
      marriages = this.originalPersonIfDuplicate.marriages_for_stammbaum_person;
    }
    if (!marriages || marriages.length === 0) {
      return;
    }
    if (marriages.length === 1) {
      // there is a marriage, and it is only one, so display it directly
      const modalRefMar = this.ngbModal.open(StammbaumTreeEditMarriageComponent, {backdrop: 'static', size: 'lg'});
      modalRefMar.componentInstance.marriage = marriages[0];
      return;
    }
    // there are multiple marriages, so display the select marriage to edit screen
    const modalRefSel = this.ngbModal.open(StammbaumTreeSelectEditMarriageComponent, {backdrop: 'static'});
    modalRefSel.componentInstance.marriages = marriages;
    modalRefSel.result.then(
      (mar) => {
        if (mar) {
          const modalRefMar = this.ngbModal.open(StammbaumTreeEditMarriageComponent, {backdrop: 'static', size: 'lg'});
          modalRefMar.componentInstance.marriage = mar;
        }
      },
      () => {}
    );
  }

  uploadUrkunden() {
    const modalRef = this.ngbModal.open(StammbaumTreeUrkundenUploadComponent, {backdrop: 'static', size: 'lg'});
    modalRef.componentInstance.editMode = false;
    modalRef.componentInstance.version = this.version
    if (!this.person.is_duplicate_of) {
      modalRef.componentInstance.person = this.personDisplayData;
    } else {
      modalRef.componentInstance.person = this.originalPersonIfDuplicate;
    }
  }

  showUrkunden() {
    const urkunden: IUrkundenFileRef[] = [];
    if (!this.personDisplayData.is_duplicate_of) {
      for (const urkunde of this.personDisplayData.urkunden_for_person) {
        urkunden.push(this.stammbaumService.checkIfReferenceDocumentForDisplay(urkunde));
      }
      for (const mar of this.personDisplayData.marriages_for_stammbaum_person) {
        for (const urk of mar.urkunden_for_marriage) {
          if (!urkunden.find(x => x.id === urk.id)) {
            urkunden.push(this.stammbaumService.checkIfReferenceDocumentForDisplay(urk));
          }
        }
      }
    } else {
      for (const urkunde of this.originalPersonIfDuplicate.urkunden_for_person) {
        urkunden.push(this.stammbaumService.checkIfReferenceDocumentForDisplay(urkunde));
      }
      for (const mar of this.originalPersonIfDuplicate.marriages_for_stammbaum_person) {
        for (const urk of mar.urkunden_for_marriage) {
          if (!urkunden.find(x => x.id === urk.id)) {
            urkunden.push(this.stammbaumService.checkIfReferenceDocumentForDisplay(urk));
          }
        }
      }
    }
    const modalRef = this.ngbModal.open(StammbaumTreeUrkundenPopupComponent, <NgbModalOptions>{size: 'lg'});
    if (!this.person.is_duplicate_of) {
      modalRef.componentInstance.person = this.personDisplayData;
    } else {
      modalRef.componentInstance.person = this.originalPersonIfDuplicate;
    }
    modalRef.componentInstance.urkunden = urkunden;
  }

  moveLeft() {
    this.stammbaumService.personMoveLeft(this.personDisplayData.id);
  }

  moveRight() {
    this.stammbaumService.personMoveRight(this.personDisplayData.id);
  }

  flipLeft() {
    this.stammbaumService.personFlipLeft(this.personDisplayData.id);
  }

  flipRight() {
    this.stammbaumService.personFlipRight(this.personDisplayData.id);
  }

  editComment(comment_field: string) {
    const modalRef = this.ngbModal.open(EditPersonSingleFieldComponent, {backdrop: 'static'});
    if (!this.person.is_duplicate_of) {
      modalRef.componentInstance.person = this.personDisplayData;
    } else {
      modalRef.componentInstance.person = this.originalPersonIfDuplicate;
    }
    modalRef.componentInstance.mode = comment_field;
    modalRef.componentInstance.enterToClose = false;
  }

  editBirth() {
    const modalRef = this.ngbModal.open(EditPersonSingleFieldComponent, {backdrop: 'static'});
    if (!this.person.is_duplicate_of) {
      modalRef.componentInstance.person = this.personDisplayData;
    } else {
      modalRef.componentInstance.person = this.originalPersonIfDuplicate;
    }
    modalRef.componentInstance.mode = 'birth';
    modalRef.componentInstance.enterToClose = false;
  }

  editDeath() {
    const modalRef = this.ngbModal.open(EditPersonSingleFieldComponent, {backdrop: 'static'});
    if (!this.person.is_duplicate_of) {
      modalRef.componentInstance.person = this.personDisplayData;
    } else {
      modalRef.componentInstance.person = this.originalPersonIfDuplicate;
    }
    modalRef.componentInstance.mode = 'death';
    modalRef.componentInstance.enterToClose = false;
  }

  editVorname() {
    const modalRef = this.ngbModal.open(EditPersonSingleFieldComponent, {backdrop: 'static'});
    if (!this.person.is_duplicate_of) {
      modalRef.componentInstance.person = this.personDisplayData;
    } else {
      modalRef.componentInstance.person = this.originalPersonIfDuplicate;
    }
    modalRef.componentInstance.mode = 'vorname';
    modalRef.componentInstance.enterToClose = true;
  }

  editNachname() {
    const modalRef = this.ngbModal.open(EditPersonSingleFieldComponent, {backdrop: 'static'});
    if (!this.person.is_duplicate_of) {
      modalRef.componentInstance.person = this.personDisplayData;
    } else {
      modalRef.componentInstance.person = this.originalPersonIfDuplicate;
    }
    modalRef.componentInstance.mode = 'nachname';
    modalRef.componentInstance.enterToClose = false;
  }

  editGeschlecht() {
    const modalRef = this.ngbModal.open(EditPersonSingleFieldComponent, {backdrop: 'static'});
    if (!this.person.is_duplicate_of) {
      modalRef.componentInstance.person = this.personDisplayData;
    } else {
      modalRef.componentInstance.person = this.originalPersonIfDuplicate;
    }
    modalRef.componentInstance.mode = 'geschlecht';
    modalRef.componentInstance.enterToClose = true;
  }

  editReligion() {
    const modalRef = this.ngbModal.open(EditPersonSingleFieldComponent, {backdrop: 'static'});
    if (!this.person.is_duplicate_of) {
      modalRef.componentInstance.person = this.personDisplayData;
    } else {
      modalRef.componentInstance.person = this.originalPersonIfDuplicate;
    }
    modalRef.componentInstance.mode = 'religion';
    modalRef.componentInstance.enterToClose = true;
  }

  delete() {
    const ngbModalRef = this.ngbModal.open(ConfirmModalComponent, {backdrop: 'static'});
    ngbModalRef.componentInstance.message = `Wollen Sie ${this.person.nachname} ${this.person.vorname} wirklich löschen?`;
    ngbModalRef.componentInstance.title = 'Bestätigung';
    ngbModalRef.result.then(
      (result) => {
        if (result) {
          this.store.dispatch(new StammbaumActions.TriggerDeleteStammbaumPerson(this.person.id));
        }
      }
    );
  }

  exchangePerson() {
    const ngbModalRef = this.ngbModal.open(StammbaumExchangePersonComponent, {size: 'lg', backdrop: 'static'});
    ngbModalRef.componentInstance.personToExchange = this.person;
    ngbModalRef.result.then(
      (result) => {
        if (result) {}
      }
    );
  }

  exchangeMarriage() {
    let marriages = this.personDisplayData.marriages_for_stammbaum_person;
    if (this.personDisplayData.is_duplicate_of) {
      marriages = this.originalPersonIfDuplicate.marriages_for_stammbaum_person;
    }
    if (!marriages || (marriages.length < 1) || (marriages.length > 1)) {
      return;
    }
    // there is exactly one marriage
    const ngbModalRef = this.ngbModal.open(StammbaumExchangeMarriageComponent, {size: 'lg', backdrop: 'static'});
    ngbModalRef.componentInstance.marriageToExchange = marriages[0];
    ngbModalRef.result.then(
      (result) => {
        if (result) {}
      }
    );
  }

  disablePerson() {}

  isHeir() {
    return this.stammbaumService.isPersonHeir(this.person);
  }

  showPersonInfo() {
    const ngbModalRef = this.ngbModal.open(HeirPopupComponent);
    ngbModalRef.componentInstance.heir = this.person;
    ngbModalRef.result.then(
      (result) => {
        if (result) {
          this.caseService.goToPersondetailId(this.person.id, `${this.person.nachname} ${this.person.vorname}`);
        }
      }
    );
  }

  fraction(decimalNumber: number) {
    const frac = new Fraction(decimalNumber* 0.01);
      return frac.toFraction();
  }

  getUrkundenUnterArtText(urkunde) {
    return this.stammbaumService.getUrkundenUnterartText(urkunde)
  }

  updateUrkundenForDisplay() {
    if (!this.personDisplayData.is_duplicate_of) {
      this.urkunden_for_display = this.personDisplayData.urkunden_for_person;
      for (const mar of this.personDisplayData.marriages_for_stammbaum_person) {
        for (const urk of mar.urkunden_for_marriage) {
          if (!this.urkunden_for_display.find(x => x.id === urk.id)) {
            this.urkunden_for_display.push(urk);
          }
        }
      }
    } else {
      this.urkunden_for_display = this.originalPersonIfDuplicate.urkunden_for_person;
      for (const mar of this.originalPersonIfDuplicate.marriages_for_stammbaum_person) {
        for (const urk of mar.urkunden_for_marriage) {
          if (!this.urkunden_for_display.find(x => x.id === urk.id)) {
            this.urkunden_for_display.push(urk);
          }
        }
      }
    }
  }
}
