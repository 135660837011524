import {Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {INVZState} from "../../store/nvz-state.interface";
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import {MODULE_PAGES, MODULES} from "../../routing/routes.model";
import * as UiActions from "../../store/ui.actions";
import * as NVZOptions from "../nvz-options";
import {NVZService} from "../../services/nvz.service";
import * as NVZActions from "../../store/nvz.actions";
import * as AppNotificationActions from "../../store/app-notification.actions";
import {
  NOTIFICATION_MESSAGE_LOAD_NVZ_FAILED_ERROR,
  NOTIFICATION_SLOT_NVZ
} from "../../app-notifications/app-notification.interface";
import {INVZEintrag} from "../nvz-eintrag.interface";
import {INVZEintragSearchresult} from "../nvz-eintrag-searchresult.interface";
import {RightsManagementService} from "../../services/rights-management.service";

@Component({
  selector: 'nvz-search',
  templateUrl: './nvz-search.component.html',
  styleUrls: ['./nvz-search.component.css']
})
export class NvzSearchComponent implements OnInit, AfterViewInit {

  @Input() nvzState: INVZState;
  @ViewChild('alle_namen', { static: true }) alle_namenElement: ElementRef;
  @ViewChild('vorname', { static: true }) vornameElement: ElementRef;
  @ViewChild('nachname', { static: true }) nachnameElement: ElementRef;
  @ViewChild('geburtsname', { static: true }) geburtsnameElement: ElementRef;
  @ViewChild('fuzzi', { static: true }) fuzziElement: ElementRef;
  @ViewChild('standesamt', { static: true }) standesamtElement: ElementRef;
  @ViewChild('stadt', { static: true }) stadt: ElementRef;
  @ViewChild('art', { static: true }) artElement: ElementRef;
  @ViewChild('juedisch', { static: true }) juedischElement: ElementRef;
  search_done: boolean;
  form: FormGroup;
  options = NVZOptions.NVZUrkundenArt;
  search_results: INVZEintragSearchresult[] = [];

  constructor(private store: Store<{nvz: INVZState}>,
              private nvzService: NVZService,
              public rightsManagementService: RightsManagementService) { }

  ngOnInit() {
    this.search_done = true;
     this.form = new FormGroup({
        'alle_namen': new FormControl(""),
        'vorname': new FormControl(""),
        'nachname': new FormControl(""),
        'geburtsname': new FormControl(""),
        'fuzzi': new FormControl(""),
        'standesamt': new FormControl(""),
        'stadt': new FormControl(""),
        'art': new FormControl(-1),
        'juedisch': new FormControl(-1)
      });
  }

   ngAfterViewInit() {
    this.alle_namenElement.nativeElement.focus();
  }

  goToAutoparser() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.NAMENSVERZEICHNISSE, page: MODULE_PAGES.NAMENSVERZEICHNISSE.AUTOPARSER}));
  }

  isAllowed() {
    return this.rightsManagementService.isAllowedToEditNVZ();
  }

  isAllowedAutoparser() {
    return this.rightsManagementService.isAllowedAutoparser();
  }

  editMode() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.NAMENSVERZEICHNISSE, page: MODULE_PAGES.NAMENSVERZEICHNISSE.STAEDTE}));
  }

  showZufallsfunde() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.NAMENSVERZEICHNISSE, page: MODULE_PAGES.NAMENSVERZEICHNISSE.ZUFALL}));
  }

  search() {
    this.search_done = false;
    this.search_results = [];
    let search_param_string: string = '';
    let first: boolean = true;
    if (this.form.controls.vorname.value) {
      search_param_string += 'vorname=' + this.form.controls.vorname.value;
      first = false;
    }
    if (this.form.controls.geburtsname.value) {
      if (!first) {
        search_param_string += '&';
      }
      search_param_string += 'geburtsname=' + this.form.controls.geburtsname.value;
      first = false;
    }
    if (this.form.controls.fuzzi.value) {
      if (!first) {
        search_param_string += '&';
      }
      search_param_string += 'fuzzi=' + this.form.controls.fuzzi.value;
      first = false;
    }
    if (this.form.controls.alle_namen.value) {
      if (!first) {
        search_param_string += '&';
      }
      search_param_string += 'alle_namen=' + this.form.controls.alle_namen.value;
      first = false;
    }
    if (this.form.controls.nachname.value) {
      if (!first) {
        search_param_string += '&';
      }
      search_param_string += 'nachname=' + this.form.controls.nachname.value;
      first = false;
    }
    if (this.form.controls.standesamt.value) {
      if (!first) {
        search_param_string += '&';
      }
      search_param_string +='standesamt_name=' + this.form.controls.standesamt.value;
      first = false;
    }
    if (this.form.controls.stadt.value) {
      if (!first) {
        search_param_string += '&';
      }
      search_param_string +='stadt=' + this.form.controls.stadt.value;
      first = false;
    }
    if (this.form.controls.art.value != -1) {
      if (!first) {
        search_param_string += '&';
      }
      search_param_string += 'urkundenart=' + this.form.controls.art.value;
      first = false;
    }
    if (this.form.controls.juedisch.value != -1) {
      if (!first) {
        search_param_string += '&';
      }
      search_param_string += 'juedisch=' + this.form.controls.juedisch.value;
      first = false;
    }
    this.nvzService.searchEintraege(search_param_string).subscribe(
      (response) => {
        this.search_results = response;
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
        this.search_done = true;
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_LOAD_NVZ_FAILED_ERROR));
      },
      () => {}
    );
  }

  showVerzeichnisArt(artId: number) {
    let options = NVZOptions.NVZUrkundenArt;
    let option = options.find(x => x.id == artId);
    if (option) {
      return option.name;
    }
    return "--";
  }
}
