import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {IStammbaumPerson} from '../../stammbaum/stammbaum-person.interface';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {StammbaumService} from '../../services/stammbaum.service';
import {PersonPopupComponent} from '../person-popup/person-popup.component';
import {CaseService} from '../../services/case.service';
import {DatesService} from "../../services/dates.service";
import Fraction from "fraction.js";

@Component({
  selector: 'heir-embedded-max',
  templateUrl: './heir-embedded-max.component.html',
  styleUrls: ['./heir-embedded-max.component.css']
})
export class HeirEmbeddedMaxComponent implements OnInit, OnChanges, OnDestroy {
  @Input() stammbaumState: IStammbaumState;
  stammbaumPersonsToDisplay: IStammbaumPerson[] = [];
  searchString = '';

  constructor(private stammbaumService: StammbaumService,
              private caseService: CaseService,
              private datesService: DatesService,
              private ngbModal: NgbModal) {
  }

  ngOnInit() {
    this.copyAndRunFilters();
  }

  ngOnChanges() {
    this.copyAndRunFilters();
  }

  getPersonTypeText(personType: number) {
    return this.stammbaumService.getPersonTypeText(personType);
  }

  fraction(decimalNumber: number) {
    const frac = new Fraction(decimalNumber * 0.01);
      return frac.toFraction();
  }

  hasPa(required_pa_user: number) {
    // tslint:disable-next-line:no-bitwise
    return (required_pa_user & 4) === 4;
  }

  onSelect(item: IStammbaumPerson) {
    const modalRef = this.ngbModal.open(PersonPopupComponent);
    modalRef.componentInstance.heir = item;
    modalRef.result.then(
      (result) => {
        if (item && result) {
          this.caseService.goToPersondetailId(item.id, `${item.nachname} ${item.vorname}`);
        }
      },
      () => {}
    );
  }

  updateFilter(event) {
    this.searchString = event.target.value.toLowerCase();
    this.copyAndRunFilters();
  }

  resetFilter() {
    this.searchString = '';
    this.copyAndRunFilters();
  }

  copyAndRunFilters() {
    if (!this.stammbaumState || !this.stammbaumState.stammbaum) {
      return;
    }
    let heirs: IStammbaumPerson[] = [...this.stammbaumState.stammbaum.persons];
    heirs = heirs.filter(x => { return this.stammbaumService.isPersonHeir(x); });
    this.stammbaumPersonsToDisplay = heirs;
  }

  getPaColor(date_input: string) {
    const date_input_string = date_input.slice(4, 8) + '-' + date_input.slice(2, 4) + '-' + date_input.slice(0, 2)
    const date_compare = new Date(date_input_string);
    const date_today = new Date(Date.now());
    const difference = this.datesService.calculateMonthDiff(date_today, date_compare);
    return difference > 6 ? 'green' : 'red';
  }

  isDateReadable(date_input_string) {
    return this.datesService.isDateReadable(date_input_string);
  }

  ngOnDestroy() {}

}
