import {Action} from '@ngrx/store';
import {INoteDraft} from '../notes/note-draft.interface';

export const CREATE_NEW_NOTE_DRAFT = 'CREATE_NEW_NOTE_DRAFT';
export const DELETE_NOTE_DRAFT = 'DELETE_NOTE_DRAFT';

export class CreateNewNoteDraft implements Action {
  readonly type = CREATE_NEW_NOTE_DRAFT;
  constructor(public payload: INoteDraft) {
  }
}

export class DeleteNoteDraft implements Action {
  readonly type = DELETE_NOTE_DRAFT;
}

export type NoteDraftActions =
  CreateNewNoteDraft |
  DeleteNoteDraft;
