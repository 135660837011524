import { Component, OnInit, HostListener, Input } from '@angular/core';
import {INVZState} from '../../store/nvz-state.interface';
import * as NVZOptions from '../nvz-options';
import * as NVZActions from '../../store/nvz.actions';
import {Store} from '@ngrx/store';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IUser} from '../../models/user.interface';
import {NVZEintragFormComponent} from '../nvz-eintrag-form/nvz-eintrag-form.component';
import {INVZEintrag} from '../nvz-eintrag.interface';
import {NVZService} from '../../services/nvz.service';
import * as AppNotificationActions from '../../store/app-notification.actions';
import {
  NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR,
  NOTIFICATION_SLOT_NVZ
} from '../../app-notifications/app-notification.interface';
import {UserService} from '../../services/user.service';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';
import {RightsManagementService} from '../../services/rights-management.service';

@Component({
  selector: 'nvz-eintraege',
  templateUrl: './nvz-eintraege.component.html',
  styleUrls: ['./nvz-eintraege.component.css']
})
export class NVZEintraegeComponent implements OnInit {
  @Input() nvzState: INVZState;
  @Input() user: IUser;
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.key === 'Insert') && ((this.rightsManagementService.isAllowedToEditAllNVZEntries()) || this.nvzState.activeVerzeichnis.users_allowed_to_edit.includes(+this.user.id))) {
      this.addEintrag();
    }
  }

  constructor(private store: Store<{nvz: INVZState}>,
              private modalService: NgbModal,
              public nvzService: NVZService,
              public userService: UserService,
              public rightsManagementService: RightsManagementService) { }

  ngOnInit() {}

  showEintraegeStatus(statusId: number) {
    let options = NVZOptions.NVZEintragStatusOptions;
    let option = options.find(x => x.id == statusId);
    if (option) {
      return option.name;
    }
    return "---";
  }

  showVerzeichnisArt(artId: number) {
    let options = NVZOptions.NVZUrkundenArt;
    let option = options.find(x => x.id == artId);
    if (option) {
      return option.name;
    }
    return "--";
  }

  addEintrag() {
    const modalRef = this.modalService.open(NVZEintragFormComponent);
    modalRef.componentInstance.editMode = false;
    modalRef.componentInstance.vorhandeneEintraege = this.nvzState.eintraege;
    modalRef.result.then(
      (result) => {
        if (result && result.eintrag) {
          this.save(result.eintrag, false);
          if (result && result.next) {
            this.addEintrag();
          }
        }
      },
      () => {}
    );
  }

  editEintrag(eintrag: INVZEintrag) {
    const modalRef = this.modalService.open(NVZEintragFormComponent);
    modalRef.componentInstance.eintrag = eintrag;
    modalRef.componentInstance.editMode = true;
    modalRef.componentInstance.vorhandeneEintraege = this.nvzState.eintraege;
    modalRef.result.then(
      (result) => {if (result && result.eintrag) {this.save(result.eintrag, true)}},
      () => {}
    );
  }

  deleteEintrag(eintrag: INVZEintrag) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.message = 'Wollen Sie diesen Eintrag wirklich löschen?';
    modalRef.componentInstance.title = 'Bestätigung';
    modalRef.result.then(
      (result) => {
        if (result) {
          this.nvzService.deleteEintrag(eintrag).subscribe(
            (resultHtml) => {
              this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
              const eintraege = this.nvzState.eintraege.filter(x => x.id !== eintrag.id);
              this.store.dispatch(new NVZActions.ReplaceNVZEintrage(eintraege));
            },
            (error) => {
              this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
              this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR));
              this.nvzService.getEintraege(this.nvzState.activeVerzeichnis.id);
            },
            () => {}
          );
        }
      }
    );
  }

  selectEintrag(eintrag: INVZEintrag) {
    // todo implement
    // this.store.dispatch(new UiActions.SetActivePage({module: MODULES.NAMENSVERZEICHNISSE, page: MODULE_PAGES.NAMENSVERZEICHNISSE.EINTRAG}));
  }

  save(eintrag: INVZEintrag, editMode: boolean) {
    if (editMode) {
      let eintragToChange: INVZEintrag = this.nvzState.eintraege.find(x => x.id === eintrag.id);
      if (eintragToChange) {
        eintragToChange = eintrag;
        this.nvzService.updateEintrag(eintragToChange).subscribe(
        (result) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          const eintraege = this.nvzState.eintraege;
          const eintragChanged = eintraege.find(x => x.id === result.id);
          if (eintragChanged !== null) {
            Object.assign(eintragChanged,  result);
          } else {
            this.nvzService.getEintraege(this.nvzState.activeVerzeichnis.id);
          }
          this.store.dispatch(new NVZActions.ReplaceNVZEintrage(eintraege));
        },
        (error) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR));
          this.nvzService.getEintraege(this.nvzState.activeVerzeichnis.id);
        },
        () => {}
      );
      }
    } else { // if it is a new Eintrag, set owner_id and verzeichnis_id
      eintrag.owner_id = this.user.id;
      eintrag.verzeichnis_id = this.nvzState.activeVerzeichnis.id;
      this.nvzService.createEintrag(eintrag).subscribe(
        (result) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          let eintraege = this.nvzState.eintraege;
          eintraege.push(result);
          eintraege = eintraege.sort((a, b) => {
            if (a.nachname > b.nachname) { return 1; }
            if (a.nachname < b.nachname) { return -1; }
            return 0;
          });
          this.store.dispatch(new NVZActions.ReplaceNVZEintrage(eintraege));
        },
        (error) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR));
          this.nvzService.getEintraege(this.nvzState.activeVerzeichnis.id);
        },
        () => {}
      );
    }
  }
}
