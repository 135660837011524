import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IStammbaumMarriage} from '../stammbaum-marriage.interface';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CaseService} from '../../services/case.service';
import {Store} from '@ngrx/store';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {Subscription} from 'rxjs';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';

@Component({
  selector: 'edit-marriage',
  templateUrl: './edit-marriage.component.html',
  styleUrls: ['./edit-marriage.component.css']
})
export class EditMarriageComponent implements OnInit, OnDestroy {
  @Input() editMode = false;
  @Input() hasTrueStammbaum = false;
  person: IStammbaumPerson;
  form: FormGroup;
  marriage_id: number;
  partner_options: { id: number; description: string }[] = [];
  stammbaumStateSub: Subscription;
  stammbaumPersons: IStammbaumPerson[];

  constructor(private ngbActiveModal: NgbActiveModal,
              private ngbModal: NgbModal,
              private caseService: CaseService,
              private store: Store<{ stammbaum: IStammbaumState }>) {
    this.form = new FormGroup({
      'needs_hu': new FormControl(false),
      'needs_hu_but_do_without': new FormControl(false),
      'ordered_hu': new FormControl(false),
      'ordered_hu_date': new FormControl(''),
      'kommentar': new FormControl(''),
      'date': new FormControl(''),
      'divorced': new FormControl(false),
      'divorced_date': new FormControl(''),
      'married_to_select': new FormControl(0, [Validators.min(1)])
    });
  }

  ngOnInit() {
    this.stammbaumStateSub = this.store.select('stammbaum').subscribe(
      (stammbaumState) => {
        if (this.hasTrueStammbaum) {
          this.person = stammbaumState.stammbaum.persons.find(x => x.id === stammbaumState.personToEditId);
          this.stammbaumPersons = stammbaumState.stammbaum.persons;
        } else {
          this.person = stammbaumState.stammbaumPersonsWithoutVersion.find(x => x.id === stammbaumState.personToEditId);
          this.stammbaumPersons = stammbaumState.stammbaumPersonsWithoutVersion;
        }
        this.partner_options = [];
        for (const pers of this.stammbaumPersons) {
          let desc = `${pers.vorname} ${pers.nachname}`;
          if (pers.geburtsname && pers.geburtsname !== 'null') {
            desc = `${desc} (geb. ${pers.geburtsname})`;
          }
          if (pers.geburtsdatum && pers.geburtsdatum !== 'null') {
            desc = `${desc} ${pers.geburtsdatum}`;
          }
          this.partner_options.push({id: pers.id, description: desc});
        }
        if (this.person) {
          this.partner_options = this.partner_options.filter(x => x.id !== this.person.id);
        }
        if (this.form && this.editMode) {
          let partner_id = null;
          let marriage: IStammbaumMarriage;
          if (this.hasTrueStammbaum) {
            marriage = stammbaumState.stammbaum.marriages.find(x => x.id === stammbaumState.marriageToEditId);
          } else {
            marriage = stammbaumState.stammbaumMarriagesWithoutVersion.find(x => x.id === stammbaumState.marriageToEditId);
          }
          this.marriage_id = marriage.id;
          const pers = marriage.persons.find(x => x.id !== this.person.id);
          if (pers) {
            partner_id = pers.id;
          }
          this.form.patchValue({
            'needs_hu': marriage.needs_hu,
            'needs_hu_but_do_without': marriage.needs_hu_but_do_without,
            'ordered_hu': marriage.ordered_hu,
            'ordered_hu_date': marriage.ordered_hu_date,
            'kommentar': marriage.kommentar,
            'date': marriage.date,
            'divorced': marriage.divorced,
            'divorced_date': marriage.divorced_date,
            'married_to_select': partner_id
          });
          this.form.controls.married_to_select.disable();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.stammbaumStateSub.unsubscribe();
    this.store.dispatch(new StammbaumActions.ReplaceMarriageToEditId(null));
  }

  deleteEhe() {
    const modalRef: NgbModalRef = this.ngbModal.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Bestätigung';
    modalRef.componentInstance.message = 'Wollen Sie diese Eheschließung wirklich löschen?';
    modalRef.result.then(
      (result) => {
        if (result) {
          this.stammbaumStateSub.unsubscribe();
          this.store.dispatch(new StammbaumActions.TriggerDeleteStammbaumMarriage(this.marriage_id));
          this.ngbActiveModal.close();
        }
      },
      () => {
      }
    );
  }

  cancel() {
    this.ngbActiveModal.close();
  }

  save() {
    if (!this.editMode) {
      if (this.hasTrueStammbaum) {
        this.store.dispatch(new StammbaumActions.TriggerAddMarriageToActiveStammbaum(<IStammbaumMarriage>{
          persons: [this.person.id, this.form.controls.married_to_select.value],
          date: this.form.controls.date.value,
          kommentar: this.form.controls.kommentar.value,
          needs_hu: this.form.controls.needs_hu.value,
          needs_hu_but_do_without: this.form.controls.needs_hu_but_do_without.value,
          ordered_hu: this.form.controls.ordered_hu.value,
          ordered_hu_date: this.form.controls.ordered_hu_date.value,
          divorced: this.form.controls.divorced.value,
          divorced_date: this.form.controls.divorced_date.value,
          case: this.person.case,
          id: this.marriage_id
        }));
      } else {
        this.store.dispatch(new StammbaumActions.TriggerAddMarriageWithoutVersion(<IStammbaumMarriage>{
          persons: [this.person.id, this.form.controls.married_to_select.value],
          date: this.form.controls.date.value,
          kommentar: this.form.controls.kommentar.value,
          needs_hu: this.form.controls.needs_hu.value,
          needs_hu_but_do_without: this.form.controls.needs_hu_but_do_without.value,
          ordered_hu: this.form.controls.ordered_hu.value,
          ordered_hu_date: this.form.controls.ordered_hu_date.value,
          divorced: this.form.controls.divorced.value,
          divorced_date: this.form.controls.divorced_date.value,
          case: this.person.case,
          id: this.marriage_id
        }));
      }
    } else {
      if (this.hasTrueStammbaum) {
        this.store.dispatch(new StammbaumActions.TriggerEditMarriageInActiveStammbaum(<IStammbaumMarriage>{
          persons: [this.person.id, this.form.controls.married_to_select.value],
          date: this.form.controls.date.value,
          kommentar: this.form.controls.kommentar.value,
          needs_hu: this.form.controls.needs_hu.value,
          needs_hu_but_do_without: this.form.controls.needs_hu_but_do_without.value,
          ordered_hu: this.form.controls.ordered_hu.value,
          ordered_hu_date: this.form.controls.ordered_hu_date.value,
          divorced: this.form.controls.divorced.value,
          divorced_date: this.form.controls.divorced_date.value,
          case: this.person.case,
          id: this.marriage_id
        }));
      } else {
        this.store.dispatch(new StammbaumActions.TriggerEditMarriageWithoutVersion(<IStammbaumMarriage>{
          persons: [this.person.id, this.form.controls.married_to_select.value],
          date: this.form.controls.date.value,
          kommentar: this.form.controls.kommentar.value,
          needs_hu: this.form.controls.needs_hu.value,
          needs_hu_but_do_without: this.form.controls.needs_hu_but_do_without.value,
          ordered_hu: this.form.controls.ordered_hu.value,
          ordered_hu_date: this.form.controls.ordered_hu_date.value,
          divorced: this.form.controls.divorced.value,
          divorced_date: this.form.controls.divorced_date.value,
          case: this.person.case,
          id: this.marriage_id
        }));
      }
    }
    this.ngbActiveModal.close();
  }
}
