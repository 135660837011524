import {Injectable} from '@angular/core';
import {ConstantsService} from './constants.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {RightsManagementService} from './rights-management.service';
import {captureMessage} from '@sentry/browser';
import {AuskehrGeldquelleInterface, AuskehrZahlungInterface} from '../auskehr/auskehr.interface';
import {IAddressbookInstitution} from '../addressbook/addressbook-institution.interface';

@Injectable({
  providedIn: 'root'
})
export class AuskehrService {
  caseSub: Subscription;
  activeCaseId: number = null;
  auskehrInstitutionsForCaseSub: BehaviorSubject<IAddressbookInstitution[]> = new BehaviorSubject<IAddressbookInstitution[]>([]);

  constructor(private constantsService: ConstantsService,
              private http: HttpClient,
              private store: Store<any>,
              private rightsManagementService: RightsManagementService) {

    this.caseSub = this.store.select('cases').subscribe(
      (cases) => {
        if (+this.activeCaseId !== +cases.activeCaseId) {
          if (this.rightsManagementService.isAllowedToViewAuskehrTabInCase()) {
            this.loadAuskehrDataForCase(+cases.activeCaseId);
          }
        }
        this.activeCaseId = cases.activeCaseId;
      }
    );
  }

  loadAuskehrDataForCase(caseId: number) {
    if (+caseId < 1) {
      return;
    }
    this.http.get<IAddressbookInstitution[]>(`${this.constantsService.getApiEndpoint()}/auskehr-institution-for-case/?case_id=${caseId}`, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (result) => {
        this.auskehrInstitutionsForCaseSub.next(result);
      },
      (error) => {
        captureMessage('error loading auskehr institutions');
        setTimeout(() => {
          this.loadAuskehrDataForCase(caseId);
        }, 60000);
      },
      () => {
      }
    );
  }

  saveInstitution(institutionId: number, caseId: number) {
    const body = {
      case_id: caseId,
      institution_id: institutionId
    };
      return this.http.post<IAddressbookInstitution>(`${this.constantsService.getApiEndpoint()}/auskehr-add-institution/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  saveInstitutionLocally(institution: IAddressbookInstitution) {
    const institutions = this.auskehrInstitutionsForCaseSub.getValue().filter(x => x.id !== institution.id);
    institutions.push(institution);
    this.auskehrInstitutionsForCaseSub.next(institutions);
  }

   removeInstitution(institutionId: number, caseId: number) {
    const body = {
      case_id: caseId,
      institution_id: institutionId
    };
      return this.http.post<IAddressbookInstitution>(`${this.constantsService.getApiEndpoint()}/auskehr-remove-institution/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  removeInstitutionLocally(institution: IAddressbookInstitution) {
    const institutions = this.auskehrInstitutionsForCaseSub.getValue().filter(x => x.id !== institution.id);
    this.auskehrInstitutionsForCaseSub.next(institutions);
  }

  saveGeldquelle(quelle: AuskehrGeldquelleInterface, institutionId: number, caseId: number, editMode = false) {
    const body = {
      geldquelle: quelle,
      case_id: caseId,
      institution_id: institutionId
    }
    if (!editMode) {
      return this.http.post<AuskehrGeldquelleInterface>(`${this.constantsService.getApiEndpoint()}/auskehr-geldquelle/`, body, {headers: this.constantsService.getHttpOptions()});
    } else {
      return this.http.patch<AuskehrGeldquelleInterface>(`${this.constantsService.getApiEndpoint()}/auskehr-geldquelle/`, body, {headers: this.constantsService.getHttpOptions()});
    }
  }

  deleteGeldquelle(quelleId) {
    const body = {
      geldquelle_id: quelleId
    }
    return this.http.post<AuskehrGeldquelleInterface>(`${this.constantsService.getApiEndpoint()}/auskehr-geldquelle-delete/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  saveGeldquelleLocally(quelle: AuskehrGeldquelleInterface, institutionId: number) {
    const institutions = this.auskehrInstitutionsForCaseSub.getValue().filter(x => x.id !== institutionId);
    const institution = this.auskehrInstitutionsForCaseSub.getValue().find(x => x.id === institutionId);
    const quellen = institution.geldquellen.filter(x => x.id !== quelle.id);
    quellen.push(quelle);
    quellen.sort((a, b) => {
      if (a.id > b.id) { return 1; }
      if (a.id < b.id) { return -1; }
      return 0;
    });
    let auskehr_completed = true;
    let sum_expected = 0;
    for (const q of quellen.filter(x => !x.deleted)) {
      if (!q.completed) {
        auskehr_completed = false;
      }
      if (q.sum_expected) {
        sum_expected += +q.sum_expected;
      }
    }
    institution.auskehr_completed = auskehr_completed;
    institution.sum_expected = sum_expected;
    institution.geldquellen = quellen;
    institutions.push(institution);
    institutions.sort((a, b) => {
      if (a.id > b.id) { return 1; }
      if (a.id < b.id) { return -1; }
      return 0;
    });
    this.auskehrInstitutionsForCaseSub.next(institutions);
  }

  saveZahlung(zahlung: AuskehrZahlungInterface, institutionId: number, caseId: number, editMode = false) {
    const body = {
      zahlung: zahlung,
      case_id: caseId,
      institution_id: institutionId
    }
    if (!editMode) {
      return this.http.post<AuskehrZahlungInterface>(`${this.constantsService.getApiEndpoint()}/auskehr-zahlung/`, body, {headers: this.constantsService.getHttpOptions()});
    } else {
      return this.http.patch<AuskehrZahlungInterface>(`${this.constantsService.getApiEndpoint()}/auskehr-zahlung/`, body, {headers: this.constantsService.getHttpOptions()});
    }
  }

  deleteZahlung(zahlungId) {
    const body = {
      zahlung_id: zahlungId
    }
    return this.http.post<AuskehrZahlungInterface>(`${this.constantsService.getApiEndpoint()}/auskehr-zahlung-delete/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  saveZahlungLocally(zahlung: AuskehrZahlungInterface, institutionId: number) {
    const institutions = this.auskehrInstitutionsForCaseSub.getValue().filter(x => x.id !== institutionId);
    const institution = this.auskehrInstitutionsForCaseSub.getValue().find(x => x.id === institutionId);
    const zahlungen = institution.auskehrungen.filter(x => x.id !== zahlung.id);
    zahlungen.push(zahlung);
    zahlungen.sort((a, b) => {
      if (a.id > b.id) { return 1; }
      if (a.id < b.id) { return -1; }
      return 0;
    });
    let sum = 0;
    let sum_at_death = 0;
    for (const z of zahlungen.filter(x => !x.deleted)) {
      if (z.sum) {
        sum += +z.sum;
      }
      if (z.sum_at_death) {
        sum_at_death += +z.sum_at_death;
      }
    }
    institution.sum_received = sum;
    institution.sum_at_death = sum_at_death;
    institution.auskehrungen = zahlungen;
    institutions.push(institution);
    institutions.sort((a, b) => {
      if (a.id > b.id) { return 1; }
      if (a.id < b.id) { return -1; }
      return 0;
    });
    this.auskehrInstitutionsForCaseSub.next(institutions);
  }
}
