import {Injectable} from '@angular/core';
import {ConstantsService} from '../services/constants.service';

@Injectable()
export class DocumentsService {
  constructor(private constantsService: ConstantsService) {
  }

  createVundV(person_id, mode= 'vollmacht', hu_needed= false, gu_needed= false, pa_needed = false, bank_needed = false, email_needed = false, freitext = '') {
    const token = localStorage.getItem('token');
    const submitForm = document.createElement('form');
    submitForm.style.display = 'none';

    let target = 'Dokument';
    if (mode === 'vollmacht' || mode === 'vollmacht-m') {
      target = 'Vollmacht';
    }
    if (mode === 'vertrag' || mode === 'vertrag-m') {
      target = 'Vertrag';
    }
    if (mode === 'bestaetigung' || mode === 'bestaetigung-m') {
      target = 'VundV-Bestätigung';
    }
    submitForm.target = target;

    submitForm.method = 'POST';
    submitForm.action = this.constantsService.getApiEndpoint() + '/create-v-und-v/';

    const formInput = document.createElement('input');
    formInput.type = 'text';
    formInput.name = 'api_token';
    formInput.value = token;

    const numberInput = document.createElement('input');
    numberInput.type = 'text';
    numberInput.name = 'person_id';
    numberInput.value = person_id;

    const modeInput = document.createElement('input');
    modeInput.type = 'text';
    modeInput.name = 'mode';
    modeInput.value = mode;

    const freitextInput = document.createElement('input');
    freitextInput.type = 'text';
    freitextInput.name = 'freitext';
    freitextInput.value = freitext;

    const huInput = document.createElement('input');
    huInput.type = 'text';
    huInput.name = 'hu';
    if (hu_needed) { huInput.value = '1'; } else { huInput.value = '0'; }

    const guInput = document.createElement('input');
    guInput.type = 'text';
    guInput.name = 'gu';
    if (gu_needed) { guInput.value = '1'; } else { guInput.value = '0'; }

    const paInput = document.createElement('input');
    paInput.type = 'text';
    paInput.name = 'pa';
    if (pa_needed) { paInput.value = '1'; } else { paInput.value = '0'; }

    const bankInput = document.createElement('input');
    bankInput.type = 'text';
    bankInput.name = 'bank';
    if (bank_needed) { bankInput.value = '1'; } else { bankInput.value = '0'; }

    const emailInput = document.createElement('input');
    emailInput.type = 'text';
    emailInput.name = 'email';
    if (email_needed) { emailInput.value = '1'; } else { emailInput.value = '0'; }

    submitForm.appendChild(formInput);
    submitForm.appendChild(numberInput);
    submitForm.appendChild(modeInput);
    submitForm.appendChild(freitextInput);
    submitForm.appendChild(huInput);
    submitForm.appendChild(guInput);
    submitForm.appendChild(paInput);
    submitForm.appendChild(bankInput);
    submitForm.appendChild(emailInput);

    document.body.appendChild(submitForm);
    const wind = window.open('', target);
    if (wind) {
      submitForm.submit();
    }
  }

  createDocx(person_id, mode= 'empty') {
    const token = localStorage.getItem('token');
    const submitForm = document.createElement('form');
    submitForm.style.display = 'none';

    let target = 'Dokument';
    if (mode === 'empty') {
      target = 'Schreiben an';
    }

    submitForm.target = target;

    submitForm.method = 'POST';
    submitForm.action = this.constantsService.getApiEndpoint() + '/create-docx/';

    const formInput = document.createElement('input');
    formInput.type = 'text';
    formInput.name = 'api_token';
    formInput.value = token;

    const numberInput = document.createElement('input');
    numberInput.type = 'text';
    numberInput.name = 'person_id';
    numberInput.value = person_id;

    const modeInput = document.createElement('input');
    modeInput.type = 'text';
    modeInput.name = 'mode';
    modeInput.value = mode;

    submitForm.appendChild(formInput);
    submitForm.appendChild(numberInput);
    submitForm.appendChild(modeInput);

    document.body.appendChild(submitForm);
    const wind = window.open('', target);
    if (wind) {
      submitForm.submit();
    }
  }

  createDocxForCase(case_id, mode= 'empty') {
    const token = localStorage.getItem('token');
    const submitForm = document.createElement('form');
    submitForm.style.display = 'none';

    let target = 'Dokument';
    if (mode === 'empty') {
      target = 'Schreiben an';
    }

    submitForm.target = target;

    submitForm.method = 'POST';
    submitForm.action = this.constantsService.getApiEndpoint() + '/create-docx/';

    const formInput = document.createElement('input');
    formInput.type = 'text';
    formInput.name = 'api_token';
    formInput.value = token;

    const numberInput = document.createElement('input');
    numberInput.type = 'text';
    numberInput.name = 'case_id';
    numberInput.value = case_id;

    const modeInput = document.createElement('input');
    modeInput.type = 'text';
    modeInput.name = 'mode';
    modeInput.value = mode;

    submitForm.appendChild(formInput);
    submitForm.appendChild(numberInput);
    submitForm.appendChild(modeInput);

    document.body.appendChild(submitForm);
    const wind = window.open('', target);
    if (wind) {
      submitForm.submit();
    }
  }

  createDocxForEBS(version_id) {
    const token = localStorage.getItem('token');
    console.log('token:', token)
    const submitForm = document.createElement('form');
    submitForm.style.display = 'none';

    const target = 'EBS-Antrag';

    submitForm.target = target;

    submitForm.method = 'POST';
    submitForm.action = this.constantsService.getApiEndpoint() + '/v6/create-ebs-docx/';

    const formInput = document.createElement('input');
    formInput.type = 'text';
    formInput.name = 'api_token';
    formInput.value = token;

    const numberInput = document.createElement('input');
    numberInput.type = 'text';
    numberInput.name = 'version_id';
    numberInput.value = version_id;

    submitForm.appendChild(formInput);
    submitForm.appendChild(numberInput);

    document.body.appendChild(submitForm);
    const wind = window.open('', target);
    if (wind) {
      submitForm.submit();
    }
  }

  createDocxForEV(case_id) {
    const token = localStorage.getItem('token');
    console.log('token:', token)
    const submitForm = document.createElement('form');
    submitForm.style.display = 'none';

    const target = 'EV-Entwurf';

    submitForm.target = target;

    submitForm.method = 'POST';
    submitForm.action = this.constantsService.getApiEndpoint() + '/v6/create-ev-docx/';

    const formInput = document.createElement('input');
    formInput.type = 'text';
    formInput.name = 'api_token';
    formInput.value = token;

    const numberInput = document.createElement('input');
    numberInput.type = 'text';
    numberInput.name = 'case_id';
    numberInput.value = case_id;

    submitForm.appendChild(formInput);
    submitForm.appendChild(numberInput);

    document.body.appendChild(submitForm);
    const wind = window.open('', target);
    if (wind) {
      submitForm.submit();
    }
  }

  createFaxAntragAkteneinsicht(case_id, mode, user_to, court, datum) {
    if (user_to === undefined) { user_to = null; }
    if (court === undefined) { court = null; }
    if (datum === undefined) { datum = null; }
    const token = localStorage.getItem('token');
    console.log(case_id, mode, user_to, court, datum); // todo remove
    const submitForm = document.createElement('form');
    submitForm.style.display = 'none';

    const target = 'EV-Entwurf';

    submitForm.target = target;

    submitForm.method = 'POST';
    submitForm.action = this.constantsService.getApiEndpoint() + '/v6/create-fax-akteneinsicht-docx/';

    const formInput = document.createElement('input');
    formInput.type = 'text';
    formInput.name = 'api_token';
    formInput.value = token;

    const numberInput = document.createElement('input');
    numberInput.type = 'text';
    numberInput.name = 'case_id';
    numberInput.value = case_id;

    const nxInput = document.createElement('input');
    nxInput.type = 'text';
    nxInput.name = 'mode_id';
    nxInput.value = mode;

    const nyInput = document.createElement('input');
    nyInput.type = 'text';
    nyInput.name = 'court';
    nyInput.value = court;

    const nzInput = document.createElement('input');
    nzInput.type = 'text';
    nzInput.name = 'user_to';
    nzInput.value = user_to;

    const naInput = document.createElement('input');
    naInput.type = 'text';
    naInput.name = 'datum';
    naInput.value = datum;

    submitForm.appendChild(formInput);
    submitForm.appendChild(numberInput);
    submitForm.appendChild(nxInput);
    submitForm.appendChild(nyInput);
    submitForm.appendChild(nzInput);
    submitForm.appendChild(naInput);

    document.body.appendChild(submitForm);
    const wind = window.open('', target);
    if (wind) {
      submitForm.submit();
    }
  }
}
