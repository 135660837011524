import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'case-detail-max-help',
  templateUrl: './case-detail-max-help.component.html',
  styleUrls: ['./case-detail-max-help.component.css']
})
export class CaseDetailMaxHelpComponent {
  constructor(private ngbActiveModal: NgbActiveModal) {}

  close() {
    this.ngbActiveModal.close();
  }
}
