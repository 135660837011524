import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {KostenkategorieDetailInterface, KassenbuchBelegInterface, KassenbuchEinzelpostenAusBelegInterface} from '../kassenbuch.interface';
import {KassenbuchService} from '../kassenbuch.service';
import {Subscription} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {UserService} from '../../services/user.service';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-edit-kostenposition',
  templateUrl: './edit-kostenposition.component.html',
  styleUrls: ['./edit-kostenposition.component.css']
})
export class EditKostenpositionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('postentitel', {static: false}) postentitelElRef: ElementRef;
  case_id: number;
  editMode = false;
  form: FormGroup;
  einzelpostenFormArray: FormArray;
  artOptions = [];
  kostenkategorieOptions = [];
  waehrungOptions = [];
  kostenposition: KassenbuchBelegInterface = <KassenbuchBelegInterface>{
    id: 0,
    created: '',
    updated: '',
    belegtitel: '',
    erlaeuterung: '',
    von_mitarbeiter: null,
    fall: null,
    belegdatum: '',
    art: 0,
    status: 0,
    betrag_bezahlt_durch: null,
    betrag_bezahlt_am: '',
    belegnummer: '',
    verlaufsprotokoll: [],
    deleted: false,
    deleted_reason: '',
    einzelposten: [],
    dateien: []
  };
  files: any[] = [];

  constructor(public kassenbuchService: KassenbuchService,
              private ngbActiveModal: NgbActiveModal,
              private fb: FormBuilder,
              private userService: UserService) {
    this.form = new FormGroup({
      titel: new FormControl('', [Validators.required]),
      erlaeuterung: new FormControl(''),
      belegnummer: new FormControl(''),
      belegdatum: new FormControl(''),
      betrag_ueberwiesen_am: new FormControl(''),
      art: new FormControl(0, [Validators.required]),
    });
    this.einzelpostenFormArray = this.fb.array([]);
    this.form.addControl('einzelposten', this.einzelpostenFormArray);
    this.artOptions = this.kassenbuchService.getArtOptions();
    this.kostenkategorieOptions = this.kassenbuchService.getKostenkategorieOptions();
    this.waehrungOptions = this.kassenbuchService.getWaehrungOptions();
  }

  ngOnInit() {
    if (this.editMode) {
      this.case_id = this.kostenposition.fall;
      this.form.patchValue({
        titel: this.kostenposition.belegtitel,
        erlaeuterung: this.kostenposition.erlaeuterung,
        belegnummer: this.kostenposition.belegnummer,
        belegdatum: this.kostenposition.belegdatum,
        betrag_ueberwiesen_am: this.kostenposition.betrag_bezahlt_am,
        art: this.kostenposition.art
      });
      for (const el of this.kostenposition.einzelposten) {
        const fg: FormGroup = this.createNewEinzelpostenFormItem();
        fg.patchValue({
          id: el.id,
          postentitel: el.postentitel,
          betrag_brutto: el.betrag_brutto,
          ust_satz: el.ust_satz,
          kostenkategorie: el.kostenkategorie,
          in_rechnung_stellen: +el.in_rechnung_stellen,
          waehrung: +el.waehrung
        });
        this.einzelpostenFormArray.push(fg);
      }
    } else {
      this.einzelpostenFormArray.push(this.createNewEinzelpostenFormItem());
    }
  }

  ngAfterViewInit() {}

  addRow() {
    this.einzelpostenFormArray.push(this.createNewEinzelpostenFormItem());
  }

  deleteRow(index: number) {
    this.einzelpostenFormArray.removeAt(index);
  }

  createNewEinzelpostenFormItem(): FormGroup {
    const group = this.fb.group({
      id: new FormControl(null),
      postentitel: new FormControl('', [Validators.required]),
      betrag_brutto: new FormControl(null),
      betrag_brutto_formatted: new FormControl('€ 0,00'),
      betrag_brutto_as_number: new FormControl(0, Validators.min(0.01)),
      betrag_brutto_invalid: new FormControl(true),
      ust_satz: new FormControl(0),
      kostenkategorie: new FormControl(0, Validators.min(1)),
      in_rechnung_stellen: new FormControl(1),
      waehrung: new FormControl(0)
    });
    group.get('betrag_brutto').valueChanges.subscribe(newValue => {
      let transformedValue = '';
      if (newValue === null || newValue === undefined || newValue === '') {
        group.get('betrag_brutto_formatted').setValue('€ 0,00');
        group.get('betrag_brutto_as_number').setValue(null);
        group.get('betrag_brutto_invalid').setValue(true);
        return;
      }
      if (typeof newValue !== 'string') {
        newValue = newValue.toString();
      }
      if (newValue.match(/^[+\-0-9,.()]+$/g) === null) {
        group.get('betrag_brutto_formatted').setValue('ungültig');
        group.get('betrag_brutto_as_number').setValue(null);
        group.get('betrag_brutto_invalid').setValue(true);
        return;
      }
      const countKomma = newValue.split(',').length - 1;
      let countPoint = newValue.split('.').length - 1;
      if (countKomma === 1 && countPoint === 1) {
        if (newValue.indexOf('.') + 4 === newValue.indexOf(',')) {
          newValue = newValue.replace('.', '')
          countPoint = newValue.split('.').length - 1;
        }
      }
      let frontString = '';
      let backString = '';
      if (countKomma + countPoint > 1) {
        group.get('betrag_brutto_formatted').setValue('ungültig');
        group.get('betrag_brutto_as_number').setValue(null);
        group.get('betrag_brutto_invalid').setValue(true);
        return
      }
      if (countKomma + countPoint === 0) {
        transformedValue = `€ ${newValue},00`;
        group.get('betrag_brutto_as_number').setValue(+newValue);
      }
      if (countKomma + countPoint === 1) {
        let spl = ['', ''];
        if (countKomma === 1) {
          spl = newValue.split(',', 2);
        } else {
          spl = newValue.split('.', 2);
        }
        frontString = spl[0];
        backString = spl[1];
        if (backString === null || backString === undefined) {
          backString = '00';
        }
        if (backString.length === 1) {
          backString = `${backString}0`;
        }
        if (backString.length === 0) {
          backString = `${backString}00`;
        }
        if (backString.length > 2) {
          group.get('betrag_brutto_formatted').setValue('ungültig');
          group.get('betrag_brutto_as_number').setValue(null);
          group.get('betrag_brutto_invalid').setValue(true);
          return;
        }
        if (frontString.length === 0) {
          frontString = '0';
        }
        transformedValue = `€ ${frontString},${backString}`;
        group.get('betrag_brutto_as_number').setValue(+frontString + (+backString / 100));
      }
      group.get('betrag_brutto_formatted').setValue(transformedValue);
      group.get('betrag_brutto_invalid').setValue(false);
    });
    return group;
  }

  getEinzelpostenFormRow(): FormArray {
    return this.form.get('einzelposten') as FormArray;
  }

  cancel() {
    this.ngbActiveModal.dismiss();
  }

  updateFirstPostenTitleOnBlur() {
    const rows = this.getEinzelpostenFormRow().controls;
    if (rows) {
      const el = rows[0].get('postentitel');
      if (!el.value && this.form.controls.titel.value) {
        el.setValue(this.form.controls.titel.value);
      }
    }
  }

  save() {
    this.kostenposition.fall = this.case_id;
    this.kostenposition.belegtitel = this.form.controls.titel.value;
    this.kostenposition.art = this.form.controls.art.value;
    this.kostenposition.erlaeuterung = this.form.controls.erlaeuterung.value;
    this.kostenposition.belegnummer = this.form.controls.belegnummer.value;
    this.kostenposition.von_mitarbeiter = this.userService.getActiveUserId();
    let belegdatum = this.form.controls.belegdatum.value;
    if (!belegdatum) { belegdatum = null; }
    this.kostenposition.belegdatum = belegdatum;
    let betrag_ueberwiesen_am = this.form.controls.betrag_ueberwiesen_am.value;
    if (!betrag_ueberwiesen_am) { betrag_ueberwiesen_am = null; }
    this.kostenposition.betrag_bezahlt_am = betrag_ueberwiesen_am;

    const einzelposten: KassenbuchEinzelpostenAusBelegInterface[] = [];
    for (const row of this.getEinzelpostenFormRow().controls) {
      const betrag_netto = row.get('betrag_brutto_as_number').value - row.get('betrag_brutto_as_number').value / (1 + row.get('ust_satz').value / 100) * (row.get('ust_satz').value / 100);
      const posten = {
        id: row.get('id').value,
        postentitel: row.get('postentitel').value,
        betrag_brutto: row.get('betrag_brutto_as_number').value,
        ust_satz: row.get('ust_satz').value,
        kostenkategorie: row.get('kostenkategorie').value,
        in_rechnung_stellen: row.get('in_rechnung_stellen').value,
        betrag_netto: Math.round(betrag_netto * 100) / 100,
        waehrung: row.get('waehrung').value
      };
      einzelposten.push(posten);
    }
    this.kostenposition.einzelposten = einzelposten;

    if (this.editMode) {
      this.kassenbuchService.updateKostenposition(this.kostenposition).subscribe(
        (result) => {
          this.kassenbuchService.updateKostenpositionLocally(result, false);
          this.uploadFiles(result.id);
          this.ngbActiveModal.close();
        },
        (error) => { console.log(error); },
        () => {}
      );
    } else {
      this.kassenbuchService.createKostenposition(this.kostenposition).subscribe(
        (result) => {
          this.kassenbuchService.addKostenpositionLocally(result);
          this.uploadFiles(result.id);
          this.ngbActiveModal.close();
        },
        (error) => { console.log(error); },
        () => {}
      );
    }
  }

  uploadFiles(kostenpositionId: number) {
    this.kassenbuchService.uploadFilesForAbrechnung(this.files, kostenpositionId);
  }

  public dropped(event: NgxFileDropEntry[]) {
    for (const ev of event) {
      this.files.push(ev);
    }
  }

  public fileOver(event) {}

  public fileLeave(event) {}

  public removeFile(file) {
    this.files = this.files.filter(x => x !== file);
  }

  ngOnDestroy() {}
}
