import {NgModule} from '@angular/core';
import {NamensverzeichnisseEntryComponent} from './namensverzeichnisse-entry/namensverzeichnisse-entry.component';
import {CommonModule} from '@angular/common';
import {ToolsModule} from '../tools/tools.module';
import {NgbAlertModule, NgbTabsetModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {NVZEintragFormComponent} from './nvz-eintrag-form/nvz-eintrag-form.component';
import { NvzStandesaemterComponent } from './nvz-standesaemter/nvz-standesaemter.component';
import { NVZStandesaemterFormComponent } from './nvz-standesaemter-form/nvz-standesaemter-form.component';
import { NVZVerzeichnisseComponent } from './nvz-verzeichnisse/nvz-verzeichnisse.component';
import {NVZVerzeichnisseFormComponent} from './nvz-verzeichnisse-form/nvz-verzeichnisse-form.component';
import {NVZEintraegeComponent} from './nvz-eintraege/nvz-eintraege.component';
import { NvzSearchComponent } from './nvz-search/nvz-search.component';
import {AppNotificationModule} from '../app-notifications/app-notifications.module';
import {NvzZufallComponent} from './nvz-zufall/nvz-zufall.component';
import {NvzStaedteComponent} from './nvz-staedte/nvz-staedte.component';
import {NVZStaedteFormComponent} from './nvz-staedte-form/nvz-staedte-form.component';
import {NVZUserrightsFormComponent} from './nvz-userrights-form/nvz-userrights-form.component';
import {NVZVerzeichnisseCheckComponent} from './nvz-verzeichnisse-check/nvz-verzeichnisse-check.component';
import {NvzAutoparserModule} from '../nvz-autoparser/nvz-autoparser.module';
import {NvzLaenderComponent} from './nvz-laender/nvz-laender.component';
import {NVZLaenderFormComponent} from './nvz-laender-form/nvz-laender-form.component';
import {NvzEditNavigationComponent} from './nvz-edit-navigation/nvz-edit-navigation.component';
import {NVZStandesamtSortComponent} from './nvz-standesamt-sort/nvz-standesamt-sort.component';

@NgModule({
  entryComponents: [
    NVZEintragFormComponent,
    NVZStandesaemterFormComponent,
    NVZVerzeichnisseFormComponent,
    NVZStaedteFormComponent,
    NVZLaenderFormComponent,
    NVZUserrightsFormComponent,
    NVZVerzeichnisseCheckComponent,
    NVZStandesamtSortComponent
  ],
  declarations: [
    NamensverzeichnisseEntryComponent,
    NVZEintragFormComponent,
    NvzStaedteComponent,
    NVZStaedteFormComponent,
    NVZUserrightsFormComponent,
    NvzStandesaemterComponent,
    NVZStandesaemterFormComponent,
    NVZVerzeichnisseComponent,
    NVZVerzeichnisseFormComponent,
    NVZVerzeichnisseCheckComponent,
    NVZEintraegeComponent,
    NvzZufallComponent,
    NvzSearchComponent,
    NvzLaenderComponent,
    NVZLaenderFormComponent,
    NvzEditNavigationComponent,
    NVZStandesamtSortComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ToolsModule,
    NgbAlertModule,
    NgbTabsetModule,
    NgbModule,
    ReactiveFormsModule,
    AppNotificationModule,
    NvzAutoparserModule
  ],
  exports: [
    NamensverzeichnisseEntryComponent
  ]
})
export class NamensverzeichnisseModule {}
