import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'line-image-popup',
  templateUrl: './line-image-popup.component.html',
  styleUrls: ['./line-image-popup.component.css']
})
export class LineImagePopupComponent {
  urls: string[] = [];

  constructor(private ngbActiveModal: NgbActiveModal) {}

  close() {
    this.ngbActiveModal.close();
  }
}
