import {Component, OnDestroy} from '@angular/core';
import {INetworkManagerMessage} from '../network-manager-message.interface';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {INetworkState} from '../../store/network-state.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'network-manager-messages',
  templateUrl: './network-manager-messages.component.html',
  styleUrls: ['./network-manager-messages.component.css']
})
export class NetworkManagerMessagesComponent implements OnDestroy {
  messages: INetworkManagerMessage[] = [];
  networkSub: Subscription;

  constructor(private store: Store<{network: INetworkState}>,
              private ngbActiveModal: NgbActiveModal) {
    this.networkSub = this.store.select('network').subscribe(
      (networkState) => {
        if (networkState) {
          this.messages = networkState.messages;
        }
      }
    );
  }

  displayLevel(level: number) {
    if (level === 0) {
      return 'Hinweis';
    }
    if (level === 1) {
      return 'Warnung';
    }
    if (level === 2) {
      return 'Fehler';
    }
  }

  ngOnDestroy() {
    this.networkSub.unsubscribe();
  }

  close() {
    this.ngbActiveModal.close();
  }
}
