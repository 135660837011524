import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {isNullOrUndefined} from "util";

@Component({
  selector: 'editfield-checkbox',
  templateUrl: './editfield-checkbox.component.html',
  styleUrls: ['./editfield-checkbox.component.css']
})
export class EditfieldCheckboxComponent implements OnInit {

  @Input() checkbox: boolean = false;
  @Input() editMode: boolean = false;
  @Output() checkboxChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  checkboxDisplay: boolean = false;

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.parse();
  }

  parse() {
    this.checkboxDisplay = this.checkbox;
  }
}
