import {Component, OnDestroy, OnInit} from '@angular/core';
import {KassenbuchService} from '../kassenbuch.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-kassenbuch-for-main-menu',
  templateUrl: './kassenbuch-for-main-menu.component.html',
  styleUrls: ['./kassenbuch-for-main-menu.component.css']
})
export class KassenbuchForMainMenuComponent implements OnInit, OnDestroy {
  subpage = '';
  subpageSub: Subscription;
  constructor(private kassenbuchService: KassenbuchService) { }

  ngOnInit() {
    this.subpageSub = this.kassenbuchService.kassenbuchMainSubpageSub.subscribe(
      (subpage) => { this.subpage = subpage; }
    );
  }

  ngOnDestroy() {
    this.subpageSub.unsubscribe();
  }

}
