import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {IUi} from "../../models/ui.interface";
import {IModulePages, MODULES, MODULE_PAGES} from "../../routing/routes.model";
import * as SLOTS from "../../app-notifications/app-notification.interface";
import * as UiActions from "../../store/ui.actions";
import {IUserState} from "../../store/user-state.interface";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BackupStatusModalComponent} from '../../tools/backup-status-modal/backup-status-modal.component';
import {ClientConnectionModalComponent} from '../../tools/client-connection-modal/client-connection-modal.component';

@Component({
  selector: 'administration-entry',
  templateUrl: './administration-entry.component.html',
  styleUrls: ['./administration-entry.component.css']
})
export class AdministrationEntryComponent implements OnInit {
  MODULE_PAGES: IModulePages;
  SLOT_USERS = SLOTS.NOTIFICATION_SLOT_USERS;
  @Input() ui: {ui: IUi};

  constructor(private store: Store<{userState: IUserState}>,
              private ngbModal: NgbModal) {}

  ngOnInit() {
    this.MODULE_PAGES = MODULE_PAGES;
  }

  showBackupStatus() {
    this.ngbModal.open(BackupStatusModalComponent);
  }

  showClientConnections() {
    this.ngbModal.open(ClientConnectionModalComponent, {size: 'lg'});
  }

  navigateTo(target: string) {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.ADMINISTRATION, page: target}));
  }
}
