import {Component} from '@angular/core'
import {IGeneratedDoc} from "../generated-doc.interface";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'list-docs',
  templateUrl: './list-docs.component.html',
  styleUrls: ['./list-docs.component.css']
})

export class ListDocsComponent {
  constructor(public userService: UserService) {}

  docs: IGeneratedDoc[] = [
    <IGeneratedDoc>{id: 1, user: 1, created: new Date('2018-08-08'), file_name: 'document.pdf', path: '', comment: '', title: 'Beispiel-Dokument', case: 301001, person: 1001, document_content_text_only: 'text', document_send_status: 0, document_type: 0, deleted: false},
    <IGeneratedDoc>{id: 1, user: 1, created: new Date('2018-08-09'), file_name: 'document_2.pdf', path: '', comment: '', title: 'Beispiel-Email', case: 301001, person: 1001, document_content_text_only: 'text', document_send_status: 1, document_type: 1, deleted: false},
    <IGeneratedDoc>{id: 1, user: 1, created: new Date('2018-06-30'), file_name: 'document_3.pdf', path: '', comment: '', title: 'Beispiel-Fax', case: 301001, person: 1001, document_content_text_only: 'text', document_send_status: 2, document_type: 2, deleted: false},
  ]
}
