import {Action} from "@ngrx/store";
import {IBundesanzeiger} from "../models/bundesanzeiger.interface";

export const REPLACE_BUNDESANZEIGER_CASES_IN_LIST = 'REPLACE_BUNDESANZEIGER_CASES_IN_LIST';
export const REMOVE_BUNDESANZEIGER_CASE_FROM_LIST = 'REMOVE_BUNDESANZEIGER_CASE_FROM_LIST';

export class ReplaceBundesanzeigerCasesInList implements Action {
  readonly type = REPLACE_BUNDESANZEIGER_CASES_IN_LIST;
  constructor(public payload: IBundesanzeiger[]) {}
}

export class RemoveBundesanzeigerCaseFromList implements Action {
  readonly type = REMOVE_BUNDESANZEIGER_CASE_FROM_LIST;
  constructor(public payload: number) {}
}

export type BundesanzeigerActions =
  ReplaceBundesanzeigerCasesInList |
  RemoveBundesanzeigerCaseFromList;
