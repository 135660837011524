import {Component, Inject, Input, OnDestroy} from '@angular/core';
import {Subscription, Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {INoteState} from '../../store/note-state.interface';
import {INote} from '../../notes/note.interface';
import {PageScrollService} from 'ngx-page-scroll-core';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'history-notes',
  templateUrl: './history-notes.component.html',
  styleUrls: ['./history-notes.component.css']
})

export class HistoryNotesComponent implements OnDestroy {
  @Input() maxResults = 0;
  notesObs: Observable<INoteState>;
  notesSub: Subscription;
  recentNotes: INote[];


  constructor(private store: Store<{notes: INoteState}>,
              private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) {
    this.notesObs = this.store.select('notes');
    this.notesSub = this.notesObs.subscribe(
      (noteState) => {
        this.recentNotes = noteState.recentNotes;
      }
    );
  }

  ngOnDestroy() {
    this.notesSub.unsubscribe();
  }

  scroll(id) {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: '#note-history-' + id.toString(),
      scrollOffset: 60
    });
  }
}
