import * as AppNotifacationActions from './app-notification.actions';


const initialState = {
  appNotifications: []
};

export function appNotificationReducer(state = initialState, action: AppNotifacationActions.AppNotificationActions) {
  switch(action.type) {

    case AppNotifacationActions.ADD_APP_NOTIFICATION:
      return {
        ...state,
        appNotifications: [...state.appNotifications, action.payload]
      };

    case AppNotifacationActions.UPDATE_APP_NOTIFICATION:
      // remove all notifications in the same slot
      let appNotificationsBefore = [...state.appNotifications];
      let slotToDelete = appNotificationsBefore.filter(x => x.slot === action.payload.slot);
      for (let message of slotToDelete) {
        appNotificationsBefore.splice(appNotificationsBefore.indexOf(message), 1);
      }
      // then add the new one
      return {
        ...state,
        appNotifications: [...appNotificationsBefore, action.payload]
      };

    case AppNotifacationActions.CLEAR_APP_NOTIFICATION_SLOT:
      let oldAppNotifications = [...state.appNotifications];
      let messagesToDelete = oldAppNotifications.filter(x => x.slot === action.payload);
      for (let message of messagesToDelete) {
        oldAppNotifications.splice(oldAppNotifications.indexOf(message), 1);
      }
      return {
        ...state,
        appNotifications: oldAppNotifications
      };

    default:
      return state;
  }
}
