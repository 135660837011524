import {Component, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { CaseService } from '../../services/case.service';
import { UserService } from '../../services/user.service';
import {Store} from '@ngrx/store';
import {RightsManagementService} from '../../services/rights-management.service';
import {IUi} from '../../models/ui.interface';
import {IUser} from '../../models/user.interface';
import {IUserState} from '../../store/user-state.interface';
import {ICaseState} from '../../store/case-state.interface';
import {ICaseStatus} from '../../models/case-status.interface';
import {DebugLogService} from '../../services/debug-log.service';
import {ICase} from '../case.interface';
import * as CaseActions from '../../store/case.actions';
import {IStammbaumPerson} from '../../stammbaum/stammbaum-person.interface';


@Component({
  selector: 'case-list-search-results',
  templateUrl: './case-list-search-results.component.html',
  styleUrls: ['./case-list-search-results.component.css']
})

export class CaseListSearchResultsComponent implements OnInit, OnDestroy {
  @Input() searchResults: ICase[] = [];
  caseExpanded: number;
  caseStatus: ICaseStatus[];
  caseStatusSub: Subscription;
  caseSub: Subscription;
  users: IUser[] = [];
  usersStateSub: Subscription;

  constructor(private caseService: CaseService,
              private debugLogService: DebugLogService,
              private store: Store<{ui: IUi, users: IUserState, cases: ICaseState, caseStatus: ICaseStatus[]}>,
              private renderer: Renderer2,
              public rightsManagementService: RightsManagementService,
              private userService: UserService,
              private modalService: NgbModal) {}

  ngOnInit() {
    this.usersStateSub = this.store.select('users').subscribe(
      (usersState) => {
        this.users = usersState.users;
      }
    );
    this.caseStatusSub = this.store.select('caseStatus').subscribe(
      (caseStatus) => { this.caseStatus = caseStatus; }
    );
    this.caseSub = this.store.select('cases').subscribe(
      (casesState) => {
        this.caseExpanded = casesState.expandedCaseInCaselist;
      });
  }

  expandCase(id: number) {
    if (id !== this.caseExpanded) {
      this.store.dispatch(new CaseActions.ExpandCaseInCaselist(id));
    } else {
      this.store.dispatch(new CaseActions.ExpandCaseInCaselist(null));
    }
  }

  ngOnDestroy() {
    this.usersStateSub.unsubscribe();
    this.caseStatusSub.unsubscribe();
    this.caseSub.unsubscribe();
  }

  onTableClicked(item: ICase) {
    this.caseService.goToCasedetailId(item.id, item.last_name);
  }
}
