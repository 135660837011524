import { Component, OnInit, HostListener, Input } from '@angular/core';
import {INVZStandesamt} from "../nvz-standesamt.interface";
import {INVZState} from "../../store/nvz-state.interface";
import * as NVZOptions from "../nvz-options";
import * as NVZActions from "../../store/nvz.actions";
import * as UiActions from "../../store/ui.actions";
import {Store} from '@ngrx/store';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {INVZVerzeichnis} from "../nvz-verzeichnis.interface";
import {MODULE_PAGES, MODULES} from "../../routing/routes.model";
import {NVZVerzeichnisseFormComponent} from "../nvz-verzeichnisse-form/nvz-verzeichnisse-form.component";
import {IUser} from "../../models/user.interface";
import {NVZService} from "../../services/nvz.service";
import * as AppNotificationActions from "../../store/app-notification.actions";
import {
  NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR,
  NOTIFICATION_SLOT_NVZ
} from "../../app-notifications/app-notification.interface";
import {UserService} from "../../services/user.service";
import {ConfirmModalComponent} from "../../tools/confirm-modal/confirm-modal.component";
import {RightsManagementService} from "../../services/rights-management.service";
import {NVZUserrightsFormComponent} from "../nvz-userrights-form/nvz-userrights-form.component";
import {NVZVerzeichnisseCheckComponent} from "../nvz-verzeichnisse-check/nvz-verzeichnisse-check.component";

@Component({
  selector: 'nvz-verzeichnisse',
  templateUrl: './nvz-verzeichnisse.component.html',
  styleUrls: ['./nvz-verzeichnisse.component.css']
})
export class NVZVerzeichnisseComponent implements OnInit {

   @HostListener('window:keydown', ['$event'])
   keyEvent(event: KeyboardEvent) {
     if ((event.key === 'Insert') && this.rightsManagementService.isAllowedToEditAllNVZEntries() ) {
       this.addVerzeichnis();
     }
   }
   @Input() nvzState: INVZState;
   @Input() user: IUser;

  constructor(private store: Store<{nvz: INVZState}>,
              private modalService: NgbModal,
              public nvzService: NVZService,
              public userService: UserService,
              public rightsManagementService: RightsManagementService) { }

  ngOnInit() {}

  showVerzeichnisStatus(statusId: number) {
    let options = NVZOptions.NVZVerzeichnisStatusOptions;
    let option = options.find(x => x.id == statusId);
    if (option) {
      return option.name;
    }
    return "---";
  }

  showVerzeichnisArt(artId: number) {
    return this.nvzService.showVerzeichnisArt(artId);
  }

  addVerzeichnis() {
    const modalRef = this.modalService.open(NVZVerzeichnisseFormComponent);
    modalRef.componentInstance.editMode = false;
    modalRef.result.then(
      (result) => {if (result) {this.save(result, false)}},
      () => {}
    );
  }

  editUserRights(verzeichnis: INVZVerzeichnis) {
    const modalRef = this.modalService.open(NVZUserrightsFormComponent);
    modalRef.componentInstance.verzeichnis = verzeichnis;
    modalRef.componentInstance.active_standesamt_id = this.nvzState.activeStandesamt.id;
    modalRef.result.then(
      (result) => {},
      () => {}
    );
  }

  hasUserEditRights(verzeichnis: INVZVerzeichnis) {
    let results: number[] = verzeichnis.users_allowed_to_edit.filter(x=>x==this.user.id);
    if (results && results.length > 0){
      return true;
    }
    return false;
  }

  editVerzeichnis(verzeichnis: INVZVerzeichnis) {
    const modalRef = this.modalService.open(NVZVerzeichnisseFormComponent);
    modalRef.componentInstance.verzeichnis = verzeichnis;
    modalRef.componentInstance.editMode = true;
    modalRef.result.then(
      (result) => {if (result) {this.save(result, true)}},
      () => {}
    );
  }

  checkVerzeichnis(verzeichnis: INVZVerzeichnis) {
    const modalRef = this.modalService.open(NVZVerzeichnisseCheckComponent);
    modalRef.componentInstance.verzeichnis = verzeichnis;
    modalRef.result.then(
      (result) => {},
      () => {}
    );
  }

  deleteVerzeichnis(verzeichnis: INVZVerzeichnis) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.message = "Wollen Sie dieses Verzeichnis mit all seinen Einträgen wirklich löschen?";
    modalRef.componentInstance.title = "Bestätigung";
    modalRef.result.then(
      (result) => {
        if (result) {
          this.nvzService.deleteVerzeichnis(verzeichnis).subscribe(
            (result) => {
              this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
              this.nvzService.getVerzeichnisse(this.nvzState.activeStandesamt.id);
            },
            (error) => {
              this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
              this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR));
              this.nvzService.getVerzeichnisse(this.nvzState.activeStandesamt.id);
            },
            () => {}
          );
        }
      }
    );
  }

  selectVerzeichnis(verzeichnis: INVZVerzeichnis) {
    this.store.dispatch(new NVZActions.SetActiveVerzeichnis(verzeichnis));
    this.nvzService.getEintraege(verzeichnis.id);
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.NAMENSVERZEICHNISSE, page: MODULE_PAGES.NAMENSVERZEICHNISSE.EINTRAG}));
  }

  save(verzeichnis: INVZVerzeichnis, editMode: boolean) {
    if (editMode) {
      let verzeichnisToChange: INVZVerzeichnis = this.nvzState.verzeichnisse.find(x => x.id === verzeichnis.id);
      if (verzeichnisToChange) {
        verzeichnisToChange = verzeichnis;
        this.nvzService.updateVerzeichnis(verzeichnisToChange).subscribe(
        (result) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.nvzService.getVerzeichnisse(this.nvzState.activeStandesamt.id);
        },
        (error) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR));
          this.nvzService.getVerzeichnisse(this.nvzState.activeStandesamt.id);
        },
        () => {}
      );
      }
    } else { // if it is a new Verzeichnis, set owner_id and standesamt_id
      verzeichnis.owner_id = this.user.id;
      verzeichnis.standesamt_id = this.nvzState.activeStandesamt.id;
      this.nvzState.verzeichnisse.push(verzeichnis);
      this.nvzService.createVerzeichnis(verzeichnis).subscribe(
        (result) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.nvzService.getVerzeichnisse(this.nvzState.activeStandesamt.id);
        },
        (error) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR));
          this.nvzService.getVerzeichnisse(this.nvzState.activeStandesamt.id);
        },
        () => {}
      );
    }
    this.store.dispatch(new NVZActions.ReplaceVerzeichnisse(this.nvzState.verzeichnisse));
  }
}
