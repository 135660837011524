import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../../services/constants.service';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.css']
})
export class ChangelogComponent implements OnInit {

  frontendApiInformation = '';
  backendApiInformation = '<<not available>>';

  constructor(private constantsService: ConstantsService) { }

  ngOnInit() {
    this.getFrontendApiInformation();
    this.getBackendApiInformation();
  }
  getFrontendApiInformation() {
    this.frontendApiInformation = this.constantsService.getApiVersion();
  }

  getBackendApiInformation() {
    this.constantsService.getBackendApiInformation().subscribe(response => { this.doneGetBackendApiInformation(response)});
  }

  doneGetBackendApiInformation(response: Response) {
    if (response) {
      this.backendApiInformation = response + ' (at ' + this.constantsService.getApiServer() + ')';
    } else {
      this.backendApiInformation = '<<not available>>;'
    }
  }
}
