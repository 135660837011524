import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CaseService} from '../../services/case.service';
import {CaseDetailMaxHelpComponent} from '../case-detail-max-help/case-detail-max-help.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RightsManagementService} from '../../services/rights-management.service';
import {StammbaumService} from '../../services/stammbaum.service';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';


@Component({
  selector: 'case-detail-max-menu',
  templateUrl: './case-detail-max-menu.component.html',
  styleUrls: ['./case-detail-max-menu.component.css']
})
export class CaseDetailMaxMenuComponent {
  @Input() mode: number;
  @Input() editMode;
  @Output() modeChanged: EventEmitter<number> = new EventEmitter<number>();

  constructor(private caseService: CaseService,
              private stammbaumService: StammbaumService,
              public rightsManagementService: RightsManagementService,
              private ngbModal: NgbModal) {
  }

  getTitle() {
    return this.caseService.getTitleTextForActiveCase();
  }

  hasOpenModals() {
    return this.ngbModal.hasOpenModals();
  }

  goToCaseSearch() {
    if (!this.editMode) { // only switch display mode if edit mode is none
      this.caseService.goToCaseSearch();
    }
  }

  changeToMode(mode: number) {
    if (!this.editMode) { // only switch display mode if edit mode is none
      this.modeChanged.emit(mode);
    }
  }

  createStammbaum() {
    const modalRef = this.ngbModal.open(ConfirmModalComponent);
    modalRef.componentInstance.message = 'Wollen Sie wirklich einen neuen Stammbaum für diesen Fall anlegen?';
    modalRef.componentInstance.title = 'Bestätigung';
    modalRef.result.then(
      (result) => {
        if (result) {
          this.stammbaumService.createNewStammbaum('eo4');
        }
      }
    );
  }

  hasTrueStammbaum() {
    return this.stammbaumService.hasTrueStammbaum();
  }

  showHelp() {
    this.ngbModal.open(CaseDetailMaxHelpComponent, {backdrop: 'static'});
  }
}
