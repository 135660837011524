import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'stammbaum-tree-select-edit-marriage',
  templateUrl: './stammbaum-tree-select-edit-marriage.component.html',
  styleUrls: ['./stammbaum-tree-select-edit-marriage.component.css']
})
export class StammbaumTreeSelectEditMarriageComponent {
  marriages;

  constructor(private ngbActiveModal: NgbActiveModal) {}

  goToMarriage(marriage) {
    this.ngbActiveModal.close(marriage);
  }

  close() {
    this.ngbActiveModal.dismiss();
  }
}
