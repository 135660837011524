import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MODULE_PAGES, MODULES} from '../../routing/routes.model';
import * as CaseActions from '../../store/case.actions';
import * as StammbaumActions from '../../store/stammbaum.actions';
import * as UiActions from '../../store/ui.actions';
import {RoutingService} from '../../routing/routing.service';
import {Store} from '@ngrx/store';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {Observable, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {StammbaumService} from '../../services/stammbaum.service';
import {StammbaumCheckpointsComponent} from '../stammbaum-checkpoints/stammbaum-checkpoints.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CaseService} from '../../services/case.service';

@Component({
  selector: 'stammbaum',
  templateUrl: './stammbaum-entry.component.html',
  styleUrls: ['./stammbaum-entry.component.css']
})
export class StammbaumEntryComponent implements OnInit, OnDestroy {
  stammbaumMode = '';
  stammbaumSub: Subscription;
  stammbaumStateObs: Observable<IStammbaumState>;
  stammbaumState: IStammbaumState;

  constructor(private routingService: RoutingService,
              public stammbaumService: StammbaumService,
              private router: Router,
              private caseService: CaseService,
              private ngbModal: NgbModal,
              private store: Store<{stammbaum: IStammbaumState}>) {}

  ngOnInit(): void {
    this.store.dispatch(new StammbaumActions.SetStammbaumMode('person-list'));
    this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersionsForCase(true));
    this.stammbaumStateObs = this.store.select('stammbaum');
    this.stammbaumSub = this.stammbaumStateObs.subscribe(
      (stammbaumState) => {
        this.stammbaumState = stammbaumState;
        if (stammbaumState) {
          this.stammbaumMode = stammbaumState.stammbaumMode;
        }
      });
  }

  ngOnDestroy(): void {
    this.stammbaumSub.unsubscribe();
  }

  backToCaseViaRouter() {
    this.router.navigate(['/']);
  }

  toTreeView() {
    this.store.dispatch(new StammbaumActions.SetStammbaumMode('tree-view'));
  }

  toCheckpoints() {
    const ngbModalRef = this.ngbModal.open(StammbaumCheckpointsComponent, {backdrop: 'static', size: 'lg'});
    ngbModalRef.componentInstance.stammbaumState = this.stammbaumState;
  }

  caseInfo() {
    return `Fall ${this.caseService.getActiveCaseId()}: ${this.caseService.getActiveCaseLastName()}`;
  }
}
