import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {IUi} from '../../models/ui.interface';
import * as UiActions from '../../store/ui.actions';
import {MODULES, MODULE_PAGES} from '../../routing/routes.model';
import {ICalendarEntry} from '../calendar-entry.interface';
import {ICalendarEntryState} from '../../store/calendar-entry-state.interface';
import {IAuthUser} from '../../models/auth-user.interface';
import {IUserState} from '../../store/user-state.interface';
import {IUser} from '../../models/user.interface';
import {CalendarEntryService} from '../../services/calendar-entry.service';
import {UserService} from '../../services/user.service';
import * as CalendarEntryActions from '../../store/calendar-entry.actions';
import {CalendarPopupComponent} from '../calendar-popup/calendar-popup.component';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'calendar-show-month',
  templateUrl: './calendar-show-month.component.html',
  styleUrls: ['./calendar-show-month.component.css']
})

export class CalendarShowMonthComponent implements OnInit, OnDestroy {
  calendarEntriesSub: Subscription;
  calendarEntriesOwn: ICalendarEntry[];
  calendarEntriesOther: ICalendarEntry[];
  authUser: IAuthUser;
  authUserSub: Subscription;
  usersStateSub: Subscription;
  users: IUser[];
  currentDate = moment();
  today = moment();
  currentMonthName = '';
  firstDayOfCalendarMonth;
  lastDayOfCalendarMonth;
  weeks: moment.Moment[][] = [];

  weekDays = [ 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
  monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

  constructor(private store: Store<{users: IUserState, authUser: {authUser: IAuthUser}, ui: IUi, calendarEntry: ICalendarEntryState}>,
              public calendarEntryService: CalendarEntryService,
              private modalService: NgbModal,
              public userService: UserService) {
    this.usersStateSub = this.store.select('users').subscribe(
      (users) => {
        this.users = users.users;
      }
    );
    this.authUserSub = this.store.select('authUser').subscribe((authUser) => {this.authUser = authUser.authUser; });
    this.calendarEntriesSub = this.store.select('calendarEntry').subscribe(
      (entries: {calendarEntries: ICalendarEntry[]}) => {
        let entriesCopy: ICalendarEntry[] = [...entries.calendarEntries];
        entriesCopy = entries.calendarEntries.sort((a, b) => a.deadline_timestamp.getTime() - b.deadline_timestamp.getTime());
        this.calendarEntriesOther = entriesCopy.filter(x => (x.created_by_user_id == this.authUser.user.id) && (x.for_user_id != this.authUser.user.id) && !x.completed);
        this.calendarEntriesOwn = entriesCopy.filter(x => (x.for_user_id == this.authUser.user.id) && !x.completed);
      }
    );
  }

  ngOnInit() {
    this.calculateGrid();
  }

  prevMonth(): void {
    this.currentDate = moment(this.currentDate).subtract(1, 'months');
    this.calculateGrid();
  }
  nextMonth(): void {
    this.currentDate = moment(this.currentDate).add(1, 'months');
    this.calculateGrid();
  }

  calculateGrid() {
    this.currentMonthName = this.monthNames[this.currentDate.month()];
    const startDateOfMonth = moment(this.currentDate).startOf('month');
    let startDateOfMonthWeekday = startDateOfMonth.day();
    if (startDateOfMonthWeekday == 0) {
      startDateOfMonthWeekday = 6; // if it is a sunday (=0), then put it at the end of the week
    } else {
      startDateOfMonthWeekday -= 1; // move the other days accordingly
    }
    this.firstDayOfCalendarMonth = moment(startDateOfMonth).subtract(startDateOfMonthWeekday, 'days');
    const endDateOfMonth = moment(this.currentDate).endOf('month');
    let endDateOfMonthWeekday = endDateOfMonth.day();
    if (endDateOfMonthWeekday == 0) {
      endDateOfMonthWeekday = 6;
    } else {
      endDateOfMonthWeekday -= 1;
    }
    this.lastDayOfCalendarMonth = moment(endDateOfMonth).add(6 - endDateOfMonthWeekday, 'days');
    const numberOfDays = this.lastDayOfCalendarMonth.diff(this.firstDayOfCalendarMonth, 'days') + 1;
    const numberOfWeeks: number = numberOfDays / 7;

    this.weeks = [];
    for (let w = 0; w < numberOfWeeks; w++) {
      this.weeks.push(this.getWeek(w));
    }
  }

  getWeek(weekNumber: number) {
    const startDate: moment.Moment = moment(this.firstDayOfCalendarMonth).add(weekNumber * 7, 'days');
    const endDate: moment.Moment = moment(this.firstDayOfCalendarMonth).add(((weekNumber + 1) * 7), 'days');
    const days: moment.Moment[] = [];
    while (startDate.diff(endDate, 'days') != 0) {
      days.push(moment(startDate));
      startDate.add(1, 'days');
    }
    return days;
  }

  getEntries(day: moment.Moment) {
    const entriesOwn: ICalendarEntry[] = this.calendarEntriesOwn.filter(x => day.isSame(x.deadline_timestamp, 'd'));
    const entriesOther: ICalendarEntry[] = this.calendarEntriesOther.filter(x => day.isSame(x.deadline_timestamp, 'd'));
    const all = {
      own: entriesOwn,
      others: entriesOther
    }
    return all;
  }

  convertTimeForOutput(hour, minute) {
    return this.calendarEntryService.convertTimeForOutput(hour, minute);
  }

  getAbbrevationForUser(for_id) {
    return this.userService.getAbbreviationForUser(for_id);
  }

  showList() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.CALENDAR, page: MODULE_PAGES.CALENDAR.LIST}));
  }

  ngOnDestroy() {
    this.calendarEntriesSub.unsubscribe();
    this.usersStateSub.unsubscribe();
    this.authUserSub.unsubscribe();
  }

  openDetails(calendarEntry: ICalendarEntry) {
    this.store.dispatch(new CalendarEntryActions.SetActiveEntry(calendarEntry));
    const modalRef = this.modalService.open(CalendarPopupComponent);
  }
}
