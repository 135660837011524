import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import {IStammbaumMarriage} from '../stammbaum-marriage.interface';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EditMarriageComponent} from '../edit-marriage/edit-marriage.component';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {Store} from '@ngrx/store';
import {MARGIN_HEIGHT_PX, PERSON_MIN_HEIGHT_PX, PERSON_WIDTH_PX, StammbaumDisplayService} from '../../services/stammbaum-display.service';
import {Subscription} from 'rxjs';
import {IStammbaumVersion} from '../stammbaum-version.interface';

@Component({
  selector: '[stammbaum-tree-children-connection]',
  templateUrl: './stammbaum-tree-children-connection.component.html',
  styleUrls: ['./stammbaum-tree-children-connection.component.css']
})
export class StammbaumTreeChildrenConnectionComponent implements OnDestroy {
  lv1_x1 = 0;
  lv1_y1 = 0;
  lv1_x2 = 0;
  lv1_y2 = 0;
  lv2_x1 = 0;
  lv2_y1 = 0;
  lv2_x2 = 0;
  lv2_y2 = 0;
  lh_x1 = 0;
  lh_y1 = 0;
  lh_x2 = 0;
  lh_y2 = 0;
  p_eo = 0;
  c_eo = 0;
  realHeight = PERSON_MIN_HEIGHT_PX;
  realHeightSub: Subscription;

  @Input() treeViewMode: string;
  @Input() version: IStammbaumVersion;
  @Input() mapOnly = false;
  @Input() set p(p: IStammbaumPerson) {
    if (!p) {
      return;
    }
    this.lv1_x1 = p.displayParams.x + 0.5 * PERSON_WIDTH_PX;
    this.lv1_y1 = p.displayParams.y + this.realHeight;
    this.lv1_x2 = this.lv1_x1;
    this.lv1_y2 = this.lv1_y1 + 0.5 * MARGIN_HEIGHT_PX;
    this.p_eo = p.erbordnung;
    this.recalculateHorizontalLine();
  }
  @Input() set c(c: IStammbaumPerson) {
    if (!c) {
      return;
    }
    this.lv2_x1 = c.displayParams.x + 0.5 * PERSON_WIDTH_PX;
    this.lv2_y1 = c.displayParams.y;
    this.lv2_x2 = this.lv2_x1;
    this.lv2_y2 = this.lv2_y1 - 0.5 * MARGIN_HEIGHT_PX;
    this.c_eo = c.erbordnung;
    this.recalculateHorizontalLine();
  }

  constructor(private store: Store<{stammbaum: IStammbaumState}>,
              private ngbModal: NgbModal,
              private stammbaumDisplayService: StammbaumDisplayService) {
    this.realHeightSub = this.stammbaumDisplayService.personHeight.subscribe(
      (height) => {
        this.realHeight = height;
        this.recalculateHorizontalLine();
      }
    );
  }

  ngOnDestroy() {
    this.realHeightSub.unsubscribe();
  }

  recalculateHorizontalLine() {
    if (this.lv1_x1 !== this.lv2_x1) {
      this.lh_x1 = this.lv1_x1;
      this.lh_x2 = this.lv2_x1;
      this.lh_y1 = this.lv1_y2;
      this.lh_y2 = this.lv2_y2;
    }
  }

  hideConnection() {
    if (!this.c_eo || !this.p_eo || !this.version) { return true; }
    if (this.version.hide_eo_4 && ((this.c_eo === 4) || (this.p_eo === 4))) { return true; }
    if (this.version.hide_eo_3 && ((this.c_eo === 3) || (this.p_eo === 3))) { return true; }
    return false;
  }
}
