import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-table-sort-header',
  templateUrl: './table-sort-header.component.html',
  styleUrls: ['./table-sort-header.component.css']
})
export class TableSortHeaderComponent implements OnInit {
  @Input() title = '';
  @Input() isActive = false;
  @Input() isInverse = false;
  constructor() { }

  ngOnInit() {}
}
