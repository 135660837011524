import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {UserService} from '../../services/user.service';
import {IUser} from '../../models/user.interface';
import {IUi} from '../../models/ui.interface';
import {IUserState} from '../../store/user-state.interface';
import {IUserRole} from '../../models/userrole.interface';
import * as UiActions from '../../store/ui.actions';
import {MODULES, MODULE_PAGES} from '../../routing/routes.model';
import {NotifyModalComponent} from '../../tools/notify-modal/notify-modal.component';

@Component({
  selector: 'user-management-edit',
  templateUrl: './user-management-edit.component.html',
  styleUrls: ['./user-management-edit.component.css']
})
export class UserManagementEditComponent implements OnInit, OnDestroy {

  copiedUser: IUser;
  userSub: Subscription;
  users: IUserState;
  userRoles: IUserRole[];
  userRolesSub: Subscription;
  printableUserRoles: string[] = [];

  editMode: boolean = false;

  constructor(private store: Store<{ui: IUi, users: IUserState, userRoles: IUserRole[]}>,
              private userService: UserService,
              private modalService: NgbModal)
  {
    this.userSub = this.store.select('users').subscribe(
      (users) => {
        this.users = users;
        let activeUserId: number = users.activeUser;
        if (!this.editMode) { // only copy data if not in edit mode
          this.copiedUser = {...users.users.find(x => x.id == activeUserId)};
        }
      }
    );
    this.userRolesSub = this.store.select('userRoles').subscribe(
      (userRoles) => {
        this.userRoles = [...userRoles];
        for (let userRole of userRoles) {
          this.printableUserRoles[+userRole.id] = userRole.description;
        }
      });
  }

  ngOnInit() {}

  backToList() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.ADMINISTRATION, page: MODULE_PAGES.ADMINISTRATION.USER}));
  }

  editUser() {
    this.editMode = true;
  }

  cancelEdit() {
    this.editMode = false;
    let activeUserId: number = this.users.activeUser;
    this.copiedUser = {...this.users.users.find(x => x.id == activeUserId)};
  }

  save() {
    this.editMode = false;
    this.userService.edit_save(this.copiedUser);
  }

  newPwd() {
    this.userService.resetPassword(this.copiedUser)
      .subscribe(
        (data) => {
          const modalRef = this.modalService.open(NotifyModalComponent);
          modalRef.componentInstance.message = "Dem Benutzer " + this.copiedUser.first_name + " " + this.copiedUser.last_name + " wurde ein neues Passwort zugeschickt.";
          modalRef.componentInstance.title = "Hinweis";
          },
        (err) => this.setSaveError(err)
      );
  }

  setSaveError(err) {
    console.log(err);
  }

  ngOnDestroy() {
    this.userRolesSub.unsubscribe();
    this.userSub.unsubscribe();
  }
}
