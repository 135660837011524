import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-age-check-popup',
  templateUrl: './age-check.component.html',
  styleUrls: ['./age-check.component.css']
})
export class AgeCheckComponent implements OnInit {
  mode: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  confirm() {
    this.activeModal.close();
  }

  deny() {
    this.activeModal.dismiss();
  }

}
