import {Component, Input} from '@angular/core';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {Store} from '@ngrx/store';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ICalendarEntry} from "../calendar-entry.interface";
import {MODULES, MODULE_PAGES} from "../../routing/routes.model";
import * as UiActions from "../../store/ui.actions";
import {ICalendarEntryState} from "../../store/calendar-entry-state.interface";
import * as CalendarEntryActions from "../../store/calendar-entry.actions";
import {IUser} from "../../models/user.interface";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {IAuthUser} from "../../models/auth-user.interface";
import {IUserState} from "../../store/user-state.interface";
import {CalendarEntryService} from "../../services/calendar-entry.service";

@Component({
  selector: 'calendar-form',
  templateUrl: './calendar-form.component.html',
  styleUrls: ['./calendar-form.component.css']
})

export class CalendarFormComponent {
  @Input() mode: string;
  entryID: number = null;
  calendarEntryForm: FormGroup;
  calendarEntry: ICalendarEntry = null;
  deadline: Date = null;
  deadline_time: string = "";
  authUser: IAuthUser;
  authUserSub: Subscription;
  users: IUser[] = [];
  usersSub: Subscription;
  activeEntrySubscription: Subscription;

  constructor(private store: Store<{users: IUserState, calendarEntry: ICalendarEntryState, authUser: {authUser}}>,
              public calendarEntryService: CalendarEntryService) {
    this.usersSub = this.store.select('users').subscribe(
      (users: IUserState) => {
        this.users = users.users.filter(x => x.is_active);
      }
    );
    this.authUserSub = this.store.select('authUser').subscribe(
      (authUser) => {this.authUser = authUser.authUser;});
  }

  ngOnInit() {
    if (this.mode == 'own') {
       this.calendarEntryForm = new FormGroup({
        'title': new FormControl(null, [Validators.required, Validators.maxLength(60)]),
        'deadlineTimestamp': new FormControl(null, [Validators.required, Validators.maxLength(10)]),
        'deadlineTimestampTime': new FormControl(null, [Validators.maxLength(10)]),
        'message': new FormControl(null, [Validators.maxLength(300)]),
      });
    }
    else {
      this.calendarEntryForm = new FormGroup({
        'title': new FormControl(null, [Validators.required, Validators.maxLength(40)]),
        'deadlineTimestamp': new FormControl(null, [Validators.required, Validators.maxLength(10)]),
        'deadlineTimestampTime': new FormControl(null, [Validators.maxLength(10)]),
        'message': new FormControl(null, [Validators.maxLength(300)]),
        'forUserId': new FormControl(null, [Validators.required, Validators.min(1)])
      });
    }
    this.activeEntrySubscription = this.store.select('calendarEntry').subscribe(
      (entry: ICalendarEntryState) => {
        if (this.mode == 'change') {
          let ctimesp = {year: entry.activeCalendarEntry.deadline_timestamp.getFullYear(), month: entry.activeCalendarEntry.deadline_timestamp.getMonth()+1, day: entry.activeCalendarEntry.deadline_timestamp.getDate()};
          this.deadline = entry.activeCalendarEntry.deadline_timestamp;
          this.calendarEntryForm.setValue({
            deadlineTimestamp: ctimesp,
            deadlineTimestampTime: {hour: entry.activeCalendarEntry.deadline_hour, minute: entry.activeCalendarEntry.deadline_minute},
            title: entry.activeCalendarEntry.title,
            forUserId: entry.activeCalendarEntry.for_user_id,
            message: entry.activeCalendarEntry.message
          });
          this.entryID = entry.activeCalendarEntry.id;
        }
      }
    );
    this.calendarEntryForm.valueChanges.subscribe((form) => {
      if (form.deadlineTimestamp != null) {
        this.deadline = new Date(form.deadlineTimestamp.year + "-" + form.deadlineTimestamp.month + "-" + form.deadlineTimestamp.day);
      }
      if (form.deadlineTimestampTime != null) {
        this.deadline_time = this.calendarEntryService.convertTimeForOutput(form.deadlineTimestampTime.hour, form.deadlineTimestampTime.minute);
      }
   });
  }

  onSubmit() {
    let forUserId: number;
      if (this.mode == 'own') {
        forUserId = this.authUser.user.id;
      }
      else {
        forUserId = +this.calendarEntryForm.controls.forUserId.value;
      }
      let message = this.calendarEntryForm.controls.message.value;
      if (message == null) {
        message = '';
      }
      let hour = null;
      let minute = null;
      if (this.calendarEntryForm.controls.deadlineTimestampTime.value) {
        hour = this.calendarEntryForm.controls.deadlineTimestampTime.value.hour;
        minute = this.calendarEntryForm.controls.deadlineTimestampTime.value.minute;
      }
      let entry: ICalendarEntry = <ICalendarEntry>{
        id: this.entryID,
        title: this.calendarEntryForm.controls.title.value,
        deadline_timestamp: this.deadline,
        deadline_hour: hour,
        deadline_minute: minute,
        created_timestamp: new Date(),
        message: message,
        for_user_id: forUserId,
        created_by_user_id: this.authUser.user.id,
        completed: false,
      };
    if (this.mode != 'change') {
      // create new entry
      this.store.dispatch(new CalendarEntryActions.AddEntryToEntrylist(entry));
      this.store.dispatch(new UiActions.SetActivePage({module:MODULES.CALENDAR, page: MODULE_PAGES.CALENDAR.LIST}));
      this.calendarEntryService.calendarEntryCreate(entry);
    }
    else {
      // todo update in store
      this.store.dispatch(new UiActions.SetActivePage({module:MODULES.CALENDAR, page: MODULE_PAGES.CALENDAR.LIST}));
      this.calendarEntryService.calendarEntrySave(entry);
    }
  }

  onRevert() {
    this.calendarEntryForm.reset();
    this.store.dispatch(new UiActions.SetActivePage({module:MODULES.CALENDAR, page: MODULE_PAGES.CALENDAR.LIST}));
  }

  ngOnDestroy() {
    this.authUserSub.unsubscribe();
    this.usersSub.unsubscribe();
    this.activeEntrySubscription.unsubscribe();
  }
}
