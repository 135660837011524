import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuskehrService} from '../../services/auskehr.service';
import {captureMessage} from '@sentry/browser';
import {AuskehrZahlungInterface} from '../auskehr.interface';

@Component({
  selector: 'app-edit-auskehr-quelle',
  templateUrl: './edit-auskehr-zahlung.component.html',
  styleUrls: ['./edit-auskehr-zahlung.css']
})
export class EditAuskehrZahlungComponent implements OnInit {
  editMode = false;
  form: FormGroup;
  saving = false;
  caseId = null;
  institution = null;
  zahlung: AuskehrZahlungInterface = null;

  constructor(private activeModal: NgbActiveModal, private auskehrService: AuskehrService) {
    this.form = new FormGroup({
      text: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      date: new FormControl('', [Validators.required]),
      sum: new FormControl(null),
      sum_as_number: new FormControl(0),
      sum_formatted: new FormControl('€ 0,00'),
      sum_invalid: new FormControl(true),
      sum_at_death: new FormControl(null),
      sum_at_death_as_number: new FormControl(0),
      sum_at_death_formatted: new FormControl('€ 0,00'),
      sum_at_death_invalid: new FormControl(true),
      item_received: new FormControl(false),
      completed: new FormControl(0)
    });
    this.form.controls.sum.valueChanges.subscribe(
      (newValue) => {
        let transformedValue = '';
        if (newValue === null || newValue === undefined || newValue === '') {
          this.form.patchValue({
            sum_formatted: 'ungültig',
            sum_as_number: 0,
            sum_invalid: true
          });
          return;
        }
        if (typeof newValue !== 'string') {
          newValue = newValue.toString();
        }
        if (newValue.match(/^[+\-0-9,.()]+$/g) === null) {
          this.form.patchValue({
            sum_formatted: 'ungültig',
            sum_as_number: 0,
            sum_invalid: true
          });
          return;
        }
        const countKomma = newValue.split(',').length - 1;
        let countPoint = newValue.split('.').length - 1;
        if (countKomma === 1 && countPoint === 1) {
          if (newValue.indexOf('.') + 4 === newValue.indexOf(',')) {
            newValue = newValue.replace('.', '')
            countPoint = newValue.split('.').length - 1;
          }
        }
        let frontString = '';
        let backString = '';
        if (countKomma + countPoint > 1) {
          this.form.patchValue({
            sum_formatted: 'ungültig',
            sum_as_number: 0,
            sum_invalid: true
          });
          return
        }
        if (countKomma + countPoint === 0) {
          transformedValue = `€ ${newValue},00`;
          this.form.patchValue({
            sum_as_number: +newValue,
          });
        }
        if (countKomma + countPoint === 1) {
          let spl: string[];
          if (countKomma === 1) {
            spl = newValue.split(',', 2);
          } else {
            spl = newValue.split('.', 2);
          }
          frontString = spl[0];
          backString = spl[1];
          if (backString === null || backString === undefined) {
            backString = '00';
          }
          if (backString.length === 1) {
            backString = `${backString}0`;
          }
          if (backString.length === 0) {
            backString = `${backString}00`;
          }
          if (backString.length > 2) {
            this.form.patchValue({
              sum_formatted: 'ungültig',
              sum_as_number: 0,
              sum_invalid: true
            });
            return;
          }
          if (frontString.length === 0) {
            frontString = '0';
          }
          transformedValue = `€ ${frontString},${backString}`;
           this.form.patchValue({
            sum_as_number: +frontString + (+backString / 100),
          });
        }
        this.form.patchValue({
              sum_formatted: transformedValue,
              sum_invalid: false
            });
    });
    this.form.controls.sum_at_death.valueChanges.subscribe(
      (newValue) => {
        let transformedValue = '';
        if (newValue === null || newValue === undefined || newValue === '') {
          this.form.patchValue({
            sum_at_death_formatted: 'ungültig',
            sum_at_death_as_number: 0,
            sum_at_death_invalid: true
          });
          return;
        }
        if (typeof newValue !== 'string') {
          newValue = newValue.toString();
        }
        if (newValue.match(/^[+\-0-9,.()]+$/g) === null) {
          this.form.patchValue({
            sum_at_death_formatted: 'ungültig',
            sum_at_death_as_number: 0,
            sum_at_death_invalid: true
          });
          return;
        }
        const countKomma = newValue.split(',').length - 1;
        let countPoint = newValue.split('.').length - 1;
        if (countKomma === 1 && countPoint === 1) {
          if (newValue.indexOf('.') + 4 === newValue.indexOf(',')) {
            newValue = newValue.replace('.', '')
            countPoint = newValue.split('.').length - 1;
          }
        }
        let frontString = '';
        let backString = '';
        if (countKomma + countPoint > 1) {
          this.form.patchValue({
            sum_at_death_formatted: 'ungültig',
            sum_at_death_as_number: 0,
            sum_at_death_invalid: true
          });
          return
        }
        if (countKomma + countPoint === 0) {
          transformedValue = `€ ${newValue},00`;
          this.form.patchValue({
            sum_at_death_as_number: +newValue,
          });
        }
        if (countKomma + countPoint === 1) {
          let spl: string[];
          if (countKomma === 1) {
            spl = newValue.split(',', 2);
          } else {
            spl = newValue.split('.', 2);
          }
          frontString = spl[0];
          backString = spl[1];
          if (backString === null || backString === undefined) {
            backString = '00';
          }
          if (backString.length === 1) {
            backString = `${backString}0`;
          }
          if (backString.length === 0) {
            backString = `${backString}00`;
          }
          if (backString.length > 2) {
            this.form.patchValue({
              sum_at_death_formatted: 'ungültig',
              sum_at_death_as_number: 0,
              sum_at_death_invalid: true
            });
            return;
          }
          if (frontString.length === 0) {
            frontString = '0';
          }
          transformedValue = `€ ${frontString},${backString}`;
           this.form.patchValue({
            sum_at_death_as_number: +frontString + (+backString / 100),
          });
        }
        this.form.patchValue({
              sum_at_death_formatted: transformedValue,
              sum_at_death_invalid: false
            });
    });
  }

  ngOnInit() {
    if (this.editMode) {
      this.form.patchValue({
        text: this.zahlung.text,
        sum: this.zahlung.sum,
        sum_at_death: this.zahlung.sum_at_death,
        item_received: this.zahlung.item_received,
        date: this.zahlung.date
      });
      if (this.zahlung.item_received || +this.zahlung.item_received === 1 || this.zahlung.item_received.toString() === 'true') {
        this.form.patchValue({ item_received: 1 });
      } else {
        this.form.patchValue({ item_received: 0 });
      }
    }
  }

  cancel() {
    this.activeModal.close();
  }

  save() {
    if (!this.editMode) {
      this.saving = true;
      this.auskehrService.saveZahlung(
        <AuskehrZahlungInterface>{
          text: this.form.controls.text.value,
          sum: this.form.controls.sum_as_number.value,
          sum_at_death: this.form.controls.sum_at_death_as_number.value,
          item_received: this.form.controls.item_received.value,
          date: this.form.controls.date.value,
        },
        this.institution.id,
        this.caseId,
        this.editMode
      ).subscribe(
        (result) => {
          this.auskehrService.saveZahlungLocally(result, this.institution.id);
          this.saving = false;
          this.activeModal.close();
        },
        (error) => {
          captureMessage('error saving auskehr zahlung');
          console.log(error);
          this.saving = false;
        },
        () => {
        }
      );
    } else {
      this.saving = true;
      this.auskehrService.saveZahlung(
        <AuskehrZahlungInterface>{
          id: this.zahlung.id,
          text: this.form.controls.text.value,
          sum: this.form.controls.sum_as_number.value,
          sum_at_death: this.form.controls.sum_at_death_as_number.value,
          item_received: this.form.controls.item_received.value,
          date: this.form.controls.date.value,
        },
        this.institution.id,
        this.caseId,
        this.editMode
      ).subscribe(
        (result) => {
          this.auskehrService.saveZahlungLocally(result, this.institution.id);
          this.saving = false;
          this.activeModal.close();
        },
        (error) => {
          captureMessage('error saving auskehr zahlung');
          console.log(error);
          this.saving = false;
        },
        () => {
        }
      );
    }
  }
}

