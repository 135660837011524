import {Component, Input} from '@angular/core';
import {IModulePages, MODULES, MODULE_PAGES} from "../../routing/routes.model";
import {IUi} from "../../models/ui.interface";

@Component({
  selector: 'user-entry',
  templateUrl: './user-entry.component.html',
  styleUrls: ['./user-entry.component.css']
})

export class UserEntryComponent {
  @Input() ui: {ui: IUi};
  MODULE_PAGES: IModulePages;

  ngOnInit() {
    this.MODULE_PAGES = MODULE_PAGES;
  }
}
