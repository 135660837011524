import * as UiActions from './ui.actions';
import {MODULE_PAGES, MODULES} from '../routing/routes.model';
import {IUi} from '../models/ui.interface';


const initialState = {
  ui: {
    userNotifications: [],
    routing: {
      activeModule: MODULES.LOGIN,
      activePageInModule: {
        CASES: MODULE_PAGES.CASES.CASESEARCH,
        CALENDAR: MODULE_PAGES.CALENDAR.LIST,
        PERSONS: MODULE_PAGES.PERSONS.PERSONLIST,
        ADMINISTRATION: MODULE_PAGES.ADMINISTRATION.USER,
        NAMENSVERZEICHNISSE: MODULE_PAGES.NAMENSVERZEICHNISSE.SEARCH,
        ADDRESSBOOK: MODULE_PAGES.ADDRESSBOOK.SEARCH,
        USERS: MODULE_PAGES.USERS.USER_LIST
      }
    },
    scrollCommand: {
      toTop: false,
      toFiles: false,
      toPersons: false,
      toNotes: false,
    }
  }
};

export function uiReducer(state = initialState, action: UiActions.UiActions) {
  switch (action.type) {

    case UiActions.REPLACE_USER_NOTIFICATIONS:
      return {
        ...state,
        ui: {
          ...state.ui,
          userNotifications: action.payload
        }
      };

    case UiActions.SET_ACTIVE_MODULE:
      return {
        ...state,
        ui: {
          ...state.ui,
          routing: {
            ...state.ui.routing, activeModule: action.payload.moduleName
          }
        }
      };

    case UiActions.SET_ACTIVE_PAGE:
      const module: string = action.payload.module;
      const activePages = {...state.ui.routing.activePageInModule};
      activePages[module] = action.payload.page;
      return {
        ...state,
        ui: {
          ...state.ui,
          routing: {
            ...state.ui.routing,
            activePageInModule: activePages
          }
        }
      };

    case UiActions.SET_ACTIVE_PAGES_TO_INITIAL_STATE:
      return {
        ...state,
        ui: {
          ...state.ui,
          routing: initialState.ui.routing
        }
      };

    case UiActions.TRIGGER_SCROLL_COMMAND:
      return {
        ...state,
        ui: {
          ...state.ui,
          scrollCommand: action.payload
        }
      };

    default:
      return state;
  }
}
