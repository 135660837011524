import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

declare var require: any;
const { version } = require('../../../package.json');

@Injectable()
export class ConstantsService {
  debug = true; // enables debug output if set to true

  constructor(private http: HttpClient) { }

  getApiVersion(): string {
    return version;
  }

  // API Endpoint for REST API Calls
  getApiEndpoint(): string {
    return this.getApiServer() + 'backend';
  }

  getWebsocketServerAutoparserChannel(): string {
     return this.getWebsocketServer() + 'ws/message/autoparser/';
  }

  getWebsocketServerDataUpdateNotifyChannel() {
    return this.getWebsocketServer() + 'ws/message/maincom/';
  }

  getWebsocketServerDataUpdateChannelForUser() {
    return this.getWebsocketServer() + 'ws/data-update/';
  }

  isTest() {
    return environment.isTest;
  }

  getApiServer(): string {
    return environment.apiServer;
  }

  getWebsocketServer(): string {
    return environment.websocketServer
  }

  getFileUploadServer(): string {
    return environment.fileUploadServer;
  }

  // get header for login call
  getHttpLoginOptions() {
    return new HttpHeaders({'Accept': 'application/json', 'Content-Type': 'application/json'});
  }

  // get header for REST API Calls
  getHttpOptions(token = '') {
    if (token === '') {
      token = localStorage.getItem('token'); // if no token is given use the one from local storage
    }
    return new HttpHeaders({'Accept': 'application/json', 'Content-Type': 'application/json',
      'Authorization': 'Token ' + token});
  }

  // get header for file upload Calls
  getHttpFileUploadOptions(token = '') {
    if (token === '') {
      token = localStorage.getItem('token'); // if no token is given use the one from local storage
    }
    return new HttpHeaders({'Authorization': 'Token ' + token});
  }

  // get headers for post requests
  getHttpFormPostOptions(token = '') {
    if (token === '') {
      token = localStorage.getItem('token'); // if no token is given use the one from local storage
    }
    return new HttpHeaders({'Accept': 'application/json', 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Authorization': 'Token ' + token});
  }

  getHeadersForFileUpload(token = '') { // this is for the new upload using ng2-file-upload
    if (token === '' ) {
      token = localStorage.getItem('token'); // if no token is given use the one from local storage
    }
    return {name: 'Authorization', value: 'Token ' + token};
  }

  // get backend API information
  getBackendApiInformation() {
    return this.http.get<any>(`${this.getApiEndpoint()}/version/`, {headers: this.getHttpOptions()});
  }
}
