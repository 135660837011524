import {IUserState} from "./user-state.interface";
import * as UserActions from "./user.actions";

const initialState: IUserState = {
  users: [],
  activeUser: null,
};

export function userReducer(state = initialState, action: UserActions.UserActions) {
  switch(action.type) {

    case UserActions.REPLACE_USERS_IN_USERLIST:
      return {
        ...state,
        users: action.payload
      };

    case UserActions.ADD_USER_TO_USERLIST:
      return {
        ...state,
        users: [...state.users, action.payload]
      };

    case UserActions.SET_ACTIVE_USER:
      return {
        ...state,
        activeUser: action.payload
      };

    default:
      return state;
  }
}
