import {Action} from "@ngrx/store";
import {IAppNotification} from "../app-notifications/app-notification.interface";

export const ADD_APP_NOTIFICATION = 'ADD_APP_NOTIFICATION';
export const UPDATE_APP_NOTIFICATION = 'UPDATE_APP_NOTIFICATION';
export const CLEAR_APP_NOTIFICATION_SLOT = 'CLEAR_APP_NOTIFICATION_SLOT';

export class AddAppNotification implements Action {
  readonly type = ADD_APP_NOTIFICATION;
  constructor(public payload: IAppNotification) {}
}
export class UpdateAppNotification implements Action {
  readonly type = UPDATE_APP_NOTIFICATION;
  constructor(public payload: IAppNotification) {}
}
export class ClearAppNotificationSlot implements Action {
  readonly type = CLEAR_APP_NOTIFICATION_SLOT;
  constructor(public payload: string) {}
}

export type AppNotificationActions = AddAppNotification | UpdateAppNotification | ClearAppNotificationSlot;
