import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map, tap} from 'rxjs/operators';
import * as GeneratedDocumentActions from './generated-document.actions';
import {Store} from '@ngrx/store';
import {IGeneratedDocumentState} from "./generated-document-state.interface";
import {GeneratedDocumentService} from "../services/generated-document.service";



@Injectable()
export class GeneratedDocumentEffects {

  @Effect({dispatch: false})
  reloadDocumentCategories = this.actions.pipe(ofType(GeneratedDocumentActions.TRIGGER_RELOAD_GENERATED_DOCUMENT_CATEGORIES),
    (tap(() => {
      this.generateDocumentService.reloadCategories();
    })
  ));

  constructor(private actions: Actions,
              private generateDocumentService: GeneratedDocumentService,
              private store: Store<{generatedDocument: IGeneratedDocumentState}>) {}
}
