import {Component, OnInit, Renderer2, Input, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import { CaseService } from '../../services/case.service';
import { UserService } from '../../services/user.service';
import {isNullOrUndefined} from "util";
import {Store} from "@ngrx/store";
import {RightsManagementService} from "../../services/rights-management.service";
import {IUi} from "../../models/ui.interface";
import {ICase} from "../case.interface";
import {IUser} from "../../models/user.interface";
import {IUserState} from "../../store/user-state.interface";
import * as CaseActions from "../../store/case.actions";
import {ICaseState} from "../../store/case-state.interface";
import {ICaseStatus} from "../../models/case-status.interface";
import {IAuthUser} from "../../models/auth-user.interface";


@Component({
  selector: 'case-show-list',
  templateUrl: './case-list-show.component.html',
  styleUrls: ['./case-list-show.component.css']
})

export class CaseListShowComponent implements OnInit, OnDestroy {
  Math: any = Math;
  @Input() mode: number; // 1= nicht zugeordnete Fälle, 2= neue Fälle, 3= Recherche als Fallführer, 4= Recherche als Teammitglied, (5= Fiskus-Alarm), 6= Börse, 7=Pool 8=Cases For a different user, 10=Junior-Pool
  @Input() for_user_id: number;
  headline = '';
  cases: ICase[] = [];
  copiedCases: {cases: ICase[]};
  caseStatus: ICaseStatus[];
  caseStatusSub: Subscription;
  users: IUser[] = [];
  usersStateSub: Subscription;
  authUser: IAuthUser;
  authUserSub: Subscription;
  pagination = 0;
  resultCount = 0;
  recalculating = false;

  constructor(private caseService: CaseService,
              private store: Store<{ui: IUi, users: IUserState, cases: ICaseState, caseStatus: ICaseStatus[], authUser: {authUser: IAuthUser}}>,
              private renderer: Renderer2,
              public rightsManagementService: RightsManagementService,
              private userService: UserService) {
    this.authUserSub = this.store.select('authUser').subscribe(
      (authUser) => {this.authUser = authUser.authUser; });
  }

  ngOnInit() {
    if (+this.mode === 1) {
      this.caseService.getCasesNichtZugeordnet().subscribe(
        (result) => {
          this.cases = result;
          this.copyAndRunFilters(); },
        (error) => { console.log('error', error)},
        () => {}
      );
      this.headline = 'nicht zugeordnete Fälle';
    }
    if (+this.mode === 2) {
      this.caseService.getCasesNeu().subscribe(
        (result) => {
          this.cases = result;
          this.copyAndRunFilters(); },
        (error) => { console.log('error', error)},
        () => {}
      );
      this.headline = 'neue Fälle';
    }
    if (+this.mode === 3) {
      this.caseService.getCasesRechercheAsTeamLeader().subscribe(
        (result) => {
          this.cases = result;
          this.copyAndRunFilters(); },
        (error) => { console.log('error', error)},
        () => {}
      );
      this.headline = 'Recherche (als Fallführer)';
    }
    if (+this.mode === 4) {
      this.caseService.getCasesRechercheAsTeamMember().subscribe(
        (result) => {
          this.cases = result;
          this.copyAndRunFilters(); },
        (error) => { console.log('error', error)},
        () => {}
      );
      this.headline = 'Recherche (als Teammitglied)';
    }

    if (+this.mode === 6) {
      this.caseService.getCasesBoerse().subscribe(
        (result) => {
          this.cases = result;
          this.copyAndRunFilters(); },
        (error) => { console.log('error', error)},
        () => {}
      );
      this.headline = '';
    }
    if (+this.mode === 7) {
      this.caseService.getCasesPool().subscribe(
        (result) => {
          this.cases = result;
          this.copyAndRunFilters(); },
        (error) => { console.log('error', error)},
        () => {}
      );
      this.headline = '';
    }
    if (+this.mode === 8) {
      this.headline = `Betreute Fälle von ${this.userService.getUserName(this.for_user_id)}`;
      this.caseService.getCasesOfJunior(this.for_user_id).subscribe(
        (result) => {
          this.cases = result;
          this.copyAndRunFilters(); },
        (error) => { console.log('error', error)},
        () => {}
      );
    }
    this.usersStateSub = this.store.select('users').subscribe(
      (usersState) => {
        this.users = usersState.users;
        this.copyAndRunFilters();
      }
    );
    this.caseStatusSub = this.store.select('caseStatus').subscribe(
      (caseStatus) => {
        this.caseStatus = caseStatus;
        this.copyAndRunFilters();
      }
    );
  }

  ngOnDestroy() {
    this.authUserSub.unsubscribe();
    this.caseStatusSub.unsubscribe();
    this.usersStateSub.unsubscribe();
  }

  copyAndRunFilters() {
    this.recalculating = true;
    let filteredCases: ICase[] = [...this.cases];

    this.resultCount = filteredCases.length;
    if (filteredCases.length > 100) {
      const start = this.pagination * 100;
      const stop = (this.pagination + 1) * 100;
      filteredCases = filteredCases.slice(start, stop);
    }
    this.copiedCases = {cases: filteredCases};
    this.recalculating = false;
  }

  clearMessages() {
    this.caseService.clearMessages();
  }

  onTableClicked(item: ICase) {
    this.caseService.goToCasedetailId(item.id, item.last_name);
  }

  paginationUp() {
    if ((this.pagination + 1) * 100 < this.resultCount) {
      this.pagination++;
      this.copyAndRunFilters();
    }
  }

  paginationDown() {
    if (this.pagination > 0) {
      this.pagination--;
      this.copyAndRunFilters();
    }
  }
}
