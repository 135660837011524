import {Action} from "@ngrx/store";
import {ITeamMember} from "../cases/team-member.interface";

export const REPLACE_TEAM_MEMBER_LIST_FOR_CURRENT_CASE = 'REPLACE_TEAM_MEMBER_LIST_FOR_CURRENT_CASE';

export class ReplaceTeamMemberListForCurrentCase implements Action {
  readonly type = REPLACE_TEAM_MEMBER_LIST_FOR_CURRENT_CASE;
  constructor(public payload: ITeamMember[]) {}
}

export type TeamMemberActions = ReplaceTeamMemberListForCurrentCase;

