import * as AddressbookEntryActions from './addressbook-entry.actions';
import {IAddressbookEntryState} from "./addressbook-entry-state.interface";

const initialState: IAddressbookEntryState = {
  addressbookEntries: [],
  activeAddressbookEntry: null
};

export function addressbookEntryReducer(state = initialState, action: AddressbookEntryActions.AddressbookEntryActions) {
  switch(action.type) {

    case AddressbookEntryActions.SET_ACTIVE_ADDRESSBOOK_ENTRY:
      return {
        ...state,
        activeAddressbookEntry: action.payload
      };

    default:
      return state;
  }
}
