import {Component} from '@angular/core';
import {IUrkundenFileRef, IUrkundenFileRefForPerson} from '../urkunden-file-ref.interface';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import {IFileUpload} from '../../files/file-upload.interface';
import {FileUploadService} from '../../services/file-upload.service';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {StammbaumService} from '../../services/stammbaum.service';
import {UserService} from '../../services/user.service';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {Store} from '@ngrx/store';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {StammbaumTreeUrkundenUploadComponent} from '../stammbaum-tree-urkunden-upload/stammbaum-tree-urkunden-upload.component';

@Component({
  selector: 'urkunden-popup',
  templateUrl: './urkunden-popup.component.html',
  styleUrls: ['./urkunden-popup.component.css']
})
export class UrkundenPopupComponent {
  urkunden: IUrkundenFileRefForPerson[] = [];
  person: IStammbaumPerson = null;

  constructor(private fileUploadService: FileUploadService,
              private ngbActiveModal: NgbActiveModal,
              private ngbModal: NgbModal,
              public stammbaumService: StammbaumService,
              public userService: UserService,
              private store: Store<{stammbaum: IStammbaumState}>) {}

  showUrkunde(urkunde: IFileUpload) {
    if (urkunde) {
      this.fileUploadService.showFile(urkunde);
    }
  }

  editUrkunde(urkunde: IUrkundenFileRefForPerson) {
    // this.store.dispatch(new StammbaumActions.ReplaceUrkundeToEdit(urkunde));
    // this.store.dispatch(new StammbaumActions.ReplacePersonForUpload(this.person)); // for deleting double-popup bug, TODO: still needed?!
    console.log('EDIT CALLED');
    this.ngbActiveModal.close();
    const ngbModalRef: NgbModalRef = this.ngbModal.open(StammbaumTreeUrkundenUploadComponent, {backdrop: 'static', size: 'lg'});
    ngbModalRef.componentInstance.urkunde = urkunde;
    ngbModalRef.componentInstance.person = this.person;
    ngbModalRef.componentInstance.editMode = true;
  }

  deleteUrkunde(urkunde: IUrkundenFileRefForPerson) {
    const modalRef: NgbModalRef = this.ngbModal.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Bestätigung';
    modalRef.componentInstance.message = `Wollen Sie diese Urkunde wirklich löschen?`;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.stammbaumService.deleteUrkunden(urkunde.id).subscribe(
            (reply) => {
              this.urkunden = this.urkunden.filter(x => x.id !== urkunde.id);
            },
            (error) => {
              console.log(error); // todo implement error handling
            })
        }
      },
      () => {}
    );
  }

  getStaKiBeh(urkunde: IUrkundenFileRefForPerson) {
    if (!urkunde) {
      return '';
    }
    if (urkunde.standesamt) {
      return 'STA ' + urkunde.standesamt.name;
    }
    let kibeh = '';
    if (urkunde.kirche_oder_behoerde_freitext) {
      kibeh += urkunde.kirche_oder_behoerde_freitext + ' ';
    }
    if (urkunde.kirche_oder_behoerde_ort) {
      kibeh += urkunde.kirche_oder_behoerde_ort;
    }
    return kibeh;
  }

  close() {
    this.ngbActiveModal.close();
  }
}
