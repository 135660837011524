import {Component, Input, OnInit} from '@angular/core';
import {ICase} from '../case.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RightsManagementService} from '../../services/rights-management.service';
import {CasestatusService} from '../../services/casestatus.service';
import {CaseService} from "../../services/case.service";

@Component({
  selector: 'case-change-status',
  templateUrl: './case-change-status.component.html',
  styleUrls: ['./case-change-status.component.css']
})
export class CaseChangeStatusComponent implements OnInit {
  activeCase: ICase = null;
  caseStatusOptions = [];
  caseStatusSelected: number;
  grund: number;
  erlaeuterung: string;
  ablagegrundOptions = [];

  constructor(private ngbActiveModal: NgbActiveModal,
              private caseStatusService: CasestatusService,
              private caseService: CaseService,
              private rightsManagementService: RightsManagementService) {}

  ngOnInit() {
    this.getCaseStatusOptions();
    this.caseStatusSelected = +this.activeCase.status;
    this.ablagegrundOptions = this.caseService.getAblagegrundOptions();
  }

  getCaseStatusName(status) {
    return this.caseStatusService.getCaseStatusText(status);
  }

  ablageSelected() {
    if (!this.caseStatusSelected) { return false; }
    const sel = this.caseStatusOptions.find(x => x.name === 'Ablage');
    if (!sel) { return false; }
    return +this.caseStatusSelected === +sel.to_status;
  }

  getCaseStatusOptions() {
    this.caseStatusOptions = this.rightsManagementService.getAllowedCaseStatusOptions(this.activeCase.status);
  }

  close() {
    this.ngbActiveModal.close(  {status: this.caseStatusSelected, grund: this.grund, erlaeuterung: this.erlaeuterung});
  }

  dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
