import {Component, Input} from "@angular/core";

@Component({
  selector: 'show-date',
  template: `<span style="color: green; font-weight: bold;" *ngIf="value&&value.length==8">{{value.substring(0,2)}}.{{value.substring(2,4)}}.{{value.substring(4,8)}}</span>
      <span *ngIf="!value||value=='false'" class="fa fa-remove" style="color: red;"></span>
      <span *ngIf="(value&&!(value.length==8))&&value!='false'&&value!=''" class="fa fa-question" style="color: orange;"></span>`
})

export class ShowDateComponent {
  @Input() value;
}
