import {Component} from "@angular/core";
import {IAddressbookContact} from "../addressbook-contact.interface";
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AddressbookService} from "../../services/addressbook.service";


@Component({
  selector: 'addressbook-subform-contact',
  templateUrl: './addressbook-subform-contact.component.html',
  styleUrls: ['./addressbook-subform-contact.component.css']
})

export class AddressbookSubformContactComponent {
  contact: IAddressbookContact;
  subForm: FormGroup;
  contactTypes; // todo typing, change, refactor to ngrx/store and loading on change from table/backend!

  constructor(public activeModal: NgbActiveModal,
              private addressbookService: AddressbookService) {}

  ngOnInit() {
    this.addressbookService.contactTypesObs.subscribe(
      (contTypes) => {this.contactTypes = contTypes;} // todo dirty hack, remove/replace
    );
    this.subForm = new FormGroup({
      'details': new FormControl('', [Validators.required, Validators.maxLength(60)]),
      'comment': new FormControl('', [Validators.maxLength(60)]),
      'type': new FormControl(null, [Validators.required]),
    });
    this.subForm.patchValue(this.contact);
  }

  cancel() {
    this.activeModal.close(null);
  }

  delete() {
    this.addressbookService.deleteContact(this.contact.id).subscribe(
    (result) => {
      this.activeModal.close(null);
    },
    (error) => {},
    () => {}
    );
  }

  update() {
    this.addressbookService.updateContact(
      {...this.contact,
        details: this.subForm.controls.details.value,
        comment: this.subForm.controls.comment.value,
        type: this.subForm.controls.type.value
      }
    ).subscribe(
      (result) => {
        this.activeModal.close(result);
        },
      (error) => {},
      () => {}
    );
  }

  create() {
    this.addressbookService.createContact(
      {...this.contact,
        details: this.subForm.controls.details.value,
        comment: this.subForm.controls.comment.value,
        type: this.subForm.controls.type.value
      }
    ).subscribe(
      (result) => {
        this.activeModal.close(result);
      },
      (error) => {},
      () => {}
    );
  }
}
