import {Injectable, OnDestroy} from '@angular/core';
import {ConstantsService} from './constants.service';
import {HttpClient} from '@angular/common/http';
import {IStammbaumPerson} from '../stammbaum/stammbaum-person.interface';
import {Store} from '@ngrx/store';
import {IStammbaumState} from '../store/stammbaum-state.interface';
import * as StammbaumActions from '../store/stammbaum.actions';
import {Subscription} from 'rxjs';
import {ICaseState} from '../store/case-state.interface';
import {IUrkundenFileRef, IUrkundenFileRefForPerson} from '../stammbaum/urkunden-file-ref.interface';
import {IStammbaumMarriage} from '../stammbaum/stammbaum-marriage.interface';
import {IStammbaum} from '../stammbaum/stammbaum.interface';
import {CaseService} from './case.service';
import {IStammbaumVersion} from '../stammbaum/stammbaum-version.interface';
import {IFileUpload} from '../files/file-upload.interface';

export enum PersonType {
  'normale Stammbaum-Person' = 0,
  Erblasser = 1,
  Erbe = 2,
  'Erbe (v+v verschickt)' = 3,
  'Erbe (v+v unterschrieben)' = 4,
  'Erbe (nachverstorben)' = 5,
  'Erbeserbe (nachverstorben)' = 6,
  Erbeserbe = 7,
  'ausgeschlagener Erbe' = 8,
  'unwilliger Erbe' = 9,
  Namenstraeger = 10,
  'sonstige Person (nicht im Stammbaum)' = 20,
  'aus Stammbaum entfernte Person' = 30
}

@Injectable()
export class StammbaumService implements OnDestroy {

  urkunden_art_options = <{ id: number, description: string }[]>[
    {id: 0, description: '---bitte auswählen---'},
    {id: 1, description: 'Personenstand (PS)'},
    {id: 2, description: 'Kirchenbuch (KB)'},
    {id: 3, description: 'Urkundenersatz (UE)'}
  ];

  urkunden_art_ps_options = <{ id: number, description: string }[]>[
    {id: 0, description: '---bitte auswählen---'},
    {id: 1, description: 'GU'},
    {id: 2, description: 'HU'},
    {id: 3, description: 'SU'},
  ];
  urkunden_art_kb_options = <{ id: number, description: string }[]>[
    {id: 0, description: '---bitte auswählen---'},
    {id: 11, description: 'Taufe'},
    {id: 12, description: 'Trauung'},
    {id: 13, description: 'Begräbnis'},
  ];
  urkunden_art_ue_options = <{ id: number, description: string }[]>[
    {id: 0, description: '---bitte auswählen---'},
    {id: 21, description: 'Todesfallanzeige'},
    {id: 22, description: 'Sammelakte (1 Person)'},
    {id: 23, description: 'Sammelakte (2 Personen)'},
    {id: 24, description: 'EMK (1 Person)'},
    {id: 25, description: 'EMK (2 Personen)'},
    {id: 26, description: 'Negativbescheinigung für GU'},
    {id: 27, description: 'Negativbescheinigung für HU'},
    {id: 28, description: 'Negativbescheinigung für SU'},
    {id: 38, description: 'sonstiges (1 Person)'},
    {id: 39, description: 'sonstiges (2 Personen)'},
  ];
  urkunden_status_options = <{ id: number, description: string }[]>[
    {id: 0, description: '---bitte auswählen---'},
    {id: 1, description: 'einfache Kopie'},
    {id: 2, description: 'beglaubigte Kopie'},
    {id: 3, description: 'notariell beglaubigte Kopie'},
    {id: 4, description: 'Original'},
    {id: 5, description: 'aus Akte'},
  ];

  staatsbuergerschaft_options = <{ id: number, description: string }[]>[
    {id: 0, description: '---bitte auswählen---'},
    {id: 1, description: 'deutsch'},
    {id: 2, description: 'polnisch'},
    {id: 3, description: 'tschechisch'},
    {id: 4, description: 'russisch'},
    {id: 5, description: 'unbekannt'},
  ];

  adoptionsland_options = <{ id: number, description: string}[]> [
    {id: 0, description: '---bitte auswählen---'},
    {id: 1, description: 'BRD'},
    {id: 2, description: 'DDR'},
    {id: 3, description: 'Deutsches Reich'},
  ]

  person_type_options = <{id: number, chooseable: boolean, description: string, is_heir: boolean, is_nt: boolean, is_in_tree: boolean}[]>[
    {id: PersonType['normale Stammbaum-Person'], chooseable: true, description: 'normale Stammbaum-Person', is_heir: false, is_nt: false, is_in_tree: true},
    {id: PersonType.Erblasser, chooseable: false, description: 'Erblasser', is_heir: false, is_nt: false, is_in_tree: true},
    {id: PersonType.Erbe, chooseable: true, description: 'Erbe', is_heir: true, is_nt: false, is_in_tree: true},
    {id: PersonType['Erbe (v+v verschickt)'], chooseable: true, description: 'Erbe (V+V verschickt)', is_heir: true, is_nt: false, is_in_tree: true},
    {id: PersonType['Erbe (v+v unterschrieben)'], chooseable: true, description: 'Erbe (V+V unterschrieben)', is_heir: true, is_nt: false, is_in_tree: true},
    {id: PersonType['Erbe (nachverstorben)'], chooseable: true, description: 'Erbe (nachverstorben)', is_heir: true, is_nt: false, is_in_tree: true},
    {id: PersonType['Erbeserbe (nachverstorben)'], chooseable: true, description: 'Erbeserbe (nachverstorben)', is_heir: true, is_nt: false, is_in_tree: true},
    {id: PersonType.Erbeserbe, chooseable: true, description: 'Erbeserbe', is_heir: true, is_nt: false, is_in_tree: true},
    {id: PersonType['ausgeschlagener Erbe'], chooseable: true, description: 'ausgeschlagener Erbe', is_heir: true, is_nt: false, is_in_tree: true},
    {id: PersonType['unwilliger Erbe'], chooseable: true, description: 'unwilliger Erbe', is_heir: true, is_nt: false, is_in_tree: true},
    {id: PersonType.Namenstraeger, chooseable: false, description: 'Namensträger', is_heir: false, is_nt: true, is_in_tree: false},
    {id: PersonType['sonstige Person (nicht im Stammbaum)'], chooseable: false, description: 'sonstige Person (nicht im Stammbaum)', is_heir: false, is_nt: true, is_in_tree: false},
    {id: PersonType['aus Stammbaum entfernte Person'], chooseable: false, description: 'aus Stammbaum entfernte Person', is_heir: false, is_nt: true, is_in_tree: false},
  ];

  activeVersion: IStammbaumVersion = <IStammbaumVersion>{};
  stammbaumState: IStammbaumState;
  stammbaumSub: Subscription;
  activeCaseSub: Subscription;
  heirSub: Subscription;
  activeCaseId: number;
  heirsForActiveCase: IStammbaumPerson[] = [];

  constructor(private constantsService: ConstantsService,
              private httpClient: HttpClient,
              private caseService: CaseService,
              private store: Store<{stammbaum: IStammbaumState, cases: ICaseState}>) {
    this.activeCaseSub = this.store.select('cases').subscribe(
      (cases) => {
        this.activeCaseId = cases.activeCaseId;
      });
    this.heirSub = this.store.select('heirs').subscribe(
      (heirState) => {
        if (heirState && heirState.heirsForActiveCase) {
          this.heirsForActiveCase = heirState.heirsForActiveCase;
        }
      }
    );
    this.stammbaumSub = this.store.select('stammbaum').subscribe(
      (stammbaumState) => {
        if (stammbaumState && stammbaumState.stammbaum && stammbaumState.stammbaum.version) {
          this.activeVersion = stammbaumState.stammbaum.version;
          this.stammbaumState = stammbaumState;
        } else {
          this.stammbaumState = null;
          this.activeVersion = null;
        }
      }
    );
  }

  isSUForELAvailable() {
    if (!this.stammbaumState || !this.stammbaumState.stammbaum) { return false; }
    const el = this.stammbaumState.stammbaum.persons.find(x => x.person_type === PersonType.Erblasser);
    if (!el) { return false; }
    if (!el.urkunden_vorhanden) {
      return false;
    }
    return el.urkunden_vorhanden.has_su;
  }

  getUnwilligeErben() {
    if (!this.stammbaumState || !this.stammbaumState.stammbaum) { return []; }
    return this.stammbaumState.stammbaum.persons.filter(x => x.person_type === PersonType['unwilliger Erbe']);
  }

  getErbenAsOptions() {
    const eopt = [{id: '', description: '-----------------'}];
    if (this.stammbaumState && this.stammbaumState.stammbaum && this.stammbaumState.stammbaum.persons) {
      for (const pers of this.stammbaumState.stammbaum.persons.filter(x => this.isPersonHeir(x))) {
        eopt.push({id: pers.id.toString(), description: `${pers.nachname} ${pers.vorname} ${pers.geburtsdatum}`})
      }
    }
    return eopt;
  }

  getAusschlagungen() {
    if (!this.stammbaumState || !this.stammbaumState.stammbaum) { return []; }
    return this.stammbaumState.stammbaum.persons.filter(x => x.person_type === PersonType['ausgeschlagener Erbe']);
  }

  getNachverstorbeneErben() {
    if (!this.stammbaumState || !this.stammbaumState.stammbaum) { return []; }
    return this.stammbaumState.stammbaum.persons.filter(x =>
      x.person_type === PersonType['Erbe (nachverstorben)'] ||
      x.person_type === PersonType['Erbeserbe (nachverstorben)']
    );
  }

  getStammbaumPersonById(id: number) {
    if (!this.stammbaumState || !this.stammbaumState.stammbaum || !this.stammbaumState.stammbaum.persons) {
      return null;
    }
    return {...this.stammbaumState.stammbaum.persons.find(x => x.id === id)};
  }

  createNewStammbaum(mode) {
    const body = {'case_id': this.activeCaseId, 'mode': mode};
    this.httpClient.post<IStammbaumVersion>(`${this.constantsService.getApiEndpoint()}/v4/stammbaum-create-for-case/`, body, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (result) => {
        console.log(result);
        this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(result));
        this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersionsForCase(false));
      },
      (error) => {
        console.log(error); // todo implement catch errors
      },
      () => {
      }
    );
  }

  loadStammbaumVersion(id: number) {
    const params = `?version_id=${id}`;
    return this.httpClient.get<IStammbaum>(`${this.constantsService.getApiEndpoint()}/v5/stammbaum-version/${params}`, {headers: this.constantsService.getHttpOptions()});
  }

  changeStammbaumVersionSettings(data) {
    return this.httpClient.patch(`${this.constantsService.getApiEndpoint()}/v5/stammbaum-version-display-mode/`, data, {headers: this.constantsService.getHttpOptions()});
  }

  loadStammbaumPersonsAndMarriagesWithoutVersion(case_id: number, include_deleted_marriages = false) {
    const body = {case_id: case_id, include_deleted_marriages: include_deleted_marriages};
    return this.httpClient.post<{persons: IStammbaumPerson[], marriages: IStammbaumMarriage[]}>(`${this.constantsService.getApiEndpoint()}/v5/stammbaum-load-persons-and-marriages-without-version-for-case/`, body,{headers: this.constantsService.getHttpOptions()});
  }

  setStammbaumVersionDefault(id: number) {
    const params = `?version_id=${id}`;
    return this.httpClient.get<IStammbaum>(`${this.constantsService.getApiEndpoint()}/v5/stammbaum-version-as-default/${params}`, {headers: this.constantsService.getHttpOptions()});
  }

  loadStammbaumCheckpoint(id: number) {
    const params = {checkpoint_id: id};
    return this.httpClient.post<IStammbaum>(`${this.constantsService.getApiEndpoint()}/v5/stammbaum-load-checkpoint/`, params, {headers: this.constantsService.getHttpOptions()});
  }

  saveStammbaumCheckpoint(params: {version_id: number, title: string, comment: string}) {
    return this.httpClient.post<IStammbaum>(`${this.constantsService.getApiEndpoint()}/v5/stammbaum-save-checkpoint/`, params, {headers: this.constantsService.getHttpOptions()});
  }

  loadStammbaumVersionsForCase() {
    const params = `?case_id=${this.activeCaseId}`;
    return this.httpClient.get<IStammbaumVersion[]>(`${this.constantsService.getApiEndpoint()}/v5/stammbaum-versions-for-case/${params}`, {headers: this.constantsService.getHttpOptions()});
  }

  updateStammbaumPerson(person: IStammbaumPerson) {
    return this.httpClient.patch<IStammbaumPerson>(`${this.constantsService.getApiEndpoint()}/v3/stammbaum-person/${person.id}/`, person, {headers: this.constantsService.getHttpOptions()});
  }

  updateStammbaumPersonWithoutVersion(person: IStammbaumPerson) {
    return this.httpClient.patch<IStammbaumPerson>(`${this.constantsService.getApiEndpoint()}/v3/stammbaum-person-without-version/${person.id}/`, person, {headers: this.constantsService.getHttpOptions()});
  }

  updateStammbaumMarriage(marriage: IStammbaumMarriage) {
    return this.httpClient.patch<IStammbaumMarriage>(`${this.constantsService.getApiEndpoint()}/v3/stammbaum-marriage/${marriage.id}/`, marriage, {headers: this.constantsService.getHttpOptions()});
  }

  updateStammbaumMarriageWithoutVersion(marriage: IStammbaumMarriage) {
    return this.httpClient.patch<IStammbaumMarriage>(`${this.constantsService.getApiEndpoint()}/v3/stammbaum-marriage-without-version/${marriage.id}/`, marriage, {headers: this.constantsService.getHttpOptions()});
  }

  addStammbaumPerson(person: IStammbaumPerson) {
    return this.httpClient.post<IStammbaumPerson>(`${this.constantsService.getApiEndpoint()}/v3/stammbaum-person/`, person, {headers: this.constantsService.getHttpOptions()});
  }

  addStammbaumPersonWithoutVersion(person: IStammbaumPerson) {
    return this.httpClient.post<IStammbaumPerson>(`${this.constantsService.getApiEndpoint()}/v3/stammbaum-person-without-version/`, person, {headers: this.constantsService.getHttpOptions()});
  }

  addStammbaumMarriage(marriage: IStammbaumMarriage) {
    return this.httpClient.post<IStammbaumMarriage>(`${this.constantsService.getApiEndpoint()}/v3/stammbaum-marriage/`, marriage, {headers: this.constantsService.getHttpOptions()});
  }

  addStammbaumMarriageWithoutVersion(marriage: IStammbaumMarriage) {
    return this.httpClient.post<IStammbaumMarriage>(`${this.constantsService.getApiEndpoint()}/v3/stammbaum-marriage-without-version/`, marriage, {headers: this.constantsService.getHttpOptions()});
  }

  setStammbaumPersonActive(person_id: number) {
    const person: IStammbaumPerson = <IStammbaumPerson>{id: person_id, is_active: true};
    return this.httpClient.patch<IStammbaumPerson>(`${this.constantsService.getApiEndpoint()}/v3/stammbaum-person/${person.id}/`, person, {headers: this.constantsService.getHttpOptions()});
  }

  setStammbaumPersonInactive(person_id: number) {
    const person: IStammbaumPerson = <IStammbaumPerson>{id: person_id, is_active: false};
    return this.httpClient.patch<IStammbaumPerson>(`${this.constantsService.getApiEndpoint()}/v3/stammbaum-person/${person.id}/`, person, {headers: this.constantsService.getHttpOptions()});
  }

  exchangeStammbaumPerson(newID: number, oldID: number) {
    const body = {old: oldID, new: newID};
    return this.httpClient.post(`${this.constantsService.getApiEndpoint()}/v5/stammbaum-exchange-person/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  exchangeStammbaumMarriage(newID: number, oldID: number) {
    const body = {old: oldID, new: newID};
    return this.httpClient.post(`${this.constantsService.getApiEndpoint()}/v5/stammbaum-exchange-marriage/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  deleteStammbaumPerson(person_id: number) {
    const body = {person_id: person_id};
    return this.httpClient.post(`${this.constantsService.getApiEndpoint()}/v5/stammbaum-delete-person/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  deleteStammbaumMarriage(marriage_id: number) {
    return this.httpClient.delete(`${this.constantsService.getApiEndpoint()}/v3/stammbaum-marriage/${marriage_id}/`, {headers: this.constantsService.getHttpOptions()});
  }

  deleteUrkunden(urkunden_id: number) {
    return this.httpClient.delete(`${this.constantsService.getApiEndpoint()}/v3/urkunde-for-stammbaum-person/${urkunden_id}/`, {headers: this.constantsService.getHttpOptions()});
  }

  updateUrkunde(urkunden_id: number, urkunde: IUrkundenFileRef) {
    return this.httpClient.patch(`${this.constantsService.getApiEndpoint()}/v3/urkunde-for-stammbaum-person/${urkunden_id}/`, urkunde, {headers: this.constantsService.getHttpOptions()});
  }

  addChild(first_parent_id: number, second_parent_id: number, child_data: IStammbaumPerson) {
    const body = {
      first_parent_id: first_parent_id,
      second_parent_id: second_parent_id,
      child_data: child_data
    };
    return this.httpClient.post<IStammbaumPerson>(`${this.constantsService.getApiEndpoint()}/v4/stammbaum-child/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  addMarriageAndPerson(person: IStammbaumPerson, marriage: IStammbaumMarriage) {
    const body = {
      person: person,
      marriage: marriage,
      case_id: this.caseService.activeCaseId,
      version_id: this.activeVersion.id
    };
    return this.httpClient.post<IStammbaumPerson>(`${this.constantsService.getApiEndpoint()}/v4/stammbaum-person-and-marriage/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  getUrkundenArtText(id: number) {
    const art = this.urkunden_art_options.find(x => x.id === id);
    if (art && art.id > 0) {
      return art.description;
    } else {
      return '';
    }
  }

  getUrkundenStatusText(id: number) {
    const art = this.urkunden_status_options.find(x => x.id === id);
    if (art && art.id > 0) {
      return art.description;
    } else {
      return '';
    }
  }

  getUrkundenUnterartText(urkunde) {
    const id = urkunde.urkunden_unterart;
    let text = '';
    let art = this.urkunden_art_kb_options.find(x => x.id === id);
    if (art && art.id > 0) {
      text = art.description;
    }
    art = this.urkunden_art_ps_options.find(x => x.id === id);
    if (art && art.id > 0) {
      text = art.description;
    }
    art = this.urkunden_art_ue_options.find(x => x.id === id);
    if (art && art.id > 0) {
      text = art.description;
    }
    return text;
  }

  getPersonTypeText(type_id: number) {
    const pers = this.person_type_options.find(x => x.id === type_id);
    if (pers) {
      return pers.description;
    } else {
      return '';
    }
  }

  getPersonTypeClass(person: IStammbaumPerson) {
    const pers_type = person.person_type;
    if (person.unbekannt) {
      return 'stbp-unbekannt';
    }
    if (pers_type) {
      if (pers_type === PersonType.Erblasser) {
        return 'stbp-erblasser';
      }
      if (pers_type === PersonType['Erbe (nachverstorben)'] || pers_type === PersonType['Erbeserbe (nachverstorben)']) { // nachverstorbener Erbe oder nachverstorbener Erbeserbe
        return 'stbp-erbe-nachverstorben';
      }
      if (pers_type === PersonType.Erbe || pers_type === PersonType.Erbeserbe) { // Erbe oder Erbeserbe
        return 'stbp-searched';
      }
      if (pers_type === PersonType['Erbe (v+v unterschrieben)']) {
        return 'stbp-erbe-v-und-v-zurueck';
      }
      if (pers_type === PersonType['Erbe (v+v verschickt)']) {
        return 'stbp-erbe-v-und-v-sendout';
      }
      if (pers_type === PersonType['ausgeschlagener Erbe']) {
        return 'stbp-erbe-ausgeschlagen';
      }
      if (pers_type === PersonType['unwilliger Erbe']) {
        return 'stbp-erbe-unwillig';
      }
    }
    if (person.wird_gesucht) {
      return 'stbp-searched';
    }
    if (person.nk_in_vierter_eo_nicht_erbberechtigt) {
      return 'stbp-descendants-no-heirs';
    }
    if (person.sicher_keine_nachkommen) {
      return 'stbp-no-descendants';
    }
    return 'stbp-normal';
  }

  isPersonHeir(p: IStammbaumPerson) {
    const personType = this.person_type_options.find(x => x.id === p.person_type);
    if (!personType) { return false; }
    return personType.is_heir;
  }

  isPersonInTree(p: IStammbaumPerson) {
    const personType = this.person_type_options.find(x => x.id === p.person_type);
    if (!personType) { return false; }
    return personType.is_in_tree;
  }

  isPersonNT(p: IStammbaumPerson) {
    const personType = this.person_type_options.find(x => x.id === p.person_type);
    if (!personType) { return false; }
    return personType.is_nt;
  }

  canDisplayPersonDetails(p: IStammbaumPerson) {
    // for the moment: displayed can be details of heirs and NTs
    return this.isPersonHeir(p) || this.isPersonNT(p);
  }

  hasPersonAllHUs(p: IStammbaumPerson) {
    for(const uk of p.urkunden_vorhanden.has_hu) {
      if (uk.needs_hu && !uk.has_hu) { return false; }
    }
    return true;
  }

  hasTrueStammbaum(stammbaumState: IStammbaumState = null) {
    if (stammbaumState == null) {
      if (this.stammbaumState != null) {
        stammbaumState = this.stammbaumState; }
      } else {
      return false;
    }
    if (stammbaumState != null && (stammbaumState.stammbaumVersions != null) && stammbaumState.stammbaumVersions.length > 0) { return true; }
    return false;
  }


  getPersonTypeColor(person: IStammbaumPerson) {
    if (person.unbekannt) {
      return '#b8b8b6';
    }
    const pers_type = person.person_type;
    if (pers_type) {
      if (pers_type === PersonType.Erblasser) { // Erblasser
        return '#ffff5b';
      }
      if (pers_type === PersonType['Erbe (nachverstorben)'] || pers_type === PersonType['Erbeserbe (nachverstorben)']) { // nachverstorbener Erbe oder nachverstorbener Erbeserbe
        return 'greenyellow';
      }
      if (pers_type === PersonType.Erbe || pers_type === PersonType.Erbeserbe) { // Erbe oder Erbeserbe
        return '#4bc1e9';
      }
      if (pers_type === PersonType['Erbe (v+v unterschrieben)']) { // Erbe V+V zurück
        return '#ff8040';
      }
      if (pers_type === PersonType['Erbe (v+v verschickt)']) { // Erbe V+V verschickt
        return '#00bf60';
      }
      if (pers_type === PersonType['ausgeschlagener Erbe']) { // Erbe ausgeschlagen}
        return '#ff0f0f';
      }
      if (pers_type === PersonType['unwilliger Erbe']) {
        return '#fbbf6f';
      }
    }
    if (person.wird_gesucht) {
      return '#4bc1e9';
    }
    if (person.nk_in_vierter_eo_nicht_erbberechtigt) {
      return '#eb8ef2';
    }
    if (person.sicher_keine_nachkommen) {
      return '#ff0f0f';
    }
    return '#f8f8f8';
  }

  getPersonTypeTooltip(person: IStammbaumPerson) {
    if (person.unbekannt) {
      return 'Person unbekannt';
    }
    const pers_type = person.person_type;
    if (pers_type) {
      if (pers_type === PersonType.Erblasser) { // Erblasser
        return 'Erblasser';
      }
      if (pers_type === PersonType.Erbe) { // Erbe
        return 'Erbe';
      }
      if (pers_type === PersonType.Erbeserbe) {
        return 'Erbeserbe'
      }
      if (pers_type === PersonType['Erbe (nachverstorben)']) { // nachverstorbener Erbe
        return 'Erbe - nachverstorben';
      }
      if (pers_type === PersonType['Erbeserbe (nachverstorben)']) {
        return 'Erbeserbe - nachverstorben';
      }
      if (pers_type === PersonType['Erbe (v+v unterschrieben)']) { // Erbe V+V zurück
        return 'Erbe - V&V unterschrieben ';
      }
      if (pers_type === PersonType['Erbe (v+v verschickt)']) { // Erbe V+V verschickt
        return 'Erbe - V&V verschickt';
      }
      if (pers_type === PersonType['ausgeschlagener Erbe']) { // ausgeschlagener Erbe
        return 'ausgeschlagener Erbe';
      }
      if (pers_type === PersonType['ausgeschlagener Erbe']) {
        return 'unwilliger Erbe';
      }
    }
    if (person.wird_gesucht) {
      return 'Person wird gesucht';
    }
    if (person.nk_in_vierter_eo_nicht_erbberechtigt) {
      return 'Nachkommen sind in 4. EO nicht mehr erbberechtigt';
    }
    if (person.sicher_keine_nachkommen) {
      return 'hat sicher keine Nachkommen';

    }
    return '';
  }

  toggleKasten() {
    this.store.dispatch(new StammbaumActions.StammbaumDisplayToggleShowKasten());
  }

  zoomIn() {
    this.store.dispatch(new StammbaumActions.StammbaumDisplayZoomIn());
  }

  zoomOut() {
    this.store.dispatch(new StammbaumActions.StammbaumDisplayZoomOut());
  }

  editModeToStructure() {
    this.store.dispatch(new StammbaumActions.StammbaumSetTreeViewMode('edit-structure'));
  }

  editModeToPersonData() {
    this.store.dispatch(new StammbaumActions.StammbaumSetTreeViewMode('edit-person-data'));
  }

  editModeToExtraInfo() {
    this.store.dispatch(new StammbaumActions.StammbaumSetTreeViewMode('show-extra-info'));
  }

  editModeToMorphStructure() {
    this.store.dispatch(new StammbaumActions.StammbaumSetTreeViewMode('morph-structure'));
  }

  personMoveRight(person_id: number) {
    this._personMove(person_id, true);
  }

  personMoveLeft(person_id: number) {
    this._personMove(person_id, false);
  }

  _personMove(person_id: number, direction_right: boolean) {
    let params = {};
    if (direction_right) {
      params = {'person_id': person_id, 'direction': 'r'};
    } else {
      params = {'person_id': person_id, 'direction': 'l'};
    }
    this.httpClient.post<IStammbaum>(`${this.constantsService.getApiEndpoint()}/v4/stammbaum-move-person/`, params, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (result) => {
      },
      (error) => {
        console.log(error); // todo implement error handling
      },
      () => {
        this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.activeVersion));
      }
    );
  }

  personFlipRight(person_id: number) {
    this._personFlip(person_id, true);
  }

  personFlipLeft(person_id: number) {
    this._personFlip(person_id, false);
  }

  _personFlip(person_id: number, direction_right: boolean) {
    let params = {};
    if (direction_right) {
      params = {'person_id': person_id, 'direction': 'r'};
    } else {
      params = {'person_id': person_id, 'direction': 'l'};
    }
    this.httpClient.post<IStammbaum>(`${this.constantsService.getApiEndpoint()}/v4/stammbaum-flip-person/`, params, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (result) => {
      },
      (error) => {
        console.log(error); // todo implement error handling
      },
      () => {
        this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.activeVersion));
      }
    );
  }

  createPdf(mode: number) {
    if (mode === 1) { this.store.dispatch(new StammbaumActions.StammbaumExecutePrint()); } // this is the quick&dirty screenshot version
    if (mode === 2) { this.createPdfForCourt(); }
  }

  createPdfForCourt() {
    const token = localStorage.getItem('token');
    const submitForm = document.createElement('form');
    submitForm.style.display = 'none';
    submitForm.target = 'Stammbaum-PDF';
    submitForm.method = 'POST';
    submitForm.action = this.constantsService.getApiEndpoint() + '/get-stammbaum-pdf-for-court/';
    const formInput = document.createElement('input');
    formInput.type = 'text';
    formInput.name = 'api_token';
    formInput.value = token;
    const numberInput = document.createElement('input');
    numberInput.type = 'text';
    numberInput.name = 'case_id';
    numberInput.value = this.caseService.getActiveCaseId().toString();
    submitForm.appendChild(formInput);
    submitForm.appendChild(numberInput);
    document.body.appendChild(submitForm);
    const wind = window.open('', 'Stammbaum-PDF');
    if (wind) {
      submitForm.submit();
    }
  }

  fehlendeUrkundenPDF(mode: number) {
    const case_id = this.caseService.getActiveCaseId().toString();
    const pdf_name = 'Liste fehlender Urkunden Fall ' + case_id;
    const token = localStorage.getItem('token');
    const submitForm = document.createElement('form');
    submitForm.style.display = 'none';
    submitForm.target = pdf_name;
    submitForm.method = 'POST';
    submitForm.action = this.constantsService.getApiEndpoint() + '/liste-fehlender-urkunden/';
    const formInput = document.createElement('input');
    formInput.type = 'text';
    formInput.name = 'api_token';
    formInput.value = token;
    const modeInput = document.createElement('input');
    modeInput.type = 'text';
    modeInput.name = 'mode';
    modeInput.value = mode.toString();
    const numberInput = document.createElement('input');
    numberInput.type = 'text';
    numberInput.name = 'case_id';
    numberInput.value = case_id;
    submitForm.appendChild(formInput);
    submitForm.appendChild(numberInput);
    submitForm.appendChild(modeInput);
    document.body.appendChild(submitForm);
    const wind = window.open('', pdf_name);
    if (wind) {
      submitForm.submit();
    }
  }

  personHasSuInAkte(person: IStammbaumPerson) {

  }

  checkIfReferenceDocumentForDisplay(urkunde: IUrkundenFileRef) {
    if (urkunde.refers_to !== null) {
      const refUrk = urkunde.refers_to;
      urkunde.file_ref = refUrk.file_ref;
      urkunde.urkunden_art = refUrk.urkunden_art;
      urkunde.urkunden_unterart = refUrk.urkunden_unterart;
      urkunde.urkunden_status = refUrk.urkunden_status;
      urkunde.urkunden_nummer = refUrk.urkunden_nummer;
      urkunde.kirche_oder_behoerde_freitext = refUrk.kirche_oder_behoerde_freitext;
      urkunde.kirche_oder_behoerde_ort = refUrk.kirche_oder_behoerde_ort;
      urkunde.urkunden_art_freitext = refUrk.urkunden_art_freitext;
      urkunde.standesamt = refUrk.standesamt;
      urkunde.jahr = refUrk.jahr;
      return urkunde;
    }
    return urkunde
  }

  getDuplicateText(urkunde: IUrkundenFileRefForPerson) {
    let type = '';
    if (urkunde.is_su) {
      type = 'SU';
    }
    if (urkunde.is_hu) {
      type = 'HU';
    }
    if (urkunde.is_gu) {
      type = 'GU';
    }
    if (urkunde.refers_to) {
      const refUrk = urkunde.refers_to;
      let refType = '';
      if (refUrk.is_gu) {
        refType = 'GU';
      }
      if (refUrk.is_su) {
        refType = 'SU';
      }
      if (refUrk.is_hu) {
        refType = 'HU';
      }
      return `DUPLIKAT ${refType} ${refUrk.urkunden_nummer}/${refUrk.jahr} als ${type}`;
    }
    return 'Fehlerhafte Zuordnung!';
  }

  getDuplicateUnterartText(urkunde: IUrkundenFileRef) {
    let type = '';
    if (urkunde.is_su) {
      type = 'SU';
    }
    if (urkunde.is_hu) {
      type = 'HU';
    }
    if (urkunde.is_gu) {
      type = 'GU';
    }
    return type;
  }

  ngOnDestroy() {
    this.activeCaseSub.unsubscribe();
    this.heirSub.unsubscribe();
    this.stammbaumSub.unsubscribe();
  }
}
