import {Component, Input, OnInit} from '@angular/core';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {Store} from '@ngrx/store';
import {StammbaumService} from '../../services/stammbaum.service';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StammbaumCheckpointsComponent} from '../stammbaum-checkpoints/stammbaum-checkpoints.component';
import {RightsManagementService} from '../../services/rights-management.service';
import {ConstantsService} from '../../services/constants.service';
import {CaseService} from '../../services/case.service';
import {CreatePdfComponent} from '../create-pdf/create-pdf.component';
import {ChangeTreeSettingsComponent} from '../change-tree-settings/change-tree-settings.component';

@Component({
  selector: 'stammbaum-controls',
  templateUrl: './stammbaum-controls.component.html',
  styleUrls: ['./stammbaum-controls.component.css']
})
export class StammbaumControlsComponent implements OnInit{
  @Input() stammbaumState;
  media_fix_controls = false;  // for not displaying the controls when on cellphone

  constructor(private store: Store<{stammbaum: IStammbaumState}>,
              private ngbModal: NgbModal,
              private constantsService: ConstantsService,
              public rightsManagementService: RightsManagementService,
              private caseService: CaseService,
              public stammbaumService: StammbaumService) {}

  ngOnInit() {
    if (window.innerWidth <= 768) {
      this.media_fix_controls = true;
    }
  }

  toPersonList() {
    this.store.dispatch(new StammbaumActions.SetStammbaumMode('person-list'))
  }

  toCheckpoints() {
    const ngbModalRef = this.ngbModal.open(StammbaumCheckpointsComponent, {backdrop: 'static', size: 'lg'});
    ngbModalRef.componentInstance.stammbaumState = this.stammbaumState;
  }

  changeTreeSettings() {
    const ngbModalRef = this.ngbModal.open(ChangeTreeSettingsComponent);
    ngbModalRef.componentInstance.version = this.stammbaumState.stammbaum.version;
  }

  generatePdf() {
    this.ngbModal.open(CreatePdfComponent);
  }
}
