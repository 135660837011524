import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs/internal/Subscription';
import {Store} from '@ngrx/store';
import {IUi} from '../../models/ui.interface';

@Component({
  selector: 'user-notifiction-popup',
  templateUrl: './user-notification-popup.component.html',
  styleUrls: ['./user-notification-popup.component.css']
})
export class UserNotificationPopupComponent implements OnInit, OnDestroy {
  messages: string[];
  userNotificationStateSub: Subscription;
  count = 0;

  constructor(public activeModal: NgbActiveModal,
              private store: Store<{ui: {ui: IUi}}>) {}

  ngOnInit() {
    this.userNotificationStateSub = this.store.select('ui').subscribe(
      (uiState) => {
        this.messages = uiState.ui.userNotifications;
        this.count += 1;
        if (this.count > 1 && (!this.messages || this.messages.length === 0)) {
          this.activeModal.close(true);
        }
      }
    );
  }

  ngOnDestroy() {}

  cancel() {
    this.activeModal.close(false);
  }

  confirm() {
    this.activeModal.close(true);
  }
}
