import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subscription, timer} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {IAuthUser} from '../models/auth-user.interface';
import {ConstantsService} from './constants.service';
import * as NetworkActions from '../store/network.actions';
import {INetworkState} from '../store/network-state.interface';
import {CaseService} from './case.service';
import {UserroleService} from './userrole.service';
import {UserService} from './user.service';
import {CasestatusService} from './casestatus.service';
import {NoteService} from './note.service';
import {TeamMemberService} from './team-member.service';
import {FileUploadService} from './file-upload.service';
import {CalendarEntryService} from './calendar-entry.service';
import {BundesanzeigerService} from './bundesanzeiger.service';
import * as GeneratedDocumentActions from '../store/generated-document.actions';
import {IClientConnection} from '../models/client-connection.interface';
import {WebsocketService} from './websocket.service';
import {DebugLogService} from './debug-log.service';


const CHECK_FOR_UPDATES_INTERVAL = 1000 * 10; // check for updates every 10 seconds
const INITIAL_DELAY = 1000 * 1;

export interface ITableUpdateTimes {
  // api_endpoints_case: string, todo remove, this has moved into network state
  api_endpoints_userrole: string,
  api_endpoints_ilanotuser: string,
  auth_user: string,
  api_endpoints_casestatus: string,
  api_endpoints_teammember: string,
  api_endpoints_filereference: string,
  api_endpoints_persongroup: string,
  api_endpoints_calendarentry: string,
  api_endpoints_bundesanzeigercase: string,
  api_endpoints_generateddocumentcategory: string
  // todo implement for stammbaum!!!!!
}

@Injectable()
export class NetworkService implements OnDestroy {
  private ws: WebSocket;
  networkState: INetworkState;

  // old below

  keepAliveTimerSubscription: Subscription;
  checkForUpdatedTimerSubscription: Subscription;
  authUserSubscription: Subscription;
  authUser: {authUser: IAuthUser};
  checkUpdatesInProgress = false;
  connectedToServerSubscription: Subscription;
  connectedToServer = false;
  updateTimestamps: ITableUpdateTimes = <ITableUpdateTimes>{ api_endpoints_userrole: null, api_endpoints_ilanotuser: null, auth_user: null,
    api_endpoints_teammember: null, api_endpoints_casestatus: null, api_endpoints_filereference: null, api_endpoints_calendarentry: null,
    api_endpoints_persongroup: null, api_endpoints_bundesanzeigercase: null, api_endpoints_generateddocumentcategory: null};

  constructor(private http: HttpClient,
              private debugLogService: DebugLogService,
              private constantsService: ConstantsService,
              private webSocketService: WebsocketService,
              private caseService: CaseService,
              private userRoleService: UserroleService,
              private userService: UserService,
              private noteService: NoteService,
              private caseStatusService: CasestatusService,
              private teamMemberService: TeamMemberService,
              private fileUploadService: FileUploadService,
              private calendarEntryService: CalendarEntryService,
              private bundesanzeigerService: BundesanzeigerService,
              private store: Store<{authUser: {authUser: IAuthUser}, network: INetworkState}>) {

    this.connectedToServerSubscription = this.store.select('network').subscribe(
      (network) => {
        this.connectedToServer = network.isConnectedToServer;
        this.networkState = network;
      }
    );

    this.authUserSubscription = this.store.select('authUser').subscribe(
      (authUser) => {
        this.authUser = authUser;
        if (authUser && authUser.authUser && authUser.authUser.loggedIn) {
          this.debugLogService.log('network check update timer subscribed');
          this.checkForUpdatedTimerSubscription = timer(INITIAL_DELAY, CHECK_FOR_UPDATES_INTERVAL).subscribe(t => {
            if (this.authUser.authUser.loggedIn && this.connectedToServer && !this.checkUpdatesInProgress) {
              this.checkForUpdates();
            }
          });
        } else {
          if (this.checkForUpdatedTimerSubscription) {
            this.checkForUpdatedTimerSubscription.unsubscribe();
          }
          this.debugLogService.log('network check update timer unsubscribed');
        }
      }
    );
  }

  checkForUpdates() {
    this.checkUpdatesInProgress = true;
    this.http.get(`${this.constantsService.getApiEndpoint()}/v2/tables-update-times/`, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (response) => {
        this.checkUpdatesInProgress = false;
        this.checkUpdateTimes(response);
      },
      (error) => {
        this.checkUpdatesInProgress = false;
      },
      () => {
        this.checkUpdatesInProgress = false;
      }
    );
  }

  checkUpdateTimes(response) {

    for (const el in response) {
      try {
        if (!response[el]) {
          response[el] = '1970-01-01T12:00:00';
        }
      } catch (e) {
        this.debugLogService.log(e);
      }
    }

    // TODO THESE ARE THE NEW METHODS ALL THIS SHOULD ONlY HAPPEN ON WEBSOCKET (RE-)CONNECT!!!
    // TODO OR MAYBE JUST LEAVE IT LIKE THIS, BUT TRIGGER MORE SELDOM? AND ON RECONNECT?
    if (this.networkState) {

      /**
      // api_endpoints__case
      this.debugLogService.log(`api_endpoints__case ${this.networkState.last_update_api_endpoints__case} ${Date.parse(this.networkState.last_update_api_endpoints__case)} ${Date.parse(response.api_endpoints_case)}`);
      if (!this.networkState.last_update_api_endpoints__case || Date.parse(this.networkState.last_update_api_endpoints__case) < Date.parse(response.api_endpoints_case)) {
        this.debugLogService.log('TRIGGER RELOAD CASELIST');
        this.store.dispatch(new NetworkActions.SetLastUpdateApiEndpointsCase(response.api_endpoints_case));
        this.caseService.triggerCaselistReloadFromServer(); // signal to case service to try to reload
      }
       */

      // api_endpoints__person
      // todo reimplement?

      // api_endpoints__note
      this.debugLogService.log(`api_endpoints__note ${this.networkState.last_update_api_endpoints__note} ${Date.parse(this.networkState.last_update_api_endpoints__note)} ${Date.parse(response.api_endpoints_note)}`);
      if (!this.networkState.last_update_api_endpoints__note || Date.parse(this.networkState.last_update_api_endpoints__note) < Date.parse(response.api_endpoints_note)) {
        this.debugLogService.log('TRIGGER RELOAD NOTES, NOTES FOR CASE AND NOTES FOR PERSON');
        this.store.dispatch(new NetworkActions.SetLastUpdateApiEndpointsNote(response.api_endpoints_note));
        this.noteService.triggerNotesReloadFromServer(); // signal to note service to try to reload
        this.noteService.triggerNotesForCaseReloadFromServer(); // signal to note service to try to reload
        this.noteService.triggerNotesForPersonReloadFromServer(); // signal to note service to try to reload
      }
    }


    // OLD BELOW TODO REFACTOR

    // api_endpoints_userrole
    if (this.updateTimestamps.api_endpoints_userrole == null || Date.parse(this.updateTimestamps.api_endpoints_userrole) < Date.parse(response.api_endpoints_userrole)) {
      this.updateTimestamps.api_endpoints_userrole = response.api_endpoints_userrole;
      this.userRoleService.triggerUserRolesReloadFromServer();
    }
    // api_endpoints_ilanotuser and auth_user
    if (this.updateTimestamps.api_endpoints_ilanotuser == null || Date.parse(this.updateTimestamps.api_endpoints_ilanotuser) < Date.parse(response.api_endpoints_ilanotuser)) {
      this.updateTimestamps.api_endpoints_ilanotuser = response.api_endpoints_ilanotuser;
      this.userService.triggerUserlistReloadFromServer();
    }
    if (this.updateTimestamps.auth_user == null || Date.parse(this.updateTimestamps.auth_user) < Date.parse(response.auth_user)) {
      this.updateTimestamps.auth_user = response.auth_user;
      this.userService.triggerUserlistReloadFromServer();
    }
    // api_endpoints_casestatus
    if (this.updateTimestamps.api_endpoints_casestatus == null || Date.parse(this.updateTimestamps.api_endpoints_casestatus) < Date.parse(response.api_endpoints_casestatus)) {
      this.updateTimestamps.api_endpoints_casestatus = response.api_endpoints_casestatus;
      this.caseStatusService.triggerCaseStatusReloadFromServer();
    }

    // api_endpoints_teammember
    if (this.updateTimestamps.api_endpoints_teammember == null || Date.parse(this.updateTimestamps.api_endpoints_teammember) < Date.parse(response.api_endpoints_teammember)) {
      this.updateTimestamps.api_endpoints_teammember = response.api_endpoints_teammember;
      this.teamMemberService.triggerTeamMemberForCaseReloadFromServer(); // signal to teammember service to try to reload
    }
    // api_endpoints_filereference
    if (this.updateTimestamps.api_endpoints_filereference == null || Date.parse(this.updateTimestamps.api_endpoints_filereference) < Date.parse(response.api_endpoints_filereference)) {
      this.updateTimestamps.api_endpoints_filereference = response.api_endpoints_filereference;
      this.fileUploadService.triggerFileUploadForCaseReloadFromServer(); // signal to fileUpload service to try to reload
      this.fileUploadService.triggerFileUploadForPersonReloadFromServer(); // signal to fileUpload service to try to reload
    }
    // api_endpoints_calendarentry
    if (this.updateTimestamps.api_endpoints_calendarentry == null || Date.parse(this.updateTimestamps.api_endpoints_calendarentry) < Date.parse(response.api_endpoints_calendarentry)) {
      this.updateTimestamps.api_endpoints_calendarentry = response.api_endpoints_calendarentry;
      this.calendarEntryService.triggerCalendarEntryReloadFromServer(); // signal to calendarEntry service to try to reload
    }
    // api_endpoints_bundesanzeigercase
    if (this.updateTimestamps.api_endpoints_bundesanzeigercase == null || Date.parse(this.updateTimestamps.api_endpoints_bundesanzeigercase) < Date.parse(response.api_endpoints_bundesanzeigercase)) {
      this.updateTimestamps.api_endpoints_bundesanzeigercase = response.api_endpoints_bundesanzeigercase;
      this.bundesanzeigerService.triggerBundesanzeigerReloadFromServer(); // signal to bundesanzeiger service to try to reload
    }

    // api_endpoints_generateddocumentcategory
    if (this.updateTimestamps.api_endpoints_generateddocumentcategory == null || Date.parse(this.updateTimestamps.api_endpoints_generateddocumentcategory) < Date.parse(response.api_endpoints_generateddocumentcategory)) {
      this.updateTimestamps.api_endpoints_generateddocumentcategory = response.api_endpoints_generateddocumentcategory;
      this.store.dispatch(new GeneratedDocumentActions.TriggerReloadGeneratedDocumentCategories());
    }
  }

  getDatabaseUpdateStatus() {
    return this.http.get<{created: string, filename: string}[]>(`${this.constantsService.getApiEndpoint()}/v3/data-backup-status/`, {headers: this.constantsService.getHttpOptions()});
  }

  getClientConnectionStatus() {
    return this.http.get<IClientConnection[]>(`${this.constantsService.getApiEndpoint()}/websockets/connected-sockets/`, {headers: this.constantsService.getHttpOptions()});
  }

  ngOnDestroy() {
    this.authUserSubscription.unsubscribe();
    this.connectedToServerSubscription.unsubscribe();
    this.keepAliveTimerSubscription.unsubscribe();
    this.checkForUpdatedTimerSubscription.unsubscribe();
  }
}
