import {Component, OnInit, OnDestroy} from '@angular/core';
import {IUser} from '../../models/user.interface';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {IUserState} from '../../store/user-state.interface';
import {IUserRole} from '../../models/userrole.interface';
import * as UiActions from '../../store/ui.actions';
import {IUi} from '../../models/ui.interface';
import {MODULES, MODULE_PAGES} from '../../routing/routes.model';
import * as UserActions from '../../store/user.actions';

@Component({
  selector: 'user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit, OnDestroy {

  recalculating = false;
  resultCount = 0;
  users: IUser[] = [];
  copiedUsers: IUser[] = [];
  usersStateSub: Subscription;
  userRoles: IUserRole[];
  showDisabledUsers = false;

  constructor(private store: Store<{users: IUserState, userRoles: IUserRole[], ui: IUi}>) { }

  ngOnInit() {
    this.usersStateSub = this.store.select('users').subscribe(
      (usersState) => {
        this.users = usersState.users;
        this.copyAndRunFilters();
      }
    );
  }

  newUser() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.ADMINISTRATION, page: MODULE_PAGES.ADMINISTRATION.USER_CREATE}));
  }

  onTableClicked(userId: number) {
    this.store.dispatch(new UserActions.SetActiveUser(userId));
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.ADMINISTRATION, page: MODULE_PAGES.ADMINISTRATION.USER_EDIT}));
  }

  toggleShowDisabled () {
    this.showDisabledUsers = !this.showDisabledUsers;
    this.copyAndRunFilters();
  }

  copyAndRunFilters() {
    this.recalculating = true;
    if (this.showDisabledUsers) {
      this.copiedUsers = [...this.users];
    } else {
      this.copiedUsers = [...this.users].filter(x => x.is_active);
    }
    this.recalculating = false;
    this.resultCount = this.copiedUsers.length;
  }

  ngOnDestroy() {
    this.usersStateSub.unsubscribe();
  }
}
