import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IUser} from '../../models/user.interface';
import {UserService} from '../../services/user.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'user-change-senior',
  templateUrl: './user-change-senior.component.html',
  styleUrls: ['./user-change-senior.component.css']
})
export class UserChangeSeniorComponent implements OnInit {
  @Input() user: IUser;
  form: FormGroup;
  user_selection: IUser[] = [];
  constructor(private ngbActiveModal: NgbActiveModal,
              private userService: UserService) {
    this.form = new FormGroup({
      'senioruser': new FormControl(null),
    });
  }

  ngOnInit() {
    this.user_selection = this.userService.getSeniors();
    this.form.patchValue({'senioruser': this.user.senior});
  }

  cancel() {
    this.ngbActiveModal.dismiss();
  }

  save() {
    this.userService.changeSenior(this.user.id, this.form.controls.senioruser.value);
    this.ngbActiveModal.close();
  }
}
