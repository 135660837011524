import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {isNullOrUndefined} from "util";
import {Address} from '../../models/address';

@Component({
  selector: 'editfield-address',
  templateUrl: './editfield-address.component.html',
  styleUrls: ['./editfield-address.component.css']
})
export class EditfieldAddressComponent implements OnInit {
  @Input() address: Address;
  @Input() editMode: boolean = false;
  @Output() addressChange: EventEmitter<Address> = new EventEmitter<Address>();
  addressDisplay: string = "";
  cssClass: string = "";

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.parse();
  }

  parse() {
    if (isNullOrUndefined(this.address)) {
      return;
    }
    this.addressDisplay = this.address.street + " " + this.address.house_number + "<br />";
    if (this.address.additional_information != "") {
      this.addressDisplay += this.address.additional_information + "<br />";
    }
    this.addressDisplay += this.address.zipcode + " " + this.address.town + "<br />";
    this.addressDisplay += this.address.country;
  }

  createAddress() {
    // this.addressService.createNewAddress();
  }
}
