import {Action} from "@ngrx/store";
import {IUser} from "../models/user.interface";

export const REPLACE_USERS_IN_USERLIST = 'REPLACE_USERS_IN_USERLIST';
export const ADD_USER_TO_USERLIST = 'ADD_USER_TO_USERLIST';
export const SET_ACTIVE_USER = 'SET_ACTIVE_USER';
export const DELETE_ALL_USER_DATA = 'DELETE_ALL_USER_DATA';

export class ReplaceUsersInUserlist implements Action {
  readonly type = REPLACE_USERS_IN_USERLIST;
  constructor(public payload: IUser[]) {}
}

export class AddUserToUserlist implements Action {
  readonly type = ADD_USER_TO_USERLIST;
  constructor(public payload: IUser) {}
}

export class SetActiveUser implements Action {
  readonly type = SET_ACTIVE_USER;
  constructor(public payload: number) {}
}

export class DeleteAllUserData implements Action {
  readonly type = DELETE_ALL_USER_DATA;
  constructor() {}
}

export type UserActions = ReplaceUsersInUserlist | AddUserToUserlist | SetActiveUser | DeleteAllUserData;

