import {isEmpty} from 'rxjs/operators';
/**
 * Created by user_ALT on 20.02.2017.
 */

export class Address {
  id?: number;
  street?: string;
  house_number?: string;
  additional_information?: string;
  zipcode?: string;
  town?: string;
  country?: string;

  constructor() {
    this.street = '';
    this.house_number = '';
    this.additional_information = '';
    this.zipcode = '';
    this.town = '';
    this.country = '';
  }

  getAddressFromJSON(jsonAddress: string) {
    if (jsonAddress == '') {
      return;
    }
    const parsed = JSON.parse(jsonAddress);
    this.id = parsed.id;
    this.street = parsed.street;
    this.country = parsed.county;
    this.house_number = parsed.house_number;
    this.additional_information = parsed.additional_information;
    this.zipcode = parsed.zipcode;
    this.town = parsed.town;
  }
}
