import {Component, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EditPersonComponent} from '../edit-person/edit-person.component';
import {Store} from '@ngrx/store';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';
import {UrkundenPopupComponent} from '../urkunden-popup/urkunden-popup.component';
import {IStammbaumMarriage} from '../stammbaum-marriage.interface';
import {IUrkundenFileRef} from '../urkunden-file-ref.interface';
import {StammbaumService} from '../../services/stammbaum.service';
import {CaseService} from '../../services/case.service';

@Component({
  selector: 'tr[person-for-list-without-version]',
  templateUrl: './person-for-list-without-version.component.html',
  styleUrls: ['./person-for-list-without-version.component.css']
})
export class PersonForListWithoutVersionComponent {
  @Input() person: IStammbaumPerson;
  @Input() hasTrueStammbaum = false;

  constructor(private ngbModal: NgbModal,
              public stammbaumService: StammbaumService,
              private caseService: CaseService,
              private store: Store<{stammbaum: IStammbaumState}>) {}

  addDocument() {
    this.store.dispatch(new StammbaumActions.ReplacePersonForUpload(this.person));
    // this.store.dispatch(new StammbaumActions.SetStammbaumMode('urkunden-upload-list'));
  }

  editPerson() {
    this.store.dispatch(new StammbaumActions.ReplacePersonToEditId(this.person.id));
    let modalRef: NgbModalRef;
    modalRef = this.ngbModal.open(EditPersonComponent, <NgbModalOptions>{size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.editMode = true;
    modalRef.componentInstance.hasTrueStammbaum = this.hasTrueStammbaum;
    modalRef.result.then(
      (result) => {},
      () => {}
    );
  }

  goToHeirViewOfPerson() {
    this.caseService.goToPersondetailId(this.person.id);
  }

  deletePerson() {
    let modalRef: NgbModalRef;
    modalRef = this.ngbModal.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Bestätigung';
    modalRef.componentInstance.message = `Wollen Sie die Person ${this.person.vorname} ${this.person.nachname} wirklich löschen?`;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.store.dispatch(new StammbaumActions.TriggerDeleteStammbaumPerson(this.person.id));
        }
      },
      () => {}
    );
  }

  getMarriageInfo(marriage: IStammbaumMarriage) {
    const other_person = marriage.persons.find(x => x.id !== this.person.id);
    if (other_person) {
      const marstring = `Ehe mit ${other_person.vorname} ${other_person.nachname}`;
      if (marriage.date && marriage.date !== 'null') {
        return `${marstring} (${marriage.date})`;
      } else {
        return marstring;
      }
    }
    return '';
  }

  toggleActive() {
    this.person.is_active = !this.person.is_active;
    if (this.person.is_active) {
      this.store.dispatch(new StammbaumActions.TriggerSetStammbaumPersonActive(this.person.id));
    } else {
      this.store.dispatch(new StammbaumActions.TriggerSetStammbaumPersonInactive(this.person.id));
    }
  }

  showUrkunden() {
    const urkunden: IUrkundenFileRef[] = this.person.urkunden_for_person;
    for (const mar of this.person.marriages_for_stammbaum_person) {
      for (const urk of mar.urkunden_for_marriage) {
        if (!urkunden.find(x => x.id === urk.id)) {
          urkunden.push(urk);
        }
      }
    }
    const modalRef = this.ngbModal.open(UrkundenPopupComponent, <NgbModalOptions>{size: 'lg'});
    modalRef.componentInstance.person = this.person;
    modalRef.componentInstance.urkunden = urkunden;
  }

  dropped($event) {}

  fileOver() {}

  fileLeave() {}
}

