import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { AuthenticationService } from './authentication.service';
import { ICommunicationDetail } from '../models/communication-detail.interface';

@Injectable()
export class CommunicationDetailService {

  constructor(private constantsService: ConstantsService, private http: HttpClient,
              private authenticationService: AuthenticationService) { }

  getAllForPerson(person_id: number): Observable<ICommunicationDetail[]> {
    return this.http.post<ICommunicationDetail[]>(`${this.constantsService.getApiEndpoint()}/get-contact/`, {stammbaum_person_id: person_id}, {headers: this.constantsService.getHttpOptions()});
  }

  save(communicationDetail: ICommunicationDetail) : Observable<any> {
    return this.http.put(`${this.constantsService.getApiEndpoint()}/contact_update/${communicationDetail.id}/`, communicationDetail, {headers: this.constantsService.getHttpOptions()});
  }

  delete(communicationDetail: ICommunicationDetail) : Observable<any> {
    return this.http.get(`${this.constantsService.getApiEndpoint()}/contact_delete/${communicationDetail.id}/`, {headers: this.constantsService.getHttpOptions()});
  }

  create(communicationDetail: ICommunicationDetail) : Observable<any> {
    let body = JSON.stringify(communicationDetail);
    return this.http.post(`${this.constantsService.getApiEndpoint()}/contact_create/`, body, {headers: this.constantsService.getHttpOptions()});
  }
}
