import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IStammbaumMarriage} from '../stammbaum-marriage.interface';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: '[display-marriage]',
  templateUrl: './display-marriage.component.html',
  styleUrls: ['./display-marriage.component.css']
})
export class DisplayMarriageComponent implements OnInit, OnChanges {
  form: FormGroup;
  @Input() marriage: IStammbaumMarriage;
  @Input() person_id: number;
  @Output() editMarriageSignal: EventEmitter<IStammbaumMarriage> = new EventEmitter<IStammbaumMarriage>();
  person_to_display: IStammbaumPerson;

  constructor() {
    this.form = new FormGroup({
      'needs_hu': new FormControl(null),
      'needs_hu_but_do_without': new FormControl(null),
      'ordered_hu': new FormControl(null)
    });
  }

  editMarriage(marriage: IStammbaumMarriage) {
    this.editMarriageSignal.emit(marriage);
  }

  ngOnInit() {
    this.form.controls.needs_hu.disable();
    this.form.controls.needs_hu_but_do_without.disable();
    this.form.controls.ordered_hu.disable();
    this.person_to_display = this.marriage.persons.find(x => x.id !== this.person_id);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('marriage' in changes && this.form) {
      this.form.patchValue({
        needs_hu: changes.marriage.currentValue.needs_hu,
        needs_hu_but_do_without: changes.marriage.currentValue.needs_hu_but_do_without,
        ordered_hu: changes.marriage.currentValue.ordered_hu});
    }
  }
}
