import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistics-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  institutionen = [
    {
      name: 'Sparkasse', quellen: [
          {
            name: 'Girokonto', vermutung: 30000, erhalten: 29685.90, abgeschlossen: true
          },
          {
            name: 'Sparbuch', vermutung: 10000, erhalten: 11200, abgeschlossen: true
          }
          ]
    },
    {
      name: 'Nachlasspfleger', quellen: [
        {
          name: 'Bargeld', vermutung: 9000, erhalten: 0, abgeschlossen: false
        }
      ]
    }
  ];

  erben = [
    {name: 'Gerda Müller', quote: 0.25, abgerechnet: false},
    {name: 'Lisa Mayer', quote: 0.5, abgerechnet: true},
    {name: 'Thomas Schmidt', quote: 0.25, abgerechnet: true},
  ];

  constructor() { }

  ngOnInit() {
  }

}
