import {Component, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { CaseService } from '../../services/case.service';
import { UserService } from '../../services/user.service';
import {Store} from '@ngrx/store';
import {RightsManagementService} from '../../services/rights-management.service';
import {IUi} from '../../models/ui.interface';
import {IUserState} from '../../store/user-state.interface';
import {ICaseState} from '../../store/case-state.interface';
import {ICaseStatus} from '../../models/case-status.interface';
import {DebugLogService} from '../../services/debug-log.service';
import {IStammbaumPerson} from '../../stammbaum/stammbaum-person.interface';
import {StammbaumService} from '../../services/stammbaum.service';
import {PersonPopupComponent} from '../person-popup/person-popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'person-list-search-results',
  templateUrl: './person-list-search-results.component.html',
  styleUrls: ['./person-list-search-results.component.css']
})

export class PersonListSearchResultsComponent implements OnInit, OnDestroy {
  @Input() searchResults: IStammbaumPerson[] = [];

  constructor(private caseService: CaseService,
              private stammbaumService: StammbaumService,
              public rightsManagementService: RightsManagementService,
              private ngbModal: NgbModal) {}

  ngOnInit() {}

  ngOnDestroy() {}

  getTypeText(personTypeId: number) {
    return this.stammbaumService.getPersonTypeText(personTypeId);
  }

  getTypeColor(person: IStammbaumPerson) {
    return this.stammbaumService.getPersonTypeColor(person);
  }

  getTypeTooltip(person: IStammbaumPerson) {
    return this.stammbaumService.getPersonTypeTooltip(person);
  }

  onTableClicked(item: IStammbaumPerson) {
    const ngbModalRef = this.ngbModal.open(PersonPopupComponent);
    ngbModalRef.componentInstance.heir = item;
    ngbModalRef.result.then(
      (result) => {
        if (result) {
          this.caseService.goToPersondetailId(item.id, `${item.nachname} ${item.vorname}`);
        }
      },
      () => {}
    )
  }
}
