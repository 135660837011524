import {Component, ElementRef, ViewChild, HostListener} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import * as NVZOptions from "../nvz-options";
import {INVZVerzeichnis} from "../nvz-verzeichnis.interface";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {INVZEintrag} from "../nvz-eintrag.interface";
import {UserService} from "../../services/user.service";
import {NVZService} from "../../services/nvz.service";
import {IUserState} from "../../store/user-state.interface";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {IUser} from "../../models/user.interface";


@Component({
  selector: 'nvz-userrights-form',
  templateUrl: './nvz-userrights-form.component.html',
  styleUrls: ['./nvz-userrights-form.component.css']
})
export class NVZUserrightsFormComponent {
  verzeichnis: INVZVerzeichnis;
  active_standesamt_id: number;
  usersStateSub: Subscription;
  activeUsers: IUser[] = [];
  user_id: number;

  constructor(private activeModal: NgbActiveModal,
              public userService: UserService,
              private nvzService: NVZService,
              private store: Store<{users: IUserState}>) {}

  ngOnInit() {
    this.usersStateSub = this.store.select('users').subscribe(
      (usersState) => {
        this.activeUsers = usersState.users.filter(x => x.is_active);
      }
    );
  }

  ngOnDestroy() {
    this.usersStateSub.unsubscribe();
  }

  save() {
    this.activeModal.close();

  }

  back() {
    this.activeModal.close();
  }

  remove(user: number) {
    this.nvzService.removeUserFromVerzeichnis(this.verzeichnis, user).subscribe(
      (result) => {},
      (error) => {},
      () => {
        this.nvzService.getVerzeichnisse(this.active_standesamt_id);
      }
    );
    this.activeModal.close();
  }

  add(user: number) {
    if (user > 0) {
      this.nvzService.addUserToVerzeichnis(this.verzeichnis, user).subscribe(
      (result) => {},
      (error) => {},
      () => {
        this.nvzService.getVerzeichnisse(this.active_standesamt_id);
      }
    );
    this.activeModal.close();
    }
  }
}
