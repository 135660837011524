import {Component, OnDestroy, OnInit} from '@angular/core';
import {KassenbuchService} from '../kassenbuch.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-kassenbuch-stats',
  templateUrl: './kassenbuch-stats.component.html',
  styleUrls: ['./kassenbuch-stats.component.css']
})
export class KassenbuchStatsComponent implements OnInit, OnDestroy {
  stats: {name: string, value: number}[] = [];
  statsAggregated: {name: string, value: number}[] = [];
  statsSub: Subscription;
  total = 0;

  gradient = false;
  showLegend = false;
  doughnut = true;
  labels = true;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#D0AAAA', '#BC4444', '#232323']
  };
  constructor(private kassenbuchService: KassenbuchService) {}

  ngOnInit() {
    this.statsSub = this.kassenbuchService.kostenpositionenStatsSub.subscribe(
      (stats) => {
        const statsChanged: {name: string, value: number}[] = [];
        const statsAgg: {name: string, value: number}[] = [];
        this.total = 0;
        let sonstiges = 0;
        for (const s of stats) {
          this.total += s.total_brutto;
          statsChanged.push({name: s.kostenkategorie__bezeichnung, value: s.total_brutto});
        }
        for (const s of stats) {
          if (s.total_brutto / this.total >= 0.05) {
            statsAgg.push({name: s.kostenkategorie__bezeichnung, value: s.total_brutto});
          } else {
            sonstiges += s.total_brutto;
          }
        }
        this.stats = statsChanged.sort(
          (a, b) => {
            if (a.value > b.value) { return -1; }
            if (a.value < b.value) { return 1 };
            return 0;
          }
        );
        if (sonstiges > 0) {
          statsAgg.push({name: 'sonstiges', value: sonstiges});
        }
        this.statsAggregated = statsAgg;
      }
    );
  }

  onSelect($event) {}

  ngOnDestroy() {
    this.statsSub.unsubscribe();
  }
}
