import {Component, OnDestroy, OnInit} from '@angular/core';
import * as UiActions from "../../store/ui.actions";
import {MODULE_PAGES, MODULES} from "../../routing/routes.model";
import {Store} from "@ngrx/store";
import {AutoparserService} from "../../services/autoparser.service";
import {IAutoparserState} from "../../store/autoparser-state.interface";
import * as AutoparserActions from "../../store/autoparser.actions";
import {NvzApEditResultsComponent} from "../nvz-ap-edit-results/nvz-ap-edit-results.component";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {IAutoparserDirectory} from '../autoparser-directory.interface';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {IAuthUser} from '../../models/auth-user.interface';

@Component({
  selector: 'nvz-ap-entry',
  templateUrl: './nvz-ap-entry.component.html',
  styleUrls: ['./nvz-ap-entry.component.css']
})
export class NvzApEntryComponent implements OnInit, OnDestroy {
  messages: string[] = ['Autoparser bereit.'];
  mode: string = null;
  parameter_mode: string = 'cropping';
  command_running: boolean = false;

  authUserId: number;
  authUserSub: Subscription;

  images_cropped_simulated: string[] = [];
  images_cropped: string[] = [];
  images_lines_horizontal: string[] = [];
  images_lines_vertical: string[] = [];
  images_lines: string[] = [];
  images: string[] = [];
  actionForm: FormGroup;
  autoparserState: IAutoparserState;
  autoparserStateSub: Subscription;
  activePage = '';

  directories: IAutoparserDirectory[] = [];

  constructor(private store: Store<{autoparser: IAutoparserState, authUser: {authUser: IAuthUser}}>,
              private autoparserService: AutoparserService,
              private ngbModal: NgbModal) {
    this.autoparserStateSub = this.store.select('autoparser').subscribe(
      (autoparserState) => {
        this.autoparserState = autoparserState;
        if (autoparserState) {
          this.activePage = autoparserState.activePage;
          this.directories = autoparserState.directories;
        }
      }
    );
    this.authUserSub = this.store.select('authUser').subscribe(
      (authUser) => {
        if (authUser && authUser.authUser && authUser.authUser.user) {
          this.authUserId = authUser.authUser.user.id;
          this.getDirectories();
        }
      }
    );
    this.actionForm = new FormGroup({
      'directory': new FormControl(-1, [Validators.required, Validators.min(0)])
    });
  }

  ngOnInit() {}

  getDirectories() {
    this.autoparserService.getDirectories();
  }

  startPostprocessing() {
    const dir: IAutoparserDirectory = this.directories.find(x => +x.id === +this.actionForm.controls.directory.value);
    this.autoparserService.reloadCurrentDirectory(dir.id);
    this.store.dispatch(new AutoparserActions.SetAutoparserActivePage('postprocess'));
}

  startPreprocessing() {
    this.command_running = true;
    this.store.dispatch(new AutoparserActions.AddNewMessage('Send START_PREPROCESSING'));
    this.autoparserService.startPreprocessing().subscribe(
      (response) => {
        this.store.dispatch(new AutoparserActions.AddNewMessage('Command OK'));
        this.command_running = false;
        },
      () => {
        this.store.dispatch(new AutoparserActions.AddNewMessage('Server ERROR'));
        this.command_running = false;
        },
      () => {
        if (this.command_running) {
          this.command_running = false;
          this.store.dispatch(new AutoparserActions.AddNewMessage('Server TIMEOUT'));
        }
      });
  }

  startOCR() {
    this.command_running = true;
    this.store.dispatch(new AutoparserActions.AddNewMessage('Send START_OCR'));
    this.autoparserService.startOCR().subscribe(
      (response) => {
        this.store.dispatch(new AutoparserActions.AddNewMessage('Command OK'));
        this.command_running = false;
        },
      () => {
        this.store.dispatch(new AutoparserActions.AddNewMessage('Server ERROR'));
        this.command_running = false;
        },
      () => {
        if (this.command_running) {
          this.command_running = false;
          this.store.dispatch(new AutoparserActions.AddNewMessage('Server TIMEOUT'));
        }
      });
  }

  exportList() {}

  showFirstNames() {}

  showLastNames() {}


  setMode(new_mode: string) {
    this.mode = new_mode;
  }

  setParameterMode(new_mode: string) {
    this.parameter_mode = new_mode;
  }

  end() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.NAMENSVERZEICHNISSE, page: MODULE_PAGES.NAMENSVERZEICHNISSE.SEARCH}));
  }

  ngOnDestroy(): void {
    this.autoparserStateSub.unsubscribe();
    this.authUserSub.unsubscribe();
  }
}
