import {Action} from '@ngrx/store';
import {INetworkManagerMessage} from '../network-manager/network-manager-message.interface';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const SET_CONNECTED_TO_SERVER_TRUE = 'SET_CONNECTED_TO_SERVER_TRUE';
export const SET_CONNECTED_TO_SERVER_FALSE = 'SET_CONNECTED_TO_SERVER_FALSE';
export const SET_UPDATE_NEEDED_CASE_TRUE = 'SET_UPDATE_NEEDED_CASE_TRUE';
export const SET_UPDATE_NEEDED_CASE_FALSE = 'SET_UPDATE_NEEDED_CASE_FALSE';
export const SET_UPDATE_NEEDED_PERSON_TRUE = 'SET_UPDATE_NEEDED_PERSON_TRUE';
export const SET_UPDATE_NEEDED_PERSON_FALSE = 'SET_UPDATE_NEEDED_PERSON_FALSE';
export const SET_UPDATE_NEEDED_PERSONS_FOR_CASE_TRUE = 'SET_UPDATE_NEEDED_PERSONS_FOR_CASE_TRUE';
export const SET_UPDATE_NEEDED_PERSONS_FOR_CASE_FALSE = 'SET_UPDATE_NEEDED_PERSONS_FOR_CASE_FALSE';
export const SET_UPDATE_NEEDED_USER_ROLE_TRUE = 'SET_UPDATE_NEEDED_USER_ROLE_TRUE';
export const SET_UPDATE_NEEDED_USER_ROLE_FALSE = 'SET_UPDATE_NEEDED_USER_ROLE_FALSE';
export const SET_UPDATE_NEEDED_USER_TRUE = 'SET_UPDATE_NEEDED_USER_TRUE';
export const SET_UPDATE_NEEDED_USER_FALSE = 'SET_UPDATE_NEEDED_USER_FALSE';
export const SET_UPDATE_NEEDED_CASE_STATUS_TRUE = 'SET_UPDATE_NEEDED_CASE_STATUS_TRUE';
export const SET_UPDATE_NEEDED_CASE_STATUS_FALSE = 'SET_UPDATE_NEEDED_CASE_STATUS_FALSE';
export const SET_UPDATE_NEEDED_NOTES_TRUE = 'SET_UPDATE_NEEDED_NOTES_TRUE';
export const SET_UPDATE_NEEDED_NOTES_FALSE = 'SET_UPDATE_NEEDED_NOTES_FALSE';
export const SET_UPDATE_NEEDED_NOTES_FOR_CASE_TRUE = 'SET_UPDATE_NEEDED_NOTES_FOR_CASE_TRUE';
export const SET_UPDATE_NEEDED_NOTES_FOR_CASE_FALSE = 'SET_UPDATE_NEEDED_NOTES_FOR_CASE_FALSE';
export const SET_UPDATE_NEEDED_NOTES_FOR_PERSON_TRUE = 'SET_UPDATE_NEEDED_NOTES_FOR_PERSON_TRUE';
export const SET_UPDATE_NEEDED_NOTES_FOR_PERSON_FALSE = 'SET_UPDATE_NEEDED_NOTES_FOR_PERSON_FALSE';
export const SET_UPDATE_NEEDED_TEAM_MEMBER_FOR_CASE_TRUE = 'SET_UPDATE_NEEDED_TEAM_MEMBER_FOR_CASE_TRUE';
export const SET_UPDATE_NEEDED_TEAM_MEMBER_FOR_CASE_FALSE = 'SET_UPDATE_NEEDED_TEAM_MEMBER_FOR_CASE_FALSE';
export const SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_CASE_TRUE = 'SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_CASE_TRUE';
export const SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_CASE_FALSE = 'SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_CASE_FALSE';
export const SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_PERSON_TRUE = 'SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_PERSON_TRUE';
export const SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_PERSON_FALSE = 'SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_PERSON_FALSE';
export const SET_UPDATE_NEEDED_PERSON_GROUP_TRUE = 'SET_UPDATE_NEEDED_PERSON_GROUP_TRUE';
export const SET_UPDATE_NEEDED_PERSON_GROUP_FALSE = 'SET_UPDATE_NEEDED_PERSON_GROUP_FALSE';
export const SET_UPDATE_NEEDED_CALENDAR_ENTRIES_TRUE = 'SET_UPDATE_NEEDED_CALENDAR_ENTRIES_TRUE';
export const SET_UPDATE_NEEDED_CALENDAR_ENTRIES_FALSE = 'SET_UPDATE_NEEDED_CALENDAR_ENTRIES_FALSE';
export const SET_UPDATE_NEEDED_BUNDESANZEIGER_CASES_TRUE = 'SET_UPDATE_NEEDED_BUNDESANZEIGER_CASES_TRUE';
export const SET_UPDATE_NEEDED_BUNDESANZEIGER_CASES_FALSE = 'SET_UPDATE_NEEDED_BUNDESANZEIGER_CASES_FALSE';
export const SET_LAST_UPDATE_API_ENDPOINTS__CASE = 'SET_LAST_UPDATE_API_ENDPOINTS__CASE';
export const SET_LAST_UPDATE_API_ENDPOINTS__PERSON = 'SET_LAST_UPDATE_API_ENDPOINTS__PERSON';
export const SET_LAST_UPDATE_API_ENDPOINTS__NOTE = 'SET_LAST_UPDATE_API_ENDPOINTS__NOTE';
export const RESET_ALL = 'RESET_ALL';

export class AddMessage implements Action {
  readonly type = ADD_MESSAGE;
  constructor(public payload: INetworkManagerMessage) {}
}

export class SetConnectedToServerTrue implements Action {
  readonly type = SET_CONNECTED_TO_SERVER_TRUE;
  constructor() {}
}

export class SetConnectedToServerFalse implements Action {
  readonly type = SET_CONNECTED_TO_SERVER_FALSE;
  constructor() {}
}

export class SetUpdateNeededCaseTrue implements Action {
  readonly type = SET_UPDATE_NEEDED_CASE_TRUE;
  constructor() {}
}

export class SetUpdateNeededCaseFalse implements Action {
  readonly type = SET_UPDATE_NEEDED_CASE_FALSE;
  constructor() {}
}

export class SetUpdateNeededPersonTrue implements Action {
  readonly type = SET_UPDATE_NEEDED_PERSON_TRUE;
  constructor() {}
}

export class SetUpdateNeededPersonFalse implements Action {
  readonly type = SET_UPDATE_NEEDED_PERSON_FALSE;
  constructor() {}
}

export class SetUpdateNeededPersonsForCaseTrue implements Action {
  readonly type = SET_UPDATE_NEEDED_PERSONS_FOR_CASE_TRUE;
  constructor() {}
}

export class SetUpdateNeededPersonsForCaseFalse implements Action {
  readonly type = SET_UPDATE_NEEDED_PERSONS_FOR_CASE_FALSE;
  constructor() {}
}

export class SetUpdateNeededUserRoleTrue implements Action {
  readonly type = SET_UPDATE_NEEDED_USER_ROLE_TRUE;
  constructor() {}
}

export class SetUpdateNeededUserRoleFalse implements Action {
  readonly type = SET_UPDATE_NEEDED_USER_ROLE_FALSE;
  constructor() {}
}

export class SetUpdateNeededUserTrue implements Action {
  readonly type = SET_UPDATE_NEEDED_USER_TRUE;
  constructor() {}
}

export class SetUpdateNeededUserFalse implements Action {
  readonly type = SET_UPDATE_NEEDED_USER_FALSE;
  constructor() {}
}

export class SetUpdateNeededCaseStatusTrue implements Action {
  readonly type = SET_UPDATE_NEEDED_CASE_STATUS_TRUE;
  constructor() {}
}

export class SetUpdateNeededCaseStatusFalse implements Action {
  readonly type = SET_UPDATE_NEEDED_CASE_STATUS_FALSE;
  constructor() {}
}

export class SetUpdateNeededNotesTrue implements Action {
  readonly type = SET_UPDATE_NEEDED_NOTES_TRUE;
  constructor() {}
}

export class SetUpdateNeededNotesFalse implements Action {
  readonly type = SET_UPDATE_NEEDED_NOTES_FALSE;
  constructor() {}
}

export class SetUpdateNeededNotesForCaseTrue implements Action {
  readonly type = SET_UPDATE_NEEDED_NOTES_FOR_CASE_TRUE;
  constructor() {}
}

export class SetUpdateNeededNotesForCaseFalse implements Action {
  readonly type = SET_UPDATE_NEEDED_NOTES_FOR_CASE_FALSE;
  constructor() {}
}

export class SetUpdateNeededNotesForPersonTrue implements Action {
  readonly type = SET_UPDATE_NEEDED_NOTES_FOR_PERSON_TRUE;
  constructor() {}
}

export class SetUpdateNeededNotesForPersonFalse implements Action {
  readonly type = SET_UPDATE_NEEDED_NOTES_FOR_PERSON_FALSE;
  constructor() {}
}

export class SetUpdateNeededTeamMemberForCaseTrue implements Action {
  readonly type = SET_UPDATE_NEEDED_TEAM_MEMBER_FOR_CASE_TRUE;
  constructor() {}
}

export class SetUpdateNeededTeamMemberForCaseFalse implements Action {
  readonly type = SET_UPDATE_NEEDED_TEAM_MEMBER_FOR_CASE_FALSE;
  constructor() {}
}

export class SetUpdateNeededFileUploadsForCaseTrue implements Action {
  readonly type = SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_CASE_TRUE;
  constructor() {}
}

export class SetUpdateNeededFileUploadsForCaseFalse implements Action {
  readonly type = SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_CASE_FALSE;
  constructor() {}
}

export class SetUpdateNeededFileUploadsForPersonTrue implements Action {
  readonly type = SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_PERSON_TRUE;
  constructor() {}
}

export class SetUpdateNeededFileUploadsForPersonFalse implements Action {
  readonly type = SET_UPDATE_NEEDED_FILE_UPLOADS_FOR_PERSON_FALSE;
  constructor() {}
}

export class SetUpdateNeededPersonGroupTrue implements Action {
  readonly type = SET_UPDATE_NEEDED_PERSON_GROUP_TRUE;
  constructor() {}
}

export class SetUpdateNeededPersonGroupFalse implements Action {
  readonly type = SET_UPDATE_NEEDED_PERSON_GROUP_FALSE;
  constructor() {}
}

export class SetUpdateNeededCalendarEntriesTrue implements Action {
  readonly type = SET_UPDATE_NEEDED_CALENDAR_ENTRIES_TRUE;
  constructor() {}
}

export class SetUpdateNeededCalendarEntriesFalse implements Action {
  readonly type = SET_UPDATE_NEEDED_CALENDAR_ENTRIES_FALSE;
  constructor() {}
}

export class SetUpdateNeededBundesanzeigerCasesTrue implements Action {
  readonly type = SET_UPDATE_NEEDED_BUNDESANZEIGER_CASES_TRUE;
  constructor() {}
}

export class SetUpdateNeededBundesanzeigerCasesFalse implements Action {
  readonly type = SET_UPDATE_NEEDED_BUNDESANZEIGER_CASES_FALSE;
  constructor() {}
}

export class SetLastUpdateApiEndpointsCase implements Action {
  readonly type = SET_LAST_UPDATE_API_ENDPOINTS__CASE;
  constructor(public payload: string) {}
}

export class SetLastUpdateApiEndpointsPerson implements Action {
  readonly type = SET_LAST_UPDATE_API_ENDPOINTS__PERSON;
  constructor(public payload: string) {}
}

export class SetLastUpdateApiEndpointsNote implements Action {
  readonly type = SET_LAST_UPDATE_API_ENDPOINTS__NOTE;
  constructor(public payload: string) {}
}

export class ResetAll implements Action {
  readonly type = RESET_ALL;
  constructor() {}
}

export type NetworkActions =
  AddMessage |
  SetConnectedToServerTrue |
  SetConnectedToServerFalse |
  SetUpdateNeededCaseTrue |
  SetUpdateNeededCaseFalse |
  SetUpdateNeededPersonTrue |
  SetUpdateNeededPersonFalse |
  SetUpdateNeededPersonsForCaseTrue |
  SetUpdateNeededPersonsForCaseFalse |
  SetUpdateNeededUserRoleTrue |
  SetUpdateNeededUserRoleFalse |
  SetUpdateNeededUserTrue |
  SetUpdateNeededUserFalse |
  SetUpdateNeededCaseStatusTrue |
  SetUpdateNeededCaseStatusFalse |
  SetUpdateNeededNotesTrue |
  SetUpdateNeededNotesFalse |
  SetUpdateNeededNotesForCaseTrue |
  SetUpdateNeededNotesForCaseFalse |
  SetUpdateNeededNotesForPersonTrue |
  SetUpdateNeededNotesForPersonFalse |
  SetUpdateNeededTeamMemberForCaseTrue |
  SetUpdateNeededTeamMemberForCaseFalse |
  SetUpdateNeededFileUploadsForCaseTrue |
  SetUpdateNeededFileUploadsForCaseFalse |
  SetUpdateNeededFileUploadsForPersonTrue |
  SetUpdateNeededFileUploadsForPersonFalse |
  SetUpdateNeededPersonGroupTrue |
  SetUpdateNeededPersonGroupFalse |
  SetUpdateNeededCalendarEntriesTrue |
  SetUpdateNeededCalendarEntriesFalse |
  SetUpdateNeededBundesanzeigerCasesTrue |
  SetUpdateNeededBundesanzeigerCasesFalse |
  SetLastUpdateApiEndpointsCase |
  SetLastUpdateApiEndpointsPerson |
  SetLastUpdateApiEndpointsNote |
  ResetAll;
