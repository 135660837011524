import {Component, Input} from "@angular/core";

@Component({
  selector: 'show-boolean-value',
  template: `<i *ngIf="!value||value=='false'" class="fa fa-remove" style="color: red;"></i>
             <i *ngIf="value&&value!='false'&&value!=''" class="fa fa-check" style="color: green;"></i>`
})

export class ShowBooleanValueComponent {
  @Input() value;
}
