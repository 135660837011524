import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NetworkManagerIconComponent} from './network-manager-icon/network-manager-icon.component';
import {NetworkManagerMessagesComponent} from './network-manager-messages/network-manager-messages.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    NetworkManagerIconComponent,
    NetworkManagerMessagesComponent
  ],
  exports: [
    NetworkManagerIconComponent,
    NetworkManagerMessagesComponent
  ],
  entryComponents: [
    NetworkManagerMessagesComponent
  ]
})
export class NetworkManagerModule {}
