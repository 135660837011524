import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DatePipe} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import * as L from 'leaflet';
import {Store} from '@ngrx/store';
import {IStammbaumPerson} from '../../stammbaum/stammbaum-person.interface';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html'})

export class MapComponent implements OnInit, OnDestroy {

  // mapMarkers: MapMarker[] = [];
  mapMode = 0; // 0= Open Street Map, 1= Google Luftbild
  showLocationSearch = false;
  showTasks = false;
  map: L.Map;

  Maplayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 18});
  options = {
    layers: [
      this.Maplayer
    ],
    zoom: 12,
    center: L.latLng({lat: 51.3404088, lng: 12.3328508}),
    errorTileUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attributionControl: false
  };
  mapzoom = 12;
  mapcenter = L.latLng({lat: 51.3404088, lng: 12.3328508});

  heirs: IStammbaumPerson[] = [];
  heirSub: Subscription;

  constructor(public dialog: MatDialog,
              private elementRef: ElementRef,
              private datePipe: DatePipe) {
  }

  ngOnInit() {}

  show() {
    this.addMarkers();
  }

  ngOnDestroy() {
    this.heirSub.unsubscribe();
  }

  onMapReady(map) {
    this.map = map;
  }

  addMarkers() {
    let class_name: string;
    let popup: string;
    /*
    for (const heir of this.heirs) {
      console.log(heir);
      if (!Number.isNaN(heir.lat) && !Number.isNaN(heir.long) && (+heir.group === 1)) {
        if (+heir.group === 1) {
          class_name = 'extra-marker-circle-blue extra-marker';
          popup = '<h3>' + heir.last_name + ' ' + heir.first_name + '</h3>';
          popup += '<p>Fall ' + heir.case_id + '</p>';

          if (heir.color_code === '#00ee00') {
            // heir has been created but no V+V und kein unwilliger Erbe
            class_name = 'extra-marker-circle-lightgreen extra-marker';
            popup += '<p>Status: V+V nicht verschickt</p>';
          }
          if (heir.color_code === '#004000') {
            // V+V sent out
            class_name = 'extra-marker-circle-green extra-marker';
            popup += '<p>Status: V+V verschickt</p>';
          }
          if (heir.color_code === '#b88428') {
            // V+V signed (overrides V+V sent out)
            class_name = 'extra-marker-circle-orange extra-marker';
            popup += '<p>Status: V+V unterschrieben</p>';
          }
          if (heir.color_code === '#ff0000') {
            // unwilliger Erbe
            class_name = 'extra-marker-circle-red extra-marker';
            popup += '<p>Status: unwilliger Erbe</p>';
          }
        }
        if (+heir.group !== 1) {
          class_name = 'extra-marker-square-green extra-marker';
          popup = '';
          // popup = "<h3>" + heir.last_name + " " + heir.first_name + "</h3>";
          // popup += "<p>Fall " + heir.case_id + "</p>";
          // popup += "<p>Namensträger</p>";
        }


        const icon = L.divIcon({
          className: class_name,
          iconSize: [35, 45],
          iconAnchor: [17, 42],
          popupAnchor: [1, -32],
          shadowAnchor: [10, 12],
          shadowSize: [36, 16],
          html: ''
        });

        const mapMarker = L.marker([heir.lat, heir.long], {
          icon: icon,
          draggable: false,
        }).addTo(this.map);
        mapMarker.bindPopup(popup, {className: '', minWidth: 200});

      }
    }*/
  }

  makeMarker(a) {
    const marker = L.marker([a.lat, a.lng], {
      icon: L.icon({
        iconSize: [25, 41],
        iconAnchor: [13, 41],
        iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.3/images/marker-icon.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.3/images/marker-shadow.png'
      })
    }).addTo(this.map);
    this.showLocationSearch = false;
    this.mapcenter = L.latLng({lat: a.lat, lng: a.lng});
    this.mapzoom = 15;
  }
}

