import { Component, OnInit, Input, HostListener } from '@angular/core';
import {Store} from '@ngrx/store';
import {INVZState} from '../../store/nvz-state.interface';
import * as NVZActions from '../../store/nvz.actions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as UiActions from '../../store/ui.actions';
import {MODULE_PAGES, MODULES} from '../../routing/routes.model';
import {IUser} from '../../models/user.interface';
import {NVZService} from '../../services/nvz.service';
import {
  IAppNotification, NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR, NOTIFICATION_SLOT_NVZ
} from '../../app-notifications/app-notification.interface';
import * as AppNotificationActions from '../../store/app-notification.actions';
import {ConfirmModalComponent} from '../../tools/confirm-modal/confirm-modal.component';
import {UserService} from '../../services/user.service';
import {RightsManagementService} from '../../services/rights-management.service';
import {INVZStadt} from '../nvz-stadt.interface';
import {NVZLaenderFormComponent} from '../nvz-laender-form/nvz-laender-form.component';
import {INVZLand} from '../nvz-land.interface';

@Component({
  selector: 'nvz-laender',
  templateUrl: './nvz-laender.component.html',
  styleUrls: ['./nvz-laender.component.css']
})
export class NvzLaenderComponent implements OnInit {
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.key === 'Insert') && (this.rightsManagementService.isAllowedToEditAllNVZEntries())) {
      this.addLand();
    }
  }

  @Input() nvzState: INVZState;
  @Input() user: IUser;

  constructor(private store: Store<{nvz: INVZState, appNotifications: { appNotifications: IAppNotification[]}}>,
              private modalService: NgbModal,
              public nvzService: NVZService,
              public userService: UserService,
              public rightsManagementService: RightsManagementService) { }

  ngOnInit() {
    this.nvzService.getLaender();
  }

  addLand() {
    const modalRef = this.modalService.open(NVZLaenderFormComponent);
    modalRef.componentInstance.editMode = false;
    modalRef.result.then(
      (result) => {if (result) {this.save(result, false)}},
      () => {}
    );
  }

  editLand(land: INVZLand) {
    const modalRef = this.modalService.open(NVZLaenderFormComponent);
    modalRef.componentInstance.land = land;
    modalRef.componentInstance.editMode = true;
    modalRef.result.then(
      (result) => {if (result) {this.save(result, true)}},
      () => {}
    );
  }

  deleteLand(land: INVZLand) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.message = 'Wollen Sie dieses Land mit all seinen Städten, Standesämtern, Verzeichnissen und Einträgen wirklich löschen?';
    modalRef.componentInstance.title = 'Bestätigung';
    modalRef.result.then(
      (result) => {
        if (result) {
          this.nvzService.deleteLand(land).subscribe(
            (reply) => {
              this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
              this.nvzService.getLaender();
            },
            (error) => {
              this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
              this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR));
              this.nvzService.getLaender();
            },
            () => {}
          );
        }
      }
    );
  }

  selectLand(land: INVZLand) {
    this.store.dispatch(new NVZActions.SetActiveLand(land));
    this.nvzService.getStaedte();
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.NAMENSVERZEICHNISSE, page: MODULE_PAGES.NAMENSVERZEICHNISSE.STAEDTE}));
  }

  back() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.NAMENSVERZEICHNISSE, page: MODULE_PAGES.NAMENSVERZEICHNISSE.SEARCH}));
  }

  save(land: INVZLand, editMode: boolean) {
    if (editMode) { // change existing
      let landToChange: INVZLand = this.nvzState.laender.find(x => x.id === land.id);
      if (landToChange) {
        landToChange = land;
        this.nvzService.updateLand(land).subscribe(
        (result) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.nvzService.getLaender();
        },
        (error) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR));
          this.nvzService.getLaender();
        },
        () => {}
      );
      }
    } else { // create new
      this.nvzState.laender.push(land);
      this.nvzService.createLand(land).subscribe(
        (result) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.nvzService.getLaender();
        },
        (error) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_NVZ));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_SAVE_NVZ_FAILED_ERROR));
          this.nvzService.getLaender();
        },
        () => {}
      );
    }
  }
}
