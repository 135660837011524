import {Input, Component, OnInit, SimpleChanges, OnChanges, OnDestroy, AfterViewInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {IUi} from '../../models/ui.interface';
import {ICaseState} from '../../store/case-state.interface';
import {RightsManagementService} from '../../services/rights-management.service';
import {ICaseStatus} from '../../models/case-status.interface';
import {IUserState} from '../../store/user-state.interface';
import {UserService} from '../../services/user.service';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {StammbaumService} from '../../services/stammbaum.service';


@Component({
  selector: 'case-detail-max-heir',
  templateUrl: './case-detail-max-heir.component.html',
  styleUrls: ['./case-detail-max-heir.component.css']
})
export class CaseDetailMaxHeirComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() stammbaumState: IStammbaumState;
  @Input() ui;

  id: number;

  constructor(private store: Store<{ui: IUi, caseStatus: ICaseStatus[], cases: ICaseState, users: IUserState}>,
              public rightsManagementService: RightsManagementService,
              private stammbaumService: StammbaumService,
              public userService: UserService) {
  }

  ngAfterViewInit() {
    window.scrollTo({top: 0, left: 0});
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {}

  ngOnDestroy() {}

  hasTrueStammbaum() {
    return this.stammbaumService.hasTrueStammbaum();
  }
}
