import {Component} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import { Store } from '@ngrx/store';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {IAddressbookPerson} from "../addressbook-person.interface";
import {IAddressbookInstitution} from "../addressbook-institution.interface";
import {AddressbookService} from "../../services/addressbook.service";
import {IAddressbookEntryState} from "../../store/addressbook-entry-state.interface";
import * as AddressbookEntryActions from "../../store/addressbook-entry.actions";
import {DeactivatedModalComponent} from "../../tools/deactivated-modal/deactivated-modal-component";
import * as UiActions from "../../store/ui.actions";
import {MODULES, MODULE_PAGES} from "../../routing/routes.model";
import {AddressbookCreatePopupComponent} from "../addressbook-create-popup/addressbook-create-popup.component";
import {AddressbookDetailPopupComponent} from "../addressbook-detail-popup/addressbook-detail-popup.component";
import {isNullOrUndefined} from "util";

@Component({
  selector: 'addressbook-search',
  templateUrl: './addressbook-search.component.html',
  styleUrls: ['./addressbook-search.component.css']
})

export class AddressbookSearchComponent {

  searchResultInstitutions: IAddressbookInstitution[];
  searchResultPersons: IAddressbookPerson[];
  addressbookEntriesStateObs: Observable<IAddressbookEntryState>;
  activeAddressbookEntry: IAddressbookInstitution;
  activeAddressbookEntrySub: Subscription;
  contactTypes; // todo typing, change, refactor to ngrx/store and loading on change from table/backend!
  institutionTypes; // todo typing, change, refactor to ngrx/store and loading on change from table/backend!
  addressRegions; // todo typing, change, refactor to ngrx/store and loading on change from table/backend!

  constructor(private addressbookService: AddressbookService,
              private modalService: NgbModal,
              private store: Store<{addressbookEntry: IAddressbookEntryState}>)
  {
    this.addressbookService.institutionTypesObs.subscribe(
      (instTypes) => {this.institutionTypes = instTypes;} // todo dirty hack, remove/replace
    );
    this.addressbookService.addressRegionsObs.subscribe(
      (addrRegions) => {this.addressRegions = addrRegions;} // todo dirty hack, remove/replace
    );
    this.addressbookService.contactTypesObs.subscribe(
      (contTypes) => {this.contactTypes = contTypes;} // todo dirty hack, remove/replace
    );
    this.addressbookEntriesStateObs = this.store.select('addressbookEntry');
    this.activeAddressbookEntrySub = this.addressbookEntriesStateObs.subscribe(
      (addressbookEntryState) => {
        this.activeAddressbookEntry = addressbookEntryState.activeAddressbookEntry;
      }
    );
  }

  ngOnInit() {}

  search($event) {
    if ($event.target.value.length > 2) {
      this.addressbookService.typeaheadSearch($event.target.value.toLowerCase()).subscribe(
        (addressbookEntries) =>
        {
          this.searchResultInstitutions = addressbookEntries.institutions;
          this.searchResultPersons = addressbookEntries.persons;
        }
      );
    }
    else {
      this.searchResultPersons = null;
      this.searchResultInstitutions = null;
    }
  }

  showInstitution(id) {
    this.addressbookService.loadInstitutionFromServer(id);
  }

  editActiveEntry() {
    this.store.dispatch(new AddressbookEntryActions.SetActiveAddressbookEntry(this.activeAddressbookEntry));
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.ADDRESSBOOK, page: MODULE_PAGES.ADDRESSBOOK.EDIT}));
  }

  createNewEntry() {
    const modalRef = this.modalService.open(AddressbookCreatePopupComponent, {size: 'lg'});
    modalRef.result.then(
      (institution: IAddressbookInstitution) => {
        this.activeAddressbookEntry = institution;
        this.store.dispatch(new AddressbookEntryActions.SetActiveAddressbookEntry(this.activeAddressbookEntry));
        if (!isNullOrUndefined(institution)) {
          this.editActiveEntry();
        }
      }
    )
  }

  printContactType(type_id) {
    if (this.contactTypes) {
      let type = this.contactTypes.find(x => x.id == type_id);
      if (type) {
        return type.name;
      }
      else {
        return "";
      }
    }
  }

  printInstitutionType(type_id) {
    if (this.institutionTypes) {
      let type = this.institutionTypes.find(x => x.id == type_id);
      if (type) {
        return type.name;
      }
      else {
        return "";
      }
    }
  }

  printAddressRegion(region_id) {
    if (this.addressRegions) {
      let region = this.addressRegions.find(x => x.id == region_id);
      if (region) {
        return region.name;
      }
      else {
        return "";
      }
    }
  }

  ngOnDestroy() {
    this.activeAddressbookEntrySub.unsubscribe();
  }
}
