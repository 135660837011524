import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IStammbaumState} from '../../store/stammbaum-state.interface';

@Component({
  selector: 'case-detail-stammbaum',
  templateUrl: './case-detail-stammbaum.component.html',
  styleUrls:['./case-detail-stammbaum.component.css']
})
export class CaseDetailStammbaumComponent implements OnInit, AfterViewInit{
  @Input() stammbaumState: IStammbaumState;
  ngOnInit() {
  }

  ngAfterViewInit() {
  }
}
