import {Component, OnDestroy, ElementRef, ViewChild} from "@angular/core";
import {IAutoparserState} from "../../store/autoparser-state.interface";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";

@Component({
  selector: 'nvz-ap-messages',
  templateUrl: './nvz-ap-messages.component.html',
  styleUrls: ['./nvz-ap-messages.component.css']
})
export class NvzApMessagesComponent implements OnDestroy {
  @ViewChild('scrollcontainer', { static: true }) private scrollcontainer: ElementRef;
  autoparserState: IAutoparserState;
  autoparserStateSub: Subscription;

  constructor(private store: Store<{autoparser: IAutoparserState}>) {
    this.autoparserStateSub = this.store.select('autoparser').subscribe(
      (autoparserState) => {
        this.autoparserState = autoparserState;
        setTimeout( () => {
          try {
            this.scrollcontainer.nativeElement.scrollTop = this.scrollcontainer.nativeElement.scrollHeight;
        } catch(err) { }
          }, 10);
      }
    );
  }

  ngOnDestroy() {
    this.autoparserStateSub.unsubscribe();
}
}
