import {Component, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {ICaseState} from '../../store/case-state.interface';
import {Subscription} from 'rxjs';
import {IStammbaumPerson} from '../../stammbaum/stammbaum-person.interface';
import {StammbaumService} from '../../services/stammbaum.service';

@Component({
  selector: 'heirs-for-caselist',
  templateUrl: './heirs-for-caselist.component.html',
  styleUrls: ['./heirs-for-caselist.component.css']
})
export class HeirsForCaselistComponent implements OnDestroy {
  caseState: ICaseState;
  heirlist: IStammbaumPerson[];
  caseStateSub: Subscription;

  constructor(private store: Store<{cases: ICaseState}>, private stammbaumService: StammbaumService) {
    this.caseStateSub = this.store.select('cases').subscribe(
      (caseState) => {
        this.caseState = caseState;
        this.heirlist = caseState.expandedHeirs;
      }
    );
  }

  getPersonTypeText(person_type: number) {
    return this.stammbaumService.getPersonTypeText(person_type);
  }

  ngOnDestroy() {
    this.caseStateSub.unsubscribe();
  }
}
