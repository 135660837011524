import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {ListDocsComponent} from "./list-docs/list-docs.component";
import {AdminDocsModuleComponent} from "./admin-docs-module/admin-docs-module.component";
import {GenerateDocumentPopupComponent} from "./generate-document-popup/generate-document-popup.component";
import {SelectRecipientFromAddressbookComponent} from "./select-recipient-from-addressbook/select-recipient-from-addressbook.component";
import {SelectRecipientFromUserComponent} from "./select-recipient-from-user/select-recipient-from-user.component";
import {EditDocumentComponent} from "./edit-document/edit-document.component";
import {GenerateDocButtonComponent} from "./generate-doc-button/generate-doc-button.component";

@NgModule({
  declarations: [
    ListDocsComponent,
    AdminDocsModuleComponent,
    GenerateDocumentPopupComponent,
    SelectRecipientFromAddressbookComponent,
    SelectRecipientFromUserComponent,
    EditDocumentComponent,
    GenerateDocButtonComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    ListDocsComponent,
    AdminDocsModuleComponent,
    EditDocumentComponent,
    GenerateDocButtonComponent
  ],
  entryComponents: [
    GenerateDocumentPopupComponent,
    EditDocumentComponent
  ]
})

export class GeneratedDocumentsModule {}
