import { Injectable } from '@angular/core';
import { Address } from '../models/address';

@Injectable()
export class AddressService {

  constructor() {
  }
}

