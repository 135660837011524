import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {AddressbookEntryComponent} from "./addressbook-entry/addressbook-entry.component";
import {AddressbookFormComponent} from "./addressbook-form/addressbook-form.component";
import {AddressbookSearchComponent} from "./addressbook-search/addressbook-search.component";
import {AddressbookAutocompleteComponent} from "./addressbox-autocomplete/addressbook-autocomplete.component";
import {AddressbookCreatePopupComponent} from "./addressbook-create-popup/addressbook-create-popup.component";
import {AddressbookSubformAddressComponent} from "./addressbook-subform-address/addressbook-subform-address.component";
import {AddressbookSubformPersonComponent} from "./addressbook-subform-person/addressbook-subform-person.component";
import {AddressbookSubformContactComponent} from "./addressbook-subform-contact/addressbook-subform-contact.component";
import {AddressbookDetailPopupComponent} from "./addressbook-detail-popup/addressbook-detail-popup.component";

@NgModule({
  entryComponents: [
    AddressbookDetailPopupComponent,
    AddressbookCreatePopupComponent,
    AddressbookSubformAddressComponent,
    AddressbookSubformContactComponent,
    AddressbookSubformPersonComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [
    AddressbookEntryComponent,
    AddressbookFormComponent,
    AddressbookSearchComponent,
    AddressbookAutocompleteComponent,
    AddressbookCreatePopupComponent,
    AddressbookSubformAddressComponent,
    AddressbookSubformPersonComponent,
    AddressbookSubformContactComponent,
    AddressbookDetailPopupComponent
  ],
  exports: [
    AddressbookEntryComponent,
    AddressbookAutocompleteComponent,
    AddressbookCreatePopupComponent,
    AddressbookDetailPopupComponent
  ]
})

export class AddressbookModule {}
