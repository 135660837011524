import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup} from '@angular/forms';
import * as StammbaumActions from '../../store/stammbaum.actions';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import {IStammbaumMarriage} from '../stammbaum-marriage.interface';
import {Store} from '@ngrx/store';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {CaseService} from '../../services/case.service';
import {CustomUppercasePipe} from '../../tools/custom-uppercase-pipe/custom-uppercase-pipe';

@Component({
  selector: 'add-child',
  templateUrl: './add-child.component.html',
  styleUrls: ['./add-child.component.css']
})
export class AddChildComponent implements OnInit {
  form: FormGroup;
  first_parent_id: number = null;
  constructor(private ngbActiveModal: NgbActiveModal,
              private caseService: CaseService,
              private customUppercasePipe: CustomUppercasePipe,
              private store: Store<{stammbaum: IStammbaumState}>) {}

  ngOnInit() {
    this.form = new FormGroup({
      'nachname': new FormControl(''),
      'vorname': new FormControl(''),
      'geburtsname': new FormControl(''),
      'kommentar': new FormControl(''),
      'sicher_keine_nachkommen': new FormControl(false),
      'wird_gesucht': new FormControl(false),
      'geschlecht': new FormControl(0)
    });
  }

  cancel() {
    this.ngbActiveModal.dismiss();
  }

  save() {
    const child_data: IStammbaumPerson = <IStammbaumPerson>{
      nachname: this.customUppercasePipe.transform(this.form.controls.nachname.value),
      vorname: this.form.controls.vorname.value,
      geburtsname: this.form.controls.geburtsname.value,
      kommentar: this.form.controls.kommentar.value,
      sicher_keine_nachkommen: this.form.controls.sicher_keine_nachkommen.value,
      wird_gesucht: this.form.controls.wird_gesucht.value,
      geschlecht: this.form.controls.geschlecht.value
    };
    child_data.nachname = child_data.nachname.toLocaleUpperCase(); // todo check if can be removed should be redundant
    const second_parent_id: number = null; // todo implement
    this.store.dispatch(new StammbaumActions.TriggerAddChild({child_data: child_data, first_parent_id: this.first_parent_id, second_parent_id: second_parent_id}));
    this.ngbActiveModal.close();
  }
}
