import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FileItem, FileLikeObject, FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {ConstantsService} from '../../../services/constants.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FileUploadService} from '../../../services/file-upload.service';
import {IFileUpload} from '../../file-upload.interface';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FileUploaderComponent implements OnInit {
  public hasBaseDropZoneOver = false;
  @Input() case_id: number;
  @Input() case_name: string;
  @Input() person_id: number;
  @Input() person_name: string;
  @Input() display_only = false;
  form: FormGroup;
  folderList: {id: number, description: string}[];

  constructor(private constantsService: ConstantsService,
              private ngbActiveModal: NgbActiveModal,
              public fileUploadService: FileUploadService ) {
    this.form = new FormGroup({
      'title': new FormControl('', [Validators.required, Validators.maxLength(50)]),
      'description': new FormControl('', []),
      'folder_id': new FormControl(0)
    });
  }

  ngOnInit(): void {
    this.folderList = this.fileUploadService.getFolderList();
  }

  setValues() {
    this.fileUploadService.currentFileInfo = <IFileUpload>{case_id: this.case_id, person_id: this.person_id, folder_id: this.form.controls.folder_id.value, description: this.form.controls.description.value, title: this.form.controls.title.value, stammbaum_relation: null};
    console.log(this.fileUploadService.currentFileInfo);
  }

  public fileOverBase(e: any): void {
    this.setValues();
    this.hasBaseDropZoneOver = e;
  }

  getFileInfoForItem(item: FileItem) {
    return this.fileUploadService.uploaderFileInfo.find(x => x.file === item);
  }

  close() {
    this.ngbActiveModal.close();
  }

  get_folder_name(item: FileItem) {
    const infoItem = this.getFileInfoForItem(item);
    if (!infoItem) {
      return '';
    }
    const id = infoItem.information.folder_id;
    const folder: {id: number, description: string} = this.folderList.find(x => +x.id === +id);
    if (folder) {
      return folder.description;
    }
    return '';
  }

  canClose() {
    if (this.fileUploadService.uploader.queue.length === 0) {
      return true;
    }
    const entries = this.fileUploadService.uploader.queue;
    let all_uploading = true;
    for (const entry of entries) {
      if (!entry.isUploading && !entry.isReady) {
        all_uploading = false;
      }
    }
    if (this.fileUploadService.uploader.isUploading && all_uploading) {
      return true;
    }
    return false;
  }

  fileOverDrop($event) {}

  fileLeaveDrop($event) {}

  dropped($event: NgxFileDropEntry[]) {
    this.setValues();
    for (const f of $event) {

      if (f.fileEntry.isFile) {
        const fileEntry = f.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          // console.log(f.relativePath, file);
          this.fileUploadService.uploader.addToQueue([file]);
        });
      }
    }
  }

}
