import {Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {INVZStandesamt} from '../nvz-standesamt.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import * as NVZOptions from '../nvz-options';
import {NVZService} from '../../services/nvz.service';

@Component({
  selector: 'nvz-standesaemter-form',
  templateUrl: './nvz-standesaemter-form.component.html',
  styleUrls: ['./nvz-standesaemter-form.component.css']
})
export class NVZStandesaemterFormComponent implements OnInit, AfterViewInit {
  @ViewChild('name', { static: true }) nameElement: ElementRef;
  @ViewChild('comment', { static: true }) commentElement: ElementRef;
  @ViewChild('status', { static: true }) statusElement: ElementRef;
  @HostListener('window:keydown', ['$event'])
  editMode = false;
  amt: INVZStandesamt = <INVZStandesamt>{};
  form: FormGroup;
  options = NVZOptions.NVZStandesamtStatusOptions;

  keyEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.key === 'Enter') {
      this.save();
    }
  }

  constructor(private activeModal: NgbActiveModal,
              private nvzService: NVZService) { }

  ngOnInit() {
    this.form = new FormGroup({
        'name': new FormControl(null, [Validators.required, Validators.maxLength(50)]),
        'comment': new FormControl(null, [Validators.maxLength(100)]),
        'schluessel': new FormControl('', [Validators.maxLength(15)]),
        'status': new FormControl(0, [Validators.required]),
      });
    if (this.editMode) {
      this.form.setValue({
        name: this.amt.name,
        comment: this.amt.comment,
        schluessel: this.amt.schluessel,
        status: this.amt.status
      })
    }
  }

  ngAfterViewInit() {
    this.nameElement.nativeElement.focus();
  }

  save() {
    if (this.form.valid) {
      this.amt.name = this.form.controls.name.value;
      this.amt.comment = this.form.controls.comment.value;
      this.amt.schluessel = this.form.controls.schluessel.value;
      this.amt.status = this.form.controls.status.value;
      this.activeModal.close(this.amt);
    }
  }

  back() {
    this.activeModal.close();
  }
}
