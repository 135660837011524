import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VundvPopupComponent} from './vundv-popup/vundv-popup.component';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [
    VundvPopupComponent
  ],
  entryComponents: [
    VundvPopupComponent
  ],
  exports: [
    VundvPopupComponent
  ]
})
export class DocumentsModule {}
