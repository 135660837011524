import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import { FileUploadService } from '../../../services/file-upload.service';
import { IFileUpload } from '../../file-upload.interface';
import { ConstantsService } from '../../../services/constants.service';
import {isNullOrUndefined} from 'util';
import {IUser} from '../../../models/user.interface';
import {RightsManagementService} from '../../../services/rights-management.service';
import {IFileUploadState} from '../../../store/file-upload-state.interface';
import {ICaseState} from '../../../store/case-state.interface';
import {IUserState} from '../../../store/user-state.interface';
import {ConfirmModalComponent} from '../../../tools/confirm-modal/confirm-modal.component';
import {FormGroup, FormControl} from '@angular/forms';
import {FileUploaderComponent} from '../file-uploader/file-uploader.component';
import {UserService} from '../../../services/user.service';
import {IStammbaumPerson} from '../../../stammbaum/stammbaum-person.interface';

@Component({
  selector: 'file-upload-list',
  templateUrl: './file-upload-list.component.html',
  styleUrls: ['./file-upload-list.component.css']
})
export class FileUploadListComponent implements OnInit, OnDestroy {

  @Input() componentHeadline: string;
  @Input() mode: string = 'case';
  fileUploads: IFileUpload[];
  fileUploadsSub: Subscription;
  copiedFileUploads: IFileUpload[];
  activeTab: number = 0;
  users: IUser[];
  usersSub: Subscription;
  activeCaseId: number;
  activeCaseName: string;
  casesSub: Subscription;
  activePerson: IStammbaumPerson;

  folderList = [];

  loading: boolean = false;
  deleting: boolean = false;
  moving: boolean = false;
  renaming = false;
  moving_done: boolean = false;
  deleting_done: boolean = false;
  renaming_done = false;
  error: boolean = false;
  no_folder: boolean = false;
  file_transfered: string = null;
  transfer_done: boolean = false;
  fileToDelete: string = null;
  fileToRename: string = null;
  fileToMove: string = null;
  targetFolder: number = null;

  file_title: string = '';
  file_title_new: string = '';
  title_new: string = '';
  file_description: string = '';
  file_description_new: string = '';
  file_folder: number = 0;

  filterForm: FormGroup;
  filterstring: string = "";

  constructor(private fileUploadService: FileUploadService,
              public userService: UserService,
              public rightsManagementService: RightsManagementService,
              private store: Store<{fileUploads: IFileUploadState, cases: ICaseState, users: IUserState}>,
              private modalService: NgbModal,
              private constantsService: ConstantsService) { }

  ngOnInit() {
    this.filterForm = new FormGroup({
      'searchString': new FormControl(null)
    });
    this.filterForm.valueChanges.subscribe(
      (form) => {
         if (form.searchString != null) {
          this.filterstring = form.searchString;
          this.copyAndRunFilters();
        }
      }
    );
    this.fileUploadsSub = this.store.select('fileUploads').subscribe(
      (fileUploads) => {
        if (this.mode == 'case') {
          this.fileUploads = fileUploads.fileUploadsForCurrentCase;
        }
        else {
          this.fileUploads = fileUploads.fileUploadsForCurrentPerson;
        }
        this.copyAndRunFilters();
        this.fileToDelete = null;
        this.fileToMove = null;
      });
    this.usersSub = this.store.select('users').subscribe(
      (users) => {this.updateUsers(users.users);}
    );
    this.casesSub = this.store.select('cases').subscribe(
      (cases) => {
        this.activeCaseId = cases.activeCaseId;
        if (cases.activeCase) {
          this.activeCaseName = cases.activeCase.last_name;
        } else {
          this.activeCaseName = '';
        }
        this.activePerson = {...cases.activePerson};
      }
    );

    this.folderList = this.fileUploadService.getFolderList(); // todo remove somehow
  }

  resetAllErrors() {
    this.deleting = false;
    this.deleting_done = false;
    this.moving = false;
    this.moving_done = false;
    this.transfer_done = false;
    this.loading = false;
    this.error = false;
    this.renaming = false;
    this.renaming_done = false;
    this.no_folder = false;
  }

  copyAndRunFilters() {
    let tempFiles: IFileUpload[] = [...this.fileUploads];
    const filterstring = this.filterstring.toUpperCase();
    tempFiles = tempFiles.filter(x =>
      x.folder_id == this.activeTab && (!filterstring || x.description.toUpperCase().includes(filterstring) || x.file_name.toUpperCase().includes(filterstring) || x.title.toUpperCase().includes(filterstring))
    );
    // this.copiedFileUploads = tempFiles.sort((a, b) => (a.file_name > b.file_name) ? 1 : (b.file_name > a.file_name) ? -1 : 0);
    this.copiedFileUploads = tempFiles.sort((a, b) => (a.created > b.created) ? 1 : (b.created > a.created) ? -1 : 0);
  }

  updateUsers(users: IUser[]) {
    this.users = users;
  }

  updateFileUploads(fileUploads: IFileUpload[]) {
    this.fileUploads = fileUploads;
  }

  onClicked(element) {
    this.fileUploadService.showFile(element);
  }

  tabChange($event) {
    this.activeTab = $event.nextId;
    this.copyAndRunFilters();
  }

  changeFolder() {
    this.resetAllErrors();
    if (isNullOrUndefined(this.fileToMove)) {
      return;
    }
    if (+this.fileToMove == 0) {
      return;
    }
    if (this.targetFolder == null) {
      this.no_folder = true;
      return;
    }
    this.moving = true;
    this.fileUploadService.changeFolder(+this.fileToMove, this.targetFolder).subscribe(
      (response) => {
        this.moving = false;
        this.moving_done = true;
        if (this.mode == 'case') {
          this.fileUploadService.triggerFileUploadForCaseReloadFromServer();
        }
        else {
          this.fileUploadService.triggerFileUploadForPersonReloadFromServer();
        }
      },
      (error) => {
        this.moving = false;
        this.error = true;
        this.moving_done = true;
      },
      () => {
        if (!this.moving_done) {
          this.moving_done = true;
          this.error = true;
          this.moving = false;
        }
      }
    );
  }

  renameFile() {
    if (this.fileToRename && this.file_title_new && this.title_new) {
      const modalRef = this.modalService.open(ConfirmModalComponent);
      modalRef.componentInstance.message = 'Wollen Sie die Datei wirklich umbenennen?';
      modalRef.componentInstance.title = 'Bestätigung';
      modalRef.result.then(
        (result) => {
          if (result) {
            this.renameConfirmed();
          }
        }
      );
    }
  }

  renameConfirmed() {
    this.resetAllErrors();
    this.renaming = true;
    this.fileUploadService.changeFilename(+this.fileToRename, this.file_title_new, this.file_description_new, this.title_new).subscribe(
      (result) => {
        this.renaming_done = true;
        this.renaming = false;
        this.fileToRename = null;
        this.file_title_new = null;
        this.title_new = null;
        this.file_description_new = null;
      },
      (error) => {
        this.error = true;
        this.renaming_done = true;
        this.renaming = false;
      },
      () => {}
    );
  }

  delete() {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.message = "Wollen Sie die Datei wirklich löschen?";
    modalRef.componentInstance.title = "Bestätigung";
    modalRef.result.then(
      (result) => {
        if (result) {
          this.deleteConfirmed();
        }
      }
    );
  }

  deleteConfirmed() {
    if (isNullOrUndefined(this.fileToDelete)) {
      return;
    }
    if (+this.fileToDelete == 0) {
      return;
    }
    this.resetAllErrors();
    this.deleting = true;
    this.fileUploadService.delete(+this.fileToDelete).subscribe(
      (response) => {
        this.deleting = false;
        this.deleting_done = true;
        if (this.mode == 'case') {
          this.fileUploadService.triggerFileUploadForCaseReloadFromServer();
        }
        else {
          this.fileUploadService.triggerFileUploadForPersonReloadFromServer();
        }
      },
      (error) => {
        this.deleting = false;
        this.error = true;
        this.deleting_done = true;
      },
      () => {
        if (!this.deleting_done) {
          this.deleting_done = true;
          this.error = true;
          this.deleting = false;
        }
      }
    );
  }

  doneFileUploads(result: any) {
    if (result.toString()=="401") {
      this.error = true;
    }
    else {
      this.error = false;
    }
    this.loading = false;
    this.transfer_done = true;
    if (this.mode === 'case') {
      this.fileUploadService.triggerFileUploadForCaseReloadFromServer();
    } else {
      this.fileUploadService.triggerFileUploadForPersonReloadFromServer();
    }
  }

  uploadDocuments() {
    const modalRef: NgbModalRef = this.modalService.open(FileUploaderComponent, <NgbModalOptions>{size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.display_only = false;
    if (this.file_folder > 0) {
      modalRef.componentInstance.folder_id = this.file_folder;
    } else {
      modalRef.componentInstance.folder_id = 0;
    }
    if (this.mode === 'case') {
      modalRef.componentInstance.person_id = 0;
      modalRef.componentInstance.case_id = this.activeCaseId;
      modalRef.componentInstance.case_name = this.activeCaseName;
    } else {
      modalRef.componentInstance.person_id = this.activePerson.id;
      modalRef.componentInstance.person_name = this.activePerson.nachname;
      modalRef.componentInstance.case_id = this.activePerson.case;
    }
  }

  ngOnDestroy() {
    this.casesSub.unsubscribe();
    this.usersSub.unsubscribe();
    this.fileUploadsSub.unsubscribe();
  }
}
