import {Component} from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {IGeneratedDocRecipient} from '../generated-doc-recipient.interface';
import {IGeneratedDocumentState} from '../../store/generated-document-state.interface';
import {CaseService} from '../../services/case.service';
import * as GeneratedDocumentActions from '../../store/generated-document.actions';
import {IGeneratedDocTemplate} from '../generated-doc-template.interface';
import {IGeneratedDocCategory} from '../generated-doc-category.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RoutingService} from '../../routing/routing.service';

@Component({
  selector: 'generate-document-popup',
  templateUrl: './generate-document-popup.component.html',
  styleUrls: ['./generate-document-popup.component.css']
})

export class GenerateDocumentPopupComponent {
  generatedDocCategorySub: Subscription;

  case: number;
  person: number;
  selectRecipientMode: string = 'addressbook';
  recipients: IGeneratedDocRecipient[] = [];
  documentType: number = 1;
  documentTypeSelected: string = '';
  documentCategory: number = null;
  documentTemplates: IGeneratedDocTemplate[] = [];
  documentTemplate: number = null;
  documentTypeOptions: {value: number, name: string}[] = [
    {value: 1, name: 'Dokument'},
    {value: 2, name: 'E-Mail'},
    {value: 3, name: 'Fax'}
  ];
  documentCategories: IGeneratedDocCategory[] = [];

  constructor(private store: Store<{generatedDocument: IGeneratedDocumentState}>,
              public caseService: CaseService,
              private ngbActiveModal: NgbActiveModal,
              private routingService: RoutingService) {
    this.generatedDocCategorySub = this.store.select('generatedDocument').subscribe(
      (generatedDocumentState) => {
        this.documentCategories = generatedDocumentState.categories;
        this.documentTemplates = generatedDocumentState.templates_for_category;
      }
    );
  }

  ngOnDestroy() {
    this.generatedDocCategorySub.unsubscribe();
  }

  selectPerson() {
    let result: IGeneratedDocRecipient[] = this.recipients.filter(x => x.case_person_id === this.person);
    if (result.length === 0) {
      // this.recipients.push(<IGeneratedDocRecipient>{case_person_id: this.person, name: this.heirService.getNameForHeirId(this.person)});
    }
  }

  recipientSelected(recipient: IGeneratedDocRecipient) {
    let result = this.recipients.find( // check if already added before
      x =>
        (x.addressbook_person_id === recipient.addressbook_person_id) &&
        (x.user_id === recipient.user_id) &&
        (x.case_person_id === recipient.case_person_id) &&
        (x.addressbook_institution_id === recipient.addressbook_institution_id));
    if (!result) {
      this.recipients.push(recipient);
    }
  }

  selectFromAdressbook() {
    this.selectRecipientMode = 'addressbook';
  }

  selectFromUsers() {
    this.selectRecipientMode = 'user';
  }

  changedCategory() {
    this.documentTemplates = [];
    this.documentTemplate = null;
    this.store.dispatch(new GeneratedDocumentActions.TriggerReloadGeneratedDocumentTemplates(this.documentCategory));
  }

  changedTemplate() {
    const template: IGeneratedDocTemplate = this.documentTemplates.find(x => +x.id === +this.documentTemplate);
    if (template) {
      this.store.dispatch(new GeneratedDocumentActions.ReplaceGeneratedDocumentTemplateSelected(template));
    }
  }

  removeRecipient(recipient: IGeneratedDocRecipient) {
    const ix = this.recipients.indexOf(recipient);
    if (ix > -1) {
      this.recipients.splice(ix, 1);
    }
  }

  createDocument() {
    this.store.dispatch(new GeneratedDocumentActions.ReplaceGeneratedDocumentRecipientsSelected(this.recipients));
    this.store.dispatch(new GeneratedDocumentActions.ReplaceGeneratedDocumentCaseAndPersonSelected({case_id: this.case, person_id: this.person}))
    this.ngbActiveModal.close(true);
  }
}
