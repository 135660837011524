import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DebugEntryComponent} from "./debug-entry.component";
import {NotesModule} from "../notes/notes.module";

@NgModule({
  declarations: [
    DebugEntryComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    NotesModule
  ],
  exports: [
    DebugEntryComponent
  ]
})

export class DebugModule {}
