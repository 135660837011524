import * as UserRoleActions from './user-role.actions';

const initialState = [];

export function userRoleReducer(state = initialState, action: UserRoleActions.UserRoleActions) {
  switch(action.type) {

    case UserRoleActions.SET_USER_ROLES:
      return action.payload;

    default:
      return state;
  }
}
