import {Injectable, OnDestroy} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as StammbaumActions from './stammbaum.actions';
import {StammbaumService} from '../services/stammbaum.service';
import {IStammbaumState} from './stammbaum-state.interface';
import {IStammbaum} from '../stammbaum/stammbaum.interface';
import {StammbaumDisplayService} from '../services/stammbaum-display.service';
import {Subscription} from 'rxjs';
import {IStammbaumPerson} from '../stammbaum/stammbaum-person.interface';
import {IStammbaumMarriage} from '../stammbaum/stammbaum-marriage.interface';
import {CaseService} from '../services/case.service';
import {captureMessage} from '@sentry/browser';


@Injectable()
export class StammbaumEffects implements OnDestroy {

  @Effect({dispatch: false})
  updateStammbaumPerson = this.actions.pipe(
    ofType<StammbaumActions.TriggerEditPersonInActiveStammbaum>(StammbaumActions.TRIGGER_EDIT_PERSON_IN_ACTIVE_STAMMBAUM),
    map((action) => {
      console.log(action.payload);
      this.stammbaumService.updateStammbaumPerson(action.payload).subscribe(
        (result) => {
          console.log(result)
        },
        (error) => {
          console.log(error) // todo implement error handling
          captureMessage(`error updating person in active stammbaum: ${action.payload.id}`);
        },
        () => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
        }
      );
    })
  );

  @Effect({dispatch: false})
  updateStammbaumPersonWithoutVersion = this.actions.pipe(
    ofType<StammbaumActions.TriggerEditPersonWithoutVersion>(StammbaumActions.TRIGGER_EDIT_PERSON_WITHOUT_VERSION),
    map((action) => {
      console.log(action.payload);
      this.stammbaumService.updateStammbaumPersonWithoutVersion(action.payload).subscribe(
        (result) => {
          console.log(result)
        },
        (error) => {
          console.log(error) // todo implement error handling
          captureMessage(`error updating person without stammbaum: ${action.payload.id}`);
        },
        () => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(action.payload.case));
        }
      );
    })
  );

  @Effect({dispatch: false})
  updateStammbaumMarriage = this.actions.pipe(
    ofType<StammbaumActions.TriggerEditMarriageInActiveStammbaum>(StammbaumActions.TRIGGER_EDIT_MARRIAGE_IN_ACTIVE_STAMMBAUM),
    map((action) => {
      this.stammbaumService.updateStammbaumMarriage(action.payload).subscribe(
        (result) => {},
        (error) => {
          console.log(error) // todo implement error handling
        },
        () => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
        }
      );
    })
  );


@Effect({dispatch: false})
  updateStammbaumMarriageWithoutVersion = this.actions.pipe(
    ofType<StammbaumActions.TriggerEditMarriageWithoutVersion>(StammbaumActions.TRIGGER_EDIT_MARRIAGE_WITHOUT_VERSION),
    map((action) => {
      this.stammbaumService.updateStammbaumMarriageWithoutVersion(action.payload).subscribe(
        (result) => {},
        (error) => {
          console.log(error) // todo implement error handling
        },
        () => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(this.caseService.getActiveCaseId()));
        }
      );
    })
  );

  @Effect({dispatch: false})
  addStammbaumPerson = this.actions.pipe(
    ofType<StammbaumActions.TriggerAddPersonToActiveStammbaum>(StammbaumActions.TRIGGER_ADD_PERSON_TO_ACTIVE_STAMMBAUM),
    map((action) => {
      this.stammbaumService.addStammbaumPerson(action.payload).subscribe(
        (result) => {},
        (error) => {
          console.log(error) // todo implement error handling
        },
        () => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
        }
      );
    }));

  @Effect({dispatch: false})
  addStammbaumPersonWithoutVersion = this.actions.pipe(
    ofType<StammbaumActions.TriggerAddPersonWithoutVersion>(StammbaumActions.TRIGGER_ADD_PERSON_WITHOUT_VERSION),
    map((action) => {
      this.stammbaumService.addStammbaumPersonWithoutVersion(action.payload).subscribe(
        (result) => {},
        (error) => {
          console.log(error) // todo implement error handling
        },
        () => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(this.caseService.getActiveCaseId()));
        }
      );
    }));

  @Effect({dispatch: false})
  addStammbaumMarriage = this.actions.pipe(
    ofType<StammbaumActions.TriggerAddMarriageToActiveStammbaum>(StammbaumActions.TRIGGER_ADD_MARRIAGE_TO_ACTIVE_STAMMBAUM),
    map((action) => {
      this.stammbaumService.addStammbaumMarriage(action.payload).subscribe(
        (result) => {},
        (error) => {
          console.log(error) // todo implement error handling
        },
        () => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
        }
      );
    }));

  @Effect({dispatch: false})
  addStammbaumMarriageWithoutVersion = this.actions.pipe(
    ofType<StammbaumActions.TriggerAddMarriageWithoutVersion>(StammbaumActions.TRIGGER_ADD_MARRIAGE_WITHOUT_VERSION),
    map((action) => {
      this.stammbaumService.addStammbaumMarriageWithoutVersion(action.payload).subscribe(
        (result) => {},
        (error) => {
          console.log(error) // todo implement error handling
        },
        () => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(this.caseService.getActiveCaseId()));
        }
      );
    }));

  @Effect({dispatch: false})
  setStammbaumPersonActive = this.actions.pipe(
    ofType<StammbaumActions.TriggerSetStammbaumPersonActive>(StammbaumActions.TRIGGER_SET_STAMMBAUM_PERSON_ACTIVE),
    map((action) => {
      this.stammbaumService.setStammbaumPersonActive(action.payload).subscribe(
        (result) => {},
        (error) => {
          console.log(error) // todo implement error handling
        },
        () => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
        }
      );
    }));

  @Effect({dispatch: false})
  setStammbaumMode = this.actions.pipe(
    ofType<StammbaumActions.SetStammbaumMode>(StammbaumActions.SET_STAMMBAUM_MODE),
    map((action) => {
      if (action.payload === 'tree-view') {
        console.log('reset person height required')
        this.stammbaumDisplayService.resetPersonHeightRequired();
      }
    }));

  @Effect({dispatch: false})
  setStammbaumPersonInactive = this.actions.pipe(
    ofType<StammbaumActions.TriggerSetStammbaumPersonInactive>(StammbaumActions.TRIGGER_SET_STAMMBAUM_PERSON_INACTIVE),
    map((action) => {
      this.stammbaumService.setStammbaumPersonInactive(action.payload).subscribe(
        (result) => {},
        (error) => {
          console.log(error) // todo implement error handling
        },
        () => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
        }
      );
    }));

  @Effect({dispatch: false})
  triggerRecalculateDisplay = this.actions.pipe(
    ofType<StammbaumActions.TriggerRecalculateDisplay>(StammbaumActions.TRIGGER_RECALCULATE_DISPLAY),
    map((action) => {
      console.log('trigger recalculate effect here');
      this.stammbaumDisplayService.calculateDisplay(this.stammbaumState.stammbaum);
    }
    ));

  @Effect({dispatch: false})
  loadStammbaumCheckpoint = this.actions.pipe(
    ofType<StammbaumActions.TriggerLoadStammbaumCheckpoint>(StammbaumActions.TRIGGER_LOAD_STAMMBAUM_CHECKPOINT),
    map((action) => {
      this.stammbaumService.loadStammbaumCheckpoint(action.payload).subscribe(
        (result) => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumState.stammbaum.version));
        },
        (error) => {
          console.log(error); // todo implement error handling
        },
          () => {}
      );
    }));

  @Effect({dispatch: false})
  saveStammbaumCheckpoint = this.actions.pipe(
    ofType<StammbaumActions.TriggerSaveStammbaumCheckpoint>(StammbaumActions.TRIGGER_SAVE_STAMMBAUM_CHECKPOINT),
    map((action) => {
      this.stammbaumService.saveStammbaumCheckpoint(action.payload).subscribe(
        (result) => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumState.stammbaum.version));
        },
        (error) => {
          console.log(error); // todo implement error handling
        },
          () => {}
      );
    }));

  @Effect({dispatch: false})
  loadStammbaumVersion = this.actions.pipe(
    ofType<StammbaumActions.TriggerLoadStammbaumVersion>(StammbaumActions.TRIGGER_LOAD_STAMMBAUM_VERSION),
    map((action) => {
      let stammbaum: IStammbaum = null;
      this.stammbaumService.loadStammbaumVersion(action.payload.id).subscribe(
        (result) => {
          if (!result) {
            this.store.dispatch(new StammbaumActions.ReplaceStammbaum(<IStammbaum>{}));
          } else {
            stammbaum = result;
            this.stammbaumDisplayService.calculateDisplay(stammbaum);
          }
        },
        (error) => {
          console.log(error); // todo implement error handling
        },
          () => {}
      );
    }));

  @Effect({dispatch: false})
  loadStammbaumPersonsAndMarriagesWithoutVersion = this.actions.pipe(
    ofType<StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase>(StammbaumActions.TRIGGER_LOAD_STAMMBAUM_PERSONS_AND_MARRIAGES_WITHOUT_VERSION_FOR_CASE),
    map((action) => {
      this.stammbaumService.loadStammbaumPersonsAndMarriagesWithoutVersion(action.payload).subscribe(
        (result) => {
          if (!result) {
            this.store.dispatch(new StammbaumActions.ReplaceStammbaumPersonsAndMarriagesWithoutVersionForCase({persons: <IStammbaumPerson[]>{}, marriages: <IStammbaumMarriage[]>[]}));
          } else {
            this.store.dispatch(new StammbaumActions.ReplaceStammbaumPersonsAndMarriagesWithoutVersionForCase(result));
          }
        },
        (error) => {
          console.log(error); // todo implement error handling
        },
          () => {}
      );
    }));

  @Effect({dispatch: false})
  setStammbaumVersionDefaultAndLoad = this.actions.pipe(
    ofType<StammbaumActions.TriggerSetStammbaumVersionAsDefaultAndLoad>(StammbaumActions.TRIGGER_SET_STAMMBAUM_VERSION_AS_DEFAULT_AND_LOAD),
    map((action) => {
      this.stammbaumService.setStammbaumVersionDefault(action.payload.id).subscribe(
        (result) => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(action.payload));
        },
        (error) => {
          console.log(error); // todo implement error handling
        },
          () => {}
      );
    }));

  @Effect({dispatch: false})
  loadStammbaumVersionsForCase = this.actions.pipe(
    ofType<StammbaumActions.TriggerLoadStammbaumVersionsForCase>(StammbaumActions.TRIGGER_LOAD_STAMMBAUM_VERSIONS_FOR_CASE),
    map((action) => {
      this.stammbaumService.loadStammbaumVersionsForCase().subscribe(
        (result) => {
          if (!result) {
            this.store.dispatch(new StammbaumActions.ReplaceStammbaumVersions([]));
          } else {
            this.store.dispatch(new StammbaumActions.ReplaceStammbaumVersions(result));
            if (action.payload) { // if true, also directly proceed to load the default version (otherwise just reload the list)
              if (result.find(x => x.is_default)) {
                this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(result.find(x => x.is_default)));
              } else {
                if (result && (result.length > 0)) {
                  this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(result[0]));
                }
              }
            }
          }
        },
        (error) => {
          console.log(error); // todo implement error handling
        },
          () => {}
      );
    }));

  @Effect({dispatch: false})
  deleteStammbaumPerson = this.actions.pipe(
    ofType<StammbaumActions.TriggerDeleteStammbaumPerson>(StammbaumActions.TRIGGER_DELETE_STAMMBAUM_PERSON),
    map((action) => {
      this.stammbaumService.deleteStammbaumPerson(action.payload).subscribe(
        (result) => {},
        (error) => {
          console.log(error); // todo implement error handling
        },
        () => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
        }
      );
    })
  );

  @Effect({dispatch: false})
  deleteStammbaumMarriage = this.actions.pipe(
    ofType<StammbaumActions.TriggerDeleteStammbaumMarriage>(StammbaumActions.TRIGGER_DELETE_STAMMBAUM_MARRIAGE),
    map((action) => {
      this.stammbaumService.deleteStammbaumMarriage(action.payload).subscribe(
        (result) => {},
        (error) => {
          console.log(error); // todo implement error handling
        },
        () => {
          if (this.stammbaumService.activeVersion && this.stammbaumService.activeVersion.id > 0) {
            this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
          } else {
            this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(this.caseService.getActiveCaseId()));
          }
        }
      );
    })
  );

  @Effect({dispatch: false})
  addMarriageAndPerson = this.actions.pipe(
    ofType<StammbaumActions.TriggerAddMarriageAndPersonToActiveStammbaum>(StammbaumActions.TRIGGER_ADD_MARRIAGE_AND_PERSON_TO_ACTIVE_STAMMBAUM),
    map((action) => {
      this.stammbaumService.addMarriageAndPerson(action.payload.person, action.payload.marriage).subscribe(
        (result) => {},
        (error) => {
          console.log(error) // todo implement error handling
        },
        () => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
        }
      );
    })
  );

  @Effect({dispatch: false})
  addChild = this.actions.pipe(
    ofType<StammbaumActions.TriggerAddChild>(StammbaumActions.TRIGGER_ADD_CHILD),
    map((action) => {
      this.stammbaumService.addChild(action.payload.first_parent_id, action.payload.second_parent_id, action.payload.child_data).subscribe(
        (result) => {},
        (error) => {
          console.log(error) // todo implement error handling
        },
        () => {
          this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersion(this.stammbaumService.activeVersion));
        }
      );
    })
  );

  stammbaumState: IStammbaumState;
  stammbaumStateSub: Subscription;

  constructor(private actions: Actions,
              private stammbaumService: StammbaumService,
              private caseService: CaseService,
              private stammbaumDisplayService: StammbaumDisplayService,
              private store: Store<{stammbaum: IStammbaumState}>) {
    this.stammbaumStateSub = this.store.select('stammbaum').subscribe(
      (stammbaumState) => {
        this.stammbaumState = stammbaumState;
      }
    );
  }

  ngOnDestroy() {
    this.stammbaumStateSub.unsubscribe();
  }
}
