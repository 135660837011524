import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IAutoparserPostprocessedEntry} from '../../store/autoparser-state.interface';

@Component({
  selector: 'nvz-ap-result-list-popup',
  templateUrl: './nvz-ap-result-list-popup.component.html',
  styleUrls: ['./nvz-ap-result-list-popup.component.css']
})
export class NvzApResultListPopupComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  entry: IAutoparserPostprocessedEntry;
  allExistingEntries: IAutoparserPostprocessedEntry[] = [];
  entriesWithSameNumber: IAutoparserPostprocessedEntry[] = [];
  @ViewChild('urkunden_nr', { static: true }) urkunden_nrElement: ElementRef;
  @ViewChild('nachname', { static: true }) nachnameElement: ElementRef;
  @ViewChild('geburtsname', { static: true }) geburtsnameElement: ElementRef;
  @ViewChild('vorname', { static: true }) vornameElement: ElementRef;
  @ViewChild('juedisch', { static: true }) juedischElement: ElementRef;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.key === 'Enter') {
      this.save();
    }
  }

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {
     this.form = new FormGroup({
        'urkunden_nr': new FormControl(null, [Validators.required, Validators.maxLength(100)]),
        'nachname': new FormControl(null, [Validators.required, Validators.maxLength(100)]),
        'geburtsname': new FormControl(null, [Validators.maxLength(100)]),
        'vorname': new FormControl(null, [Validators.maxLength(100)]),
        'juedisch': new FormControl(false)
      });
     this.form.setValue({
       urkunden_nr: this.entry.urkunden_nr,
       nachname: this.entry.nachname,
       geburtsname: this.entry.geburtsname,
       vorname: this.entry.vorname,
       juedisch: this.entry.juedisch
    });
  }

  ngAfterViewInit() {
    this.urkunden_nrElement.nativeElement.focus();
  }

  checkExisting() {
    this.entriesWithSameNumber = [];
    if (this.form.controls.urkunden_nr.value) {
      this.entriesWithSameNumber = this.allExistingEntries.filter(x => +x.urkunden_nr === +this.form.controls.urkunden_nr.value);
    }
  }

  resetExisting() {
    this.entriesWithSameNumber = [];
  }

  save() {
    if (this.form.valid) {
      this.entry.urkunden_nr = this.form.controls.urkunden_nr.value;
      this.entry.nachname = this.form.controls.nachname.value;
      if (this.entry.nachname) { this.entry.nachname = this.entry.nachname.toUpperCase(); }
      this.entry.geburtsname = this.form.controls.geburtsname.value;
      this.entry.vorname = this.form.controls.vorname.value;
      this.entry.juedisch = this.form.controls.juedisch.value;
      this.activeModal.close({entry: this.entry});
    }
  }

  back() {
    this.activeModal.close();
  }
}
