import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {StammbaumService} from '../../services/stammbaum.service';
import {CaseService} from '../../services/case.service';
import {Subscription} from 'rxjs';
import {IStammbaumState} from '../../store/stammbaum-state.interface';
import {IStammbaumPerson} from '../stammbaum-person.interface';
import {ShowDatePipe} from '../../tools/show-date-pipe/show-date-pipe';
import {Store} from '@ngrx/store';
import * as StammbaumActions from '../../store/stammbaum.actions';

@Component({
  selector: 'stammbaum-select-person',
  templateUrl: 'stammbaum-exchange-person.component.html',
  styleUrls: ['./stammbaum-exchange-person.component.css']
})
export class StammbaumExchangePersonComponent implements OnInit, OnDestroy {
  stammbaumSub: Subscription;
  persons: IStammbaumPerson[] = [];
  personToExchange: IStammbaumPerson = null;

  constructor(private ngbActiveModal: NgbActiveModal,
              private store: Store<{stammbaum: IStammbaumState}>,
              private caseService: CaseService,
              private showDatePipe: ShowDatePipe,
              private stammbaumService: StammbaumService) {}

  ngOnInit(): void {
    this.stammbaumSub = this.stammbaumService.loadStammbaumPersonsAndMarriagesWithoutVersion(this.caseService.getActiveCaseId()).subscribe(
      (result) => {
        let personsUnord = result.persons;
        personsUnord = personsUnord.sort((a, b) => {
          if (a.person_type < b.person_type) { return -1; }
          if (a.person_type > b.person_type) { return 1; }
          return 0;
        });
        this.persons = personsUnord;
        },
      (error) => {},
      () => {}
    );
  }

  ngOnDestroy(): void {
    this.stammbaumSub.unsubscribe();
  }

  printDate(date: string) {
    return this.showDatePipe.transform(date);
  }

  getPersonName() {
    if (!this.personToExchange) { return ''; }
    const gebdat = this.showDatePipe.transform(this.personToExchange.geburtsdatum);
    return `${this.personToExchange.nachname} ${this.personToExchange.vorname} ${gebdat}`;
  }

  printPersonType(p: IStammbaumPerson) {
    return this.stammbaumService.getPersonTypeText(p.person_type);
  }

  exchange(p: IStammbaumPerson) {
    this.stammbaumService.exchangeStammbaumPerson(p.id, this.personToExchange.id).subscribe(
      (result) => {
        this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumVersionsForCase(true));
        this.store.dispatch(new StammbaumActions.TriggerLoadStammbaumPersonsAndMarriagesWithoutVersionForCase(this.caseService.getActiveCaseId()));
        this.ngbActiveModal.close();
      },
      (error) => { console.log(error); },
      () => {}
    );
  }

  close() {
    this.ngbActiveModal.close();
  }
}
