import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StammbaumEntryComponent} from './stammbaum-entry/stammbaum-entry.component';
import {StammbaumListPersonsComponent} from './stammbaum-list-persons/stammbaum-list-persons.component';
import {PersonForListComponent} from './person-for-list/person-for-list.component';
import {StammbaumUrkundenUploadComponent} from './stammbaum-urkunden-upload/stammbaum-urkunden-upload.component';
import {NgxFileDropModule} from 'ngx-file-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EditPersonComponent} from './edit-person/edit-person.component';
import {ExtraFormsModule} from '../../../projects/extra-forms/src/lib/extra-forms.module';
import {SearchStandesamtComponent} from './search-standesamt/search-standesamt.component';
import {UrkundenPopupComponent} from './urkunden-popup/urkunden-popup.component';
import {EditMarriageComponent} from './edit-marriage/edit-marriage.component';
import {DisplayMarriageComponent} from './display-marriage/display-marriage.component';
import {ToolsModule} from '../tools/tools.module';
import {GridsterModule} from 'angular-gridster2';
import {AngularDraggableModule} from 'angular2-draggable';
import {Ng2PanZoomModule} from 'ng2-panzoom';
import {StammbaumTreePersonComponent} from './stammbaum-tree-person/stammbaum-tree-person.component';
import {StammbaumTreeSvgComponent} from './stammbaum-tree-svg/stammbaum-tree-svg.component';
import {StammbaumTreeMarriageConnectionComponent} from './stammbaum-tree-marriage-connection/stammbaum-tree-marriage-connection.component';
import {StammbaumTreeChildrenConnectionComponent} from './stammbaum-tree-children-connection/stammbaum-tree-children-connection.component';
import {StammbaumVersionDisplayComponent} from './stammbaum-version-display/stammbaum-version-display.component';
import {StammbaumVersionSelectComponent} from './stammbaum-version-select/stammbaum-version-select.component';
import {AccordionModule} from 'ngx-bootstrap';
import {AddPersonAndMarriageComponent} from './add-person-and-marriage/add-person-and-marriage.component';
import {AddChildComponent} from './add-child/add-child.component';
import {StammbaumControlsComponent} from './stammbaum-controls/stammbaum-controls.component';
import {StammbaumTreeMapSvgComponent} from './stammbaum-tree-map-svg/stammbaum-tree-map-svg.component';
import {AutosizeModule} from 'ngx-autosize';
import {EditPersonSingleFieldComponent} from './edit-person-single-field/edit-person-single-field.component';
import {StammbaumTreeEditPersonComponent} from './stammbaum-tree-edit-person/stammbaum-tree-edit-person.component';
import {StammbaumTreeEditMarriageComponent} from './stammbaum-tree-edit-marriage/stammbaum-tree-edit-marriage.component';
import {StammbaumTreeSelectEditMarriageComponent} from './stammbaum-tree-select-edit-marriage/stammbaum-tree-select-edit-marriage.component';
import {StammbaumTreeUrkundenUploadComponent} from './stammbaum-tree-urkunden-upload/stammbaum-tree-urkunden-upload.component';
import {StammbaumTreeUrkundenPopupComponent} from './stammbaum-tree-urkunden-popup/stammbaum-tree-urkunden-popup.component';
import {StammbaumCheckpointsComponent} from './stammbaum-checkpoints/stammbaum-checkpoints.component';
import {StammbaumListPersonsWithoutVersionComponent} from './stammbaum-list-persons-without-version/stammbaum-list-persons-without-version.component';
import {PersonForListWithoutVersionComponent} from './person-for-list-without-version/person-for-list-without-version.component';
import {StammbaumExchangePersonComponent} from './stammbaum-exchange-person/stammbaum-exchange-person.component';
import {StammbaumExchangeMarriageComponent} from './stammbaum-exchange-marriage/stammbaum-exchange-marriage.component';
import {CreatePdfComponent} from './create-pdf/create-pdf.component';
import {ChangeTreeSettingsComponent} from './change-tree-settings/change-tree-settings.component';
import { HeirPopupComponent } from './heir-popup/heir-popup.component';
import { MutipleDocumentUsageComponent } from './stammbaum-tree-urkunden-upload/mutiple-document-usage/mutiple-document-usage.component';
import {NgbAccordionModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    StammbaumEntryComponent,
    StammbaumListPersonsComponent,
    StammbaumListPersonsWithoutVersionComponent,
    PersonForListComponent,
    PersonForListWithoutVersionComponent,
    StammbaumExchangePersonComponent,
    StammbaumExchangeMarriageComponent,
    StammbaumUrkundenUploadComponent,
    EditPersonComponent,
    EditPersonSingleFieldComponent,
    EditMarriageComponent,
    DisplayMarriageComponent,
    SearchStandesamtComponent,
    UrkundenPopupComponent,
    StammbaumTreePersonComponent,
    StammbaumTreeSvgComponent,
    StammbaumTreeMapSvgComponent,
    CreatePdfComponent,
    StammbaumTreeMarriageConnectionComponent,
    StammbaumTreeChildrenConnectionComponent,
    StammbaumVersionDisplayComponent,
    StammbaumVersionSelectComponent,
    AddPersonAndMarriageComponent,
    AddChildComponent,
    StammbaumControlsComponent,
    StammbaumTreeEditPersonComponent,
    StammbaumTreeEditMarriageComponent,
    StammbaumTreeSelectEditMarriageComponent,
    StammbaumTreeUrkundenUploadComponent,
    StammbaumTreeUrkundenPopupComponent,
    StammbaumCheckpointsComponent,
    ChangeTreeSettingsComponent,
    HeirPopupComponent,
    MutipleDocumentUsageComponent
  ],
    imports: [
        CommonModule,
        GridsterModule,
        NgxFileDropModule,
        ReactiveFormsModule,
        ExtraFormsModule,
        ToolsModule,
        AngularDraggableModule,
        Ng2PanZoomModule,
        AccordionModule,
        AutosizeModule,
        NgbAccordionModule,
        FormsModule
    ],
  entryComponents: [
    StammbaumCheckpointsComponent,
    StammbaumTreeUrkundenUploadComponent,
    StammbaumTreeUrkundenPopupComponent,
    StammbaumTreeEditPersonComponent,
    StammbaumExchangePersonComponent,
    StammbaumExchangeMarriageComponent,
    CreatePdfComponent,
    ChangeTreeSettingsComponent,
    StammbaumTreeEditMarriageComponent,
    StammbaumTreeSelectEditMarriageComponent,
    EditPersonComponent,
    EditPersonSingleFieldComponent,
    SearchStandesamtComponent,
    UrkundenPopupComponent,
    EditMarriageComponent,
    StammbaumEntryComponent,
    StammbaumVersionSelectComponent,
    AddPersonAndMarriageComponent,
    AddChildComponent,
    HeirPopupComponent,
    MutipleDocumentUsageComponent
  ],
  exports: [
    StammbaumEntryComponent,
    SearchStandesamtComponent,
    StammbaumEntryComponent,
    StammbaumTreeSvgComponent,
    StammbaumExchangePersonComponent,
    StammbaumControlsComponent,
    StammbaumListPersonsComponent,
    StammbaumListPersonsWithoutVersionComponent
  ]
})
export class StammbaumModule {}
