import {Action} from "@ngrx/store";
import {ICalendarEntry} from "../calendar/calendar-entry.interface";

export const REPLACE_ENTRIES_IN_ENTRYLIST = 'REPLACE_ENTRIES_IN_ENTRYLST';
export const ADD_ENTRY_TO_ENTRYLIST = 'ADD_ENTRY_TO_ENTRYLIST';
export const SET_ACTIVE_ENTRY = 'SET_ACTIVE_ENTRY';
export const UPDATE_ACTIVE_ENTRY = 'UPDATE_ACTIVE_ENTRY';
export const DELETE_ALL_ENTRY_DATA = 'DELETE_ALL_ENTRY_DATA';

export class ReplaceEntriesInEntrylist implements Action {
  readonly type = REPLACE_ENTRIES_IN_ENTRYLIST;
  constructor(public payload: ICalendarEntry[]) {}
}

export class AddEntryToEntrylist implements Action {
  readonly type = ADD_ENTRY_TO_ENTRYLIST;
  constructor(public payload: ICalendarEntry) {}
}

export class SetActiveEntry implements Action {
  readonly type = SET_ACTIVE_ENTRY;
  constructor(public payload: ICalendarEntry) {}
}

export class UpdateActiveEntry implements Action {
  readonly type = UPDATE_ACTIVE_ENTRY;
  constructor(public payload: ICalendarEntry) {}
}

export class DeleteAllEntryData implements Action {
  readonly type = DELETE_ALL_ENTRY_DATA;
  constructor() {}
}

export type CalendarEntryActions = ReplaceEntriesInEntrylist | AddEntryToEntrylist | SetActiveEntry | DeleteAllEntryData | UpdateActiveEntry;

