import * as NVZActions from './nvz.actions';
import {INVZState} from "./nvz-state.interface";
import {INVZLand} from '../namensverzeichnisse/nvz-land.interface';

const initialState: INVZState = {
  laender: [],
  staedte: [],
  standesaemter: [],
  verzeichnisse: [],
  eintraege: [],
  activeLand: <INVZLand>{id: 1, name: 'Deutschland', comment: ''},
  activeStadt: null,
  activeStandesamt: null,
  activeVerzeichnis: null
};

export function nvzReducer(state = initialState, action: NVZActions.NVZActions) {
  switch (action.type) {

    case NVZActions.REPLACE_STANDESAEMTER:
      return {
        ...state,
        standesaemter: action.payload
      };

    case NVZActions.REPLACE_STAEDTE:
      return {
        ...state,
        staedte: action.payload
      };

    case NVZActions.REPLACE_LAENDER:
      return {
        ...state,
        laender: action.payload
      };

    case NVZActions.REPLACE_VERZEICHNISSE:
      return {
        ...state,
        verzeichnisse: action.payload
      };

    case NVZActions.REPLACE_NVZEINTRAEGE:
      return {
        ...state,
        eintraege: action.payload
      };

    case NVZActions.SET_ACTIVE_STADT:
      return {
        ...state,
        activeStadt: action.payload
      };

    case NVZActions.SET_ACTIVE_LAND:
      return {
        ...state,
        activeLand: action.payload
      };

    case NVZActions.SET_ACTIVE_STANDESAMT:
      return {
        ...state,
        activeStandesamt: action.payload
      };

    case NVZActions.SET_ACTIVE_VERZEICHNIS:
      return {
        ...state,
        activeVerzeichnis: action.payload
      };

    default:
      return state;
  }
}
