import {Component} from "@angular/core";
import {IAddressbookAddress} from "../addressbook-address.interface";
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AddressbookService} from "../../services/addressbook.service";

@Component({
  selector: 'addressbook-subform-address',
  templateUrl: './addressbook-subform-address.component.html',
  styleUrls: ['./addressbook-subform-address.component.css']
})

export class AddressbookSubformAddressComponent {
  address: IAddressbookAddress;
  subForm: FormGroup;
  addressRegions; // todo typing, change, refactor to ngrx/store and loading on change from table/backend!

  constructor(public activeModal: NgbActiveModal,
              private addressbookService: AddressbookService) {}

  ngOnInit() {
     this.addressbookService.addressRegionsObs.subscribe(
      (addrRegions) => {
        this.addressRegions = addrRegions;
        this.subForm.controls.region.setValue(this.address.region); // update the value now
      } // todo dirty hack, remove/replace
    );
    this.subForm = new FormGroup({
      'street': new FormControl("", [Validators.required, Validators.maxLength(40)]),
      'housenumber': new FormControl("", [Validators.maxLength(8)]),
      'additional_address_information': new FormControl("", [Validators.maxLength(40)]),
      'zipcode': new FormControl("", [Validators.maxLength(10)]),
      'town': new FormControl("", [Validators.maxLength(30)]),
      'region': new FormControl(null, []),
    });
    this.subForm.patchValue(this.address);
  }

  cancel() {
    this.activeModal.close(null);
  }

  delete() {
    this.addressbookService.deleteAddress(this.address.id).subscribe(
      (result) => {
        this.activeModal.close(null);
      },
      (error) => {},
      () => {}
    );
  }

  update() {
    this.addressbookService.updateAddress(
      {...this.address,
        street: this.subForm.controls.street.value,
        housenumber: this.subForm.controls.housenumber.value,
        additional_address_information: this.subForm.controls.additional_address_information.value,
        zipcode: this.subForm.controls.zipcode.value,
        town: this.subForm.controls.town.value,
        region: this.subForm.controls.region.value
      }
    ).subscribe(
      (result) => {
        this.activeModal.close(result);
      },
      (error) => {},
      () => {}
    );
  }

  create() {
     this.addressbookService.createAddress(
      {...this.address,
        street: this.subForm.controls.street.value,
        housenumber: this.subForm.controls.housenumber.value,
        additional_address_information: this.subForm.controls.additional_address_information.value,
        zipcode: this.subForm.controls.zipcode.value,
        town: this.subForm.controls.town.value,
        region: this.subForm.controls.region.value
      }
    ).subscribe(
      (result) => {
        this.activeModal.close(result);
      },
      (error) => {},
      () => {}
    );
  }
}

