import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {fromEvent, Observable, Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, mergeMap} from 'rxjs/operators';

const AUTOCOMPLETE_VALUE_ACCESSOR: any = {
provide : NG_VALUE_ACCESSOR,
useExisting: forwardRef(() => AutocompleteComponent),
multi : true,
};

@Component({
  selector: 'extra-forms-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css'],
  providers : [AUTOCOMPLETE_VALUE_ACCESSOR],
})
export class AutocompleteComponent implements OnInit, ControlValueAccessor {
  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Input() options: {id: number, name: string}[]; // options retrieved from server by using search string
  @Input() no_value_text: string; // text shown if no options are found
  @Input() min_chars: number; // minimum chars before querying server
  @Input() not_enough_chars_text: string;

  private disabled: boolean;
  private onChange: Function;
  private onTouched: Function;
  private searchValueSubject: Subject<string> = new Subject<string>();
  private searchValueSubscription: Subscription;
  public currentSearchValue = '';

  constructor() {
    this.onChange = (_: any) => {
      console.log('changed', _);
    };
    this.onTouched = () => {};
    this.disabled = false;
  }

  ngOnInit() {
    this.searchValueSubscription = this.searchValueSubject.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe((value) => {
        this.search.emit(value);
      });
  }

  public searchValueChanged($event) {
    this.currentSearchValue = $event.target.value;
    this.searchValueSubject.next($event.target.value);
  }

  public selectedValueChanged($event) {
    if ($event && $event.target && $event.target.value) {
      this.onChange($event.target.value);
      this.onTouched();
    }
  }

  writeValue(obj: any): void {
    console.log('write value triggered', obj);
    this.selectedValueChanged(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
