import {Component, Input} from '@angular/core';
import {IAppNotification} from "./app-notification.interface";
import * as SLOTS from "./app-notification.interface";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";

@Component({
  selector: 'app-notification',
  templateUrl: './app-notification.component.html',
  styleUrls: ['./app-notification.component.css']
})

export class AppNotificationComponent {
  appNotificationsObs: Observable<{appNotifications: IAppNotification[]}>;
  appNotifications: IAppNotification[];
  SLOT_GLOBAL = SLOTS.NOTIFICATION_SLOT_GLOBAL;
  @Input() limitToSlot: string;

  constructor(private store: Store<{appNotifications: { appNotifications: IAppNotification[]}}>) {
    this.appNotificationsObs = this.store.select('appNotifications');
    this.appNotificationsObs.subscribe((appNotifications) => {this.appNotifications = appNotifications.appNotifications;});
  }
}
