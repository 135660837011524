import {Component} from "@angular/core";
import {IAddressbookPerson} from "../addressbook-person.interface";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AddressbookService} from "../../services/addressbook.service";


@Component({
  selector: 'addressbook-subform-person',
  templateUrl: './addressbook-subform-person.component.html',
  styleUrls: ['./addressbook-subform-person.component.css']
})

export class AddressbookSubformPersonComponent {
  person: IAddressbookPerson;
  subForm: FormGroup;

  constructor(public activeModal: NgbActiveModal,
              private addressbookService: AddressbookService) {}

  ngOnInit() {
    this.subForm = new FormGroup({
      'first_name': new FormControl("", [Validators.maxLength(30)]),
      'last_name': new FormControl("", [Validators.required, Validators.maxLength(30)]),
      'function': new FormControl("", [Validators.maxLength(50)]),
      'comment': new FormControl("", [Validators.maxLength(200)]),
    });
    this.subForm.patchValue(this.person);
  }

  cancel() {
    this.activeModal.close(null);
  }

  delete() {
    this.addressbookService.deletePerson(this.person.id).subscribe(
    (result) => {
      this.activeModal.close(null);
    },
    (error) => {},
    () => {}
    );
  }

  update() {
     this.addressbookService.updatePerson(
      {...this.person,
        first_name: this.subForm.controls.first_name.value,
        last_name: this.subForm.controls.last_name.value,
        comment: this.subForm.controls.comment.value,
        function: this.subForm.controls.function.value
      }
    ).subscribe(
      (result) => {
        this.activeModal.close(result);
      },
      (error) => {},
      () => {}
    );
  }

  create() {
     this.addressbookService.createPerson(
      {...this.person,
        first_name: this.subForm.controls.first_name.value,
        last_name: this.subForm.controls.last_name.value,
        comment: this.subForm.controls.comment.value,
        function: this.subForm.controls.function.value
      }
    ).subscribe(
      (result) => {
        this.activeModal.close(result);
      },
      (error) => {},
      () => {}
    );
  }
}
