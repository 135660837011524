import * as CaseActions from './case.actions';
import {ICaseState} from './case-state.interface';
import {ICase} from '../cases/case.interface';

const initialState: ICaseState = {
  cases: [],
  expandedCaseInCaselist: null,
  expandedHeirs: [],
  activeCaseId: null,
  activeCase: null,
  activeCaseDetailTab: 1,
  activeCaseHistory: [],
  activePersonId: null,
  activePerson: null,
  personHistory: [],
  caseModuleHeadline: 'Fälle',
  caseFilters: {
    searchString: '',
    groupString: 'Standard',
    limitResultsToUser: 0,
    fiscalFilter: 'egal',
    pagination: 0,
    personState: 'alles anzeigen'
  },
  searchResultsCases: [],
  searchResultsPersons: [],
  searchResultMode: '',
  loadAllCases: false,
  loadAllCasesExecuted: false
};

export function caseReducer(state = initialState, action: CaseActions.CaseActions) {
  switch (action.type) {

    case CaseActions.REPLACE_CASES_IN_CASELIST:
      const id: number = state.activeCaseId;
      return {
        ...state,
        cases: action.payload
      };

    case CaseActions.REPLACE_CASE_IN_CASELIST:
      const cases: ICase[] = state.cases.filter(x => x.id !== action.payload.id);
      cases.push(action.payload);
      cases.sort((a, b) => {
          if (a.id < b.id) { return 1 }
          if (a.id > b.id) { return -1 }
          return 0;
        });
      return {
        ...state,
        cases: [...cases]
      };

    case CaseActions.ADD_CASE_TO_CASELIST:
      return {
        ...state,
        cases: [...state.cases, action.payload]
      };

    case CaseActions.SET_ACTIVE_CASE:
      return {
        ...state,
        activeCaseId: action.payload.id,
        activeCase: action.payload.caseData
      };

    case CaseActions.SET_ACTIVE_CASE_DETAIL_TAB:
      return {
        ...state,
        activeCaseDetailTab: action.payload
      };

    case CaseActions.SET_ACTIVE_PERSON:
      return {
        ...state,
        activePersonId: action.payload.id,
        activePerson: action.payload.personData
      };

    case CaseActions.EXPAND_CASE_IN_CASELIST:
      return {
        ...state,
        expandedCaseInCaselist: action.payload
      };

    case CaseActions.REPLACE_EXPANDED_HEIRS:
      return {
        ...state,
        expandedHeirs: action.payload
      };

    case CaseActions.DELETE_ALL_CASE_DATA:
      return initialState;

    case CaseActions.ADD_CASE_ID_TO_HISTORY:
      const caseHistory: {id: number, text: string}[] = [...state.activeCaseHistory];
      if (caseHistory.find(x => x.id === action.payload.id)) { // if it is already in history, ignore
        return state;
      }
      return {
        ...state,
        activeCaseHistory: [...state.activeCaseHistory, action.payload]
      };

    case CaseActions.ADD_PERSON_ID_TO_HISTORY:
      const personHistory: {id: number, text: string}[] = [...state.personHistory];
      if (personHistory.find(x => x.id === action.payload.id)) { // if it is already in history, ignore
        return state;
      }
      return {
        ...state,
        personHistory: [...state.personHistory, action.payload]
      };

    case CaseActions.SET_CASE_MODULE_HEADLINE:
      return {
        ...state,
        caseModuleHeadline: action.payload
      };

    case CaseActions.SET_CASE_FILTERS:
      return {
        ...state,
        caseFilters: action.payload
      };

    case CaseActions.CLEAR_CASE_FILTERS:
      return {
        ...state,
        caseFilters: initialState.caseFilters
      };

    case CaseActions.SET_LOAD_ALL_CASES_FLAG:
      return {
        ...state,
        loadAllCases: action.payload
      };

    case CaseActions.SET_LOAD_ALL_CASES_EXECUTED_FLAG:
      return {
        ...state,
        loadAllCasesExecuted: action.payload
      };

    case CaseActions.REPLACE_CASE_SEARCH_RESULTS:
      return {
        ...state,
        searchResultsCases: action.payload
      };

    case CaseActions.REPLACE_PERSON_SEARCH_RESULTS:
      return {
        ...state,
        searchResultsPersons: action.payload
      };

    case CaseActions.REPLACE_SEARCH_RESULT_MODE:
      return {
        ...state,
        searchResultMode: action.payload
      };

    default:
      return state;
  }
}
